import { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  CellWrapper,
  Dropdown,
  DropdownOptionItem,
  Flex,
  HoverAction,
  HoverWrapper,
  Spacing,
  Text,
  Tooltip,
  Icon
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { OutreachContext } from '../../../../../communication/outreach/context/OutreachContext'
import { isEmpty } from 'lodash'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const imagePostStyles = css`
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
  background-image: url('${({ image }) => image}'),
    url('/static/img/placeholders/avatarPlaceholder.png'); 
  background-size: cover;
  background-position: center;
`

const StyledContainer = styled(Flex)`
  padding: 0 24px;
  ${cellEllipsis};
`

const ImageContainer = styled.div`
  width: 30px;
  height: 30px;
  ${imagePostStyles};
  border-radius: 50%;
`

const NoImageContainer = styled.div`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary.light5};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out; 
    text-decoration: underline;
  }
`

const StyledFlex = styled(Flex)`
  width : 97%;
`

const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

const textWrapperStyles = css`
  overflow: hidden;
`

const addToPayButtonStyles = css`
  color: ${({ theme }) => theme.colors.primary.default} !important;
  background: ${({ theme, disabledIcon }) => disabledIcon ? theme.colors.app.light3 : theme.colors.app.default} !important;
  white-space:nowrap;
`
const addToPayButtonTextStyles = css`
  color: ${({ theme }) => theme.colors.neutral.white} !important;
`

const removeFromPayButtonStyles = css`
  color: ${({ theme }) => theme.colors.neutral.white} !important;
  background: ${({ theme }) => theme.colors.neutral.white} !important;
  border: 1px solid ${props => props.theme.colors.primary.light3};
  border-bottom: 1px solid ${props => props.theme.colors.primary.light3} !important;
  white-space:nowrap;
`

const removeFromPayButtonTextStyles = css`
  color: ${({ theme }) => theme.colors.primary.light3} !important;
`

export const InvoicePaymentsTableTdAliasName = ({
  row: { original },
  onEditClick,
  onAliasClick,
  onSendMassiveEmailClick,
  onRemoveFromInvoiceClick,
  onAddToPayClick,
  onRemoveFromPayClick,
  loadingAddToPay = false,
  loadingRemoveFromPay = false
}) => {
  const { t } = useTranslate()

  const influencer = original?.irmInfluencers?.[0]
  const influencerId = influencer?._id
  const alias = influencer?.alias || original?.alias
  const picture = influencer?.picture

  const [sendToOpened, setSendToOpened] = useState(false)
  const { setAssociateToOutreachesModalOpen } = useContext(OutreachContext)
  const showAddToPay = original?.statusInPool === 'toAdd' || original?.statusInPool === 'incompleteInfluencerData'
  const showRemoveFromPay = original?.statusInPool === 'added'
  const showedEdit = original?.status === 'draft' && !isEmpty(influencerId)
  const isDisabledAddToPay = original?.statusInPool === 'incompleteInfluencerData'
  const isDisabledRemoveFromInvoice = original?.status !== 'draft'

  const renderSendTo = () => {
    return (
      <Dropdown
        isOpen={sendToOpened}
        setOpen={setSendToOpened}
        align='left'
        top='18px'
        right='30px'
        position='fixed'
        width='auto'
      >
        <DropdownOptionItem
          onClick={() => onSendMassiveEmailClick()}
          data-cy='compose-email'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.composeEmail')}
            </Text>
          </Flex>
        </DropdownOptionItem>
        <DropdownOptionItem
          onClick={() => setAssociateToOutreachesModalOpen(true, [influencerId], 'influencer')}
          data-cy='send-to-outreach'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.addToOutreach')}
            </Text>
          </Flex>
        </DropdownOptionItem>
      </Dropdown>
    )
  }

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <>
            <Spacing vertical size={5} />
            {
              influencerId
                ? <ImageContainer image={picture} />
                : <NoImageContainer>
                  <Icon name='dangercircle' size={18} color='primary.light3' />
                </NoImageContainer>
            }

          </>
          <Flex column styles={textWrapperStyles}>
            {onAliasClick && influencerId
              ? <Text name='Influencer-Alias' ellipsis showTooltip styles={clickableStyles} size='14' color='primary.light2' bold onClick={onAliasClick}>{alias}</Text>
              : <Tooltip
                  text={t('commons.influencer.deleted.tooltip')}
                  place='bottom'
                  type='dark'
                  color='neutral.white'
                  containerStyles={widgetTooltipContainerStyles}
                  styles={widgetTooltipStyles}
              >
                <Text name='Influencer-Alias' ellipsis showTooltip size='14' color='primary.light2' bold>{alias}</Text>
              </Tooltip>
            }
          </Flex>
        </StyledContainer>
        <Flex>
          <HoverWrapper>
            {onEditClick && showedEdit && !showRemoveFromPay &&
            <Tooltip
              text={t('entity.influencer.tool.edit.tooltip')}
              place='bottom'
              type='dark'
              color='neutral.white'
              containerStyles={widgetTooltipContainerStyles}
              styles={widgetTooltipStyles}
              >
              <HoverAction onClick={onEditClick} iconName='edit' />
            </Tooltip>
            }

            {onSendMassiveEmailClick && !showRemoveFromPay &&
              <>
                <Tooltip
                  text={!influencerId ? t('commons.button.sendEmail.disabled.influencersDeleted.tooltip') : t('commons.button.sendEmail.tooltip')}
                  place='top'
                  type='dark'
                  color='neutral.white'
                  containerStyles={widgetTooltipContainerStyles}
                  styles={widgetTooltipStyles}
                >
                  <HoverAction
                    onClick={() => setSendToOpened(!sendToOpened)}
                    iconName='send'
                    isDropdown
                    dropdownComponent={renderSendTo}
                    disabledIcon={!influencerId}
                  />
                </Tooltip>
              </>
            }

            {onRemoveFromInvoiceClick && !showRemoveFromPay && (
            <Tooltip
              text={isDisabledRemoveFromInvoice ? t('commons.button.removeFromInvoice.disabled.tooltip') : t('commons.button.removeFromInvoice.tooltip')}
              place='bottom'
              type='dark'
              color='neutral.white'
              containerStyles={widgetTooltipContainerStyles}
              styles={widgetTooltipStyles}
                >
              <HoverAction onClick={onRemoveFromInvoiceClick} iconName='remove' disabledIcon={isDisabledRemoveFromInvoice} />
            </Tooltip>
            )}
          </HoverWrapper>

          {showAddToPay && onAddToPayClick && (
          <Tooltip
            text={isDisabledAddToPay && t('commons.button.addToPay.disabled.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
                >
            <HoverAction
              wrapperStyles={addToPayButtonStyles}
              textStyles={addToPayButtonTextStyles}
              onClick={onAddToPayClick}
              disabledIcon={loadingAddToPay || isDisabledAddToPay}
              loaderColor='neutral.white'
              text={t('commons.button.addToPay')}
            />
          </Tooltip>
          )}
          {showRemoveFromPay && onRemoveFromPayClick && (
          <HoverAction
            wrapperStyles={removeFromPayButtonStyles}
            textStyles={removeFromPayButtonTextStyles}
            onClick={onRemoveFromPayClick}
            disabledIcon={loadingRemoveFromPay}
            loaderColor='primary.light3'
            text={t('commons.button.removeFromPay')}
          />
          )}
        </Flex>
      </StyledFlex>
    </CellWrapper>
  )
}
