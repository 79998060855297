import styled from 'styled-components'
import { Flex, Image, Text } from 'influ-dms'

const StyledFlex = styled(Flex)`
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primary.light6};
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  cursor: pointer;
  :not(:last-child) {
    margin-right: 24px;
  }
  transition: border 0.1s ease-in-out;
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.light2};
  }
`

const ImageContainer = styled(Flex)`
  height: 48px;
`

const StyledText = styled(Text)`
  margin-top: 8px;
  text-align: center;
  padding: 0 8px;
`

export const EmailSetupOption = ({ text, server, handleClick, img }) => {
  const onClick = () => {
    handleClick(server)
  }
  return (
    <StyledFlex center onClick={onClick}>
      <ImageContainer center>
        <Image src={img} alt={img} />
      </ImageContainer>
      <StyledText bold color='primary.dark' size='12'>{text}</StyledText>
    </StyledFlex>
  )
}
