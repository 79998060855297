import { DropdownOptionItem, Flex, Text } from 'influ-dms'
import { useTranslate } from '~/hooks'
import { MoreOptions } from '../../../commons/components/ActionButtons'

export const DropdownContainer = ({
  isDisabled,
  moreOptionsOpened,
  setMoreOptionesOpened,
  onClickOption1,
  onClickOption2
}) => {
  const { t } = useTranslate()
  return (
    <MoreOptions
      isDisabled={isDisabled}
      isOpen={moreOptionsOpened}
      setIsOpen={setMoreOptionesOpened}
      tooltipText={t('commons.button.moreOptions.tooltip')}
    >
      <DropdownOptionItem onClick={onClickOption1} data-cy='unlink-profile'>
        <Flex>
          <Text size='14' ellipsis color='primary.light2'>
            {t('commons.button.removeProfile')}
          </Text>
        </Flex>
      </DropdownOptionItem>
      <DropdownOptionItem onClick={onClickOption2} data-cy='remove-and-delete-profile'>
        <Flex>
          <Text size='14' ellipsis color='primary.light2'>
            {t('commons.button.removeProfileAndDeleteProfile')}
          </Text>
        </Flex>
      </DropdownOptionItem>
    </MoreOptions>
  )
}
