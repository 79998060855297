export const reportsTableNumberRangeFilter = (rows, id, filterValue) => {
  if (!filterValue || !filterValue.length) return rows
  const [fromValue, toValue] = [...filterValue]
  let finalRows = rows
  if (fromValue !== undefined) {
    finalRows = finalRows.filter(row => row.values[id] >= fromValue)
  }
  if (toValue !== undefined) {
    finalRows = finalRows.filter(row => row.values[id] <= toValue)
  }
  return finalRows
}
