import styled from 'styled-components'
import { BottomCenteredModalHeader } from './BottomCenteredModalHeader'

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  ${({ isOpen, isMaximized, noAnimation, selectedOption }) => isOpen ? `
    right: 120px;
    width: ${isMaximized ? 'calc(100vw - 200px)' : '600px'};
    bottom: 0;
    opacity: 1;
    transition: ${noAnimation ? 'all 0s ease-in-out' : 'right 0.4s ease-in-out, width 0.4s ease-in-out, opacity 0.4s ease-in-out'};
  ` : `
    right: -600px;
    width: 600px;
    bottom: -600px;
    opacity: 0;
    transition: ${selectedOption === 'personalEmail' ? 'all 0s ease-in-out' : 'bottom 0.4s ease-in-out, width 0.4s ease-in-out, right 0.4s ease-in-out 0.4s, opacity 0.4s ease-in-out'};
  `}
  z-index: 106;
  max-height: 100vh;
`

const Container = styled.div`
  border-radius: 8px; 
  box-shadow: -4px 0 7px 0 rgba(124, 113, 148, 0.2); 
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-sizing: border-box;
  height: 100%;
`

export const BottomCenteredModal = ({
  isOpen,
  isMaximized,
  header,
  isWarningModal,
  noAnimation,
  selectedOption,
  children
}) => (
  <ModalContainer
    isOpen={isOpen}
    isMaximized={isMaximized}
    noAnimation={noAnimation}
    selectedOption={selectedOption}
  >
    <Container>
      <BottomCenteredModalHeader isWarningModal={isWarningModal}>{header}</BottomCenteredModalHeader>
      {children}
    </Container>
  </ModalContainer>
)
