import { gql } from '@apollo/client'
import { getApplicationFormFragment } from '../../../../graphql/getApplicationFormFragment'

export const getPaymentInvoiceFormForEdition = gql`
  query getPaymentInvoiceFormForEdition($_id: String!) {
    getPaymentInvoiceFormForEdition(_id: $_id) {
      ...ApplicationFormFragment
    }
  }
  ${getApplicationFormFragment}
`
