import { useContext } from 'react'
import { useTranslate } from '~/hooks/useTranslate'
import {
  Button,
  Flex,
  Icon,
  FormContext,
  Text,
  Tooltip,
  SnackbarContext,
  Spacing,
  StateButton
} from 'influ-dms'
import { useMutation } from '@apollo/client'
import { css } from 'styled-components'
import { useCleanCache } from '../../../../commons/hooks'
import { sendMassiveEmailToInfluencers as sendMassiveEmailToInfluencersMutation } from '../../apollo/sendMassiveEmailToInfluencers'
import { Divider, widgetTooltipStyles } from './styles'

export const SendMassiveEmailReviewEmailModal = ({
  influencers,
  influencersWithoutEmail,
  massiveCommData,
  onClose,
  setStep,
  setErrorInfo
}) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const { showSnackbar } = useContext(SnackbarContext)
  const { senderName, replyToAddress, fullSenderEmail } = massiveCommData
  const recipientsCount = influencers?.length - influencersWithoutEmail?.length
  const { clearInfluencerEmailsTabCache } = useCleanCache()

  const [sendMassiveEmailToInfluencers, { loading: loadingSendMassiveEmailToInfluencers }] = useMutation(sendMassiveEmailToInfluencersMutation, {
    update (cache, _, { variables }) {
      if (influencers.length === 1) {
        clearInfluencerEmailsTabCache(influencers[0].email)
        cache.gc()
      }
    }
  })

  const handleSendEmail = async () => {
    let response = null
    const parsedHtml = values?.html.replace(/src="blob:(.*?)">/g, (image) => {
      let replacedImage = image
      values?.inlineAttachments?.forEach(attachment => {
        const alt = image.match(/alt="(.*?)"/)?.length > 0 && image.match(/alt="(.*?)"/)[1]
        if (alt === attachment.contentId) {
          replacedImage = image.replace(/src="blob:(.*?)"/, `src="cid:${attachment.contentId}" alt="${attachment.contentId}"`)
            .replace(`alt="${attachment.contentId}"`, '')
          return replacedImage
        } else {
          return image
        }
      })
      return replacedImage
    })

    const variables = {
      ...values,
      attachments: [...values?.inlineAttachments, ...values?.notInlineAttachments],
      html: parsedHtml
    }
    response = await sendMassiveEmailToInfluencers({ variables })
    if (!response) {
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    } else if (response?.data?.sendMassiveEmailToInfluencers?.success) {
      showSnackbar('success', t('commons.snackbar.success.sendEmail'))
      onClose()
      return true
    } else if (response.errors) {
      setErrorInfo(response.errors[0])
      return false
    } else {
      if (response.type === 'snackbarError') {
        showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      }
      return false
    }
  }

  return (
    <Flex column justify='space-between' styles={css`height: 440px;`} data-cy='SendMassiveEmailReviewEmailModal'>
      <Flex column>
        <Text bold color='primary.dark' size={20}>
          {t('modal.newMessage.emailReview.header1')}
        </Text>
        <Spacing size={16} />
        <Divider />
        <Spacing size={16} />
        <Flex align='center' gap='4px'>
          <Text color='primary.light2' size={14}>
            {t('entity.email.field.emailFrom.label')}
          </Text>
          <Text bold color='primary.dark' size={14}>
            {t(`${senderName} <${fullSenderEmail}>`)}
          </Text>
        </Flex>
        <Spacing size={8} />
        <Flex align='center' gap='4px'>
          <Text color='primary.light2' size={14}>
            {t('entity.email.field.emailType.label')}
          </Text>
          <Text bold color='primary.dark' size={14}>
            {t('entity.email.field.emailType.value.bulkEmail.label')}
          </Text>
        </Flex>
        <Spacing size={8} />
        <Text color='primary.light2' size={14}>
          {t('entity.email.field.emailType.value.bulkEmail.helpertext', { emailAddress: replyToAddress })}
        </Text>
        <Spacing size={16} />
        <Divider />
        <Spacing size={16} />
        {influencersWithoutEmail?.length > 0 &&
          <>
            <Flex column>
              <Text bold color='primary.dark' size={14}>
                {t('modal.newMessage.influencerWithoutEmail.paragraph1', { count: influencersWithoutEmail?.length })}
              </Text>
              <Text color='primary.light2' size={14}>
                {t('modal.newMessage.influencerWithoutEmail.paragraph2')}
              </Text>
            </Flex>
            <Spacing size={16} />
            <Divider />
            <Spacing size={16} />
          </>
        }
        <Flex column>
          <Text bold color='primary.dark' size={14}>
            {t('modal.newMessage.influencerIncluded.paragraph1', { count: recipientsCount })}
          </Text>
          <Text color='primary.light2' size={14}>
            {t('modal.newMessage.influencerIncluded.paragraph2')}
          </Text>
        </Flex>
      </Flex>
      <Flex align='center' justify='space-between'>
        <Button
          color='secondary'
          variant='outline'
          size='m'
          onClick={() => setStep(3)}
          withIcon
        >
          <Icon name='arrowleft' size='20' color='primary.light3' />
          {t('commons.button.back')}
        </Button>
        {recipientsCount === 0
          ? <Tooltip
              text={t('commons.button.continueAndSend.tooltip.noRecipients')}
              place='top'
              type='dark'
              color='neutral.white'
              styles={widgetTooltipStyles}
            >
            <StateButton
              size='m'
              color='warning'
              onClick={handleSendEmail}
              data-cy='Button-SendMassiveEmailReviewEmailModal-save'
              disabled
            >
              {t('commons.button.continueAndSend')}
            </StateButton>
          </Tooltip>
          : <StateButton
              loading={loadingSendMassiveEmailToInfluencers}
              loadingIconColor='neutral.white'
              size='m'
              color={influencersWithoutEmail?.length > 0 ? 'warning' : 'primary'}
              onClick={handleSendEmail}
              data-cy='Button-SendMassiveEmailReviewEmailModal-save'
            >
            {t('commons.button.continueAndSend')}
          </StateButton>
        }
      </Flex>
    </Flex>
  )
}
