import { gql } from "@apollo/client";

export const updateProfileValidation = gql`
  query updateProfileValidation($brandId: String!, $profileIds: [String]!) {
    updateProfileValidation(brandId: $brandId, profileIds: $profileIds) {
      calculateProfilesBulk {
        total
        alreadyAnalyzed
        notAnalizable {
          total
          byNetwork {
            type
            count
          }
        }
        notIncludedInPlan {
          total
          byNetwork {
            type
            count
          }
        }
      }
      profileIds
    }
  }
` 
