import { gql } from '@apollo/client'

export const currencyQuery = gql`
  query currencyQuery (
   $brandId: String!,
  ) {
    brand(_id: $brandId) {
      id: _id
      currency {
        code
        currency
        symbol
      }
    }
  }
 `
