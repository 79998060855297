import { gql } from "@apollo/client";

export const deleteTemplates = gql`
  mutation deleteTemplates($brandId: String!, $templateIds: [String]!) {
    deleteTemplates(brandId: $brandId, templateIds: $templateIds) {
      message
      code
      success
    }
  }
`