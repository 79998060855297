import { Flex } from 'influ-dms'

const ActionButtons = (props) => {
  const { children, styles, view } = props
  return (
    <>
      <Flex data-cy={`${view}-Actions-Header`} column justify='center' styles={styles}>
        <Flex align='center'>
          {(
            <>
              {children}
            </>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export { ActionButtons }
