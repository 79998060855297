import { Button, EmptyState } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { RouterHelper } from '~/routing/routerHelper'

export const NoCommunicationFF = ({ brandId, isEnterprise, onClose }) => {
  const { t } = useTranslate()
  const router = useRouter()

  const goToPricesPlans = () => {
    onClose()
    if (isEnterprise) {
      window.open('mailto:info@influencity.com?Subject=I%20want%20to%20increase%20my%20limits')
    } else {
      router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
    }
  }

  return (
    <EmptyState
      imageUrl={'/static/img/error.png'}
      titleText={t('modal.newMessage.notActiveBulkEmail.title.header1')}
      subtitleText={t('modal.newMessage.notActiveBulkEmail.title.header2')}
    >
      <Button size='l' onClick={goToPricesPlans}>
        {isEnterprise ? t('commons.button.talkToUS') : t('commons.button.seePlansAndPrices')}
      </Button>
    </EmptyState>
  )
}
