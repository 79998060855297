import { gql } from '@apollo/client'

export const deleteInfluencerInCampaign = gql`
mutation deleteInfluencerInCampaign(
  $ids: [String!]
  ) {
    deleteInfluencerInCampaign(
      ids: $ids
    ){
      message
      success
    }
  }
`
