import { useContext, useEffect } from 'react'
import { Button, FormContext, Flex, Spacing, StateButton, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { TemplateContext } from '../../../../../communication/templates/context/TemplateContext'
import { MESSAGE_LIMITS } from '../../../../../commons/communication/CommunicationConstants'
import { NewEmailModalFooterAttachment } from '../../../../../commons/communication/setup/newEmail/footer/NewEmailModalFooterAttachment'
import { AttachmentListContainer } from './styles'

export const SendMassiveEmailBulkEmailFormFooter = ({
  attachments,
  influencers,
  onClose,
  setStep,
  useEmails,
  files,
  setFiles
}) => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const formValues = values
  const router = useRouter()
  const programId = get(router, 'query.programId')
  const influencerIds = influencers?.map(influencer => influencer._id) || []
  const { setTemplate, setSaveTemplateModalOpen } = useContext(TemplateContext)
  const isFormDisabled =
    formValues.subject?.replace(/(<([^>]+)>)/ig, '').length === 0 ||
    formValues.html?.replace(/(<([^>]+)>)/ig, '').length === 0 ||
    !formValues.subject ||
    !formValues.html

  const showWarningText = files.length > MESSAGE_LIMITS.attachmentCount

  useEffect(() => {
    setFiles([])
    if (programId) {
      setValue('InputText', 'source', { type: 'program', _id: programId })
      setValue('InputText', 'programId', programId)
    }
    if (useEmails) {
      setValue('InputText', 'emails', influencers)
    } else {
      setValue('InputText', 'influencerIds', influencerIds)
    }
  }, [])

  useEffect(() => {
    if (attachments?.length) {
      setFiles(attachments || [])
    }
  }, [attachments?.length])

  useEffect(() => {
    const parsedFiles = files?.map(file => ({ inline: false, file, attachmentId: file?.attachmentId })) || []
    setValue('InputText', 'notInlineAttachments', parsedFiles)
  }, [files?.length])

  const handleSaveTemplate = () => {
    setTemplate(formValues)
    setSaveTemplateModalOpen(true)
  }

  return (
    <>
      <Flex>
        <AttachmentListContainer>
          {files?.map(file =>
            !file?.inline &&
              <NewEmailModalFooterAttachment
                key={file?.id || file?.attachmentId}
                file={file}
                files={files}
                setFiles={setFiles}
              />
          )}
        </AttachmentListContainer>
      </Flex>
      {showWarningText &&
        <Text color='state.warningDefault'>
          {t('commons.communication.newEmail.modal.footer.limitFilesAllowed', { limitFilesAllowed: MESSAGE_LIMITS.attachmentCount })}
        </Text>
      }
      <Spacing size='32' />
      <Flex align='center' justify='flex-start'>
        <StateButton
          disabled={isFormDisabled}
          loadingIconColor='neutral.white'
          size='m'
          onClick={() => setStep(4)}
          data-cy='Button-SendMassiveEmailBulkEmailFormFooter-send'
        >
          {t('commons.button.reviewAndSend')}
        </StateButton>
        <Spacing vertical size='8' />
        <Button
          variant='outline'
          size='m'
          disabled={isFormDisabled}
          onClick={handleSaveTemplate}
          data-cy='Button-SendMassiveEmailBulkEmailFormFooter-saveTemplate'
        >
          {t('commons.button.saveAsTemplate')}
        </Button>
        <Button
          color='secondary'
          variant='flat'
          size='m'
          onClick={onClose}
        >
          {t('commons.button.cancel')}
        </Button>
      </Flex>
    </>
  )
}
