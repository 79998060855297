import { gql } from '@apollo/client'
import { keyLabelValue } from './keyLabelValue'

const influencerFragment = gql`
  fragment influencer on DeepSearchResponseType {
    socialId,
    influencerId,
    name,
    searchResultId,
    link,
    fullName,
    followers,
    followersGrowth,
    avgViews,
    avgReelsPlays,
    viewsGrowth,
    likesGrowth,
    picture,
    interactions,
    infEngagement,
    location,
    audienceGender{
      ...keyLabelValue,
    },
    audienceInterests{
      ...keyLabelValue,
    },
    audienceCity{
      ...keyLabelValue,
    },
    audienceState{
      ...keyLabelValue,
    },
    audienceCountry{
      ...keyLabelValue,
    },
    audienceAge{
      ...keyLabelValue,
    },
    ethnicity{
      ...keyLabelValue,
    },
    audienceLanguage{
      ...keyLabelValue,
    },
    audienceBrands{
      ...keyLabelValue,
    }
    isAlreadyInNetwork,
    isAlreadyInIRM,
    profileId,
    irmInfluencers {
      _id
      alias
      picture
    }
    topics{
      ...keyLabelValue,
    }
    keywords
    hashtags
  }
  ${keyLabelValue}
`

export { influencerFragment }
