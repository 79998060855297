import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { profileBySharedLink, profileMetricsQuery } from '../../apollo'

export const useQueryProfileMetrics = ({ variables, skip, fetchPolicy = 'cache-first' }) => {
  const { sharedId } = variables
  const query = sharedId ? profileBySharedLink : profileMetricsQuery

  const { data, loading, error } = useQuery(query, {
    variables,
    fetchPolicy,
    skip,
  })

  const resultData = get(data, 'profileBySharedLink') || get(data, 'profile')
  return { data: resultData, loading, error }
}
