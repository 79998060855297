import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { enableHotjar, hotjarId, hotjarSv } = publicRuntimeConfig

const hotjar = (id, sv) => {
  if (!enableHotjar || typeof window === 'undefined') return
  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
    h._hjSettings = { hjid: hotjarId, hjsv: hotjarSv }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script'); r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

export default hotjar
