import { useContext, useEffect, useState } from 'react'
import {
  Button,
  Flex,
  FormContext,
  SideModalFooter,
  StateButton,
  Spacing,
  Text,
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { AttachmentListContainer } from '../../../irm/influencers/commons/modals/SendMassiveEmailModals/styles'
import { NewEmailModalFooterAttachment } from '../../../commons/communication/setup/newEmail/footer/NewEmailModalFooterAttachment'
import { MESSAGE_LIMITS } from '../../../commons/communication/CommunicationConstants'

export const CreateEditTemplateModalFooter = ({
  selectedTemplate,
  attachments,
  handleSubmit,
  loading,
  handleCancel,
  showAttachButton,
  quickSave,
  files,
  setFiles
}) => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const formDirty = values?.formDirty ||
    values?.html?.replace('<p><br></p>', '') !== selectedTemplate?.html?.replace('<p><br></p>', '') ||
    values?.subject?.replace('<p><br></p>', '') !== selectedTemplate?.subject?.replace('<p><br></p>', '')
  const [isFormDirty, setIsFormDirty] = useState(formDirty)
  const isFormInvalid = Object.values(values.errors || {}).some(val => val)
  const hasSubjectAndBody = values?.subject?.replace('<p><br></p>', '') && values?.html?.replace('<p><br></p>', '')
  let isFormDisabled = isFormInvalid || !isFormDirty || (!hasSubjectAndBody || quickSave)
  if (quickSave && values?.name && isFormDirty) {
    isFormDisabled = false
  }
  const showWarningText = files.length > MESSAGE_LIMITS.attachmentCount

  useEffect(() => {
    if (attachments?.length) {
      setFiles(attachments || [])
    }
  }, [attachments?.length])

  useEffect(() => {
    setIsFormDirty(formDirty)
  }, [formDirty])

  useEffect(() => {
    const parsedFiles = files?.map(file => ({ inline: false, file, attachmentId: file?.attachmentId })) || []
    setValue('InputText', 'notInlineAttachments', parsedFiles)
    setIsFormDirty(true)
  }, [files?.length])

  return (
    <Flex column>
      {(showAttachButton && files.length > 0) &&
        <>
          <Spacing size='32' />
          <Flex>
            <AttachmentListContainer>
              {files?.map(file =>
                !file?.inline &&
                  <NewEmailModalFooterAttachment
                    key={file?.id || file?.attachmentId}
                    file={file}
                    files={files}
                    setFiles={setFiles}
                  />
              )}
            </AttachmentListContainer>
          </Flex>
          {showWarningText &&
            <Text color='state.warningDefault'>
              {t('commons.communication.newEmail.modal.footer.limitFilesAllowed', { limitFilesAllowed: MESSAGE_LIMITS.attachmentCount })}
            </Text>
          }
        </>
      }
      <SideModalFooter justify='flex-start'>
        <StateButton
          name='createEditTemplate'
          loading={loading}
          onClick={() => handleSubmit(values)}
          disabled={isFormDisabled}
        >
          {t('commons.button.saveTemplate')}
        </StateButton>
        <Spacing size='16' vertical />
        <Button
          name='cancelCreateEditTemplate'
          color='secondary'
          variant='flat'
          onClick={handleCancel}
        >
          {t('commons.button.cancel')}
        </Button>
      </SideModalFooter>
    </Flex>
  )
}
