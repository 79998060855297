export const influencerNoteWithContentSessionStorage = 'influencerNoteWithContent'
export const influencersPerPage = 20
export const notesPerPage = 20
export const listsPerPage = 30
export const maxCaractersLastNote = 130
export const defaultListSortBy = 'name'
export const defaultListSortOrder = 'asc'
export const defaultInfluencersSortBy = 'firstName'
export const defaultInfluencersSortOrder = 'asc'
export const maxProfilesByInfluencer = 4
