import { get } from 'lodash'

export const parseUser = (dataContact) => {
  const self = get(dataContact, 'self') || {}
  const userId = get(self, 'id')
  const email = get(self, 'email')
  const createdAt = get(self, 'createdAt')
  const firstLoginAt = get(self, 'firstLoginAt')
  const userHash = get(self, 'intercomId')
  const contact = get(self, 'contact') || []
  const firstName = get(contact, 'firstName')
  const lastName = get(contact, 'lastName')
  const name = firstName + (lastName ? ' ' + lastName : '')
  const phone = get(contact, 'phoneNumber')
  const jobPosition = get(contact, 'jobPosition')
  const imageUrl = get(contact, 'profilePhoto')

  const res = {}
  if (userId) res.user_id = userId
  if (name) res.name = name
  if (firstName) res.first_name = firstName
  if (lastName) res.last_name = lastName
  if (email) res.email = email
  if (createdAt) res.signed_up = createdAt
  if (firstLoginAt) res.first_login_at = firstLoginAt
  if (userHash) res.user_hash = userHash
  if (phone) res.phone = phone
  if (jobPosition) res.job_title = jobPosition
  if (imageUrl) {
    res.avatar = { type: 'avatar' }
    res.avatar.image_url = imageUrl
  }
  return res
}
