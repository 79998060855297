import { useContext } from 'react'
import {
  Flex,
  Modal,
  Spacing,
  Portal,
  Text,
  Button,
  StateButton
} from 'influ-dms'
import { ThemeContext } from 'styled-components'
import { useTranslate } from '~/hooks/useTranslate'

const EditConfirmationTrackingModal = ({ isOpen, onClose, handleContinue, loading }) => {
  const { colors: { gradients } } = useContext(ThemeContext)
  const { t } = useTranslate()

  return (
    <Portal selector='#editConfirmationTrackingModal'>
      <Modal
        name='editConfirmationTrackingModal'
        isOpen={isOpen}
        title='Tracking Edit'
        height='auto'
        onClose={onClose}
        minHeight='240'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        <Flex column grow='1'>
          <Text color='primary.dark' bold size='15'>{t('reports.modal.trackingEditConfirmation.titleHeader1')}</Text>
          <Spacing size='8' />
          <Text color='primary.light3' size='14'>• {t('reports.modal.trackingEditConfirmation.listItem1.label')}</Text>
          <Text color='primary.light3' size='14'>• {t('reports.modal.trackingEditConfirmation.listItem2.label')}</Text>
        </Flex>
        <Spacing size='32' />
        <Flex>
          <StateButton
            name='edit-confirmation-tracking-button'
            onClick={handleContinue}
            loading={loading}
          >
            {t('commons.button.saveChanges')}
          </StateButton>
          <Spacing vertical size='8' />
          <Button
            onClick={onClose}
            variant='flat'
            color='secondary'
          >{t('commons.button.close')}
          </Button>
        </Flex>
      </Modal>
    </Portal>
  )
}

export { EditConfirmationTrackingModal }
