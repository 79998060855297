import { gql } from '@apollo/client'

export const deactivateAudienceCoupon = gql`
  mutation deactivateAudienceCoupon(
    $programId: String!
    $couponIds: [String]!
  ) {
    deactivateAudienceCoupon(
      programId: $programId
      couponIds: $couponIds
    ){
        message
        code
        success
      }
  }
 `
