import { Avatar, Flex, Text } from 'influ-dms'
import { css } from 'styled-components'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { RouterHelper } from '../../../../routing/routerHelper'
import { AvatarDropdownOptionItem } from './AvatarDropdownOptionItem'
import { useTranslate } from '~/hooks/useTranslate'

const containerStyles = css`
  padding: 2px 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
  overflow: hidden;
`

const preferencesStyles = css`
  margin-top: 4px;
  ${({ isInPage, theme }) => !isInPage ? `
    cursor: pointer;
    :hover {
      color: ${theme.colors.primary.hover};
    }
  ` : ''}
`

const fullNameStyles = css`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const emailStyles = css`
  margin: 4px 0;
`

export const AvatarContactHeader = ({ fullName, email, contactImg, brandName }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const isPreferencesPage = router.pathname.indexOf('basicInfo') > -1
  const handleClick = () => {
    if (!isPreferencesPage) {
      router.push(RouterHelper.getRoute('basicInfo', { brandId }))
    }
  }

  return (
    <AvatarDropdownOptionItem styles={containerStyles} data-cy='Avatar-contact-Option-fullName'>
      <Flex>
        <Avatar
          variant='contact'
          size='m'
          src={contactImg}
          styles={css`margin: 4px 8px 4px 0;`}
        />
        <Flex column justify='center'>
          <Text ellipsis bold color='primary.dark' styles={fullNameStyles} size='14'>{fullName || brandName}</Text>
          <Text ellipsis color='primary.light2' styles={emailStyles}>{email}</Text>
          <Text ellipsis bold color='app.default' styles={preferencesStyles} isInPage={isPreferencesPage} onClick={handleClick}>
            {t('commons.topNavigation.user.dropdown.header.profileAndPreferences.link')}
          </Text>
        </Flex>
      </Flex>
    </AvatarDropdownOptionItem>
  )
}
