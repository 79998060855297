import { gql } from "@apollo/client";

export const getGroupedEntityFieldsByEntity = gql`
  query getGroupedEntityFieldsByEntity(
    $brandId: String
    $entityId: String
    $entity: String!
    $sharedId: String
  ) {
    getGroupedEntityFieldsByEntity(brandId: $brandId, entityId: $entityId, entity: $entity, sharedId: $sharedId)
  }
`
