import {
  Button,
  Flex,
  Modal,
  Portal
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const DuplicatedProfilesTrackingModal = ({ isOpen, onClose, duplicatedProfilesNumber, totalProfilesNumber }) => {
  const { t } = useTranslate()
  return (
    <Portal selector='#duplicatedProfilesTrackingModal'>
      <Modal
        height='250'
        zIndex='102'
        isOpen={isOpen}
        title={t('reports.modal.tracking.duplicatedProfiles.header')}
        onClose={onClose}
        color='state.warningDefault'
      >
        <Flex column grow='1'>
          {t('reports.modal.tracking.duplicatedProfiles.titleParagraph1', { duplicatedProfilesNumber, totalProfilesNumber })}
        </Flex>
        <Flex>
          <Button color='warning' onClick={onClose}>{t('commons.button.continue')}</Button>
        </Flex>
      </Modal>
    </Portal>
  )
}

export { DuplicatedProfilesTrackingModal }
