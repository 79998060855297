import styled from 'styled-components'
import { Button, Flex, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin-top: 44px;
`

export const EmailSetupRemoveInboxModalFooter = ({ loading, handleRemove, handleClose }) => {
  const { t } = useTranslate()
  return (
    <StyledFlex>
      <StateButton showLoader color='danger' loading={loading} onClick={handleRemove}>{t('settings.yourPreferences.communication.email.configuration.modal.removeInbox.button.yesRemoveInbox')}</StateButton>
      <Button disabled={loading} color='secondary' variant='flat' onClick={handleClose}>{t('commons.button.cancel')}</Button>
    </StyledFlex>
  )
}
