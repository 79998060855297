import { gql } from '@apollo/client'

export const getTablesConfig = gql`
 query getProductTablesConfigByBrand(
  $brandId: String!,
  $product: ProductOptions!, 
  $type: ProductTableTypeOptions!, 
 ) {
  getProductTablesConfigByBrand(brandId: $brandId, product: $product, type: $type,) {
    columns
  }
 }
`

export const updateTableConfigMutation = gql`
 mutation updateProductTableConfig(
   $brandId: String!,
   $product: ProductOptions!, 
   $type: ProductTableTypeOptions!, 
   $value: [String]
 ) {
 updateProductTableConfig(brandId: $brandId, product: $product, type: $type, value: $value)
}
`
