import { gql } from '@apollo/client'

export const getIrmInfluencerColumns = gql`
query getIrmInfluencerColumns(
  $brandId: String
  $view: String
  $sharedId: String
) {
  getIrmInfluencerColumns(
    brandId: $brandId
    view: $view
    sharedId: $sharedId
  ) {
      label
      fieldName
      columnType
      type
  }
}
 `



