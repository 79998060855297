import { gql } from '@apollo/client'

export const generateInvoice = gql`
  mutation generateInvoice(
    $_id: String!
  ) {
    generateInvoice(
      _id: $_id
    ) {
      message
      success
    }
  }
`
