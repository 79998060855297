import { useContext, useState } from 'react'
import { Button, Text, Flex, Modal, Spacing, StateButton, RadioButtonV2 } from 'influ-dms'
import { deleteReportV2Tracking } from '../../apollo/tracking/deleteReportV2Tracking'
import { useMutation } from '@apollo/client'
import { ReportsContext } from '../../context/ReportsContext'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'
import { captureException } from '@sentry/nextjs'

const KEEP_PUBLICATIONS_VALUE = 'keepPublications'
const DELETE_PUBLICATIONS_VALUE = 'deletePublications'

const ReportsTrackingModalDelete = (props) => {
  const { t } = useTranslate()
  const router = useRouter()
  const reportV2Id = get(router, 'query.reportId')
  const brandId = get(router, 'query.brandId')

  const { isOpen, onClose, subtitle, trackingId } = props
  const [deleteTrackingMutation, { loading }] = useMutation(deleteReportV2Tracking)

  const { setReportsAction } = useContext(ReportsContext)

  const [deleteOption, setDeleteOption] = useState(KEEP_PUBLICATIONS_VALUE)

  const handleSelectOption = e => {
    const { value } = e.target
    setDeleteOption(value)
  }

  const handleDeleteTracking = () => {
    const variables = {
      brandId,
      reportV2Id,
      trackingIds: [trackingId],
      deletePublications: (deleteOption === DELETE_PUBLICATIONS_VALUE)
    }
    deleteTrackingMutation({ variables })
      .then(data => {
        setReportsAction({ action: 'deleteTracking', data })
        if (!get(data, 'data.deleteReportV2Tracking.success')) {
          captureException('Tracking handleDeleteTracking no success', variables)
        }
        onClose()
      })
      .catch((err) => {
        captureException('Tracking handleDeleteTracking error', err)
      })
  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t('reports.modal.deteleTracking.header')}
        subtitle={subtitle}
        minHeight='100'
        color='state.dangerDefault'
      >
        <Flex column grow='1'>
          <Text size='15' color='primary.dark' bold>{t('reports.modal.deteleTracking.titleHeader1')}</Text>
          <Spacing size='8' />
          <Text size='14' color='primary.light3'>{t('reports.modal.deteleTracking.titleParagraph1')}</Text>
          <Spacing size='16' />
          <Text size='15' color='primary.dark' bold>{t('reports.modal.deteleTracking.titleHeader2')}</Text>
          <Spacing size='8' />
          <RadioButtonV2
            name='keepPublications'
            value={KEEP_PUBLICATIONS_VALUE}
            checked={deleteOption === KEEP_PUBLICATIONS_VALUE}
            onChange={handleSelectOption}
          >
            <Spacing vertical size='3' />
            <Text size='14' color='primary.light2'>
              {t('reports.modal.deteleTracking.listItem1.label')}
            </Text>
          </RadioButtonV2>

          <RadioButtonV2
            name='deletePublications'
            value={DELETE_PUBLICATIONS_VALUE}
            checked={deleteOption === DELETE_PUBLICATIONS_VALUE}
            onChange={handleSelectOption}
          >
            <Spacing vertical size='3' />
            <Text size='14' color='primary.light2'>
              {t('reports.modal.deteleTracking.listItem2.label')}
            </Text>
          </RadioButtonV2>

          <Spacing size='40' />
        </Flex>
        <Flex>
          <StateButton
            showLoader
            loading={loading}
            isDisabled={loading}
            color='danger'
            onClick={handleDeleteTracking}
          >{t('commons.button.deleteTracking')}
          </StateButton>
          <Spacing vertical size='8' />
          <Button variant='flat' color='secondary' onClick={() => onClose(true)}>{t('commons.button.cancel')}</Button>
        </Flex>
      </Modal>
    </>
  )
}

export { ReportsTrackingModalDelete }
