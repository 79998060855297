import { gql } from '@apollo/client'

export const getEntityFieldsByEntity = gql`
  query getEntityFieldsByEntity ( 
    $brandId: String!
    $entityNames: [String]!
    $scope: String
  ) {
    getEntityFieldsByEntity(brandId: $brandId, entityNames: $entityNames, scope: $scope) {
      entityName
      incompatibleEntities
      entityFieldGroups {
        groupName
        order
        translations {
          es {
            entityName
            groupName
          }
          en {
            entityName
            groupName
          }
          de {
            entityName
            groupName
          }
          fr {
            entityName
            groupName
          }
          it {
            entityName
            groupName
          }
          pt {
            entityName
            groupName
          }
        }
        entityFields {
          _id
          entityName
          fieldName
          order
          translations {
            es {
              entityName
              fieldName
            }
            en {
              entityName
              fieldName
            }
            de {
              entityName
              fieldName
            }
            fr {
              entityName
              fieldName
            }
            it {
              entityName
              fieldName
            }
            pt {
              entityName
              fieldName
            }
          }
        }
      }
    }
  }
`
