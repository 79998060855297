import { gql } from '@apollo/client'

export const editEntityFieldGroup = gql`
  mutation editEntityFieldGroup(
    $_id: String!
    $groupName: String!
    $groupDescription: String
  ) {
    editEntityFieldGroup(
      _id: $_id
      groupName: $groupName
      groupDescription: $groupDescription
    ) {
      message
      success
    }
  }
`
