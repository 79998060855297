import { gql } from '@apollo/client'

export const editDescription = gql`
  mutation editDescription(
    $paymentInvoiceId: String!
    $paymentRequestIds: [String]
    $description: String
  ) {
    editDescription(
      paymentInvoiceId: $paymentInvoiceId
      paymentRequestIds: $paymentRequestIds
      description: $description
    ){
      message
      code
      success
      data
    }
  }
`
