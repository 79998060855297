import { css } from 'styled-components'
import { Flex, Text, Spacing } from 'influ-dms'
import { SectionHeader } from './SectionHeader'
import { SectionTitle } from './SectionTitle'
import { useTranslate } from '~/hooks/useTranslate'

const menuStyles = css`
  width: 260px;
  padding: 16px 24px 32px 24px;
  height: auto;
  overflow: auto;
`

export const SideMenu = ({ children }) => {
  const { t } = useTranslate()

  return (
    <Flex grow='1' styles={{ height: '100%' }}>
      <Flex styles={[menuStyles]} column color='neutral.white'>
        <Text styles={{ margin: '8px 11px' }} size='16' color='gray'>{t('settings.leftSidebar.main.title')}</Text>
        <Spacing />
        <Spacing withLine />
        <SectionHeader title={t('settings.leftSidebar.yourPreferences.title')} />
        <SectionTitle title={t('setting.leftSidebar.yourPreferences.general.link')} pageName='basicInfo' />
        <SectionTitle title={t('settings.leftSidebar.yourPreferences.security.link')} pageName='security' />
        <SectionTitle title={t('settings.leftSidebar.yourPreferences.communication.link')} pageName='communication' />
        <Spacing />
        <Spacing withLine />
        <SectionHeader title={t('settings.leftSidebar.account.accountDefault')} />
        <SectionTitle title={t('settings.leftSidebar.account.accountDefault.link')} pageName='account' />
        <SectionTitle title={t('settings.leftSidebar.account.productAndBilling.link')} pageName='productsAndBilling' />
        <SectionTitle title={t('setting.leftSidebar.account.branding.link')} pageName='branding' />
        <SectionTitle title={t('setting.leftSidebar.account.users.link')} pageName='users' />
        <SectionTitle title={t('setting.leftSidebar.account.domains.link')} pageName='domains' />
        <Spacing />
        <Spacing withLine />
        <SectionHeader title={t('settings.leftSidebar.dataManagement.title')} />
        <SectionTitle title={t('settings.leftSidebar.dataManagement.views.link')} pageName='views' />
        <SectionTitle title={t('settings.leftSidebar.dataManagement.properties.link')} pageName='properties' />
        <Spacing />
        <Spacing withLine />
        <SectionHeader title={t('settings.leftSidebar.tools.title')} />
        <SectionTitle title={t('settings.leftSidebar.tools.integrations.link')} pageName='integrations' />
      </Flex>
      {children}
    </Flex>
  )
}
