import { gql } from "@apollo/client";

export const updateProfileServiceCost = gql`
  query updateProfileServiceCost($brandId: String!, $profileIds: [String]!) {
    updateProfileServiceCost(brandId: $brandId, profileIds: $profileIds) {
      analysisToCharge
      profileIds
      analysisByNetwork {
        type
        count
      }
    }
  }
` 