import { gql } from '@apollo/client'

export const calculateInfluencersMassiveAssociationInfoFromIrm = gql`
  query calculateInfluencersMassiveAssociationInfoFromIrm( 
    $profileIds: [String]!
  ) {
    calculateInfluencersMassiveAssociationInfoFromIrm(
      profileIds: $profileIds
    ) {
      total
      profilesToAssociate
      cannotBeAssociated
    }
  }
`
