import { gql } from '@apollo/client'

export const calculateInfluencersMassiveAssociationInfoFromDiscover = gql`
  query calculateInfluencersMassiveAssociationInfoFromDiscover( 
    $profiles: [String]!
    $network: String!
  ) {
    calculateInfluencersMassiveAssociationInfoFromDiscover(
      profiles: $profiles
      network: $network
    ) {
      total
      profilesToAssociate
      cannotBeAssociated
    }
  }
`
