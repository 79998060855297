import { gql } from "@apollo/client";

export const deleteProgramV2 = gql`
  mutation deleteProgramV2($programIds: [String]!) {
    deleteProgramV2(programIds: $programIds) {
      message
      code
      success
    }
  }
`