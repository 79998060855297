import { useContext, useEffect } from 'react'
import { Button, FormContext, SideModalFooter, Text } from 'influ-dms'
import { isFormEmpty } from './addPostsHelper'
import { useTranslate } from '~/hooks/useTranslate'
import { css } from 'styled-components'

const marginStyles = css`
  :not(:last-child) {
    margin-right: 16px;
  }
`

export const AddPostsModalFooter = ({ isOpen, error, handleCreate, handleClose, loading }) => {
  const { t } = useTranslate()
  const { values, cleanForm } = useContext(FormContext)
  const onCreate = () => handleCreate(values, false)
  const onAnother = () => handleCreate(values, true)
  const isEmpty = isFormEmpty(values) || loading
  const disabled = isEmpty || !!error

  useEffect(() => {
    if (!isOpen) {
      cleanForm()
    }
  }, [isOpen])

  return (
    <SideModalFooter>
      <Button name='addPosts' disabled={disabled} onClick={onCreate} styles={marginStyles}>{t('commons.button.addPublication')}</Button>
      <Button name='saveAndAddOtherPosts' disabled={disabled} variant='outline' onClick={onAnother} styles={marginStyles}>{t('commons.button.saveAndAddAnotherPublication')}</Button>
      <Button name='cancelAddPost' disabled={loading} variant='flat' color='secondary' onClick={handleClose} styles={marginStyles}>{t('commons.button.cancel')}</Button>
      {error && error.showInFooter && <Text color='app.default'>{error.text}</Text>}
    </SideModalFooter>
  )
}
