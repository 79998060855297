import { useContext } from 'react'
import { get } from 'lodash'
import {
  ModalsContext
} from '../context'
import { useRouter } from 'next/router'
import {
  AddPosts,
  CreateReport,
  DeletePost,
  DeletePosts,
  DeleteReport,
  DeleteReports,
  EditPost,
  EditReportsInfluencerCost
} from '../../reports/commons'
import { MultiBrandModal } from '../../multibrand'
import { EmailSetupModal } from '../communication/setup/EmailSetupModal'
import { EmailSetupUpdatePasswordModal } from '../../settings/preferences/communication/emails/header/actions/updatePassword/EmailSetupUpdatePasswordModal'
import { EmailSetupDisableInboxModal } from '../../settings/preferences/communication/emails/header/actions/disableInbox/EmailSetupDisableInboxModal'
import { EmailSetupRemoveInboxModal } from '../../settings/preferences/communication/emails/header/actions/removeInbox/EmailSetupRemoveInboxModal'
import { EmailSetupEnableInboxModal } from '../../settings/preferences/communication/emails/header/actions/enableInbox/EmailSetupEnableInboxModal'
import { ConnectInboxModal } from '../communication/setup/connectInbox/ConnectInboxModal'
import { NewEmailModal } from '../communication/setup/newEmail/NewEmailModal'
import { EditTrackingModal } from '../../reports/commons/addPosts/tracking/EditTrackingModal'
import { PreviewPublicationModal } from '../../reports/commons/previewPublication/PreviewPublicationModal'
import { SendMassiveEmailModal } from '../../irm/influencers/commons/modals/SendMassiveEmailModals'

const Modals = () => {
  const { state, dispatch } = useContext(ModalsContext)
  const reportId = get(state, 'reportId')
  const reportIds = get(state, 'reportIds')
  const postIds = get(state, 'postIds')
  const postId = get(state, 'postId')
  const numberOfPosts = get(state, 'numberOfPosts')
  const selectedSection = get(state, 'selectedSection')
  const profileId = get(state, 'profileId')
  const emailAccount = get(state, 'emailAccount')
  const email = get(state, 'email')
  const influencers = get(state, 'influencers')

  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const {
    showCreateReport,
    showDeleteReport,
    showDeleteReports,
    showAddPosts,
    showEditPost,
    showDeletePost,
    showDeletePosts,
    showEditReportsInfluencer,
    showPreviewPost,
    showSendEmailIrmInfluencer,
    isMultiBrandOpen,
    isConnectInboxOpen,
    isNewEmailOpen,
    isEmailSetupOpen,
    isEmailPasswordOpen,
    isEmailEnableOpen,
    isEmailDisableOpen,
    isEmailDeleteOpen,
    isEditTrackingOpen,
    trackingData
  } = state

  if (!brandId) return null

  return (
    <>
      <CreateReport
        isOpen={showCreateReport}
        onClose={() => dispatch({ type: 'toggleCreateReport' })}
      />
      <DeleteReport
        isOpen={showDeleteReport}
        onClose={() => dispatch({ type: 'toggleDeleteReport' })}
        reportId={reportId}
      />
      <DeleteReports
        isOpen={showDeleteReports}
        onClose={() => dispatch({ type: 'toggleDeleteReports' })}
        reportIds={reportIds}
      />
      <AddPosts
        isOpen={showAddPosts}
        onClose={() => dispatch({ type: 'toggleAddPosts' })}
        numberOfPosts={numberOfPosts}
        selectedSection={selectedSection}
      />
      <EditPost
        isOpen={showEditPost}
        onClose={() => dispatch({ type: 'closeEditPost' })}
        postId={postId}
      />
      <DeletePost
        isOpen={showDeletePost}
        onSuccess={() => dispatch({ type: 'closeEditPost' })}
        onClose={() => dispatch({ type: 'toggleDeletePost', payload: postId })}
        postId={postId}
      />
      <DeletePosts
        isOpen={showDeletePosts}
        onClose={() => dispatch({ type: 'toggleDeletePosts' })}
        postIds={postIds}
      />
      <EditReportsInfluencerCost
        isOpen={showEditReportsInfluencer}
        onClose={() => dispatch({ type: 'closeEditReportsInfluencer' })}
        profileId={profileId}
      />
      <MultiBrandModal
        isOpen={isMultiBrandOpen}
        onClose={() => dispatch({ type: 'toggleMultiBrand' })}
      />
      <SendMassiveEmailModal
        isOpen={showSendEmailIrmInfluencer}
        onClose={() => dispatch({
          type: 'toggleSendEmailIrmInfluencer',
          influencers
        })}
      />
      <ConnectInboxModal
        isOpen={isConnectInboxOpen}
        onClose={() => dispatch({ type: 'toggleConnectInbox' })}
      />
      <NewEmailModal
        email={email}
        isOpen={isNewEmailOpen}
        onClose={() => dispatch({ type: 'toggleNewEmail' })}
      />
      <EmailSetupModal
        isOpen={isEmailSetupOpen}
        onClose={() => dispatch({ type: 'toggleEmailSetup' })}
      />
      <EmailSetupUpdatePasswordModal
        isOpen={isEmailPasswordOpen}
        onClose={() => dispatch({ type: 'toggleEmailPassword' })}
      />
      <EmailSetupEnableInboxModal
        isOpen={isEmailEnableOpen}
        onClose={() => dispatch({ type: 'toggleEmailEnable' })}
      />
      <EmailSetupDisableInboxModal
        isOpen={isEmailDisableOpen}
        onClose={() => dispatch({ type: 'toggleEmailDisable' })}
      />
      <EmailSetupRemoveInboxModal
        emailAccount={emailAccount}
        isOpen={isEmailDeleteOpen}
        onClose={() => dispatch({ type: 'toggleEmailDelete' })}
      />
      <EditTrackingModal
        isEditTrackingOpen={isEditTrackingOpen}
        trackingData={trackingData}
        onClose={() => dispatch({ type: 'toggleEditTracking', payload: {} })}
      />
      {postId && <PreviewPublicationModal
        isOpen={showPreviewPost}
        onClose={() => dispatch({ type: 'closePreviewPost' })}
        postId={postId}
      />}
    </>
  )
}

export { Modals }
