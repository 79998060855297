import { Text, Flex, Spacing, AccordionHeader, AccordionContent, Icon, AccordionContext } from 'influ-dms'
import { isEmpty } from 'lodash'
import { useContext, useEffect } from 'react'
import styled from 'styled-components'

const StyledTag = styled('div')`
  white-space: nowrap;
  border-radius: 4px;
  height: 20px;
  padding: 3px 4px 1px 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary.light5}
`

const FlexAccordion = styled(Flex)`
  margin: 16px 0px 4px;
`

export const ReportsTrackingCardAccordion = ({ tracking, icon, title, data }) => {
  const { setOpen } = useContext(AccordionContext)
  useEffect(() => {
    setOpen(false)
  }, [tracking])
  return (
    <>
      <AccordionHeader color='primary.light3' arrowPosition='right'>
        <Flex>
          <Icon name={icon} color='primary.light3' size='14' />
          <Spacing vertical size='8' />
          <Text color='primary.light3' size='12'>
            {title}
          </Text>
          {/*
          <Spacing vertical size='8' />
          <TagTitleWrapper>
            <Spacing vertical size='6' />
            <Text bold size='12' color='primary.light3'>
              {get(data, 'length', 0)}
            </Text>
            <Spacing vertical size='6' />
          </TagTitleWrapper>
          */}
        </Flex>
      </AccordionHeader>
      <AccordionContent>
        <FlexAccordion wrap='wrap'>
          {!isEmpty(data) && data.map((item, i) =>
            <StyledTag key={i}>
              <Text color='primary.dark' size='12'>{item}</Text>
            </StyledTag>
          )}
        </FlexAccordion>
      </AccordionContent>
    </>
  )
}
