import { useState } from 'react'
import styled, { css } from 'styled-components'
import { Flex, Text, Icon } from 'influ-dms'

const StyledTag = styled(Flex)`
  margin: 2px 0;
  padding: 4px 8px;
  border-radius: 16px;
  word-break: break-all;
  width: fit-content;
  min-width: 240px;
  background: ${({ theme, isValid, loading }) => isValid || loading ? theme.colors.primary.light5 : theme.colors.state.dangerDefault};
  :hover {
    background: ${({ theme, isValid, loading }) => isValid || loading ? theme.colors.primary.light3 : theme.colors.state.dangerHover};
  }
  ${({ styles }) => styles || ''};
`
const StyledIcon = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
`
const spinRefreshStyles = css`
    @keyframes spin-refresh {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  animation: spin-refresh 1.7s linear infinite;
`

export const Tag = ({ url: { value, isValid, isDuplicated, loading = false }, handleClose, styles }) => {
  const [hoverIcon, setHoverIcon] = useState(false)
  const isUrlValid = isValid && !isDuplicated
  const onClose = () => {
    handleClose(value)
  }

  const onHover = () => setHoverIcon(true)

  const onOut = () => hoverIcon && setHoverIcon(false)

  return (
    <StyledTag justify='space-between' isValid={isUrlValid} loading={loading} styles={styles} onMouseOver={onHover} onMouseOut={onOut}>
      <Text color={isUrlValid || loading ? 'primary.dark' : 'neutral.white'}>{value}</Text>
      <StyledIcon onClick={!loading ? onClose : ''}>
        {loading ? <Icon name='refresh' styles={spinRefreshStyles} /> : (
          isUrlValid && !hoverIcon
            ? <Icon name='radiochecked' color='state.active' />
            : <Icon color='neutral.white' name='close' />
        )}
      </StyledIcon>
    </StyledTag>
  )
}
