import { useTranslate } from '~/hooks/useTranslate'
import { LOCALES_TRANSLATED, useLanguageContext } from '~/context/Language'
import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { ExportModal, Flex, Portal, SnackbarContext, Spacing, Text } from 'influ-dms'
import { get } from 'lodash'
import { exportEndpoint } from '~/graphql/commons/ExportMutation'
import { contact } from '~/graphql/contact'
import { ExportButton } from '../../commons/components/ActionButtons/ExportButton'
import styled from 'styled-components'
import { roundedButtonStyles } from './styles'
import { userBrandSubscription } from '../../../../apollo/subscriptions'
import { captureException } from '@sentry/nextjs'

const TitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: 14px;
  font-weight: 500;
`
const SubTitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary.light2};
  font-size: 14px;
`
const Divider = styled.hr`
  border: 0.5px solid ${({ theme }) => theme.colors.primary.light5};
  background: ${({ theme }) => theme.colors.primary.light5};
  width: 100%;
`

const SuccessDownloadLink = styled.a`
  color: ${({ theme }) => theme.colors.state.active};
  font-weight: 500;
`

const ListDetailExport = (props) => {
  const SINGLE_ROW = 1
  const columns = ['all']
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { brandId, canExport, domain, query, listName, styles } = props
  const [getExport] = useMutation(exportEndpoint)
  const { showSnackbar } = useContext(SnackbarContext)
  const { data: dataContact } = useQuery(contact, { skip: !brandId })
  const email = get(dataContact, 'self.email', '')
  const userId = get(dataContact, 'self.id', '')
  const { data: userBrandSubscriptionData } = useSubscription(userBrandSubscription, { variables: { userId, brandId }, skip: !brandId })

  const languageOptions = Object.entries(LOCALES_TRANSLATED).map(([key, value]) => ({ label: t(value), value: key }))
  const languageDefault = languageOptions.find(option => option.value === locale)
  const staticTexts = {
    fileFormat: {
      title: t('modal.export.fileFormat.input.label'),
      placeholder: t('modal.export.fileFormat.input.placeholder')
    },
    columnsExport: {
      title: t('modal.export.columnsIncludedInExport.titleHeader1'),
      options: [t('modal.export.columnsIncludedInExport.listItem.onlyColumnsInView.label'), t('modal.export.columnsIncludedInExport.listItem.allColumnsOnRecord.label')]
    },
    languageExport: {
      title: t('modal.export.selectLanguage.input.label'),
      options: languageOptions,
      placeholder: t('modal.export.selectLanguage.input.placeholder')
    },
    submitExportText: t('commons.button.export'),
    cancelButtonText: t('commons.button.cancel')
  }

  useEffect(() => {
    if (
      userBrandSubscriptionData &&
      userBrandSubscriptionData?.userBrandSubscription &&
      userBrandSubscriptionData?.userBrandSubscription?.domain === 'notifyExportedFileAlreadyGenerated' &&
      userBrandSubscriptionData?.userBrandSubscription?.brandId === brandId &&
      userBrandSubscriptionData?.userBrandSubscription?.userId === userId
    ) {
      const downloadUrl = get(userBrandSubscriptionData, 'userBrandSubscription.message.downloadUrl')
      const exportName = get(userBrandSubscriptionData, 'userBrandSubscription.message.exportName')

      if (downloadUrl) {
        showSnackbar(
          'success',
          t('snackbar.export.success', { message: `${exportName}` }),
          true,
          <SuccessDownloadLink href={ downloadUrl } target='_blank' rel='noreferrer noopener nofollow'>{t('commons.button.clickToDownload')}</SuccessDownloadLink>,
          10000
        )
      }
    }
  }, [userBrandSubscriptionData])

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const submitExportOptions = async (variables, dispatch) => {
    const { fileType, domain, locale } = variables
    setLoading(true)
    getExport({
      variables: {
        fileType,
        brandId,
        domain,
        data: JSON.stringify(query),
        locale,
        exportName: 'list_detail',
        timezone: new window.Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
      }
    }).then((response) => {
      const success = get(response, 'data.export.success', false)
      success
        ? showSnackbar('info', t('snackbar.export.processing'), true, <></>, 10000)
        : showSnackbar('error', t('snackbar.export.error'), true, <></>, 10000)
    }).catch((error) => {
      captureException({ mutation: 'export', brandId, listId: query.listId, error })
      showSnackbar('error', t('snackbar.export.error'), true, <></>, 10000)
    }).finally(() => {
      setLoading(false)
      setIsModalOpen(false)
    })
  }

  return (
    <>
      <ExportButton
        loading={loading}
        canExport={canExport}
        getFile={() => setIsModalOpen(true)}
        color={'primary.light2'}
        size={20}
        styles={styles || roundedButtonStyles}
      />
      <Portal selector='#exportModal'>
        <ExportModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalTitle={t('modal.export.header')}
          submitExportOptions={submitExportOptions}
          staticTexts={staticTexts}
          language={languageDefault}
          rows={SINGLE_ROW}
          currentColumns={columns}
          allColumns={columns}
          domain={domain}
          hasRadioButtons={false}
        >
          <Flex column>
            <Flex gap='4px' column>
              <TitleText>{t('modal.export.sendTo.titleHeader1')}</TitleText>
              <Flex>
                <SubTitleText>{t('modal.export.sendTo.titleParagraph1', { email: (<SubTitleText bold variant='span' >{email}</SubTitleText>) })}</SubTitleText>
              </Flex>
            </Flex>
            <Divider />
            <Spacing size='20'/>
            <Flex>
              <TitleText>{t('modal.export.prepareExportFile.titleHeader1')}</TitleText>
            </Flex>
            <Flex>
              <SubTitleText>{t('modal.export.exporting.titleParagraph1')}&nbsp;</SubTitleText>
              <SubTitleText bold>{listName}</SubTitleText>
            </Flex>
            <Spacing size='20'/>
          </Flex>
        </ExportModal>
      </Portal>
    </>
  )
}

export { ListDetailExport }
