import { gql } from '@apollo/client'

export const countProgramsV2ByBrand = gql`
    query countProgramsV2ByBrand(
      $brandId: String!,
      $filters: [NewFilterParamsType],
      $view: String!
    ) {
      countProgramsV2ByBrand(
        brandId: $brandId,
        filters: $filters,
        view: $view
      )
    }
`
