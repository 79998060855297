import { useApolloClient, useQuery } from '@apollo/client'
import { get, isEmpty } from 'lodash'
import { USE_ARRAY_ACTIONS } from '~/hooks'
import { useContext } from 'react'
import { NotifyIrmInfluencerDataProcessedContext } from '../../../../context/irm/influencers/NotifyIrmInfluencerDataProcessed'
import { getIRMInfluencer } from '../../influencers/commons/apollo/getIRMInfluencer'
import { irmInfluencerBySharedLink } from '../../influencers/commons/apollo/irmInfluencerBySharedLink'
import { useCleanCache } from './useCleanCache'

export const useQueryInfluencer = ({ variables = {}, skip, fetchPolicy = 'cache-first' }) => {
  const { influencerId, sharedId } = variables // { influencerId, sharedId, type }
  const { influencersDone, setInfluencersDone } = useContext(NotifyIrmInfluencerDataProcessedContext)
  const { clearInfluencerDetailCache } = useCleanCache()

  const client = useApolloClient()
  const query = (influencerId && !sharedId) ? getIRMInfluencer : irmInfluencerBySharedLink

  const influencerCache = client.readQuery({ query, variables })
  const { data, loading, error } = useQuery(query, { variables, fetchPolicy, skip, })
  const resultData = get(data, 'getIRMInfluencer') || get(data, 'irmInfluencerBySharedLink')

  if (influencersDone.includes(influencerId) && !isEmpty(influencerCache)) {
    clearInfluencerDetailCache(influencerId)
    setInfluencersDone(influencerId, USE_ARRAY_ACTIONS.DELETE)
  }
  return { data: resultData, loading, error }
}
