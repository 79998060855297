import styled, { css } from 'styled-components'
import { shadowLight } from '~/theme/styles'
import { Flex } from 'influ-dms'

export const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const StyledContainer = styled(Flex)`
  padding: 0 24px;
  ${cellEllipsis};
`

export const StyledFlex = styled(Flex)`
  width : 97%;
`

export const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

export const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

export const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out; 
    text-decoration: underline;
  }
`

export const DomainsTableContainerStyledComponent = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 16px 16px 24px 16px;
  flex-direction: column;
`

export const hoverStyles = css`
  :hover {
    > div {
      background: ${({ theme }) => theme.colors.primary.light6} !important;
    }
  }
`

export const loaderStyles = css`
  display: flex;
  padding: 64px;
  align-items: center;
  justify-content: center;
`

export const cardWidth = css`
  width: calc(100% - 4px);
`

export const Divider = styled.hr`
  border: 0.5px solid #ededf4;
  background: #ededf4;
  width: 100%;
`

export const cardStyles = css`
  width: 100%;
`

export const cardContentStyles = css`
  align-items: flex-start;
  padding: 0 16px 16px 16px;
`

export const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutral.white};
  padding: 10px 8px;
  width: 100%;
  width: 100%;
  height: fit-content;
  ${shadowLight}
  ${({ styles }) => styles || ''};
`

export const textCellStyles = css`
  padding: 16px 24px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  white-space: normal;
  word-break: break-word;
`

export const bodyStyles = css`
  > div {
    height: auto;
    box-sizing: content-box !important;
    :not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.state.default};
    }
  }
`

export const scrollHiddenStyles = css`
  overflow: hidden;
`

export const flexStyles = css`
  padding: 16px 24px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`

export const textStyles = css`
  white-space: normal;
  word-break: break-word;
`
