import { gql } from '@apollo/client'

export const deleteInfluencerNoteQuery = gql`
  mutation deleteInfluencerNote($_id: String!) {
    deleteInfluencerNote(_id: $_id) {
      message
      success
    }
  }
`
