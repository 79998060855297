import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { Date, Flex, Icon, InfluDateFormat, Loader, Modal, Spacing, Text } from 'influ-dms'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { postQuery } from '../../apollo/postQuery'
import { PreviewCarrousel } from './PreviewCarrousel'
import { PreviewPublicationYoutube } from './PreviewPublicationYoutube'
import { imagePostStyles } from '../../commons/reportsTable/styles'
import { dataSourceMap, postTypeMap } from '../../posts/table/ReportsPostsTable'
import { useTranslate } from '~/hooks/useTranslate'

const VerticalLine = styled('span')`
  border-left: 1px solid ${({ theme }) => theme.colors.primary.dark};
  margin: 0px 2px;
  height: 17px;
`

const StyledFlex = styled(Flex)`
  align-self: flex-start;
`

const loaderStyles = css`
  align-self: center;
`

const ImageContainer = styled.div`
  width: 32px;
  height: 32px;
  ${imagePostStyles};
  border-radius: 50%;
`

const StyledFlexAvatar = styled(Flex)`
  margin: 12px 0px;
  align-self: flex-start;
`

const modalStyles = css`
  padding: 0px 32px 16px 32px;
`

export const PreviewPublicationModal = ({ postId, isOpen, onClose }) => {
  const { t } = useTranslate()
  const { colors: { gradients } } = useContext(ThemeContext)
  const variables = { postId }
  const { data, loading } = useQuery(postQuery, { variables, fetchPolicy: 'network-only', skip: !isOpen })
  const post = get(data, 'getReportsV2Publication', {})
  const dataSource = get(post, 'dataSource')
  const isYoutubeManual = post.network === 'youtube' && (dataSource === 'manual' || dataSource === 'manualNoAudienceData')

  return (
    <Modal
      isOpen={isOpen}
      title={t('reports.report.modal.publicationPreview.header')}
      minHeight='450px'
      onClose={onClose}
      gradient1={gradients[1][0]}
      gradient2={gradients[1][1]}
      styles={modalStyles}
    >
      {loading && <Loader styles={loaderStyles} fillspace />}
      {!loading &&
        <Flex column justify='center' align='center'>
          <StyledFlexAvatar align='center'>
            <ImageContainer image={post.profilePicture} />
            <Text color='primary.dark' bold size='13' ellipsis>@{post.username}</Text>
          </StyledFlexAvatar>
          {post.network === 'youtube' && !isYoutubeManual && <PreviewPublicationYoutube youtubeShortcode={get(post, 'shortcode', '')} />}
          {(post.network === 'instagram' || post.network === 'tiktok' || isYoutubeManual) && <PreviewCarrousel mediaUrls={post.media} type={get(post, 'type')} />}
          <Spacing size='10' />
          <StyledFlex column>
            <Flex align='center'>
              <Icon size='15' name={post.network} />
              <VerticalLine />
              <Text color='primary.dark' bold size='15'>{postTypeMap(t)[post.type]}</Text>
            </Flex>
            <Text size='14' color='primary.light3'>{dataSourceMap(t)[post.dataSource]}</Text>
            <Spacing size='12' />
            <Text size='14' color='primary.light3'>{post.caption}</Text>
            <Spacing size='12' />
            <Text size='14' color='primary.light3'><Date format={InfluDateFormat().L}>{post.publicationDate}</Date></Text>
          </StyledFlex>
          <Spacing size='16' />
        </Flex>}
    </Modal>
  )
}
