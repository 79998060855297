import { gql } from '@apollo/client'

export const verifyDomain = gql`
    mutation verifyDomain(
        $_id: String!
    ) {
        verifyDomain(
            _id: $_id
        ){
            message
            data
            success
        }
    }
`
