import { Text, Flex, Date, Spacing, Accordion, Icon, Button, Dropdown, DropdownOptionItem, StateButton } from 'influ-dms'
import { get } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import appTheme from '~/theme/themeConstructor'
import { CardContainer, cardStyles } from '../styles'
import { ReportsTrackingModalPause } from './ReportsTrackingModalPause'
import { ReportsTrackingModalDelete } from './ReportsTrackingModalDelete'
import { ReportsTrackingModalLimit } from './ReportsTrackingModalLimit'
import { useMutation } from '@apollo/client'
import { setStatusReportV2Tracking } from '../../apollo/tracking/setStatusReportV2Tracking'
import { ReportsContext } from '../../context/ReportsContext'
import { useRouter } from 'next/router'
import { ModalsContext } from '../../../commons/context'
import { ReportsTrackingCardAccordion } from './ReportsTrackingCardAccordion'
import { useTranslate } from '~/hooks/useTranslate'
import { captureException } from '@sentry/nextjs'

const { colors } = appTheme({})

const StatusDot = styled('span')`
  height: 12px;
  width: 12px;
  background-color: ${({ colorStatus }) => colorStatus};
  border-radius: 50%;
  margin-left: 8px;
`

const VerticalLine = styled('span')`
  border-left: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding: 0px 6px;
  margin: 0px 6px;
  height: 17px;
`

export const statusData = {
  readyToStart: { color: colors.state.blocked, label: 'reports.publicationsAndData.dataSource.trackings.tracking.status.readyToStart.label' },
  inProgress: { color: colors.state.active, label: 'reports.publicationsAndData.dataSource.trackings.tracking.status.inProgress.label' },
  paused: { color: colors.state.default, label: 'reports.publicationsAndData.dataSource.trackings.tracking.status.paused.label' },
  finished: { color: colors.state.done, label: 'reports.publicationsAndData.dataSource.trackings.tracking.status.finished.label' },
  error: { color: colors.state.dangerDefault, label: 'reports.publicationsAndData.dataSource.trackings.tracking.status.error' }
}

export const ReportsTrackingCard = ({ tracking, isTrackingLimitExceeded, loadingTrackings, setModalEditOpen }) => {
  const { t } = useTranslate()
  const [isLoading, setIsLoading] = useState()
  const [isOpenActions, setOpenActions] = useState()
  const [isOpenModalPause, setIsOpenModalPause] = useState()
  const [isOpenModalDelete, setIsOpenModalDelete] = useState()
  const [isOpenModalLimit, setIsOpenModalLimit] = useState()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const reportV2Id = get(router, 'query.reportId')

  const { setReportsAction } = useContext(ReportsContext)
  const [setStatusTrackingMutation] = useMutation(setStatusReportV2Tracking)
  const { dispatch } = useContext(ModalsContext)

  const {
    _id,
    createdAt,
    updatedAt,
    name,
    hashtags,
    mentions,
    keywords,
    startDate,
    endDate,
    status,
    // resumeDate,
    influencers
  } = tracking

  const influncersWithoutApiAccess = influencers.reduce((n, influencer) => (influencer.apiAccess === false || influencer.apiAccess === null) ? n + 1 : n, 0)

  const canResumeStatus = status === 'paused'
  const canPauseStatus = status === 'readyToStart' || status === 'inProgress'

  useEffect(() => {
    if (isLoading) setIsLoading(loadingTrackings)
  }, [loadingTrackings])

  const handleChangeStatusMutation = (trackingId, newStatus) => {
    setIsLoading(true)
    const variables = {
      brandId,
      reportV2Id,
      trackingIds: [trackingId],
      status: newStatus
    }
    setStatusTrackingMutation({ variables })
      .then(data => {
        setReportsAction({ action: 'setStatusTracking', data })
        if (!get(data, 'data.setStatusReportV2Tracking.success')) {
          captureException('Tracking handleChangeStatusMutation no success', variables)
        }
      })
      .catch((err) => {
        if (err.message === '12110') {
          setIsOpenModalLimit(true)
        }
        setIsLoading(false)
      })
  }

  const handleChangeStatus = () => {
    if (canResumeStatus && isTrackingLimitExceeded) {
      setIsOpenModalLimit(true)
    } else if (canResumeStatus) {
      handleChangeStatusMutation(_id, 'readyToStart')
    } else if (canPauseStatus) {
      setIsOpenModalPause(true)
      setReportsAction({ action: 'setStatusTracking' })
    }
  }

  const handleEditTracking = () => {
    dispatch({ type: 'toggleEditTracking', payload: tracking })
  }

  const handleDeleteTracking = () => {
    setIsOpenModalDelete(true)
  }

  return (
    <Flex column justify='space-between' colorStatus={statusData[status].color} styles={cardStyles}>
      <ReportsTrackingModalPause
        isOpen={isOpenModalPause}
        setIsOpenModal={setIsOpenModalPause}
        onClose={() => setIsOpenModalPause(false)}
        trackingId={_id}
        handleChangeStatusMutation={handleChangeStatusMutation}
        loading={isLoading}
      />
      <ReportsTrackingModalDelete
        isOpen={isOpenModalDelete}
        setIsOpenModal={setIsOpenModalDelete}
        onClose={() => setIsOpenModalDelete(false)}
        trackingId={_id}
      />
      <ReportsTrackingModalLimit
        isOpen={isOpenModalLimit}
        setIsOpenModal={setIsOpenModalLimit}
        onClose={() => setIsOpenModalLimit(false)}
      />
      <CardContainer>
        <Flex justify='space-between'>

          <Flex column align='left'>
            <Flex styles='width: 190px;'><Text ellipsis color='primary.light2' size='14'>{name}</Text></Flex>
            <Text color='primary.light3' size='10'>{t('reports.publicationsAndData.dataSource.trackings.tracking.createdOn.label')} <Date>{createdAt}</Date></Text>
            <Spacing size='8' />
          </Flex>
          <Flex column align='right' justify='center'>
            <Flex align='center'>
              <Text color='primary.light2' size='12' ellipsis>{t(statusData[status].label)}</Text>
              <StatusDot colorStatus={statusData[status].color} />
            </Flex>
            <Spacing size='8' />
          </Flex>
        </Flex>
        <Spacing withLine />
        {status === 'error' &&
          <>
            <Flex align='center'>
              <Icon name='danger' color={statusData[status].color} size='10' />
              <Spacing vertical size='4' />
              <Text bold color={statusData[status].color} size='12'>{t('reports.modal.limitTracking.header')}</Text>
            </Flex>
            <Text size='12' color={statusData[status].color}>
              {t('reports.publicationsAndData.dataSource.trackings.tracking.cantStart.label')}
            </Text>
            <Spacing size='8' />
          </>}
        <Flex column>
          <Text color='primary.light3' size='12'>{t('reports.publicationsAndData.dataSource.trackings.tracking.lastUpdate.label')}</Text>
          <Text color='primary.dark' size='14'><Date>{updatedAt}</Date></Text>
          <Spacing size='24' />
        </Flex>

        <Accordion>
          <ReportsTrackingCardAccordion tracking={tracking} icon='hashtag' title={t('reports.publicationsAndData.dataSource.trackings.tracking.hashtags.label')} data={hashtags} />
        </Accordion>
        <Spacing size='12' />
        <Spacing size='12' withLine />

        <Accordion>
          <ReportsTrackingCardAccordion tracking={tracking} icon='at' title={t('reports.publicationsAndData.dataSource.trackings.tracking.mentions.label')} data={mentions} />
        </Accordion>
        <Spacing size='12' />
        <Spacing size='12' withLine />

        <Accordion>
          <ReportsTrackingCardAccordion tracking={tracking} icon='keywords_line' title={t('reports.publicationsAndData.dataSource.trackings.tracking.keywords.label')} data={keywords} />
        </Accordion>
        <Spacing size='24' />

        <Flex>
          <Flex column grow='1'>
            <Text color='primary.light3' size='12'>{t('reports.publicationsAndData.dataSource.trackings.tracking.startDate.label')}</Text>
            <Text color='primary.dark' size='14'><Date>{startDate}</Date></Text>
          </Flex>
          <Flex column grow='1'>
            <Text color='primary.light3' size='12'>{t('reports.publicationsAndData.dataSource.trackings.tracking.endDate.label')}</Text>
            <Text color='primary.dark' size='14'><Date>{endDate}</Date></Text>
          </Flex>
        </Flex>
        <Spacing size='24' />

        <Flex column>
          <Text color='primary.light3' size='12'>{t('reports.publicationsAndData.dataSource.trackings.tracking.numberOfProfiles.label')}</Text>
          <Flex>
            <Text color='primary.dark' size='14'>{influencers ? influencers.length : 0}</Text>
            {influncersWithoutApiAccess > 0 && (
              <>
                <VerticalLine><Text color='state.warningDefault' size='14'>{influncersWithoutApiAccess} </Text></VerticalLine>
              </>)}
          </Flex>
          {influncersWithoutApiAccess > 0 && (
            <>
              <Spacing size='4' />
              <Flex align='center'>
                <Icon name='danger' color='state.warningDefault' size='10' />
                <Spacing vertical size='4' />
                <Text color='state.warningDefault' size='12'>{t('reports.publicationsAndData.dataSource.trackings.tracking.notOptedInYet.warning.label', { totalProfiles: influncersWithoutApiAccess })}</Text>
              </Flex>
            </>
          )}
          <Spacing size='12' />
        </Flex>
      </CardContainer>
      <Flex column>
        <Spacing size='0' withLine />
        <CardContainer>
          <Flex justify='space-between' align='center'>
            <Flex column>
              {canResumeStatus &&

                <StateButton
                  loading={isLoading}
                  isDisabled={isLoading}
                  variant='outline' onClick={handleChangeStatus}
                >
                  <Icon name='videoplay' size='18' />
                  <Spacing size='4' vertical />
                  {t('commons.button.resume')}
                </StateButton>}
              {canPauseStatus &&

                <StateButton
                  loading={isLoading}
                  isDisabled={isLoading}
                  variant='outline' onClick={handleChangeStatus}
                >
                  <Icon name='pause' size='18' />
                  <Spacing size='4' vertical />
                  {t('commons.button.pause')}
                </StateButton>}
            </Flex>

            <Flex column align='flex-start'>
              <Dropdown isOpen={isOpenActions} setOpen={setOpenActions} align='top' right='42px' width='auto'>
                <DropdownOptionItem
                  onClick={() => { handleEditTracking(); setOpenActions(false) }}
                  data-cy='dropdown-edit-tracking'
                >
                  <Flex>
                    <Icon name='edit' size='18' color='primary.light2' />
                    <Spacing vertical size='8' />
                    <Text ellipsis size='14' color='primary.light2'>{t('reports.publicationsAndData.dataSource.trackings.tracking.button.actions.dropdown.button.editTracking')}</Text>
                  </Flex>
                </DropdownOptionItem>
                <Spacing withLine size='0' />
                <DropdownOptionItem
                  onClick={() => { handleDeleteTracking(); setOpenActions(false) }}
                  data-cy='dropdown-edit-tracking'
                >
                  <Flex>
                    <Icon name='trash' size='18' color='state.dangerDefault' />
                    <Spacing vertical size='8' />
                    <Text ellipsis size='14' color='state.dangerDefault'>{t('reports.publicationsAndData.dataSource.trackings.tracking.button.actions.dropdown.button.deleteTracking')}</Text>
                  </Flex>
                </DropdownOptionItem>
              </Dropdown>
              <Button
                color='secondary'
                variant='flat' onClick={() => {
                  setOpenActions(!isOpenActions)
                }}
              >
                {t('commons.button.actions')}
                <Spacing vertical size='4' />
                <Icon name='arrowupthick' size='18' />
              </Button>
            </Flex>
          </Flex>
        </CardContainer>
      </Flex>
    </Flex>)
}
