import styled from 'styled-components'

const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  height: fit-content;
  :not(:last-child) {
    margin-right: 24px;
  }
`
export const ScrollStickyDiv = ({ children, styles }) => (
  <StickyDiv styles={styles}>
    {children}
  </StickyDiv>
)
