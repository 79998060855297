import { AddPostsManualPostCost } from '../../../../addPosts/manual/form/subForms/AddPostsManualPostCost'
import { EditPostModalContentAutomaticPeopleTagged } from '../peopleTagged/EditPostModalContentAutomaticPeopleTagged'
import { AddPostsManualInstagramReelAdditionalMetrics } from './AddPostsManualInstagramReelAdditionalMetrics'
import { AddPostsManualInstagramLikesDisabled } from '../../../../addPosts/manual/form/subForms/AddPostsManualInstagramLikesDisabled'

export const AddPostsManualInstagramReel = ({ tooltipText, likeAndViewCountDisabled }) => (
  <>
    {likeAndViewCountDisabled && <AddPostsManualInstagramLikesDisabled />}
    <EditPostModalContentAutomaticPeopleTagged />
    <AddPostsManualInstagramReelAdditionalMetrics tooltipText={tooltipText} />
    <AddPostsManualPostCost />
  </>
)
