import { gql } from '@apollo/client'

export const registerRelationshipProgramV2IRMInfluencers = gql`
  mutation registerRelationshipProgramV2IRMInfluencers(
    $programIds: [String]!
    $influencerIds: [String]!
  ) {
    registerRelationshipProgramV2IRMInfluencers(
      programIds: $programIds
      influencerIds: $influencerIds
    ){
        message
        code
        success
      }
  }
 `
