import { isEmpty } from 'lodash'
import { Form, FormInputWithValidations } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const requiredValidation = {
  checkFunction: isEmpty
}

export const EmailSetupUpdatePasswordModalPassword = () => {
  const { t } = useTranslate()
  return (
    <>
      <FormInputWithValidations name='password' validations={[requiredValidation]}>
        {({ name, onChange, helperText, variant }) => (
          <Form.InputText
            label={t('commons.modals.setPassword.input.password.label')} name={name} type='password' onChange={onChange}
            helperText={helperText || t('settings.yourPreferences.communication.email.configuration.modal.enableInbox.input.password.helpertext.default')}
            variant={variant}
          />
        )}
      </FormInputWithValidations>
    </>
  )
}
