import { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { currencyQuery } from '../../../graphql/commons'
import { reportsV2CurrencyBySharedLink } from '../../../graphql/commons/SharedCurrencyQuery'
import { useLanguageContext } from '~/context/Language'

export const CurrencyContext = createContext({ value: {} })

export const CurrencyContextProvider = (props) => {
  const router = useRouter()
  const { locale } = useLanguageContext()
  const brandId = get(router, 'query.brandId')
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const skip = isPublic || !brandId
  const { data } = useQuery(currencyQuery, { variables: { brandId }, skip })
  let currency = get(data, 'brand.currency')
  let symbol = get(currency, 'symbol', '$')
  let code = get(currency, 'code', 'USD')

  // currency for shared reportV2
  const sharedId = get(router, 'query.sharedId')
  const isReport = router && router.pathname && router.pathname.includes('report')
  const { data: sharedData } = useQuery(reportsV2CurrencyBySharedLink, { variables: { sharedId, locale }, skip: !sharedId || !isReport, fetchPolicy: 'network-only' })
  if (sharedId && isPublic && isReport) {
    currency = get(sharedData, 'reportsV2CurrencyBySharedLink')
    symbol = get(currency, 'symbol', '$')
    code = get(currency, 'code', 'USD')
  }

  const value = { symbol, code }

  return (
    <CurrencyContext.Provider value={value}>
      {props.children}
    </CurrencyContext.Provider>
  )
}

export const CurrencyContextConsumer = CurrencyContext.consumer
