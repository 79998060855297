import { Flex } from 'influ-dms'
import styled, { css } from 'styled-components'

export const tabsStyles = css`
  position: relative;
  height: 100%;
`

export const tabHeaderStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  margin: 4px 24px 0px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

export const tabStyles = css`
  padding: 4px;
`

export const StyledContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  flex-direction: column;
  height: calc(100% - 45px);
`

export const cardStyles = css`
  border: 1px solid ${({ colorStatus }) => colorStatus || ''};
  border-radius: 4px;  
  margin: 16px 16px 16px 0;
  width: 320px;
  min-height: 449px;
`

export const CardContainer = styled('div')`
  padding: 16px;
`

export const TagTitleWrapper = styled.div`
  display: flex;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.light6};
`
