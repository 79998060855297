import { Fragment, useContext, useState } from 'react'
import { Dropdown, DropdownOptionItem, Flex, Text } from 'influ-dms'
import { useMutation, useQuery } from '@apollo/client'
import { get } from 'lodash'
import { useTranslate, useBrandData } from '~/hooks'
import { RouterHelper } from '~/routing/routerHelper'
import { getIrmListSharedLink } from '../../../apollo'
import { ActionIcons, Actions } from '../../../commons/components/ActionButtons'
import Action from '../../../commons/components/ActionButtons/Action'
import { ListContext } from '../context'
import { MoreOptionsForList } from '../MoreOptionsForList'
import { PrintEntityTypes, useToPrint } from '../../../commons/hooks'
import { ListDetailExport } from '../../Detail/ListDetailExport'
import { useRouter } from 'next/router'
import { ModalsContext } from '../../../../commons/context'
import { OutreachContext } from '../../../../communication/outreach/context'
import { getTableInfluencersInListByBrand } from '../apollo/getTableInfluencersInListByBrand'

export const useListActions = ({
  isSidebarOpen,
  list = {},
  sharedId,
  setIsOpenRefreshModal
}) => {
  const { object } = list
  const { t } = useTranslate()
  const { openShareModal } = useContext(ListContext)
  const [mutationLink] = useMutation(getIrmListSharedLink)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const listId = list.id || get(router, 'query.listId')
  const { featureFlags } = useBrandData({ variables: { brandId }, skip: !brandId })
  const canExport = get(featureFlags, 'irm.exportCSV')

  const { dispatch } = useContext(ModalsContext)
  const [sendToOpened, setSendToOpened] = useState(false)
  const { setAssociateToOutreachesModalOpen } = useContext(OutreachContext)
  const hasSendEmail = object?.toLowerCase() === 'influencer'
  const variables = sharedId ? { sharedId, view: 'table' } : { listId, view: 'table' }
  const { data, loading } = useQuery(getTableInfluencersInListByBrand, { variables, skip: (!listId && !sharedId) || !hasSendEmail })
  // const isProfileList = list?.object === 'profile'
  // const profileIds = list?.profiles?.map(profile => profile._id)
  // const { profilesLoading } = useContext(NotifyProfileDataProcessedContext)
  // const isSomeProfileLoading = areSomeValuesIntoArray(profileIds, profilesLoading)
  // const isRefreshDiabled = isEmpty(profileIds) || isSomeProfileLoading

  const { goTo: goToPrint } = useToPrint({
    entity: PrintEntityTypes.LIST,
    entityId: list.id,
    limitText: t('commons.modals.blocked.titleHeader1.printListPdfs'),
    canPrint: true
  })

  const handleShareList = async () => {
    const link = await mutationLink({ variables: { listId: list.id } })
    const sharedId = get(link, 'data.getIrmListSharedLink.sharedId')
    const domain = typeof window !== 'undefined' ? window.location.origin : ''
    const route = sharedId ? RouterHelper.getUrl('irmListPublic', { sharedId }) : ''
    openShareModal(`${domain}${route}`)
  }

  const handleSendEmail = () => {
    dispatch({
      type: 'openSendEmailIrmInfluencer',
      influencers: !loading && data?.getTableInfluencersInListByBrand.map(influencer => ({
        _id: influencer._id,
        email: influencer.emails?.find(email => email.primary && !email.deleted)?.value || influencer.email,
      })),
    })
  }

  const renderSendTo = () => {
    return (
      <Dropdown
        isOpen={sendToOpened}
        setOpen={setSendToOpened}
        align='left'
        top='18px'
        right='30px'
        position='fixed'
        width='auto'
      >
        {hasSendEmail &&
          <DropdownOptionItem
            onClick={handleSendEmail || (() => {})}
            data-cy='compose-email'
          >
            <Flex>
              <Text size='14' ellipsis color='primary.light2'>
                {t('commons.button.composeEmail')}
              </Text>
            </Flex>
          </DropdownOptionItem>
        }
        <DropdownOptionItem
          onClick={() => setAssociateToOutreachesModalOpen(true, [listId], 'list')}
          data-cy='send-to-outreach'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.addToOutreach')}
            </Text>
          </Flex>
        </DropdownOptionItem>
      </Dropdown>
    )
  }

  const ActionList = ({ color }) => (
    <Fragment>
      <Action
        key={Actions.SEND}
        iconName={ActionIcons[Actions.SEND]}
        text={t('commons.button.email.tooltip')}
        tooltipPlace='top'
        onClick={() => setSendToOpened(!sendToOpened)}
        isDisabled={loading || !data?.getTableInfluencersInListByBrand?.length}
        dropdownComponent={renderSendTo}
        color={color}
      />
      <Action
        key={Actions.SHARE}
        color={color}
        iconName={ActionIcons[Actions.SHARE]}
        text={t('commons.button.share.tooltip')}
        onClick={handleShareList || (() => {})}
      />
      <Action
        key={Actions.EXPORT}
        color={color}
        iconName={ActionIcons[Actions.EXPORT]}
        text={t('commons.button.export.tooltip')}
        customComponent={() => (
          <ListDetailExport
            brandId={brandId}
            canExport={canExport}
            domain={'list.detail'}
            query={{ listId: list.id }}
            listName={list.listName}
          />
        )}
      />
      <Action
        key={Actions.PRINT}
        color={color}
        iconName={ActionIcons[Actions.PRINT]}
        text={t('commons.button.print.tooltip')}
        onClick={goToPrint || (() => {})}
      />
      {/* {isProfileList &&
        <Action
          key={Actions.REFRESH}
          color={color}
          iconName={ActionIcons[Actions.REFRESH]}
          text={t('commons.button.refreshData.tooltip')}
          onClick={() => setIsOpenRefreshModal(true) || (() => {})}
          isDisabled={isRefreshDiabled}
          spinWhenDisabled={isSomeProfileLoading}
        />
      } */}
      <Action
        key={Actions.MORE_OPTIONS}
        color={color}
        iconName={ActionIcons[Actions.MORE_OPTIONS]}
        customComponent={() =>
          <MoreOptionsForList isSidebarOpen={isSidebarOpen} />
        }
      />
    </Fragment>
  )

  return {
    actions: ActionList,
    handleShareList
  }
}
