import { gql } from '@apollo/client'

export const listUpdateAggregatedDataStatus = gql`
    subscription listUpdateAggregatedDataStatus(
        $brandId: String!
        $listId: String!
        $network: String!
    ) {
      listUpdateAggregatedDataStatus(
        brandId: $brandId
        listId: $listId
        network: $network
      ){
        brandId
        listId
        network
      }
    }
`
