import { useContext, useEffect } from 'react'
import { Button, FiltersTableContext, Flex, Modal, Portal, StateButton, TableContext } from 'influ-dms'
import { useQuery } from '@apollo/client'
import { useTranslate } from '~/hooks/useTranslate'
import { parseEmail } from '../../../commons/utils/parseEmail'
import { TemplateContext } from '../../context/TemplateContext'
import { getTemplate } from '../../apollo'
import { SelectTemplateModalTable } from './SelectTemplateModalTable'
import { css, ThemeContext } from 'styled-components'

const modalStyles = css`
  padding: 24px 32px 12px 32px;
`

export const SelectTemplateModal = ({ setSelectedTemplate, setLoadingSelectedTemplate }) => {
  const { t } = useTranslate()
  const { state: { selectedRows } } = useContext(TableContext)
  const { colors: { gradients } } = useContext(ThemeContext)
  const { isSelectTemplateModalOpen, setSelectTemplateModalOpen } = useContext(TemplateContext)
  const { setFiltersAdded } = useContext(FiltersTableContext)
  const { data, loading } = useQuery(
    getTemplate,
    {
      variables: { _id: selectedRows[0]?._id },
      skip: !isSelectTemplateModalOpen || selectedRows.length === 0,
      fetchPolicy: 'network-only'
    }
  )

  const handleOnInsert = () => {
    setSelectTemplateModalOpen(false)
    setFiltersAdded([])
  }

  const handleOnCancel = () => {
    setSelectTemplateModalOpen(false)
    setFiltersAdded([])
    setSelectedTemplate(null)
  }

  useEffect(() => {
    setLoadingSelectedTemplate(loading)
  }, [loading])

  useEffect(() => {
    if (data?.getTemplate && !loading) {
      const template = parseEmail(data?.getTemplate, false)
      setSelectedTemplate(template)
    }
  }, [data])

  return (
    <Portal selector='#selectTemplateModal'>
      <Modal
        name='selectTemplateModal'
        isOpen={isSelectTemplateModalOpen}
        onClose={handleOnCancel}
        title={t('modal.selectTemplate.header')}
        width='903'
        height='613'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
        styles={modalStyles}
        zIndex='1000'
      >
        <SelectTemplateModalTable selectedRows={selectedRows} />
        <Flex>
          <StateButton name='insertTemplate' showLoader disabled={!selectedRows.length || loading} onClick={handleOnInsert}>
            {t('commons.button.insert')}
          </StateButton>
          <Button color='secondary' variant='flat' onClick={handleOnCancel}>{t('commons.button.cancel')}</Button>
        </Flex>
      </Modal>
    </Portal>
  )
}
