import { AddPostsManualInstagramStoryInfo } from './subForms/AddPostsManualInstagramStoryInfo'
import { AddPostsManualInstagramStoryMetrics } from './subForms/AddPostsManualInstagramStoryMetrics'
import { AddPostsManualPostCost } from './subForms/AddPostsManualPostCost'

export const AddPostsManualInstagramStory = () => {
  return (
    <>
      <AddPostsManualInstagramStoryInfo />
      <AddPostsManualInstagramStoryMetrics />
      <AddPostsManualPostCost />
    </>
  )
}
