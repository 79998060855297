import { gql } from '@apollo/client'

export const getMasterTableCountries = gql`
  query getMasterTableCountries(
    $query: String!
    $type: MasterTableOptions!
    $locale: String
  ) {
    getMasterTableCountries(
      query: $query
      type: $type
      locale: $locale
    ) {
        key
        label
        value
        geometry {
          type
          coordinates
        }
    }
  }
`
