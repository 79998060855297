import { gql } from '@apollo/client'

export const addInfluencersMassiveWithProfileFromDiscover = gql`
  mutation addInfluencersMassiveWithProfileFromDiscover(
    $brandId: String!
    $profiles: [String]!
    $from: String!
    $analyzeProfile: Boolean!
    $socialNetwork: String!
    $channelIdsUsernamesTuples: [ChannelIdsUsernamesTuplesInputType]
  ) {
    addInfluencersMassiveWithProfileFromDiscover(
    brandId: $brandId
    profiles: $profiles
    from: $from
    analyzeProfile: $analyzeProfile
    socialNetwork: $socialNetwork
    channelIdsUsernamesTuples: $channelIdsUsernamesTuples
  ) {
      message
      code
      success
      data
    }
  }
`
