import { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { FormContext, InputTag, useArray, Text } from 'influ-dms'
import { validations } from 'front-tools'

const { checkValidEmail } = validations

const containerStyles = css`
  height: fit-content;
  overflow: hidden;
  padding: 0;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;
  flex-shrink: 0;
`

const textareaStyles = css`
  height: 12px;
  min-height: 12px;
  margin: 0 4px;
  padding: 8px;
  white-space: nowrap;
  flex: 1;
`

const tagStyles = css`
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  margin-left: 4px;
  :first-child {
    margin-left: 32px;
  }
  p {
    margin-right: 6px;
  }
`

const StyledText = styled(Text)`
  padding: 6px 4px 6px 6px;
`

export const NewEmailModalTo = ({ isOpen, name, email, placeholder, children }) => {
  const address = get(email, name)
  const [urls, setUrls] = useArray([])
  const { setValue } = useContext(FormContext)

  useEffect(() => {
    if (isOpen && address) {
      if (typeof address === 'string') {
        setUrls([{ value: address, isValid: get(checkValidEmail(address), 'success') }])
      } else if (Array.isArray(address)) {
        const values = address.map(email => ({ value: email, isValid: get(checkValidEmail(email), 'success') }))
        setUrls(values)
      }
    } else {
      setUrls([])
    }
  }, [isOpen, address])

  useEffect(() => {
    setValue('InputText', name, urls.filter(({ isValid }) => isValid).map(({ value }) => ({ address: value })))
  }, [isOpen, urls.length])

  const handleUrl = val => {
    setUrls({ value: val, isValid: get(checkValidEmail(val), 'success') }, 'edit', 'value')
  }

  return (
    <InputTag
      values={urls}
      setValues={setUrls}
      handleValue={handleUrl}
      containerStyles={containerStyles}
      textareaStyles={textareaStyles}
      tagStyles={tagStyles}
      rows={1}
      leftChildren={<StyledText>{placeholder}</StyledText>}
      rightChildren={children}
    />
  )
}
