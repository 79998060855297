import styled, { css } from 'styled-components'
import { Flex } from 'influ-dms'

export const StyledContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 16px 0;
  height: 100%;
`

export const errorStyles = css`
  a {
    margin-left: 4px;
    color: inherit;
    font-weight: 500;
  }
`
