import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { self } from '../graphql'
import { ChangePasswordModal } from '../containers/Landing/ChangePasswordModal'

const ChangePassword = (props) => {
  const { children } = props
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname', '')
  const isPublic = pathname.includes('public')
  const skip = !brandId || isPublic

  const { data: selfData, refetch: selfRefetch } = useQuery(self, { /* notifyOnNetworkStatusChange: true, */ skip })
  const requestChangePassword = get(selfData, 'self.requestChangePassword')

  if (requestChangePassword) {
    return (
      <>
        <ChangePasswordModal
          requestChangePassword={requestChangePassword}
          selfRefetch={selfRefetch}
        />
        {children}
      </>
    )
  }
  return children
}

export { ChangePassword }
