import { useContext } from 'react'
import { TableContext, TableV2TdCheckbox } from 'influ-dms'
import { isEmpty } from 'lodash'
import { MAX_PROFILES_PER_INFLUENCER, MAX_PROFILES_PER_LIST } from '../../commons/constants'

const hasMetricsData = (row) => {
  return (
    !isEmpty(row.audienceTopAge) ||
    !isEmpty(row.audienceTopBrandAffinity) ||
    !isEmpty(row.audienceTopCity) ||
    !isEmpty(row.audienceTopCountry) ||
    !isEmpty(row.audienceTopEthnicity) ||
    !isEmpty(row.audienceTopGender) ||
    !isEmpty(row.audienceTopInterest) ||
    !isEmpty(row.audienceTopLanguage) ||
    !isEmpty(row.audienceTopState) ||
    !isEmpty(row.likersTopAge) ||
    !isEmpty(row.likersTopBrandAffinity) ||
    !isEmpty(row.likersTopCity) ||
    !isEmpty(row.likersTopCountry) ||
    !isEmpty(row.likersTopEthnicity) ||
    !isEmpty(row.likersTopGender) ||
    !isEmpty(row.likersTopInterest) ||
    !isEmpty(row.likersTopLanguage)
  )
}

export const getSelectionCell = (t, row, params, programId, listId, list) => {
  const { state: { selectedRows } } = useContext(TableContext)

  const isAssociatingInfluencersToProgram = row.original.__typename === 'IrmInfluencerForTable' && programId
  const isForPrograms = isAssociatingInfluencersToProgram
  const isForComparator = params.view === 'compare'
  const isAssociatingProfilesToList = !!(row.original.__typename === 'ProfileTableType' && listId)
  const isAssociatingListsToProfile = !!(row.original.__typename === 'IrmListType' && !listId)
  const isAssociatingProfilesInListToAnotherList = !!(row.original.__typename === 'IrmListType' && listId)
  const isForLists = isAssociatingProfilesToList || isAssociatingListsToProfile || isAssociatingProfilesInListToAnotherList

  const disabledForProfiles = list?.profiles?.length + (selectedRows?.length || 0) >= MAX_PROFILES_PER_LIST
  const disabledForLists = row.original.profiles?.length + (selectedRows?.length || 0) >= MAX_PROFILES_PER_LIST
  const disabledForProfilesAndLists = isAssociatingProfilesInListToAnotherList ? disabledForLists : disabledForProfiles || disabledForLists
  const disabledForInfluencers = row.original.profiles?.length + (selectedRows?.length || 0) >= MAX_PROFILES_PER_INFLUENCER
  const disabledForComparator = isForComparator && !hasMetricsData(row?.original)

  let disabled = isForPrograms ? false : isForLists ? disabledForProfilesAndLists : disabledForInfluencers
  let disabledText = t(isForLists ? 'commons.button.addToList.tooltip.warning.maximum' : 'commons.button.addToInfluencer.tooltip.warning.maximum', { maximum: MAX_PROFILES_PER_INFLUENCER, number: MAX_PROFILES_PER_LIST })
  const disabledComparatorText = disabledForComparator && t('commons.button.addToComparator.tooltip.warning.noAnalyzed')

  const disabledForRecipientsInfluencers = params.fromOutreach && row.original.emails?.length === 0 && row.original.emails?.some(({ deleted }) => !deleted)
  const disabledForRecipientsList = params.fromOutreach && row.original.size === 0
  const disabledForPaymentsList = params.fromPayments && row.original.size === 0
  if (disabledForRecipientsInfluencers) {
    disabled = true
    disabledText = t('commons.button.addToOutreach.tooltip.warning.influencerWithoutEmail')
  } else if (disabledForRecipientsList) {
    disabled = true
    disabledText = t('commons.button.addToOutreach.tooltip.warning.emptyList')
  } else if (disabledForPaymentsList) {
    disabled = true
    disabledText = t('commons.button.addToPayout.tooltip.warning.emptyList')
  }

  const disabledForProgramInfluencers = params.fromPrograms && (row.original.emails?.length === 0 || row.original.emails?.every(({ deleted }) => deleted === true))
  const disabledForProgramList = params.fromPrograms && row.original.size === 0
  const disabledForProgramPrograms = params.fromPrograms && row.original.id === programId
  if (disabledForProgramInfluencers) {
    disabled = true
    disabledText = t('commons.button.addToProgram.tooltip.warning.influencerWithoutEmail')
  } else if (disabledForProgramList) {
    disabled = true
    disabledText = t('commons.button.addToProgram.tooltip.warning.emptyList')
  } else if (disabledForProgramPrograms) {
    disabled = true
    disabledText = t('commons.button.addToProgram.tooltip.warning.currentProgram')
  }

  if (params?.originId) {
    const originIds = !Array.isArray(params?.originId) ? [params?.originId] : params?.originId
    for (const prop in row?.original) {
      const isRelationshipArray = Array.isArray(row?.original?.[prop]) && (row?.original?.[prop]?.[0]?._id || row?.original?.[prop]?.[0]?.id)
      if (isRelationshipArray) {
        let disabledRow = true
        let anyTrue = false
        for (const id of originIds) {
          const match = row?.original?.[prop]?.find(o => (!isEmpty(id)) && (o._id === id || o.id === id))
          disabledRow = disabledRow && match
          anyTrue = anyTrue || match
        }
        if (disabledRow) {
          disabled = true
          if (params.fromOutreach) {
            if (row.original.__typename === 'OutreachType') {
              disabledText = t('commons.button.selectOutreach.tooltip.warning.influencerAlreadyInOutreach')
            } else {
              disabledText = t('commons.button.addToOutreach.tooltip.warning.influencerDuplicated')
            }
          } else if (params.fromPrograms) {
            disabledText = t('commons.button.addToProgram.tooltip.warning.influencerDuplicated')
          } else {
            disabledText = t('commons.button.associate.tooltip.warning.alreadyAssociate')
          }
          break
        } else if (anyTrue) {
          break
        }
      }
    }
  }

  if (params?.fromOutreach && !isEmpty(row.original.typeName)) { // Is associated Outreach
    disabled = true
    disabledText = t('commons.button.selectOutreach.tooltip.warning.notIncludedOnAssociatedEntity', { associatedTo: row.original.typeName })
  }

  return (
    <TableV2TdCheckbox
      row={row}
      disabled={disabled}
      disabledText={disabledComparatorText || disabledText}
    />
  )
}
