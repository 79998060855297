import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Date, FormContext, InfluDateFormat, Text } from 'influ-dms'
import { EditPostModalContentHeader } from './header/EditPostModalContentHeader'
import { isEmpty } from 'lodash'
import { cloneDeep } from '@apollo/client/utilities'
import { EditPostModalContentManual } from './EditPostModalContentManual'
import { EditPostModalContentAutomatic } from './automatic/EditPostModalContentAutomatic'
import { useTranslate } from '~/hooks/useTranslate'

const StyledDiv = styled.div`
  height: calc(100% - 32px);
`

const StyledContent = styled.div`
  overflow: auto;
  height: calc(100% - 144px);
  padding: 16px 32px 64px;
`

const StyledDateText = styled(Text)`
  text-align: end;
  margin-bottom: 16px;
`

export const EditPostModalContent = ({ post, isOpen }) => {
  const { t } = useTranslate()
  const { setValues } = useContext(FormContext)
  const showManualForm = post.dataSource === 'manual' || post.dataSource === 'manualNoAudienceData'
  const showAutomaticForm = post.dataSource === 'automatic' || post.dataSource === 'tracking'

  useEffect(() => {
    if (!isEmpty(post) && isOpen) {
      const newValues = cloneDeep(post)
      // FIXME by conf
      delete newValues.__typename
      if (newValues.additionalMetrics) {
        delete newValues.additionalMetrics.__typename
        if (newValues.additionalMetrics.countries) {
          newValues.additionalMetrics.countries.forEach(country => {
            delete country.__typename
          })
        }
        if (newValues.additionalMetrics.cities) {
          newValues.additionalMetrics.cities.forEach(city => {
            delete city.__typename
          })
        }
      }
      newValues.requestAudienceData = post.dataSource === 'manual'
      newValues.url = newValues.url || ''
      newValues.postTaggedUsers = newValues.taggedUsers
      setValues(newValues)
    }
  }, [isOpen, post.publicationInReportV2Id, post.status])

  if (isEmpty(post)) return null
  return (
    <StyledDiv>
      <EditPostModalContentHeader post={post} />
      <StyledContent>
        <StyledDateText size='14'>{t('reports.report.modal.publication.lastUpdate.label')} <Date format={InfluDateFormat(t).LLL}>{post.updatedAt}</Date></StyledDateText>
        {showManualForm && <EditPostModalContentManual isUrlEmpty={!post.url} postId={post.publicationInReportV2Id} />}
        {showAutomaticForm && <EditPostModalContentAutomatic />}
      </StyledContent>
    </StyledDiv>
  )
}
