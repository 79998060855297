import { useContext, useEffect, useState } from 'react'
import { css } from 'styled-components'
import { get, isEmpty } from 'lodash'
import { Flex, FormContext, Icon, ScrollableContainer, Spacing, Text, useArray, Loader } from 'influ-dms'
import { getListsWithInfluencersForTracking } from '../../../../reports/apollo/tracking/getListsWithInfluencersForTracking'
import { useQuery } from '@apollo/client'
import { LIMIT_OF_PROFILES_TO_ADD } from './AddProfileTrackingCard'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'

const cursorStyles = css`
  cursor: pointer;
  margin: 4px;
  align-items: center;
`
const textStyles = css`
  margin: 2px 4px;
`

const listTextStyles = css`
  margin-left: 4px;
`
export const AddFromInfluencersList = (props) => {
  const { t } = useTranslate()
  const { brandId, network, setLoading, searchFilter } = props
  const { values, setValue } = useContext(FormContext)
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const { data, loading: dataLoading } = useQuery(getListsWithInfluencersForTracking, { variables: { brandId, network }, skip: !brandId || isPublic, fetchPolicy: 'network-only' })
  const [activeItems, setActiveItems] = useArray([])
  const allProfiles = get(data, 'getListsWithInfluencersForTracking', [])
  const [filteredItems, setFilteredItems] = useState(allProfiles)
  const limitExceeded = get(values.influencersInModal, 'length', 0) + get(values.influencers, 'length', 0) > LIMIT_OF_PROFILES_TO_ADD

  useEffect(() => {
    if (isEmpty(values.influencersInModal)) {
      setActiveItems([])
    }
  }, [values.influencersInModal])

  useEffect(() => {
    if (searchFilter) {
      setFilteredItems(allProfiles.filter((profile) => profile.name.toLowerCase().includes(searchFilter.toLowerCase())))
    } else {
      setFilteredItems(allProfiles)
    }
  }, [searchFilter])

  useEffect(() => {
    if (!dataLoading && isEmpty(filteredItems)) {
      setFilteredItems(allProfiles)
      setActiveItems([])
    }
  }, [allProfiles])

  useEffect(() => setLoading(dataLoading), [dataLoading])

  const adddToSelected = (listSelected) => {
    const isSelected = (activeItems || []).find(list => list.name === listSelected.name) !== undefined
    if (isSelected) {
      const newActiveItems = activeItems.filter(list => list.name !== listSelected.name)
      setActiveItems(newActiveItems)
      setValue('InputText', 'influencersInModal', newActiveItems.reduce((acc, next) => { return acc.concat(next.profiles) }, []))
    } else {
      setActiveItems(listSelected, 'add')
      setValue('InputText', 'influencersInModal', isEmpty(values.influencersInModal) ? listSelected.profiles : values.influencersInModal.concat(listSelected.profiles))
    }
  }
  return (
    <>
      <Spacing size='8' />
      <ScrollableContainer column height='320px'>
        {dataLoading ? <Loader fillspace />
          : (
            <>
              {!isEmpty(filteredItems) && filteredItems.map((list) => {
                const name = get(list, 'name')
                const isActive = isEmpty(activeItems) ? false : activeItems.find(list => list.name === name) !== undefined
                const iconName = isActive ? 'radiochecked' : 'addcircle'
                const iconColor = isActive ? 'state.active' : 'primary.light1'

                return (
                  <Flex
                    key={name}
                    onClick={() => adddToSelected(list)}
                    shrink='0'
                    styles={cursorStyles}
                  >
                    <Flex>
                      <Icon name={iconName} color={iconColor} size='20' />
                    </Flex>
                    <Text bold color='primary.light1' styles={listTextStyles} ellipsis>{name}</Text>
                  </Flex>
                )
              }
              )}
            </>
            )}
        {!dataLoading &&
          <>
            {isEmpty(filteredItems) && !isEmpty(allProfiles) && <Text color='primary.light1' styles={textStyles} size='14'> {t('reports.modal.tracking.addProfilesFromMyInfluencers.helpertext.error.noResults', { searchFilter })} </Text>}
            {isEmpty(allProfiles) && <Text color='primary.light1' styles={textStyles} size='14'>{t('reports.modal.tracking.addProfilesFromMyInfluencers.helpertext.error.noLists')}</Text>}
          </>}
      </ScrollableContainer>
      <Spacing size='8' />
      {limitExceeded && (
        <Flex align='center'>
          <Icon name='danger' size='12' color='state.dangerDefault' />
          <Text color='state.dangerDefault' styles={textStyles}>
            {t('reports.modal.tracking.addProfilesFromMyInfluencers.helpertext.error', { profilesLimit: LIMIT_OF_PROFILES_TO_ADD })}
          </Text>
        </Flex>
      )}
    </>)
}
