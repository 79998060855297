import { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { FormContext, Spacing } from 'influ-dms'
import { Quill } from './wysiwyg/quill/Quill'
import { _replaceInlineAttachments } from '../../../helpers'

const StyledDiv = styled.div`
  min-height: 180px;
  display: flex;
  .quill {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
  }
  .ql-editor {
    flex: 1;
    transition: all 0.4s ease-in-out;
  }
`

export const Signature = ({ showSignature, accountSettings, disabled }) => {
  const { values, setValue } = useContext(FormContext)
  const [quillValue, setQuillValue] = useState('')

  const accountSignature = get(accountSettings, 'signature')
  const accountSignatureAttachments = get(accountSettings, 'signatureAttachments')
  const signature = get(values, 'signature')

  const handleChange = val => {
    setQuillValue(val)
    setValue('InputText', 'signature', val)
    setValue('InputText', 'formDirty', true)
  }

  useEffect(() => {
    if (!showSignature) {
      setQuillValue('')
      setValue('InputText', 'signature', '')
    }
  }, [showSignature])

  useEffect(() => {
    setQuillValue(_replaceInlineAttachments(accountSignature || '', accountSignatureAttachments))
  }, [accountSignature])

  useEffect(() => {
    setQuillValue(_replaceInlineAttachments(signature || '', accountSignatureAttachments))
  }, [signature])

  return useMemo(() => (
    showSignature ? (
      <>
        <Spacing size='16' />
        <StyledDiv id='signatureBody'>
          <Quill containerId='#signatureBody' value={quillValue} handleChange={handleChange} readOnly={disabled} />
        </StyledDiv>
      </>
    ) : null),
  [showSignature, quillValue])
}
