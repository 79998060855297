import { createContext, useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import {
  notifyCommunicationBrandSubscription,
  notifyCommunicationUserSubscription
} from './apollo/communicationSubscriptions'
import { getCommunicationAccountSettingsQuery } from './apollo/communicationQueries'
import { COMMUNICATION_ACTIONS, MESSAGE_STATUS } from './CommunicationConstants'
import { useDidMountEffect } from '../../../hooks/useDidMountEffect'
import { getMassiveCommConfig } from '../../settings/preferences/communication/apollo/getMassiveCommConfig'
import { getDomainsByBrand } from '../../settings/account/domains/commons/apollo/getDomainsByBrand'

export const CommunicationContext = createContext({ value: {} })

export const CommunicationContextProvider = (props) => {
  const router = useRouter()
  const isPublic = router?.pathname?.includes('public')
  const isLogout = router?.pathname?.includes('logout')
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname')
  const isInDomains = pathname && pathname.includes('domains')
  const [communicationAction, setCommunicationAction] = useState()
  const { data } = useSubscription(notifyCommunicationUserSubscription, { skip: !brandId || isPublic })
  const { data: communicationBrandData } = useSubscription(notifyCommunicationBrandSubscription, { variables: { brandId }, skip: !brandId || isPublic })
  const notifyCommunicationBrand = get(communicationBrandData, 'notifyCommunicationBrand')

  const { data: communicationSettingsData, refetch: refetchCommunicationSettingsData } = useQuery(getCommunicationAccountSettingsQuery, { fetchPolicy: 'network-only', skip: !brandId || isPublic || isLogout })
  const { data: massiveCommConfigData, loading: loadingMassiveCommConfig, refetch: refetchMassiveCommConfig } = useQuery(getMassiveCommConfig, { variables: { brandId }, fetchPolicy: 'network-only', skip: !brandId || isPublic || isLogout })
  const { data: domainsByBrandData, loading: loadingDomainsByBrand, refetch: refetchDomainsByBrand } = useQuery(getDomainsByBrand, { variables: { brandId }, fetchPolicy: 'network-only', skip: !brandId || isPublic || isLogout || isInDomains })

  const notifyCommunicationUser = get(data, 'notifyCommunicationUser')
  const accountSettings = get(communicationSettingsData, 'getCommunicationAccountSettings')
  const massiveCommConfig = get(massiveCommConfigData, 'getMassiveCommConfig')
  const domainsByBrand = get(domainsByBrandData, 'getDomainsByBrand')

  useDidMountEffect(() => {
    if (!isLogout && !isPublic && brandId) {
      refetchCommunicationSettingsData()
      refetchMassiveCommConfig()
      if (!isInDomains) refetchDomainsByBrand()
    }
  }, [brandId])

  const value = {
    communicationAction,
    setCommunicationAction,
    accountSettings,
    notifyCommunicationUser,
    massiveCommConfig,
    loadingMassiveCommConfig,
    domainsByBrand,
    loadingDomainsByBrand
  }

  useEffect(() => {
    const status = get(notifyCommunicationUser, 'status')
    if ([
      COMMUNICATION_ACTIONS.newAccountConnecting,
      COMMUNICATION_ACTIONS.newAccountVerified,
      COMMUNICATION_ACTIONS.newAccountConnected
    ].indexOf(status) > -1) {
      setCommunicationAction({ type: status, data: { account: get(notifyCommunicationUser, 'account') } })
    }
  }, [notifyCommunicationUser])

  useEffect(() => {
    const status = get(notifyCommunicationBrand, 'status')
    if ([
      MESSAGE_STATUS.newMessageSent,
      MESSAGE_STATUS.newMessageReceived,
      MESSAGE_STATUS.messageDeleted
    ].indexOf(status) > -1) {
      setCommunicationAction({ type: status, data: { } })
    }
  }, [notifyCommunicationBrand])

  useEffect(() => {
    if (communicationAction) {
      if ([
        COMMUNICATION_ACTIONS.newAccountConnecting,
        COMMUNICATION_ACTIONS.newAccountVerified,
        COMMUNICATION_ACTIONS.newAccountConnected,
        COMMUNICATION_ACTIONS.accountCreationFailed,
        COMMUNICATION_ACTIONS.accountUpdated,
        COMMUNICATION_ACTIONS.accountDisabled,
        COMMUNICATION_ACTIONS.accountEnabled,
        COMMUNICATION_ACTIONS.accountDeleted
      ].indexOf(communicationAction.type) > -1) {
        refetchCommunicationSettingsData()
        refetchMassiveCommConfig()
        refetchDomainsByBrand()
      }
      setCommunicationAction(undefined)
    }
  }, [communicationAction])

  return (
    <CommunicationContext.Provider value={value}>
      {props.children}
    </CommunicationContext.Provider>
  )
}

export const CommunicationContextConsumer = CommunicationContext.consumer
