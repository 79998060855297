import { Form } from 'influ-dms'
import { servers } from '../../conf'
import { OfficeForm } from './OfficeForm'
import { ImapForm } from './imap/ImapForm'
import { Footer } from '../Footer'
import { ConnectFormSteps } from './ConnectFormSteps'

export const ConnectForm = ({ loading, server, handleConnect, handleCancel }) => (
  <Form autoComplete='off'>
    {loading ? <ConnectFormSteps /> : (
      <>
        {server === servers.outlook.id && <OfficeForm />}
        {server === servers.imap.id && <ImapForm />}
      </>
    )}
    <Footer loading={loading} handleConnect={handleConnect} handleCancel={handleCancel} server={server} />
  </Form>
)
