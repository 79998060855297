import { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Form, FormContext, DateInput, SideBox, Spacing, Text, Flex, HelpTooltip, Icon, Loader } from 'influ-dms'
import { headerStyles } from './styles'
import { FormatOptionLabel } from '../../../../search/FiltersModal/SelectNetwork/FormatOptionLabel'
import { InputTagTracking } from './InputTagTracking'
import { get, isEmpty } from 'lodash'
import { AddTrackingLimitExceeded } from './AddTrackingLimitExceeded'
import { UnlockAddTracking } from './UnlockAddTracking'
import { AddProfileTrackingCard } from './AddProfileTrackingCard'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { brand } from '../../../../../graphql/brands'
import { ReportsContext } from '../../../context/ReportsContext'
import { useTranslate } from '~/hooks/useTranslate'
import { bindLinksMap } from '~/hooks/useLink'
import { useLanguageContext } from '../../../../../context/Language'

const sideBoxStyles = css`
  padding: 0;
`
const Container = styled.div`
  padding: 32px 32px ${({ editing }) => editing ? '64px' : '24px'} 32px;
`
const PaddedContainer = styled.div`
  padding: 16px 16px 20px 16px;
`
const marginedStyles = css`
  margin: 4px 4px;
`
const margined2pxStyles = css`
  margin: 2px 2px;
`
const marginBottomStyles = css`
  margin-bottom: 4px;
`

const inputStyles = css`
  border: 1px solid ${({ theme, danger }) => danger ? theme.colors.state.dangerDefault : theme.colors.state.default};
`

const LoadingFlex = styled(Flex)`
  margin-top: 32px;
  height: 50vh;
`

const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.light3};
  :hover {
    color: ${({ theme }) => theme.colors.primary.light3};
  }
`

const networkOptions = [{ value: 'instagram', label: 'Instagram' }]

export const AddTracking = ({ handleClose, error, setError, trackingData, addAnOther, setAddAnOther, editing = false }) => {
  const { t } = useTranslate()
  const [startDate, setStartDate] = useState(trackingData && trackingData.startDate)
  const [endDate, setEndDate] = useState(trackingData && trackingData.endDate)
  const { values, setValue, cleanForm } = useContext(FormContext)
  const { reportsAction } = useContext(ReportsContext)
  const [focusInputs, setFocusInputs] = useState({})

  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const { brandId } = router.query
  const { data, refetch, loading } = useQuery(brand, { variables: { brandId }, skip: !brandId || isPublic, fetchPolicy: 'network-only' })
  const isTrial = get(data, 'brand.subscriptionInfo.isTrial')
  const trackingLimit = get(data, 'brand.featureFlags.reportsV2.trackingLimit')
  const trackingLock = trackingLimit === 0
  const isTrackingLimitExceeded = get(data, 'brand.featureFlags.reportsV2.isTrackingLimitExceeded')

  const [trackingLink, setTrackingLink] = useState('')
  const { locale } = useLanguageContext()
  const linksMap = new Map([
    ['hashtags_mention_keyword_link', setTrackingLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])

  useEffect(() => {
    setValue('InputText', 'network', networkOptions[0].value)// force select default value)
    setFocusInputs({})
  }
  , [])

  useEffect(() => {
    setValue('InputText', 'network', networkOptions[0].value)// force select default value
    if (!isEmpty(trackingData)) {
      setValue('InputText', 'name', trackingData.name)
      setValue('InputText', 'hashtags', trackingData.hashtags)
      setValue('InputText', 'keywords', trackingData.keywords)
      setValue('InputText', 'mentions', trackingData.mentions)
      setValue('InputText', 'influencers', trackingData.influencers)
      setEndDate(new Date(trackingData.endDate))
      setStartDate(new Date(trackingData.startDate))
      setFocusInputs({})
    }
  }, [trackingData])

  useEffect(() => {
    setValue('InputText', 'startDate', startDate && new Date(startDate))
    if (new Date(startDate) > new Date(endDate)) {
      setEndDate()
    }
  }, [startDate])

  useEffect(() => {
    setValue('InputText', 'endDate', endDate && new Date(endDate))
  }, [endDate])

  useEffect(() => {
    setError(false)
  }, [values.name])

  useEffect(() => {
    if (reportsAction && reportsAction.action === 'keepAddingTracking') {
      setError(false)
      cleanForm()
      setFocusInputs({})
      setStartDate(null)
      setEndDate(null)
      refetch()
      setValue('InputText', 'network', networkOptions[0].value)
    }
  }, [reportsAction])

  if (loading) {
    return <LoadingFlex align='center' justify='center'><Loader fillSpace /></LoadingFlex>
  }
  if (!editing) {
    if (trackingLock) { // Tracking disabled
      if (isTrial) { // Tracking with trial
        return <UnlockAddTracking trial handleClose={handleClose} />
      } else { return <UnlockAddTracking trial={false} handleClose={handleClose} /> }
    } else if (isTrackingLimitExceeded) { // Tracking active but Exceeded
      return <AddTrackingLimitExceeded handleClose={handleClose} />
    }
  }

  const onBlurRequired = (e) => setFocusInputs({ ...focusInputs, [e.target.name]: true })
  const nameError = (!values.name && focusInputs && focusInputs.name) || (values.name && error && error.code === '12100')
  return (
    <Container editing={editing}>
      {!editing && (
        <>
          <Text color='primary.dark' size='16' bold>{t('reports.report.modal.addPublication.tracking.titleHeader1')}</Text>
          <Spacing size='8' />
          <Text color='primary.light3'>
            {t('reports.report.modal.addPublication.tracking.titleParagraph1')}
          </Text>
          <Spacing size='24' />
        </>
      )}
      <SideBox styles={sideBoxStyles}>
        <PaddedContainer>
          <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.tracking.trackingInformationBlock.title')}</Text>
          <Spacing size='16' />
          <Form.InputText
            placeholder={t('reports.modal.tracking.trackingInformationBlock.input.trackingName.placeholder')}
            id='trackingName'
            name='name'
            onBlur={onBlurRequired}
            styles={inputStyles}
            danger={nameError}
            label={<Text size='14' bold color='primary.dark'>{t('reports.modal.tracking.trackingInformationBlock.input.trackingName.label')}</Text>}
          />
          {!values.name && focusInputs && focusInputs.name ? (
            <Flex align='center'>
              <Icon name='danger' size='12' color='state.dangerDefault' />
              <Text color='state.dangerDefault' styles={marginedStyles} size='12'>
                {t('reports.modal.tracking.trackingInformationBlock.input.trackingName.helpertext.nameMustBeProvided.error')}
              </Text>
            </Flex>)
            : !error && (
              <Text color='primary.light3' styles={marginedStyles} size='12'>
                {t('reports.modal.tracking.trackingInformationBlock.input.trackingName.helpertext')}
              </Text>
              )}
          {values.name && error && error.code === '12100' && (
            <Flex align='center'>
              <Icon name='danger' size='12' color='state.dangerDefault' />
              <Text color='state.dangerDefault' styles={marginedStyles} size='12'>
                {error.text || error}
              </Text>
            </Flex>)}
          <Spacing size='4' />
        </PaddedContainer>
      </SideBox>
      <SideBox styles={sideBoxStyles}>
        <PaddedContainer>
          <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.modal.tracking.socialNetworkBlock.title')}</Text>
          <Spacing size='16' />
          <Form.Select
            label={<Text size='14' bold color='primary.dark'>{t('reports.modal.tracking.socialNetworkBlock.input.socialNetwork.label')}</Text>}
            formatOptionLabel={FormatOptionLabel}
            name='network'
            defaultValue={networkOptions[0]}
            options={networkOptions}
            color='primary.light3'
            isSearchable={false}
            value={networkOptions[0]}
            isDisabled
          />
          <Text color='primary.light3' styles={marginedStyles} size='12'>
            {t('reports.modal.tracking.socialNetworkBlock.input.socialNetwork.helpertext')}
          </Text>
          <Spacing size='4' />
        </PaddedContainer>
      </SideBox>
      <SideBox styles={sideBoxStyles}>
        <PaddedContainer>
          <Flex align='center'>
            <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.title')}</Text>
            <HelpTooltip name='tracking-workflow' text={t('reports.modal.editTracking.hashtagsMentionAndKeywordBlock.tooltip')} />
          </Flex>
          <Spacing size='16' />
          <Flex align='center' styles={marginBottomStyles}>
            <Icon name='hashtag' size='14' color='primary.dark' />
            <Text size='14' bold color='primary'> {t('reports.modal.editTracking.hashtagsMentionAndKeywordBlock.input.hashtags.helpertext')}</Text>
            <HelpTooltip name='tracking-workflow' text={t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.hashtags.tooltip')} />
          </Flex>
          <InputTagTracking separator='#' separatorName='hashtags' onBlur={onBlurRequired} defaultTexts={trackingData && trackingData.hashtags} />
          <Text color='primary.light3' styles={marginedStyles} size='12'>
            {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.hashtags.helpertext')}
          </Text>
          <Spacing size='8' />
          <Flex align='center' styles={marginBottomStyles}>
            <Icon name='at' size='14' color='primary.dark' />
            <Text size='14' bold color='primary'> {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.mention.label')}</Text>
            <HelpTooltip name='tracking-workflow' text={t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.mention.tooltip')} />
          </Flex>
          <InputTagTracking separator='@' separatorName='mentions' onBlur={onBlurRequired} defaultTexts={trackingData && trackingData.mentions} />
          <Text color='primary.light3' styles={marginedStyles} size='12'>
            {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.mention.helpertext')}
          </Text>
          <Spacing size='8' />
          <Flex align='center' styles={marginBottomStyles}>
            <Icon name='keywords_line' size='14' color='primary.dark' />
            <Text size='14' bold color='primary'> {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.keywords.label')}</Text>
            <HelpTooltip name='tracking-workflow' text={t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.keywords.tooltip')} />
          </Flex>
          <InputTagTracking separator='' separatorName='keywords' onBlur={onBlurRequired} defaultTexts={trackingData && trackingData.keywords} />
          <Text color='primary.light3' styles={marginedStyles} size='12'>
            {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.keywords.helpertext')}
          </Text>
          <Spacing size='8' />
          {isEmpty(values.hashtags) && isEmpty(values.mentions) && isEmpty(values.keywords) && focusInputs &&
          (focusInputs.hashtags || focusInputs.mentions || focusInputs.keywords)
            ? (
              <Flex align='center'>
                <Icon name='danger' size='12' color='state.dangerDefault' />
                <Text color='state.dangerDefault' styles={margined2pxStyles} size='12'>
                  {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.keywords.helpertext.warning2')}
                </Text>
              </Flex>
              ) : (
                <Flex align='center'>
                  <Flex><Icon name='danger' size='12' color='primary.light3' /></Flex>
                  <Text color='primary.light3' styles={margined2pxStyles} size='12'>
                    {`${t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.helpertext')} `}
                    <StyledLink target='_blank' href={trackingLink} rel='noreferrer'>{`${t('commons.button.here')}.`}</StyledLink>
                  </Text>
                </Flex>)}
        </PaddedContainer>
      </SideBox>
      <SideBox styles={sideBoxStyles}>
        <PaddedContainer>
          <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.modal.tracking.timeSpanBlock.title')}</Text>
          <Spacing size='16' />
          <Flex justify='space-between'>
            <Flex column style={{ width: 'calc(50% - 16px)' }}>
              <DateInput
                value={startDate}
                minDate={new Date()}
                onChange={(e) => setStartDate(e)}
                name='startDate'
                label={<Text size='14' bold color='primary.dark'>{t('reports.modal.tracking.timeSpanBlock.input.startDate.label')}</Text>}
                autoComplete='off'
                onBlur={onBlurRequired}
                resetEnabled
              />
              {!startDate && focusInputs && focusInputs.startDate
                ? (
                  <Flex align='center'>
                    <Icon name='danger' size='12' color='state.warningDefault' />
                    <Text color='state.warningDefault' styles={margined2pxStyles}>
                      {t('reports.modal.tracking.timeSpanBlock.input.startDate.helpertext.warning')}
                    </Text>
                  </Flex>
                  ) : (
                    <Text color='primary.light3' styles={marginedStyles}>
                      {t('reports.modal.tracking.timeSpanBlock.input.startDate.helpertext')}
                    </Text>)}
            </Flex>
            <Flex column style={{ width: 'calc(50% - 16px)' }}>
              <DateInput
                value={endDate}
                minDate={editing ? (startDate > new Date() ? startDate : new Date()) : (startDate || new Date())}
                onChange={(e) => setEndDate(e)}
                name='endDate'
                label={<Text size='14' bold color='primary.dark'>{t('reports.modal.tracking.timeSpanBlock.input.endDate.label')} </Text>}
                autoComplete='off'
                resetEnabled
              />
              <Text color='primary.light3' styles={marginedStyles} size='12'>
                {t('reports.modal.tracking.timeSpanBlock.input.endDate.helpertext')}
              </Text>
            </Flex>
          </Flex>
          <Spacing size='8' />
          {startDate && endDate && (
            <Flex align='center'>
              <Icon name='danger' size='12' color='state.warningDefault' />
              <Text color='state.warningDefault' styles={margined2pxStyles} size='12'>
                {t('reports.modal.tracking.timeSpanBlock.helpertext.warning')}
              </Text>
            </Flex>)}
        </PaddedContainer>
      </SideBox>
      <SideBox styles={sideBoxStyles}>
        <AddProfileTrackingCard editing={editing} focusInputs={focusInputs} setFocusInputs={setFocusInputs} trackingId={get(trackingData, '_id', 'new')} />
        {editing && <Spacing size='40' />}
      </SideBox>
      {error && error.code !== '12100' && (
        <Flex align='center'>
          <Icon name='danger' size='12' color='state.dangerDefault' />
          <Text color='state.dangerDefault' styles={marginedStyles} size='12'>
            {error.text || error}
          </Text>
        </Flex>)}
    </Container>
  )
}
