import { Flex, Form, HelpTooltip, SideBox, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../../styles'

export const AddPostsManualInstagramReelAdditionalMetrics = ({ tooltipText, isTracking }) => {
  const { t } = useTranslate()
  return (
    <SideBox>
      <Flex>
        <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.additionalMetricsOfThepublicationBlock.title')}</Text>
        <HelpTooltip text={tooltipText} name={'help-text-edit-reel'}/>
      </Flex>
      <Spacing size='24' />
      <Form.TimeInput name='additionalMetrics.totalPlayTime' label={t('commons.insights.totalTimeWatched.input.label')} />
      <Spacing size='24' />
      <Form.TimeInput name='additionalMetrics.averageWatchTime' label={t('commons.insights.totalTimeWatched.averageTimeWatched.label')} />
      <Spacing size='24' />
      {!isTracking && (
        <>
          <Form.InputNumber placeholder={t('commons.insights.reach.input.placeholder')} name='additionalMetrics.reach' label={t('commons.insights.reach.input.label')} />
          <Spacing size='24' />
        </>
      )}
      <Form.InputNumber placeholder={t('commons.insights.shares.input.placeholder')} name='additionalMetrics.shares' label={t('commons.insights.shares.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.saves.input.placeholder')} name='additionalMetrics.saves' label={t('commons.insights.saves.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.impressions.input.placeholder')} name='additionalMetrics.impressions' label={t('commons.insights.impressions.input.label')} />
      <Spacing size='8' />
    </SideBox>
  )
}
