import { useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useFilter, useCleanCache } from '../../../irm/commons/hooks'
import { useFilterQuery } from '../../../irm/commons/hooks/useFilterQuery'
import {
  getPaymentRequestsByInvoice,
  countPaymentRequestsByInvoice,
  addInfluencersToInvoice,
  addInfluencersFromListToInvoice,
  countInfluencersToPayByInvoice,
  addToPay,
  removeFromPay,
  getPaymentsSummary as getPaymentsSummaryCall,
  editDescription as editDescriptionCall,
  editCountry as editCountryCall,
  editAmount as editAmountCall,
  getPaymentCountries as getPaymentCountriesCall,
  deletePaymentRequests
} from '../apollo'
import { useTranslate } from '~/hooks'
import { SnackbarContext } from 'influ-dms'
import { useMutation, useQuery } from '@apollo/client'
import { captureException } from '@sentry/nextjs'

export const useInvoicePayments = () => {
  const {
    clearPaymentsTableCache,
    clearInvoiceDetailCache,
    clearInvoicesTableCache,
    clearCountInfluencersToPayCache,
    clearGetPaymentSummaryCache,
    clearCanGenerateInvoiceCache
  } = useCleanCache()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    loadingData: loadingInvoicesPayments,
    loadingCount: loadingCountInvoicesPayments,
    setQueryOptions: setTableInvoicesOptions,
    setCountQueryOptions: setCountTableInvoicesOptions
  } = useFilterQuery({
    query: getPaymentRequestsByInvoice,
    queryAccessor: 'getPaymentRequestsByInvoice',
    countQuery: countPaymentRequestsByInvoice,
    countQueryAccessor: 'countPaymentRequestsByInvoice'
  })

  const { getFilteredDataOptions } = useFilter()

  const [addInfluencersToInvoiceMutation, { loading: loadingAddInfluencers }] = useMutation(addInfluencersToInvoice, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      variables?.paymentInvoiceIds?.forEach((paymentInvoiceId) => {
        clearInvoiceDetailCache(paymentInvoiceId)
      })
      clearInvoicesTableCache({ evictCount: false })
      clearCanGenerateInvoiceCache()
      cache.gc()
    }
  })

  const [addInfluencersFromListToInvoiceMutation, { loading: loadingAddInfluencersFromList }] = useMutation(addInfluencersFromListToInvoice, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      variables?.paymentInvoiceIds?.forEach((paymentInvoiceId) => {
        clearInvoiceDetailCache(paymentInvoiceId)
      })
      clearInvoicesTableCache({ evictCount: false })
      clearCanGenerateInvoiceCache()
      cache.gc()
    }
  })

  const [removeInfluencersFromInvoiceMutation, { loading: loadingRemoveInfluencersFromInvoice }] = useMutation(deletePaymentRequests, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      variables?.paymentInvoiceId && clearInvoiceDetailCache(variables.paymentInvoiceId)
      clearInvoicesTableCache({ evictCount: false })
      clearCanGenerateInvoiceCache()
      cache.gc()
    }
  })

  const [addToPayMutation, { loading: loadingAddToPay }] = useMutation(addToPay, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      clearCountInfluencersToPayCache()
      clearGetPaymentSummaryCache()
      cache.gc()
    }
  })

  const [removeFromPayMutation, { loading: loadingRemoveFromPay }] = useMutation(removeFromPay, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      clearCountInfluencersToPayCache()
      clearGetPaymentSummaryCache()
      cache.gc()
    }
  })

  const [editDescriptionMutation, { loading: loadingEditDescription }] = useMutation(editDescriptionCall, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      cache.gc()
    }
  })

  const [editCountryMutation, { loading: loadingEditCountry }] = useMutation(editCountryCall, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      cache.gc()
    }
  })

  const [editAmountMutation, { loading: loadingEditAmount }] = useMutation(editAmountCall, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache()
      variables?.paymentInvoiceId && clearInvoiceDetailCache(variables.paymentInvoiceId)
      clearInvoicesTableCache({ evictCount: false })
      cache.gc()
    }
  })

  const [countInfluencersToPayOptions, setCountInfluencersToPayOptions] = useState({ skip: true })
  const { data: countInfluencersToPay, loading: loadingCountInfluencersToPay } = useQuery(countInfluencersToPayByInvoice, countInfluencersToPayOptions)

  const [getPaymentsSummaryOptions, setGetPaymentsSummaryOptions] = useState({ skip: true })
  const { data: paymentSummary, loading: loadingPaymentSummary } = useQuery(getPaymentsSummaryCall, getPaymentsSummaryOptions)

  const { data: paymentCountries, loading: loadingPaymentCountries } = useQuery(getPaymentCountriesCall)

  const getInvoicePayments = async (filterParams, paymentInvoiceId, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, paymentInvoiceId, view },
        { brandId, paymentInvoiceId, view }
      )
      setTableInvoicesOptions(options)
      page === 0 && setCountTableInvoicesOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining Invoice payments', e)
    }
  }

  const addInfluencers = async ({ paymentInvoiceIds, influencerIds }) => {
    const variables = { paymentInvoiceIds, influencerIds }
    try {
      const response = await addInfluencersToInvoiceMutation({ variables })
      return response
    } catch (e) {
      captureException('Error adding influencers to invoice', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const addInfluencersFromList = async ({ paymentInvoiceIds, listIds }) => {
    const variables = { paymentInvoiceIds, listIds }
    try {
      const response = await addInfluencersFromListToInvoiceMutation({ variables })
      return response
    } catch (e) {
      captureException('Error adding influencers from list to invoice', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const removeInfluencersFromInvoice = async ({ paymentInvoiceId, paymentRequestIds }) => {
    const variables = { paymentInvoiceId, paymentRequestIds }
    try {
      const response = await removeInfluencersFromInvoiceMutation({ variables })
      return response
    } catch (e) {
      captureException('Error removing influencers from invoice', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const getCountInfluencersToPay = (paymentInvoiceId) => {
    try {
      const options = {
        variables: {
          paymentInvoiceId
        },
        skip: !paymentInvoiceId
      }
      setCountInfluencersToPayOptions(options)
    } catch (e) {
      captureException('Error obtaining count influencers to pay', e)
    }
  }

  const addInfluencersToPay = async (paymentRequestIds) => {
    const variables = { paymentRequestIds }
    try {
      const response = await addToPayMutation({ variables })
      return response
    } catch (e) {
      captureException('Error adding influencers to pay', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const removeInfluencersFromPay = async (paymentRequestIds) => {
    const variables = { paymentRequestIds }
    try {
      const response = await removeFromPayMutation({ variables })
      return response
    } catch (e) {
      captureException('Error removing influencers from pay', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const getPaymentsSummary = (paymentInvoiceId) => {
    try {
      const options = {
        variables: {
          paymentInvoiceId
        },
        skip: !paymentInvoiceId
      }
      setGetPaymentsSummaryOptions(options)
    } catch (e) {
      captureException('Error payment summary', e)
    }
  }

  const editDescription = async (paymentRequestIds, description, paymentInvoiceId) => {
    const variables = { paymentRequestIds, description, paymentInvoiceId }
    try {
      const response = await editDescriptionMutation({ variables })
      return response
    } catch (e) {
      captureException('Error editing payment description', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      throw (e)
    }
  }

  const editCountry = async (paymentRequestIds, country, paymentInvoiceId) => {
    const variables = { paymentRequestIds, country, paymentInvoiceId }
    try {
      const response = await editCountryMutation({ variables })
      return response
    } catch (e) {
      captureException('Error editing payment country', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      throw (e)
    }
  }

  const editAmount = async (paymentRequestIds, amount, paymentInvoiceId) => {
    const variables = { paymentRequestIds, amount, paymentInvoiceId }
    try {
      const response = await editAmountMutation({ variables })
      return response
    } catch (e) {
      captureException('Error editing payment amount', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      throw (e)
    }
  }

  return {
    getInvoicePayments,
    loadingInvoicesPayments,
    loadingCountInvoicesPayments,
    addInfluencers,
    loadingAddInfluencers,
    addInfluencersFromList,
    loadingAddInfluencersFromList,
    removeInfluencersFromInvoice,
    loadingRemoveInfluencersFromInvoice,
    getCountInfluencersToPay,
    countInfluencersToPay,
    loadingCountInfluencersToPay,
    addInfluencersToPay,
    loadingAddToPay,
    removeInfluencersFromPay,
    loadingRemoveFromPay,
    getPaymentsSummary,
    loadingPaymentSummary,
    paymentSummary,
    editDescription,
    loadingEditDescription,
    editCountry,
    loadingEditCountry,
    editAmount,
    loadingEditAmount,
    paymentCountries,
    loadingPaymentCountries
  }
}
