import { gql } from "@apollo/client";

export const getPaymentPoolsByInvoice = gql`
  query getPaymentPoolsByInvoice(
    $brandId: String!
    $paymentInvoiceId: String!
    $limit: Int
    $page: Int
    $sortParams: PaymentPoolSortParamsType, 
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getPaymentPoolsByInvoice(
      brandId: $brandId,
      paymentInvoiceId: $paymentInvoiceId,
      sortParams: $sortParams,
      page: $page,
      limit: $limit,
      filters: $filters,
      view: $view
    ) {
      _id
      brandId
      poolNumber
      status
      statusTranslated
      amount
      fee
      tax
      total
      currency
      idProforma
      createdByUser {
        firstName
      }
      createdAt
      updatedAt
    }
  }
`
