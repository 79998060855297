import { gql } from '@apollo/client'

export const deleteReportV2Tracking = gql`
 mutation deleteReportV2Tracking(
  $brandId: String!
  $reportV2Id: String!
  $trackingIds: [String]!
  $deletePublications: Boolean!
) {
  deleteReportV2Tracking(
    brandId: $brandId,
    reportV2Id: $reportV2Id,
    trackingIds: $trackingIds,
    deletePublications: $deletePublications,
    ) {
      success
    }
 }
`
