import { css } from 'styled-components'
import { Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const accountStyles = css`
  :before {
    content: "\00a0";
  }
`

export const EmailSetupRemoveInboxModalContent = ({ emailAccount }) => {
  const { t } = useTranslate()
  return (
    <div>
      <Text color='primary.light3' size='14'>
        {t('settings.yourPreferences.communication.email.configuration.modal.removeInbox.titleParagraph1')}
      </Text>
      <Spacing />
      <Text color='primary.light3' size='14' variant='span'>
        {t('settings.yourPreferences.communication.email.configuration.modal.removeInbox.titleParagraph2', { emailAccount: <Text color='primary.dark' bold size='14' variant='span' styles={accountStyles}>{emailAccount}</Text> })}
      </Text>
    </div>
  )
}
