import { gql } from '@apollo/client'

export const getCampaignsbetaByBrand = gql`
 query getCampaignsbetaByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: CampaignSortParamsType
    $filters: [NewFilterParamsType]
    $view: String!
  ) {
    getCampaignsbetaByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
    _id
    name
    networks
    createdAt
    updatedAt
  }
 }
 `
