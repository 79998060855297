import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  ${({ styles }) => styles || ''};
`

export const SettingsContainer = ({ children, styles }) => (
  <StyledDiv styles={styles}>
    {children}
  </StyledDiv>
)
