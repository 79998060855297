import { useContext } from 'react'
import styled from 'styled-components'
import { Button, Flex, FormContext, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin: 16px -32px 0 -32px;
  padding: 16px 32px 0 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export const Footer = ({ loading, handleConnect, handleCancel, server }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)

  const isFormInvalid = values.errors ? Object.values(values.errors || {}).some(val => val) : true

  const handleAccept = () => {
    handleConnect({ ...values, type: server })
  }

  return (
    <StyledFlex>
      <StateButton disabled={isFormInvalid} loading={loading} showLoader onClick={handleAccept}>{t('commons.button.connectAccount')}</StateButton>
      <StyledButton disabled={loading} color='secondary' variant='flat' onClick={handleCancel}>{t('commons.button.cancel')}</StyledButton>
    </StyledFlex>
  )
}
