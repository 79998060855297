import { Flex } from 'influ-dms'
import { EditPostModalContentHeaderDelete } from './EditPostModalContentHeaderDelete'
import { EditPostModalContentHeaderRefresh } from './EditPostModalContentHeaderRefresh'

export const EditPostModalContentHeaderButtons = ({ post }) => {
  const showRefresh = post.dataSource !== 'tracking'

  return (
    <Flex>
      {showRefresh && <EditPostModalContentHeaderRefresh post={post} />}
      <EditPostModalContentHeaderDelete postId={post.publicationInReportV2Id} />
    </Flex>
  )
}
