import { useContext } from 'react'
import { FormContext, StateButton } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { checkProfileMutation } from '../../../../apollo/checkPostQueries'
import { useTranslate } from '~/hooks/useTranslate'

export const AddPostsManualCheckProfileButton = ({ profileHasBeenChecked, onCheckProfileSuccess, onCheckProfileError }) => {
  const { t } = useTranslate()
  const errors = {
    private: t('commons.insights.influencerProfile.input.helpertext.privateAccountOrRestricted.error'),
    notExists: t('commons.insights.influencerProfile.input.helpertext.thisProfileDoesntExist.error')
  }
  const [checkProfile, { loading }] = useMutation(checkProfileMutation)
  const { values, setValue } = useContext(FormContext)
  const handleOnClick = () => {
    checkProfile({ variables: { account: values.username, network: values.network } })
      .then(({ data: { checkProfile } }) => {
        if (checkProfile.exists) {
          setValue('InputText', 'username', checkProfile.username)
          setValue('InputText', 'profileUrl', checkProfile.profileUrl)
          onCheckProfileSuccess()
        } else if (checkProfile.error) {
          onCheckProfileError(errors.notExists)
        } else {
          onCheckProfileError(checkProfile.private ? errors.private : checkProfile.exists ? errors.notExists : t('commons.insights.influencerProfile.input.helpertext.somethingHappend.error'))
        }
      })
      .catch(() => {
        onCheckProfileError(t('commons.insights.influencerProfile.input.helpertext.somethingHappend.error'))
      })
  }
  const disabled = profileHasBeenChecked || !(values.username && values.network)

  return (
    <StateButton
      loading={loading}
      disabled={disabled}
      showLoader
      size='s'
      onClick={handleOnClick}
    >{t('commons.button.verifyProfile')}
    </StateButton>
  )
}
