import { gql } from '@apollo/client'

export const confirmPayment = gql`
  mutation confirmPayment(
    $paymentInvoiceId: String!
  ) {
    confirmPayment(
      paymentInvoiceId: $paymentInvoiceId
    ){
      message
      code
      success
    }
  }
`
