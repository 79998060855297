import { useContext, useState } from 'react'
import { get } from 'lodash'
import { useMutation } from '@apollo/client'
import { Dropdown, DropdownOptionItem, Flex, Text } from 'influ-dms'
import { getProfileSharedLink } from '~/containers/irm/apollo'
import { RouterHelper } from '~/routing/routerHelper'
import { useTranslate } from '~/hooks/useTranslate'
import { NotifyProfileDataProcessedContext } from '~/context/irm/profiles/NotifyProfileDataProcessed'
import { useRefreshAction, PrintEntityTypes, useToPrint } from '.'
import { STARTABLE_STATUS_CODES } from '~/containers/irm/commons/constants'
import { useBrandData } from '~/hooks/useBrandData'
import { useRouter } from 'next/router'
import { AVAILABLE_NETWORKS } from '~/constants'
import {
  ActionIcons,
  Actions,
  ProfileExport,
  StyledSeparator
} from '~/containers/irm/commons/components/ActionButtons'
import { MoreOptionsForInsights } from '../../influencers/Insights/MoreOptionsForInsights/MoreOptionsForInsights'
import Action from '../components/ActionButtons/Action'
import { useRefreshUpdateMutation } from './useRefreshUpdateMutation'
import { ProfileContext } from '~/containers/irm/profiles/commons/context'

export const useProfileActions = (
  profile,
  isInfluencer,
  openLinkInfluencer,
  previewProfile,
  setProfileToAssociate
) => {
  const profileId = profile?._id
  const profileNetwork = profile?.network
  const profileUsername = profile?.username
  const statusFromMetrics = profile?.status

  const { t } = useTranslate()
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [isOpenRefreshModal, setIsOpenRefreshModal] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [addToOpened, setAddToOpened] = useState(false)
  const [sharedUrl, setSharedUrl] = useState()

  const { setProfileId, openAssociateToListModal } = useContext(ProfileContext)

  const { handleUpdateProfileMutation } = useRefreshUpdateMutation({ profileIds: [profileId], onUpdateSuccess: () => setIsOpenRefreshModal(false) })
  const { doSingleRefresh } = useRefreshAction()
  const { profilesLoading } = useContext(NotifyProfileDataProcessedContext)
  const [mutationLink] = useMutation(getProfileSharedLink)

  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { featureFlags } = useBrandData({ variables: { brandId }, skip: !brandId })

  const canPrint = get(featureFlags, 'irm.pdfInfluencer')
  const canExport = get(featureFlags, 'irm.exportCSV')
  const isProfileRefreshing = profilesLoading.includes(profileId)
  const isNotAvailableNetwork = !AVAILABLE_NETWORKS.some((network) => network === profileNetwork)

  const { goTo: goToPrint } = useToPrint({
    entity: PrintEntityTypes.PROFILE,
    entityId: profileId,
    limitText: t('commons.modals.blocked.titleHeader1.printProfilePdfs'),
    canPrint
  })

  const getUrl = async () => {
    const link = await mutationLink({ variables: { profileId } })
    const sharedId = get(link, 'data.getProfileSharedLink.sharedId')
    const domain = typeof window !== 'undefined' ? window.location.origin : ''
    const route = sharedId ? RouterHelper.getUrl('profilePublic', { sharedId }) : ''
    setSharedUrl(`${domain}${route}`)
  }

  const onShare = () => {
    getUrl()
    setShareModalOpen(true)
  }

  const handleDelete = () => {
    setDeleteModalOpen(true)
  }

  const renderAddTo = (openLinkInfluencer) => {
    return (
      <Dropdown
        isOpen={addToOpened}
        setOpen={setAddToOpened}
        align={'right'}
        top='3px'
        width='auto'
      >
        <DropdownOptionItem
          onClick={() => {
            setProfileToAssociate && setProfileToAssociate(profile)
            setAddToOpened(false)
            openLinkInfluencer()
          }}
          data-cy='add-to-influencer'>
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.addToMyInfluencers')}
            </Text>
          </Flex>
        </DropdownOptionItem>
        <DropdownOptionItem
          onClick={() => {
            setProfileId(profileId)
            openAssociateToListModal()
          }}
          data-cy='add-to-list'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.addToList')}
            </Text>
          </Flex>
        </DropdownOptionItem>
      </Dropdown>
    )
  }

  const renderActions = (openLinkInfluencer) =>
    <Flex data-cy='Profile-Actions-Header' column justify='center'>
      <Flex align='center'>
        <Action key={Actions.REFRESH}
          onClick={() => doSingleRefresh({ profileNetwork, setIsOpenRefreshModal, handleUpdateProfileMutation })}
          iconName={ActionIcons[Actions.REFRESH]}
          text={isNotAvailableNetwork
            ? t('commons.button.refresh.tooltip.warning.noDataAvailable')
            : t('commons.button.refreshData.tooltip')}
          spinWhenDisabled={STARTABLE_STATUS_CODES.includes(statusFromMetrics) || isProfileRefreshing}
          name={Actions.REFRESH}
          isDisabled={ STARTABLE_STATUS_CODES.includes(statusFromMetrics) ||
        isProfileRefreshing ||
        isNotAvailableNetwork} />

        <StyledSeparator />
        <Action key={Actions.SHARE}
          onClick={onShare}
          iconName={ActionIcons[Actions.SHARE]}
          text={t('commons.button.share.tooltip')}
        />
        <Action key={Actions.PRINT}
          onClick={goToPrint}
          iconName={ActionIcons[Actions.PRINT]}
          text={t('commons.button.print.tooltip')}
        />
        <Action key={Actions.EXPORT}
          text={t('commons.button.export.tooltip')}
          customComponent={() => (
            <ProfileExport
              brandId={brandId}
              profileId={profileId}
              canExport={canExport}
              profileUsername={profileUsername}
              username={`@${profileUsername}`}
              domain={'profile.detail'}
              query={{ profileDetailId: profileId }}
            />)}
        />
        <Action key={Actions.ADD_TO}
          isDropdown={true}
          iconName={ActionIcons[Actions.ADD_TO]}
          text={t('commons.button.addTo.tooltip')}
          dropdownComponent={() => renderAddTo(openLinkInfluencer)}
          onClick={() => setAddToOpened(true)}
        />
        <StyledSeparator />
        {isInfluencer ? (
          <Action key={Actions.MORE_OPTIONS}
            customComponent={() => <MoreOptionsForInsights profileId={profileId} /> }
          />
        ) : (
          <Action key={Actions.DELETE}
            onClick={handleDelete}
            iconName={ActionIcons[Actions.DELETE]}
            text={t('commons.button.delete.tooltip')}
          />
        )}
      </Flex>
    </Flex>

  const renderComparatorActions = () => (
    <Flex gap='4px'>
      <Action key={Actions.PREVIEW}
        onClick={() => previewProfile(profileId)}
        iconName={ActionIcons[Actions.PREVIEW]}
        text={t('commons.button.preview.tooltip')}
        isComparator={true}
        />
      <Action key={Actions.SHARE}
        onClick={onShare}
        iconName={ActionIcons[Actions.SHARE]}
        text={t('commons.button.share.tooltip')}
        isComparator={true}
        />
      <Action key={Actions.PRINT}
        onClick={goToPrint}
        iconName={ActionIcons[Actions.PRINT]}
        text={t('commons.button.print.tooltip')}
        isComparator={true}
        />
      <Action key={Actions.EXPORT}
        text={t('commons.button.export.tooltip')}
        customComponent={() => (
          <ProfileExport
            brandId={brandId}
            profileId={profileId}
            canExport={canExport}
            profileUsername={profileUsername}
            username={`@${profileUsername}`}
            domain={'profile.detail'}
            query={{ profileDetailId: profileId }}
            isComparator={true}
            />)}
        />
      <Action key={Actions.ADD_TO}
        isDropdown={true}
        iconName={ActionIcons[Actions.ADD_TO]}
        text={t('commons.button.addTo.tooltip')}
        isComparator={true}
        dropdownComponent={() => renderAddTo(openLinkInfluencer)}
        onClick={() => setAddToOpened(true)}
        />
    </Flex>
  )

  return {
    renderActions,
    renderComparatorActions,
    shareModalOpen,
    deleteModalOpen,
    sharedUrl,
    setShareModalOpen,
    setSharedUrl,
    setDeleteModalOpen,
    isOpenRefreshModal,
    setIsOpenRefreshModal
  }
}
