import { gql } from "@apollo/client";

export const getDomain = gql`
  query getDomain(
    $_id: String!
  ) {
    getDomain(_id: $_id) {
      id: _id
      domainName
      status
      dnsRecords
    }
  }
`
