import styled from 'styled-components'

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none !important;
  }
`

export { Content }
