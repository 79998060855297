import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, Form, FormContext } from 'influ-dms'
import { useLazyQuery } from '@apollo/client'
import { get, debounce, isEqual } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'
import { getMasterTableCities } from '~/graphql/commons'
import { captureException } from '@sentry/nextjs'

const CityContainer = styled.div`
  flex: 2 0 0;
  margin-right: 16px;
`
const PercentageContainer = styled.div`
  flex: 1 0 0;
`

const cityLabels = [
  'commons.insights.audienceCityOne.input.label',
  'commons.insights.audienceCityTwo.input.label',
  'commons.insights.audienceCityThree.input.label',
  'commons.insights.audienceCityFour.input.label',
  'commons.insights.audienceCityFive.input.label'
]

export const AddPostsManualTiktokCityMetrics = ({ i }) => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const [optionCities, setOptionCities] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const options = { variables: { query: '', type: 'cities' }, fetchPolicy: 'network-only' }
  const [getCities] = useLazyQuery(getMasterTableCities, { fetchPolicy: 'network-only' })
  const cityName = `additionalMetrics.cities[${i - 1}].cityName`
  const percentageName = `additionalMetrics.cities[${i - 1}].cityPercentage`
  const hasCityValue = !!get(values, cityName)
  const hasPreviousCityValue = i === 1 || !!get(values, `additionalMetrics.cities[${i - 2}].cityName`)

  useEffect(() => {
    if (!hasPreviousCityValue && hasCityValue) {
      setValue('InputText', cityName, null)
      setValue('InputText', percentageName, null)
    }
  }, [hasPreviousCityValue])

  useEffect(() => {
    if (hasCityValue && get(values, percentageName) === null) {
      setValue('InputText', percentageName, 0)
    }
    const newSelectedCities = get(values, 'additionalMetrics.cities', []).map(({ cityName }) => cityName)
    if (!isEqual(selectedCities, newSelectedCities)) {
      setSelectedCities(newSelectedCities)
    }
  }, [values])

  useEffect(() => {
    if (selectedCities[i - 1]) {
      setValue('InputText', cityName, selectedCities[i - 1])
      setOptionCities([{ label: [selectedCities[i - 1]], value: selectedCities[i - 1] }])
    }
  }, [selectedCities])

  const onChange = e => {
    setValue('InputText', cityName, e.value)
    if (e.value && !get(values, percentageName)) {
      setValue('InputText', percentageName, 0)
    } else if (!e.value) {
      setValue('InputText', percentageName, null)
    }
  }

  const debouncedSearch = debounce(function (value, callback) {
    if (!value || value.length < 3) {
      return
    }
    loadCities(value, callback)
  }, 1000)

  const loadCities = async (searchValue, cbFunction) => {
    try {
      const newOptions = { ...options, variables: { ...options.variables, search: searchValue } }
      const response = await getCities(newOptions)
      const rawResults = get(response, 'data.getMasterTableCities', [])
      const results = rawResults.map(({ value, label }) => ({ label, value }))
      cbFunction(results)
      setOptionCities(results)
    } catch (e) {
      captureException('There was an error trying to fetch cities data', e)
    }
  }

  return (
    <Flex>
      <CityContainer>
        <Form.Select
          placeholder={t('commons.insights.selectCity.input.placeholder')}
          name={cityName}
          label={t(cityLabels[i - 1])}
          isDisabled={!hasPreviousCityValue}
          onChange={onChange}
          isSearchable
          isAsync
          options={optionCities}
          noOptionsMessage={({ inputValue }) => {
            return inputValue
              ? t('commons.modal.filtersInfluencers.input.selectPlaceholder.noResultsFound')
              : t('commons.modal.filtersInfluencers.input.selectPlaceholder.typeSomething')
          }}
          loadOptions={debouncedSearch}
          loadingMessage={() => `${t('commons.button.loading')}...`}
        />
      </CityContainer>
      <PercentageContainer>
        <Form.InputNumber placeholder='%' name={percentageName} label='%' />
      </PercentageContainer>
    </Flex>
  )
}
