import styled, { css } from 'styled-components'

export const imageStyles = css`
  margin: 8px;
  width: 100px;
`

export const HeaderWrapper = styled.div`
  z-index: 10;
  background: ${({ theme }) => theme.colors.neutral.white};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 28px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media print {
    position: fixed;
  }
`

export const BrandWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const avatarStyles = css`
  float: right;
`

export const brandNameStyles = css`
  margin: 8px;
  float: left;
`
export const textStyles = css`
  display: block;
  float: left;
`
