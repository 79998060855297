import { gql } from "@apollo/client";

export const getPaymentsSummary = gql`
  query getPaymentsSummary(
    $paymentInvoiceId: String!
  ) {
    getPaymentsSummary(
      paymentInvoiceId: $paymentInvoiceId
    ) {
      influencers {
        fullName
        amount
      }
      totals {
        amount
        fee
        feePercentage
        tax
        taxPercentage
        total
      }
      bankInfo {
        beneficiary
        iban
        bic
        intermediaryBic
        beneficiaryAddress
        bankPaymentInstitution
        bankPaymentInstitutionAddress
      }
    }
  }
`
