import { createContext, useReducer } from 'react'

const initialValues = {
  payment: undefined,
  isAddInfluencersModalOpen: {
    isOpen: false,
    ids: [],
    entityName: ''
  },
  isRemoveInfluencerModalOpen: false,
  isRemoveInfluencersModalOpen: false,
  isEditDescriptionModalOpen: false,
  isEditAmountModalOpen: false,
  isEditCountryModalOpen: false,
  editSummary: undefined,
  isEditSummaryModalOpen: false
}

export const PaymentContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setPayment':
      return { ...state, payment: action.payload }
    case 'setAddInfluencersModalOpen':
      return { ...state, isAddInfluencersModalOpen: action.payload }
    case 'setRemoveInfluencerModalOpen':
      return { ...state, isRemoveInfluencerModalOpen: action.payload }
    case 'setRemoveInfluencersModalOpen':
      return { ...state, isRemoveInfluencersModalOpen: action.payload }
    case 'setEditDescriptionModalOpen':
      return { ...state, isEditDescriptionModalOpen: action.payload }
    case 'setEditAmountModalOpen':
      return { ...state, isEditAmountModalOpen: action.payload }
    case 'setEditCountryModalOpen':
      return { ...state, isEditCountryModalOpen: action.payload }
    case 'setEditSummary':
      return { ...state, editSummary: action.payload }
    case 'setEditSummaryModalOpen':
      return { ...state, isEditSummaryModalOpen: action.payload }
  }
}

export const PaymentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setPayment = (payment) => {
    dispatch({ type: 'setPayment', payload: payment })
  }

  const setAddInfluencersModalOpen = (isOpen, ids, entityName) => {
    dispatch({ type: 'setAddInfluencersModalOpen', payload: { isOpen, ids, entityName } })
  }

  const setRemoveInfluencerModalOpen = (isOpen) => {
    dispatch({ type: 'setRemoveInfluencerModalOpen', payload: isOpen })
  }

  const setRemoveInfluencersModalOpen = (isOpen) => {
    dispatch({ type: 'setRemoveInfluencersModalOpen', payload: isOpen })
  }

  const setEditDescriptionModalOpen = (isOpen) => {
    dispatch({ type: 'setEditDescriptionModalOpen', payload: isOpen })
  }

  const setEditAmountModalOpen = (isOpen) => {
    dispatch({ type: 'setEditAmountModalOpen', payload: isOpen })
  }

  const setEditCountryModalOpen = (isOpen) => {
    dispatch({ type: 'setEditCountryModalOpen', payload: isOpen })
  }

  const setEditSummary = (editSummary) => {
    dispatch({ type: 'setEditSummary', payload: editSummary })
  }

  const setEditSummaryModalOpen = (isOpen) => {
    dispatch({ type: 'setEditSummaryModalOpen', payload: isOpen })
  }

  return (
    <PaymentContext.Provider value={{
      ...state,
      setPayment,
      setAddInfluencersModalOpen,
      setRemoveInfluencerModalOpen,
      setRemoveInfluencersModalOpen,
      setEditDescriptionModalOpen,
      setEditAmountModalOpen,
      setEditCountryModalOpen,
      setEditSummary,
      setEditSummaryModalOpen
    }}>
      {children}
    </PaymentContext.Provider>
  )
}
