import styled, { css } from 'styled-components'
import { CellWrapper, Flex, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { get } from 'lodash'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const cellPadding = css`
  padding: 0 24px;
  ${cellEllipsis};
`

const imagePostStyles = css`
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
  background-image: url('${({ image }) => image}'),
    url('/static/img/placeholders/avatarPlaceholder.png');
  background-size: cover;
  background-position: center;
`

const StyledContainer = styled(Flex)`
  ${cellPadding};
`

const ImageContainer = styled.div`
  width: 30px;
  height: 30px;
  ${imagePostStyles};
  border-radius: 50%;
`
const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out; 
    text-decoration: underline;
  }
`

const StyledFlex = styled(Flex)`
  width : 97%;
`

const textWrapperStyles = css`
  overflow: hidden;
`

export const ProfilesListTableTdInfluencerAlias = ({ row: { original }, onInfluencerClick, onAssociateInfluencerClick }) => {
  const { t } = useTranslate()
  const profile = original || {}
  const influencer = get(profile, 'irmInfluencers[0]')

  if (influencer) {
    const { alias, picture } = influencer
    return (
      <CellWrapper>
        <StyledFlex justify='space-between' align='center'>
          <StyledContainer align='center' >
            <ImageContainer image={picture} />
            <Flex column styles={textWrapperStyles}>
              {onInfluencerClick
                ? <Text ellipsis showTooltip styles={clickableStyles} size='14' color='primary.light2' bold onClick={() => onInfluencerClick(influencer._id)}>{alias}</Text>
                : <Text ellipsis showTooltip size='14' color='primary.light2' bold>{alias}</Text>
              }
            </Flex>
          </StyledContainer>
        </StyledFlex>
      </CellWrapper>
    )
  } else {
    return (
      <CellWrapper>
        <StyledFlex justify='space-between' align='center'>
          <StyledContainer align='center' >
            <Flex column>
              {onAssociateInfluencerClick
                ? <Text ellipsis showTooltip styles={clickableStyles} size='14' color='primary.light2' bold onClick={() => onAssociateInfluencerClick(profile)}>{t('commons.button.associateToAnInfluencer')}</Text>
                : <Text ellipsis showTooltip size='14' color='primary.light2' bold >-</Text>}
            </Flex>
          </StyledContainer>
        </StyledFlex>
      </CellWrapper>
    )
  }
}
