import { gql } from '@apollo/client'

export const campaignInfluencers = gql`
 query campaignQuery(
   $campaignId: String!,
 ) {
  campaignBeta(_id: $campaignId ){
    id: _id
    name
    currency{
      id: _id
      symbol
    }
    workflow{
      id: _id
      steps{
        name
        key
      }
    }
    influencers(sortBy: "order", orderBy: asc ){
      totalCount
      edges{
        id: _id
        influencerInNetworkId
        name
        step
        stepKey
        order
        campaignStatus
        clientStatus
        taskCount
        totalCost
        imageUrl
        location
        networks
        taskNetworks
        estimate
        createdAt
      }
    }
  }
 }
 `
