import { css } from 'styled-components'

import 'normalize.css/normalize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-phone-input-2/lib/style.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

export const shadowMedium = css`
  box-shadow: 0 10px 20px rgba(124, 113, 148, 0.07);
`

export const shadowLight = css`
  box-shadow: 0 1px 4px rgba(39, 0, 56, 0.12);
`
