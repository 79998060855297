import { gql } from '@apollo/client'

export const notifyCommunicationUserSubscription = gql`
subscription notifyCommunicationUser {
  notifyCommunicationUser {
    userId
    brandId
    account
    status
  }
}
`

export const notifyCommunicationBrandSubscription = gql`
subscription notifyCommunicationBrand($brandId: String!) {
  notifyCommunicationBrand(brandId: $brandId) {
    brandId
    account
    influencerId
    status
    messageId
    messageServerId
    messageIdForReplies
    inReplyTo
  }
}
`
