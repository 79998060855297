import { useContext } from 'react'
import { SnackbarContext, useCreateEditForm } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useTranslate } from '~/hooks'
import { useFilter } from '../../../commons/hooks/useFilter'
import { useCleanCache } from '../../../commons/hooks'
import { countTableIrmListsByBrand, unregisterRelationshipListInfluencers } from '../../../apollo'
import { useFilterQuery } from '../../../commons/hooks/useFilterQuery'
import { ListContext } from '../context/ListContext'
import {
  addIrmList as addIrmListCall,
  editIrmList as editIrmListCall,
  deleteIrmLists as deleteIrmListsCall,
  getIRMListsByBrand,
  getIRMListsByProfile,
  countTableIrmListsByProfile,
  getTableProfilesInListByBrand,
  countTableProfilesInListByBrand,
  getTableInfluencersInListByBrand,
  countTableInfluencersInListByBrand,
  updateAggregatedDataFromList,
  computeOverlap,
  getIRMListsByInfluencer,
  countTableIrmListsByInfluencer
} from '../apollo'
import { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse, getFormErrors } from '../../../../../constants/forms/getFormResponse'
import { captureException } from '@sentry/nextjs'

export const useLists = () => {
  const { clearListDetailCache, clearListTableCache, clearProfileInListsTableCache, clearInfluencerDetailCache, clearListsInInfluencer, clearInfluencerTableCache } =
    useCleanCache()
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)
  const {
    setIsLoadingMetrics,
    setListErrorModalOpen,
    setListEmptyAggregatedDataModalOpen,
    setIsListOverlapErrorModalOpen,
    setIsListOverlapEmptyDataModalOpen
  } = useContext(ListContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const sharedId = get(router, 'query.sharedId')
  const listId = get(router, 'query.listId')

  const {
    loadingData: loadingLists,
    loadingCount: loadingCountLists,
    setQueryOptions: setTableListsOptions,
    setCountQueryOptions: setCountTableListsOptions
  } = useFilterQuery({
    query: getIRMListsByBrand,
    queryAccessor: 'getIRMListsByBrand',
    countQuery: countTableIrmListsByBrand,
    countQueryAccessor: 'countTableIrmListsByBrand'
  })

  const {
    loadingData: loadingProfileLists,
    loadingCount: loadingCountProfileLists,
    setQueryOptions: setProfileListsOptions,
    setCountQueryOptions: setCountProfileListsOptions
  } = useFilterQuery({
    query: getIRMListsByProfile,
    queryAccessor: 'getIRMListsByProfile',
    countQuery: countTableIrmListsByProfile,
    countQueryAccessor: 'countTableIrmListsByProfile'
  })

  const {
    loadingData: loadingInfluencerLists,
    loadingCount: loadingCountInfluencerLists,
    setQueryOptions: setInfluencerListsOptions,
    setCountQueryOptions: setCountInfluencerListsOptions
  } = useFilterQuery({
    query: getIRMListsByInfluencer,
    queryAccessor: 'getIRMListsByInfluencer',
    countQuery: countTableIrmListsByInfluencer,
    countQueryAccessor: 'countTableIrmListsByInfluencer',
  })

  const {
    loadingData: loadingListProfiles,
    loadingCount: loadingCountListProfiles,
    setQueryOptions: setTableProfileListsOptions,
    setCountQueryOptions: setCountTableProfileListsOptions
  } = useFilterQuery({
    query: getTableProfilesInListByBrand,
    queryAccessor: 'getTableProfilesInListByBrand',
    countQuery: countTableProfilesInListByBrand,
    countQueryAccessor: 'countTableProfilesInListByBrand'
  })

  const {
    loadingData: loadingListInfluencers,
    loadingCount: loadingCountListInfluencers,
    setQueryOptions: setTableInfluencerListsOptions,
    setCountQueryOptions: setCountTableInfluencerListsOptions
  } = useFilterQuery({
    query: getTableInfluencersInListByBrand,
    queryAccessor: 'getTableInfluencersInListByBrand',
    countQuery: countTableInfluencersInListByBrand,
    countQueryAccessor: 'countTableInfluencersInListByBrand'
  })

  const [addIrmListMutation, { loading: loadingAddIrmList }] = useMutation(addIrmListCall, {
    update (cache, _, { variables }) {
      clearListTableCache()
      variables?.profileIds?.forEach((profileId) => clearProfileInListsTableCache(profileId))
      variables?.influencerIds?.forEach((influencerId) => {
        clearListsInInfluencer(influencerId)
        clearInfluencerDetailCache(influencerId)
      })

      cache.gc()
    }
  })
  const [deleteIrmListsMutation, { loading: loadingDeleteIrmLists }] = useMutation(
    deleteIrmListsCall,
    {
      update (cache, _, { variables }) {
        variables?.listIds?.forEach((listId) => clearListDetailCache(listId))
        clearListTableCache()
        clearProfileInListsTableCache()
        clearListsInInfluencer()
      }
    }
  )
  const [editIrmListMutation, { loading: loadingEditIrmList }] = useMutation(editIrmListCall, {
    update (cache, _, { variables }) {
      variables?._id && clearListDetailCache(variables._id)
      clearListTableCache({ evictCount: false })
    }
  })
  const [updateAggregatedData, { loading: loadingUpdateListAggregatedData }] = useMutation(
    updateAggregatedDataFromList,
    {
      update (cache, _, { variables }) {
        variables?.listId && cache.evict({ IrmListMetricsType: variables.listId })
      }
    }
  )
  const [computeOverlapMutation, { loading: loadingComputeOverlap }] = useMutation(computeOverlap)

  const [removeInfluencersMutation, { loading: loadingRemoveInfluencers }] = useMutation(unregisterRelationshipListInfluencers, {
    update (cache, _, { variables }) {
      variables?.listIds?.forEach((listId) => clearListDetailCache(listId))
      clearListTableCache(cache)
      clearInfluencerTableCache()
      variables?.influencerIds?.forEach((influencerId) => {
        clearInfluencerDetailCache(influencerId)
        clearListsInInfluencer(influencerId)
      })
      cache.gc()
    }
  })

  const { sendForm } = useCreateEditForm()
  const { getFilteredDataOptions } = useFilter()

  const getLists = async (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view },
        { brandId, view }
      )
      if (view === 'linkToProgramV2') {
        options.fetchPolicy = 'network-only'
        countOptions.fetchPolicy = 'network-only'
      }
      setTableListsOptions(options)
      page === 0 && setCountTableListsOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining lists', e)
    }
  }

  const addIrmList = async (originIdName, originId) => {
    try {
      const response = await sendForm(addIrmListMutation, false, {
        brandId,
        from: 'irm',
        [originIdName]: originId
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error adding irmList', e)
      return false
    }
  }

  const deleteIrmLists = async (listIds) => {
    const variables = { listIds }
    try {
      const response = await deleteIrmListsMutation({ variables })
      const count = listIds?.length
      showSnackbar(
        'success',
        t(
          count > 1
            ? 'commons.snackbar.success.deleteLists'
            : 'commons.snackbar.success.deleteList',
          { count }
        )
      )
      return response
    } catch (e) {
      captureException('Error deleting irmLists', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const editIrmList = async (listId) => {
    try {
      const response = await sendForm(editIrmListMutation, true, {
        brandId,
        _id: listId,
        from: 'irm'
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error editing irmList', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const getProfileLists = (filterParams, view = 'table', extraArgs) => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view, ...extraArgs },
        { brandId, view, ...extraArgs }
      )

      setProfileListsOptions(options)
      page === 0 && setCountProfileListsOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining profile lists', e)
    }
  }

  const getListProfiles = (filterParams, view = 'profilesList') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { listId, sharedId, view },
        { listId, sharedId, view }
      )

      setTableProfileListsOptions({
        ...options,
        skip: !brandId && !sharedId
      })
      page === 0 &&
        setCountTableProfileListsOptions({
          ...countOptions,
          skip: !brandId && !sharedId
        })
    } catch (e) {
      captureException('Error obtaining list profiles', e)
    }
  }

  const getInfluencerLists = (filterParams, view = 'table', extraArgs) => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view, ...extraArgs },
        { brandId, view, ...extraArgs }
      )

      setInfluencerListsOptions({
        ...options,
        skip: (!brandId && !sharedId) || (!extraArgs?.influencerId)
      })
      page === 0 && setCountInfluencerListsOptions({
        ...countOptions,
        skip: (!brandId && !sharedId) || (!extraArgs?.influencerId)
      })
    } catch (e) {
      captureException('Error obtaining influencer lists', e)
    }
  }

  const getListInfluencers = (filterParams, view = 'influencersList') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { listId, sharedId, view },
        { listId, sharedId, view }
      )

      setTableInfluencerListsOptions({
        ...options,
        skip: !brandId && !sharedId
      })

      page === 0 &&
        setCountTableInfluencerListsOptions({
          ...countOptions,
          skip: !brandId && !sharedId
        })
    } catch (e) {
      captureException('Error obtaining list influencers', e)
    }
  }

  const handleUpdateAggregatedData = async (listId, network) => {
    setIsLoadingMetrics(true)
    const variables = {
      listId,
      network
    }

    try {
      const response = await updateAggregatedData({ variables })

      const success = get(response, 'updateAggregatedDataFromList.success')
      const message = get(response, 'updateAggregatedDataFromList.message')

      if (!success) {
        captureException(message)
      }
      return true
    } catch (err) {
      const { message } = err
      const errors = getFormErrors()
      const _message = parseInt(message)

      if (errors['12643']?.code === _message) {
        setListErrorModalOpen(true)
      } else if (errors['12642']?.code === _message) {
        setListEmptyAggregatedDataModalOpen(true)
      } else {
        captureException('Error updating irmList aggregated data', err)
      }
      return false
    } finally {
      setIsLoadingMetrics(false)
    }
  }

  const computeIrmOverlap = async (listId, network) => {
    try {
      return await computeOverlapMutation({ variables: { listId, network } })
    } catch (err) {
      const code = get(err, 'message')
      if (code === '15102') {
        setIsListOverlapErrorModalOpen(true)
      } else if (code === '15105') {
        setIsListOverlapEmptyDataModalOpen(true)
      } else if (code === '496') {
        // TODO show buy more analysis
      } else {
        showSnackbar('error', t('commons.errorMessage.tryAgainLater'))
        captureException('Error updating irmList overlap', err)
      }
      return false
    }
  }

  const removeInfluencers = async (listIds, influencerIds) => {
    const variables = { listIds, influencerIds }
    try {
      const response = await removeInfluencersMutation({ variables })
      if (response?.data?.unregisterRelationshipListInfluencers?.success) {
        return true
      }
      throw Error
    } catch (e) {
      captureException('Error removing influencers from program', e)
      return false
    }
  }

  return {
    getLists,
    loadingLists,
    addIrmList,
    loadingAddIrmList,
    deleteIrmLists,
    loadingDeleteIrmLists,
    editIrmList,
    loadingEditIrmList,
    loadingCountLists,
    getProfileLists,
    loadingProfileLists,
    loadingCountProfileLists,
    getInfluencerLists,
    loadingInfluencerLists,
    loadingCountInfluencerLists,
    getListProfiles,
    loadingListProfiles,
    loadingCountListProfiles,
    getListInfluencers,
    loadingListInfluencers,
    loadingCountListInfluencers,
    handleUpdateAggregatedData,
    loadingUpdateListAggregatedData,
    computeIrmOverlap,
    loadingComputeOverlap,
    // loadingInfluencersInList,
    loadingRemoveInfluencers,
    removeInfluencers
  }
}
