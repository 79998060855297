import { gql } from "@apollo/client";

export const getPaymentRequestsByInvoice = gql`
  query getPaymentRequestsByInvoice(
    $brandId: String!
    $paymentInvoiceId: String!
    $limit: Int
    $page: Int
    $sortParams: PaymentRequestSortParamsType, 
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getPaymentRequestsByInvoice(
      brandId: $brandId,
      paymentInvoiceId: $paymentInvoiceId,
      sortParams: $sortParams,
      page: $page,
      limit: $limit,
      filters: $filters,
      view: $view
    ) {
      _id
      brandId
      status
      statusTranslated
      statusInPool
      alias
      fullName
      email
      description
      country
      amount
      currency
      createdByUser {
        firstName
      }
      createdAt
      updatedAt
      irmInfluencers {
        _id
        alias
        picture
      }
      paymentPools {
        _id
        poolNumber
      }
    }
  }
`
