import { Flex, Tag } from 'influ-dms'
import styled, { css } from 'styled-components'

export const MentionsWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgba(124, 113, 148, 0.3);
`

export const MentionListWrapper = styled(Flex)`
  width: 40%;
  min-width: 556px;
  height: 100%;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colors.primary.light4};
`

export const MentionPreviewWrapper = styled(Flex)`
  width: 60%;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.light6};
  border-radius: 0 8px 8px 0;
`

export const AlertMentionsTableContainerWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  & [role='table'] {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.primary.light5};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
    div:last-child {
      & [role='row'] {
        height: auto;
        border-top: none;
      }
    }
  }
  & [role='columnheader'] {
    div {
      border-left: none;
    }
  }
`

export const AlertMentionsTableContainerHeaderWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  min-height: 65px;
  padding: 16px 16px 0px 8px;
  box-sizing: border-box;
  > div {
    padding-bottom: 8px;
    align-items: center;
  }
  & [wrap='wrap'] {
    div {
      margin: 0;
      margin-right: 8px;
    }
  }
`

export const hoverStyles = css`
  :hover {
    > div {
      background: ${({ theme }) => theme.colors.primary.light6} !important;
    }
  }
`

export const loaderStyles = css`
  position: absolute;
  z-index: 10;
  backdrop-filter: blur(5px);
  display: flex;
  height: 80vh;
  width: 99vw;
  margin-top: -1px;
  align-items: center;
  justify-content: center;
`

export const emptyStateStyles = css`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-top-right-radius: 8px;
`

export const subtitleStyles = css`
  margin-top: -20px;
`

export const StyledContainer = styled(Flex)`
  padding: 16px 24px;
  width: 100%;
  cursor: pointer;
  ${({ theme, selected }) => selected && `
    background: ${theme.colors.primary.light6} !important;
  `}
`

export const SentimentTag = styled(Tag)`
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  height: 16px;
  padding: 2px 4px;
  align-items: center;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
`

export const mentionTextStyles = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const noWrapStyles = css`
  white-space: nowrap;
`

export const MentionPreviewHeader = styled(Flex)`
  height: 56px;
  padding: 8px 32px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
  border-radius: 0 8px 0 0;
`

export const MentionsPreviewBody = styled(Flex)`
  padding: 16px 24px;
  height: 100%;
  overflow-y: auto;
`

export const MentionsRelatedData = styled(Flex)`
  height: fit-content;
  padding: 16px;
  margin-bottom: 14px;
  border-radius: 8px;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

export const tagStyles = css`
  padding: 4px;
  font-size: 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary.dark};
`

export const ProcessingMentionsBar = styled(Flex)`
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.white};
  background-color: ${({ theme }) => theme.colors.blue.disabled};

  & > div {
    align-items: center;
  }
`

export const IconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`
