import { useEffect } from 'react'

export const useMessage = (callback) => {
  useEffect(() => {
    const eventCallback = event => {
      const { data } = event
      callback(data)
    }

    window.addEventListener('message', eventCallback)
    return () => window.removeEventListener('message', eventCallback)
  }, [])
}
