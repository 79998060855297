import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../../styles'

export const AddPostsManualInstagramReelMetrics = () => {
  const { t } = useTranslate()
  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationMetrics.title')}</Text>
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.plays.input.placeholder')} name='videoPlays' label={t('commons.insights.plays.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.views.input.placeholder')} name='videoViews' label={t('commons.insights.views.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.likes.input.placeholder')} name='likes' label={t('commons.insights.likes.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.comments.input.placeholder')} name='comments' label={t('commons.insights.comments.input.label')} />
      <Spacing size='8' />
    </SideBox>
  )
}
