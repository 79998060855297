import { gql } from '@apollo/client'

const searchBrandsAutocomplete = gql`
    query searchBrands($query: String!) {
        searchBrands(
            query: $query,
        ) {
            code
            name
        }
    }
`

export { searchBrandsAutocomplete }
