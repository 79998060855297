import { servers, steps } from '../conf'
import { OfficeTerms } from './OfficeTerms'
import { ImapTerms } from './ImapTerms'

export const Terms = ({ server, setStep, handleCancel }) => {
  const handleAccept = () => {
    setStep([servers.imap.id].includes(server) ? steps.warning : steps.form)
  }
  return (
    <>
      {server === servers.outlook.id && <OfficeTerms handleAccept={handleAccept} handleCancel={handleCancel} />}
      {server === servers.imap.id && <ImapTerms handleAccept={handleAccept} handleCancel={handleCancel} />}
    </>
  )
}
