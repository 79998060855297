import { useContext, useEffect, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { FormContext, Form, SideBox, Spacing, Text } from 'influ-dms'
import { AddPostsManualCheckUrlButtons } from './AddPostsManualCheckUrlButtons'
import { headerStyles } from '../styles'
import { useMutation, useQuery } from '@apollo/client'
import { checkUrlMutation, checkUrlOnEditMutation } from '../../../../apollo/checkPostQueries'
import { postNetworkValidator, postValidator } from '../../addPostsHelper'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { brand } from '../../../../../../graphql/brands'
import { LimitsContext } from '../../../../../../context'
import { ModalsContext } from '../../../../../commons/context'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '~/context/Language'

const sideBoxStyles = css`
  padding: 0;
`
const PaddedContainer = styled.div`
  padding: 16px 16px 20px 16px;
`

export const AddPostsManualCheckUrl = ({ continueWithoutUrl, onCheckUrlExists, currentStep, isEdit, isUrlEmpty, postId }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()

  const CHECK_URL_ERRORS = useMemo(() => ({
    409: t('commons.insights.url.input.helpertext.publicationExistForThisReport.error'),
    12002: t('commons.insights.url.input.helpertext.urlMustCoincideWithNetwork.error'),
    12003: t('commons.insights.url.input.helpertext.urlMustCoincideWithUserName.error'),
    12004: t('commons.insights.url.input.helpertext.urlMustCoincideWithThePublicationType.error')
  }), [locale])

  const [checkUrl, { loading }] = useMutation(checkUrlMutation)
  const [checkUrlOnEdit, { loading: editLoading }] = useMutation(checkUrlOnEditMutation)
  const [urlHasBeenChecked, setUrlHasBeenChecked] = useState(false)
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const reportId = get(router, 'query.reportId')
  const brandId = get(router, 'query.brandId')
  const { setValue } = useContext(FormContext)
  const [url, setUrl] = useState()
  const [urlErrors, setUrlErrors] = useState()
  const firstStep = currentStep === 0
  const isEditDisabled = isEdit && !isUrlEmpty
  const isButtonDisabled = !url || urlErrors !== null || isEditDisabled || urlHasBeenChecked
  const isInputDisabled = currentStep === 1 || isEditDisabled
  const showFormatError = url && urlErrors !== null
  const showButtons = firstStep || (isEdit && isUrlEmpty)
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId || isPublic })
  const networksInReportsV2 = get(data, 'brand.featureFlags.reportsV2.networks', [])
  const { validateLimit } = useContext(LimitsContext)
  const { dispatch } = useContext(ModalsContext)

  const handleContinueWithoutUrl = () => {
    setValue('InputText', 'url', '')
    continueWithoutUrl()
  }

  useEffect(() => {
    if (postId) {
      setUrlErrors(undefined)
    }
  }, [postId])

  useEffect(() => {
    if (url && !urlHasBeenChecked) {
      setValue('InputText', 'error', t('commons.insights.url.input.helpertext.urlNotChecked.error'))
    } else {
      setValue('InputText', 'error', url && urlErrors ? urlErrors : null)
    }
  }, [urlHasBeenChecked, url, urlErrors])

  const onUrlChange = e => {
    const url = e.target.value
    setUrl(url)
    setUrlErrors(postValidator(url, 'manual', t))
    setUrlHasBeenChecked(false)
  }

  const onClick = () => {
    const validNetworkUrl = postNetworkValidator(url, networksInReportsV2)
    if (validNetworkUrl === null) {
      const mutation = isEdit ? checkUrlOnEdit : checkUrl
      const variables = { url, publicationInReportV2Id: postId }
      if (!isEdit) {
        variables.reportV2Id = reportId
      }
      mutation({ variables }).then(({ data: { checkUrl = {}, checkUrlOnEdit = {} } }) => {
        if (checkUrl.exists || checkUrlOnEdit.exists) {
          setUrlHasBeenChecked(true)
          if (!isEdit) {
            onCheckUrlExists()
            setValue('InputText', 'username', checkUrl.username)
            setValue('InputText', 'network', checkUrl.network)
            setValue('InputText', 'type', checkUrl.postType)
          }
          setValue('InputText', 'profileUrl', checkUrl.profileUrl || checkUrlOnEdit.profileUrl)
        } else {
          setUrlErrors(t('commons.insights.url.input.helpertext.publicationUrlnotValidPleaseCheck.error'))
        }
      })
        .catch((error) => {
          if (Object.keys(CHECK_URL_ERRORS).indexOf(error.message) > -1) {
            setUrlErrors(CHECK_URL_ERRORS[+error.message])
          } else {
            setUrlErrors(t('commons.errorMessage.tryAgainLater'))
          }
        })
    } else {
      validateLimit(false)
      dispatch({ type: 'toggleAddPosts' })
    }
  }

  return (
    <SideBox styles={sideBoxStyles}>
      <PaddedContainer>
        <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{firstStep ? t('reports.report.modal.addPublication.manualAnalyses.douYouHaveTheUrlBlock.title') : t('reports.report.modal.publication.publicationUrlBlock.title')}</Text>
        <Spacing size='16' />
        <Form.InputText placeholder={t('commons.insights.url.input.placeholder')} disabled={isInputDisabled} trim name='url' label={t('commons.insights.url.input.label')} onChange={onUrlChange} />
        <Spacing size='4' />
        {showFormatError
          ? <Text size='10' color='state.dangerDefault'>{urlErrors}</Text>
          : firstStep ? <Text size='10' color='primary.light3'>{t('commons.insights.url.input.helpertext.notValidPublicationUrl.helpertext')}</Text> : null}
      </PaddedContainer>
      {showButtons &&
        <AddPostsManualCheckUrlButtons
          onClick={onClick}
          continueWithoutUrl={handleContinueWithoutUrl}
          loading={loading || editLoading}
          isButtonDisabled={isButtonDisabled}
          showContinueWithoutUrl={!isEdit}
        />}
    </SideBox>
  )
}
