import { gql } from '@apollo/client'

const profileMetricsFragment = gql`
  fragment profileMetrics on ProfileMetrics {
    notableFollowersRatio {
      calculated
      notableFollowersRatioValue
    }
    sponsoredPostsEngagementRatio {
      calculated
      sponsoredPostsEngagementRatioValue
    }
    likers {
      geo {
        cities {
          calculated
          topCitiesList {
            cityName
            count
            percentage
            realUsers
            translationKey
          }
        }
        countries {
          calculated
          topCountriesList {
            count
            countryCode
            countryName
            geometry
            percentage
            realUsers
            translationKey
          }
        }
      }
      genderGraph {
        calculated
        dominantGender {
          gender
          percentage
        }
        genderGraph {
          gender
          percentage
        }
      }
      generateTopics {
        likersTopicsByWeight {
          calculated
          topics {
            key
            value
          }
        }
        topicsLikers {
          calculated
          topics
        }
      }
      ages {
        agesGraph {
          percentage
          range
        }
        averageAgeRange
        calculated
      }
      agesByGender {
        agesByGenderGraph {
          men
          range
          total
          women
        }
        calculated
      }
      likersBrandAffinity {
        likersBrandAffinity {
          categories
          name
          src
        }
        calculated
      }
      likersEthnicities {
        likersEthnicitiesGraph {
          key
          label
          originalLabel
          translationKey
          type
          value
        }
        calculated
      }
      likersLanguages {
        likersLanguagesGraph {
          key
          label
          originalLabel
          translationKey
          type
          value
        }
        calculated
      }
      likersReachability {
        likersReachabilityGraph {
          key
          value
        }
        calculated
      }
      likersLookalikes {
        likersLookalikesList {
          engagements
          followers
          fullname
          isVerified
          location
          picture
          url
          username
        }
        calculated
      }
      notableLikers {
        calculated
        notableLikersList {
          engagements
          followers
          fullname
          isVerified
          location
          picture
          url
          username
        }
      }
      notableLikersRatio {
        calculated
        notableLikersRatioValue
      }
      likesNotFromFollowers {
        calculated
        likesNotFromFollowersValue
      }
    }
    ages {
      agesGraph {
        percentage
        range
      }
      averageAgeRange
      calculated
    }
    agesByGender {
      agesByGenderGraph {
        men
        range
        total
        women
      }
      calculated
    }
    audienceEthnicities {
      audienceEthnicitiesGraph {
        key
        label
        originalLabel
        translationKey
        type
        value
      }
      calculated
    }
    audienceLanguages {
      audienceLanguagesGraph {
        key
        label
        originalLabel
        translationKey
        type
        value
      }
      calculated
    }
    audienceLookalikes {
      audienceLookalikesList {
        engagements
        followers
        fullname
        isVerified
        location
        picture
        url
        username
      }
      calculated
    }
    audienceReachability {
      audienceReachabilityGraph {
        key
        value
      }
      calculated
    }
    averageLikesStats {
      calculated
      monthlyStats {
        month
        value
      }
      rate
    }
    averageViewsStats {
      calculated
      monthlyStats {
        month
        value
      }
      rate
    }
    avgInteractions {
      avgInteractionsGraph {
        type
        value
      }
      calculated
    }
    avgInteractionsBy30Posts {
      avgInteractionsBy30PostsGraph {
        type
        value
      }
      calculated
    }
    engagement {
      calculated
      engagementGraph {
        histogram {
          isUserRange
          max
          median
          min
          total
        }
        rate
      }
    }
    followersStats {
      calculated
      monthlyStats {
        month
        value
      }
      rate
    }
    followingStats {
      calculated
      monthlyStats {
        month
        value
      }
      rate
    }
    genderGraph {
      calculated
      dominantGender {
        gender
        percentage
      }
      genderGraph {
        gender
        percentage
      }
    }
    generateTopics {
      audienceTopicsByWeight {
        calculated
        topics {
          key
          value
        }
      }
      topicsAudience {
        calculated
        topics
      }
      topicsInfluencer {
        calculated
        topics
      }
    }
    geo {
      cities {
        calculated
        topCitiesList {
          cityName
          count
          percentage
          realUsers
          translationKey
        }
      }
      countries {
        calculated
        topCountriesList {
          count
          countryCode
          countryName
          geometry
          percentage
          realUsers
          translationKey
        }
      }
    }
    hashtags {
      calculated
      hashtagsGraph {
        key
        value
      }
    }
    influenceGraph {
      calculated
      graph {
        audience
        percentage
      }
    }
    influencerBrandAffinity {
      calculated
      influencerBrandAffinity {
        categories
        name
        src
      }
    }
    audienceBrandAffinity {
      audienceBrandAffinity {
        categories
        name
        src
      }
      calculated
    }
    influencerGrowthStats {
      calculated
      growthHistory {
        avgLikes {
          last2Months
          last3Months
          last4Months
          last5Months
          last6Months
          lastMonth
        }
        avgViews {
          last2Months
          last3Months
          last4Months
          last5Months
          last6Months
          lastMonth
        }
        followers {
          last2Months
          last3Months
          last4Months
          last5Months
          last6Months
          lastMonth
        }
      }
    }
    influencerLookalikes {
      calculated
      influencerLookalikesList {
        engagements
        followers
        fullname
        isVerified
        location
        picture
        url
        username
      }
    }
    mentions {
      calculated
      mentionsGraph {
        key
        value
      }
    }
    notableFollowers {
      calculated
      notableFollowersList {
        engagements
        followers
        fullname
        isVerified
        location
        picture
        url
        username
      }
    }
    sponsoredPosts {
      calculated
      sponsoredPostsList {
        created
        hashtags
        image
        link
        mentions
        post_id
        sponsor {
          user_id
          username
        }
        stat {
          comments
          dislikes
          likes
          shares
          views
        }
        text
        thumbnail
        type
        user_id
        user_picture
        user_url
        username
      }
    }
    topPosts {
      calculated
      topPostsList {
        created
        hashtags
        image
        link
        mentions
        post_id
        stat {
          comments
          dislikes
          likes
          shares
          views
        }
        text
        thumbnail
        type
        user_id
        user_picture
        user_url
        username
      }
    }
    topReels{
      calculated
      topReelsList {
        created
        image
        link
        mentions
        post_id
        stat {
          comments
          dislikes
          likes
          shares
          views
        }
        text
        thumbnail
        type
        user_id
        user_picture
        user_url
        username
      }
    }
    streamAverageViewDuration {
      calculated
      streamAuthViewersViewDuration
      streamAvgDailyEstimatedAudienceViewDuration
    }
    streamUniqueViewers {
      calculated
      streamUniqueViewers{
        value
        percentage
      }
      streamUniqueAuthViewers{
        value
        percentage
      }
    }
    streamEstimateIncome {
      calculated
      streamMinIncomeFromSubs
      streamMaxIncomeFromSubs
    }
    streamContentAuthorized {
      calculated
      streamContentAuthorized{
        percentage
      }
      streamContentNoAuthorized{
        percentage
      }
    }
    streamSubscribedType {
      calculated
      streamPaidSubs{
        value
        percentage
      }
      streamGiftedSubs{
        value
        percentage
      }
    }
    streamActiveSubsByTier {
      calculated
      streamPrimeSubs{
        value
        percentage
      }
      streamTier1Subs{
        value
        percentage
      }
      streamTier2Subs{
        value
        percentage
      }
      streamTier3Subs{
        value
        percentage
      }
    }
    streamTopGames {
      calculated
      streamTopGames{
        name
        hoursWatched
        percentage
      }
    }
    streamChatKPIs {
      calculated
      streamTotalMessages
      streamActiveChatters
      streamEngagement
      streamTotalBits
      streamIncomeFromBits
    }
    streamAdditionalStats {
      calculated
      streamTotalWordsCount
      streamPassiveChatters
      streamMessagesPerChatter
    }
    streamChatterWith {
      calculated
      streamChattersWith1Message{
        value
        percentage
      }
      streamChattersWith2Message{
        value
        percentage
      }
    }
    streamCheersAndBits {
      calculated
      streamCheerMessages
      streamCheerChatters
      streamBitsPerCheeredMessage
      streamBitsPerCheeredChatter
    }
    totalKPIs {
      calculated
      totals {
        accounts
        accountsNotAnalyzed
        acumulatedReach
        doubtfulFollowersPercentage
        earnedMedia
        engagement
        following
        hasProfilesNotAnalyzed
        niceFollowersPercentage
        realFollowersPercentage
        totalEarnedMedia
        totalInteractions
        totalPosts
        totalReach
        realLikersPercentage
        niceLikersPercentage
        doubtfulLikersPercentage
        totalReachLikers
        streamFollowers
        streamTotalSubs
        streamHoursWatched
        streamPeakViewers
        streamAverageViewers
        streamAirtime
        streamStreams
        streamLiveViews
        streamDailyEstimatedAudience
        streamFollowersGain
      }
    }
  }
`

export const profileMetricsQuery = gql`
  query profile($profileId: String!, $locale: String) {
    profile(_id: $profileId, locale: $locale) {
      _id
      analyzeProfile
      errorStartTime
      errorEstimatedTime
      followerCount
      followingCount
      fullName
      lastRefreshDate
      network
      isVerified
      noAudienceDataAvailable
      private
      profilePhotoUrl
      status
      hasFollowersData
      hasLikersData
      url
      username
      streamPartnershipStatus
      irmInfluencers {
        _id
      }
      metrics {
        ...profileMetrics
      }
    }
  }
  ${profileMetricsFragment}
`

export const profileForComparatorQuery = gql`
  query profile($profileId: String!, $locale: String) {
    profile(_id: $profileId, locale: $locale) {
      _id
      fullName
      username
      network
      status
      irmInfluencers {
        _id
      }
    }
  }
`

export const profilesToPrint = gql`
  query profilesToPrint(
    $brandId: String!,
    $influencerIds: [String]!,
    $locale: String,
  ) {
    profilesToPrint(
      brandId: $brandId,
      influencerIds: $influencerIds,
      locale: $locale,
    ) {
      _id
      analyzeProfile
      errorStartTime
      errorEstimatedTime
      followerCount
      followingCount
      fullName
      lastRefreshDate
      network
      isVerified
      noAudienceDataAvailable
      private
      profilePhotoUrl
      status
      hasFollowersData
      hasLikersData
      url
      username
      streamPartnershipStatus
      metrics {
        ...profileMetrics
      }
    }
  }
  ${profileMetricsFragment}
`

export const profileBySharedLink = gql`
  query profileBySharedLink (
    $sharedId: String!
    $type: String
    $profileId: String
    $locale: String
  ) {
    profileBySharedLink(sharedId: $sharedId, type: $type, profileId: $profileId, locale: $locale) {
      _id
      analyzeProfile
      errorStartTime
      errorEstimatedTime
      followerCount
      followingCount
      fullName
      lastRefreshDate
      brandLogo
      network
      isVerified
      noAudienceDataAvailable
      private
      profilePhotoUrl
      status
      hasFollowersData
      hasLikersData
      url
      username
      streamPartnershipStatus
      irmInfluencers {
        _id
      }
      metrics {
        ...profileMetrics
      }
    }
  }
  ${profileMetricsFragment}
`

export const updateProfileMutation = gql`
  mutation updateProfile($brandId: String!, $profileIds: [String]!, $from: String!, $sync: Boolean) {
    updateProfile(brandId: $brandId, profileIds: $profileIds, from: $from, sync: $sync){
      code
      message
      success
    }
  }
`
