import { css } from 'styled-components'
import { ActionIcons, Actions } from '~/containers/irm/commons/components/ActionButtons'

export const activeButtonsStyles = css`
  & {
    margin: 0;
    height: 28px;
    width: ${({ name }) => (name === ActionIcons[Actions.addMultiple] ? '40px' : '28px')};
    padding: 4px;
  }
  a {
    border-radius: 10%;
    width: 26px;
    box-shadow: none;
  }
`
