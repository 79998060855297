import styled from 'styled-components'

const DotSection = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: ${({ height }) => `${height}px;` || '100%;'}
`

export { DotSection }
