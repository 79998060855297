import { gql } from '@apollo/client'

export const getListsWithInfluencersForTracking = gql`
 query getListsWithInfluencersForTracking(
    $brandId: String!,
    $network: String!
) {
  getListsWithInfluencersForTracking(
    brandId: $brandId,
    network: $network,
    ) {
        _id
        name
        profiles{
          username
          network
          profilePicture
          followers
          profileUrl
          apiAccess
          analyzeProfile
        }
        numberOfProfiles
      }
 }
`
