import { gql } from '@apollo/client'

export const campaignQuery = gql`
 query campaignInfo(
   $campaignId: String!,
 ) {
  campaignBeta(_id: $campaignId ){
    id: _id
    name
    networks
    budget
    startDate
    endDate
    description
    goal
    countries
    influencers{
      totalCount
    }
    target{
      locations
      gender
      age{
        from
        to
        weight
      }
      interests
      additionalInfo
    }
    currency{
      id: _id
      symbol
    }
    workflow{
      id: _id
      name
      steps{
        name
        key
      }
    }
  }
 }
 `
