import { Flex, Image, Text, Spacing } from 'influ-dms'

export const FormatOptionLabel = ({ value, label }) => (
  <Flex>
    <Image
      src={`/static/img/icons/networks/${value}.png`}
      alt={`${value} icon`}
      width='16px'
      height='16px'
    />
    <Spacing vertical size={4} />
    <Text>{label}</Text>
  </Flex>
)
