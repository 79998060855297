import { useMemo, useContext } from 'react'
import {
  TableEditableTh,
  TableEditableThCheckbox,
  TableV2TdCheckbox,
  TableV2TdText,
  TableV2TdDate,
  TableV2TdNetworks,
  TableV2TdCurrency,
  TableEditableFilterNumberRange,
  TableEditableFilterRangeDatepicker,
  TableEditableFilterDropdown
} from 'influ-dms'
import { reportsTableRangeDateFilter } from '../../commons/reportsTable/filterBar/filters/rangeDatePicker/ReportsTableRangeDateFilter'
import { ReportsTableContainer } from '../../commons/reportsTable/ReportsTableContainer'
import { ReportsTableTdProfile } from './ReportsTableTdProfile'
import { reportsTableNumberRangeFilter } from '../../commons/reportsTable/filterBar/filters/numberRange/ReportsTableNumberRangeFilter'
import { ReportsPostTdPost } from './ReportsPostTdPost'
import { ReportsPostTdError } from './ReportsPostTdError'
import { CurrencyContext } from '../../../commons/context/CurrencyContext'
import { networkMapValues } from '~/constants'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '~/context/Language'

export const postTypeMap = (t) => {
  return (
    {
      photo: t('commons.publicationType.photo.label'),
      carousel: t('commons.publicationType.carousel.label'),
      story: t('commons.publicationType.story.label'),
      video: t('commons.publicationType.video.label'),
      reel: t('commons.publicationType.reels.label'),
      short: t('commons.publicationType.short.label')
    }
  )
}
export const dataSourceMap = (t) => {
  return (
    {
      automatic: t('commons.dataSource.automaticAnalysis.label'),
      manual: t('commons.dataSource.manualAnalysisAndAudienceInsights.label'),
      manualNoAudienceData: t('commons.dataSource.manualAnalysis.label'),
      tracking: t('commons.dataSource.tracking.label')
    }
  )
}
export const statusMap = (t) => {
  return (
    {
      processing: t('reports.publications.status.procesign.label'),
      available: t('reports.publications.status.available.label'),
      retry_later: t('reports.publications.status.retryLater.label'),
      error: t('reports.publications.status.weCannotProcessThePublication.label')
    }
  )
}
export const booleanMap = (t) => {
  return (
    {
      yes: t('commons.table.cell.registerdInfluencer.yes.label'),
      no: t('commons.table.cell.registerdInfluencer.no.label')
    }
  )
}

export const ReportsPostsTable = ({ tableId, postList, initialColumns, loading }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const { symbol } = useContext(CurrencyContext)

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        disableResizing: true,
        minWidth: 48,
        width: 48,
        maxWidth: 48,
        Header: ({ page }) => <TableEditableThCheckbox rows={page} />,
        Cell: TableV2TdCheckbox,
        disableFilters: true
      },
      {
        label: t('commons.table.column.publicationType.label'),
        accessor: 'type',
        Header: TableEditableTh,
        width: 200,
        minWidth: 150,
        Cell: ({ value, row }) => <ReportsPostTdPost row={row} value={value} valueMap={postTypeMap(t)} />,
        Filter: ({ column, preFilteredRows }) => TableEditableFilterDropdown({
          column,
          preFilteredRows,
          valueMap: postTypeMap(t)
        }),
        filter: 'includesValue',
        deleteAction: {
          event: 'toggleDeletePosts',
          featureFlag: 'brand.featureFlags.reportsV2.deleteUrl'
        },
        disableGlobalFilter: true
      },
      {
        label: t('commons.table.column.profile.label'),
        accessor: 'slug',
        Header: TableEditableTh,
        width: 150,
        minWidth: 100,
        Cell: ReportsTableTdProfile,
        disableFilters: true
      },
      {
        label: t('commons.table.column.socialNetwork.label'),
        accessor: 'network',
        Header: TableEditableTh,
        width: 130,
        minWidth: 50,
        Cell: TableV2TdNetworks,
        Filter: ({ column, preFilteredRows }) => TableEditableFilterDropdown({
          column,
          preFilteredRows,
          withIcon: true,
          valueMap: networkMapValues
        }),
        filter: 'includesValue',
        disableGlobalFilter: true
      },
      {
        label: t('commons.table.column.url.label'),
        accessor: 'url',
        Header: TableEditableTh,
        width: 220,
        minWidth: 150,
        Cell: ({ value }) => (<TableV2TdText value={value} onClick={() => window.open(value, '_blank')} />),
        disableFilters: true
      },
      {
        label: t('commons.table.column.dataSource.label'),
        accessor: 'dataSource',
        Header: TableEditableTh,
        width: 125,
        minWidth: 50,
        Cell: ({ value }) => {
          const textValue = useMemo(() => dataSourceMap(t)[value], [value])
          return (<TableV2TdText value={textValue} />)
        },
        Filter: ({ column, preFilteredRows }) => TableEditableFilterDropdown({
          column,
          preFilteredRows,
          valueMap: {
            automatic: t('commons.dataSource.automaticAnalysis.label'),
            manual: t('commons.dataSource.manualAnalysisAndAudienceInsights.label'),
            manualNoAudienceData: t('commons.dataSource.manualAnalysis.label'),
            tracking: t('commons.dataSource.tracking.label')
          }
        }),
        filter: 'includesValue',
        disableGlobalFilter: true
      },
      {
        label: t('commons.table.column.cost.label'),
        accessor: 'cost',
        Header: TableEditableTh,
        width: 100,
        minWidth: 50,
        Cell: ({ value }) => (<TableV2TdCurrency value={value} symbol={symbol} />),
        Filter: ({ column }) => (<TableEditableFilterNumberRange column={column} symbol={symbol} />),
        filter: reportsTableNumberRangeFilter,
        disableGlobalFilter: true
      },
      {
        label: t('commons.table.column.status.label'),
        accessor: 'status',
        Header: TableEditableTh,
        width: 100,
        minWidth: 50,
        Cell: ReportsPostTdError,
        Filter: ({ column, preFilteredRows }) => TableEditableFilterDropdown({
          column,
          preFilteredRows,
          valueMap: statusMap(t)
        }),
        filter: 'includesValue',
        disableGlobalFilter: true
      },
      {
        label: t('commons.table.column.publicationDate.label'),
        accessor: 'publicationDate',
        Header: TableEditableTh,
        width: 140,
        minWidth: 75,
        Cell: TableV2TdDate,
        Filter: TableEditableFilterRangeDatepicker,
        filter: reportsTableRangeDateFilter,
        disableGlobalFilter: true,
        locale
      },
      {
        label: t('commons.table.column.additionalMetrics'),
        accessor: 'additionalMetricsFilled',
        Header: TableEditableTh,
        width: 150,
        minWidth: 50,
        Cell: ({ value }) => {
          const textValue = useMemo(() => booleanMap(t)[value], [value])
          return (<TableV2TdText value={textValue} />)
        },
        disableGlobalFilter: true
      },
      {
        label: t('commons.table.column.lastUpdate.label'),
        accessor: 'updatedAt',
        Header: TableEditableTh,
        width: 125,
        minWidth: 75,
        Cell: TableV2TdDate,
        Filter: TableEditableFilterRangeDatepicker,
        filter: reportsTableRangeDateFilter,
        disableGlobalFilter: true,
        locale
      }
    ],
    [postList, symbol, locale]
  )
  return (
    <ReportsTableContainer
      tableId={tableId}
      columns={columns}
      data={postList}
      initialColumns={initialColumns}
      loading={loading}
      initialSortBy={[{ id: 'updatedAt', desc: true }]}
      searchPlaceholder={t('reports.publicationsAndData.publications.search.input.placeholder')}
    />
  )
}
