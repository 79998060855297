import { gql } from '@apollo/client'

export const deletePaymentRequests = gql`
  mutation deletePaymentRequests(
    $paymentInvoiceId: String!
    $paymentRequestIds: [String]!
  ) {
    deletePaymentRequests(
      paymentInvoiceId: $paymentInvoiceId
      paymentRequestIds: $paymentRequestIds
    ){
      message
      code
      success
    }
  }
`
