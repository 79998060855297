import { SideModal, Loader } from 'influ-dms'
import { EditInfluencerModalFooter } from './EditInfluencerModalFooter'
import { EditInfluencerModalContent } from './content/EditInfluencerModalContent'
import { useTranslate } from '~/hooks/useTranslate'
import { css } from 'styled-components'

const contentStyles = css`
  overflow: hidden; 
  padding: 0; 
`

export const EditInfluencerModal = ({ profileId, influencer, isOpen, handleCreate, handleClose, loading, editLoading }) => {
  const { t } = useTranslate()

  return (
    <SideModal
      name='editInfluencer'
      isPanel
      onClose={handleClose}
      isOpen={isOpen}
      title={t('reports.report.modal.profile.header')}
      contentStyles={contentStyles}
    >
      {loading ? <Loader fillSpace /> : <EditInfluencerModalContent profileId={profileId} influencer={influencer} isOpen={isOpen} />}
      <EditInfluencerModalFooter isOpen={isOpen} handleCreate={handleCreate} handleClose={handleClose} loading={editLoading} disabled={loading} />
    </SideModal>
  )
}
