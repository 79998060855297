import { gql } from '@apollo/client'

export const getAudienceCodesByProgramIdColumns = gql`
  query getAudienceCodesByProgramIdColumns {
    getAudienceCodesByProgramIdColumns {
      label
      fieldName
      columnType
      type
    }
  }
`
