import { gql } from '@apollo/client'

export const countAudienceCodesByProgramId = gql`
  query countAudienceCodesByProgramId(
    $programId: String!,
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    countAudienceCodesByProgramId(
      programId: $programId,
      filters: $filters,
      view: $view
    )
  }
`
