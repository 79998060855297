import { gql } from '@apollo/client'

export const getEntityFieldGroupsFormForEdition = gql`
  query getEntityFieldGroupsFormForEdition($_id: String!) {
    getEntityFieldGroupsFormForEdition(_id: $_id) {
      _id
      model
      formId
      label
      translationKey{
        onCreate
        onEdit
      }
      graphqlEndpoints{
        onCreate
        onEdit
      }
      inputGroups{
        order
        id
        translationKey
        inputs{
          order
          id
          name
          translationKey
          placeholder
          placeholderTranslationKey
          primaryOption
          type
          frontendValidations {
            name
            format
          }
          attrs
          value
          helperText
          helperTextTranslationKey
          disabled
          disabledTooltipTranslationKey
        }
      }
    }
  }
`
