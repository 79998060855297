import styled from 'styled-components'
import PropTypes from 'prop-types'

const ScrollableSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 56px 0px;
  height: ${({ height }) => `${height}px;` || '100%;'}
  ${({ styles }) => styles}
`

ScrollableSection.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number
}

export { ScrollableSection }
