import { createContext, useReducer } from 'react'

const initialValues = {
  invoice: undefined,
  isCreateInvoiceModalOpen: false,
  isEditInvoiceModalOpen: false,
  isDeleteInvoiceModalOpen: false,
  isDeleteInvoicesModalOpen: false,
  isGenerateInvoiceModalOpen: false,
  isConfirmGenerateInvoiceModalOpen: false,
  isViewInvoiceModalOpen: false,
  isConfirmPaymentModalOpen: false,
  isHowToPayModalOpen: false,
  isReviewBillingInfoModalOpen: false
}

export const InvoiceContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setInvoice':
      return { ...state, invoice: action.payload }
    case 'setCreateInvoiceModalOpen':
      return { ...state, isCreateInvoiceModalOpen: action.payload }
    case 'setEditInvoiceModalOpen':
      return { ...state, isEditInvoiceModalOpen: action.payload }
    case 'setDeleteInvoiceModalOpen':
      return { ...state, isDeleteInvoiceModalOpen: action.payload }
    case 'setDeleteInvoicesModalOpen':
      return { ...state, isDeleteInvoicesModalOpen: action.payload }
    case 'setGenerateInvoiceModalOpen':
      return { ...state, isGenerateInvoiceModalOpen: action.payload }
    case 'setConfirmGenerateInvoiceModalOpen':
      return { ...state, isConfirmGenerateInvoiceModalOpen: action.payload }
    case 'setViewInvoiceModalOpen':
      return { ...state, isViewInvoiceModalOpen: action.payload }
    case 'setConfirmPaymentModalOpen':
      return { ...state, isConfirmPaymentModalOpen: action.payload }
    case 'setHowToPayModalOpen':
      return { ...state, isHowToPayModalOpen: action.payload }
    case 'setReviewBillingInfoModalOpen':
      return { ...state, isReviewBillingInfoModalOpen: action.payload }
  }
}

export const InvoiceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setInvoice = (invoice) => {
    dispatch({ type: 'setInvoice', payload: invoice })
  }

  const setCreateInvoiceModalOpen = (isOpen) => {
    dispatch({ type: 'setCreateInvoiceModalOpen', payload: isOpen })
  }

  const setEditInvoiceModalOpen = (isOpen) => {
    dispatch({ type: 'setEditInvoiceModalOpen', payload: isOpen })
  }

  const setDeleteInvoiceModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteInvoiceModalOpen', payload: isOpen })
  }

  const setDeleteInvoicesModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteInvoicesModalOpen', payload: isOpen })
  }

  const setGenerateInvoiceModalOpen = (isOpen) => {
    dispatch({ type: 'setGenerateInvoiceModalOpen', payload: isOpen })
  }

  const setConfirmGenerateInvoiceModalOpen = (isOpen) => {
    dispatch({ type: 'setConfirmGenerateInvoiceModalOpen', payload: isOpen })
  }

  const setViewInvoiceModalOpen = (isOpen) => {
    dispatch({ type: 'setViewInvoiceModalOpen', payload: isOpen })
  }

  const setConfirmPaymentModalOpen = (isOpen) => {
    dispatch({ type: 'setConfirmPaymentModalOpen', payload: isOpen })
  }

  const setHowToPayModalOpen = (isOpen) => {
    dispatch({ type: 'setHowToPayModalOpen', payload: isOpen })
  }

  const setReviewBillingInfoModalOpen = (isOpen) => {
    dispatch({ type: 'setReviewBillingInfoModalOpen', payload: isOpen })
  }

  return (
    <InvoiceContext.Provider value={{
      ...state,
      setInvoice,
      setCreateInvoiceModalOpen,
      setEditInvoiceModalOpen,
      setDeleteInvoiceModalOpen,
      setDeleteInvoicesModalOpen,
      setGenerateInvoiceModalOpen,
      setConfirmGenerateInvoiceModalOpen,
      setViewInvoiceModalOpen,
      setConfirmPaymentModalOpen,
      setHowToPayModalOpen,
      setReviewBillingInfoModalOpen
    }}>
      {children}
    </InvoiceContext.Provider>
  )
}
