import { compactInteger, formatNumber } from 'humanize-plus'

export const humanizeFormatter = value => compactInteger(value, 0)
export const humanizeFormatterWithTwoDecimals = value => compactInteger(value, 2)
const getPercentage = value => Math.round(Number(value) * 100) / 100
export const percentageFormatter = value => `${(getPercentage(value))}%`
const getPercentageWithDecimals = (value, decimals) => `${(getPercentage(value).toFixed(decimals))}%`
export const percentageFormatterWithOneDecimal = value => getPercentageWithDecimals(value, 1)
export const percentageFormatterWithTwoDecimals = value => getPercentageWithDecimals(value, 2)
export const costFormatterWithDecimals = (value, symbol) => `${symbol}${Number.isFinite(value) ? formatNumber(value, 2) : ' -'}`
