import { gql } from '@apollo/client'

export const updateMassiveCommConfig = gql`
  mutation updateMassiveCommConfig(
    $brandId: String!
    $senderName: String!
    $senderEmail: String!
    $replyToAddress: String
    $domainType: DomainTypeEnumType!
  ){
    updateMassiveCommConfig(
      brandId: $brandId
      senderName: $senderName
      senderEmail: $senderEmail
      replyToAddress: $replyToAddress
      domainType: $domainType
    ){
      message
      code
      success
      data
    }
  }
`
