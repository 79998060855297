import { TabNav } from 'influ-dms'
import { css } from 'styled-components'

export const tabStyles = css`
  :not(:last-child) {
    margin-right: 16px;
  }
`

export const tabTextStyles = css`
  padding-left: 1px;
  padding-right: 1px;
  font-size: 14px;
`

export const SettingsTab = ({ id, title }) => (
  <TabNav id={id} title={title} styles={tabStyles} level='third' state='default' />
)
