import { useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { SnackbarContext, useCreateEditForm } from 'influ-dms'
import { useMutation, useQuery } from '@apollo/client'
import { get } from 'lodash'
import { useTranslate } from '~/hooks'
import { useFilter, useCleanCache } from '~/containers/irm/commons/hooks'
import { useFilterQuery } from '../../../irm/commons/hooks/useFilterQuery'
import { addProgramV2 as addProgramCall } from '../../apollo/addProgramV2'
import { addProgramV2InfluencerGifting as addInfluencerGiftingCall } from '../../apollo/addProgramV2InfluencerGifting'
import { editProgramV2 as editProgramCall } from '../../apollo/editProgramV2'
import { deleteProgramV2 as deleteProgramCall } from '../../apollo/deleteProgramV2'
import { getProgramsV2ByBrand } from '../../apollo/getProgramsV2ByBrand'
import { countProgramsV2ByBrand } from '../../apollo/countProgramsV2ByBrand'
import { getIrmInfluencersTableInProgramV2 } from '../../apollo/getIrmInfluencersTableInProgramV2'
import { countIrmInfluencersTableInProgramV2 } from '../../apollo/countIrmInfluencersTableInProgramV2'
import { registerRelationshipProgramV2IRMInfluencers } from '../../apollo/registerRelationshipProgramV2IRMInfluencers'
import { removeInfluencerFromProgramV2 } from '../../apollo/removeInfluencerFromProgramV2'
import { getProgramsV2ByIrmInfluencer } from '../../apollo/getProgramsV2ByIrmInfluencer'
import { countProgramsV2ByIrmInfluencer } from '../../apollo/countProgramsV2ByIrmInfluencer'
import { activateIrmInfluencersInProgram } from '../../apollo/activateIrmInfluencersInProgram'
import { deactivateIrmInfluencersInProgram } from '../../apollo/deactivateIrmInfluencersInProgram'
import { ProgramContext } from '../context'
import Link from 'next/link'
import { RouterHelper } from '../../../../routing/routerHelper'
import styled from 'styled-components'
import { ThirdPartyIntegrationError } from '../constants'
import { getProgramV2TableColumns } from '../../apollo/getProgramV2TableColumns'
import { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse } from '../../../../constants/forms/getFormResponse'
import { addProgramV2AudienceBenefit as addProgramV2AudienceBenefitCall } from '../../apollo/addProgramV2AudienceBenefit'
import { syncInfluencersInProgram } from '../../apollo/syncInfluencersInProgram'
import { getSeedingCodesColumns as getSeedingCodesColumnsCall } from '../../apollo/getSeedingCodesColumns'
import { getAudienceCodesColumns as getAudienceCodesColumnsCall } from '../../apollo/getAudienceCodesColumns'
import { getGiftingCodesByInfluencerTable } from '../../apollo/getGiftingCodesByInfluencerTable'
import { getAudienceCodeByInfluencerTable } from '../../apollo/getAudienceCodeByInfluencerTable'
import { countAudienceCodeByInfluencerTable } from '../../apollo/countAudienceCodeByInfluencerTable'
import { getAudienceCodesByProgramId } from '../../apollo/getAudienceCodesByProgramId'
import { countAudienceCodesByProgramId } from '../../apollo/countAudienceCodesByProgramId'
import { getAudienceCodesByProgramIdColumns } from '../../apollo/getAudienceCodesByProgramIdColumns'
import { getGiftingCodesByProgramId } from '../../apollo/getGiftingCodesByProgramId'
import { countGiftingCodesByProgramId } from '../../apollo/countGiftingCodesByProgramId'
import { getGiftingCodesByProgramIdColumns } from '../../apollo/getGiftingCodesByProgramIdColumns'
import { createAudienceBenefitCode as createAudienceBenefitCodeCall } from '../../apollo/createAudienceBenefitCode'
import { editAudienceBenefitCode as editAudienceBenefitCodeCall } from '../../apollo/editAudienceBenefitCode'
import { deleteAudienceBenefitCode as deleteAudienceBenefitCodeCall } from '../../apollo/deleteAudienceBenefitCode'
import { activateCoupon as activateCouponCall } from '../../apollo/activateCoupon'
import { deactivateAudienceCoupon as deactivateAudienceCouponCall } from '../../apollo/deactivateAudienceCoupon'
import { deactivateInfluencerGiftingCoupon as deactivateInfluencerGiftingCouponCall } from '../../apollo/deactivateInfluencerGiftingCoupon'
import { captureException } from '@sentry/nextjs'

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.state.dangerDefault};  
  font-weight: 500;  
  text-decoration: underline;
`

export const usePrograms = () => {
  const { sendForm } = useCreateEditForm()
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)
  const { setInfluencersLoading, openProgramDataNotSyncModal } = useContext(ProgramContext)
  const router = useRouter()
  const { getFilteredDataOptions } = useFilter()
  const brandId = get(router, 'query.brandId')
  const influencerId = get(router, 'query.influencerId')
  const programId = get(router, 'query.programId')
  const {
    clearProgramTableCache,
    clearProgramDetailCache,
    clearInfluencerDetailCache,
    clearProgramsInInfluencer,
    clearAudienceCodeTableCache,
    clearGiftingCodeTableCache,
    clearInfluencerTableCache,
    clearOutreachDetailCache
  } = useCleanCache()

  const [programsColumnsOptions, setProgramsColumnsOptions] = useState({ skip: true })
  const { data: programsColumns, loading: loadingProgramsColumns } = useQuery(getProgramV2TableColumns, programsColumnsOptions)

  const [seedingCodesColumnsOptions, setSeedingCodesColumnsOptions] = useState({ skip: true })
  const { data: seedingCodesColumns, loading: loadingSeedingCodesColumns } = useQuery(getSeedingCodesColumnsCall, seedingCodesColumnsOptions)

  const [audienceCodesColumnsOptions, setAudienceCodesColumnsOptions] = useState({ skip: true })
  const { data: audienceCodesColumns, loading: loadingAudienceCodesColumns } = useQuery(getAudienceCodesColumnsCall, audienceCodesColumnsOptions)

  const {
    loadingData: loadingInfluencersInProgram,
    loadingCount: loadingCountInfluencersInProgram,
    setQueryOptions: setTableInfluencerOptions,
    setCountQueryOptions: setCountTableInfluencerOptions
  } = useFilterQuery({
    query: getIrmInfluencersTableInProgramV2,
    queryAccessor: 'getIrmInfluencersTableInProgramV2',
    countQuery: countIrmInfluencersTableInProgramV2,
    countQueryAccessor: 'countIrmInfluencersTableInProgramV2'
  })

  const {
    loadingData: loadingSeedingCodes,
    setQueryOptions: setTableSeedingCodesOptions,
  } = useFilterQuery({
    query: getGiftingCodesByInfluencerTable,
    queryAccessor: 'getGiftingCodesByInfluencerTable',
  })

  const {
    loadingData: loadingAudienceCodes,
    loadingCount: loadingCountAudienceCodes,
    setQueryOptions: setTableAudienceCodesOptions,
    setCountQueryOptions: setCountTableAudienceCodesOptions,
  } = useFilterQuery({
    query: getAudienceCodeByInfluencerTable,
    queryAccessor: 'getAudienceCodeByInfluencerTable',
    countQuery: countAudienceCodeByInfluencerTable,
    countQueryAccessor: 'countAudienceCodeByInfluencerTable'
  })

  const {
    loadingData: loadingPrograms,
    loadingCount: loadingCountPrograms,
    setQueryOptions: setTableProgramsOptions,
    setCountQueryOptions: setCountTableProgramsOptions
  } = useFilterQuery({
    query: getProgramsV2ByBrand,
    queryAccessor: 'getProgramsV2ByBrand',
    countQuery: countProgramsV2ByBrand,
    countQueryAccessor: 'countProgramsV2ByBrand'
  })

  const {
    loadingData: loadingProgramsV2InInfluencer,
    loadingCount: loadingCountProgramsV2InInfluencer,
    setQueryOptions: setTableProgramsV2InInfluencerOptions,
    setCountQueryOptions: setCountTableProgramsV2InInfluencerOptions
  } = useFilterQuery({
    query: getProgramsV2ByIrmInfluencer,
    queryAccessor: 'getProgramsV2ByIrmInfluencer',
    countQuery: countProgramsV2ByIrmInfluencer,
    countQueryAccessor: 'countProgramsV2ByIrmInfluencer'
  })

  const [syncInfluencersMutation, { loading: loadingSyncInfluencers }] = useMutation(syncInfluencersInProgram, {
    update (cache, _, { variables }) {
      variables?.programId && clearProgramDetailCache(variables.programId)
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramTableCache(cache, { evictCount: false })
      cache.gc()
    }
  })

  const [deactivateInfluencersMutation, { loading: loadingDeactivateInfluencers }] = useMutation(deactivateIrmInfluencersInProgram, {
    update (cache, _, { variables }) {
      variables?.programIds?.forEach((programId) => clearProgramDetailCache(programId))
      cache.gc()
    }
  })

  const [activateInfluencersMutation, { loading: loadingActivateInfluencers }] = useMutation(activateIrmInfluencersInProgram, {
    update (cache, _, { variables }) {
      variables?.programIds?.forEach((programId) => clearProgramDetailCache(programId))
      cache.gc()
    }
  })

  const [addProgramV2Mutation, { loading: loadingAddProgram }] = useMutation(addProgramCall, {
    update (cache, _, { variables }) {
      clearProgramTableCache(cache)
      variables?.influencerIds?.forEach((influencerId) => {
        clearInfluencerDetailCache(influencerId)
        clearProgramsInInfluencer(influencerId)
      })
      cache.gc()
    }
  })

  const [editProgramV2Mutation, { loading: loadingEditProgram }] = useMutation(editProgramCall, {
    update (cache, _, { variables }) {
      variables?._id && clearProgramDetailCache(variables._id)
      clearProgramTableCache(cache, { evictCount: false })
      cache.gc()
      clearOutreachDetailCache()
    }
  })

  const [addInfluencerGiftingMutation, { loading: loadingAddInfluencerGifting }] = useMutation(addInfluencerGiftingCall, {
    update (cache, _, { variables }) {
      variables?.programId && clearProgramDetailCache(variables.programId)
      clearProgramTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      cache.gc()
    }
  })

  const [addAudienceBenefitMutation, { loading: loadingAddAudienceBenefit }] = useMutation(addProgramV2AudienceBenefitCall, {
    update (cache, _, { variables }) {
      variables?.programId && clearProgramDetailCache(variables.programId)
      clearProgramTableCache(cache, { evictCount: false })
      clearAudienceCodeTableCache(cache, { evictCount: false })
      cache.gc()
    }
  })

  const [deleteProgramsV2Mutation, { loading: loadingDeletePrograms }] = useMutation(deleteProgramCall, {
    update (cache, _, { variables }) {
      variables?.programIds?.forEach((programId) => clearProgramDetailCache(programId))
      clearProgramTableCache(cache)
      cache.gc()
      clearOutreachDetailCache()
    },
  })

  const [addInfluencersMutation, { loading: loadingAddInfluencers }] = useMutation(registerRelationshipProgramV2IRMInfluencers, {
    update (cache, _, { variables }) {
      variables?.programIds?.forEach((programId) => clearProgramDetailCache(programId))
      clearProgramTableCache(cache)
      variables?.influencerIds?.forEach((influencerId) => {
        clearInfluencerDetailCache(influencerId)
        clearProgramsInInfluencer(influencerId)
      })
      clearInfluencerTableCache()
      cache.gc()
    }
  })

  const [removeInfluencersMutation, { loading: loadingRemoveInfluencers }] = useMutation(removeInfluencerFromProgramV2, {
    update (cache, _, { variables }) {
      variables?.programIds?.forEach((programId) => clearProgramDetailCache(programId))
      clearProgramTableCache(cache)
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearAudienceCodeTableCache(cache, { evictCount: false })
      variables?.influencerIds?.forEach((influencerId) => {
        clearInfluencerDetailCache(influencerId)
        clearProgramsInInfluencer(influencerId)
      })
      clearInfluencerTableCache()
      cache.gc()
    }
  })

  const [audienceCodesByProgramColumnsOptions, setAudienceCodesByProgramColumnsOptions] = useState({ skip: true })
  const { data: audienceCodesByProgramColumns, loading: loadingAudienceCodesByProgramColumns } = useQuery(getAudienceCodesByProgramIdColumns, audienceCodesByProgramColumnsOptions)

  const {
    loadingData: loadingAudienceCodesByProgram,
    loadingCount: loadingCountAudienceCodesByProgram,
    setQueryOptions: setTableAudienceCodesByProgramOptions,
    setCountQueryOptions: setCountTableAudienceCodesByProgramOptions
  } = useFilterQuery({
    query: getAudienceCodesByProgramId,
    queryAccessor: 'getAudienceCodesByProgramId',
    countQuery: countAudienceCodesByProgramId,
    countQueryAccessor: 'countAudienceCodesByProgramId'
  })

  const [giftingCodesByProgramColumnsOptions, setGiftingCodesByProgramColumnsOptions] = useState({ skip: true })
  const { data: giftingCodesByProgramColumns, loading: loadingGiftingCodesByProgramColumns } = useQuery(getGiftingCodesByProgramIdColumns, giftingCodesByProgramColumnsOptions)

  const {
    loadingData: loadingGiftingCodesByProgram,
    loadingCount: loadingCountGiftingCodesByProgram,
    setQueryOptions: setTableGiftingCodesByProgramOptions,
    setCountQueryOptions: setCountTableGiftingCodesByProgramOptions
  } = useFilterQuery({
    query: getGiftingCodesByProgramId,
    queryAccessor: 'getGiftingCodesByProgramId',
    countQuery: countGiftingCodesByProgramId,
    countQueryAccessor: 'countGiftingCodesByProgramId'
  })

  const getProgramColumns = () => {
    try {
      setProgramsColumnsOptions({ variables: { brandId }, skip: false })
    } catch (e) {
      captureException('Error obtaining Programs V2 Columns', e)
    }
  }

  const getSeedingCodesColumns = () => {
    try {
      setSeedingCodesColumnsOptions({ skip: false })
    } catch (e) {
      captureException('Error obtaining Seeding Codes Columns', e)
    }
  }

  const getAudienceCodesColumns = () => {
    try {
      setAudienceCodesColumnsOptions({ skip: false })
    } catch (e) {
      captureException('Error obtaining Audience Codes Columns', e)
    }
  }

  const getProgramsInInfluencer = async (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { influencerId, view },
        { influencerId, view }
      )
      setTableProgramsV2InInfluencerOptions(options)
      page === 0 && setCountTableProgramsV2InInfluencerOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining programs', e)
    }
  }

  const getInfluencersInProgram = (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { programId, view },
        { programId, view }
      )

      setTableInfluencerOptions(options)
      if (page === 0) setCountTableInfluencerOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining influencers', e)
    }
  }

  const getSeedingCodes = async (influencerInProgramId, filterParams, view = 'table') => {
    try {
      const { options } = getFilteredDataOptions(
        filterParams,
        { influencerInProgramId, programId, view },
        { influencerInProgramId, programId, view }
      )
      setTableSeedingCodesOptions(options)
    } catch (e) {
      captureException('Error obtaining seeding codes', e)
    }
  }

  const getAudienceCodes = async (influencerInProgramId, filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { influencerInProgramId, brandId, view },
        { influencerInProgramId, brandId, view }
      )
      setTableAudienceCodesOptions(options)
      page === 0 && setCountTableAudienceCodesOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining audience codes', e)
    }
  }

  const getPrograms = async (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view },
        { brandId, view }
      )
      if (view === 'linkToProgramV2') {
        options.fetchPolicy = 'network-only'
        countOptions.fetchPolicy = 'network-only'
      }
      setTableProgramsOptions(options)
      page === 0 && setCountTableProgramsOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining programs', e)
    }
  }

  const addProgram = async (originIdName, originId) => {
    try {
      const response = await sendForm(addProgramV2Mutation, false, {
        [originIdName]: originId
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error adding program', e)
      return false
    }
  }

  const addInfluencerGifting = async (programId) => {
    try {
      const response = await sendForm(addInfluencerGiftingMutation, false, {
        programId
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error adding program', e)
      return false
    }
  }

  const addAudienceBenefit = async (programId, formValues) => {
    try {
      let extraArgs = { programId }
      if (formValues) {
        extraArgs = { programId, ...formValues }
      }
      const response = await sendForm(addAudienceBenefitMutation, false,
        extraArgs, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error adding program', e)
      return false
    }
  }

  const handleProgramError = (error) => {
    const code = get(error, 'graphQLErrors[0].extensions.code', null)
    let message = 'commons.snackbar.error.somethingWentWrong'
    if (Object.values(ThirdPartyIntegrationError).includes(code)) {
      message = 'commons.snackbar.error.errorShopIntegration'

      showSnackbar('error', t(message), true, <StyledLink
        href={ RouterHelper.getUrl('integrations', { brandId: router.query.brandId })}
      >
        {t('commons.button.goToSettings')}
      </StyledLink>)
    } else {
      if (code !== 12622) {
        showSnackbar('error', t(message))
      }
    }
  }

  const enablePrograms = async (influencerIds) => {
    try {
      setInfluencersLoading(influencerIds)
      const variables = { influencerIds, programIds: [programId] }
      const { data } = await activateInfluencersMutation({ variables })
      const res = get(data, 'activateIrmInfluencersInProgram')
      setInfluencersLoading(null, influencerIds)
      if (res.success) {
        showSnackbar('success', t('commons.snackbar.success.influencersEnabled'))
        return true
      } else if (!res.success && res?.code === '12808') {
        openProgramDataNotSyncModal()
        return false
      } else {
        throw Error(res.message)
      }
    } catch (err) {
      handleProgramError(err)

      setInfluencersLoading(null, influencerIds)
      captureException('Error enabling program', err)
      return false
    }
  }

  const disablePrograms = async (influencerIds) => {
    try {
      setInfluencersLoading(influencerIds)
      const variables = { influencerIds, programIds: [programId] }
      const { data } = await deactivateInfluencersMutation({ variables })
      const res = get(data, 'deactivateIrmInfluencersInProgram')
      setInfluencersLoading(null, influencerIds)
      if (res.success) {
        showSnackbar('success', t('commons.snackbar.success.influencersDisabled'))
        return true
      } else {
        throw Error(res.message)
      }
    } catch (err) {
      handleProgramError(err)

      setInfluencersLoading(null, influencerIds)
      captureException('Error disabling program', err)
      return false
    }
  }

  const editProgram = async (programId) => {
    try {
      const response = await sendForm(editProgramV2Mutation, true, {
        brandId,
        _id: programId,
        from: 'irm'
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error editing program', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const deletePrograms = async (programIds) => {
    const variables = { programIds }
    try {
      const response = await deleteProgramsV2Mutation({ variables })
      const count = programIds?.length
      showSnackbar('success', t(count > 1 ? 'commons.snackbar.success.deletePrograms' : 'commons.snackbar.success.deleteProgram', { count }))
      return response
    } catch (err) {
      handleProgramError(err)

      captureException('Error deleting programs', err)

      return false
    }
  }

  const addInfluencers = async ({ programIds, influencerIds }) => {
    const variables = { programIds, influencerIds }
    try {
      const response = await addInfluencersMutation({ variables })
      if (response?.data?.registerRelationshipProgramV2IRMInfluencers?.success) {
        return true
      }
      throw Error
    } catch (err) {
      handleProgramError(err)

      setInfluencersLoading(null, influencerIds)
      captureException('Error adding influencers to program', err)
      return false
    }
  }

  const removeInfluencers = async (programIds, influencerIds) => {
    const variables = { programIds, influencerIds }
    try {
      const response = await removeInfluencersMutation({ variables })
      if (response?.data?.removeInfluencerFromProgramV2?.success) {
        showSnackbar('success', influencerIds?.length > 1 ? t('commons.snackbar.success.removeInfluencersFromProgram') : t('commons.snackbar.success.removeInfluencerFromProgram'))
        return true
      } else {
        throw Error(response.message)
      }
    } catch (err) {
      handleProgramError(err)
      captureException('Error removing influencers from program', err)
      return false
    }
  }

  const syncInfluencers = async (programId, influencersInProgramIds) => {
    const variables = { programId, influencerIds: influencersInProgramIds }
    try {
      const { data } = await syncInfluencersMutation({ variables })
      const response = get(data, 'syncInfluencers')
      if (response.success) {
        showSnackbar('success', t('commons.snackbar.success.syncInfluencers'))
        return true
      } else {
        throw Error(response.message)
      }
    } catch (err) {
      handleProgramError(err)
      captureException('There was an error trying to sync influencers in program data', err)
      return false
    }
  }

  const getAudienceCodesByProgram = async (filterParams, programId) => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { programId, brandId, view: 'table' },
        { programId, brandId, view: 'table' }
      )
      setTableAudienceCodesByProgramOptions(options)
      page === 0 && setCountTableAudienceCodesByProgramOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining audience codes by program', e)
    }
  }

  const getAudienceCodesByProgramColumns = () => {
    try {
      setAudienceCodesByProgramColumnsOptions({ variables: { brandId }, skip: false })
    } catch (e) {
      captureException('Error obtaining Audience Codes By Program Columns', e)
    }
  }

  const getGiftingCodesByProgram = async (filterParams, programId) => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { programId, brandId, view: 'tableGifting' },
        { programId, brandId, view: 'tableGifting' }
      )
      setTableGiftingCodesByProgramOptions(options)
      page === 0 && setCountTableGiftingCodesByProgramOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining gifting codes by program', e)
    }
  }

  const getGiftingCodesByProgramColumns = () => {
    try {
      setGiftingCodesByProgramColumnsOptions({ variables: { brandId }, skip: false })
    } catch (e) {
      captureException('Error obtaining Gifting Codes By Program Columns', e)
    }
  }

  const [createAudienceBenefitCodeMutation, { loading: loadingCreateAudienceBenefitCode }] = useMutation(createAudienceBenefitCodeCall, {
    update (cache, _, { variables }) {
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramDetailCache(programId)
      cache.gc()
    }
  })

  const [editAudienceBenefitCodeMutation, { loading: loadingEditAudienceBenefitCode }] = useMutation(editAudienceBenefitCodeCall, {
    update (cache, _, { variables }) {
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramDetailCache(programId)
      cache.gc()
    }
  })

  const [deleteAudienceBenefitCodeMutation, { loading: loadingDeleteAudienceBenefitCode }] = useMutation(deleteAudienceBenefitCodeCall, {
    update (cache, _, { variables }) {
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramDetailCache(programId)
      cache.gc()
    }
  })

  const [activateCouponMutation, { loading: loadingActivateCoupon }] = useMutation(activateCouponCall, {
    update (cache, _, { variables }) {
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramDetailCache(programId)
      cache.gc()
    }
  })

  const [deactivateAudienceCouponMutation, { loading: loadingDeactivateAudienceCoupon }] = useMutation(deactivateAudienceCouponCall, {
    update (cache, _, { variables }) {
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramDetailCache(programId)
      cache.gc()
    }
  })

  const [deactivateGiftingCouponMutation, { loading: loadingDeactivateGiftingCoupon }] = useMutation(deactivateInfluencerGiftingCouponCall, {
    update (cache, _, { variables }) {
      clearAudienceCodeTableCache(cache, { evictCount: false })
      clearGiftingCodeTableCache(cache, { evictCount: false })
      clearProgramDetailCache(programId)
      cache.gc()
    }
  })

  const createAudienceBenefitCode = async (programId, influencerInProgramIds, seedCode) => {
    const variables = { programId, influencerInProgramIds, seedCode }
    try {
      const response = await createAudienceBenefitCodeMutation({ variables })
      return response
    } catch (e) {
      captureException('Error creating audience benefit code', e)
      return e
    }
  }

  const editAudienceBenefitCode = async (programId, couponId, influencerInProgramId, seedCode) => {
    const variables = { programId, couponId, influencerInProgramId, seedCode }
    try {
      const response = await editAudienceBenefitCodeMutation({ variables })
      return response
    } catch (e) {
      captureException('Error editing audience benefit code', e)
      return e
    }
  }

  const deleteAudienceBenefitCode = async (couponIds, programId) => {
    const variables = { couponIds, programId }
    try {
      const response = await deleteAudienceBenefitCodeMutation({ variables })
      const count = couponIds?.length
      showSnackbar(
        'success',
        t(
          count > 1
            ? 'commons.snackbar.success.deleteCodes'
            : 'commons.snackbar.success.deleteCode',
          { count }
        )
      )
      return response
    } catch (e) {
      captureException('Error deleting audience benefit code', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const activateCoupon = async (couponIds, programId) => {
    const variables = { couponIds, programId }
    try {
      const response = await activateCouponMutation({ variables })
      return response
    } catch (e) {
      captureException('Error activating audience benefit code', e)
      return false
    }
  }

  const deactivateAudienceCoupon = async (couponIds, programId) => {
    const variables = { couponIds, programId }
    try {
      const response = await deactivateAudienceCouponMutation({ variables })
      return response
    } catch (e) {
      captureException('Error deactivating audience benefit code', e)
      return false
    }
  }

  const deactivateGiftingCoupon = async (couponIds) => {
    const variables = { couponIds }
    try {
      const response = await deactivateGiftingCouponMutation({ variables })
      return response
    } catch (e) {
      captureException('Error deactivating gifting benefit code', e)
      return false
    }
  }

  return {
    loadingCountPrograms,
    loadingCountInfluencersInProgram,
    loadingCountProgramsV2InInfluencer,
    loadingPrograms,
    loadingInfluencersInProgram,
    loadingProgramsV2InInfluencer,
    loadingActivateInfluencers,
    loadingDeactivateInfluencers,
    loadingAddProgram,
    loadingAddInfluencerGifting,
    loadingAddAudienceBenefit,
    loadingEditProgram,
    loadingDeletePrograms,
    loadingAddInfluencers,
    loadingRemoveInfluencers,
    programsColumns,
    loadingProgramsColumns,
    loadingSyncInfluencers,
    seedingCodesColumns,
    loadingSeedingCodesColumns,
    audienceCodesColumns,
    loadingAudienceCodesColumns,
    loadingSeedingCodes,
    loadingAudienceCodes,
    loadingCountAudienceCodes,
    loadingAudienceCodesByProgram,
    loadingCountAudienceCodesByProgram,
    audienceCodesByProgramColumns,
    loadingAudienceCodesByProgramColumns,
    loadingGiftingCodesByProgram,
    loadingCountGiftingCodesByProgram,
    giftingCodesByProgramColumns,
    loadingGiftingCodesByProgramColumns,
    loadingCreateAudienceBenefitCode,
    loadingEditAudienceBenefitCode,
    loadingDeleteAudienceBenefitCode,
    loadingActivateCoupon,
    loadingDeactivateAudienceCoupon,
    loadingDeactivateGiftingCoupon,
    getProgramColumns,
    getPrograms,
    getInfluencersInProgram,
    getProgramsInInfluencer,
    enablePrograms,
    disablePrograms,
    addProgram,
    addInfluencerGifting,
    addAudienceBenefit,
    editProgram,
    deletePrograms,
    addInfluencers,
    removeInfluencers,
    handleProgramError,
    syncInfluencers,
    getSeedingCodesColumns,
    getAudienceCodesColumns,
    getSeedingCodes,
    getAudienceCodes,
    getAudienceCodesByProgram,
    countAudienceCodesByProgramId,
    getAudienceCodesByProgramColumns,
    getGiftingCodesByProgram,
    countGiftingCodesByProgramId,
    getGiftingCodesByProgramColumns,
    createAudienceBenefitCode,
    editAudienceBenefitCode,
    deleteAudienceBenefitCode,
    activateCoupon,
    deactivateAudienceCoupon,
    deactivateGiftingCoupon
  }
}
