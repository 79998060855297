import { gql } from '@apollo/client'

export const addToPay = gql`
  mutation addToPay(
    $paymentRequestIds: [String]!
  ) {
    addToPay(
      paymentRequestIds: $paymentRequestIds
    ){
      message
      code
      success
    }
  }
`
