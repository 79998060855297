import { EmptyState, Flex } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const SelectTemplateEmptyState = () => {
  const { t } = useTranslate()

  return (
    <>
      <Flex column center grow='1' styles={{ height: '100%' }}>
        <EmptyState
          imageUrl='/static/img/irm/profiles/empty_profiles.svg'
          titleText={t('templates.allTemplates.empty.titleHeader1')}
          subtitleText={t('templates.allTemplates.empty.titleHeader2')}
        />
      </Flex>
    </>
  )
}
