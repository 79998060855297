import { useContext, useState } from 'react'
import { SnackbarContext, useCreateEditForm } from 'influ-dms'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useTranslate } from '~/hooks'
import { useFilter, useCleanCache } from '../../../irm/commons/hooks'
import { useFilterQuery } from '../../../irm/commons/hooks/useFilterQuery'
import { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse } from '../../../../constants/forms/getFormResponse'
import {
  addPaymentInvoice as addPaymentInvoiceCall,
  deletePaymentInvoices as deletePaymentInvoicesCall,
  editPaymentInvoice as editPaymentInvoiceCall,
  getPaymentInvoicesByBrand,
  countPaymentInvoicesByBrand,
  canGenerateInvoice as canGenerateInvoiceCall,
  generateInvoice as generateInvoiceCall,
  editInvoiceOrderNumber as editInvoiceOrderNumberCall,
} from '../apollo'
import { captureException } from '@sentry/nextjs'

export const useInvoices = () => {
  const {
    clearInvoicesTableCache,
    clearInvoiceDetailCache,
    clearPaymentsTableCache
  } = useCleanCache()
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const {
    loadingData: loadingInvoices,
    loadingCount: loadingCountInvoices,
    setQueryOptions: setTableInvoicesOptions,
    setCountQueryOptions: setCountTableInvoicesOptions
  } = useFilterQuery({
    query: getPaymentInvoicesByBrand,
    queryAccessor: 'getPaymentInvoicesByBrand',
    countQuery: countPaymentInvoicesByBrand,
    countQueryAccessor: 'countPaymentInvoicesByBrand'
  })

  const [createInvoiceMutation, { loading: loadingCreateInvoice }] = useMutation(addPaymentInvoiceCall, {
    update (cache, _) {
      clearInvoicesTableCache()
      cache.gc()
    }
  })
  const [deleteInvoicesMutation, { loading: loadingDeleteInvoices }] = useMutation(deletePaymentInvoicesCall, {
    update (cache, _) {
      clearInvoicesTableCache()
      cache.gc()
    }
  })

  const [editInvoiceMutation, { loading: loadingEditInvoice }] = useMutation(editPaymentInvoiceCall, {
    update (cache, _, { variables }) {
      variables?._id && clearInvoiceDetailCache(variables._id)
      clearInvoicesTableCache({ evictCount: false })
      clearPaymentsTableCache({ evictCount: false })
      cache.gc()
    }
  })

  const [canGenerateInvoiceOptions, setCanGenerateInvoiceOptions] = useState({ skip: true })
  const { data: canGenerate, loading: loadingCanGenerateInvoice } = useQuery(canGenerateInvoiceCall, canGenerateInvoiceOptions)

  const [generateInvoiceMutation, { loading: loadingGenerateInvoice }] = useMutation(generateInvoiceCall, {
    update (cache, _, { variables }) {
      variables?._id && clearInvoiceDetailCache(variables._id)
      clearInvoicesTableCache({ evictCount: false })
      cache.gc()
    }
  })

  const [editInvoiceOrderNumberMutation, { loading: loadingEditInvoiceOrderNumber }] = useMutation(editInvoiceOrderNumberCall, {
    update (cache, _, { variables }) {
      variables?._id && clearInvoiceDetailCache(variables._id)
      cache.gc()
    }
  })

  const { sendForm } = useCreateEditForm()
  const { getFilteredDataOptions } = useFilter()

  const getInvoices = async (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view },
        { brandId, view }
      )
      setTableInvoicesOptions(options)
      page === 0 && setCountTableInvoicesOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining Invoices', e)
    }
  }

  const createInvoice = async () => {
    try {
      const response = await sendForm(createInvoiceMutation, false, { brandId }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error creating Invoice', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const deleteInvoices = async (paymentInvoiceIds) => {
    const variables = { brandId, paymentInvoiceIds }
    try {
      const response = await deleteInvoicesMutation({ variables })
      const count = paymentInvoiceIds?.length
      showSnackbar(
        'success',
        t(
          count > 1
            ? 'commons.snackbar.success.deleteInvoices'
            : 'commons.snackbar.success.deleteInvoices',
          { count }
        )
      )
      return response
    } catch (e) {
      captureException('Error deleting Invoices', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const editInvoice = async (invoiceId) => {
    try {
      const response = await sendForm(editInvoiceMutation, true, {
        brandId,
        _id: invoiceId
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error editing Invoice', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const generateInvoice = async (_id) => {
    const variables = { _id }
    try {
      const response = await generateInvoiceMutation({ variables })
      return response
    } catch (e) {
      captureException('Error generating Invoice', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const editInvoiceOrderNumber = async (_id, orderNumber) => {
    const variables = { _id, orderNumber }
    try {
      const response = await editInvoiceOrderNumberMutation({ variables })
      return response
    } catch (e) {
      captureException('Error editing Invoice order number', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const canGenerateInvoice = (_id) => {
    try {
      const options = {
        variables: {
          _id
        },
        skip: !_id
      }
      setCanGenerateInvoiceOptions(options)
    } catch (e) {
      captureException('Error obtaining Irm Influencer Columns', e)
    }
  }

  return {
    getInvoices,
    loadingInvoices,
    createInvoice,
    loadingCreateInvoice,
    deleteInvoices,
    loadingDeleteInvoices,
    editInvoice,
    loadingEditInvoice,
    loadingCountInvoices,
    generateInvoice,
    loadingGenerateInvoice,
    editInvoiceOrderNumber,
    loadingEditInvoiceOrderNumber,
    canGenerateInvoice,
    canGenerate,
    loadingCanGenerateInvoice
  }
}
