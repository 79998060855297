import { AddPostsManualPostCost } from './subForms/AddPostsManualPostCost'
import { AddPostsManualInstagramReelInfo } from './subForms/AddPostsManualInstagramReelInfo'
import { AddPostsManualInstagramReelMetrics } from './subForms/AddPostsManualInstagramReelMetrics'
import { AddPostsManualInstagramReelAdditionalMetrics } from './subForms/AddPostsManualInstagramReelAdditionalMetrics'

export const AddPostsManualInstagramReel = () => (
  <>
    <AddPostsManualInstagramReelInfo />
    <AddPostsManualInstagramReelMetrics />
    <AddPostsManualInstagramReelAdditionalMetrics />
    <AddPostsManualPostCost />
  </>
)
