import { gql } from "@apollo/client"

export const updateAggregatedDataFromList = gql`
mutation updateAggregatedDataFromList(
      $listId: String!,
      $network: String,
) {
      updateAggregatedDataFromList(listId: $listId, network: $network){
            success
            message
  }
}
`
