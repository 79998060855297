import { createContext, useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { refreshReportV2TrackingSubscription } from '../apollo/refreshReportV2TrackingSubscription'
import { useRouter } from 'next/router'
import { get } from 'lodash'

export const ReportsContext = createContext({ value: {} })

export const ReportsContextProvider = (props) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const [reportsAction, setReportsAction] = useState()
  const [reportName, setReportName] = useState()
  const value = { reportsAction, setReportsAction, reportName, setReportName }
  const { data: dataTrackingSubscription } = useSubscription(refreshReportV2TrackingSubscription, { variables: { brandId }, skip: !brandId })

  useEffect(() => {
    if (dataTrackingSubscription) {
      const refreshReportV2Tracking = get(dataTrackingSubscription, 'refreshReportV2Tracking')
      if (refreshReportV2Tracking && refreshReportV2Tracking.brandId === brandId) {
        setReportsAction({ action: 'setStatusTracking' })
      }
    }
  }, [dataTrackingSubscription])

  return (
    <ReportsContext.Provider value={value}>
      {props.children}
    </ReportsContext.Provider>
  )
}

export const ReportsContextConsumer = ReportsContext.consumer
