import { gql } from '@apollo/client'

export const getTemplate = gql`
  query getTemplate ( 
    $_id: String!
  ) {
    getTemplate(_id: $_id) {
      _id
      name
      subject
      html
      attachments {
        attachmentId
        success
        filename
        mimetype
        inline
        content
        contentId
      }
      type
      createdAt
      updatedAt
    }
  }
`
