import styled, { css } from 'styled-components'

export const AccountProfile = styled.div`
  display: flex;
  flex-direction: ${({ isExpanded }) => isExpanded ? 'row-reverse' : 'column'};
  cursor: pointer;
  align-items: center;
  position: relative;
  padding: 8px 8px 0px 8px;
  min-height: 50px;
  max-height: 50px;
`

export const dropdownStyles = css`
  -webkit-font-smoothing: antialiased;
  width: 230px;
  left: ${({ isExpanded }) => isExpanded ? '192px' : '48px'};
  bottom: 5px;
  z-index: 104;
`
