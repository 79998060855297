import { gql } from "@apollo/client";

export const deleteIrmLists = gql`
  mutation deleteIrmLists($listIds: [String]!) {
    deleteIrmLists(listIds: $listIds) {
      message
      code
      success
    }
  }
`