import { useContext, useEffect } from 'react'
import { get } from 'lodash'
import { Button, FormContext, SideModalFooter, StateButton, Text } from 'influ-dms'
import { isFormEmpty } from '../addPosts/addPostsHelper'
import { useTranslate } from '~/hooks/useTranslate'

export const EditPostModalFooter = ({ isOpen, handleCreate, handleClose, loading, disabled, error, setError }) => {
  const { t } = useTranslate()
  const { values, cleanForm } = useContext(FormContext)
  const onCreate = () => handleCreate(values)
  const isEmpty = isFormEmpty(values) || loading
  const isProcessing = get(values, 'status') === 'processing'
  const hasError = !!get(values, 'error')
  const isAutomatic = get(values, 'dataSource') === 'automatic'
  const isDisabled = isEmpty || disabled || (isProcessing && isAutomatic) || hasError

  useEffect(() => {
    if (error) {
      setError('')
    }
  }, [values])

  useEffect(() => {
    if (!isOpen) {
      cleanForm()
    }
  }, [isOpen])

  return (
    <SideModalFooter>
      <StateButton loading={loading} showLoader name='editPost' disabled={isDisabled} onClick={onCreate}>{t('commons.button.save')}</StateButton>
      <Button name='cancelAddPost' disabled={loading} variant='flat' color='secondary' onClick={handleClose}>{t('commons.button.cancel')}</Button>
      {error && <Text color='app.default'>{error}</Text>}
    </SideModalFooter>
  )
}
