import { Children, Component, cloneElement } from 'react'
import PropTypes from 'prop-types'
import {
  ScrollableSection,
  Container,
  Content,
  DotSection,
  IconContainer
} from './'
import { Icon } from 'influ-dms'

class Scrollable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSection: 0
    }
  }

  sectionChange (index) {
    const domNode = this[`domRef${index}`]
    domNode.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  handleScroll (params) {
    const scroll = params.target.scrollTop
    const { height } = this.props
    const { activeSection } = this.state

    const actualSection = Math.round(scroll / height)
    if (actualSection !== activeSection) this.setState({ activeSection: actualSection })
  }

  render () {
    const { children, height, activeColor } = this.props
    // intercept sections to add refs and height
    const CompoundChildren = Children.map(children, (child, index) => {
      if (child.type === ScrollableSection) {
        return cloneElement(child, {
          ...child.props,
          height,
          ref: (ref) => { this[`domRef${index}`] = ref }
        })
      }
      return child
    })

    // add a dot for each ection to link them on scroll
    const ScrollDots = Children.map(children, (child, index) => {
      if (child.type === ScrollableSection) {
        const color = this.state.activeSection === index ? activeColor : '#EDEDF4'
        return (
          <IconContainer
            onClick={() => this.sectionChange(index)}
            data-cy='Scrollable-IconContainer-active'
          >
            <Icon
              size={24}
              name='dot'
              color={color}
              key={index}
            />
          </IconContainer>
        )
      }
    })

    return (
      <Container
        height={height}
        // onScroll={params => this.handleScroll(params)}
      >
        <Content
          onScroll={params => this.handleScroll(params)}
        >
          {CompoundChildren}
        </Content>
        <DotSection height={height}>
          {ScrollDots}
        </DotSection>
      </Container>
    )
  }
}

Scrollable.defaultProps = {
  color: 'ee0d61'
}

Scrollable.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
  activeColor: PropTypes.string
}

export { Scrollable }
