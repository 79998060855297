import { gql } from "@apollo/client";

export const deleteIrmInfluencers = gql`
  mutation deleteIrmInfluencers($influencerIds: [String]!) {
    deleteIrmInfluencers(influencerIds: $influencerIds) {
      message
      code
      success
    }
  }
`