import { gql } from '@apollo/client'

export const toggleEstimate = gql`
 mutation toggleEstimate($ids: [String!], $estimate: Boolean!) {
    toggleEstimate(ids: $ids, estimate: $estimate ){
        message
        success
  }
 }
 `
