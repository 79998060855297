import { gql } from '@apollo/client'

export const addTrackingMutation = gql`
 mutation addReportV2Tracking(
  $brandId: String!
  $reportV2Id: String!
  $name: String!
  $network: String!
  $hashtags: [String]
  $mentions: [String]
  $keywords: [String]
  $startDate: String!
  $endDate: String
  $influencers: [InfluencerInTrackingInputType]!
) {
  addReportV2Tracking(
    brandId: $brandId,
    reportV2Id: $reportV2Id,
    name: $name,
    network: $network,
    hashtags: $hashtags,
    mentions: $mentions,
    keywords: $keywords,
    influencers: $influencers,
    startDate: $startDate,
    endDate: $endDate) {
      _id,
      createdAt,
      updatedAt,
      brandId,
      reportV2Id,
      name,
      network,
      hashtags,
      mentions,
      keywords,
      endDate,
      resumeDate,
      status,
      influencers {
        username
        network
        profilePicture
        followers
        profileUrl
        apiAccess
        analyzeProfile
      }
    }
 }
`
