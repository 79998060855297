import { createContext, useReducer } from 'react'
import { get } from 'lodash'
import { withRouter } from 'next/router'
import { influencerNoteWithContentSessionStorage } from '~/constants/irm'

if (typeof window !== 'undefined') sessionStorage.setItem(influencerNoteWithContentSessionStorage, false)

export const ModalsContext = createContext()

const initialState = {
  influencerId: undefined,
  listId: undefined,
  profileId: null,
  noteId: undefined,
  influencerIds: [],
  listIds: [],
  showEditIrmInfluencer: false,
  showSendEmailIrmInfluencer: false,
  showAddInfluencer: false,
  showAddList: false,
  showEditInfluencer: false,
  showEditList: false,
  showShareList: false,
  showPreviewInfluencer: false,
  showPreviewProfile: false,
  showDeleteInfluencer: false,
  showDeleteInfluencers: false,
  showDeleteList: false,
  showDeleteLists: false,
  showListInfluencer: false,
  showListInfluencers: false,
  showCampaignList: false,
  showCampaignInfluencer: false,
  showCampaignInfluencers: false,
  showFiltersModal: false,
  showFiltersModalMyInfluencers: false,
  showNewNoteModal: false,
  showDeleteNoteConfirmModal: false,
  showConfirmCloseNoteWithContent: false,
  lastAction: {},
  isEditTrackingOpen: false,
  trackingData: {}
}

const reducer = (state, action) => {
  const influencerNoteWithContent = sessionStorage.getItem(influencerNoteWithContentSessionStorage)
  switch (action.type) {
    case 'reset':
      return initialState
    case 'setInfluencerIds':
      return { ...initialState, influencerIds: action.payload }
    case 'toggleAddInfluencer':
      return { ...initialState, showAddInfluencer: !state.showAddInfluencer }
    case 'toggleAddList':
      return { ...initialState, showAddList: !state.showAddList }
    case 'closePreviewInfluencer':
      return { ...initialState, showPreviewInfluencer: false, influencerId: action.payload }
    case 'openPreviewInfluencer':
      if (influencerNoteWithContent !== 'false') { return { ...state, showConfirmCloseNoteWithContent: true, lastAction: action } }
      return { ...initialState, showPreviewInfluencer: true, influencerId: action.payload }
    case 'closePreviewProfile':
      return { ...initialState, showPreviewProfile: false, profileId: action.payload }
    case 'openPreviewProfile':
      return { ...initialState, showPreviewProfile: true, profileId: action.payload }
    case 'toggleEditIrmInfluencer':
      return { ...initialState, showEditIrmInfluencer: !state.showEditIrmInfluencer, influencerId: action.payload }
    case 'toggleEditInfluencer':
      return { ...initialState, showEditInfluencer: !state.showEditInfluencer, influencerId: action.payload }
    case 'openEditIrmInfluencer':
      return { ...initialState, showEditIrmInfluencer: true, influencerId: action.payload }
    case 'openEditInfluencer':
      return { ...initialState, showEditInfluencer: true, influencerId: action.payload }
    case 'toggleSendEmailIrmInfluencer':
      return { ...initialState, showSendEmailIrmInfluencer: !state.showSendEmailIrmInfluencer, influencers: action.influencers, useEmails: action.useEmails }
    case 'openSendEmailIrmInfluencer':
      return { ...initialState, showSendEmailIrmInfluencer: true, influencers: action.influencers }
    case 'toggleEditList':
      return { ...initialState, showEditList: !state.showEditList, listId: action.payload }
    case 'openEditList':
      return { ...initialState, showEditList: true, listId: action.payload }
    case 'toggleDeleteInfluencer':
      return { ...initialState, showDeleteInfluencer: !state.showDeleteInfluencer }
    case 'toggleDeleteInfluencers':
      return { ...initialState, showDeleteInfluencers: !state.showDeleteInfluencers }
    case 'toggleDeleteList':
      return { ...initialState, showDeleteList: !state.showDeleteList }
    case 'toggleDeleteLists':
      return { ...initialState, showDeleteLists: !state.showDeleteLists }
    case 'toggleListInfluencer':
      return { ...initialState, showListInfluencer: !state.showListInfluencer, influencerId: action.payload }
    case 'toggleListInfluencers':
      return { ...initialState, showListInfluencers: !state.showListInfluencers, influencerIds: action.payload }
    case 'toggleCampaignInfluencer':
      return { ...initialState, showCampaignInfluencer: !state.showCampaignInfluencer, influencerId: action.payload }
    case 'toggleCampaignInfluencers':
      return { ...initialState, showCampaignInfluencers: !state.showCampaignInfluencers, influencerIds: action.payload }
    case 'toggleCampaignList':
      return { ...initialState, showCampaignList: !state.showCampaignList }
    case 'toggleShareList':
      return { ...initialState, showShareList: !state.showShareList, listId: action.payload }
    case 'toggleFiltersModalMyInfluencers':
      return { ...initialState, showFiltersModalMyInfluencers: !state.showFiltersModalMyInfluencers }
    case 'toggleNewNoteModal':
      return { ...state, showNewNoteModal: !state.showNewNoteModal, influencerId: action.payload }
    case 'toggleDeleteNoteConfirmModal':
      return { ...state, showDeleteNoteConfirmModal: !state.showDeleteNoteConfirmModal, note: action.payload }
    case 'toggleConfirmCloseNoteWithContent':
      if (action.payload === 'confirm') {
        return reducer({ ...state, showConfirmCloseNoteWithContent: false, noteWithContent: false, showNewNoteModal: false }, state.lastAction)
      }
      return { ...state, showConfirmCloseNoteWithContent: !state.showConfirmCloseNoteWithContent, note: action.payload }
    case 'toggleCreateReport':
      return { ...initialState, showCreateReport: !state.showCreateReport }
    case 'toggleDeleteReport':
      return { ...initialState, showDeleteReport: !state.showDeleteReport, reportId: action.payload }
    case 'toggleDeleteReports':
      return { ...initialState, showDeleteReports: !state.showDeleteReports, reportIds: action.payload }
    case 'toggleAddPosts':
      return { ...initialState, showAddPosts: !state.showAddPosts, numberOfPosts: action.payload, selectedSection: action.selectedSection }
    case 'keepAddingPosts':
      return { ...state, numberOfPosts: state.numberOfPosts + action.payload }
    case 'openEditPost':
      return { ...initialState, showEditPost: true, postId: action.payload }
    case 'closeEditPost':
      return { ...state, showEditPost: false }
    case 'toggleDeletePost':
      return { ...state, showDeletePost: !state.showDeletePost, postId: action.payload }
    case 'toggleDeletePosts':
      return { ...initialState, showDeletePosts: !state.showDeletePosts, postIds: action.payload }
    case 'openEditReportsInfluencer':
      return { ...initialState, showEditReportsInfluencer: true, profileId: action.payload }
    case 'closeEditReportsInfluencer':
      return { ...state, showEditReportsInfluencer: false }
    case 'toggleMultiBrand':
      return { ...state, isMultiBrandOpen: !state.isMultiBrandOpen }
    case 'toggleConnectInbox':
      return { ...initialState, isConnectInboxOpen: !state.isConnectInboxOpen }
    case 'toggleNewEmail':
      return { ...state, isNewEmailOpen: !state.isNewEmailOpen, email: action.payload }
    case 'toggleEmailSetup':
      return { ...initialState, isEmailSetupOpen: !state.isEmailSetupOpen }
    case 'toggleEmailPassword':
      return { ...state, isEmailPasswordOpen: !state.isEmailPasswordOpen }
    case 'toggleEmailEnable':
      return { ...state, isEmailEnableOpen: !state.isEmailEnableOpen }
    case 'toggleEmailDisable':
      return { ...state, isEmailDisableOpen: !state.isEmailDisableOpen }
    case 'toggleEmailDelete':
      return { ...state, isEmailDeleteOpen: !state.isEmailDeleteOpen, emailAccount: action.payload }
    case 'toggleMessageDelete':
      return { ...state, isMessageDeleteOpen: !state.isMessageDeleteOpen, messageId: action.payload }
    case 'toggleEditTracking':
      return { ...initialState, isEditTrackingOpen: !state.isEditTrackingOpen, trackingData: action.payload }
    case 'openPreviewPost':
      return { ...state, showPreviewPost: true, postId: action.payload }
    case 'closePreviewPost':
      return { ...state, showPreviewPost: false }
    default: return { ...state }
  }
}

const ModalsContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())
  const value = { state, dispatch }

  function getInitialState () {
    const initialStatusFiltersModal = get(props, 'router.query.modalStatus')
    const isFiltersOpen = initialStatusFiltersModal === 'open'
    return {
      ...initialState,
      showFiltersModal: isFiltersOpen || false
    }
  }

  return (
    <ModalsContext.Provider value={value}>
      {props.children}
    </ModalsContext.Provider>
  )
}

const ModalsContextProviderWR = withRouter(ModalsContextProvider)
export { ModalsContextProviderWR as ModalsContextProvider }

export const ModalsContextConsumer = ModalsContext.consumer
