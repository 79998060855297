import { gql } from '@apollo/client'

export const editProgramV2 = gql`
  mutation editProgramV2(
    $_id: String!
    $name: String!
    $description: String
  ) {
    editProgramV2(
      _id: $_id
      name: $name
      description: $description
    ) {
      message
      success
    }
  }
`
