import { gql } from "@apollo/client";

export const getEntityViewsByBrand = gql`
  query getEntityViewsByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: EntityViewSortParamsType, 
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getEntityViewsByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      id: _id
      entityViewName
      object
      inUse
      type
      translations {
        es {
          object
        }
        en {
          object
        }
        fr {
          object
        }
        de {
          object
        }
        it {
          object
        }
        pt {
          object
        }
      }
      createdByUser {
        firstName
      }
      createdAt
    }
  }
`
