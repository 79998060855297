import { createContext, useReducer } from 'react'

const initialValues = {
  alert: null,
  rule: null,
  mention: null,
  isResumeAlertModalOpen: false,
  isPauseAlertModalOpen: false,
  isCreateAlertModalOpen: false,
  isEditAlertModalOpen: false,
  isDeleteAlertModalOpen: false,
  isDeleteAlertsModalOpen: false,
  isAlertRuleModalOpen: { isOpen: false, cb: () => {} },
  isConfigureQueryModalOpen: false,
  isHistoricalMentionsModalOpen: { isOpen: false, isBetween: false, date: null },
  isRunQueryConfirmationModalOpen: false,
  isUnsavedChangesModalOpen: { isOpen: false, query: null },
  isQueryIsTooBroadModalOpen: false
}

export const SocialListeningContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setAlert':
      return { ...state, alert: action.payload }
    case 'setRule':
      return { ...state, rule: action.payload }
    case 'setMention':
      return { ...state, mention: action.payload }
    case 'setResumeAlertModalOpen':
      return { ...state, isResumeAlertModalOpen: action.payload }
    case 'setPauseAlertModalOpen':
      return { ...state, isPauseAlertModalOpen: action.payload }
    case 'setCreateAlertModalOpen':
      return { ...state, isCreateAlertModalOpen: action.payload }
    case 'setEditAlertModalOpen':
      return { ...state, isEditAlertModalOpen: action.payload }
    case 'setDeleteAlertModalOpen':
      return { ...state, isDeleteAlertModalOpen: action.payload }
    case 'setDeleteAlertsModalOpen':
      return { ...state, isDeleteAlertsModalOpen: action.payload }
    case 'setAlertRuleModalOpen':
      return { ...state, isAlertRuleModalOpen: action.payload }
    case 'setConfigureQueryModalOpen':
      return { ...state, isConfigureQueryModalOpen: action.payload }
    case 'setHistoricalMentionsModalOpen':
      return { ...state, isHistoricalMentionsModalOpen: action.payload }
    case 'setRunQueryConfirmationModalOpen':
      return { ...state, isRunQueryConfirmationModalOpen: action.payload }
    case 'setUnsavedChangesModalOpen':
      return { ...state, isUnsavedChangesModalOpen: action.payload }
    case 'setQueryIsTooBroadModalOpen':
      return { ...state, isQueryIsTooBroadModalOpen: action.payload }
  }
}

export const SocialListeningProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setAlert = (alert) => {
    dispatch({ type: 'setAlert', payload: alert })
  }

  const setRule = (rule) => {
    dispatch({ type: 'setRule', payload: rule })
  }

  const setMention = (mention) => {
    dispatch({ type: 'setMention', payload: mention })
  }

  const setResumeAlertModalOpen = (isOpen) => {
    dispatch({ type: 'setResumeAlertModalOpen', payload: isOpen })
  }

  const setPauseAlertModalOpen = (isOpen) => {
    dispatch({ type: 'setPauseAlertModalOpen', payload: isOpen })
  }

  const setCreateAlertModalOpen = (isOpen) => {
    dispatch({ type: 'setCreateAlertModalOpen', payload: isOpen })
  }

  const setEditAlertModalOpen = (isOpen) => {
    dispatch({ type: 'setEditAlertModalOpen', payload: isOpen })
  }

  const setDeleteAlertModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteAlertModalOpen', payload: isOpen })
  }

  const setDeleteAlertsModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteAlertsModalOpen', payload: isOpen })
  }

  const setAlertRuleModalOpen = (isOpen, cb = () => {}) => {
    dispatch({ type: 'setAlertRuleModalOpen', payload: { isOpen, cb } })
  }

  const setConfigureQueryModalOpen = (isOpen) => {
    dispatch({ type: 'setConfigureQueryModalOpen', payload: isOpen })
  }

  const setHistoricalMentionsModalOpen = (isOpen, isBetween, date) => {
    dispatch({ type: 'setHistoricalMentionsModalOpen', payload: { isOpen, isBetween, date } })
  }

  const setRunQueryConfirmationModalOpen = (isOpen) => {
    dispatch({ type: 'setRunQueryConfirmationModalOpen', payload: isOpen })
  }

  const setUnsavedChangesModalOpen = (isOpen, query) => {
    dispatch({ type: 'setUnsavedChangesModalOpen', payload: { isOpen, query } })
  }

  const setQueryIsTooBroadModalOpen = (isOpen) => {
    dispatch({ type: 'setQueryIsTooBroadModalOpen', payload: isOpen })
  }

  return (
    <SocialListeningContext.Provider value={{
      ...state,
      setAlert,
      setRule,
      setMention,
      setResumeAlertModalOpen,
      setPauseAlertModalOpen,
      setCreateAlertModalOpen,
      setEditAlertModalOpen,
      setDeleteAlertModalOpen,
      setDeleteAlertsModalOpen,
      setAlertRuleModalOpen,
      setConfigureQueryModalOpen,
      setHistoricalMentionsModalOpen,
      setRunQueryConfirmationModalOpen,
      setUnsavedChangesModalOpen,
      setQueryIsTooBroadModalOpen
    }}>
      {children}
    </SocialListeningContext.Provider>
  )
}
