import { gql } from '@apollo/client'

export const getIRMList = gql`
  query getIRMList ( 
    $listId: String
    $sharedId: String
  ) {
    getIRMList(listId: $listId, sharedId: $sharedId) {
      id: _id
      brandId
      createdAt
      createdByUser {
        firstName
      }
      description
      listName
      networks
      object
      pictures
      profiles {
        _id
        info
      }
      irmInfluencers {
        _id
        info
      }
      size
      type
      updatedAt
      updatedByUser {
        firstName
      }
    }
  }
`
