import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Button, Flex, Image, Spacing, Text } from 'influ-dms'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { RouterHelper } from '../../../../../routing/routerHelper'
import { ModalsContext } from '../../../context'
import { CommunicationContext } from '../../CommunicationContext'
import { useTranslate } from '~/hooks/useTranslate'

const overflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
`

const Container = styled(Flex)`
  padding: 24px 24px 32px 24px;
  ${overflowStyles};
`

export const ConnectInboxModalContent = ({ onClose }) => {
  const { t } = useTranslate()
  const { dispatch } = useContext(ModalsContext)
  const { accountSettings } = useContext(CommunicationContext)
  const isAccountSetup = get(accountSettings, 'success')
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const handleConnectInbox = () => {
    if (isAccountSetup) {
      router.push(`${RouterHelper.getRoute('communication', { brandId })}#personalEmail`)
      onClose()
    } else {
      dispatch({ type: 'toggleEmailSetup' })
    }
  }
  const buttonText = isAccountSetup ? t('commons.button.goToSettings') : t('commons.button.connectYourInbox')
  return (
    <Container center data-cy='ConnectInboxModalContent'>
      <Text color='primary.dark' bold size='20' styles={overflowStyles}>{t('influencers.modal.composeMessage.inboxNoConnected.titleHeader1')}</Text>
      <Spacing size='16' />
      <Text color='primary.light1' size='12' styles={overflowStyles}>{t('influencers.modal.composeMessage.inboxNoConnected.titleParagraph1')}</Text>
      <Spacing size='32' />
      <Image width='200' height='200' src='/static/img/talkToCustomerSuccess.png' alt='Connect your inbox' styles={overflowStyles} />
      <Spacing size='32' />
      <Button size='l' onClick={handleConnectInbox}>{buttonText}</Button>
    </Container>
  )
}
