import { gql } from '@apollo/client'
import { influencerFragment } from './fragments'

const profilesAreAlreadyInIRM = gql`
 query profilesAreAlreadyInIRM(
  $brandId: String!,
  $edges: [DeepSocialDataInput],
 ){
  profilesAreAlreadyInIRM(
     brandId: $brandId,
     edges: $edges,
   ) {
     ...influencer
   }
 }
 ${influencerFragment}
`

export { profilesAreAlreadyInIRM }
