import { Icon, Flex, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import styled from 'styled-components'

const StyledCard = styled(Flex)`
  padding: 12px 16px;
  flex-direction: row;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.primary.light6};
  }
`

const Oval = styled.div`
  padding: 4px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.blue.disabled};
`

export const SendMassiveEmailTemplateOptionsModal = ({ handleSelectTemplateOption }) => {
  const { t } = useTranslate()

  return (
    <>
      <Text bold color={'primary.dark'} size='14'>
        {`${t('modal.selectEmailType.startFrom.title.header1')} *`}
      </Text>
      <Spacing size={16} />
      <Flex column gap='16px'>
        <StyledCard onClick={() => handleSelectTemplateOption('scratch')}>
          <Oval>
            <Icon name='edit' size='16' color='blue.disabled' />
          </Oval>
          <Spacing vertical size={12} />
          <Flex column>
            <Text bold color={'primary.dark'} size='14'>
              {t('entity.email.field.startFrom.value.emailFromScratch.label')}
            </Text>
            <Spacing size={4} />
            <Text color={'primary.light2'} size='14'>
              {t('entity.email.field.startFrom.value.emailFromScratch.paragraph1')}
            </Text>
          </Flex>
        </StyledCard>
        <StyledCard onClick={() => handleSelectTemplateOption('template')}>
          <Oval>
            <Icon name='document' size='16' color='blue.disabled' />
          </Oval>
          <Spacing vertical size={12} />
          <Flex column>
            <Text bold color={'primary.dark'} size='14'>
              {t('entity.email.field.startFrom.value.emailFromTemplate.label')}
            </Text>
            <Spacing size={4} />
            <Text color={'primary.light2'} size='14'>
              {t('entity.email.field.startFrom.value.emailFromTemplate.paragraph1')}
            </Text>
          </Flex>
        </StyledCard>
      </Flex>
    </>
  )
}
