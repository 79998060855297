import { createContext, useReducer } from 'react'

const initialValues = {
  landingPage: undefined,
  isAddLandingPageModalOpen: false,
  isEditLandingPageModalOpen: false,
  isDeleteLandingPageModalOpen: false,
  isConfigureBrandModalOpen: false,
}

export const LandingPageContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setLandingPage':
      return { ...state, landingPage: action.payload }
    case 'setAddLandingPageModalOpen':
      return { ...state, isAddLandingPageModalOpen: action.value }
    case 'setEditLandingPageModalOpen':
      return { ...state, isEditLandingPageModalOpen: action.value }
    case 'setDeleteLandingPageModalOpen':
      return { ...state, isDeleteLandingPageModalOpen: action.value }
    case 'setConfigureBrandModalOpen':
      return { ...state, isConfigureBrandModalOpen: action.value }
  }
}

export const LandingPageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setLandingPage = (landingPage) => {
    dispatch({ type: 'setLandingPage', payload: landingPage })
  }

  const openAddLandingPageModal = () => {
    dispatch({ type: 'setAddLandingPageModalOpen', value: true })
  }

  const closeAddLandingPageModal = () => {
    dispatch({ type: 'setAddLandingPageModalOpen', value: false })
  }

  const openEditLandingPageModal = () => {
    dispatch({ type: 'setEditLandingPageModalOpen', value: true })
  }

  const closeEditLandingPageModal = () => {
    dispatch({ type: 'setEditLandingPageModalOpen', value: false })
  }

  const openDeleteLandingPageModal = () => {
    dispatch({ type: 'setDeleteLandingPageModalOpen', value: true })
  }

  const closeDeleteLandingPageModal = () => {
    dispatch({ type: 'setDeleteLandingPageModalOpen', value: false })
  }

  const openConfigureBrandModal = () => {
    dispatch({ type: 'setConfigureBrandModalOpen', value: true })
  }

  const closeConfigureBrandModal = () => {
    dispatch({ type: 'setConfigureBrandModalOpen', value: false })
  }

  return (
    <LandingPageContext.Provider value={{
      ...state,
      setLandingPage,
      openAddLandingPageModal,
      closeAddLandingPageModal,
      openEditLandingPageModal,
      closeEditLandingPageModal,
      openDeleteLandingPageModal,
      closeDeleteLandingPageModal,
      openConfigureBrandModal,
      closeConfigureBrandModal,
    }}>
      {children}
    </LandingPageContext.Provider>
  )
}
