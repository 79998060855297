import { useContext } from 'react'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { LimitsContext } from '~/context'
import { RouterHelper } from '~/routing/routerHelper'

export const PrintEntityTypes = {
  PROFILE: {
    name: 'profile',
    accessorId: 'profileId',
    print: 'profilePrint'
  },
  INFLUENCER: {
    name: 'influencer',
    accessorId: 'influencerId',
    print: 'irmInfluencerPrint'
  },
  LIST: {
    name: 'list',
    accessorId: 'listId',
    print: 'irmListPrint'
  },
  PROGRAM: {
    name: 'program',
    accessorId: 'programId',
    print: 'irmProgramPrint'
  },
  OUTREACH: {
    name: 'outreach',
    accessorId: 'outreachId',
    print: 'outreachPrint'
  }
}

export const useToPrint = ({
  entity,
  entityId,
  limitText,
  canPrint
}) => {
  const { setLimitOpen, setLimit } = useContext(LimitsContext)
  const router = useRouter()

  const brandId = get(router, 'query.brandId')

  function goTo () {
    if (!canPrint) {
      setLimitOpen(true)
      setLimit(limitText)
    } else if (typeof window !== 'undefined') {
      const params = {
        brandId,
        [`${entity.accessorId}`]: entityId
      }
      const route = entityId ? RouterHelper.getUrl(entity.print, params) : ''
      const domain = window.location.origin
      window.open(`${domain}${route}`, '_blank')
      RouterHelper.traceEvent(entity.print, params)
    }
  }

  return {
    goTo
  }
}
