import { gql } from '@apollo/client'


export const notifyIrmInfluencerDataProcesssed = gql`
subscription notifyIrmInfluencerDataProcesssed($brandId: String!){
  notifyIrmInfluencerDataProcesssed(brandId: $brandId){
    brandId
    influencerId
    subscriptionStatus
  }
}
`
