import styled, { css } from 'styled-components'
import { ActionIcons, Actions } from './Action'

export const StyledSeparator = styled.div`
  width: 16px;
  height: 0px;
  ${({ theme }) => `border: .1px solid ${theme.colors.primary.light4};`}
  transform: rotate(90deg);
`

export const roundedButtonStyles = css`
  & {
    margin: 0;
    height: 28px;
    width: ${({ name }) => name === ActionIcons[Actions.addMultiple] ? '40px' : '28px'};
    padding: 4px;
  }
  a {
    background: transparent;
    box-shadow: none;
  }
`

export const comparatorActionsStyles = css`
  & {
    width: 32px;
    height: 24px;
    border-radius: 4px;
    ${({ theme }) => `background-color: ${theme.colors.primary.light5};`}
    margin: 0;
    justify-content: center;
  }

  a {
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: -4px;

    & > svg {
      width: 16px;
      height: 16px;
      ${({ theme }) => `color: ${theme.colors.primary.dark};`}
    }

    & > :nth-child(2) {
      display: none;
    }
  }
`
