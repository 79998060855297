import { useState } from 'react'
import { get } from 'lodash'
import { Button, Flex, InputText, Modal, Spacing, StateButton } from 'influ-dms'
import { DeletePostsMutation } from './Mutation'
import { useTranslate } from '~/hooks/useTranslate'

export const DeletePosts = ({ postIds, isOpen, onSuccess, onClose }) => {
  const { t } = useTranslate()
  const [term, setTerm] = useState('')
  const [error, setError] = useState('')
  const count = get(postIds, 'length') || 0
  const title = t('reports.modal.detelePublication.titleHeader1', { numberOfPublications: count })
  const subtitle = t('reports.modal.detelePublication.titleParagraph1', { numberOfPublications: count })
  const label = t('reports.modal.detelePublication.input.numberOfReportsToDelete.label')
  const typeNumber = t('reports.modal.detelePublications.input.numberOfPublicationsToDelete.helpertext.error')
  const enableDelete = postIds && postIds.length

  const handleClose = () => {
    onClose()
    setError('')
    setTerm('')
  }

  const onDelete = (mutationHandler, loading) => {
    if (loading) return
    if (count !== Number(term)) {
      setError(typeNumber)
      return
    }
    mutationHandler({ ids: postIds })
      .then(() => {
        if (onSuccess) onSuccess()
        handleClose()
      })
      .catch((error) => {
        setError(error)
      })
  }

  const onChange = (e) => {
    const value = e.target.value
    setTerm(value)
    setError('')
  }

  if (!isOpen) return null

  return (
    <DeletePostsMutation>
      {({ mutationHandler, loading }) => {
        return (
          <Modal
            name='deletePosts'
            color='state.dangerDefault'
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            subtitle={subtitle}
          >
            <Flex column grow='1'>
              <InputText
                name='deletePostsInput'
                label={label}
                placeholder={count}
                value={term}
                onChange={onChange}
                variant={error ? 'danger' : 'default'}
                helperText={error}
                autoFocus={isOpen}
                handleEnter={() => onDelete(mutationHandler, loading)}
              />
            </Flex>
            <Flex>
              <StateButton
                name='deletePosts'
                color='danger'
                disabled={loading || !enableDelete}
                onClick={() => onDelete(mutationHandler, loading)}
                showLoader
                loading={loading}
              >{t('commons.button.delete')}
              </StateButton>
              <Spacing vertical size='16' />
              <Button
                name='cancelDeletePosts'
                variant='flat'
                color='secondary'
                disabled={loading}
                onClick={handleClose}
              >{t('commons.button.cancel')}
              </Button>
            </Flex>
          </Modal>
        )
      }}
    </DeletePostsMutation>
  )
}
