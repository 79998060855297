import { Form } from 'influ-dms'
import styled, { css } from 'styled-components'

export const modalContainerStyles = css`
  padding: 24px 32px 12px 32px;
  min-height: 440px;
  justify-content: center;
`

export const loaderStyles = css`
  display: flex;
  padding: 64px;
  align-items: center;
  justify-content: center;
`

export const textStyles = css`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.light1};
  }
`

export const StyledDiv = styled.div`
  position: relative;
  .quill {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
  }
  div[data-cy^='MassiveEmailQuill'] {
    ${({ styles }) => styles}
  }
`

export const StyledForm = styled(Form)`
  div[data-cy='Modal-Background'] {
    background: transparent;
    box-shadow: 0px 2px 16px 0px rgba(124, 113, 148, 0.20);
    div[data-cy='Modal-personalizationToken'] {
      width: auto;
      height: auto;
    }
  }
`

export const AttachmentListContainer = styled.div`
  max-height: 100px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const ModalContainer = styled.div`
  position: absolute;
  ${({ selectedQuill, isSideModal }) => {
    if (isSideModal) {
      return selectedQuill === 'subject' ? `
        right: 32px;
        top: 125px;
      ` : selectedQuill === 'preview' ? `
        right: 32px;
        top: 175px;
      ` : selectedQuill === 'seedCode' ? `
        right: 0px;
        top: 40px;
      ` : `
        right: 32px;
        bottom: 185px;
      `
    }
    return selectedQuill === 'html' ? `
      left: 180px;
      bottom: 32px;
    ` : `
      right: 32px;
      bottom: 112px;
    `
  }}
  ${({ isOpen }) => isOpen ? `
    display: flex;
    flex-direction: column;
  ` : `
    display: none;
  `}
  z-index: 104;
  padding: 12px 16px;
  max-height: 70vh;
  max-width: 280px;
  border-radius: 8px; 
  box-shadow: 0px 2px 16px 0px rgba(124, 113, 148, 0.20);
  background: ${({ theme }) => theme.colors.neutral.white};
  box-sizing: border-box;
  ${({ isInsideCenteredModal }) => isInsideCenteredModal && `
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(0%, 20%);
  `}
`

export const searchStyles = css`
  border: 1px solid #C5C5D2;
  background: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.dark};
  height: 32px;
`

export const widgetTooltipContainerStyles = css`
  overflow: hidden;
  cursor: pointer;
  max-width: 20px;
`

export const widgetTooltipStyles = css`
  width: unset;
`

export const wrapperStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #C5C5D2;
  border-radius: 4px;
  padding: 0 8px 0 0;
  box-sizing: border-box;

  input {
    border: none;
    &:focus {
      border: none;
    }
    &:hover {
      border: none;
    }
    &:active {
      border: none;
    }
  }
`

export const Divider = styled.hr`
  border: 0.5px solid #ededf4;
  background: #ededf4;
  width: 100%;
`

export const templateModalPersonalizeButtonStyles = css`
  position: absolute;
  right: 0;
  bottom: 5px;
`

export const customAttachmentButtonStyles = css`
  position: absolute;
  right: 101px;
  bottom: 5px;
`

export const iconStyles = css`
  display: inline;
  margin-right: 2px;
`
