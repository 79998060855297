import { gql } from '@apollo/client'

export const getMTConfigFilters = gql`
query getMTConfigFilters(
  $brandId: String,
  $sharedId: String,
  $tableName: FilterGroupsTableNameEnumType!,
  $view: String!
) {
  getMTConfigFilters(
    brandId: $brandId,
    sharedId: $sharedId,
    tableName: $tableName,
    view: $view
  ) {
      _id
      tableName
      name
      order
      translationKey
      filters {
        _id
        tableName
        label
        labelId
        order
        groupName
        groupId
        translationKey
        favoriteOrder
        type
        autocompleteWith
        autocompleteWithOption
        data{
          key
          value
          label
          translationKey
          originalLabel
          type
          icon
        }
        operations{
          label
          labelId
          translationKey
          multipleSelections
          maxSelections
          separator
          separatorTranslationKey
          order
          input
          between
          freeOption
        }
        numberValues{
          key
          value
          label
          percentage
        }
      }
  }
}
 `



