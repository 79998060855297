import { get, isEmpty } from 'lodash'
import { MESSAGE_LIMITS } from './CommunicationConstants'
export function removeSpecialChars (contentId = '') {
  return contentId.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
}

export const _replaceInlineAttachments = (html, attachments) => {
  if (!html) return ''
  if (!isEmpty(attachments)) {
    for (const attachment of attachments) {
      html = html.replace(`src="cid:${removeSpecialChars(attachment.contentId)}"`,
    `alt="${attachment.contentId}" src="data:image/png;base64,${attachment.content}"`)
    }
  }
  return html
}

export const _replaceInlineContentIds = (html, attachments) => {
  const resultAttachments = []
  let resultHtml = html
  if (!isEmpty(attachments)) {
    for (const attachment of attachments) {
      const div = document.createElement('div')
      div.innerHTML = resultHtml.trim()
      const imgElement = div.querySelectorAll('[alt="' + attachment.contentId + '"]')
      if (imgElement && imgElement.length) {
        const src = `cid:${attachment.contentId}`
        imgElement[0].src = src
        imgElement[0].removeAttribute('alt')
        const file = dataURLtoFile('data:image/png;base64,' + attachment.content, attachment.filename)
        resultAttachments.push({ file, inline: true, contentId: attachment.contentId })
      }

      resultHtml = div.innerHTML
    }
  }
  return { html: resultHtml, attachments: resultAttachments }
}

export function dataURLtoFile (dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function emailSizeExceeded10Mb (html, attachments) {
  let totalSize = (new TextEncoder().encode(html)).length
  if (!isEmpty(attachments)) {
    for (const attachment of attachments) {
      totalSize += get(attachment, 'file.size', 0)
    }
  }
  return totalSize > MESSAGE_LIMITS.sizeLimit
}

export function attachmentCountExceeded (attachments) {
  if (attachments && attachments.length > MESSAGE_LIMITS.attachmentCount) return true
}
