import styled from 'styled-components'
import { Flex, Text } from 'influ-dms'
import { EmailSetupOption } from './EmailSetupOption'
import { servers } from './conf'
import { useTranslate } from '~/hooks/useTranslate'
import { bindLinksMap } from '~/hooks/useLink'
import { useEffect, useState } from 'react'
import { useLanguageContext } from '../../../../context/Language'

const StyledFlex = styled(Flex)`
  margin: 28px 0 59px 0;
`

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`

const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.light1};
  :hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`

const OptionsContainer = styled(Flex)`
  margin-top: 8px;
`

export const SetupContent = ({ handleClick, googleRedirect }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const [learnMoreLink, setLearnMoreLink] = useState('')
  const linksMap = new Map([
    ['learn_more_link', setLearnMoreLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])

  return (
    <StyledFlex center>
      <StyledText bold size='14' color='primary.dark'>{t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.withEmailPackage.titleHeader1')}</StyledText>
      <StyledText size='12' color='primary.light1'>
        {t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.withEmailPackage.titleParagraph1')} <StyledLink href={learnMoreLink} target='_blank'>{t('commons.button.learnMore')}</StyledLink>
      </StyledText>
      <OptionsContainer>
        {/* <EmailSetupOption text='Connect API Gmail' handleClick={googleRedirect} img={servers.gmail.image} /> */}
        <EmailSetupOption text={t('communication.email.configuration.modal.connectInbox.withEmailPackage.connectOffice')} server={servers.outlook.id} handleClick={handleClick} img={servers.outlook.image} />
        <EmailSetupOption text={t('communication.email.configuration.modal.connectInbox.withEmailPackage.connectOther')} server={servers.imap.id} handleClick={handleClick} img={servers.imap.image} />
      </OptionsContainer>
    </StyledFlex>
  )
}
