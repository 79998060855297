import { Avatar, Flex } from 'influ-dms'
import { avatarContactStyles } from './styles'

export const AvatarContact = ({ contactImg }) => {
  return (
    <Flex column align='flex-end'>
      <Avatar
        name='Profile'
        variant='contact'
        size='s'
        src={contactImg}
        styles={avatarContactStyles}
      />
    </Flex>
  )
}
