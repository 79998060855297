import { gql } from '@apollo/client'

export const deleteTask = gql`
 mutation deleteTask(
  $taskId: String!
 ) {
  deleteTask(
    _id: $taskId
   ){
      id: _id
    }
 }
 `
