function cookiesParser (document) {
  const list = {}
  const rc = document && document.cookie

  rc && rc.split(';').forEach((cookie) => {
    const parts = cookie.split('=')
    list[parts.shift().trim()] = decodeURI(parts.join('='))
  })

  return list
}

export default cookiesParser
