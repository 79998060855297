import { Flex, Icon, Text } from 'influ-dms'
import { AvatarContact } from '../AvatarContact/AvatarContact'
import { AvatarBrand } from '../AvatarBrand'
import { AccountProfile } from './styles'

export const Account = ({
  isTrial,
  isExpanded,
  brandImg,
  brandName,
  contactFirstName,
  contactImg,
  handleUpgrade,
  setAvatarOpen,
  setMenuDropdownOpened
}) => {
  return (
    <AccountProfile onClick={() => { setAvatarOpen(true); setMenuDropdownOpened(false) }} isExpanded={isExpanded}>
      {isExpanded &&
        <Flex column styles={{ marginLeft: '10px' }}>
          <Flex align='center' styles={{ width: '110px' }}>
            <Text color='primary.light3' size='14' bold ellipsis showTooltip containerStyles='width: 87%' tooltipPlacement='right'>
              {brandName}
            </Text>
            <Icon name='arrowrightthick' color='primary.light3' />
          </Flex>
          <Text color='primary.light3' size='12'>
            {contactFirstName}
          </Text>
        </Flex>
      }
      {brandName &&
        <AvatarContact contactImg={contactImg} />
      }
      <AvatarBrand
        isTrial={isTrial}
        variant='brand'
        size='l'
        src={brandImg}
        brandName={brandName}
        openUpgrade={handleUpgrade}
      />
    </AccountProfile>
  )
}
