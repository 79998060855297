import { gql } from '@apollo/client'

export const getSeedingCodesColumns = gql`
  query getSeedingCodesColumns {
    getSeedingCodesColumns {
      label
      fieldName
      columnType
      type
    }
  }
`