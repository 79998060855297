import styled from 'styled-components'
import { CellWrapper, Flex, Text } from 'influ-dms'
import { cellPadding, imagePostStyles } from '../../../../commons/reportsTable/styles'

const StyledContainer = styled(Flex)`
  cursor: pointer;
  ${cellPadding};
`
const ImageContainer = styled.div`
  width: 32px;
  height: 32px;
  ${imagePostStyles};
  border-radius: 50%;
`

export const ProfilesTrackingTableTd = ({ row: { original: { profilePicture, username } } }) => {
  return (
    <CellWrapper>
      <StyledContainer align='center'>
        <ImageContainer image={profilePicture} />
        <Text size='14' color='primary.light2' bold>@{username}</Text>
      </StyledContainer>
    </CellWrapper>
  )
}
