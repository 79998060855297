import { useState } from 'react'

// CHECK HOW TO USE @
// containers/campaignsV1/Overview/AddCampaign/index.js

const addItemToArrayState = item => {
  return previousState => {
    if (!previousState.includes(item)) {
      return [...previousState, item]
    }
    return previousState
  }
}

const nestArrayToState = (newArray) => {
  return previousState => {
    return [...previousState, ...newArray]
  }
}

const editItemInArrayState = (item, key) => {
  return previousState => {
    let findFunction = el => el === item
    if (key) findFunction = el => el[key] === item[key]
    const editIndex = previousState.findIndex(findFunction)
    return [...previousState.slice(0, editIndex), item, ...previousState.slice(editIndex + 1)]
  }
}

const removeItemFromArrayState = (item, key) => {
  return previousState => {
    let findFunction = el => el === item
    if (key) findFunction = el => el[key] === item
    const deleteIndex = previousState.findIndex(findFunction)
    return [...previousState.slice(0, deleteIndex), ...previousState.slice(deleteIndex + 1)]
  }
}

const removeArrayItemsFromState = (arrayItems) => {
  return previousState => {
    return previousState.filter(item => !arrayItems.includes(item))
  }
}

export const USE_ARRAY_ACTIONS = {
  ADD: 'add',
  NEST_NEW_ARRAY: 'nestNewArray',
  EDIT: 'edit',
  DELETE: 'delete',
  DELETE_FROM_ARRAY: 'deleteFromArray',
  DEFAULT: 'default'
}

export const useArray = (initialValue = []) => {
  const [array, setArray] = useState(initialValue)

  // keys are add, edit, delete
  const handleArray = (element, action = 'default', key) => {
    actions[action](element, key)
  }

  const actions = {
    add (element) {
      setArray(addItemToArrayState(element))
    },
    nestNewArray (newArray) {
      setArray(nestArrayToState(newArray))
    },
    edit (element, key) {
      setArray(editItemInArrayState(element, key))
    },

    delete (element, key) {
      setArray(removeItemFromArrayState(element, key))
    },
    deleteFromArray (array) {
      setArray(removeArrayItemsFromState(array))
    },
    default (element) {
      setArray(element)
    }
  }

  return [array, handleArray]
}
