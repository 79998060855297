import { gql } from '@apollo/client'

export const getIrmListSharedLink = gql`
  mutation getIrmListSharedLink($listId: String!) {
    getIrmListSharedLink(listId: $listId) {
      type,
      refId,
      sharedId
    }
  }
`