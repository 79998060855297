import { ApolloConsumer, gql } from '@apollo/client'

export const influencerExists = gql`
 query influencerExist($account: String!, $network: String!, $from: String) {
  influencerExist(account: $account, network: $network, from: $from){
      success
      url
      error
      reason
      isPrivate
    }
 }
 `

const InfluencerExists = ({ children }) => {
  return (
    <ApolloConsumer>
      {(client) => {
        const query = (variables) => {
          return client.query({
            query: influencerExists,
            variables,
            fetchPolicy: 'network-only'
          })
        }
        return children(query)
      }}
    </ApolloConsumer>
  )
}

export { InfluencerExists }
