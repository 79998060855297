import { gql } from '@apollo/client'

export const duplicateEntityField = gql`
  mutation duplicateEntityField(
    $_id: String!
  ) {
    duplicateEntityField(
      _id: $_id
    ) {
      message
      success
    }
  }
`
