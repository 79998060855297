import { gql } from '@apollo/client'
import fragments from './fragments'

export const contact = gql`
query contact{
  self{
    id: _id
    email
    intercomId
    firstLoginAt
    createdAt
    contact{
      ...Contact
    }
  }
}
${fragments.contact}
`
