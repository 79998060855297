import { useContext, useEffect, useRef, useState } from 'react'
import {
  Button,
  Icon,
  InputSearch,
  Flex,
  FormContext,
  ScrollableContainer,
  Select,
  Spacing,
  Text
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useLanguageContext } from '~/context/Language'
import {
  ModalContainer,
  searchStyles,
  textStyles
} from './styles'

const ENTITIES_WITH_INCOMPATIBILITIES = ['influencer', 'programV2', 'list']

export const PersonalizationTokenModal = ({
  isOpen,
  onClose,
  quillRef,
  selectedQuill,
  data,
  isSideModal = false,
  name,
  entityNames,
  onAddToken = () => {},
  isInsideCenteredModal = false
}) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const { values } = useContext(FormContext)
  const router = useRouter()
  const pathname = get(router, 'pathname')
  const isInInfluencers = pathname?.includes('influencers')
  const [term, setTerm] = useState('')
  const [entityValues, setEntityValues] = useState([])
  const [selectedEntity, setSelectedEntity] = useState('influencer')
  const wrapperRef = useRef(null)

  const tokenOptions = [
    { value: 'influencer', label: t('entity.property.field.object.value.influencers.label') },
    { value: 'programV2', label: t('entity.property.field.object.value.programs.label') },
    { value: 'user', label: t('entity.property.field.object.value.user.label') },
    { value: 'brand', label: t('entity.property.field.object.value.accountInformation.label') }
  ]

  const [options, setOptions] = useState(tokenOptions.filter((option) => entityNames.includes(option.value)))
  const defaultValue = tokenOptions.filter((option) => entityNames.includes(option.value))[0]

  useEffect(() => {
    function handleClickOutside (event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    const subject = values?.subject?.replace('<p><br></p>', '')
    const html = values?.html?.replace('<p><br></p>', '')
    const subjectEntity = subject?.match(/entityname="(.*?)"/i)?.pop()?.replace('entityname="', '')?.replace('"', '')
    const htmlEntity = html?.match(/entityname="(.*?)"/i)?.pop()?.replace('entityname="', '')?.replace('"', '')
    const emailEntity = !name && (subjectEntity || htmlEntity)

    if (data) {
      if (emailEntity) {
        if (ENTITIES_WITH_INCOMPATIBILITIES.includes(emailEntity)) {
          data.getEntityFieldsByEntity.forEach((entity) => {
            // const { incompatibleEntities, entityName } = entity
            const { entityName } = entity
            if (entityName === emailEntity) {
              const filteredOptions = options.map((option) => {
                // if (incompatibleEntities?.includes(option.value)) {
                //   option.isDisabled = true
                //   option.tooltipText = t('entity.property.field.object.value.label.disable.tooltip.differentEntity')
                // }
                return option
              })
              setOptions(filteredOptions)
            }
          })
        }
      } else {
        const filteredOptions = options.map((option) => {
          option.isDisabled = false
          option.tooltipText = ''
          return option
        })
        setOptions(filteredOptions)
      }
    }
  }, [data, values])

  useEffect(() => {
    if (data) {
      const entityFieldsValues = {}
      data.getEntityFieldsByEntity.forEach((entity) => {
        const { entityFieldGroups } = entity
        const groups = []
        entityFieldGroups.forEach((entityFieldGroup) => {
          const { entityFields, translations, order } = entityFieldGroup
          const group = { name: translations[locale]?.groupName, fields: [], order }
          const filteredFields = term
            ? entityFields.filter((field) => field.translations[locale].fieldName.toLowerCase().includes(term.toLowerCase()))
            : entityFields
          filteredFields?.forEach((field) => {
            const { _id: id, translations, order, entityName, fieldName } = field
            const localeFieldName = translations[locale].fieldName
            const localeEntityName = translations[locale].entityName
            group.fields = [
              ...group.fields,
              {
                id,
                value: `${localeEntityName}.${localeFieldName.replace('.0', '')}`,
                label: localeFieldName.replace('.0', ''),
                order,
                entityName,
                fieldName
              }
            ]
          })
          // group.fields.sort((a, b) => a.order - b.order)
          if (group.fields.length > 0) groups.push(group)
        })
        entityFieldsValues[entity.entityName] = groups.sort((a, b) => a.order - b.order)
      })
      setEntityValues(entityFieldsValues[selectedEntity])
    }
  }, [data, term, selectedEntity])

  const handleSelectToken = (id, value, entityName, fieldName, isIncompatible) => {
    const editor = quillRef.getEditor()
    const range = editor.getSelection(true)
    if (range) {
      editor.insertEmbed(range.index, 'token', { id, value, entityName, fieldName, isIncompatible })
      const newRange = editor.getSelection(true)
      editor.insertText(newRange.index + 1, ' ')
      editor.setSelection(newRange.index + 2)
      if (onAddToken) onAddToken({ id, value, entityName, fieldName, isIncompatible })
    }
  }

  const checkIncompatibilities = (fieldEntity) => {
    const emailEntity = isInInfluencers ? 'influencer' : 'programV2'
    if (data) {
      data.getEntityFieldsByEntity.forEach((entity) => {
        const { incompatibleEntities, entityName } = entity
        if (entityName === emailEntity) {
          return incompatibleEntities.includes(fieldEntity)
        }
      })
    }
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      ref={wrapperRef}
      selectedQuill={selectedQuill}
      isSideModal={isSideModal}
      data-cy='personalizationTokenModal'
      isInsideCenteredModal={isInsideCenteredModal}
    >
      <Flex align='center' justify='space-between'>
        <Text bold color={'primary.dark'} size='14'>
          {t('modal.insertPersonalizationToken.header')}
        </Text>
        <Spacing vertical size={24} />
        <Button
          color='secondary'
          variant='flat'
          size='m'
          onClick={onClose}
          withIcon
          paddingSideways='0px'
          data-cy='Button-closePersonalizationTokenModal'
        >
          <Icon name='close' size='16' color='primary.dark' />
        </Button>
      </Flex>
      <Spacing size={16} />
      <Select
        placeholder={t('entity.property.tool.search.input.placeholder')}
        name='token'
        options={options}
        defaultValue={defaultValue}
        value={options.find((option) => option.value === selectedEntity)}
        onChange={(e) => setSelectedEntity(e.value)}
      />
      <Spacing size={8} />
      <InputSearch
        name='searchPersonalizationToken'
        autoFocus
        onChange={setTerm}
        size='l'
        styles={searchStyles}
        placeholder={t('entity.property.tool.search.input.placeholder')}
        position='left'
        noMargin
      />
      <Spacing size={16} />
      <ScrollableContainer height='200px' onHitBottom={() => {}}>
        {entityValues?.length === 0 &&
          <>
            <Spacing size={24} />
            <Text center color={'primary.light1'} size='14'>
              {t('modal.insertPersonalizationToken.noResults.titleHeader1')}
            </Text>
            <Spacing size={24} />
          </>
        }
        {entityValues?.map((group, index) => {
          const { name, fields } = group
          return (
            <div key={index}>
              {['influencer', 'programV2'].includes(selectedEntity) &&
                <>
                  <Text bold color={'primary.dark'} size='14'>
                    {name}
                  </Text>
                  <Spacing size={8} />
                </>
              }
              {fields?.map((field, index) => {
                const { id, value, label, entityName, fieldName } = field
                return (
                  <div key={index}>
                    <Text
                      color={'primary.light2'}
                      styles={textStyles}
                      size='14'
                      onClick={() => {
                        const isIncompatible = checkIncompatibilities(entityName)
                        handleSelectToken(id, value, entityName, fieldName, isIncompatible)
                      }}
                    >
                      {label}
                    </Text>
                    <Spacing size={8} />
                  </div>
                )
              })}
            </div>
          )
        })}
      </ScrollableContainer>
    </ModalContainer>
  )
}
