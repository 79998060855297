import { Button, Flex, SideBox, Spacing, Text } from 'influ-dms'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { RouterHelper } from '../../../../../routing/routerHelper'
import { ModalsContext } from '../../../../commons/context'
import { useTranslate } from '~/hooks/useTranslate'

const sideBoxStyles = css`
  padding: 0;
  text-align: center
`
const PaddedContainer = styled.div`
  padding: 16px 16px 20px 16px;
`
const StyledButton = styled(Button)`
  margin-top: 14px;
`
const StyledFlex = styled(Flex)`
  margin-bottom: 32px;
`
export const AddPostsAutomaticLimitReached = () => {
  const { t } = useTranslate()
  const { dispatch } = useContext(ModalsContext)
  const router = useRouter()
  const { brandId } = router.query

  const handleClick = () => {
    dispatch({ type: 'toggleAddPosts' })
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }

  return (
    <SideBox styles={sideBoxStyles}>
      <PaddedContainer>
        <Spacing size='32' />
        <Text size='15' color='primary.dark' bold>{t('reports.report.modal.addPublication.automaticAnalyses.limitReached.titleHeader1')}</Text>
        <Spacing size='4' />
        <Text size='14' color='primary.light3'>{t('reports.report.modal.addPublication.automaticAnalyses.limitReached.titleParagraph1')}</Text>
        <Spacing size='16' />
        <img
          width='250px' height='250px'
          src='/static/img/error.png'
          alt='Publications Limit Exceeded'
        />
        <Spacing size='4' />
        <StyledFlex center>
          <StyledButton onClick={handleClick} size='l'>{t('commons.button.seePlansAndPrices')}</StyledButton>
        </StyledFlex>
        <Spacing size='4' />
      </PaddedContainer>
    </SideBox>
  )
}
