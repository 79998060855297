import { SendMassiveEmailBulkEmailFormContent } from './SendMassiveEmailBulkEmailFormContent'
import { SendMassiveEmailBulkEmailFormFooter } from './SendMassiveEmailBulkEmailFormFooter'
import { SendMassiveEmailReviewEmailModal } from './SendMassiveEmailReviewEmailModal'
import { StyledForm } from './styles'
import { useArray } from 'influ-dms'

export const SendMassiveEmailBulkEmailForm = ({
  influencers,
  influencersWithoutEmail,
  selectedTemplate,
  loadingSelectedTemplate,
  massiveCommData,
  onClose,
  step,
  setStep,
  setErrorInfo,
  useEmails
}) => {
  const [files, setFiles] = useArray([])

  return (
    <StyledForm>
      {step === 3 &&
        <>
          <SendMassiveEmailBulkEmailFormContent
            selectedTemplate={selectedTemplate}
            attachments={selectedTemplate?.attachments?.filter(file => file.inline) || []}
            loadingSelectedTemplate={loadingSelectedTemplate}
            addedFiles={files}
            setAddedFiles={setFiles}
          />
          <SendMassiveEmailBulkEmailFormFooter
            attachments={selectedTemplate?.attachments?.filter(file => !file.inline) || []}
            influencers={influencers}
            onClose={onClose}
            setStep={setStep}
            useEmails={useEmails}
            files={files}
            setFiles={setFiles}
          />
        </>
      }
      {step === 4 &&
        <SendMassiveEmailReviewEmailModal
          influencers={influencers}
          influencersWithoutEmail={influencersWithoutEmail}
          massiveCommData={massiveCommData}
          onClose={onClose}
          setErrorInfo={setErrorInfo}
          setStep={setStep}
        />
      }
    </StyledForm>
  )
}
