import { gql } from '@apollo/client'

export const applyFilters = gql`
query applyFilters(
  $brandId: String
  $page: Int,
  $limit: Int,
  $filters: [NewFilterParamsType]
 ) {
  applyFilters(
    brandId: $brandId
    page: $page,
    limit: $limit,
    filters: $filters,
   )
 }
 `
