import { b64toBlob } from '../../../irm/influencers/commons/utils'

export const replaceTokens = (html) => {
  return html?.replace(/>%/g, '>{{').replace(/%</g, '}}<').replaceAll('<span class="quill-token"', '<em class="quill-token"').replaceAll('</span>﻿</span>', '</span></em>')
}

export const parseEmail = (email, removeUnsubscribeText) => {
  const { subject, html, attachments } = email

  let parsedHtml = replaceTokens(html)
  if (removeUnsubscribeText) {
    parsedHtml = parsedHtml.replace(/<br><br>(.*?)<\/p>/g, '')
  }

  const parsedAttachments = attachments.map((attachment) => {
    const blob = b64toBlob(attachment.content, attachment.mimetype)
    const fileContent = URL.createObjectURL(blob)
    const newFile = new File([blob], attachment.filename, { type: attachment.mimetype })
    newFile.attachmentId = attachment.attachmentId
    if (attachment.inline) {
      newFile.contentId = attachment.contentId
      newFile.inline = attachment.inline
      parsedHtml = parsedHtml.replace(/src="blob:(.*?)">/g, (image) => {
        const alt = image.match(/alt="(.*?)"/)?.length > 0 && image.match(/alt="(.*?)"/)[1]
        if (alt === attachment.contentId) {
          const replacedImage = image.replace(/src="blob:(.*?)"/, `src="${fileContent}" alt="${attachment.contentId}"`)
            .replace(`alt="${attachment.contentId}"`, '')
          return replacedImage
        } else {
          return image
        }
      })
      parsedHtml = parsedHtml.replace(/src="cid:(.*?)">/g, (image) => {
        const alt = image.match(/alt="(.*?)"/)?.length > 0 && image.match(/alt="(.*?)"/)[1]
        if (alt === attachment.contentId) {
          const replacedImage = image.replace(`src="cid:${attachment.contentId}"`, `src="${fileContent}" alt="${attachment.contentId}"`)
            .replace(`alt="${attachment.contentId}"`, '')
          return replacedImage
        } else {
          return image
        }
      })
    }
    return newFile
  })

  const template = {
    ...email,
    rawsubject: replaceTokens(subject),
    rawhtml: parsedHtml,
    attachments: parsedAttachments
  }

  if (email.preview) {
    template.rawpreview = replaceTokens(email.preview)
  }

  return template
}
