import { gql } from '@apollo/client'

export const updateInfluencerCostsMutation = gql`
  mutation updateInfluencerCosts (
    $influencerId: String!
    $influencerCost: Float
    $publicationCosts: [ReportPublicationCostInputType]
  ) {
  updateInfluencerCosts (
    influencerId: $influencerId
    influencerCost: $influencerCost
    publicationCosts: $publicationCosts
  ) {
      reportV2Id
    }
 }
`
