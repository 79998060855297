import { css } from 'styled-components'
import { Text } from 'influ-dms'

const SectionHeaderStyles = css`
   margin: 11px 8px;
 `

export const SectionHeader = ({ title }) => {
  return (
    <Text styles={[SectionHeaderStyles]} size='14' color='primary.dark' bold>{title}</Text>
  )
}
