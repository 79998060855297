import { useContext } from 'react'
import styled from 'styled-components'
import { FormContext, Spacing, Text } from 'influ-dms'
import { AddPostsAutomaticTagInput } from './AddPostsAutomaticTagInput'
import { errorStyles } from '~/containers/reports/influencers/styles'
import { RefreshRemainingFeatureFlagsContext } from '~/context/RefreshRemainingFeatureFlags'
import { AddPostsAutomaticLimitReached } from './AddPostsAutomaticLimitReached'
import { useTranslate } from '~/hooks/useTranslate'

const Container = styled.div`
  padding: 32px 64px 32px 32px;
  height: inherit;
`

export const AddPostsAutomatic = ({ error, setError }) => {
  const { t } = useTranslate()
  const { setValue } = useContext(FormContext)
  const { remainingUrlsPerPeriodReportsV2 } = useContext(RefreshRemainingFeatureFlagsContext)
  const canAdd = remainingUrlsPerPeriodReportsV2 === null || remainingUrlsPerPeriodReportsV2 > 0
  const renderErrorLine = (err, i) => <Text key={i} styles={errorStyles} color='state.dangerDefault'>{err.text || err}</Text>

  const renderError = () => {
    if (!error || error.showInFooter) return <></>
    if (Array.isArray(error)) {
      return error.map((err, i) => renderErrorLine(err, i))
    } else {
      return renderErrorLine(error, 1)
    }
  }
  return (
    <Container>
      <Text color='primary.dark' size='16' bold>{t('reports.report.modal.addPublication.automaticAnalyses.titleHeader1')}</Text>
      <Spacing size='8' />
      <Text color='primary.light3'>{t('reports.report.modal.addPublication.automaticAnalyses.titleParagraph1')}</Text>
      <Spacing size='32' />
      {canAdd
        ? (
          <>
            <Text color='primary.dark' size='13' bold>{t('reports.report.modal.addPublication.automaticAnalyses.urls.input.label')}</Text>
            <Spacing size='4' />
            <AddPostsAutomaticTagInput setValue={setValue} error={error} setError={setError} />
            {renderError()}
          </>
          )
        : <AddPostsAutomaticLimitReached />}
    </Container>
  )
}
