import { createContext, useEffect } from 'react'
import { get, isNumber } from 'lodash'
import { refreshRemainingFeatureFlags } from '~/graphql/brands'
import { useSubscription } from '@apollo/client'
import { useRouter } from 'next/router'
import { useBrandData } from '~/hooks'

const isNotEmpty = (value) => {
  return value !== undefined && value !== null
}

export const RefreshRemainingFeatureFlagsContext = createContext({ value: {} })

const RefreshRemainingFeatureFlagsProvider = (props) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname')
  const isPublic = pathname && pathname.includes('public')
  const skip = isPublic || !brandId

  const { data: brandData } = useBrandData({ variables: { brandId }, skip }) // useQuery(brand, { variables: { brandId }, skip })
  const { data } = useSubscription(refreshRemainingFeatureFlags, { variables: { brandId }, skip })

  // remaining searches
  const remainingSearchResultsBrand = get(brandData, 'brand.featureFlags.search.remainingResultsPerPeriod')
  let remainingSearches = get(data, 'refreshRemainingFeatureFlags.remainingResultsPerPeriod')
  remainingSearches = isNotEmpty(remainingSearches) ? remainingSearches : remainingSearchResultsBrand

  useEffect(() => {
    if (isNumber(remainingSearchResultsBrand)) remainingSearches = remainingSearchResultsBrand
  }, [remainingSearchResultsBrand])

  // remaining analyses network
  const remainingAnalysisBrand = get(brandData, 'brand.featureFlags.network.remainingAnalysisPerPeriod')
  const remainingAnalysisValue = get(data, 'refreshRemainingFeatureFlags.remainingAnalysisPerPeriod')
  let remainingAnalysis = isNotEmpty(remainingAnalysisValue) ? remainingAnalysisValue : remainingAnalysisBrand

  useEffect(() => {
    if (isNumber(remainingAnalysisBrand)) remainingAnalysis = remainingAnalysisBrand
  }, [remainingAnalysisBrand])

  // remaining irm profile analyses
  const remainingProfileAnalysesBrand = get(brandData, 'brand.featureFlags.irm.remainingAnalysisPerPeriod')
  const remainingProfileAnalysesValue = get(data, 'refreshRemainingFeatureFlags.remainingProfileAnalysisPerPeriod')
  let remainingProfileAnalyses = isNotEmpty(remainingProfileAnalysesValue) ? remainingProfileAnalysesValue : remainingProfileAnalysesBrand

  // remaining irm influencers
  const remainingInfluencersBrand = get(brandData, 'brand.featureFlags.irm.remainingInfluencers')
  const remainingInfluencersValue = get(data, 'refreshRemainingFeatureFlags.remainingInfluencers')

  const remainingInfluencers = isNumber(remainingInfluencersValue) ? remainingInfluencersValue : remainingInfluencersBrand

  // remaining irm lists
  const remainingListsBrand = get(brandData, 'brand.featureFlags.irm.remainingLists')
  const remainingListsValue = get(data, 'refreshRemainingFeatureFlags.remainingLists')

  const remainingLists = isNumber(remainingListsValue) ? remainingListsValue : remainingListsBrand

  useEffect(() => {
    if (isNumber(remainingProfileAnalysesBrand)) remainingProfileAnalyses = remainingProfileAnalysesBrand
  }, [remainingProfileAnalysesBrand])

  // overlapToBeRefreshedListId
  const overlapToBeRefreshedListId = get(data, 'refreshRemainingFeatureFlags.overlapToBeRefreshedListId')

  // remaining campaigns
  const remainingCampaignsBrand = get(brandData, 'brand.featureFlags.campaignsV1.remainingCampaigns')
  let remainingCampaigns = get(data, 'refreshRemainingFeatureFlags.remainingReports')
  remainingCampaigns = isNotEmpty(remainingCampaigns) ? remainingCampaigns : remainingCampaignsBrand

  const remainingProgramsBrand = get(brandData, 'brand.featureFlags.campaignsV1.remainingPrograms')
  let remainingPrograms = get(data, 'refreshRemainingFeatureFlags.remainingPrograms')
  remainingPrograms = isNotEmpty(remainingPrograms) ? remainingPrograms : remainingProgramsBrand

  useEffect(() => {
    if (isNumber(remainingCampaignsBrand)) remainingCampaigns = remainingCampaignsBrand
  }, [remainingCampaignsBrand])

  // remaining urls reportsV2
  const remainingUrlsPerPeriodBrandReportsV2 = get(brandData, 'brand.featureFlags.reportsV2.remainingUrlsPerPeriod')
  let remainingUrlsPerPeriodReportsV2 = get(data, 'refreshRemainingFeatureFlags.remainingUrlsPerPeriodReportsV2')
  remainingUrlsPerPeriodReportsV2 = isNotEmpty(remainingUrlsPerPeriodReportsV2) ? remainingUrlsPerPeriodReportsV2 : remainingUrlsPerPeriodBrandReportsV2

  useEffect(() => {
    if (isNumber(remainingUrlsPerPeriodBrandReportsV2)) remainingUrlsPerPeriodReportsV2 = remainingUrlsPerPeriodBrandReportsV2
  }, [remainingUrlsPerPeriodBrandReportsV2])

  // remaining reports reportsV2
  const remainingReportsBrand = get(brandData, 'brand.featureFlags.reportsV2.remainingReports')
  let remainingReports = get(data, 'refreshRemainingFeatureFlags.remainingReports')
  remainingReports = isNotEmpty(remainingReports) ? remainingReports : remainingReportsBrand

  useEffect(() => {
    if (isNumber(remainingReportsBrand)) remainingReports = remainingReportsBrand
  }, [remainingReportsBrand])

  // remaining tracking reportsV2
  const remainingReportsV2TrackingsBrand = get(brandData, 'brand.featureFlags.reportsV2.remainingReportsV2Trackings')
  let remainingReportsV2Trackings = get(data, 'refreshRemainingFeatureFlags.remainingReportsV2Trackings')
  remainingReportsV2Trackings = isNotEmpty(remainingReportsV2Trackings) ? remainingReportsV2Trackings : remainingReportsV2TrackingsBrand

  useEffect(() => {
    if (isNumber(remainingReportsV2TrackingsBrand) || remainingReportsV2TrackingsBrand === null) remainingReportsV2Trackings = remainingReportsV2TrackingsBrand
  }, [remainingReportsV2TrackingsBrand])

  // remaining social listening mentions
  const remainingMentionsBrand = get(brandData, 'brand.featureFlags.socialListening.remainingMentionsPerPeriod')
  let remainingMentions = get(data, 'refreshRemainingFeatureFlags.remainingMentionsPerPeriod')
  remainingMentions = isNotEmpty(remainingMentions) ? remainingMentions : remainingMentionsBrand

  useEffect(() => {
    if (isNumber(remainingMentionsBrand)) remainingMentions = remainingMentionsBrand
  }, [remainingMentionsBrand])

  // remaining social listening alerts
  const remainingAlertsBrand = get(brandData, 'brand.featureFlags.socialListening.remainingAlerts')
  const remainingAlertsValue = get(data, 'refreshRemainingFeatureFlags.remainingAlerts')

  const remainingAlerts = isNumber(remainingAlertsValue) ? remainingAlertsValue : remainingAlertsBrand

  // remaining guest users
  const remainingGuestUsersBrand = get(brandData, 'brand.featureFlags.config.remainingGuestUsers')
  let remainingGuestUsers = get(data, 'refreshRemainingFeatureFlags.remainingGuestUsers')
  remainingGuestUsers = isNotEmpty(remainingGuestUsers) ? remainingGuestUsers : remainingGuestUsersBrand

  useEffect(() => {
    if (isNumber(remainingGuestUsersBrand)) remainingGuestUsers = remainingGuestUsersBrand
  }, [remainingGuestUsersBrand])

  // remaining landing form pages
  const remainingLandingPagesBrand = get(brandData, 'brand.featureFlags.recruitment.remainingForms')
  const remainingLandingPagesValue = get(data, 'refreshRemainingFeatureFlags.remainingForms')

  const remainingLandingPages = isNumber(remainingLandingPagesValue) ? remainingLandingPagesValue : remainingLandingPagesBrand

  // remaining massive comm messages
  const remainingMassiveCommMessagesBrand = get(brandData, 'brand.featureFlags.irm.remainingMassiveCommPerPeriod')
  const remainingMassiveCommMessagesValue = get(data, 'refreshRemainingFeatureFlags.remainingMassiveCommPerPeriod')

  const remainingMassiveCommMessages = isNumber(remainingMassiveCommMessagesValue) ? remainingMassiveCommMessagesValue : remainingMassiveCommMessagesBrand

  // remaining custom fields
  const remainingCustomFieldsBrand = get(brandData, 'brand.featureFlags.irm.remainingCustomFields')
  const remainingCustomFieldsValue = get(data, 'refreshRemainingFeatureFlags.remainingCustomFields')

  const remainingCustomFields = isNumber(remainingCustomFieldsValue) ? remainingCustomFieldsValue : remainingCustomFieldsBrand

  // remaining templates
  const remainingTemplatesBrand = get(brandData, 'brand.featureFlags.irm.remainingTemplates')
  const remainingTemplatesValue = get(data, 'refreshRemainingFeatureFlags.remainingTemplates')

  const remainingTemplates = isNumber(remainingTemplatesValue) ? remainingTemplatesValue : remainingTemplatesBrand

  // remaining outreaches
  const remainingOutreachesBrand = get(brandData, 'brand.featureFlags.irm.remainingOutreaches')
  const remainingOutreachesValue = get(data, 'refreshRemainingFeatureFlags.remainingOutreaches')

  const remainingOutreaches = isNumber(remainingOutreachesValue) ? remainingOutreachesValue : remainingOutreachesBrand

  return (
    <RefreshRemainingFeatureFlagsContext.Provider
      value={{
        remainingSearches,
        remainingAnalysis,
        remainingProfileAnalyses,
        remainingInfluencers,
        remainingLists,
        remainingCampaigns,
        remainingPrograms,
        remainingUrlsPerPeriodReportsV2,
        remainingReports,
        overlapToBeRefreshedListId,
        remainingReportsV2Trackings,
        remainingMentions,
        remainingAlerts,
        remainingGuestUsers,
        remainingLandingPages,
        remainingMassiveCommMessages,
        remainingCustomFields,
        remainingTemplates,
        remainingOutreaches
      }}
    >
      {props.children}
    </RefreshRemainingFeatureFlagsContext.Provider>
  )
}

export { RefreshRemainingFeatureFlagsProvider }
