
import { gql } from '@apollo/client'

export const influencerTasks = gql`
 query influencerTasks( $influencerId: String! ) {
  influencerInCampaign(_id: $influencerId ){
    id: _id
    name
    influencerInNetworkId
    campaignId
    taskCount
    totalCost
    tasks{
      network
      username
      channelName
      tasks{
        taskId: _id
        currency{
          symbol
        }
        price
        date
        status
        _type
      }
    }
  }
 }
 `
