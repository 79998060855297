import { useContext, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { Form } from 'influ-dms'
import { brand } from '../../../../graphql/brands'
import { CreateReportModal } from './index'
import { LimitsContext } from '../../../../context'
import { RouterHelper } from '../../../../routing/routerHelper'
import { addReportMutation, getAddReportErrorMessage } from '../../apollo/createReportQuery'
import { ReportsContext } from '../../context/ReportsContext'
import { useTranslate } from '~/hooks/useTranslate'
import { REPORTS_DASHBOARD_TABS } from '../ReportsConstants'

export const CreateReport = ({ isOpen, onClose }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const isPublic = router.pathname && router.pathname.includes('public')

  const { setReportsAction } = useContext(ReportsContext)
  const [addReport, { loading }] = useMutation(addReportMutation)
  const [error, setError] = useState()

  const skip = isPublic || !brandId
  const { data } = useQuery(brand, { variables: { brandId }, skip })
  const { setLimit, setLimitOpen } = useContext(LimitsContext)
  const remainingReports = get(data, 'brand.featureFlags.reportsV2.reportsLimit')
  const canAdd = remainingReports === null || remainingReports > 0

  const handleOnReportClose = () => {
    clearErrors()
    onClose()
  }

  const clearErrors = () => {
    setError(undefined)
  }

  const handleCreateReport = (values, cleanForm, keepAdding) => {
    if (!canAdd) {
      setLimitOpen(true)
      setLimit(t('reports.modal.createReport.mainInfoBlock.input.helperError.reportsLimit'))
      return
    }
    addReport({ variables: { ...values, brandId } })
      .then(data => {
        RouterHelper.traceEvent('reportCreated')
        setReportsAction({ action: 'createReport', data })
        if (!keepAdding) {
          handleOnReportClose()
          router.push(RouterHelper.getRoute('reportsV2Dashboard', {
            brandId,
            reportId: data.data.addReportV2.id
          }, '', { tabName: REPORTS_DASHBOARD_TABS.overview }))
        } else {
          cleanForm()
        }
      })
      .catch((err) => {
        setError(getAddReportErrorMessage(err.message, t))
      })
  }

  return (
    <Form>
      <CreateReportModal
        isOpen={isOpen}
        handleCreate={handleCreateReport}
        handleClose={handleOnReportClose}
        loading={loading}
        error={error}
        clearErrors={clearErrors}
      />
    </Form>
  )
}
