import styled from 'styled-components'
import { Button, Flex, Icon, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledButton = styled(Button)`
  padding: 0 4px;
  :last-child {
    padding-right: 0;
  }
`

export const NewEmailModalHeader = ({ onMinimize, onMaximize, onClose }) => {
  const { t } = useTranslate()

  return (
    <>
      <Text size='20' variant='h2' color='neutral.white' bold>{t('influencers.modal.composeMessage.header')}</Text>
      <Flex>
        <StyledButton withIcon variant='flat' onClick={onMinimize}>
          <Icon size={32} color='white' name='minimize' />
        </StyledButton>
        <StyledButton withIcon variant='flat' onClick={onMaximize}>
          <Icon size={32} color='white' name='expand' />
        </StyledButton>
        <StyledButton withIcon variant='flat' onClick={onClose}>
          <Icon size={32} color='white' name='close' />
        </StyledButton>
      </Flex>
    </>
  )
}
