import { useContext, useEffect, useMemo, useState } from 'react'
import { FormContext, getFormattedDate, InfluDateFormat } from 'influ-dms'
import styled from 'styled-components'
import { get } from 'lodash'
import { Quill } from '../forms/details/wysiwyg/quill/Quill'
import { _replaceInlineAttachments } from '../../helpers'
import { useTranslate } from '~/hooks/useTranslate'

const StyledDiv = styled.div`
  flex: 1;
  overflow: auto;
  .quill {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .ql-editor {
    flex: 1;
    transition: all 0.4s ease-in-out;
  }
`

const quillLine = '<p><br></p>'

export const NewEmailModalMessageBody = ({ isOpen, email }) => {
  const { t } = useTranslate()
  const { setValue } = useContext(FormContext)
  const [quillValue, setQuillValue] = useState('')
  const handleChange = val => {
    setQuillValue(val)
    setValue('InputText', 'html', val)
  }

  useEffect(() => {
    if (!isOpen) {
      setQuillValue('')
    } else if (email) {
      const from = get(email, 'from.address')
      const html = get(email, 'html')
      const type = get(email, 'type')
      const date = getFormattedDate(email.date, InfluDateFormat(t).LLL)
      if (type === 'reply' && html) {
        setQuillValue(`${quillLine}${quillLine}On ${date}, ${from} wrote: <blockquote>${html}</blockquote>`)
      } else if (type === 'forward' && html) {
        setQuillValue(`
          ${quillLine}${quillLine}---------- Forwarded message ---------<br>
          From: ${from}<br>
          Date: ${date}<br>
          Subject: ${email.subject}<br>
          To: ${email.forwardTo}<br>${quillLine}
          ${html}
        `)
      } else if (email.signature) {
        setQuillValue(`${quillLine}${quillLine}${quillLine}${_replaceInlineAttachments(email.signature, email.signatureAttachments)}`)
      }
      setValue('InputText', 'subject', email.subject)
    }
  }, [isOpen, email])

  return useMemo(() =>
    <StyledDiv id='newEmailBody'>
      <Quill containerId='#newEmailBody' value={quillValue} handleChange={handleChange} />
    </StyledDiv>,
  [isOpen, quillValue])
}
