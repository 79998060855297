import {
  Flex,
  Modal,
  Spacing,
  Portal,
  Text,
  Button
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const DeleteProfilesConfirmationTrackingModal = ({ isOpen, onClose, handleContinue }) => {
  const { t } = useTranslate()
  return (
    <Portal selector='#deleteProfilesConfirmationTrackingModal'>
      <Modal
        name='deleteProfilesConfirmationTrackingModal'
        isOpen={isOpen}
        title={t('reports.modal.removeConfirmation.header')}
        height='auto'
        onClose={onClose}
        minHeight='240'
        color='state.warningDefault'
      >
        <Flex column grow='1'>
          <Text color='primary.dark' bold size='15'>{t('reports.modal.removeConfirmation.titleHeader1')}</Text>
          <Spacing size='8' />
          <Text color='primary.light3' size='14'>{t('reports.modal.removeConfirmation.titleParagraph1')}</Text>
        </Flex>
        <Spacing size='32' />
        <Flex>
          <Button
            name='delete-confirmation-tracking-button'
            onClick={handleContinue}
            color='warning'
          >
            {t('commons.button.removeInfluencers')}
          </Button>
          <Spacing vertical size='8' />
          <Button
            onClick={onClose}
            variant='flat'
            color='secondary'
          >{t('commons.button.cancel')}
          </Button>
        </Flex>
      </Modal>
    </Portal>
  )
}

export { DeleteProfilesConfirmationTrackingModal }
