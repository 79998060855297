import { gql } from '@apollo/client'

export const addReportMutation = gql`
 mutation addReportV2($brandId: String!, $name: String!, $description: String) {
  addReportV2(brandId: $brandId, name: $name, description: $description){
      id: _id
      name
    }
 }
`

export const getAddReportErrorMessage = (message, t) => {
  const is409 = message.includes('409')
  const isLimit = message.includes('484')
  if (is409) return 'Report already exists'
  if (isLimit) {
    return (
      <>
        {t('reports.modal.limitReports.header', {
          contactUs: (
            <a data-cy='createReportLimitReached-link' href={'mailto:info@influencity.com?Subject=I\'m%20interested%20in%20increase%20my%20reports%20limit'}>
              {t('commons.button.contactUs')}
            </a>
          )
        })}

      </>
    )
  }
  return t('commons.errorMessage.tryAgainLater')
}
