import { useState } from 'react'
import { useRouter } from 'next/router'
import { useRemoveAndDeleteActions } from '../../../commons/hooks'
import { ModalsForOptions } from './ModalsForOptions'
import { DropdownContainer } from './DropdownContainer'

export const MoreOptionsForInsights = ({ isDisabled, profileId }) => {
  const [moreOptionsOpened, setMoreOptionesOpened] = useState(false)
  const [isUnlinkModalOpen, setUnlinkModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const router = useRouter()
  const { influencerId } = router.query

  const { handleConfirmDelete, handleConfirmUnlink, unlinkLoading, deleteLoading } =
    useRemoveAndDeleteActions({
      profileId,
      influencerId,
      setUnlinkModalOpen,
      setDeleteModalOpen
    })

  return (
    <>
      <DropdownContainer
        isDisabled={isDisabled}
        moreOptionsOpened={moreOptionsOpened}
        setMoreOptionesOpened={setMoreOptionesOpened}
        onClickOption1={() => {
          setMoreOptionesOpened(false)
          setUnlinkModalOpen(true)
        }}
        onClickOption2={() => {
          setMoreOptionesOpened(false)
          setDeleteModalOpen(true)
        }}
      />
      <ModalsForOptions
        isOpenUnlink={isUnlinkModalOpen}
        isOpenDelete={isDeleteModalOpen}
        handleConfirmDelete={handleConfirmDelete}
        handleConfirmUnlink={handleConfirmUnlink}
        loadingConfirmDelete={deleteLoading}
        loadingConfirmUnlink={unlinkLoading}
        onCloseDelete={() => setDeleteModalOpen(false)}
        onCloseUnlink={() => setUnlinkModalOpen(false)}
      />
    </>
  )
}
