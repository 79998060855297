import { ApolloLink } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { get } from 'lodash'
import cookiesParser from '../helpers/cookiesParser'
import { setTraceDurations, setTraceSection } from '../helpers/traceInfo/traceInfo'

let sessionCount = 0
let userActionTotalCount = typeof window !== 'undefined' ? localStorage.getItem('user-action-total-count') || 0 : 0
if (typeof window !== 'undefined' && !userActionTotalCount) localStorage.setItem('user-action-total-count', 0)

const getBrandId = () => {
  let pathname = ''
  if (typeof window !== 'undefined') pathname = get(window, 'location.pathname')
  const pathArray = pathname.split('/')
  if (get(pathArray, '[2].length') === 24 && !pathname.includes('/shared/')) {
    return pathArray[2]
  }
}

const setHeadersMiddleware = new ApolloLink((operation, forward) => {
  let token = ''
  const isBrowser = typeof window !== 'undefined'
  const notBrowser = !isBrowser
  const requestName = operation.operationName
  const isLoginRequest = requestName === 'login'
  const notLoginRequest = !isLoginRequest

  if (notBrowser && notLoginRequest) {
    const serverToken = process.env.token
    const tokenExists = serverToken !== 'undefined' || serverToken !== ''
    if (tokenExists) token = serverToken
  }

  if (isBrowser && notLoginRequest) {
    const cookies = cookiesParser(document)
    const browserToken = get(cookies, 'token', null)
    if (browserToken) token = browserToken
  }

  const headersAdded = {
    authorization: token && `Bearer ${token}`,
    'is-trial': localStorage.getItem('is-trial') === 'true'
  }

  const brandId = get(operation, 'variables.brandId', getBrandId(get(window, 'location.pathname')))
  if (brandId) headersAdded['brand-id'] = brandId

  try {
    if (typeof window !== 'undefined') {
      headersAdded.PAI = localStorage.getItem('idAnalytics')
    }
  } catch (err) {
    captureException(err)
  }

  // count actions
  if (get(operation, 'query.loc.source.body', '').includes('mutation ') || get(operation, 'operationName') === 'search' || get(operation, 'operationName') === 'addOrAssociateProfile') {
    sessionCount++
    headersAdded['user-action-session-count'] = sessionCount

    userActionTotalCount++
    localStorage.setItem('user-action-total-count', userActionTotalCount)
    headersAdded['user-action-total-count'] = userActionTotalCount
    headersAdded['is-enterprise'] = localStorage.getItem('is-enterprise') === 'true'
    setTraceSection(headersAdded)
    setTraceDurations(headersAdded)
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...headersAdded
    }
  }))

  return forward(operation)
})

export default setHeadersMiddleware
