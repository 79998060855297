import { useContext } from 'react'
import styled from 'styled-components'
import { Flex, Text, CellWrapper } from 'influ-dms'
import { cellPadding, imagePostStyles } from '../../commons/reportsTable/styles'
import { ModalsContext } from '../../../commons/context'

const ImageContainer = styled.div`
  width: 32px;
  height: 32px;
  ${imagePostStyles};
  border-radius: 50%;
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
  :hover{
    text-decoration: underline;
  }
`

export const ReportsTableTdProfile = ({ row: { original: { profilePicture, influencerInReportV2Id } }, value, styles }) => {
  const { dispatch } = useContext(ModalsContext)
  const openInfluencer = () => {
    dispatch({ type: 'openEditReportsInfluencer', payload: influencerInReportV2Id })
  }
  return (
    <CellWrapper>
      <StyledFlex align='center' styles={[cellPadding, styles]} onClick={openInfluencer}>
        <ImageContainer image={profilePicture} />
        <Text color='primary.dark' size='13' ellipsis>{value}</Text>
      </StyledFlex>
    </CellWrapper>
  )
}
