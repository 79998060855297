import { get } from 'lodash'
import { css } from 'styled-components'
import { Flex, Text } from 'influ-dms'
import { useRouter } from 'next/router'
import { RouterHelper } from '../../../../routing/routerHelper'

const SectionHeaderStyles = css`
  margin: 8px;
  cursor: pointer;
`

const ActiveStyles = css`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary.light5};
`

export const SectionTitle = ({ title, pageName }) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const isActive = router.route.includes(pageName)

  const handleClick = (pageName) => {
    router.push(RouterHelper.getRoute(pageName === 'account' ? 'accountDefault' : pageName, { brandId }))
  }

  return (
    <Flex styles={[isActive && ActiveStyles]}>
      <Text styles={[SectionHeaderStyles]} size='14' color='gray' onClick={() => handleClick(pageName)}>{title} </Text>
    </Flex>
  )
}
