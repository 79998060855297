export const ProfileStatusEnum = {
  NEW: 'new',
  UPDATE: 'update',
  AUTO_REFRESH: 'autoRefresh',
  OK: 'ok',
  NOT_ANALYZED: 'notAnalyzed',
  LESS_THAN_ONE_THOUSAND: 'lessThan1000',
  NO_AUDIENCE_DATA_AVAILABLE: 'noAudienceDataAvailable',
  PRIVATE: 'private',
  KO: 'ko',
  TIMEOUT: 'timeout',
  RETRY_LATER: 'retry_later',
  INCOMPLETE_DATA: 'incompleteData',
  NOT_ANALYZED_FOLLOWERS: 'followersNoAnalyzed',
  NOT_ANALYZED_LIKERS: 'likersNoAnalyze',
  NO_FOLLOWERS: 'noFollowers',
  NO_LIKERS: 'noLikers',
  NOT_EXISTS: 'notExists'
}

export const STARTABLE_STATUS_CODES = [
  ProfileStatusEnum.NEW,
  ProfileStatusEnum.UPDATE,
  ProfileStatusEnum.AUTO_REFRESH
]

export const PERMANENT_STATUS_CODES = [
  ProfileStatusEnum.NEW,
  ProfileStatusEnum.UPDATE,
  ProfileStatusEnum.AUTO_REFRESH,
  ProfileStatusEnum.NOT_EXISTS
]

export const ONLY_KPIS_STATUS_CODES = [
  ProfileStatusEnum.LESS_THAN_ONE_THOUSAND,
  ProfileStatusEnum.NO_AUDIENCE_DATA_AVAILABLE
]

export const STATUS_WITH_TEXT_ERROR = [
  ProfileStatusEnum.KO,
  ProfileStatusEnum.PRIVATE,
  ProfileStatusEnum.INCOMPLETE_DATA,
  ProfileStatusEnum.TIMEOUT,
  ProfileStatusEnum.RETRY_LATER
]

export const TEMP_STATUS_CODES = [
  ProfileStatusEnum.RETRY_LATER
]

export const TEMP_STATUS_CODES_SECONDS = 5

export const profilePriceFieldsByType = {
  instagram: ['photo', 'story', 'carousel', 'video', 'reel', 'igtv'],
  tiktok: ['video15Seconds', 'video60Seconds'],
  youtube: ['video', 'videoMention'],
  twitter: ['tweet', 'photo', 'retweet'],
  facebook: ['text', 'image', 'video', 'story'],
  snapchat: ['story', 'spotlight'],
  tumblr: ['post'],
  twitch: ['preRoll', 'midRoll', 'endRoll', 'mention', 'dedicatedVideo'],
  vk: ['text', 'image', 'video', 'story'],
  weibo: ['text', 'story', 'video', 'image'],
  pinterest: ['pin', 'image'],
  douyin: ['video15Seconds', 'video60Seconds']
}

export const MAX_PROFILES_PER_INFLUENCER = 10
export const MAX_PROFILES_PER_LIST = 1000
export const MAX_INFLUENCERS_FOR_PROGRAM_MASSIVE_ACTION = 20
export const ARROW_SIZE = 24
