import { useContext } from 'react'
import styled from 'styled-components'
import { Flex, FormContext, SideBox, Text } from 'influ-dms'
import { headerStyles } from '../../../../addPosts/manual/styles'
import { EditPostModalContentAutomaticPersonTagged } from './EditPostModalContentAutomaticPersonTagged'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  padding: 16px 0 8px 0;
`

export const EditPostModalContentAutomaticPeopleTagged = () => {
  const { t } = useTranslate()
  const { values: { taggedUsers = [] } } = useContext(FormContext)
  if (!taggedUsers.length) return null
  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.peopleTaggedBlock.title')}</Text>
      <StyledFlex wrap='wrap'>
        {taggedUsers.map(p => <EditPostModalContentAutomaticPersonTagged key={p} username={p} />)}
      </StyledFlex>
    </SideBox>
  )
}
