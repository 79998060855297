var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry-monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"26f11a6f89832717b9f30d5af6d5b695d23fd12b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { useEffect } from 'react'
import { init, replayIntegration, captureConsoleIntegration, httpClientIntegration, dedupeIntegration, reactRouterV6BrowserTracingIntegration } from '@sentry/nextjs'
import pjson from './package.json'

if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
  console.log('Sentry enabled. Environment: ', process.env.NEXT_PUBLIC_SENTRY_NODE_ENV_BROWSER)
  const config = {
    dsn: process.env.NEXT_PUBLIC_SENTRY_ADVERTISER_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_NODE_ENV_BROWSER,
    maxBreadcrumbs: 50,
    sampleRate: 1,
    ignoreErrors: [/^Warning:/, 'data-hsjs-portal'],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0.1,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/brand\.influencity\.com/, /^https:\/\/staging\.influencity\.com/, /^https:\/\/integration\.influencity\.com/,
      /^https:\/\/graphql-integration\.influencity\.com\/graphql/, /^https:\/\/graphql-staging\.influencity\.com\/graphql/, /^https:\/\/graphql\.influencity\.com\/graphql/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) || 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 0.1,

    attachStacktrace: true,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      replayIntegration({
      // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
      httpClientIntegration(),
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      reactRouterV6BrowserTracingIntegration({
        useEffect
      }),
      captureConsoleIntegration({ levels: ['error'] }),
      dedupeIntegration()
    ],
  }
  if (process.env.NODE_ENV === 'production') config.release = pjson.version
  init(config)
}
