import { gql } from '@apollo/client'

export const influencerQuery = gql`
 query influencerInReportsV2 (
   $profileId: String!
 ) {
  influencerInReportsV2(_id: $profileId) {
    influencerId: _id
    name
    username
    slug
    network
    profilePicture
    numberOfPosts
    cost
    postsTotalCost
    apiAccess
    publications {
      postId: _id
      image
      type
      publicationDate
      cost
    }
  }
}
`
