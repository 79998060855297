import { useContext } from 'react'
import styled from 'styled-components'
import { Button, Flex, Image, Text } from 'influ-dms'
import { useRouter } from 'next/router'
import { RouterHelper } from '../../../../routing/routerHelper'
import { ModalsContext } from '../../context'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin-bottom: 32px;
`

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 16px;
`

const StyledButton = styled(Button)`
  margin-top: 14px;
`

export const NoCommunicationContent = () => {
  const { t } = useTranslate()
  const router = useRouter()
  const { brandId } = router.query
  const { dispatch } = useContext(ModalsContext)
  const handleClick = () => {
    dispatch({ type: 'toggleEmailSetup' })
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }
  return (
    <StyledFlex center>
      <StyledText bold size='14' color='primary.dark'>{t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.withoutEmailPackage.titleHeader1')}</StyledText>
      <StyledText size='12' color='primary.light1'>
        {t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.withoutEmailPackage.titleParagraph1')}
      </StyledText>
      <Image src='/static/img/error.png' height={184} width={184} />
      <StyledButton onClick={handleClick} size='l'>{t('commons.button.viewPricingAndFeatures')}</StyledButton>
    </StyledFlex>
  )
}
