import { gql } from '@apollo/client'

export const calculateReportV2MetricsSubscription = gql`
subscription calculateReportV2Metrics($reportV2Id: String) {
  calculateReportV2Metrics(reportV2Id: $reportV2Id) {
    reportV2Id
    reportV2PublicationIds
    calculateMetrics
  }
}
`
