import { gql } from '@apollo/client'

export const getMasterTableByType = gql`
  query getMasterTableByType(
    $query: String!,
    $type: MasterTableOptions!,
  ) {
    getMasterTableByType(
      query: $query,
      type: $type,
    ) {
        key,
        label,
        value
    }
  }
`
