import { gql } from '@apollo/client'

export const exportInfluencerProfile = gql`
  mutation exportInfluencerProfile(
    $influencerId: String!,
    $brandId: String!
  ) {
    exportInfluencerProfile(brandId: $brandId, influencerId: $influencerId) {
      csvContent
      filename
    }
  }
`
export const exportEndpoint = gql`
  mutation export(
    $fileType: ExportRequestFileType!,
    $brandId: String!,
    $domain: String!,
    $data: String!,
    $exportName: String!,
    $filters: String,
    $sortParams: ProfileTableSortParamsType,
    $exportView: String,
    $timezone: String!,
    $locale: String
  ) {
    export (
      fileType: $fileType,
      brandId: $brandId,
      domain: $domain,
      data: $data,
      exportName: $exportName,
      filters: $filters,
      sortParams: $sortParams,
      exportView: $exportView,
      timezone: $timezone,
      locale: $locale
    ) {
      message
      code
      success
    }
  }
`

export const exportList = gql`
  mutation exportList(
    $listId: String!,
    $brandId: String!
  ) {
    exportList(brandId: $brandId, listId: $listId) {
      csvContent
      filename
    }
  }
`
export const exportAllInfluencers = gql`
  mutation exportAllInfluencers(
    $brandId: String!
  ) {
    exportAllInfluencers(brandId: $brandId) {
      csvContent
      filename
    }
  }
`
export const exportSearch = gql`
mutation exportSearch(
  $brandId: String!
  $filterParams: DeepSearchFilterParamsTypeInput,
  $limit: Int,
  $sortBy: SortByOptions,
  $sortOrder: OrderByOptions,
  $network: NetworkOptions,
  $ranges: RangeObjectType,
 ) {
  exportSearch(
      brandId: $brandId,
      filterParams: $filterParams,
      limit: $limit,
      sortBy: $sortBy,
      sortOrder: $sortOrder,
      network: $network,
      ranges: $ranges
   ){
    csvContent
    filename
   }
 }
 `
export const exportReportV2 = gql`
  mutation exportReportV2(
    $reportV2Id: String!,
    $brandId: String!
  ) {
    exportReportV2(brandId: $brandId, reportV2Id: $reportV2Id) {
      csvContent
      filename
    }
  }
`
