import { gql } from '@apollo/client'

export const addEntityView = gql`
mutation addEntityView(
  $entityViewName: String!
  $object: EntityViewObjectEnumType!
  $filters: [JSON]
) {
  addEntityView(
    entityViewName: $entityViewName
    object: $object
    filters: $filters
  ){
    message
    data
    success
  }
}
 `
