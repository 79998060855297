export const COMMUNICATION_ACTIONS = {
  newAccountConnecting: 'newAccountConnecting',
  newAccountVerified: 'newAccountVerified',
  newAccountConnected: 'newAccountConnected',
  accountCreationFailed: 'accountCreationFailed',
  accountUpdated: 'accountUpdated',
  accountEnabled: 'accountEnabled',
  accountDisabled: 'accountDisabled',
  accountDeleted: 'accountDeleted',
  newMessageSent: 'newMessageSent',
  newMessageReceived: 'newMessageReceived',
  messageDeleted: 'messageDeleted'
}

export const COMMUNICATION_STATUS = {
  newAccountConnecting: 'newAccountConnecting',
  newAccountVerified: 'newAccountVerified',
  newAccountMailboxConnectionFailed: 'newAccountMailboxConnectionFailed',
  newAccountMailboxConnected: 'newAccountMailboxConnected',
  newConnectionMessageSendingFailed: 'newConnectionMessageSendingFailed',
  newConnectionMessageSending: 'newConnectionMessageSending',
  newConnectionMessageSent: 'newConnectionMessageSent',
  newAccountConnected: 'newAccountConnected',
  newConnectionTimeout: 'newConnectionTimeout'
}

export const MESSAGE_STATUS = {
  newMessageSending: 'newMessageSending',
  newMessageSent: 'newMessageSent',
  newMessageReceived: 'newMessageReceived',
  messageDeleted: 'messageDeleted'
}

export const MESSAGE_LIMITS = {
  sizeLimit: 1048576, // 10Mb
  attachmentCount: 30
}
