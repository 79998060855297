import { gql } from "@apollo/client";

export const getPaymentCountries = gql`
  query getPaymentCountries {
    getPaymentCountries {
      key
      value
      label
    }
  }
`
