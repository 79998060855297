import { css } from 'styled-components'
import { ActionIcons, Actions } from '../../commons/components/ActionButtons'

export const negativeMargin = css`
  margin-left: -16px;
  width: auto;
`

export const roundedButtonStyles = css`
  & {
    margin: 0;
    height: 28px;
    width: ${({ name }) => name === ActionIcons[Actions.addMultiple] ? '40px' : '28px'};
    padding: 4px;
  }
  a {
    background: transparent;
    box-shadow: none;
  }
`
