import { gql } from '@apollo/client'

export const addInfluencersFromListToInvoice = gql`
  mutation addInfluencersFromListToInvoice(
    $listIds: [String]!
    $paymentInvoiceIds: [String]!
  ) {
    addInfluencersFromListToInvoice(
      listIds : $listIds
      paymentInvoiceIds: $paymentInvoiceIds
    ){
      message
      code
      success
    }
  }
`
