import { useContext } from 'react'
import { Button, Text, Flex, Modal, Spacing, Image } from 'influ-dms'
import { ThemeContext } from 'styled-components'
import { RouterHelper } from '../../../../routing/routerHelper'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'

const ReportsTrackingModalLimit = (props) => {
  const { t } = useTranslate()
  const { isOpen, onClose, subtitle } = props
  const { colors: { gradients } } = useContext(ThemeContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const handleGotoPrices = () => {
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t('reports.modal.limitTracking.header')}
        subtitle={subtitle}
        minHeight='100'
        color='state.warningDefault'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        <Flex column grow='1' center>
          <Text size='15' color='primary.dark' bold>{t('reports.modal.limitTracking.titleHeader1')}</Text>
          <Spacing size='8' />
          <Text size='14' color='primary.light3'>{t('reports.modal.limitTracking.titleParagraph1')}</Text>
          <Spacing size='16' />
          <Image src='/static/img/tracking/trackingLimitExceeded.png' alt='Tracking limit exceeded' width='200' />
          <Spacing size='24' />
        </Flex>
        <Flex center>
          <Button onClick={handleGotoPrices}>{t('commons.button.seePlansAndPrices')}</Button><Spacing size='16' />
        </Flex>
      </Modal>
    </>
  )
}

export { ReportsTrackingModalLimit }
