import { useContext } from 'react'
import { TableContext, TableEditableHeaderAction } from 'influ-dms'
import { ModalsContext } from '../../../../commons/context'
import { useQuery } from '@apollo/client'
import { brand } from '../../../../../graphql/brands'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { LimitsContext } from '../../../../../context'
import { useTranslate } from '~/hooks/useTranslate'

export const ReportsTableHeaderDelete = ({ ids, deleteAction: { event, featureFlag } }) => {
  const { t } = useTranslate()
  const { dispatch } = useContext(ModalsContext)
  const { validateLimit } = useContext(LimitsContext)
  const { dispatch: tableDispatch, state } = useContext(TableContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId })

  const handleDelete = () => {
    const canDelete = get(data, featureFlag)
    if (!validateLimit(canDelete)) return
    dispatch({ type: event, payload: ids })
    tableDispatch({ type: 'setSelectedRows', payload: state.selectedRows.filter(row => !ids.includes(row[state.columnName])) })
  }

  return (
    <TableEditableHeaderAction
      iconName='trash'
      text={t('commons.button.delete')}
      onClick={handleDelete}
    />
  )
}
