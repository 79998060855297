import { Icon, Flex, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import styled from 'styled-components'

const StyledCard = styled(Flex)`
  padding: 12px 16px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.light4};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.primary.light6};
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      pointerEvents: 'none',
    }
  }
`

const Oval = styled.div`
  padding: 4px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.blue.disabled};
`

export const SendMassiveEmailOptionsModal = ({ handleSelectOption, influencers }) => {
  const { t } = useTranslate()

  return (
    <>
      <Text bold color={'primary.dark'} size='14'>
        {`${t('modal.selectEmailType.title.header1')} *`}
      </Text>
      <Spacing size={16} />
      <Flex justify='space-between' gap='16px'>
        <StyledCard onClick={() => handleSelectOption('bulkEmail')} data-cy='StyledCard-bulkEmailOption'>
          <Oval>
            <Icon name='bulkemail' size='16' color='blue.disabled' />
          </Oval>
          <Spacing size={12} />
          <Text bold color={'primary.dark'} size='14'>
            {t('entity.email.field.emailType.value.bulkEmail.label')}
          </Text>
          <Spacing size={4} />
          <Text color={'primary.light2'} size='14'>
            {t('entity.email.field.emailType.value.bulkEmail.paragraph1')}
          </Text>
        </StyledCard>
        <StyledCard disabled={influencers?.length > 1} onClick={() => handleSelectOption('personalEmail')} data-cy='StyledCard-personalEmailOption'>
          <Oval>
            <Icon name='personalemail' size='16' color='blue.disabled' />
          </Oval>
          <Spacing size={12} />
          <Text bold color={'primary.dark'} size='14'>
            {t('entity.email.field.emailType.value.personalEmail.label')}
          </Text>
          <Spacing size={4} />
          <Text color={'primary.light2'} size='14'>
            {t('entity.email.field.emailType.value.personalEmail.paragraph1')}
          </Text>
        </StyledCard>
      </Flex>
    </>
  )
}
