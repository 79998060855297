import styled, { css } from 'styled-components'
import { CellWrapper, Flex, TableV2TdNetworks, Text } from 'influ-dms'
import { get, isEmpty } from 'lodash'
import { useTranslate } from '../../../../hooks'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const cellPadding = css`
  padding: 0 24px;
  ${cellEllipsis};
`

const StyledContainer = styled(Flex)`
  cursor: pointer;
  ${cellPadding};
`

const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out; 
    text-decoration: underline;
  }
`

const StyledFlex = styled(Flex)`
  width : 97%;
`

const tdNetworksStyles = css`
  gap: 4px;
`

export const NetworksTableTd = ({ row: { original }, onAssociateProfileClick }) => {
  const { t } = useTranslate()
  const row = original || {}
  let socialNetworks = get(row, 'socialNetworks') || get(row, 'networks')
  if (Array.isArray(socialNetworks)) socialNetworks = socialNetworks.map(n => n === 'others' ? 'othertasks' : n)

  if (!isEmpty(socialNetworks)) {
    return (
      <TableV2TdNetworks styles={tdNetworksStyles} value={socialNetworks} />
    )
  } else {
    return (
      <CellWrapper>
        <StyledFlex justify='space-between' align='center'>
          <StyledContainer align='center' >
            <Flex column>
              {onAssociateProfileClick
                ? <Text ellipsis showTooltip styles={clickableStyles} size='14' color='primary.light2' bold onClick={() => onAssociateProfileClick(row)}>{t('commons.button.associateProfiles')}</Text>
                : <Text ellipsis showTooltip size='14' color='primary.light2' bold >-</Text>}
            </Flex>
          </StyledContainer>
        </StyledFlex>
      </CellWrapper>
    )
  }
}
