import { gql } from '@apollo/client'

export const getIRMInfluencer = gql`
  query getIRMInfluencer ( 
    $brandId: String!
    $influencerId: String!
  ) {
    getIRMInfluencer(brandId: $brandId, influencerId: $influencerId) {
      _id
      alias
      firstName
      lastName
      picture
      profiles {
        _id
        _type
        username
        url
        picture
      }
      relatedProfiles {
        type
        value
        formatted_value
      }
      emails {
        value
        primary
        deleted
      }
      phoneNumbers {
        value
        primary
        deleted
      }
      programs {
        _id
      }
      programsV2 {
        _id
      }
      irmLists {
        _id
      }
      irmAddresses {
        _id
        addressName
        country
        createdAt
        primary
        formattedAddress
      }
      emailSubscription
    }
  }
`
