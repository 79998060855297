import { gql } from '@apollo/client'

export const addIrmInfluencer = gql`
mutation addIrmInfluencer(
  $brandId: String!
  $profileIds: [String]
  $programIds: [String]
  $listIds: [String]
  $outreachIds: [String]
  $invoiceIds: [String]
  $alias: String!
  $firstName: String
  $lastName: String
  $emails: [FieldWithPrimaryOptionInputType]
  $phoneNumbers: [FieldWithPrimaryOptionInputType]
  $emailSubscription: String
  $from: String
  $customFields: [JSON]
) {
  addIrmInfluencer(
    brandId: $brandId
    profileIds: $profileIds
    programIds: $programIds
    listIds: $listIds
    outreachIds: $outreachIds
    invoiceIds: $invoiceIds
    alias: $alias
    firstName: $firstName
    lastName: $lastName
    emails: $emails
    phoneNumbers: $phoneNumbers
    emailSubscription: $emailSubscription
    customFields: $customFields
    from: $from  
  ){
    message
    code
    success
    data
  }
}
 `
