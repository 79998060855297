import { gql } from '@apollo/client'

export const checkUrlMutation = gql`
 mutation checkUrl($url: String!, $reportV2Id: String!) {
  checkUrl(url: $url, reportV2Id: $reportV2Id) {
      profileUrl
      username
      exists
      private
      network
      postType
    }
 }
`

export const checkUrlOnEditMutation = gql`
 mutation checkUrlOnEdit($url: String!, $publicationInReportV2Id: String!) {
  checkUrlOnEdit(url: $url, publicationInReportV2Id: $publicationInReportV2Id) {
      profileUrl
      username
      exists
      private
      network
      postType
    }
 }
`

export const checkProfileMutation = gql`
 mutation checkProfile($account: String!, $network: String!) {
  checkProfile(account: $account, network: $network) {
      profileUrl
      username
      exists
      private
      error
    }
 }
`
