import styled from 'styled-components'
import { Text } from 'influ-dms'
import { Footer } from './Footer'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '../../../../../context/Language'
import { useEffect, useState } from 'react'
import { bindLinksMap } from '~/hooks/useLink'

const StyledText = styled(Text)`
  margin-bottom: 24px;
`

const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.light1};
  :hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`

export const ImapTerms = ({ handleAccept, handleCancel }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState('')
  const [imapLink, setImapLink] = useState('')
  const linksMap = new Map([
    ['privacy_policy_link', setPrivacyPolicyLink],
    ['imap_link', setImapLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])

  return (
    <>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.terms.paragraph1')}
      </StyledText>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.terms.paragraph2')}
      </StyledText>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.terms.paragraph3')} <StyledLink href={imapLink} target='_blank'>{t('commons.button.here')}</StyledLink>.
      </StyledText>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.terms.paragraph4')} <StyledLink href={privacyPolicyLink} target='_blank'>{t('commons.button.privatePolicy')}</StyledLink>.
      </StyledText>

      <Footer acceptText={t('commons.button.acceptAndConnectToOtherImap')} handleAccept={handleAccept} handleCancel={handleCancel} />

    </>
  )
}
