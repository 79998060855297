import { Component, createContext } from 'react'
import { get } from 'lodash'

const FavSearches = createContext()

class FavSearchesProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeFav: null,
      activeName: ''
    }
  }

  updateFavSearch (id, activeName) {
    this.setState({ activeFav: id, activeName })
  }

  updateFavName (value) {
    this.setState({ activeName: value })
  }

  render () {
    const state = get(this, 'state', {})
    const values = {
      state,
      updateFavSearch: id => this.updateFavSearch(id),
      updateFavName: name => this.updateFavName(name)
    }

    return (
      <FavSearches.Provider value={values}>
        {this.props.children}
      </FavSearches.Provider>
    )
  }
}

export { FavSearchesProvider }

export default FavSearches
