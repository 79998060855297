import { gql } from '@apollo/client'

export const getIrmInfluencersTableInProgramV2 = gql`
  query getIrmInfluencersTableInProgramV2 (
    $programId: String!,
    $sortParams: InfluencerInProgramV2SortOptions, 
    $page: Int, 
    $limit: Int,
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getIrmInfluencersTableInProgramV2(programId: $programId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      _id
      activeAudienceCodes
      addedByUser {
        firstName
      }
      alias
      createdAt
      createdByUser {
        firstName
      }
      email
      firstName
      irmInfluencerId
      lastName
      numberOfAudienceCodes
      hasInfluencerGiftingRules
      hasAudienceBenefitRules
      picture
      seeding
      rawSeeding
      status
      synchronized
      rawSynchronized
      totalCodesUsed
      totalSales
      updatedAt
      updatedByUser {
        firstName
      }
    }
  }
`
