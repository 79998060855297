import styled from 'styled-components'
import { Button, Flex, Image, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin-bottom: 16px;
`

const StyledText = styled(Text)`
  margin-top: 16px;
`

const StyledImage = styled(Image)`
  margin: 16px; 
  width: 184px;
  height: 184px;
`

export const ConnectError = ({ handleRetry, error = 'settings.yourPreferences.communication.email.configuration.modal.connectInbox.setUpError.titleParagraph1', buttonText = 'commons.button.tryAgain' }) => {
  const { t } = useTranslate()
  return (
    <StyledFlex column align='center'>
      <Text color='primary.dark' bold size='14'>{t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.setUpError.titleHeader1')}</Text>
      <StyledText color='primary.light1'>{t(error)}</StyledText>
      <StyledImage src='/static/img/error.png' alt='error' />
      <Button onClick={handleRetry}>{t(buttonText)}</Button>
    </StyledFlex>
  )
}
