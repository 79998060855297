import { gql } from '@apollo/client'

export const registerRelationshipEntityFieldEntityFieldGroup = gql`
  mutation registerRelationshipEntityFieldEntityFieldGroup(
    $entityFieldIds: [String]!
    $entityFieldGroupId: String!
  ) {
    registerRelationshipEntityFieldEntityFieldGroup(
      entityFieldIds: $entityFieldIds
      entityFieldGroupId: $entityFieldGroupId
    ){
      message
      success
    }
  }
`
