import { useEffect, useState } from 'react'
import { css } from 'styled-components'
import { SideModal } from 'influ-dms'
import { AddPostsModalContent } from './AddPostsModalContent'
import { AddPostsModalFooter } from './AddPostsModalFooter'
import { AddTrackingModalFooter } from './tracking/AddTrackingModalFooter'
import { useTranslate } from '~/hooks/useTranslate'

const modalContentStyles = css`
  padding: 0;
`

export const AddPostsModal = ({ isOpen, selectedSection: section, handleCreate, handleClose, loading, error, setError, handleCreateTracking }) => {
  const { t } = useTranslate()
  const [selectedSection, setSelectedSection] = useState()

  useEffect(() => {
    setSelectedSection(section || 0)
  }, [section])

  return (
    <SideModal
      name='addPosts'
      onClose={handleClose}
      isOpen={isOpen}
      title={t('reports.report.modal.addPublication.header')}
      width='900'
      contentStyles={modalContentStyles}
    >
      {isOpen && <AddPostsModalContent isOpen={isOpen} error={error} setError={setError} selectedSection={selectedSection} setSelectedSection={setSelectedSection} handleClose={handleClose} />}
      {selectedSection !== 2 ? <AddPostsModalFooter isOpen={isOpen} error={error} handleCreate={handleCreate} handleClose={handleClose} loading={loading} />
        : <AddTrackingModalFooter error={error} handleCreate={handleCreateTracking} handleClose={handleClose} loading={loading} />}
    </SideModal>)
}
