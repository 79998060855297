import { createContext, useReducer } from 'react'

const initialValues = {
  group: undefined,
  isAddGroupModalOpen: false,
  isEditGroupModalOpen: false,
  isDeleteGroupModalOpen: false
}

export const GroupContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setGroup':
      return { ...state, group: action.payload }
    case 'setAddGroupModalOpen': {
      return { ...state, isAddGroupModalOpen: action.value }
    }
    case 'setEditGroupModalOpen': {
      return { ...state, isEditGroupModalOpen: action.value }
    }
    case 'setDeleteGroupModalOpen': {
      return { ...state, isDeleteGroupModalOpen: action.value }
    }
  }
}

export const GroupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setGroup = (list) => {
    dispatch({ type: 'setGroup', payload: list })
  }

  const openAddGroupModal = () => {
    dispatch({ type: 'setAddGroupModalOpen', value: true })
  }

  const closeAddGroupModal = () => {
    dispatch({ type: 'setAddGroupModalOpen', value: false })
  }

  const openDeleteGroupModal = () => {
    dispatch({ type: 'setDeleteGroupModalOpen', value: true })
  }

  const closeDeleteGroupModal = () => {
    dispatch({ type: 'setDeleteGroupModalOpen', value: false })
  }

  const openEditGroupModal = () => {
    dispatch({ type: 'setEditGroupModalOpen', value: true })
  }

  const closeEditGroupModal = () => {
    dispatch({ type: 'setEditGroupModalOpen', value: false })
  }

  return (
    <GroupContext.Provider value={{
      ...state,
      setGroup,
      openAddGroupModal,
      closeAddGroupModal,
      openDeleteGroupModal,
      closeDeleteGroupModal,
      openEditGroupModal,
      closeEditGroupModal,
    }}>
      {children}
    </GroupContext.Provider>
  )
}
