import { gql } from '@apollo/client'

export const deleteProfiles = gql`
mutation deleteProfiles(
  $profileIds: [String]!
) {
  deleteProfiles(
    profileIds: $profileIds
  ){
    message
    code
    success
  }
}
 `
