import { Flex, Icon, Text } from 'influ-dms'
import { AvatarDropdownOptionItem } from './AvatarDropdownOptionItem'
import { css } from 'styled-components'
import { useTranslate } from '~/hooks/useTranslate'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const showGetBeamer = (publicRuntimeConfig?.showBeamer && publicRuntimeConfig?.showBeamer === 'true')

const containerStyles = css`
  padding: 7px 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

export const AvatarContactHelpCenter = ({ helpCenterUrl, handleClick }) => {
  const { t } = useTranslate()
  const helpCenterClick = () => {
    window.open(helpCenterUrl, '_blank')
    handleClick()
  }
  const whatsNewClick = () => {
    window?.Beamer?.show()
    handleClick()
  }

  if (!helpCenterUrl) return null

  return (
    <Flex column styles={containerStyles}>
      <AvatarDropdownOptionItem
        withHover
        withIcon
        handleClick={helpCenterClick}
        data-cy='Avatar-contact-Option-help-center'
      >
        <Icon color='primary.light2' name='document' size={16} />
        <Text ellipsis color='primary.light1' size='14'>{t('commons.topNavigation.user.dropdown.listItem.helpCenter.link')}</Text>
      </AvatarDropdownOptionItem>
      {showGetBeamer &&
      <AvatarDropdownOptionItem
        withHover
        withIcon
        handleClick={whatsNewClick}
        data-cy='Avatar-contact-Option-beamer-whats-new'
      >
        <Icon color='primary.light2' name='new' size={16} />
        <Text ellipsis color='primary.light1' size='14'>{t('commons.topNavigation.whatsNew.tooltip')}</Text>
      </AvatarDropdownOptionItem>}
    </Flex>
  )
}
