import { useContext, useState, useMemo } from 'react'
import { Flex, Button, Modal, Spacing, StateButton, Text, useKeyHandler, Portal } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { confirmNewPassword } from '../../graphql'
import { ThemeContext } from 'styled-components'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '~/context/Language'
import Form from './Form'
import { get } from 'lodash'
import cookiesParser from '../../helpers/cookiesParser'

const ChangePasswordModal = (props) => {
  const { colors: { gradients } } = useContext(ThemeContext)
  const { requestChangePassword, selfRefetch } = props
  const [isOpen, setIsOpen] = useState(requestChangePassword)
  const [errors, setErrors] = useState()
  const [success, setSuccess] = useState()
  const [confirmNewPasswordMutation, { loading }] = useMutation(confirmNewPassword)
  const { t } = useTranslate()
  const { locale } = useLanguageContext()

  const formFields = useMemo(() => {
    return [
      {
        name: 'password1',
        type: 'password',
        placeholder: t('commons.modals.setPassword.input.password.placeholder'),
        label: t('commons.modals.setPassword.input.password.label'),
        required: true
      },
      {
        name: 'password2',
        type: 'password',
        placeholder: t('commons.modals.setPassword.input.repeatpassword.placeholder'),
        label: t('commons.modals.setPassword.input.repeatpassword.label'),
        required: true
      }
    ]
  }, [locale])

  const onSubmit = (values) => {
    if (values.password1.length < 8) return setErrors(t('commons.modals.setPassword.input.repeatpassword.helpertext.error.charactersLong'))
    if (values.password1.length > 32) return setErrors(t('commons.resetPassword.input.email.helpertext.error.characterMax'))
    if (values.password1 !== values.password2) return setErrors(t('commons.modals.setPassword.input.repeatpassword.helpertext.error.passwordDifferent'))

    const token = get(cookiesParser(typeof window !== 'undefined' && document), 'token', null)
    const variables = { password: values.password1, token }
    confirmNewPasswordMutation({ variables })
      .then(({ data }) => {
        const _token = get(data, 'confirmNewPassword.data.token')
        if (_token) {
          document.cookie = `token=${_token}; path=/`
        }
        setSuccess(true)
      })
  }

  const handleOnClose = () => {
    selfRefetch()
    setIsOpen(false)
  }

  useKeyHandler({
    onEnter: success && handleOnClose
  })

  return (
    <Portal selector='#changePasswordModal'>
      <Modal
        isOpen={isOpen}
        title={t('commons.modals.setPassword.header')}
        height='auto'
        notClosable={!success}
        onClose={handleOnClose}
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        {!success ? (
          <>
            <Text size='14'>{t('commons.modals.setPassword.titleParagraph1')}</Text>
            <Spacing size='32' />
            <Form
              onSubmit={(event => onSubmit(event))}
              onChange={() => setErrors(undefined)}
              formFields={formFields}
              errors={errors}
              buttonHide
              buttonName='newPasswordButton'
            >
              <Spacing size='32' />
              <StateButton
                name='Change-password'
                isDisabled={loading}
                htmlFor='newPasswordButton'
                showLoader
                loading={loading}
              >
                {t('commons.button.setPassword')}
              </StateButton>
              <Spacing size='16' />
            </Form>
          </>)
          : (
            <Flex column>
              <Spacing size='32' />
              <Text size='14'>{t('commons.modals.setPassword.titleParagraph2.success')}</Text>
              <Spacing size='48' />
              <Button
                onClick={handleOnClose}
                data-cy='Modal-ModalPassword-Button-close'
              >
                {t('commons.button.close')}
              </Button>
            </Flex>)}
      </Modal>
    </Portal>
  )
}

export { ChangePasswordModal }
