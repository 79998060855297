import { gql } from '@apollo/client'

export const removeInfluencerFromProgramV2 = gql`
  mutation removeInfluencerFromProgramV2(
    $programIds: [String]!
    $influencerIds: [String]!
  ) {
    removeInfluencerFromProgramV2(
      programIds: $programIds
      influencerIds: $influencerIds
    ){
        message
        code
        success
      }
  }
 `
