import { find, get, isEmpty } from 'lodash'
import postalCodes from 'postal-codes-js'
import { dataCountriesVat } from '../../data/commons/dataCountriesVat'
import { validations } from 'front-tools'

const { checkValidEmail, checkDisposableEmail } = validations

export const validateField = (name, values) => {
  const value = values[name] || ''
  const isoCountry = get(values, 'country.value') || ''

  switch (name) {
    case 'email':
      return validateEmail(value)
    case 'address2':
      return true
    case 'state':
      return true
    case 'zipCode':
      return validateZipCode(isoCountry, value)
    case 'taxIdentifier':
      return validateTaxId(isoCountry, values, value)
    default:
      return !isEmpty(value)
  }
}

export const validateEmail = value => {
  const isValidFormat = checkValidEmail(value)
  const isNotSpam = checkDisposableEmail(value)
  return isValidFormat.success && isNotSpam.success
}

export const validateZipCode = (isoCountry, value) => (isEmpty(isoCountry) || postalCodes.validate(isoCountry, value) === true)

export const validateTaxId = (isoCountry, values, value) => {
  const tax = find(dataCountriesVat, { country: isoCountry }) || {}
  const regex = new RegExp('^'.concat(tax.format, '$'))
  const checkAndFormatOk = values.checkVatId && regex.test(value)
  return (!values.checkVatId || checkAndFormatOk || isEmpty(isoCountry) || isEmpty(tax.format))
}
