import { useContext, useState } from 'react'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { ReportsContext } from '../../context/ReportsContext'
import { useTranslate } from '~/hooks/useTranslate'

const deletePosts = gql`
 mutation deleteReportV2Publications($ids: [String]!) {
  deleteReportV2Publications(ids: $ids) {
      message
      success
    }
 }
 `

export const DeletePostsMutation = (props) => {
  const { t } = useTranslate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const { setReportsAction } = useContext(ReportsContext)

  const getErrorMessage = (message) => {
    // const is409 = message.includes('409')
    // if (is409) return networkTexts.influencerExists
    return t('commons.errorMessage.tryAgainLater')
  }

  const handleMutation = (variables, mutation) => {
    setLoading(true)

    return mutation({ variables })
      .then((response) => {
        setLoading(false)
        const result = Promise.resolve(response)
        setReportsAction({ action: 'deletePosts', data: result })
        return Promise.resolve(response)
      })
      .catch((error) => {
        const message = getErrorMessage(error.message)
        setLoading(false)
        setError(message)
        return Promise.reject(message)
      })
  }

  const clearErrors = () => setError(undefined)
  return (
    <Mutation
      mutation={deletePosts}
    >
      {(mutation, result) => {
        const mutationHandler = (variables) => handleMutation(variables, mutation)
        return props.children({ mutationHandler, result, loading, error, clearErrors })
      }}
    </Mutation>
  )
}
