import { createContext, useReducer } from 'react'

const initialValues = {
  outreach: undefined,
  outreachEmail: undefined,
  isCreateOutreachModalOpen: false,
  isEditOutreachModalOpen: false,
  isDeleteOutreachModalOpen: false,
  isDeleteOutreachesModalOpen: false,
  isCreateOutreachEmailModalOpen: false,
  isCloseCreateOutreachEmailModalOpen: false,
  isEditOutreachEmailModalOpen: false,
  isSendTestEmailModalOpen: false,
  isSummaryRecipientsModalOpen: {
    isOpen: false,
    type: ''
  },
  isConfigureBrandModalOpen: false,
  isSetUpBulkEmailModalOpen: false,
  isAddOutreachRecipientsModalOpen: {
    isOpen: false,
    ids: [],
    entityName: ''
  },
  isRemoveOutreachRecipientModalOpen: {
    isOpen: false,
    influencerId: null
  },
  isRemoveOutreachRecipientsModalOpen: false,
  isActivateOutreachModalOpen: false,
  isEndOutreachModalOpen: false,
  isOutreachRecipientActionsModalOpen: {
    isOpen: false,
    influencerId: null,
    alias: null,
    picture: null,
    email: null
  },
  isAssociateToOutreachesModalOpen: {
    isOpen: false,
    ids: [],
    entityName: ''
  },
  isOutreachPreviewModalOpen: false,
  outreachPreviewId: false
}

export const OutreachContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setOutreach':
      return { ...state, outreach: action.payload }
    case 'setOutreachEmail':
      return { ...state, outreachEmail: action.payload }
    case 'setCreateOutreachModalOpen':
      return { ...state, isCreateOutreachModalOpen: action.payload }
    case 'setEditOutreachModalOpen':
      return { ...state, isEditOutreachModalOpen: action.payload }
    case 'setDeleteOutreachModalOpen':
      return { ...state, isDeleteOutreachModalOpen: action.payload }
    case 'setDeleteOutreachesModalOpen':
      return { ...state, isDeleteOutreachesModalOpen: action.payload }
    case 'setSaveOutreachModalOpen':
      return { ...state, isSaveOutreachModalOpen: action.payload }
    case 'setSelectOutreachModalOpen':
      return { ...state, isSelectOutreachModalOpen: action.payload }
    case 'setCreateOutreachEmailModalOpen':
      return { ...state, isCreateOutreachEmailModalOpen: action.payload }
    case 'setCloseCreateOutreachEmailModalOpen':
      return { ...state, isCloseCreateOutreachEmailModalOpen: action.payload }
    case 'setEditOutreachEmailModalOpen':
      return { ...state, isEditOutreachEmailModalOpen: action.payload }
    case 'setSendTestEmailModalOpen':
      return { ...state, isSendTestEmailModalOpen: action.payload }
    case 'setSummaryRecipientsModalOpen':
      return { ...state, isSummaryRecipientsModalOpen: action.payload }
    case 'setConfigureBrandModalOpen':
      return { ...state, isConfigureBrandModalOpen: action.payload }
    case 'setSetUpBulkEmailModalOpen':
      return { ...state, isSetUpBulkEmailModalOpen: action.payload }
    case 'setAddOutreachRecipientsModalOpen':
      return { ...state, isAddOutreachRecipientsModalOpen: action.payload }
    case 'setRemoveOutreachRecipientModalOpen':
      return { ...state, isRemoveOutreachRecipientModalOpen: action.payload }
    case 'setRemoveOutreachRecipientsModalOpen':
      return { ...state, isRemoveOutreachRecipientsModalOpen: action.payload }
    case 'setActivateOutreachModalOpen':
      return { ...state, isActivateOutreachModalOpen: action.payload }
    case 'setEndOutreachModalOpen':
      return { ...state, isEndOutreachModalOpen: action.payload }
    case 'setOutreachRecipientActionsModalOpen':
      return { ...state, isOutreachRecipientActionsModalOpen: action.payload }
    case 'setAssociateToOutreachesModalOpen':
      return { ...state, isAssociateToOutreachesModalOpen: action.payload }
    case 'setOutreachPreviewModalOpen':
      return { ...state, isOutreachPreviewModalOpen: action.payload }
    case 'setOutreachPreviewId':
      return { ...state, outreachPreviewId: action.payload }
  }
}

export const OutreachProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setOutreach = (outreach) => {
    dispatch({ type: 'setOutreach', payload: outreach })
  }

  const setOutreachEmail = (outreachEmail) => {
    dispatch({ type: 'setOutreachEmail', payload: outreachEmail })
  }

  const setCreateOutreachModalOpen = (isOpen) => {
    dispatch({ type: 'setCreateOutreachModalOpen', payload: isOpen })
  }

  const setEditOutreachModalOpen = (isOpen) => {
    dispatch({ type: 'setEditOutreachModalOpen', payload: isOpen })
  }

  const setDeleteOutreachModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteOutreachModalOpen', payload: isOpen })
  }

  const setDeleteOutreachesModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteOutreachesModalOpen', payload: isOpen })
  }

  const setCreateOutreachEmailModalOpen = (isOpen) => {
    dispatch({ type: 'setCreateOutreachEmailModalOpen', payload: isOpen })
  }

  const setCloseCreateOutreachEmailModalOpen = (isOpen) => {
    dispatch({ type: 'setCloseCreateOutreachEmailModalOpen', payload: isOpen })
  }

  const setEditOutreachEmailModalOpen = (isOpen) => {
    dispatch({ type: 'setEditOutreachEmailModalOpen', payload: isOpen })
  }

  const setSendTestEmailModalOpen = (isOpen) => {
    dispatch({ type: 'setSendTestEmailModalOpen', payload: isOpen })
  }

  const setSummaryRecipientsModalOpen = (isOpen, type) => {
    dispatch({ type: 'setSummaryRecipientsModalOpen', payload: { isOpen, type } })
  }

  const setConfigureBrandModalOpen = (isOpen) => {
    dispatch({ type: 'setConfigureBrandModalOpen', payload: isOpen })
  }

  const setSetUpBulkEmailModalOpen = (isOpen) => {
    dispatch({ type: 'setSetUpBulkEmailModalOpen', payload: isOpen })
  }

  const setAddOutreachRecipientsModalOpen = (isOpen, ids, entityName) => {
    dispatch({ type: 'setAddOutreachRecipientsModalOpen', payload: { isOpen, ids, entityName } })
  }

  const setRemoveOutreachRecipientModalOpen = (isOpen, influencerId) => {
    dispatch({ type: 'setRemoveOutreachRecipientModalOpen', payload: { isOpen, influencerId } })
  }

  const setRemoveOutreachRecipientsModalOpen = (isOpen) => {
    dispatch({ type: 'setRemoveOutreachRecipientsModalOpen', payload: isOpen })
  }

  const setActivateOutreachModalOpen = (isOpen) => {
    dispatch({ type: 'setActivateOutreachModalOpen', payload: isOpen })
  }

  const setEndOutreachModalOpen = (isOpen) => {
    dispatch({ type: 'setEndOutreachModalOpen', payload: isOpen })
  }

  const setOutreachRecipientActionsModalOpen = (isOpen, influencerId, alias, picture, email, outreach) => {
    dispatch({ type: 'setOutreachRecipientActionsModalOpen', payload: { isOpen, influencerId, alias, picture, email, outreach } })
  }

  const setAssociateToOutreachesModalOpen = (isOpen, ids, entityName) => {
    dispatch({ type: 'setAssociateToOutreachesModalOpen', payload: { isOpen, ids, entityName } })
  }

  const setOutreachPreviewModalOpen = (isOpen) => {
    dispatch({ type: 'setOutreachPreviewModalOpen', payload: isOpen })
  }

  const setOutreachPreviewId = (outreachId) => {
    dispatch({ type: 'setOutreachPreviewId', payload: outreachId })
  }

  return (
    <OutreachContext.Provider value={{
      ...state,
      setOutreach,
      setOutreachEmail,
      setCreateOutreachModalOpen,
      setEditOutreachModalOpen,
      setDeleteOutreachModalOpen,
      setDeleteOutreachesModalOpen,
      setCreateOutreachEmailModalOpen,
      setCloseCreateOutreachEmailModalOpen,
      setEditOutreachEmailModalOpen,
      setSendTestEmailModalOpen,
      setSummaryRecipientsModalOpen,
      setConfigureBrandModalOpen,
      setSetUpBulkEmailModalOpen,
      setAddOutreachRecipientsModalOpen,
      setRemoveOutreachRecipientModalOpen,
      setRemoveOutreachRecipientsModalOpen,
      setActivateOutreachModalOpen,
      setEndOutreachModalOpen,
      setOutreachRecipientActionsModalOpen,
      setAssociateToOutreachesModalOpen,
      setOutreachPreviewModalOpen,
      setOutreachPreviewId
    }}>
      {children}
    </OutreachContext.Provider>
  )
}
