import { gql } from '@apollo/client'

export const duplicateTemplate = gql`
  mutation duplicateTemplate(
    $_id: String!
  ) {
    duplicateTemplate(
      _id: $_id
    ){
      message
      data
      success
    }
  }
`
