import { gql } from '@apollo/client'


export const addProgramV2AudienceBenefit = gql`
  mutation addProgramV2AudienceBenefit(
    $programId: String!,
    $benefit: AudienceBenefitOptions!,
    $seedCode: AudienceBenefitSeedCodeInputType,
    $value: AudienceBenefitValueInputType,
    $purchaseType: PurchaseTypeOptionsEnum,
    $applyDiscountTo: ApplyDiscountToInputType,
    $minimumPurchaseRequirements: MinimumPurchaseRequirementsInputType,
    $customerEligibility: CustomerEligibilityInputType,
    $maximumDiscountUses: MaximumDiscountUsesInputType,
    $recurringPaymentsForSubscriptions: RecurringPaymentsForSubscriptionsInputType
    $productDiscountCanBeCombinedWith: [ProductDiscountCanBeCombinedWithEnumOptions],
    $countries: CountriesInputType,
    $excludeShippingRatesOverAmount: ExcludeShippingRatesOverAmountWrapperInputType
    $activeDates: ProgramV2ActiveDateInputObjectType,
  ) {
    addProgramV2AudienceBenefit(
      programId: $programId,
      benefit: $benefit,
      seedCode: $seedCode,
      value: $value,
      purchaseType: $purchaseType,
      applyDiscountTo: $applyDiscountTo,
      minimumPurchaseRequirements: $minimumPurchaseRequirements,
      customerEligibility: $customerEligibility,
      maximumDiscountUses: $maximumDiscountUses,
      recurringPaymentsForSubscriptions: $recurringPaymentsForSubscriptions,
      productDiscountCanBeCombinedWith: $productDiscountCanBeCombinedWith,
      countries: $countries
      excludeShippingRatesOverAmount: $excludeShippingRatesOverAmount
      activeDates: $activeDates
    ) {
      message
      code
      success
      data
    }
  }
`