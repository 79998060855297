import { useContext, useEffect } from 'react'
import { Flex, FormContext, Spacing, Text, Toggle } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const SecureCheck = ({ checkValue, handleChange, name }) => {
  const { t } = useTranslate()
  const { setValue } = useContext(FormContext)
  useEffect(() => {
    setValue('InputText', name, checkValue)
  }, [checkValue])

  return (
    <Flex grow='1'>
      <Flex column grow='1'>
        <Text size='12' color='primary.dark' bold>{t('settings.yourPreferences.communication.email.configuration.connection.imap.secure.secureCheck.text1')}</Text>
        <Spacing size='4' />
        <Text size='12' color='primary.light2'>{t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.secure.helperText')}</Text>
      </Flex>
      <Flex column grow='1' justify='center' align='flex-end'>
        <Toggle checked={checkValue} onChange={handleChange} />
      </Flex>
    </Flex>
  )
}
