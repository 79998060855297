import { useQuery, } from '@apollo/client'
import { FiltersTableContext } from 'influ-dms'
import { getMTConfigFilters } from '../../apollo'
import { getFiltersSorted } from '../utils/getFiltersSorted'
import { useRouter } from 'next/router'
import { cloneDeep, get } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import sha512 from 'js-sha512'
import { useLanguageContext } from '~/context/Language'
import { captureException } from '@sentry/nextjs'
import { useTranslate } from '~/hooks/useTranslate'

export const useFilter = () => {
  const { t } = useTranslate()
  const { setFilteredData, filters, setFilters, setFavFilters, filtersAdded, filtersHidden, setFiltersHidden, searchFilter, sortBy, setCount, setPageIndex, setProcessingFilter } = useContext(FiltersTableContext)
  const { locale } = useLanguageContext()

  const [queryOptions, setQueryOptions] = useState({ skip: true })
  const { data, loading: loadingFilters } = useQuery(getMTConfigFilters, queryOptions)

  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const sharedId = get(router, 'query.sharedId')

  useEffect(() => {
    if (data) {
      const { allFilters, favFilters } = getFiltersSorted(cloneDeep(data.getMTConfigFilters), t)
      setFilters(allFilters)
      setFavFilters(favFilters)
    }
  }, [data, locale])

  const getFilters = (tableName, view) => {
    try {
      const options = { variables: { brandId, sharedId, tableName, view }, skip: !brandId && !sharedId }
      setQueryOptions(options)
    } catch (e) {
      captureException('Error obtaining filters', e)
    }
  }

  const getFilterValues = (type, values, multiple, between, input) => {
    const [firstValue, secondValue] = values
    if (!input) return true
    switch (type) {
      case 'string':
      case 'datePicker':
        if (multiple && !between) return values.map((value) => value.value)
        if (between) return { min: firstValue.value, max: secondValue.value }
        return firstValue.value
      case 'numberSelectPlus':
      case 'numberSearchPlus':
        if (multiple && !between) {
          return values.map((value) => {
            const parsedValue = { ...value, value: value.weight.value }
            const { weight, ...parsedValueWithoutWeight } = parsedValue
            return parsedValueWithoutWeight
          })
        } else if (between) {
          return { min: { ...firstValue, value: firstValue.weight.value }, max: { ...secondValue, value: secondValue.weight.value } }
        } else {
          const parsedValue = { ...firstValue, value: firstValue.weight.value }
          const { weight, ...parsedValueWithoutWeight } = parsedValue
          return parsedValueWithoutWeight
        }
      default:
        if (multiple && !between) return values
        if (between) return { min: firstValue, max: secondValue }
        return firstValue
    }
  }

  const getFormattedFilters = (filters) => {
    return filters.filter((filter) => filter.applied).map((filter) => {
      const selectedRelative = { ...filter.selectedRelative }
      const values = getFilterValues(filter.type, filter.values, selectedRelative.multipleSelections, selectedRelative.between, selectedRelative.input)
      return {
        _id: filter._id,
        operation: {
          labelId: selectedRelative.labelId,
          values
        }
      }
    })
  }

  const getFilteredDataOptions = ({ newFilters, newSearchFilter, newPage, newSort, newFiltersHidden }, extraArgs, countExtraArgs) => {
    setProcessingFilter(true)
    const filtersToApply = newFilters !== undefined ? newFilters : filtersAdded
    let allFiltersToApply = getFormattedFilters(filtersToApply)
    if (newFiltersHidden) setFiltersHidden(newFiltersHidden)
    allFiltersToApply = allFiltersToApply.concat(newFiltersHidden || filtersHidden)

    const searchToApply = newSearchFilter ?? searchFilter
    if (searchToApply.value && searchToApply.value !== '') {
      const searchFilterToApply = filters.map(group => {
        const filter = group.filters.find(filter => filter.labelId === searchToApply.filterId)
        return filter
      })
      if (searchFilterToApply) {
        allFiltersToApply.push(
          {
            _id: searchFilterToApply[0]._id,
            operation: {
              labelId: 'containsExactly',
              values: [searchToApply.value]
            }
          }
        )
      }
    }
    const page = newPage ?? 0 // pageIndex
    setPageIndex(page)
    const pageLimit = 20
    const sortParams = newSort ?? sortBy
    const options = { variables: { page, limit: pageLimit, filters: allFiltersToApply, sortParams, ...extraArgs }, skip: !brandId }
    const countOptions = { variables: { filters: allFiltersToApply, ...countExtraArgs }, skip: !brandId }
    return { page, options, countOptions }
  }

  const getFilteredData = async (query, accessor, { newFilters, newSearchFilter, newPage, newSort, newFiltersHidden }, extraArgs, countQuery, countAccessor, countExtraArgs) => {
    try {
      const { page, options, countOptions } = getFilteredDataOptions({ newFilters, newSearchFilter, newPage, newSort, newFiltersHidden }, extraArgs, countExtraArgs)
      if (page === 0 && countQuery) {
        countQuery(countOptions)
          .then((responseCount) => {
            if (responseCount && responseCount.data) {
              setCount(responseCount.data[countAccessor])
            }
          })
      }
      query(options)
        .then((response) => {
          if (response && response.data) {
            setFilteredData(get(response, 'data.' + accessor))
            setProcessingFilter(false)
          }
        })
    } catch (e) {
      captureException('Error obtaining filtered data', e)
    }
  }

  function generateId (tableName, labelId) {
    const hash = sha512.create()
    hash.update(`${tableName}${labelId}`)
    const filterId = hash.hex()

    return filterId.slice(0, 24)
  }

  return { getFilters, getFormattedFilters, loadingFilters, getFilteredData, getFilteredDataOptions, generateId }
}
