import { useContext } from 'react'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useFilter, useCleanCache } from '../../../irm/commons/hooks'
import { useFilterQuery } from '../../../irm/commons/hooks/useFilterQuery'
import {
  getPaymentPoolsByInvoice,
  countPaymentPoolsByInvoice,
  confirmPayment as confirmPaymentCall
} from '../apollo'
import { useTranslate } from '~/hooks'
import { SnackbarContext } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/nextjs'

export const useInvoicePools = () => {
  const {
    clearPoolsTableCache,
    clearInvoiceDetailCache,
    clearInvoicesTableCache,
    clearPaymentsTableCache,
    clearCountInfluencersToPayCache,
    clearCanGenerateInvoiceCache
  } = useCleanCache()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    loadingData: loadingInvoicesPools,
    loadingCount: loadingCountInvoicesPools,
    setQueryOptions: setTableInvoicesOptions,
    setCountQueryOptions: setCountTableInvoicesOptions
  } = useFilterQuery({
    query: getPaymentPoolsByInvoice,
    queryAccessor: 'getPaymentPoolsByInvoice',
    countQuery: countPaymentPoolsByInvoice,
    countQueryAccessor: 'countPaymentPoolsByInvoice'
  })

  const { getFilteredDataOptions } = useFilter()

  const [confirmPaymentMutation, { loading: loadingConfirmPayment }] = useMutation(confirmPaymentCall, {
    update (cache, _, { variables }) {
      clearPaymentsTableCache({ evictCount: false })
      clearPoolsTableCache()
      variables?.paymentInvoiceId && clearCountInfluencersToPayCache(variables.paymentInvoiceId)
      variables?.paymentInvoiceId && clearInvoiceDetailCache(variables.paymentInvoiceId)
      clearInvoicesTableCache({ evictCount: false })
      clearCanGenerateInvoiceCache()
      cache.gc()
    }
  })

  const getInvoicePools = async (filterParams, paymentInvoiceId, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, paymentInvoiceId, view },
        { brandId, paymentInvoiceId, view }
      )
      setTableInvoicesOptions(options)
      page === 0 && setCountTableInvoicesOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining Invoice Pools', e)
    }
  }

  const confirmPayment = async (paymentInvoiceId) => {
    const variables = { paymentInvoiceId }
    try {
      const response = await confirmPaymentMutation({ variables })
      return response
    } catch (e) {
      captureException('Error confirming payment', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  return {
    getInvoicePools,
    loadingInvoicesPools,
    loadingCountInvoicesPools,
    confirmPayment,
    loadingConfirmPayment
  }
}
