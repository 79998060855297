import { gql } from '@apollo/client'

const searchAutocomplete = gql`
  query searchAutocomplete($query: String!, $type: AutocompleteOptionTypes!, $network: String) {
    searchAutocomplete(
      query: $query,
      type: $type,
      network: $network,
    ) {
        key,
        label,
        value,
        translationKey
        originalLabel
        type
    }
  }
`

const locationAutocompleteByType = gql`
  query locationAutocompleteByType($query: String!, $type: LocationAutocompleteByTypeOptions!) {
    locationAutocompleteByType(
      query: $query,
      type: $type
    ) {
        key,
        label,
        value,
        translationKey
        originalLabel
    }
  }
`

export { searchAutocomplete, locationAutocompleteByType }
