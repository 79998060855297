import { gql } from '@apollo/client'

export const activateIrmInfluencersInProgram = gql`
  mutation activateIrmInfluencersInProgram(
    $programIds: [String!]!
    $influencerIds: [String!]!
  ) {
    activateIrmInfluencersInProgram(
      programIds: $programIds
      influencerIds: $influencerIds
    ) {
      message
      code
      success
      data
    }
  }
`