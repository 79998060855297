import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { headerStyles } from '../../styles'
import { useTranslate } from '~/hooks/useTranslate'

export const AddPostsManualTiktokAdditionalMetrics = () => {
  const { t } = useTranslate()

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.additionalMetricsOfThepublicationBlock.title')}</Text>
      <Spacing size='24' />
      <Form.TimeInput name='additionalMetrics.totalPlayTime' label={t('commons.insights.totalTimeWatched.input.label')} />
      <Spacing size='24' />
      <Form.TimeInput name='additionalMetrics.averageWatchTime' label={t('commons.insights.totalTimeWatched.averageTimeWatched.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.watchedTheFullVideo.input.placeholder')} name='additionalMetrics.watchedTheFullVideo' label={t('commons.insights.watchedTheFullVideo.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.trafficSourceTypeFollow.input.placeholder')} name='additionalMetrics.trafficSourceFollow' label={t('commons.insights.trafficSourceTypeForFollow.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.trafficSourceTypeFollow.input.placeholder')} name='additionalMetrics.trafficSourcePersonalProfile' label={t('commons.insights.trafficSourceTypePersonalProfile.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.reachAudience.input.placeholder')} name='additionalMetrics.reach' label={t('commons.insights.reachAudience.input.label')} />
      <Spacing size='8' />
    </SideBox>
  )
}
