import { gql } from '@apollo/client'

export const instagramPostAdditionalMetricsFragment = gql`
  fragment instagramPostAdditionalMetrics on InstagramPostAdditionalMetricsType {
    shares
    saves
    profileVisits
    websiteClicks
    reach
    impressions
    newFollows
    interactingAccounts
    newFollowers
  }
`
export const InstagramReelAdditionalMetricsFragment = gql`
  fragment instagramReelAdditionalMetrics on InstagramReelAdditionalMetricsType {
    totalPlayTime
    averageWatchTime
    impressions
    shares
    saves
    reach
  }
`
export const instagramPhotoMetricsFragment = gql`
  fragment instagramPhotoMetrics on InstagramPhotoInterface {
    totalInteractions
    engagement
    likes
    comments
    additionalMetrics {
      ...instagramPostAdditionalMetrics
    }
    likeAndViewCountDisabled
  }
  ${instagramPostAdditionalMetricsFragment}
`
export const instagramCarouselMetricsFragment = gql`
  fragment instagramCarouselMetrics on InstagramCarouselInterface {
    totalInteractions
    engagement
    likes
    comments
    videoViews
    additionalMetrics {
      ...instagramPostAdditionalMetrics
    }
    likeAndViewCountDisabled
  }
  ${instagramPostAdditionalMetricsFragment}
`
export const instagramVideoMetricsFragment = gql`
  fragment instagramVideoMetrics on InstagramVideoInterface {
    totalInteractions
    engagement
    likes
    comments
    videoViews
    additionalMetrics {
      ...instagramPostAdditionalMetrics
    }
    likeAndViewCountDisabled
  }
  ${instagramPostAdditionalMetricsFragment}
`

export const instagramReelMetricsFragment = gql`
  fragment instagramReelMetrics on InstagramReelInterface {
    totalInteractions
    engagement
    likes
    comments
    videoViews
    videoPlays
    additionalMetrics {
      ...instagramReelAdditionalMetrics
    }
    taggedUsers
    likeAndViewCountDisabled
  }
  ${InstagramReelAdditionalMetricsFragment}
`

export const instagramStoryMetricsFragment = gql`
  fragment instagramStoryMetrics on InstagramStoryInterface {
    totalInteractions
    stickers
    swipeUrl
    likes
    additionalMetrics {
      reach
      impressions
      navigationBack
      navigationForward
      nextStory
      exits
      linkClicks
      profileVisits
      swipes
      stickerTaps
      replies
      shares
      interactingAccounts
    }
  }
`
export const instagramYoutubeMetricsFragment = gql`
  fragment instagramYoutubeMetrics on YoutubeVideoInterface {
    totalInteractions
    engagement
    likes
    dislikes
    comments
    videoViews
    additionalMetrics {
      reach
      shares
      impressions
      uniqueViewers
      returningViewers
      impressionsClickThroughRate
      timeWatched
      subscriptionDriven
    }
  }
`
export const instagramTiktokMetricsFragment = gql`
  fragment instagramTiktokMetrics on TiktokVideoInterface {
    totalInteractions
    engagement
    likes
    dislikes
    comments
    videoViews
    shares
    saves
    additionalMetrics {
      totalPlayTime
      averageWatchTime
      trafficSourceForYou
      trafficSourceFollow
      trafficSourcePersonalProfile
      reach
      watchedTheFullVideo
      genderMale
      genderFemale
      countries {
        countryName
        countryPercentage
      }
      cities {
        cityName
        cityPercentage
      }
      age18To24
      age25To34
      age35To44
      age45To54
      age55
    }
  }
`

export const postQuery = gql`
 query getReportsV2Publication (
   $postId: String!
 ) {
  getReportsV2Publication(_id: $postId) {
    publicationInReportV2Id: _id
    reportV2Id
    influencerInReportV2Id
    network
    username
    slug
    image
    caption
    profileUrl
    profilePicture
    url
    shortcode
    type
    dataSource
    status
    publicationDate
    cost
    createdAt
    updatedAt
    media
    mediaOriginal
    ... on InstagramPhotoInterface {
      ...instagramPhotoMetrics
    }
    ... on InstagramCarouselInterface {
      ...instagramCarouselMetrics
    }
    ... on InstagramVideoInterface {
      ...instagramVideoMetrics
    }
    ... on InstagramReelInterface {
      ...instagramReelMetrics
    }
    ... on InstagramStoryInterface {
      ...instagramStoryMetrics
    }
    ... on YoutubeVideoInterface {
      ...instagramYoutubeMetrics
    }
    ... on TiktokVideoInterface {
      ...instagramTiktokMetrics
    }
  }
 }
 ${instagramPhotoMetricsFragment}
 ${instagramCarouselMetricsFragment}
 ${instagramVideoMetricsFragment}
 ${instagramReelMetricsFragment}
 ${instagramStoryMetricsFragment}
 ${instagramYoutubeMetricsFragment}
 ${instagramTiktokMetricsFragment}
`
