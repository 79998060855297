import { AddPostsManualPostInfo } from './subForms/AddPostsManualPostInfo'
import { AddPostsManualTiktokMetrics } from './subForms/AddPostsManualTiktokMetrics'
import { AddPostsManualTiktokAdditionalMetrics } from './subForms/AddPostsManualTiktokAdditionalMetrics'
import { AddPostsManualPostCost } from './subForms/AddPostsManualPostCost'
import { AddPostsManualTiktokCountriesAndCities } from './subForms/AddPostsManualTiktokCountriesAndCities'

export const AddPostsManualTiktokVideo = ({ multiplePhotos }) => (
  <>
    <AddPostsManualPostInfo multiple={multiplePhotos}/>
    <AddPostsManualTiktokMetrics />
    <AddPostsManualTiktokAdditionalMetrics />
    <AddPostsManualTiktokCountriesAndCities />
    <AddPostsManualPostCost />
  </>
)
