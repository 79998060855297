import { useState, useContext } from 'react'
import { get, isEmpty, unionBy } from 'lodash'
import {
  Button,
  Flex,
  Modal,
  Spacing,
  StateButton,
  Text,
  Form,
  Icon,
  FormContext,
  Portal
} from 'influ-dms'
import { css, ThemeContext } from 'styled-components'
import { FormatOptionLabel } from '../../../../search/FiltersModal/SelectNetwork/FormatOptionLabel'
import { AddProfileTrackingTagInput } from '../../../commons/addPosts/tracking/AddProfileTrackingTagInput'
import { LIMIT_OF_PROFILES_TO_ADD } from '../../../commons/addPosts/tracking/AddProfileTrackingCard'
import { DuplicatedProfilesTrackingModal } from '../DuplicatedProfilesTrackingModal'
import { useTranslate } from '~/hooks/useTranslate'

const networkOptions = [{ value: 'instagram', label: 'Instagram' }]
const textStyles = css`
  margin: 2px 2px;
`
const textMarginStyles = css`
 margin: '0px 4px' ;
 `

const AddProfileToTrackingModal = (props) => {
  const { t } = useTranslate()
  const { isOpen, onClose } = props
  const { values, setValue } = useContext(FormContext)
  const { colors: { gradients } } = useContext(ThemeContext)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const limitExceeded = get(values.influencersInModal, 'length', 0) + get(values.influencers, 'length', 0) > LIMIT_OF_PROFILES_TO_ADD
  const isDisabled = isEmpty(values.influencersInModal) || limitExceeded || loading || error
  const [duplicatedModalOpen, setDuplicatedModalOpen] = useState(false)
  const [duplicatedProfilesNumber, setDuplicatedProfilesNumber] = useState(false)
  const [totalProfilesNumber, setTotalProfilesNumber] = useState(false)

  const handleOnSave = () => {
    if (!isDisabled) {
      const newProfiles = unionBy(values.influencersInModal, values.influencers, 'username')
      const duplicatedProfiles = newProfiles.length - get(values.influencers, 'length', 0)
      if (values.influencersInModal.length - duplicatedProfiles !== 0) {
        setDuplicatedProfilesNumber(duplicatedProfiles)
        setTotalProfilesNumber(values.influencersInModal.length)
        setDuplicatedModalOpen(true)
      }
      setValue('InputText', 'influencers', newProfiles)
      setValue('InputText', 'influencersInModal', [])
      setError(undefined)
      onClose()
    }
  }

  const handleOnCancel = () => {
    setValue('InputText', 'influencersInModal', [])
    setError(undefined)
    onClose()
  }

  return (
    <Portal selector='#trackingModal'>
      <Modal
        name='addProfileToTracking'
        onClose={handleOnCancel}
        isOpen={isOpen}
        title={t('reports.modal.tracking.addNewProfile.header')}
        height='auto'
        minHeight='420'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        <Flex column grow='1'>
          <Flex align='flex-start'>
            <Form.Select
              formatOptionLabel={FormatOptionLabel}
              name='network'
              defaultValue={networkOptions[0]}
              options={networkOptions}
              color='primary.light3'
              isSearchable={false}
              value={networkOptions[0]}
              isDisabled
            />
          </Flex>
          <Spacing size='16' />
          <AddProfileTrackingTagInput setLoading={setLoading} setError={setError} loading={loading} />
          <Spacing size='8' />
          {!isEmpty(error) ? (
            <Flex align='center'>
              <Icon name='danger' size='12' color='state.dangerDefault' />
              <Text color='state.dangerDefault' styles={textStyles}>
                {error || t('reports.modal.tracking.addNewProfile.profile.input.helpertext.invalidProfilesOrDuplicates.error')}
              </Text>
            </Flex>)
            : (
              <Text color='primary.light3' styles={textMarginStyles}>
                {t('reports.modal.tracking.addNewProfile.profile.input.helpertext')}
              </Text>
              )}
          {limitExceeded && (
            <Flex align='center'>
              <Icon name='danger' size='12' color='state.dangerDefault' />
              <Text color='state.dangerDefault' styles={textStyles}>
                {t('reports.modal.tracking.addNewProfile.profile.input.helpertext.reachedInfluencerLimitperTracking.error', { influencersNumber: LIMIT_OF_PROFILES_TO_ADD })}
              </Text>
            </Flex>
          )}
        </Flex>
        <Spacing size='14' />
        {duplicatedModalOpen &&
          <DuplicatedProfilesTrackingModal
            isOpen={duplicatedModalOpen}
            onClose={() => {
              setDuplicatedModalOpen(false)
            }}
            duplicatedProfilesNumber={duplicatedProfilesNumber}
            totalProfilesNumber={totalProfilesNumber}
          />}
        <Flex>
          <StateButton
            name='addInfluencerToTracking'
            disabled={isDisabled}
            loading={loading}
            onClick={handleOnSave}
            data-cy='addInfluencerToTracking'
            showLoader
          >{t('commons.button.addProfiles')}
          </StateButton>
          <Spacing size='8' vertical />
          <Button
            name='closeAddInfluencerToTracking'
            variant='flat'
            color='secondary'
            onClick={handleOnCancel}
          >
            {t('commons.button.cancel')}
          </Button>
        </Flex>
        <Spacing size='8' />
      </Modal>
    </Portal>
  )
}

export { AddProfileToTrackingModal }
