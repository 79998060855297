import { useContext } from 'react'
import { useTranslate } from '~/hooks'
import { LimitsContext } from '../../../../context'

export const useLimitModalsOnRefresh = () => {
  const { t } = useTranslate()
  const { setLimitOpen, setModalTitle, setTitle, setSubtitle, setLimit } = useContext(LimitsContext)

  const notShowingRefreshModal = window?.localStorage?.getItem('irm_refresh_profile_confirmation')

  const handleModalForLimitReached = () => {
    setLimit(t('commons.modals.blocked.titleHeader1.maximunNumberOfMonthlyAnalyses'))
    setLimitOpen(true)
  }

  const handleModalForUnavailableFeature = () => {
    setModalTitle(t('commons.modals.featureUnavailable.header'))
    setTitle(t('commons.modals.featureUnavailable.titleHeader1'))
    setSubtitle(t('commons.modals.blocked.titleParagraph1.upgradeYourPlanToUseThisFeature'))
    setLimitOpen(true)
  }

  return {
    notShowingRefreshModal,
    handleModalForLimitReached,
    handleModalForUnavailableFeature
  }
}
