import { Form, FormInputWithValidations, Spacing, Text } from 'influ-dms'
import { ImapSecure } from './ImapSecure'
import { SmtpSecure } from './SmtpSecure'
import { validateEmail } from '../../../../../../stripe/PaymentFormFieldValidation'
import { useTranslate } from '~/hooks/useTranslate'

const emailValidation = {
  checkFunction: val => !validateEmail(val)
}

const requiredValidation = {
  checkFunction: val => val === '' || val === undefined || val === null
}

export const ImapForm = () => {
  const { t } = useTranslate()

  return (
    <>
      <Spacing size='24' />
      <FormInputWithValidations name='email' validations={[emailValidation]}>
        {({ name }) => (
          <Form.InputText
            id='email' label={t('settings.yourPreferences.communication.email.configuration.input.emailAddress.label')} name={name}
            helperText={t('settings.yourPreferences.communication.email.configuration.input.emailAddress.helperText.default')} autoComplete='off'
          />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <FormInputWithValidations name='password' validations={[requiredValidation]}>
        {({ name }) => (
          <Form.InputText id='password' label={t('settings.yourPreferences.communication.email.configuration.input.password.label')} name={name} type='password' helperText={t('settings.yourPreferences.communication.email.configuration.input.password.helperText.default')} autoComplete='new-password' />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <Text size='16' bold color='primary.dark'>IMAP</Text>
      <Spacing size='16' />
      <FormInputWithValidations name='imap.host' validations={[requiredValidation]}>
        {({ name }) => (
          <Form.InputText label={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.host.label')} name={name} helperText={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.host.helperText')} />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <FormInputWithValidations name='imap.port' validations={[requiredValidation]}>
        {({ name }) => (
          <Form.InputNumber label={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.servicePortNumber.label')} name={name} helperText={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.servicePortNumber.helperText')} />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <ImapSecure />
      <Spacing size='16' />
      <Text size='16' bold color='primary.dark'>SMTP</Text>
      <Spacing size='16' />
      <FormInputWithValidations name='smtp.host' validations={[requiredValidation]}>
        {({ name }) => (
          <Form.InputText label={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.host.label')} name={name} helperText={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.host.helperText')} />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <FormInputWithValidations name='smtp.port' validations={[requiredValidation]}>
        {({ name }) => (
          <Form.InputNumber label={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.servicePortNumber.label')} name={name} helperText={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.servicePortNumber.helperText')} />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <SmtpSecure />
      <Spacing size='24' />
    </>
  )
}
