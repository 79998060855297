import { useRef } from 'react'
import styled from 'styled-components'
import { Button, Flex, Icon } from 'influ-dms'

const StyledInput = styled.input`
  display: none;
`

export const NewEmailModalFooterAttachmentButton = ({ files, setFiles, styles }) => {
  const fileRef = useRef()
  const onClick = () => {
    fileRef.current.value = null
    fileRef.current.click()
  }
  const handleChange = async e => {
    const newFiles = Array.from(e.target.files)
    for (const file of newFiles) {
      file.id = `${file.name}-${(new Date()).getTime()}`
    }
    setFiles([...files, ...newFiles], 'addMultiple')
  }

  return (
    <Flex>
      <StyledInput type='file' multiple onChange={handleChange} ref={fileRef} />
      <Button color='secondary' variant='flat' onClick={onClick} styles={styles}>
        <Icon name='clip' size='18' />
      </Button>
    </Flex>
  )
}
