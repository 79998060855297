import { useContext, useEffect, useState } from 'react'
import { Form, FormContext } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const CreateReportModalTitle = ({ isOpen, clearErrors }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const [autoFocus, setAutoFocus] = useState()

  useEffect(() => {
    if (isOpen && !values.name && !values.description) {
      setAutoFocus(true)
      const timeoutId = setTimeout(() => setAutoFocus(false), 0)
      return () => clearTimeout(timeoutId)
    }
  }, [isOpen, values])

  return (
    <Form.InputText autoFocus={autoFocus} placeholder={t('reports.modal.createReport.mainInfoBlock.reportName.input.placeholder')} name='name' label={t('reports.modal.createReport.mainInfoBlock.reportName.input.label')} onChange={clearErrors} />
  )
}
