import { css } from 'styled-components'

export const avatarRemainingStyles = css`
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  padding-top: 4px; 
`
export const avatarBrandStyles = css`
  height: 32px;
  width: 32px;
  margin: -4px; 
  border-radius: 50%;
  z-index: 2;
`
