import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  StyledButton,
  widgetTooltipContainerStyles,
  widgetTooltipStyles
} from './styles'

export const LandingPagesListTableTdName = ({
  row: { original },
  onEditClick,
  onDeleteClick,
  onPreviewClick,
}) => {
  const { t } = useTranslate()
  const landingPage = original || {}
  const { formName } = landingPage

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis showTooltip size='14' color='primary.light2' bold>{formName}</Text>
        </StyledContainer>
        <HoverWrapper>
          {onPreviewClick &&
            <StyledButton color='secondary' onClick={onPreviewClick}>
              {t('commons.button.preview')}
            </StyledButton>
          }
          {onEditClick && <Tooltip
            text={t('commons.button.edit.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onEditClick} iconName='edit' />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={t('commons.button.delete.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onDeleteClick} iconName='trash' />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
