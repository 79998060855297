import { useContext } from 'react'
import { Button, FormContext, SideModalFooter, StateButton, Text } from 'influ-dms'
import { css } from 'styled-components'
import { isEmpty } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'

const marginStyles = css`
  :not(:last-child) {
    margin-right: 16px;
  }
`

export const AddTrackingModalFooter = ({ error, handleCreate, handleClose, loading }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const onCreate = () => handleCreate(values, false)
  const onAnother = () => handleCreate(values, true)
  const isValid = values.name && values.network && !isEmpty(values.influencers) &&
   values.startDate && (!isEmpty(values.hashtags) || !isEmpty(values.mentions) || !isEmpty(values.keywords)) && !loading
  const disabled = !isValid || !!error

  return (
    <SideModalFooter>
      <StateButton name='addTracking' loading={loading} disabled={disabled} onClick={onCreate} styles={marginStyles}>{t('commons.button.createTracking')}</StateButton>
      <Button name='saveAndAddOtherTracking' disabled={disabled} variant='outline' onClick={onAnother} styles={marginStyles}>{t('commons.button.saveAndAddAnotherTracking')}</Button>
      <Button name='cancelAddTracking' disabled={loading} variant='flat' color='secondary' onClick={handleClose} styles={marginStyles}>{t('commons.button.cancel')}</Button>
      {error && error.showInFooter && <Text color='app.default'>{error.text}</Text>}
    </SideModalFooter>
  )
}
