import { useState } from 'react'
import styled, { css } from 'styled-components'
import { Dropdown, Flex, Icon, Text } from 'influ-dms'
import { AvatarDropdownOptionItem } from './AvatarDropdownOptionItem'
import { AvatarContactDropdown } from './AvatarContactDropdown/AvatarContactDropdown'
import { useTranslate } from '~/hooks/useTranslate'

const StyledDiv = styled.div`
  margin-right: 8px;
  overflow: hidden;
`

const containerStyles = css`
  padding: 7px 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

const noWrapStyles = css`
  white-space: nowrap;
`

const IconContainer = styled.div`
  margin-left: auto;
`

const dropdownStyles = css`
  min-width: 230px;
  margin-left: 230px;
  margin-top: -70px;
`

export const AvatarContactAccounts = ({ brandName, brands, onClose, showGetBeamer }) => {
  const [isOpen, setOpen] = useState(false)
  const handleClick = () => { setOpen(prevState => !prevState) }
  const { t } = useTranslate()

  if (brands.length <= 1) return null

  return (
    <>
      <AvatarDropdownOptionItem styles={containerStyles} handleClick={handleClick} withHover>
        <Flex align='center'>
          <StyledDiv>
            <Text bold color='primary.dark' ellipsis size='14'>{brandName}</Text>
            <Text color='primary.light3' styles={noWrapStyles}>{t('commons.topNavigation.user.dropdown.brandSelector.changeAccounts.label')}</Text>
          </StyledDiv>
          <IconContainer>
            <Icon name='arrowright' color='primary.dark' />
          </IconContainer>
        </Flex>
      </AvatarDropdownOptionItem>
      <Dropdown
        isOpen={isOpen}
        setOpen={setOpen}
        align='left'
        onMouseLeave={() => {}}
        styles={dropdownStyles}
        width='auto'
      >
        <AvatarContactDropdown
          brands={brands}
          handleClick={onClose}
          showGetBeamer={showGetBeamer}
        />
      </Dropdown>
    </>
  )
}
