import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, Form, FormContext } from 'influ-dms'
import { get, isEqual } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'

const CountryContainer = styled.div`
  flex: 2 0 0;
  margin-right: 16px;
`
const PercentageContainer = styled.div`
  flex: 1 0 0;
`

export const AddPostsManualTiktokCountryMetrics = ({ i, countries }) => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const [optionCountries, setOptionCountries] = useState(countries)
  const [selectedCountries, setSelectedCountries] = useState([])
  const countryName = `additionalMetrics.countries[${i - 1}].countryName`
  const percentageName = `additionalMetrics.countries[${i - 1}].countryPercentage`
  const hasCountryValue = !!get(values, countryName)
  const hasPreviousCountryValue = i === 1 || !!get(values, `additionalMetrics.countries[${i - 2}].countryName`)

  useEffect(() => {
    if (!hasPreviousCountryValue && hasCountryValue) {
      setValue('InputText', countryName, null)
      setValue('InputText', percentageName, null)
    }
  }, [hasPreviousCountryValue])

  useEffect(() => {
    if (hasCountryValue && get(values, percentageName) === null) {
      setValue('InputText', percentageName, 0)
    }
    const newSelectedCountries = get(values, 'additionalMetrics.countries', []).map(({ countryName }) => countryName)
    if (!isEqual(selectedCountries, newSelectedCountries)) {
      setSelectedCountries(newSelectedCountries)
    }
  }, [values])

  useEffect(() => {
    setOptionCountries(countries.filter(({ value }) => ((selectedCountries.indexOf(value) === -1) || get(values, countryName) === value)))
  }, [selectedCountries])

  const onChange = e => {
    if (e.value && !get(values, percentageName)) {
      setValue('InputText', percentageName, 0)
    } else if (!e.value) {
      setValue('InputText', percentageName, null)
    }
  }

  return (
    <Flex>
      <CountryContainer>
        <Form.Select
          placeholder={t('commons.insights.selectCountry.input.placeholder')}
          name={countryName}
          label={`${t('commons.insights.audienceCountry.input.label')} ${i}`}
          options={optionCountries}
          isDisabled={!hasPreviousCountryValue}
          onChange={onChange}
        />
      </CountryContainer>
      <PercentageContainer>
        <Form.InputNumber placeholder='%' name={percentageName} label='%' withDecimal max={100} min={0} disabled={!hasCountryValue} />
      </PercentageContainer>
    </Flex>
  )
}
