import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { updateProfileValidation } from '../../apollo'

export const useCalculateProfileRefreshValidations = ({ profileIds = [], isMassiveAction = false, isOpen = false }) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const {
    data: rawValidationData,
    loading: validationLoading,
    error: validationError
  } = useQuery(updateProfileValidation, {
    variables: { brandId, profileIds },
    skip: !profileIds.length || !isMassiveAction || !isOpen,
    fetchPolicy: 'network-only'
  })

  const validationSummaryData = get(rawValidationData, 'updateProfileValidation.calculateProfilesBulk', {})
  const analyzableProfiles = get(rawValidationData, 'updateProfileValidation.profileIds', [])

  return {
    validationLoading,
    validationError,
    analyzableProfiles,
    validationSummaryData
  }
}
