import { createContext, useReducer } from 'react'

const initialValues = {
  property: undefined,
  isAddPropertyModalOpen: false,
  isEditPropertyModalOpen: false,
  isDeletePropertyModalOpen: false,
  isDeletePropertiesModalOpen: false,
  isMovePropertyToGroupModalOpen: false
}

export const PropertyContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setProperty':
      return { ...state, property: action.payload }
    case 'setAddPropertyModalOpen': {
      return { ...state, isAddPropertyModalOpen: action.value }
    }
    case 'setEditPropertyModalOpen': {
      return { ...state, isEditPropertyModalOpen: action.value }
    }
    case 'setDeletePropertyModalOpen': {
      return { ...state, isDeletePropertyModalOpen: action.value }
    }
    case 'setDeletePropertiesModalOpen': {
      return { ...state, isDeletePropertiesModalOpen: action.value }
    }
    case 'setMovePropertyToGroupModalOpen': {
      return { ...state, isMovePropertyToGroupModalOpen: action.value }
    }
  }
}

export const PropertyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setProperty = (list) => {
    dispatch({ type: 'setProperty', payload: list })
  }

  const openAddPropertyModal = () => {
    dispatch({ type: 'setAddPropertyModalOpen', value: true })
  }

  const closeAddPropertyModal = () => {
    dispatch({ type: 'setAddPropertyModalOpen', value: false })
  }

  const openDeletePropertyModal = () => {
    dispatch({ type: 'setDeletePropertyModalOpen', value: true })
  }

  const closeDeletePropertyModal = () => {
    dispatch({ type: 'setDeletePropertyModalOpen', value: false })
  }

  const openDeletePropertiesModal = () => {
    dispatch({ type: 'setDeletePropertiesModalOpen', value: true })
  }

  const closeDeletePropertiesModal = () => {
    dispatch({ type: 'setDeletePropertiesModalOpen', value: false })
  }

  const openEditPropertyModal = () => {
    dispatch({ type: 'setEditPropertyModalOpen', value: true })
  }

  const closeEditPropertyModal = () => {
    dispatch({ type: 'setEditPropertyModalOpen', value: false })
  }

  const openMovePropertyToGroupModal = () => {
    dispatch({ type: 'setMovePropertyToGroupModalOpen', value: true })
  }

  const closeMovePropertyToGroupModal = () => {
    dispatch({ type: 'setMovePropertyToGroupModalOpen', value: false })
  }

  return (
    <PropertyContext.Provider value={{
      ...state,
      setProperty,
      openAddPropertyModal,
      closeAddPropertyModal,
      openDeletePropertyModal,
      closeDeletePropertyModal,
      openDeletePropertiesModal,
      closeDeletePropertiesModal,
      openEditPropertyModal,
      closeEditPropertyModal,
      openMovePropertyToGroupModal,
      closeMovePropertyToGroupModal
    }}>
      {children}
    </PropertyContext.Provider>
  )
}
