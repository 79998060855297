import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { getPaymentPool } from '../apollo/getPaymentPool'

export const useQueryPool = ({ variables = {}, skip, fetchPolicy = 'cache-first' }) => {
  const query = getPaymentPool

  const { data, loading, error, refetch } = useQuery(query, { variables, fetchPolicy, skip })
  const resultData = get(data, 'getPaymentPool')

  return { data: resultData, loading, error, refetch }
}
