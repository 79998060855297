import { gql } from '@apollo/client'


export const notifyProfileDataProcesssed = gql`
subscription notifyProfileDataProcesssed($brandId: String!){
  notifyProfileDataProcesssed(brandId: $brandId){
    profileId
    brandId
    subscriptionStatus
    profilesLoading
    profileIdsBefore
  }
}
`
