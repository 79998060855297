import { Button, EmptyState, Flex, Spacing } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { RouterHelper } from '~/routing/routerHelper'

export const MessageLimitReached = ({ onClose, brandId, moreInfluencersThanMessages, remainingMassiveCommMessages }) => {
  const { t } = useTranslate()
  const router = useRouter()

  const goToPricesPlans = () => {
    onClose()
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }

  return (
    <EmptyState
      imageUrl={'/static/img/error.png'}
      titleText={remainingMassiveCommMessages === 0 ? t('modal.newMessage.bulkEmail.limitReached.titleHeader1') : t('modal.newMessage.bulkEmail.noEnough.titleHeader1', { count: remainingMassiveCommMessages })}
      subtitleText={remainingMassiveCommMessages === 0 ? t('modal.newMessage.bulkEmail.limitReached.titleHeader2') : t('modal.newMessage.bulkEmail.noEnough.titleHeader2')}
    >
      <Flex align='center'>
        {moreInfluencersThanMessages &&
          <>
            <Button variant='outline' color='primary' size='l' onClick={onClose}>
              {t('commons.button.cancel')}
            </Button>
            <Spacing vertical size='16' />
          </>
        }
        <Button size='l' onClick={goToPricesPlans}>
          {t('commons.button.seePlansAndPrices')}
        </Button>
      </Flex>
    </EmptyState>
  )
}
