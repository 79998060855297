import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles,
  clickableStyles
} from './styles'

export const DomainsListTableTdDomainName = ({
  row: { original },
  onDeleteClick,
  onDomainNameClick
}) => {
  const { t } = useTranslate()
  const domain = original || {}
  const { domainName } = domain

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis showTooltip size='14' color='primary.light2' bold onClick={onDomainNameClick} styles={clickableStyles}>
            {domainName}
          </Text>
        </StyledContainer>
        <HoverWrapper>
          {onDeleteClick && <Tooltip
            text={t('commons.button.disconnect.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onDeleteClick} iconName='disconnect' />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
