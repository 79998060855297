import { useContext } from 'react'
import { Modal } from 'influ-dms'
import { EmailSetupDisableInboxModalContent } from './EmailSetupDisableInboxModalContent'
import { EmailSetupDisableInboxModalFooter } from './EmailSetupDisableInboxModalFooter'
import { useMutation } from '@apollo/client'
import { disableCommunicationAccountMutation } from '../../../../../../../commons/communication/apollo/communicationMutations'
import { CommunicationContext } from '../../../../../../../commons/communication/CommunicationContext'
import { COMMUNICATION_ACTIONS } from '../../../../../../../commons/communication/CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const EmailSetupDisableInboxModal = ({ isOpen, onClose }) => {
  const { t } = useTranslate()
  const { setCommunicationAction } = useContext(CommunicationContext)
  const [disableCommunicationAccount, { loading }] = useMutation(disableCommunicationAccountMutation)
  const handleDisable = () => {
    disableCommunicationAccount()
      .then(() => {
        setCommunicationAction({ type: COMMUNICATION_ACTIONS.accountDisabled })
        onClose()
      })
      .catch(() => {
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      height='auto'
      title={t('settings.yourPreferences.communication.email.configuration.modal.turnOffInbox.header')}
      color='state.dangerDefault'
      onClose={onClose}
    >
      <EmailSetupDisableInboxModalContent />
      <EmailSetupDisableInboxModalFooter loading={loading} handleDisable={handleDisable} handleClose={onClose} />
    </Modal>
  )
}
