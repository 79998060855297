import styled, { css } from 'styled-components'
import { Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledOl = styled.ol`
  margin: 0;
  padding-left: 24px;
`

const StyledLi = styled.li`
  list-style-type: disc;
`

const textStyles = css`
  margin: 24px 0 8px 0;
`

export const EmailSetupDisableInboxModalContent = () => {
  const { t } = useTranslate()

  return (
    <>
      <Text color='primary.dark' bold size='15'>{t('settings.yourPreferences.communication.email.configuration.modal.turnOffInbox.titleHeader1')}</Text>
      <Text color='primary.light3' size='14' styles={textStyles}>{t('settings.yourPreferences.communication.email.configuration.modal.turnOffInbox.titleParagraph1')}</Text>
      <StyledOl>
        <Text color='primary.light3' size='14'><StyledLi>{t('settings.yourPreferences.communication.email.configuration.modal.turnOffInbox.titleParagraph2')}</StyledLi></Text>
        <Text color='primary.light3' size='14'><StyledLi>{t('settings.yourPreferences.communication.email.configuration.modal.turnOffInbox.titleParagraph3')}</StyledLi></Text>
      </StyledOl>
    </>
  )
}
