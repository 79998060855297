import { gql } from '@apollo/client'

export const countGiftingCodesByProgramId = gql`
  query countGiftingCodesByProgramId(
    $programId: String!,
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    countGiftingCodesByProgramId(
      programId: $programId,
      filters: $filters,
      view: $view
    )
  }
`
