import { gql } from '@apollo/client'

export const reportsV2CurrencyBySharedLink = gql`
  query reportsV2CurrencyBySharedLink (
    $sharedId: String!
    $locale: String
  ) {
    reportsV2CurrencyBySharedLink(sharedId: $sharedId, locale: $locale) {
      id: _id
      code
      currency
      symbol
    }
  }
 `
