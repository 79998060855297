import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { updateProfileServiceCost } from '../../apollo'

export const useCalculateServiceCost = ({ profileIds = [], isMassiveAction = false, isOpen = false }) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const {
    data: serviceCostData,
    loading: serviceCostLoading,
    error: serviceCostError
  } = useQuery(updateProfileServiceCost, {
    variables: { brandId, profileIds },
    skip: !profileIds.length || !isMassiveAction || !isOpen,
    fetchPolicy: 'network-only'
  })

  const analysisToCharge = get(serviceCostData, 'updateProfileServiceCost.analysisToCharge', '')
  const analysisByNetwork = get(serviceCostData, 'updateProfileServiceCost.analysisByNetwork', [])
  const analyzableProfiles = get(serviceCostData, 'updateProfileServiceCost.profileIds', [])

  return {
    serviceCostLoading,
    serviceCostError,
    analysisToCharge,
    analysisByNetwork,
    analyzableProfiles
  }
}
