import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { RoundedButton, Tooltip } from 'influ-dms'
import { scrapPublicationDataMutation } from '../../../../apollo/refreshQueries'
import { ReportsContext } from '../../../../context/ReportsContext'
import { useEnableAfterTime } from '../../../../../../hooks/useEnableAfterTime'
import { useTranslate } from '~/hooks/useTranslate'

export const EditPostModalContentHeaderRefresh = ({ post }) => {
  const { t } = useTranslate()
  const [scrapPublicationData] = useMutation(scrapPublicationDataMutation)
  const { setReportsAction } = useContext(ReportsContext)
  const [clicked, setClicked] = useState(false)
  const refreshDisabled = useEnableAfterTime({ dateToEnable: new Date(post.updatedAt) })
  const loading = post.status === 'processing' || clicked
  const error = post.status === 'error'
  const disabled = refreshDisabled || loading || error

  useEffect(() => {
    if (clicked) setClicked(false)
  }, [post, refreshDisabled])

  const handleClick = () => {
    setClicked(true)
    setReportsAction({ action: 'editPost', data: post.publicationInReportV2Id })
    scrapPublicationData({ variables: { postId: post.publicationInReportV2Id } })
  }

  return (
    <Tooltip text={refreshDisabled ? t('reports.header.report.button.updatePublications.wait5Minutes.tooltip') : ''} type='dark' color='neutral.white'>
      <RoundedButton onClick={handleClick} name='refresh' label={t('commons.button.refresh')} spinWhenDisabled={loading} isDisabled={disabled} />
    </Tooltip>
  )
}
