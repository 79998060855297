import { gql } from '@apollo/client'

export const computeOverlap = gql`
  mutation computeOverlap(
    $listId: String!
    $network: String!
  ) {
    computeOverlap(
      listId: $listId
      network: $network
    ){
      message
      success
    }
  }
`
