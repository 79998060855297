import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, FormContext, Text } from 'influ-dms'

const StyledSelector = styled(Flex)`
  height: 100%;
  flex: 1;
  background: #FFFBFB;
`
const TextContainer = styled.div`
  padding: 32px;
  width: 100%;
`
const SectionContainer = styled.div`
  width: 100%;
  :first-of-type {
    margin-top: 16px;
  }
  padding: 8px 0;
  cursor: pointer;
  border-radius: 4px;
  ${({ theme, isSelected }) => isSelected ? `background: ${theme.colors.primary.light5}` : ''};
`
const StyledText = styled(Text)`
  padding: 0 4px;
`

export const AddPostsModalContentSelector = ({ title, sections, selectedSection, setError, onChange }) => {
  const { cleanForm } = useContext(FormContext)

  useEffect(() => {
    cleanForm()
    setError(undefined)
  }, [selectedSection])

  return (
    <StyledSelector>
      <TextContainer>
        <StyledText color='primary.dark' size='15' bold>{title}</StyledText>
        {sections.map((section, i) => (
          <SectionContainer key={section} isSelected={i === selectedSection} onClick={() => onChange(i)}>
            <StyledText>{section}</StyledText>
          </SectionContainer>))}
      </TextContainer>
    </StyledSelector>
  )
}
