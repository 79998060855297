import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { get, isEmpty } from 'lodash'
import { Form } from 'influ-dms'
import { AddPostsModal } from './index'
import { LimitsContext } from '../../../../context'
import { RouterHelper } from '../../../../routing/routerHelper'
import {
  addAutomaticPostsMutation,
  addInstagramCarouselManualMutation,
  addInstagramPhotoManualMutation,
  addInstagramReelManualMutation,
  addInstagramStoryManualMutation,
  addInstagramVideoManualMutation,
  addTikTokVideoManualMutation,
  addTiktokPhotoManualMutation,
  addYoutubeVideoManualMutation,
  addYoutubeShortManualMutation
} from '../../apollo/addPostsQueries'
import { addTrackingMutation } from '../../apollo/tracking/addTrackingQueries'
import { getErrorMessage, getTrackingErrorMessage } from './addPostsHelper'
import { ReportsContext } from '../../context/ReportsContext'
import { RefreshRemainingFeatureFlagsContext } from '../../../../context/RefreshRemainingFeatureFlags'
import { ModalsContext } from '../../../commons/context'
import { useTranslate } from '~/hooks/useTranslate'
import moment from 'moment'
import { captureException } from '@sentry/nextjs'

export const AddPosts = ({ isOpen, selectedSection, onClose }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const reportId = get(router, 'query.reportId')
  const brandId = get(router, 'query.brandId')
  const { setLimit, setLimitOpen } = useContext(LimitsContext)
  const { dispatch } = useContext(ModalsContext)
  const { setReportsAction } = useContext(ReportsContext)
  const { remainingUrlsPerPeriodReportsV2 } = useContext(RefreshRemainingFeatureFlagsContext)
  const canAdd = values => remainingUrlsPerPeriodReportsV2 === null || remainingUrlsPerPeriodReportsV2 >= get(values, 'urls.length', 1)
  const [addAutomaticPosts, { loading: addAutomaticPostsLoading, error: addAutomaticPostsError }] = useMutation(addAutomaticPostsMutation)
  const [addInstagramStoryManual, { loading: addInstagramStoryManualLoading, error: addInstagramStoryManualError }] = useMutation(addInstagramStoryManualMutation)
  const [addInstagramVideoManual, { loading: addInstagramVideoManualLoading, error: addInstagramVideoManualError }] = useMutation(addInstagramVideoManualMutation)
  const [addInstagramReelManual, { loading: addInstagramReelManualLoading, error: addInstagramReelManualError }] = useMutation(addInstagramReelManualMutation)
  const [addInstagramPhotoManual, { loading: addInstagramPhotoManualLoading, error: addInstagramPhotoManualError }] = useMutation(addInstagramPhotoManualMutation)
  const [addInstagramCarouselManual, { loading: addInstagramCarouselManualLoading, error: addInstagramCarouselManualError }] = useMutation(addInstagramCarouselManualMutation)
  const [addYoutubeVideoManual, { loading: addYoutubeVideoManualLoading, error: addYoutubeVideoManualError }] = useMutation(addYoutubeVideoManualMutation)
  const [addYoutubeShortManual, { loading: addYoutubeShortManualLoading, error: addYoutubeShortManualError }] = useMutation(addYoutubeShortManualMutation)
  const [addTikTokVideoManual, { loading: addTikTokVideoManualLoading, error: addTikTokVideoManualError }] = useMutation(addTikTokVideoManualMutation)
  const [addTiktokPhotoManual, { loading: addTiktokPhotoManualLoading, error: addTiktokPhotoManualError }] = useMutation(addTiktokPhotoManualMutation)
  // Tracking Mutation
  const [addReportV2Tracking, { loading: addReportV2TrackingLoading }] = useMutation(addTrackingMutation)

  const [error, setError] = useState()
  const loading = addAutomaticPostsLoading || addInstagramStoryManualLoading || addInstagramVideoManualLoading || addInstagramReelManualLoading || addYoutubeShortManualLoading ||
    addInstagramPhotoManualLoading || addInstagramCarouselManualLoading || addYoutubeVideoManualLoading || addTikTokVideoManualLoading || addTiktokPhotoManualLoading || addReportV2TrackingLoading
  useEffect(() => {
    const error = addAutomaticPostsError || addInstagramStoryManualError || addInstagramVideoManualError || addInstagramReelManualError || addTiktokPhotoManualError ||
      addInstagramPhotoManualError || addInstagramCarouselManualError || addYoutubeVideoManualError || addTikTokVideoManualError || addYoutubeShortManualError
    if (error) {
      const message = getErrorMessage(JSON.parse(JSON.stringify(error)), t)
      setError(message)
    }
  }, [addAutomaticPostsError, addInstagramStoryManualError, addInstagramVideoManualError, addInstagramReelManualError,
    addInstagramPhotoManualError, addInstagramCarouselManualError, addYoutubeVideoManualError, addTikTokVideoManualError])

  const getMutation = values => {
    const { network, type } = values
    if (network === 'youtube') {
      if (type === 'video') return addYoutubeVideoManual
      if (type === 'short') return addYoutubeShortManual
    }
    if (network === 'tiktok') {
      if (type === 'video') return addTikTokVideoManual
      if (type === 'photo') return addTiktokPhotoManual
    }
    if (network === 'instagram') {
      if (type === 'story') return addInstagramStoryManual
      if (type === 'video') return addInstagramVideoManual
      if (type === 'reel') return addInstagramReelManual
      if (type === 'photo') return addInstagramPhotoManual
      if (type === 'carousel') return addInstagramCarouselManual
    }
    return addAutomaticPosts
  }

  const handleAddPosts = (values, keepAdding) => {
    if (!canAdd(values)) {
      setLimitOpen(true)
      setLimit(t('commons.modals.blocked.titleHeader1.maximunNumberOfPublications'))
      return
    }

    const mutation = getMutation(values)
    mutation({ variables: { reportV2Id: reportId, ...values } })
      .then(({ data: { addPublicationAuto } }) => {
        if (get(addPublicationAuto, 'success') === false) {
          const message = getErrorMessage(JSON.parse(JSON.stringify(addPublicationAuto)), t)
          setError(message)
          return
        }
        RouterHelper.traceEvent('postsAdded')
        setReportsAction({ action: 'addPosts', data: addPublicationAuto })
        if (!keepAdding) {
          onClose()
        }
        dispatch({ type: 'keepAddingPosts', payload: get(values, 'urls.length') || 1 })
      })
      .catch((err) => { console.log({ createError: err }) })
  }

  const handleAddTracking = (values, keepAdding) => {
    const variables = {
      reportV2Id: reportId,
      brandId,
      ...values,
      startDate: values.startDate && moment(values.startDate).format('YYYY-MM-DD'),
      endDate: values.endDate && moment(values.endDate).format('YYYY-MM-DD'),
      hashtags: (values.hashtags || []).map(elem => elem.value),
      mentions: (values.mentions || []).map(elem => elem.value),
      keywords: (values.keywords || []).map(elem => elem.value),
      influencers: isEmpty(values.influencers) ? [] : values.influencers.map(({ username, network, profileUrl, profilePicture }) => { return { username, network, profileUrl, profilePicture } })
    }

    addReportV2Tracking({ variables })
      .then(data => {
        setReportsAction({ action: 'addTracking', data })
        if (!keepAdding) {
          onClose()
        }
        setReportsAction({ action: 'keepAddingTracking' })
      })
      .catch((err) => {
        setError(getTrackingErrorMessage(err, t))
        captureException({ error })
      })
  }

  const onAddPostsClose = () => {
    setError(undefined)
    onClose()
  }

  return (
    <Form>
      <AddPostsModal
        isOpen={isOpen}
        selectedSection={selectedSection}
        handleCreate={handleAddPosts}
        handleCreateTracking={handleAddTracking}
        handleClose={onAddPostsClose}
        loading={loading}
        error={error}
        setError={setError}
      />
    </Form>
  )
}
