import { gql } from '@apollo/client'

export const listNotesQuery = gql`
  query influencerNotes($influencerId: String!, $limit: Int, $page: Int) {
    influencerNotes(influencerId: $influencerId, limit: $limit, page: $page) {
      totalCount
      edges {
        id: _id
        text
        influencerId
        brandId
        author {
          userId
          fullName
          image
        }
        updateUser {
          userId
          fullName
          image
        }
        createdAt
        hasUpdatePermission
      }
    }
  }
`
