import { gql } from '@apollo/client'

const categories = gql`
 query searchCategories{
   categories{
     categoryId
     category
   }
 }
`

export { categories }
