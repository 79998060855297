import { useContext, useState } from 'react'
import { Form, FormContext, SideBox, Spacing, Text } from 'influ-dms'
import { get } from 'lodash'
import { headerStyles } from '../styles'
import { FormatOptionLabel } from '~/containers/reports/../search/FiltersModal/SelectNetwork/FormatOptionLabel'
import { networkOptions } from '~/containers/reports/../search/FiltersModal/SelectNetwork'
import { errorStyles } from '~/containers/reports/influencers/styles'
import { AddPostsManualCheckProfileButton } from './AddPostsManualCheckProfileButton'
import { postNetworkValidator } from '../../addPostsHelper'
import { LimitsContext } from '~/containers/reports/../../context'
import { ModalsContext } from '~/containers/reports/../commons/context'
import { useQuery } from '@apollo/client'
import { brand } from '~/containers/reports/../../graphql/brands'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'

export const AddPostsManualMainInfo = ({ urlHasBeenChecked, profileHasBeenChecked, setProfileHasBeenChecked }) => {
  const { t } = useTranslate()
  const { values, cleanFormExcept } = useContext(FormContext)
  const [profileError, setProfileError] = useState()
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const brandId = get(router, 'query.brandId')
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId || isPublic })
  const networksInReportsV2 = get(data, 'brand.featureFlags.reportsV2.networks', [])
  const { validateLimit } = useContext(LimitsContext)
  const { dispatch } = useContext(ModalsContext)

  const onCheckProfileSuccess = () => {
    setProfileHasBeenChecked(true)
  }

  const onCheckProfileError = error => {
    setProfileError({ error })
  }

  const handleChange = ({ e, src = '' }) => {
    let validNetworkUrl = null
    if (src !== 'inputText') {
      validNetworkUrl = postNetworkValidator(e.value, networksInReportsV2)
    }
    if (validNetworkUrl === null) {
      setProfileError(undefined)
      if (profileHasBeenChecked) {
        cleanFormExcept(['username', 'network'])
        setProfileHasBeenChecked(false)
      }
    } else {
      validateLimit(false)
      dispatch({ type: 'toggleAddPosts' })
    }
  }
  const isYoutube = get(values, 'network') === 'youtube'
  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.mainInformationBlock.title')}</Text>
      <Spacing size='16' />
      <Form.Select
        placeholder={t('commons.insights.socialNetwork.input.placeholder')}
        label={t('commons.insights.socialNetwork.input.label')}
        name='network'
        options={networkOptions}
        formatOptionLabel={FormatOptionLabel}
        isDisabled={urlHasBeenChecked}
        onChange={(e) => handleChange({ e })}
      />
      <Spacing size='24' />
      <Form.InputText
        placeholder={t('commons.insights.influencerProfile.input.placeholder')}
        name='username'
        label={isYoutube ? t('commons.insights.influencerChannel.input.label') : t('commons.insights.influencerProfile.input.label')}
        disabled={urlHasBeenChecked}
        onChange={() => handleChange({ src: 'inputText' })}
      />
      <Spacing size='4' />
      {profileError && <Text styles={errorStyles} color='state.dangerDefault'>{profileError.error}</Text>}
      {!urlHasBeenChecked && (
        <>
          <Spacing size='20' />
          <AddPostsManualCheckProfileButton
            profileHasBeenChecked={profileHasBeenChecked}
            onCheckProfileSuccess={onCheckProfileSuccess}
            onCheckProfileError={onCheckProfileError}
          />
        </>
      )}
      <Spacing size='8' />
    </SideBox>
  )
}
