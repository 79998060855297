import { gql } from '@apollo/client'

export const unregisterRelationshipEntityViewUsers = gql`
  mutation unregisterRelationshipEntityViewUsers(
    $entityViewId: [String]!
  ) {
    unregisterRelationshipEntityViewUsers(
      entityViewId: $entityViewId
    ){
      message
      code
      success
    }
  }
`