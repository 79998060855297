import { createContext, useReducer } from 'react'

const initialValues = {
  list: undefined,
  loadingMetrics: false,
  isListErrorModalOpen: false,
  isListEmptyAggregatedDataModalOpen: false,
  isNewListModalOpen: false,
  isEditListModalOpen: false,
  isDeleteListModalOpen: false,
  isDeleteProfileFromListModalOpen: false,
  isDeleteInfluencerFromListModalOpen: {
    isOpen: false,
    influencerId: null,
    listId: null
  },
  isShareModalOpen: false,
  sharedUrl: undefined,
  isDeleteMassiveProfilesFromListModalOpen: false,
  isDeleteMassiveInfluencersFromListModalOpen: {
    isOpen: false,
    selectedInfluencers: []
  },
  isRefreshOverlapModalOpen: false,
  isListOverlapErrorModalOpen: false,
  isListOverlapEmptyDataModalOpen: false,
  isLoadingComputeOverlap: false,
  isLinkInfluencersToListModalOpen: false,
  isAddInfluencersToListModalOpen: false,
  isLinkInfluencersToListsModalOpen: {
    isOpen: false,
    selectedInfluencers: []
  }
}

export const ListContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setList':
      return { ...state, list: action.payload }
    case 'setLoadingMetrics':
      return { ...state, loadingMetrics: action.value }
    case 'setListEmptyAggregatedDataModalOpen':
      return { ...state, isListEmptyAggregatedDataModalOpen: action.value }
    case 'setListErrorModalOpen':
      return { ...state, isListErrorModalOpen: action.value }
    case 'setNewListModalOpen': {
      return { ...state, isNewListModalOpen: action.value }
    }
    case 'setEditListModalOpen': {
      return { ...state, isEditListModalOpen: action.value }
    }
    case 'setDeleteListModalOpen': {
      return { ...state, isDeleteListModalOpen: action.value }
    }
    case 'setDeleteProfileFromListModalOpen': {
      return { ...state, isDeleteProfileFromListModalOpen: action.value }
    }
    case 'setDeleteInfluencerFromListModalOpen': {
      return { ...state, isDeleteInfluencerFromListModalOpen: action.payload }
    }
    case 'setDeleteMassiveProfilesFromListModalOpen': {
      return { ...state, isDeleteMassiveProfilesFromListModalOpen: action.value }
    }
    case 'setDeleteMassiveInfluencersFromListModalOpen': {
      return { ...state, isDeleteMassiveInfluencersFromListModalOpen: action.payload }
    }
    case 'setShareModalOpen': {
      return { ...state, isShareModalOpen: action.value }
    }
    case 'setSharedUrl': {
      return { ...state, sharedUrl: action.value }
    }
    case 'setRefreshOverlapModalOpen': {
      return { ...state, isRefreshOverlapModalOpen: action.value }
    }
    case 'setIsListOverlapErrorModalOpen':
      return { ...state, isListOverlapErrorModalOpen: action.value }
    case 'setIsListOverlapEmptyDataModalOpen':
      return { ...state, isListOverlapEmptyDataModalOpen: action.value }
    case 'setIsLoadingComputeOverlap':
      return { ...state, isLoadingComputeOverlap: action.value }
    case 'setLinkInfluencersToListModalOpen':
      return { ...state, isLinkInfluencersToListModalOpen: action.value }
    case 'setAddInfluencersToListModalOpen':
      return { ...state, isAddInfluencersToListModalOpen: action.value }
    case 'setLinkInfluencersToListsModalOpen':
      return { ...state, isLinkInfluencersToListsModalOpen: action.payload }
  }
}

export const ListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setList = (list) => {
    dispatch({ type: 'setList', payload: list })
  }

  const setIsLoadingMetrics = (value) => {
    dispatch({ type: 'setLoadingMetrics', value })
  }

  const setListEmptyAggregatedDataModalOpen = (value) => {
    dispatch({ type: 'setListEmptyAggregatedDataModalOpen', value })
  }

  const setListErrorModalOpen = (value) => {
    dispatch({ type: 'setListErrorModalOpen', value })
  }

  const setNewListModalOpen = (value) => {
    dispatch({ type: 'setNewListModalOpen', value })
  }

  const setEditListModalOpen = (value) => {
    dispatch({ type: 'setEditListModalOpen', value })
  }

  const openShareModal = (sharedUrl) => {
    dispatch({ type: 'setShareModalOpen', value: true })
    dispatch({ type: 'setSharedUrl', value: sharedUrl })
  }

  const closeShareModal = () => {
    dispatch({ type: 'setShareModalOpen', value: false })
    dispatch({ type: 'setSharedUrl', value: undefined })
  }

  const openDeleteListModal = () => {
    dispatch({ type: 'setDeleteListModalOpen', value: true })
  }

  const closeDeleteListModal = () => {
    dispatch({ type: 'setDeleteListModalOpen', value: false })
  }

  const openDeleteProfileFromListModal = () => {
    dispatch({ type: 'setDeleteProfileFromListModalOpen', value: true })
  }

  const closeDeleteProfileFromListModal = () => {
    dispatch({ type: 'setDeleteProfileFromListModalOpen', value: false })
  }

  const openDeleteInfluencerFromListModal = (influencerId, listId) => {
    dispatch({ type: 'setDeleteInfluencerFromListModalOpen', payload: { isOpen: true, influencerId, listId } })
  }

  const closeDeleteInfluencerFromListModal = () => {
    dispatch({ type: 'setDeleteInfluencerFromListModalOpen', payload: { isOpen: false, influencerId: null, listId: null } })
  }

  const openDeleteMassiveProfilesFromListModal = () => {
    dispatch({ type: 'setDeleteMassiveProfilesFromListModalOpen', value: true })
  }

  const closeDeleteMassiveProfilesFromListModal = () => {
    dispatch({ type: 'setDeleteMassiveProfilesFromListModalOpen', value: false })
  }

  const openDeleteMassiveInfluencersFromListModal = (selectedInfluencers) => {
    dispatch({ type: 'setDeleteMassiveInfluencersFromListModalOpen', payload: { isOpen: true, selectedInfluencers } })
  }

  const closeDeleteMassiveInfluencersFromListModal = () => {
    dispatch({ type: 'setDeleteMassiveInfluencersFromListModalOpen', payload: { isOpen: false, selectedInfluencers: [] } })
  }

  const openRefreshOverlapModal = () => {
    dispatch({ type: 'setRefreshOverlapModalOpen', value: true })
  }

  const closeRefreshOverlapModal = () => {
    dispatch({ type: 'setRefreshOverlapModalOpen', value: false })
  }

  const setIsListOverlapErrorModalOpen = (value) => {
    dispatch({ type: 'setIsListOverlapErrorModalOpen', value })
  }

  const setIsListOverlapEmptyDataModalOpen = (value) => {
    dispatch({ type: 'setIsListOverlapEmptyDataModalOpen', value })
  }
  const setIsLoadingComputeOverlap = value => {
    dispatch({ type: 'setIsLoadingComputeOverlap', value })
  }

  const openLinkInfluencersToListModal = () => {
    dispatch({ type: 'setLinkInfluencersToListModalOpen', value: true })
  }

  const closeLinkInfluencersToListModal = () => {
    dispatch({ type: 'setLinkInfluencersToListModalOpen', value: false })
  }

  const openAddInfluencersToListModal = () => {
    dispatch({ type: 'setAddInfluencersToListModalOpen', value: true })
  }

  const closeAddInfluencersToListModal = () => {
    dispatch({ type: 'setAddInfluencersToListModalOpen', value: false })
  }

  const openLinkInfluencersToListsModal = (selectedInfluencers) => {
    dispatch({ type: 'setLinkInfluencersToListsModalOpen', payload: { isOpen: true, selectedInfluencers } })
  }

  const closeLinkInfluencersToListsModal = (selectedInfluencers = []) => {
    dispatch({ type: 'setLinkInfluencersToListsModalOpen', payload: { isOpen: false, selectedInfluencers } })
  }

  return (
    <ListContext.Provider value={{
      ...state,
      setList,
      setIsLoadingMetrics,
      openDeleteListModal,
      closeDeleteListModal,
      openShareModal,
      closeShareModal,
      setNewListModalOpen,
      setEditListModalOpen,
      setListEmptyAggregatedDataModalOpen,
      setListErrorModalOpen,
      openDeleteProfileFromListModal,
      closeDeleteProfileFromListModal,
      openDeleteInfluencerFromListModal,
      closeDeleteInfluencerFromListModal,
      openDeleteMassiveProfilesFromListModal,
      closeDeleteMassiveProfilesFromListModal,
      openDeleteMassiveInfluencersFromListModal,
      closeDeleteMassiveInfluencersFromListModal,
      openRefreshOverlapModal,
      closeRefreshOverlapModal,
      setIsListOverlapErrorModalOpen,
      setIsListOverlapEmptyDataModalOpen,
      setIsLoadingComputeOverlap,
      openLinkInfluencersToListModal,
      closeLinkInfluencersToListModal,
      openAddInfluencersToListModal,
      closeAddInfluencersToListModal,
      openLinkInfluencersToListsModal,
      closeLinkInfluencersToListsModal
    }}>
      {children}
    </ListContext.Provider>
  )
}
