import { gql } from '@apollo/client'

export const countProgramsV2ByIrmInfluencer = gql`
  query countProgramsV2ByIrmInfluencer(
    $influencerId: String!,
    $filters: [NewFilterParamsType]
  ) {
    countProgramsV2ByIrmInfluencer(
      influencerId: $influencerId,
      filters: $filters
    )
  }
`
