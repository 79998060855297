import { gql } from '@apollo/client'

export const getProgramsV2ByBrand = gql`
  query getProgramsV2ByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: ProgramV2SortParamsType
    $filters: [NewFilterParamsType]
    $view: String!
  ) {
    getProgramsV2ByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      id: _id
      brandId
      name
      description
      status
      rawStatus
      influencersCount
      irmInfluencers {
        _id
      }
      createdAt
      updatedAt
      createdByUser {
        firstName
      }
      updatedByUser {
        firstName
      }
    }
  }
`

