import { gql } from '@apollo/client'

export const updateInfluencerCampaignStatus = gql`
mutation updateInfluencerCampaignStatus(
  $influencerInCampaignIds: [String!]
  $campaignStatus: String!
  ) {
    updateInfluencerCampaignStatus(
      ids: $influencerInCampaignIds
      campaignStatus: $campaignStatus
    ){
      id: _id
      updatedAt
      campaignStatus
    }
  }
`
