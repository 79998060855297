import { useContext, useEffect, useRef, useState, lazy, Suspense } from 'react'
import { ThemeContext } from 'styled-components'
import { FormContext, useArray } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledQuill = lazy(() => import('./StyledQuill'))

export const Quill = ({ containerId, value, handleChange, quillStyles, readOnly }) => {
  const { t } = useTranslate()
  const { colors } = useContext(ThemeContext)
  const { setValue } = useContext(FormContext)
  const [hasMounted, setHasMounted] = useState(false)
  const [inlineAttachments, setInlineAttachments] = useArray([])
  const editor = useRef(null)

  const onAddImage = file => {
    setInlineAttachments(file, 'add')
  }

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (inlineAttachments.length) {
      setValue('InputText', 'inlineAttachments', inlineAttachments)
    }
  }, [inlineAttachments.length])

  if (!hasMounted) return null

  return (
    <Suspense fallback={<div />}>
      <StyledQuill
        theme='snow'
        placeholder={t('influencers.modal.composeMessage.input.message.placeholder')}
        bounds={containerId}
        ref={editor}
        value={value}
        onChange={handleChange}
        onAddImage={onAddImage}
        colors={colors}
        quillStyles={quillStyles}
        readOnly={readOnly}
      />
    </Suspense>
  )
}
