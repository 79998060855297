import { gql } from "@apollo/client";

export const deleteEntityViews = gql`
  mutation deleteEntityViews($entityViewIds: [String]!) {
    deleteEntityViews(entityViewIds: $entityViewIds) {
      message
      code
      success
    }
  }
`