import { css } from 'styled-components'
import { Flex, Text, Icon, Spacing } from 'influ-dms'

export const InputErrorText = ({ text = 'The info is required', size, color }) => {
  return (
    <Flex align='center'>
      <Icon name='danger' size={size || '13'} color='state.dangerDefault' />
      <Spacing vertical size='4' />
      <Text color={color || 'state.dangerDefault'}>{text}</Text>
    </Flex>
  )
}

export const inputErrorStyles = css`
border-color: ${props => props.theme.colors.state.dangerDefault};
&:hover, &:active, &:focus {
  border-color: ${props => props.theme.colors.state.dangerDefault};
}
`
