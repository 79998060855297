import { useContext } from 'react'
import styled from 'styled-components'
import { Button, Flex, FormContext } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin: 16px -32px 0 -32px;
  padding: 16px 32px 0 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export const Footer = ({ acceptText, handleAccept, handleCancel }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const isFormDirty = values.formDirty
  const isFormInvalid = Object.values(values.errors || {}).some(val => val)
  const isFormDisabled = isFormInvalid || !isFormDirty
  return (
    <StyledFlex>
      <Button onClick={handleAccept} disabled={isFormDisabled}>{acceptText}</Button>
      <StyledButton color='secondary' variant='flat' type='reset' onClick={handleCancel}>{t('commons.button.cancel')}</StyledButton>
    </StyledFlex>

  )
}
