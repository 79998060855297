import { gql } from '@apollo/client'

export const getGiftingCodesByInfluencerTable = gql`
  query getGiftingCodesByInfluencerTable($influencerInProgramId: String!, $sortParams: CodesSortParamsType) {
    getGiftingCodesByInfluencerTable(influencerInProgramId: $influencerInProgramId, sortParams: $sortParams) {
      url
      status
      rawStatus
      code
      _id
      influencerInProgramV2
    }
  }
`

