import { useEffect } from 'react'
import styled from 'styled-components'
import { Flex } from 'influ-dms'
import { AddPostsModalContentSelector } from './AddPostsModalContentSelector'
import { AddPostsAutomatic } from './automatic/AddPostsAutomatic'
import { AddPostsManual } from './manual/AddPostsManual'
/* TODO: uncomment if tracking needs to be restore */
// import { AddTracking } from './tracking/AddTracking'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  height: 100%;
`

const StyledContent = styled.div`
  height: calc(100% - 64px);
  flex: 3;
  overflow-x: hidden;
`

export const AddPostsModalContent = ({
  isOpen,
  error,
  setError,
  selectedSection,
  setSelectedSection,
  handleClose
}) => {
  const { t } = useTranslate()

  useEffect(() => {
    setSelectedSection(isOpen ? selectedSection : -1)
  }, [isOpen])

  return (
    <StyledFlex>
      <AddPostsModalContentSelector
        title={t('reports.report.modal.addPublication.sidebar.title')}
        sections={[
          t('reports.report.modal.addPublication.sidebar.dataSource.automaticAnalysis.link'),
          t('reports.report.modal.addPublication.sidebar.dataSource.manualAnalysis.link'),
          /* TODO: uncomment if tracking needs to be restore */
          // t('reports.report.modal.addPublication.sidebar.dataSource.tracking.button')
        ]}
        selectedSection={selectedSection}
        onChange={setSelectedSection}
        setError={setError}
      />
      <StyledContent>
        {selectedSection === 0 && <AddPostsAutomatic error={error} setError={setError} />}
        {selectedSection === 1 && <AddPostsManual />}
        {/* TODO: uncomment if tracking needs to be restore */}
        {/* {selectedSection === 2 && (
          <AddTracking handleClose={handleClose} error={error} setError={setError} />
        )} */}
      </StyledContent>
    </StyledFlex>
  )
}
