import { setUser, setTag, captureException } from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { self } from '../../graphql'
import { useEffect } from 'react'
import axios from 'axios'

export const SentryComponent = () => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathName = get(router, 'pathname', '')
  const isPublic = pathName.includes('public')
  const skip = !brandId || isPublic
  const { data } = useQuery(self, { skip })
  const userId = get(data, 'self.id')
  const email = get(data, 'self.email')
  let ip

  useEffect(() => {
    if (data || isPublic) {
      axios.get('https://jsonip.com/').then((ipResponse) => {
        ip = ipResponse?.data?.ip
      }).catch((err) => {
        captureException(err)
      }).finally(() => {
        setUser({ id: userId, email, username: email, ip })
        setTag('brandId', brandId)
      })
    }
  }, [data])
}
