import { useContext } from 'react'
import { Modal } from 'influ-dms'
import { EmailSetupRemoveInboxModalContent } from './EmailSetupRemoveInboxModalContent'
import { EmailSetupRemoveInboxModalFooter } from './EmailSetupRemoveInboxModalFooter'
import { useMutation } from '@apollo/client'
import { deleteCommunicationAccountMutation } from '../../../../../../../commons/communication/apollo/communicationMutations'
import { CommunicationContext } from '../../../../../../../commons/communication/CommunicationContext'
import { COMMUNICATION_ACTIONS } from '../../../../../../../commons/communication/CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const EmailSetupRemoveInboxModal = ({ emailAccount, isOpen, onClose }) => {
  const { t } = useTranslate()
  const { setCommunicationAction } = useContext(CommunicationContext)
  const [deleteCommunicationAccount, { loading }] = useMutation(deleteCommunicationAccountMutation)
  const handleRemove = () => {
    deleteCommunicationAccount()
      .then(() => {
        setCommunicationAction({ type: COMMUNICATION_ACTIONS.accountDeleted })
        onClose()
      })
      .catch(() => {
      })
  }
  return (
    <Modal
      isOpen={isOpen}
      title={t('settings.yourPreferences.communication.email.personalEmailBlock.emailBlock.button.remoInbox')}
      color='state.dangerDefault'
      height='auto'
      onClose={onClose}
    >
      <EmailSetupRemoveInboxModalContent emailAccount={emailAccount} />
      <EmailSetupRemoveInboxModalFooter loading={loading} handleRemove={handleRemove} handleClose={onClose} />
    </Modal>
  )
}
