export const networkTranslationKeys = {
  instagram: 'commons.socialNetwork.instagram.label',
  tiktok: 'commons.socialNetwork.tiktok.label',
  youtube: 'commons.socialNetwork.youtube.label',
  twitter: 'commons.socialNetwork.twitter.label',
  facebook: 'commons.socialNetwork.facebook.label',
  snapchat: 'commons.socialNetwork.snapchat.label',
  tumblr: 'commons.socialNetwork.tumblr.label',
  twitch: 'commons.socialNetwork.twitch.label',
  vk: 'commons.socialNetwork.vk.label',
  weibo: 'commons.socialNetwork.weibo.label',
  pinterest: 'commons.socialNetwork.pinterest.label',
  douyin: 'commons.socialNetwork.douyin.label'
}
