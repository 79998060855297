import { gql } from '@apollo/client'

export const notifyIrmListDataProcesssed = gql`
subscription notifyIrmListDataProcesssed($brandId: String!){
  notifyIrmListDataProcesssed(brandId: $brandId){
    brandId
    listId
    subscriptionStatus
  }
}
`