import { gql } from '@apollo/client'

export const addIrmList = gql`
mutation addIrmList(
  $brandId: String!
  $listName: String!
  $description: String
  $type: IRMListTypeEnumType!
  $object: IRMListObjectEnumType!
  $profileIds: [String]
  $influencerIds: [String]
) {
  addIrmList(
    brandId: $brandId
    listName: $listName
    description: $description
    type: $type
    object: $object
    profileIds: $profileIds
    influencerIds: $influencerIds
  ){
    message
    data
    success
  }
}
 `
