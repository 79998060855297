import { css } from 'styled-components'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const cellPadding = css`
  padding: 0 24px;
  ${cellEllipsis};
`

export const imagePostStyles = css`
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
  background-image: url("${({ image }) => image}"), url('/static/img/placeholders/placeholder.jpg');
  background-size: cover;
  background-position: center;
`

export const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

export const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`
