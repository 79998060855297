import { gql } from '@apollo/client'

export const getGiftingCodesByProgramId = gql`
  query getGiftingCodesByProgramId(
    $programId: String!
    $sortParams: CodesSortParamsType
    $page: Int
    $limit: Int
    $filters: [NewFilterParamsType]!
    $view: String!
  ) {
    getGiftingCodesByProgramId(
      programId: $programId
      sortParams: $sortParams
      page: $page
      limit: $limit
      filters: $filters
      view: $view
    ) {
      _id
      brandId
      programId
      benefit
      type
      code
      influencerCount
      url
      status
      rawStatus
      totalCodesUsed
      totalSales
      createdAt
      updatedAt
      createdByUser {
        firstName
      }
      updatedByUser {
        firstName
      }
      addedByUser {
        firstName
      }
    }
  }
`