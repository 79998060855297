import { gql } from "@apollo/client";

export const getPaymentPool = gql`
query getPaymentPool( 
  $_id: String!
) {
  getPaymentPool(_id: $_id) {
      _id
      brandId
      poolNumber
      status
      statusTranslated
      amount
      fee
      tax
      feePercentage
      taxPercentage
      total
      currency
      idProforma
      bankInfo {
        beneficiary
        iban
        bic
        intermediaryBic
        beneficiaryAddress
        bankPaymentInstitution
        bankPaymentInstitutionAddress
      }
      createdByUser {
        firstName
      }
      createdAt
      updatedAt
      error
    }
  }
`
