import styled, { css } from 'styled-components'
import { statusMap } from './ReportsPostsTable'
import { cellPadding } from '../../commons/reportsTable/styles'
import { Flex, Icon, Text, CellWrapper, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

/** SOME TRANSLATIONS ARE NOT FOUND IN FIGMA */
export const errorsMap = {
  INSTAGRAM_RESTRICTED_ERROR: 'reports.publications.status.theAccountIsPrivate.label',
  INSTAGRAM_RESTRICTED_POST_ERROR: 'reports.publications.status.theAccountIsPrivate.label',
  IS_PRIVATE_ERROR: 'reports.publications.status.theAccountIsPrivate.label',
  LESS_THAN_1000_FOLLOWERS_ERROR:
    'reports.publications.status.theAccountHasLessThanOneThousand.label',
  TIKTOK_NOT_FOUND_ERROR: 'reports.publications.status.thePublicationCouldNotBeFound.label',
  TIKTOK_INVALID_PROFILE_ERROR: 'reports.publications.status.theAccountDoesNotExists.label',
  SOCIALDATA_ERROR_ACCOUNT_NOT_FOUND: 'reports.publications.status.theAccountDoesNotExists.label',
  SOCIALDATA_ERROR_ACCOUNT_REMOVED: 'reports.publications.status.theAccountNolongerExists.label',
  SOCIALDATA_ERROR_NOT_FOUND: 'reports.publications.status.thePublicationCouldNotBeFound.label',
  NOT_FOUND_ERROR: 'reports.publications.status.thePublicationCouldNotBeFound.label',
  SOCIALDATA_ERROR_RETRY_LATER: 'reports.publications.status.socialData.RetryLater',
  FB_API_REJECTED_PERMISSION: 'reports.publications.status.fbApi.permissionRejected',
  FB_API_FAILED_REQUEST: 'reports.publications.status.fbApi.failedRequest',
  FB_API_UPDATE_TRACKING_POST_ERROR: 'reports.publications.status.fbApi.updateTrackingPostError',
  FB_API_USER_PROFILE_NOT_EXISTS: 'reports.publications.status.fbApi.profileDontExist',
  FB_API_USER_PROFILE_POST_NOT_EXISTS: 'reports.publications.status.fbApi.profilePostDontExist',
  FB_API_APP_SECRET_PROOF_ERROR: 'reports.publications.status.fbApi.permissionExpired',
  FB_API_INVALID_TOKEN: 'reports.publications.status.fbApi.permissionExpired',
  FB_API_INSUFFICIENT_PERMISSIONS_FB_PAGE:
    'reports.publications.status.fbApi.insufficientPermissionsFacebookPage', // TODO
  FB_API_PERSONAL_DATA_ACCOUNT_ERROR: 'reports.publications.status.fbApi.personalDataAccountError',
  FB_API_UNTRACE_ERROR: 'reports.publications.status.fbApi.untraceError',
  FB_API_NOT_ENOUGHT_VIEWERS: 'reports.publications.status.fbApi.notEnoughViewers',
  FB_API_DOES_NOT_EXIST: 'reports.publications.status.fbApi.postDontExist'
}

const IconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 4px;
`
const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

export const ReportsPostTdError = ({
  row: {
    original: { errorResponse }
  },
  value
}) => {
  const { t } = useTranslate()
  let errorTypeIcon = value === 'retry_later' ? 'warning' : ''
  let errorTypeColor = value === 'retry_later' ? 'state.warningDefault' : ''
  if (['retry_later', 'error'].includes(value)) {
    if (value === 'error') {
      errorTypeIcon = 'danger'
      errorTypeColor = 'state.dangerDefault'
    }
    if (errorResponse && errorsMap[errorResponse.codeKey]) {
      if (errorResponse.codeKey === 'SOCIALDATA_ERROR_RETRY_LATER') {
        /* value = errorResponse.estimatedTime
          ? t('reports.publications.status.socialData.RetryLater', {
            estimatedTime: moment.duration(errorResponse.estimatedTime, 'minutes').humanize()
          })
          : t('reports.publications.status.socialData.RetryLater.fewMinutes') */
        value = t('reports.publications.status.socialData.RetryLater.fewMinutes')
      } else {
        value = t(errorsMap[errorResponse.codeKey])
      }
    } else {
      value = statusMap(t)[value]
    }
  } else {
    value = statusMap(t)[value]
  }
  return (
    <CellWrapper>
      <Tooltip
        text={value}
        place='right'
        type='dark'
        color='neutral.white'
        containerStyles={widgetTooltipContainerStyles}
        styles={widgetTooltipStyles}
      >
        <Flex align='center' styles={cellPadding}>
          {errorTypeIcon && (
            <IconContainer>
              <Icon name={errorTypeIcon} color={errorTypeColor} />
            </IconContainer>
          )}
          <Text color={errorTypeColor || 'primary.dark'} size='13' ellipsis>
            {value}
          </Text>
        </Flex>
      </Tooltip>
    </CellWrapper>
  )
}
