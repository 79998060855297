import { gql } from '@apollo/client'

export const editReportV2Tracking = gql`
mutation editReportV2Tracking(
  $brandId: String!
  $reportV2Id: String!
  $trackingId: String!
  $name: String!
  $network: String!
  $hashtags: [String]
  $mentions: [String]
  $keywords: [String]
  $startDate: String!
  $endDate: String
  $influencersToAdd: [InfluencerInTrackingInputType]
  $influencersToDelete: [InfluencerInTrackingInputType]
  ){ editReportV2Tracking(
    brandId: $brandId
    reportV2Id: $reportV2Id
    trackingId: $trackingId
    name: $name
    network: $network
    hashtags: $hashtags
    mentions: $mentions
    keywords: $keywords
    startDate: $startDate
    endDate: $endDate    
    influencersToAdd: $influencersToAdd
    influencersToDelete: $influencersToDelete )
    {
      _id
      createdAt
      updatedAt
      brandId
      reportV2Id
      name
      network
      hashtags
      mentions
      keywords
      startDate
      endDate
      resumeDate
      status
      influencers {
        username
        network
        profilePicture
        followers
        profileUrl
        apiAccess
        analyzeProfile
      }
    }
  }
`
