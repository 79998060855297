import { useEffect, useState } from 'react'
import { Flex, Icon } from 'influ-dms'
import { css } from 'styled-components'
import { get } from 'lodash'

const videoPlayStyles = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  height: 40px;
  width: 50px;
  color: ${({ theme }) => theme.colors.neutral.white};
  padding: 20px;
`

export const PlayPause = ({
  items,
  galleryRef
}) => {
  const [isPlayingVideo, setIsPlayingVideo] = useState(false)
  const currentGalleryIndex = get(galleryRef, 'current.state.currentIndex', 0)
  const selectedItemGallery = items[currentGalleryIndex]

  useEffect(() => {
    setIsPlayingVideo(!selectedItemGallery?.vidRef?.current?.paused)
  }, [selectedItemGallery?.vidRef?.current?.paused])

  if (!selectedItemGallery || !selectedItemGallery.playIcon) return ''
  return (
    <Flex onClick={() => {
      if (!selectedItemGallery?.vidRef?.current?.paused) {
        selectedItemGallery.vidRef.current.pause()
        setIsPlayingVideo(false)
      } else {
        selectedItemGallery.vidRef.current.play()
        setIsPlayingVideo(true)
      }
    }}
    >
      {isPlayingVideo ? <Icon name='pause' styles={videoPlayStyles} />
        : <Icon name='videoplay' styles={videoPlayStyles} />}
    </Flex>
  )
}
