import { gql } from '@apollo/client'

export const updateInfluencerOrderAndStep = gql`
mutation updateInfluencerOrderAndStep(
  $campaignId: String!
  $influencerId: String!
  $stepKey: String!
  $order: Int!
  ) {
    updateInfluencerOrderAndStep(
      campaignId: $campaignId
      influencerId: $influencerId
      stepKey: $stepKey
      order: $order
    ){
      id: _id
    }
  }
`
