import { createContext, useState } from 'react'

export const CampaignContext = createContext({ value: {} })

export const CampaignContextProvider = (props) => {
  const [campaignAction, setCampaignAction] = useState()
  const value = { campaignAction, setCampaignAction }

  return (
    <CampaignContext.Provider value={value}>
      {props.children}
    </CampaignContext.Provider>
  )
}

export const CampaignContextConsumer = CampaignContext.consumer
