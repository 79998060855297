import styled from 'styled-components'
import { Text } from 'influ-dms'
import { Footer } from './Footer'
import { useTranslate } from '~/hooks/useTranslate'
import { bindLinksMap } from '~/hooks/useLink'
import { useEffect, useState } from 'react'
import { useLanguageContext } from '../../../../../context/Language'

const StyledText = styled(Text)`
  margin-bottom: 24px;
`

const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.light1};
  :hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`

export const OfficeTerms = ({ handleAccept, handleCancel }) => {
  const { t } = useTranslate()
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState('')
  const [outlookLink, setOutlookLink] = useState('')
  const { locale } = useLanguageContext()
  const linksMap = new Map([
    ['privacy_policy_link', setPrivacyPolicyLink],
    ['outlook_link', setOutlookLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])

  return (
    <>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.office.terms.paragraph1')}
      </StyledText>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.office.terms.paragraph2')}
      </StyledText>
      <StyledText>
        {t('settings.yourPreferences.communication.email.modal.connect.setupOption.office.terms.paragraph3')} <StyledLink href={outlookLink} target='_blank'>{t('commons.button.here')}</StyledLink>.
      </StyledText>
      <StyledText>{t('settings.yourPreferences.communication.email.modal.connect.setupOption.office.terms.paragraph4')} <StyledLink href={privacyPolicyLink} target='_blank'>{t('commons.button.privatePolicy')}</StyledLink>.</StyledText>

      <Footer acceptText={t('commons.button.acceptAndConnectToOutlook')} handleAccept={handleAccept} handleCancel={handleCancel} />
    </>
  )
}
