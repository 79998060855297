import { gql } from "@apollo/client";

export const irmInfluencerBySharedLink = gql`
  query irmInfluencerBySharedLink (
    $sharedId: String!
    $type: String
    $influencerId: String
    $locale: String
  ) {
    irmInfluencerBySharedLink(sharedId: $sharedId, type: $type, influencerId: $influencerId, locale: $locale) {
      _id
      alias
      firstName
      lastName
      picture
      brandLogo
      profiles {
        _id
        _type
        username
        picture
      }
      relatedProfiles {
        type
        value
        formatted_value
      }
      emails {
        value
        primary
        deleted
      }
      phoneNumbers {
        value
        primary
        deleted
      }
      irmAddresses {
        _id
        addressName
        country
        createdAt
        primary
        formattedAddress
      }
      emailSubscription
    }
  }
`
