import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles
} from './styles'

export const GroupsListTableTdGroupName = ({
  row: { original },
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslate()
  const group = original || {}
  const { groupName, entityFieldsCount, origin } = group
  const isDisabledEdit = origin === 'default' || origin === 'protected'
  const isDisabledDeleteInflu = origin === 'default' || origin === 'protected'
  const isDisabledDeleteSize = entityFieldsCount > 0
  const disabledDeleteTooltip = isDisabledDeleteInflu ? 'commons.button.delete.groupOfProperties.disable.defaultGroup.tooltip' : 'commons.button.delete.groupOfProperties.disable.groupWithProperties.tooltip'

  const handleEditClick = (e) => {
    if (!isDisabledEdit) {
      onEditClick(e)
    }
  }

  const handleDeleteClick = (e) => {
    if (!isDisabledDeleteInflu && !isDisabledDeleteSize) {
      onDeleteClick(e)
    }
  }

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis size='14' color='primary.light2' bold>
            {groupName}
          </Text>
        </StyledContainer>
        <HoverWrapper>
          {onEditClick && <Tooltip
            text={isDisabledEdit ? t('commons.button.edit.groupOfProperties.disable.tooltip') : t('commons.button.edit.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={handleEditClick} iconName='edit' disabledIcon={isDisabledEdit} />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={(isDisabledDeleteInflu || isDisabledDeleteSize) ? t(disabledDeleteTooltip) : t('commons.button.delete.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={handleDeleteClick} iconName='trash' disabledIcon={isDisabledDeleteInflu || isDisabledDeleteSize} />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
