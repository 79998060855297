import { gql } from '@apollo/client'

export const addProfilePrice = gql`
mutation addProfilePrice(
  $brandId: String
  $username: String!
  $network: String!
  $profileId: String!
  $photo: String
  $story: String
  $reel: String
  $igtv: String
  $carousel: String
  $video: String
  $videoMention: String
  $video15Seconds: String
  $video60Seconds: String
  $tweet: String
  $retweet: String
  $text: String
  $image: String
  $spotlight: String
  $post: String
  $preRoll: String
  $midRoll: String
  $endRoll: String
  $mention: String
  $dedicatedVideo: String
  $pin: String
) {
  addProfilePrice(
    brandId: $brandId
    username: $username
    network: $network
    profileId: $profileId
    photo: $photo
    story: $story
    reel: $reel
    igtv: $igtv
    carousel: $carousel
    video: $video
    videoMention: $videoMention
    video15Seconds: $video15Seconds
    video60Seconds: $video60Seconds
    tweet: $tweet
    retweet: $retweet
    text: $text
    image: $image
    spotlight: $spotlight
    post: $post
    preRoll: $preRoll
    midRoll: $midRoll
    endRoll: $endRoll
    mention: $mention
    dedicatedVideo: $dedicatedVideo
    pin: $pin
  ){
    success
    message
  }
}
 `

export const getTableProfilePrices = gql`
 query getProfilePriceHistory( $profileId: String! ) {
  getProfilePriceHistory(profileId: $profileId ){
        _id
        profileId
        currency{
          symbol
        }
        updatedAt
        createdByUser{
          userId
          firstName
          lastName
          profilePhoto
        }
        updatedByUser{
          userId
          firstName
          lastName
          profilePhoto
        }
        ... on InstagramProfilePrice{
          story
          photo
          carousel
          video
          reel
          igtv
        }
        ... on TiktokProfilePrice{
          video15Seconds
          video60Seconds
        }
        ... on YoutubeProfilePrice{
          video
          videoMention
        }
        ... on TwitterProfilePrice{
          tweet
          retweet
          photo
        }
        ... on FacebookProfilePrice{
          text
          image
          video
          story
        }
        ... on SnapchatProfilePrice{
          story
          spotlight
        }
        ... on TumblrProfilePrice{
          post
        }
        ... on TwitchProfilePrice{
          preRoll
          midRoll
          endRoll
          mention
          dedicatedVideo
        }
        ... on VkProfilePrice{
          text
          image
          video
          story
        }
        ... on WeiboProfilePrice{
          text
          story
          video
          image
        }
        ... on PinterestProfilePrice{
          pin
          image
        }
        ... on DouyinProfilePrice{
          video15Seconds
          video60Seconds
        }
}
}
`


export const deleteProfilePrices = gql`
mutation deleteProfilePrices(  $profileId: String!, $ids: [String]! ) {
  deleteProfilePrices( profileId: $profileId, ids: $ids ){
        success
        message
}
}
`