import { gql } from '@apollo/client'

export const getProfilesSummaryInList = gql`
    query getProfilesSummaryInList( $listId: String, $sharedId: String ) {
        getProfilesSummaryInList(listId: $listId, sharedId: $sharedId) {
            total,
            summary {
                _type
                count
            }
        }
    }
`
