import styled from 'styled-components'

export const Logo = styled.img`
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 4px;
  align-self: flex-start;
  padding: 0px 8px;
  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  }
`
