import { gql } from '@apollo/client'

export const getMasterTableCities = gql`
  query getMasterTableCities(
    $query: String!
    $type: MasterTableOptions!
    $locale: String
    $search: String
  ) {
    getMasterTableCities(
      query: $query
      type: $type
      locale: $locale
      search: $search
    ) {
        key
        label
        value
    }
  }
`
