import { useState } from 'react'
import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { BrandsSection } from './BrandsSection'
import { InputText, ModalPage } from 'influ-dms'
import { navBarProducts } from '../../constants'
import { RouterHelper } from '../../routing/routerHelper'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'
import { useQuery } from '@apollo/client'
import { brands } from '../../graphql/brands'

const brandsSearchTerm = 'brandsSearchTerm'

const InputContainer = styled.div`
  width: 350px;
`

const containerStyles = css`
  height: 96px;
  padding: 64px 32px;
`

const titleStyles = css`
  width: calc(100vw - 64px);
  max-width: 1369px;
`

const contentStyles = css`
  height: 100vh;
  overflow: hidden auto;
`

const MultiBrandModal = (props) => {
  const router = useRouter()
  const { isOpen, onClose } = props
  const [text, setText] = useState('')
  const { t } = useTranslate()

  const isPublic = router && router.pathname && router.pathname.includes('public')

  const { data: dataBrands } = useQuery(brands, { skip: isPublic })

  const renderInput = isOpen => {
    return (
      <InputContainer>
        <InputText
          name={brandsSearchTerm}
          placeholder={t('yourAccounts.input.accounts.placehoder')}
          value={text}
          autoFocus={isOpen}
          onChange={e => setText(e.target.value || '')}
        />
      </InputContainer>
    )
  }

  const onSettingsClick = (brandId, onClose) => {
    router.push(RouterHelper.getRoute('basicInfo', { brandId }))
    onClose()
  }

  const selfBrands = get(dataBrands, 'self.brands', [])

  return (
    <ModalPage
      name='your-brands'
      title={t('yourAccounts.title')}
      centerTitleNode={renderInput(isOpen)}
      onClose={onClose}
      isOpen={isOpen}
      containerStyles={containerStyles}
      contentStyles={contentStyles}
      titleStyles={titleStyles}
    >
      <BrandsSection
        products={navBarProducts && navBarProducts(t)}
        settingsClick={brandId => onSettingsClick(brandId, onClose)}
        onClose={onClose}
        data={selfBrands}
        text={text}
      />
    </ModalPage>
  )
}

export { MultiBrandModal }
