import { gql } from '@apollo/client'

export const getIrmInfluencersTableColumnsInProgramV2 = gql`
  query getIrmInfluencersTableColumnsInProgramV2 {
    getIrmInfluencersTableColumnsInProgramV2 {
        label
        fieldName
        columnType
        type
    }
  }
`
