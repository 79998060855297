import { useContext } from 'react'
import styled from 'styled-components'
import { Flex, FormContext, SideBox, Text } from 'influ-dms'
import { imagePostStyles } from '../../../reportsTable/styles'

const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  ${imagePostStyles};
  border-radius: 50%;
  margin-right: 6px;
`

export const EditPostModalContentAutomaticProfile = () => {
  const { values: { slug, profilePicture } } = useContext(FormContext)
  return (
    <SideBox>
      <Flex align='center'>
        <ImageContainer image={profilePicture} />
        <Text size='14' color='primary.dark' bold>{slug}</Text>
      </Flex>
    </SideBox>
  )
}
