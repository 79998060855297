import { gql } from '@apollo/client'

export const activateCoupon = gql`
  mutation activateCoupon(
    $programId: String!
    $couponIds: [String]!
  ) {
    activateCoupon(
      programId: $programId
      couponIds: $couponIds
    ){
        message
        code
        success
      }
  }
 `
