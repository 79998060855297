import { Button, PageInput } from 'influ-dms'
import { Container } from './styles'
// import { PageInput } from './PageInput'
import { Pages } from './Pages'
import { useTranslate } from '~/hooks/useTranslate'

const Pagination = (props) => {
  const { t } = useTranslate()
  const { canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage, pageIndex, styles } = props
  const visiblePages = getVisiblePages()

  return (
    <Container styles={styles}>
      <Button
        name='prevPage'
        variant='outline'
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        {`< ${t('commons.table.footerTable.button.previous')} `}
      </Button>

      <Pages
        visiblePages={visiblePages}
        activePage={pageIndex}
        handlePageClick={gotoPage}
      />
      <Button
        name='nextPage'
        variant='outline'
        onClick={nextPage}
        disabled={!canNextPage}
      >
        {`${t('commons.table.footerTable.button.next')} >`}
      </Button>
      <PageInput
        page={pageIndex}
        pages={pageCount}
        onChange={page => gotoPage(page - 1)}
      />
    </Container>
  )

  function filterPages (visiblePages, totalPages) {
    return visiblePages.filter(page => page <= totalPages)
  }

  function getVisiblePages () {
    const lesThan7Pages = pageCount < 9
    const positiveModule = pageIndex % 6 >= 0
    const pageOverLimit = pageIndex > 5
    const pageNotCloseToEnd = pageIndex + 3 < pageCount
    const pageCloseToEnd = pageIndex + 3 >= pageCount
    const isInTheMiddle = positiveModule && pageOverLimit && pageNotCloseToEnd
    const isInTheEnd = positiveModule && pageOverLimit && pageCloseToEnd

    if (lesThan7Pages) {
      return filterPages([1, 2, 3, 4, 5, 6, 7, 8], pageCount)
    }
    if (isInTheMiddle) {
      return [1, pageIndex - 2, pageIndex - 1, pageIndex, pageIndex + 1, pageIndex + 2, pageIndex + 3, pageCount]
    } else if (isInTheEnd) {
      return [1, pageCount - 6, pageCount - 5, pageCount - 4, pageCount - 3, pageCount - 2, pageCount - 1, pageCount]
    }
    return [1, 2, 3, 4, 5, 6, 7, pageCount]
  }
}

export { Pagination }
