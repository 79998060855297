import { useEffect, useState } from 'react'
import { get, isEmpty } from 'lodash'
import deepDiff from 'return-deep-diff'
import { useQuery } from '@apollo/client'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { contact } from '~/graphql/contact'
import { brand, brands } from '../../graphql/brands'
import { parseUser } from './format/intercomUser'
import { parseBrands } from './format/intercomBrand'
import { networkSummaryQuery } from '../reports/influencers/home/NetworkSummary/Query'
import { RouterHelper } from '../../routing/routerHelper'

const { publicRuntimeConfig } = getConfig()
const { intercomAppId/*, nodeEnv, intercomDebug */ } = publicRuntimeConfig

// 09/02/2023 Intercom is removed
const loadIntercom = null
// const loadIntercom = typeof window !== 'undefined' && intercomAppId &&
//   (nodeEnv === 'integration' ||
//     nodeEnv === 'staging' ||
//     nodeEnv === 'production' ||
//     intercomDebug === 'true')
// Intercom limits
const oneDotFiveMins = 90000
const thirtyMins = 1800000

let startTime = new Date().getTime()
let requests = 0
let currentUserData = {}
let newUserData = {}

const timerSetUser = async (userData) => {
  const lastTime = new Date().getTime()
  const time = lastTime - startTime
  if (time > thirtyMins) {
    // reset requests each 30 mins
    // console.info(new Date().toISOString().substr(11, 8) + 'intercom reset time')
    requests = 0
    startTime = lastTime
  }
  const newUserData = deepDiff(currentUserData, userData, true) || {}
  if (typeof window !== 'undefined' && requests * oneDotFiveMins <= time &&
    !isEmpty(newUserData) && window.Intercom) {
    // console.info(new Date().toISOString().substr(11, 8) + ' Intercom update', newUserData, userData)
    window.Intercom('update', userData)
    requests++
    currentUserData = JSON.parse(JSON.stringify(userData)) // newUserData ???
  }
}

const throwUpdate = () => {
  // console.info({ throwUpdate: new Date().toISOString().substr(11, 8), newUserData })
  if (!isEmpty(newUserData)) timerSetUser(newUserData)
}

if (loadIntercom) {
  setInterval(throwUpdate, oneDotFiveMins, newUserData)
}

let firstEntryRoute = true

const TrackingComponent = (props) => {
  const [user, setUser] = useState(false)
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const brandId = get(router, 'query.brandId')

  const skip = isPublic || !brandId
  const { data: dataContact, loading: loadingContact } = useQuery(contact, { skip })
  const { data: dataBrands, loading: loadingBrands } = useQuery(brands, { skip }) || {}
  const { data: dataBrand, loading: loadingBrand } = useQuery(brand, { variables: { brandId }, skip }) || {}
  const { data: dataSummary, loading: loadingSummary } = useQuery(networkSummaryQuery, { variables: { brandId }, skip }) || {}

  const userId = get(dataContact, 'self.id')
  const userHash = get(dataContact, 'self.intercomId')
  const userEmail = get(dataContact, 'self.email')
  const brandName = get(dataBrand, 'brand.brandName')

  const setHubspotUser = () => {
    const _hsq = window._hsq = window._hsq || []
    _hsq.push(['identify', {
      email: userEmail,
      company: brandName
    }])
  }

  useEffect(() => {
    if (typeof window !== 'undefined' &&
      userEmail && brandName &&
      !loadingBrand && !loadingContact) {
      setHubspotUser()
    }
  }, [dataContact, dataBrand, loadingBrand, loadingContact])

  useEffect(() => {
    if (typeof window !== 'undefined' && router?.isReady && firstEntryRoute) {
      const route = get(router, 'route')
      const params = get(router, 'query')
      RouterHelper.traceRoute(route, params)
      firstEntryRoute = false
    }
  }, [router])

  // load all data
  useEffect(() => {
    if (loadIntercom &&
      !loadingBrands && !loadingBrand && !loadingContact && !loadingSummary &&
      dataBrands && dataBrand && dataContact && dataSummary &&
      userId && userHash
    ) {
      newUserData = {
        app_id: intercomAppId,
        user_id: userId,
        user_hash: userHash,
        ...parseUser(dataContact),
        ...parseBrands(dataBrands, dataBrand, dataSummary, dataContact)
      }
      setUser(newUserData)
    }
  }, [dataBrands, dataBrand, dataContact, dataSummary,
    loadingBrands, loadingBrand, loadingContact, loadingSummary,
    userId, userHash])

  useEffect(() => {
    if (loadIntercom && user) timerSetUser(user)
  }, [user])

  if (typeof window !== 'undefined' && loadIntercom && user) {
    if (isEmpty(currentUserData)) {
      // console.info(new Date().toISOString().substr(11, 8) + ' intercom boot', user)
      currentUserData = user
      window.Intercom('boot', user)
    }
  }
  return <div />
}

export { TrackingComponent }
