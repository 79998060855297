import { gql } from '@apollo/client'

export const countAudienceCodeByInfluencerTable = gql`
    query countAudienceCodeByInfluencerTable(
      $influencerInProgramId: String!,
      $filters: [NewFilterParamsType],
    ) {
        countAudienceCodeByInfluencerTable(
        influencerInProgramId: $influencerInProgramId,
        filters: $filters,
      )
    }
`
