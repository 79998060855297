export const ProgramType = {
  ACTIVE: {
    name: 'active',
    text: 'commons.tag.activeProgram'
  }
}

export const TabsForOverview = {
  INFLUENCERS: 'influencers',
  PROFILES: 'profiles',
  CODES: 'codes',
  COMMUNICATIONS: 'communications'
}

export const ThirdPartyIntegrationError = {
  ECOMMERCE_SHOP_NOT_FOUND: 12810,
  ECOMMERCE_WRONG_API_KEY: 12811,
  ECOMMERCE_UNGRANTED_ACCESS_DENIED: 12812,
  ECOMMERCE_NOT_PII_GRANTED: 12823
}
