import styled from 'styled-components'
import { AvatarContactAllAccounts } from './AvatarContactAllAccounts'
import { AvatarContactAccount } from './AvatarContactAccount'

const ContainerDiv = styled.div`
  max-height: ${({ showGetBeamer }) => showGetBeamer ? '245px' : '209px'};
  overflow-y: auto;
`

export const AvatarContactDropdown = ({ brands, handleClick, showGetBeamer }) => {
  return (
    <>
      <AvatarContactAllAccounts />
      <ContainerDiv showGetBeamer={showGetBeamer}>
        {brands.map(({ id, brandName, trial }, i) => (
          <AvatarContactAccount key={id} id={id} brandName={brandName} trial={trial} brandsLength={brands.length} i={i} handleClick={handleClick} />
        ))}
      </ContainerDiv>
    </>
  )
}
