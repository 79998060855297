import { gql } from '@apollo/client'

export const updateInstagramVideoAutoMutation = gql`
  mutation updateInstagramVideoAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
    $likes: Int
  ) {
  updateInstagramVideoAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
    likes: $likes
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramVideoTrackingMutation = gql`
  mutation updateInstagramVideoTracking (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
  ) {
  updateInstagramVideoTracking (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramReelAutoMutation = gql`
  mutation updateInstagramReelAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramReelAdditionalMetricsInputType
    $cost: Float
    $likes: Int
  ) {
  updateInstagramReelAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
    likes: $likes
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramPhotoAutoMutation = gql`
  mutation updateInstagramPhotoAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
    $likes: Int
  ) {
  updateInstagramPhotoAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
    likes: $likes
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramPhotoTrackingMutation = gql`
  mutation updateInstagramPhotoTracking (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
  ) {
  updateInstagramPhotoTracking (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramCarouselAutoMutation = gql`
  mutation updateInstagramCarouselAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
    $likes: Int
  ) {
  updateInstagramCarouselAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
    likes: $likes
  ) {
      reportV2Id
    }
 }
`
export const updateInstagramCarouselTrackingMutation = gql`
  mutation updateInstagramCarouselTracking (
    $publicationInReportV2Id: String!
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
  ) {
    updateInstagramCarouselTracking (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateYoutubeVideoAutoMutation = gql`
  mutation updateYoutubeVideoAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: YoutubeVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateYoutubeVideoAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateYoutubeShortAutoMutation = gql`
  mutation updateYoutubeShortAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: YoutubeVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateYoutubeShortAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateTiktokVideoAutoMutation = gql`
  mutation updateTiktokVideoAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: TiktokVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateTiktokVideoAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateTiktokPhotoAutoMutation = gql`
  mutation updateTiktokPhotoAuto (
    $publicationInReportV2Id: String!
    $additionalMetrics: TiktokVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateTiktokPhotoAuto (
    publicationInReportV2Id: $publicationInReportV2Id
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
  }
`

export const updateInstagramStoryManualMutation = gql`
  mutation updateInstagramStoryManual (
    $publicationInReportV2Id: String!
    $url: String
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $likes: Int
    $stickers: [String]
    $swipeUrl: String
    $additionalMetrics: InstagramStoryAdditionalMetricsInputType
    $cost: Float
  ) {
  updateInstagramStoryManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    caption: $caption
    publicationDate: $publicationDate
    image: $image 
    deletedImages: $deletedImages
    likes: $likes
    stickers: $stickers
    swipeUrl: $swipeUrl
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`
export const updateInstagramStoryTrackingMutation = gql`
mutation updateInstagramStoryTracking (
  $publicationInReportV2Id: String!
  $likes: Int
  $additionalMetrics: InstagramStoryAdditionalMetricsInputType
  $cost: Float
) {
  updateInstagramStoryTracking (
  publicationInReportV2Id: $publicationInReportV2Id
  likes: $likes
  additionalMetrics: $additionalMetrics
  cost: $cost
) {
    reportV2Id
  }
}
`

export const updateInstagramVideoManualMutation = gql`
  mutation updateInstagramVideoManual (
    $publicationInReportV2Id: String!
    $url: String
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $likes: Int
    $comments: Int
    $videoViews: Int
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
  ) {
  updateInstagramVideoManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    deletedImages: $deletedImages
    likes: $likes
    comments: $comments
    videoViews: $videoViews
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramReelManualMutation = gql`
  mutation updateInstagramReelManual (
    $publicationInReportV2Id: String!
    $url: String
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $likes: Int
    $comments: Int
    $videoViews: Int
    $videoPlays: Int
    $additionalMetrics: InstagramReelAdditionalMetricsInputType
    $cost: Float
    $taggedUsers: [String]
  ) {
  updateInstagramReelManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    deletedImages: $deletedImages
    likes: $likes
    comments: $comments
    videoViews: $videoViews
    videoPlays: $videoPlays
    additionalMetrics: $additionalMetrics
    cost: $cost
    taggedUsers: $taggedUsers
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramPhotoManualMutation = gql`
  mutation updateInstagramPhotoManual (
    $publicationInReportV2Id: String!
    $url: String
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $likes: Int
    $comments: Int
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
  ) {
  updateInstagramPhotoManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    deletedImages: $deletedImages
    likes: $likes
    comments: $comments
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateInstagramCarouselManualMutation = gql`
  mutation updateInstagramCarouselManual (
    $publicationInReportV2Id: String!
    $url: String
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $likes: Int
    $comments: Int
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
    $cost: Float
  ) {
  updateInstagramCarouselManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    deletedImages: $deletedImages
    likes: $likes
    comments: $comments
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateYoutubeVideoManualMutation = gql`
  mutation updateYoutubeVideoManual (
    $publicationInReportV2Id: String!,
    $url: String,
    $caption: String,
    $publicationDate: String,
    $image: [Upload],
    $deletedImages: [String],
    $videoViews: Int,
    $likes: Int,
    $dislikes: Int,
    $comments: Int
    $additionalMetrics: YoutubeVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateYoutubeVideoManual (
    publicationInReportV2Id: $publicationInReportV2Id, 
    url: $url, 
    caption: $caption, 
    publicationDate: $publicationDate, 
    image: $image, 
    deletedImages: $deletedImages, 
    videoViews: $videoViews, 
    likes: $likes, 
    dislikes: $dislikes, 
    comments: $comments
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateYoutubeShortManualMutation = gql`
  mutation updateYoutubeShortManual (
    $publicationInReportV2Id: String!,
    $url: String,
    $caption: String,
    $publicationDate: String,
    $image: [Upload],
    $deletedImages: [String],
    $videoViews: Int,
    $likes: Int,
    $dislikes: Int,
    $comments: Int
    $additionalMetrics: YoutubeVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateYoutubeShortManual (
    publicationInReportV2Id: $publicationInReportV2Id, 
    url: $url, 
    caption: $caption, 
    publicationDate: $publicationDate, 
    image: $image, 
    deletedImages: $deletedImages, 
    videoViews: $videoViews, 
    likes: $likes, 
    dislikes: $dislikes, 
    comments: $comments
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateTiktokVideoManualMutation = gql`
  mutation updateTiktokVideoManual (
    $publicationInReportV2Id: String!
    $url: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $caption: String
    $videoViews: Int
    $likes: Int
    $comments: Int
    $shares: Int
    $saves: Int
    $additionalMetrics: TiktokVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateTiktokVideoManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    publicationDate: $publicationDate
    image: $image
    deletedImages: $deletedImages
    caption: $caption
    videoViews: $videoViews
    likes: $likes
    comments: $comments
    shares: $shares
    saves: $saves
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`

export const updateTiktokPhotoManualMutation = gql`
  mutation updateTiktokPhotoManual (
    $publicationInReportV2Id: String!
    $url: String
    $publicationDate: String
    $image: [Upload]
    $deletedImages: [String]
    $caption: String
    $videoViews: Int
    $likes: Int
    $comments: Int
    $shares: Int
    $saves: Int
    $additionalMetrics: TiktokVideoAdditionalMetricsInputType
    $cost: Float
  ) {
  updateTiktokPhotoManual (
    publicationInReportV2Id: $publicationInReportV2Id
    url: $url
    publicationDate: $publicationDate
    image: $image
    deletedImages: $deletedImages
    caption: $caption
    videoViews: $videoViews
    likes: $likes
    comments: $comments
    shares: $shares
    saves: $saves
    additionalMetrics: $additionalMetrics
    cost: $cost
  ) {
      reportV2Id
    }
 }
`