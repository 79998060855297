// import { List } from 'react-virtualized'
// import { get, isEmpty } from 'lodash'
// import { Flex, Icon, InfluencerListItem } from 'influ-dms'

export const AddFromMyInfluencersVirtualized = ({ filteredItems, activeItems, addToSelected }) => {
  // function rowRenderer ({
  //   index, // Index of row
  //   isScrolling, // The List is currently being scrolled
  //   isVisible, // This row is visible within the List (eg it is not an overscanned row)
  //   key, // Unique key within array of rendered rows
  //   parent, // Reference to the parent List (instance)
  //   style // Style object to be applied to row (to position it);
  //   // This must be passed through to the rendered row element.
  // }) {
  //   const src = get(filteredItems[index], 'profilePicture', 'no-img')
  //   const name = get(filteredItems[index], 'firstName')
  //   const lastName = get(filteredItems[index], 'lastName')
  //   const fullName = `${name} ${lastName || ''}`
  //   const username = get(filteredItems[index], 'username')
  //   const instaFollowers = get(filteredItems[index], 'followers') || 'W/ A'
  //   const isActive = isEmpty(activeItems) ? false : activeItems.find(profile => profile.username === username) !== undefined
  //   const iconName = isActive ? 'radiochecked' : 'addcircle'
  //   const iconColor = isActive ? 'state.active' : 'primary.light1'

  //   return (
  //     <Flex
  //       key={key}
  //       onClick={() => addToSelected(filteredItems[index])}
  //       shrink='0'
  //       align='center'
  //       styles={[{ cursor: 'pointer', ...style }]}
  //     >
  //       <>
  //         <span>
  //           <Icon
  //             name={iconName}
  //             color={iconColor}
  //             size='20'
  //           />
  //         </span>
  //         <InfluencerListItem
  //           src={src}
  //           title={username}
  //           subtitle={fullName}
  //           width={486}
  //           instagram={instaFollowers}
  //         />
  //       </>
  //     </Flex>
  //   )
  // }

  // TODO: We're commenting this code because react-virtualized has a security vulnerability
  // TODO: We will need to find a replacement for this library once we bring this project back to life
  return (
    <></>
    // <List
    //   width={520}
    //   height={320}
    //   rowCount={filteredItems.length}
    //   rowHeight={80}
    //   rowRenderer={(row) => rowRenderer(row)}
    // />
  )
}
