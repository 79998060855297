import { gql } from '@apollo/client'

export const tasksByDay = gql`
 query tasksByDay($brandId: String, $campaignId: String, $day: String!) {
  tasksByDay(brandId: $brandId, campaignId: $campaignId, day: $day ){
    id: _id
    influencerInCampaign{
      name
      imageUrl
    }
    campaign{
      name
    }
    network
    status
    date
    _type
  }
 }
 `
