import { Flex } from 'influ-dms'
import { get, isEmpty } from 'lodash'
import { createRef, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import styled from 'styled-components'
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import { PlayPause } from './PlayPauseGallery'

const StyledFlex = styled(Flex)`
  width: 300px;
  justify-content: center;
  margin-bottom: 12px;
  .image-gallery-bullets {
    position: relative;
    top: 10px;
    height: 16px;
  }
  .image-gallery-bullet {
    background: ${({ theme }) => theme.colors.primary.light3} !important;
    box-shadow: none;
    border: 0px !important;
    margin: 0px 5px;
    padding: 3px;
    &:hover{ 
      background: ${({ theme }) => theme.colors.primary.light3};
      border: 0px;
    }
  }
  .image-gallery-bullet.active {
    padding: 4px !important;
  }
  .image-gallery-right-nav {
    right: -30px;
    filter: none;
  }
  .image-gallery-left-nav {
    left: -30px;
    filter: none;
  }
  .image-gallery-svg {
    width: 12px;
    height: 22px;
  }
  .image-gallery-icon {
    color: ${({ theme }) => theme.colors.primary.light1};
  }
  .image-gallery-image {
    ${({ isEmptyState }) => isEmptyState && `
      width: 525px;
    `}
  }
`

export const PreviewCarrousel = ({ mediaUrls, type }) => {
  let isEmptyState = false

  if (isEmpty(mediaUrls)) {
    mediaUrls = ['/static/img/preview/EmptyState.png']
    isEmptyState = true
  }
  const galleryRef = useRef(null)
  const items = []

  const lineRefs = useRef([])
  for (const [index, media] of mediaUrls.entries()) {
    const vidRef = lineRefs.current[index] || createRef()
    items.push({
      original: media,
      playIcon: false,
      ...(media.includes('.mp4') && {
        renderItem: () => <video key={media} width='300px' ref={vidRef} loop src={media} />,
        vidRef,
        playIcon: true
      })
    })
  }

  return (
    <StyledFlex type={type} isEmptyState={isEmptyState}>
      <ImageGallery
        ref={galleryRef}
        items={items}
        showFullscreenButton={false}
        showBullets={get(items, 'length', 0) > 1}
        showThumbnails={false}
        showPlayButton
        autoPlay={false}
        renderPlayPauseButton={() => (
          <PlayPause
            items={items}
            galleryRef={galleryRef}
          />
        )}
      />
    </StyledFlex>
  )
}
