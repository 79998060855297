import { Flex, Icon, IconAction, Number, TableEditableTh, Text } from 'influ-dms'
import { useContext, useEffect, useState } from 'react'
import { SocialListeningContext } from '../../../context'
import { ALERT_STATUS } from '../../../constants/alertStatus'
import { ProcessingMentionsBar } from './styles'
import { useTranslate } from '../../../../../hooks'

export const AlertMentionsListTableEditableTh = (props) => {
  const { t } = useTranslate()
  const { alert } = useContext(SocialListeningContext)
  const [isBarOpen, setIsBarOpen] = useState(false)

  useEffect(() => {
    if (alert?.rawStatus === ALERT_STATUS.ACTIVE && alert?.processingMentions !== 0 && alert?.mentions !== 0) {
      setIsBarOpen(true)
    } else {
      setIsBarOpen(false)
    }
  }, [alert?.processingMentions])

  return (
    <>
      <TableEditableTh {...props}/>
      {isBarOpen && (
        <ProcessingMentionsBar>
          <Flex gap='4px'>
            <Icon color='neutral.white' name='refresh' size='12' />
            <Text color='neutral.white' size='12'>{t('alert.mentions.statusBar.processing.header', { count: <Number withSeparator>{alert?.processingMentions}</Number> })}</Text>
          </Flex>
          <IconAction name='close' size='12' onClick={() => setIsBarOpen(false)}/>
        </ProcessingMentionsBar>
      )}
    </>
  )
}
