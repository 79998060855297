import { createContext } from 'react'
import { get } from 'lodash'
import { useSubscription } from '@apollo/client'
import { useRouter } from 'next/router'
import { notifyIrmInfluencerDataProcesssed } from '~/containers/irm/apollo/notifyIrmInfluencerDataProcesssed'
import { useArray, USE_ARRAY_ACTIONS } from '~/hooks'

export const NotifyIrmInfluencerDataProcessedContext = createContext({ value: {} })

const NotifyIrmInfluencerDataProcessedProvider = (props) => {
  const [influencersDone, setInfluencersDone] = useArray([])

  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const isPublic = router?.pathname?.includes('public')

  useSubscription(notifyIrmInfluencerDataProcesssed, {
    variables: { brandId },
    skip: !brandId || isPublic,
    onData: ({ data: rawData }) => {
      const dataInfluencerProcessed = get(rawData, 'data')
      const influencerId = get(dataInfluencerProcessed, 'notifyIrmInfluencerDataProcesssed.influencerId')
      const subscriptionStatus = get(
        dataInfluencerProcessed,
        'notifyIrmInfluencerDataProcesssed.subscriptionStatus'
      )
      if (subscriptionStatus === 'profileInInfluencerUpdated') {
        setInfluencersDone(influencerId, USE_ARRAY_ACTIONS.ADD)
      }
    }
  })

  return (
    <NotifyIrmInfluencerDataProcessedContext.Provider
      value={{ influencersDone, setInfluencersDone }}
    >
      {props.children}
    </NotifyIrmInfluencerDataProcessedContext.Provider>
  )
}

export { NotifyIrmInfluencerDataProcessedProvider }
