import { gql } from '@apollo/client'

export const getOverlapDataFromList = gql`
  query getOverlapDataFromList ( 
    $listId: String
    $sharedId: String
    $locale: String
    $network: String!
  ) {
    getOverlapDataFromList(listId: $listId, sharedId: $sharedId, locale: $locale, network: $network) {
      id: _id
      brandId
      createdAt
      emptyAudienceErrorEstimatedMinutes
      emptyAudienceErrorStartsAt
      lastUpdatedOverlapData
      errorCode
      profiles {
        id: _id
        followers
        isVerified
        notFoundError
        overlappingPercentage
        profilePicture
        status
        uniquePercentage
        username
      }
      size
      status
      summary {
        totalFollowers
        totalUniqueFollowers
      }
      type
      updatedAt
    }
  }
`
