import { Spacing, Text } from 'influ-dms'
import { EmailSetupUpdatePasswordModalPassword } from './EmailSetupUpdatePasswordModalPassword'

export const EmailSetupUpdatePasswordModalContent = ({ email }) => {
  return (
    <>
      <Text size='14' bold color='primary.dark'>{email}</Text>
      <Spacing size='16' />
      <EmailSetupUpdatePasswordModalPassword />
      <Spacing size='24' />
    </>
  )
}
