import { setUser } from '@sentry/nextjs'
import { RouterHelper } from '../routing/routerHelper'
import userflow from 'userflow.js'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { userflowToken } = publicRuntimeConfig

const cleanLocalStorage = () => {
  window.localStorage.setItem('network_filters', '')
  window.localStorage.setItem('network_last_overview', '')
  window.localStorage.setItem('token', '')
}

const logout = (options) => {
  const { loginRedirection = true, errorCode } = options || {}
  setUser(null)

  if (userflowToken) userflow.reset()

  if (typeof window !== 'undefined') {
    document.cookie = 'token=; path=/'
    cleanLocalStorage()
    if (window.Intercom) {
      window.Intercom('shutdown')
    }
    if (loginRedirection) {
      const params = errorCode ? { errorCode } : undefined
      window.location.href = RouterHelper.getRoute('login', {}, '', params)
    }
  }
}

export default logout
