import { gql } from "@apollo/client";

export const getDomainsByBrand = gql`
  query getDomainsByBrand(
    $brandId: String!
    $domainType: String
  ) {
    getDomainsByBrand(brandId: $brandId, domainType: $domainType) {
      id: _id
      domainName
      type
      status
      createdAt
    }
  }
`
