import { gql } from '@apollo/client'

export const syncInfluencersInProgram = gql`
  mutation syncInfluencersInProgram($programId: String!, $influencerIds: [String]!) {
    syncInfluencers(programId: $programId, influencerIds: $influencerIds) {
      code
      message
      success
    }
  }
`
