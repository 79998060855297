import { get } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

/**
 * It returns the value of the cookie with the given name
 * @param name - The name of the cookie you want to get.
 * @returns The value of the cookie with the name passed in.
 */
export const getCookie = (name) => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop().split(';').shift()
}

/**
 * This function sets a cookie with a name, value, and longevity in days.
 * @param cookieNake - The name of the cookie.
 * @param cookieValue - The value of the cookie.
 * @param cookieDaysLongevity - The number of days you want the cookie to last.
 */
export const setCookie = (cookieNake, cookieValue, cookieDaysLongevity = 1) => {
  const DAYS_TO_MILLISECONDS = 24 * 60 * 60 * 1000
  const today = new Date()
  today.setTime(today.getTime() + Number(cookieDaysLongevity) * DAYS_TO_MILLISECONDS)
  const expires = 'expires=' + today.toUTCString()
  document.cookie = cookieNake + '=' + cookieValue + ';' + expires + ';path=/'
}

/**
 * It sets the analyticsId in localStorage if it's not already set
 * @param userData - The user data object that is returned from the API.
 */
export const setAnalyticsId = (userData) => {
  const analyticsId = get(userData, 'self.analyticsId')
  const idAnalyticsInStorage = localStorage.getItem('idAnalytics')
  const cookieGa = getCookie('_ga')
  const uid = uuidv4()

  const id = analyticsId || idAnalyticsInStorage || cookieGa || uid

  localStorage.setItem('idAnalytics', id)

  if (!cookieGa) {
    setCookie('_ga', id, 400)
  }
}
