// ./apollo-client.js
import getConfig from 'next/config'
import fetch from 'isomorphic-unfetch'
import { split, from, ApolloClient } from '@apollo/client'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { getMainDefinition } from '@apollo/client/utilities'
import { InMemoryCache } from '@apollo/client/cache'
import setHeadersMiddleware from './setHeadersMiddleware'
import afterware from './afterware'
import { get } from 'lodash'
import cookiesParser from '../helpers/cookiesParser'
import { createUploadLink } from 'apollo-upload-client'

const { publicRuntimeConfig } = getConfig()

const cache = new InMemoryCache({
  // dataIdFromObject: o => o.id,
  addTypename: true,
  cacheRedirects: {},
  possibleTypes: {
    PostDataType: ['TwitterPostData', 'InstagramPostData', 'YoutubePostData'],
    SocialNetworkMetrics: ['YoutubeMetrics', 'TwitterMetrics', 'InstagramMetrics', 'TiktokMetrics']
  },
  typePolicies: {
    IrmListMetricsType: {
      keyFields: [ '_id', 'network' ]
    },
    IrmInfluencerInProgramTableType: {
      keyFields: [ '_id', 'programId' ]
    },
    GiftingCouponV2ByInfluencerType: {
      keyFields: [ '_id', 'influencerInProgramV2' ]
    },
    CouponV2AudienceByInfluencerType: {
      keyFields: [ '_id', 'influencerInProgramV2' ]
    }
  }
})

const uploadLink = createUploadLink({
  uri: publicRuntimeConfig.graphqlUri,
  credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  fetch
})

const wsLink = typeof window !== 'undefined' ? new GraphQLWsLink(createClient({
  url: publicRuntimeConfig.graphqlWSUri,
  connectionParams: () => {
    const cookies = cookiesParser(document)
    const token = get(cookies, 'token', null)
    return { token }
  }
})) : null

const linkWithMiddlewares = typeof window !== 'undefined'
  ? from([
    setHeadersMiddleware,
    split( // only create the split in the browser
    // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === 'OperationDefinition' && operation === 'subscription'
      },
      wsLink,
      uploadLink
    )
  ])
  : from([
    setHeadersMiddleware,
    uploadLink
  ])

  const client = new ApolloClient({
    // connectToDevTools: typeof window !== 'undefined',
    // ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: afterware.concat(linkWithMiddlewares),
    cache,
  })

  export default client
