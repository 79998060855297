import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import getConfig from 'next/config'
import { useTranslate } from '~/hooks/useTranslate'
import { useBrandData } from '~/hooks'
import { useQuery } from '@apollo/client'
import { contact } from '../../graphql/contact'
import { brands } from '../../graphql/brands'
import { RouterHelper } from '../../routing/routerHelper'
import { homePage, navBarProducts } from '../../constants'
import { getActiveProduct, getPermissions } from '../../helpers'
import { NavbarContent } from './NavbarContent'

const { publicRuntimeConfig } = getConfig()
const brandLogo = publicRuntimeConfig.brandImage

export const NavbarContainer = () => {
  const { t } = useTranslate()
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const brandId = get(router, 'query.brandId')
  const skip = isPublic || !brandId
  const { data: dataContact } = useQuery(contact, { skip })
  const { data: dataBrands } = useQuery(brands, { skip: isPublic })
  const { data: dataBrand, isEnterprise, isTrial, products } = useBrandData({ variables: { brandId }, skip })

  const permissions = useMemo(() => getPermissions(products) || {}, [products])

  const contactFirstName = get(dataContact, 'self.contact.firstName')
  const contactLastName = get(dataContact, 'self.contact.lastName')
  const contactEmail = get(dataContact, 'self.email')
  const contactImage = get(dataContact, 'self.contact.profilePhoto')
  const brandsArray = get(dataBrands, 'self.brands', [])
  const brandName = get(dataBrand, 'brand.brandName')
  const brandImage = get(dataBrand, 'brand.logoImage')

  const onProductClick = (product) => {
    let matchingProduct = homePage[product]
    if (homePage[product] === 'reports') matchingProduct = 'reportsV2'
    if (homePage[product] === 'communication') matchingProduct = 'outreaches'
    const route = RouterHelper.getRoute(matchingProduct, { brandId })
    router.push(route)
  }

  const handleSettingsClick = (brandId) => {
    router.push(RouterHelper.getRoute('brandProfile', { brandId }))
  }

  const upgradePlan = () => {
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }

  const logoClick = () => {
    router.push(RouterHelper.getRoute('home', { brandId }))
  }

  return (
    <NavbarContent
      products={navBarProducts(t)}
      logo={brandLogo}
      logoClick={logoClick}
      permissions={permissions}
      onProductClick={onProductClick}
      brandName={brandName}
      brands={brandsArray}
      brandId={brandId}
      brandImg={brandImage}
      contactFirstName={contactFirstName}
      contactLastName={contactLastName}
      contactEmail={contactEmail}
      contactImg={contactImage}
      settingsClick={handleSettingsClick}
      activeProduct={getActiveProduct(router.route)}
      handleUpgrade={upgradePlan}
      isTrial={isTrial}
      showPricesAndPlans={!isEnterprise}
    />
  )
}
