const isValidSocialUrl = (t, url, fieldName) => {
  const isTwitterField = fieldName.includes('twitter')
  const isInstagramField = fieldName.includes('instagram')
  const isYoutubeField = fieldName.includes('youtube')
  const isTiktokField = fieldName.includes('tiktok')

  const notInstagramUrl = !/instagram((?!\/p\/).)*$/.test(url)
  const notTiktokUrl = !/tiktok.*@/.test(url)
  const notYoutubeUrl = !/youtube.*\/.*$/.test(url)
  const notTwitterUrl = !/twitter((?!\/status\/).)*$/.test(url)

  if (isTwitterField && notTwitterUrl) return ({ success: false, error: t('influencers.modal.addSocialNetwork.socialNetwork.input.twitter.helpertext.error.notAValidLink') })

  if (isInstagramField && notInstagramUrl) return ({ success: false, error: t('influencers.modal.addSocialNetwork.socialNetwork.input.instagram.helpertext.error.notAValidLink') })

  if (isYoutubeField && notYoutubeUrl) return ({ success: false, error: t('influencers.modal.addSocialNetwork.socialNetwork.input.youtube.helpertext.error.notAValidLink') })

  if (isTiktokField && notTiktokUrl) return ({ success: false, error: t('influencers.modal.addSocialNetwork.socialNetwork.input.tiktok.helpertext.error.notAValidLink') })

  return ({ success: true })
}

const urlRegExp = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}\/[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|(www\.)?[a-zA-Z0-9]\.[^\s]{2,}\/[^\s]{2,}/

const isUrl = url => urlRegExp.test(url)

export { isValidSocialUrl, isUrl }
