import { gql } from '@apollo/client'

export const getApplicationFormFragment = gql`
  fragment ApplicationFormFragment on ApplicationFormType {
    _id
    model
    formId
    label
    translationKey {
      onCreate
      onEdit
    }
    graphqlEndpoints {
      onCreate
      onEdit
    }
    inputGroups {
      order
      id
      translationKey
      subTitleTranslationKey
      helperTextTranslationKey
      optionalAnchor {
        text
        anchor
        icon
        translatedAnchorLinks
      }
      custom
      disabled
      origin
      conditional {
        type
        fieldId
        value
        notNull
      }
      inputs {
        order
        id
        name
        inputLabel
        translationKey
        disabled
        disabledTooltipTranslationKey
        placeholder
        placeholderTranslationKey
        primaryOption
        type
        frontendValidations {
          name
          format
        }
        autocompleteWith
        autocompleteWithOptions
        withSeparationLine
        separationLineBeforeInput
        separationLineAfterInput
        showErrorBeforeHelperText
        attrs
        value
        helperText
        infoTextTranslationKey
        infoParagraphsTranslationKeys
        helperTextTranslationKey
        conditional {
          type
          fieldId
          value
          notNull
        }
        origin
      }
    }
  }
`
