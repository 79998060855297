import { AvatarContactItem } from '../AvatarContactItem'
import { Text } from 'influ-dms'
import { useRouter } from 'next/router'
import { RouterHelper } from '../../../../../routing/routerHelper'
import { get } from 'lodash'
import { bottomPositionStyles, topPositionStyles } from '../styles'
import userflow from 'userflow.js'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { userflowToken } = publicRuntimeConfig

const findRedirection = (push, path = '', brandId, id) => {
  window.localStorage.setItem('brand_active', id)
  if (path.includes('/search/')) {
    push(RouterHelper.getRoute('search', { brandId: id }))
  } else if (path.includes('/campaignsV1/')) {
    push(RouterHelper.getRoute('campaignsV1', { brandId: id }))
  } else if (path.includes('/reports/')) {
    push(RouterHelper.getRoute('reportsV2', { brandId: id }))
  } else if (path.includes('/settings/')) {
    push(path.replace(brandId, id))
  } else if (path.includes('/payment/')) {
    push(RouterHelper.getRoute('pricesAndPlans', { brandId: id }))
  } else {
    push(RouterHelper.getRoute('home', { brandId: id }))
  }
}
export const AvatarContactAccount = ({ id, brandName, trial, brandsLength, i, handleClick }) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const isInPage = id === brandId
  const onClick = e => {
    if (!isInPage) {
      const isTrial = get(trial, 'isTrial')
      localStorage.setItem('brand-id', id)
      localStorage.setItem('is-trial', isTrial)
      if (userflowToken) userflow.updateUser({ brand_id: id, is_trial: isTrial })

      handleClick(e)
      findRedirection(router.push, router.asPath, brandId, id)
    }
  }
  return (
    <AvatarContactItem
      key={id}
      isInPage={isInPage}
      styles={i === 0 ? topPositionStyles : i === brandsLength - 1 ? bottomPositionStyles : undefined}
      handleClick={onClick}
    >
      <Text color='primary.light2' size='14'>{brandName}</Text>
    </AvatarContactItem>
  )
}
