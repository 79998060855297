import { createContext, useReducer } from 'react'

const initialValues = {
  pool: undefined,
  isPoolDetailModalOpen: {
    isOpen: false,
    id: ''
  }
}

export const PoolContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setPool':
      return { ...state, pool: action.payload }
    case 'setPoolDetailModalOpen':
      return { ...state, isPoolDetailModalOpen: action.payload }
  }
}

export const PoolProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setPool = (pool) => {
    dispatch({ type: 'setPool', payload: pool })
  }

  const setPoolDetailModalOpen = (isOpen, id) => {
    dispatch({ type: 'setPoolDetailModalOpen', payload: { isOpen, id } })
  }

  return (
    <PoolContext.Provider value={{
      ...state,
      setPool,
      setPoolDetailModalOpen
    }}>
      {children}
    </PoolContext.Provider>
  )
}
