import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles,
  clickableStyles
} from './styles'

export const TemplatesListTableTdTemplateName = ({
  row: { original },
  onNameClick,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
  loading,
  disableDuplicate
}) => {
  const { t } = useTranslate()
  const template = original || {}
  const { name } = template

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis showTooltip size='14' color='primary.light2' bold onClick={onNameClick} styles={clickableStyles}>
            {name}
          </Text>
        </StyledContainer>
        <HoverWrapper>
          {onEditClick && <Tooltip
            text={t('entity.template.tool.edit.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onEditClick} iconName='edit' disabledIcon={loading} />
          </Tooltip>}
          {onDuplicateClick && <Tooltip
            text={t(disableDuplicate ? 'modal.newEmailTemplate.limitReached.titleHeader1' : 'entity.template.tool.duplicate.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onDuplicateClick} iconName='duplicate' disabledIcon={loading || disableDuplicate} />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={t('entity.template.tool.delete.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onDeleteClick} iconName='trash' disabledIcon={loading} />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
