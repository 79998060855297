import { gql } from '@apollo/client'

export const moveInfluencersToStep = gql`
mutation moveInfluencersToStep(
  $campaignId: String!
  $influencerIds: [String]!
  $stepKey: String!
  ) {
    moveInfluencersToStep(
      campaignId: $campaignId
      influencerIds: $influencerIds
      stepKey: $stepKey
    ){
        message
        success
    }
  }
`
