import styled, { css } from 'styled-components'
import { Flex, Icon, Text } from 'influ-dms'
import { imagePostStyles } from '../../../reportsTable/styles'
import { useTranslate } from '~/hooks/useTranslate'

const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  ${imagePostStyles};
  border-radius: 50%;
`
const textStyles = css`
  border-left: 1px solid ${({ theme }) => theme.colors.primary.light3};
  padding-left: 3px;
  margin-left: 3px;
`

export const EditInfluencerModalContentHeaderProfile = ({ profilePicture, slug, network, numberOfPosts }) => {
  const { t } = useTranslate()
  return (
    <Flex align='center'>
      <ImageContainer image={profilePicture} />
      <div>
        <Text color='primary.dark' size='14' bold>{slug}</Text>
        <Flex align='center'>
          <Icon name={network} color='primary.light3' />
          <Text size='14' color='primary.light3' styles={textStyles}>{t('reports.report.modal.profile.publications.label', { numberOfPosts })}</Text>
        </Flex>
      </div>
    </Flex>
  )
}
