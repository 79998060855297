import { gql } from '@apollo/client'

export const getTableProfilesByBrand = gql`
 query getTableProfilesByBrand( $brandId: String!, $sortParams: ProfileTableSortParamsType, $page: Int, $limit: Int, $filters: [NewFilterParamsType], $view: String! ) {
  getTableProfilesByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view ){
    _id
    irmInfluencers {
      _id
      alias
      picture
    }
    irmLists {
      _id
    }
    updatedAt
    createdAt
    lastRefreshDate
    brandId
    network
    username
    language {
        key
        value
        label
    }
    city {
        key
        value
        label
    }
    country {
        key
        value
        label
    }
    state {
        key
        value
        label
    }
    locationLabel
    profilePhotoUrl
    profilePhotoUrlOriginal
    analyzeProfile
    private
    postNumber
    isVerified
    accountType{
        key
        value
        label
    }
    status{
        key
        value
        label
    }
    engagement
    followersQuality
    likersQuality
    noAudienceDataAvailable
    avgLikes
    avgViews
    avgDislikes
    avgComments
    totalLikes
    totalViews
    avgReelsPlays
    avgInteractions
    followerCount
    gender {
        key
        value
        label
    }
    age {
        key
        value
        label
    }
    audienceTopBrandAffinity {
        key
        value
        label
    }
    audienceTopLanguage {
        key
        value
        label
    }
    likersTopLanguage {
        key
        value
        label
    }
    _id
    audienceTopGender {
        key
        value
        label
    }
    likersTopGender {
        key
        value
        label
    }
    audienceTopCountry {
        key
        value
        label
    }
    likersTopCountry {
        key
        value
        label
    }
    audienceTopCity {
        key
        value
        label
    }
    likersTopCity {
        key
        value
        label
    }
    audienceTopState {
        key
        value
        label
    }
    audienceTopAge {
        key
        value
        label
    }
    likersTopAge {
        key
        value
        label
    }
    audienceTopInterest {
        key
        value
        label
    }
    likersTopInterest {
        key
        value
        label
    }
    audienceTopEthnicity {
        key
        value
        label
    }
    likersTopEthnicity {
        key
        value
        label
    }
    audienceTopBrandAffinity {
        key
        value
        label
    }
    likersTopBrandAffinity {
        key
        value
        label
    }
    streamChannelType {
        key
        value
        label
    }
    streamTopCategory {
        key
        value
        label
    }
    streamHoursWatched
    streamPeakViewers
    streamAirtime
    streamPartnershipStatus {
        key
        value
        label
    }
    createdByUser{
        userId
        firstName
        lastName
        profilePhoto
      }
      updatedByUser{
        userId
        firstName
        lastName
        profilePhoto
      }
      originalSource{
        key
        label
      }
}
}
`
