import { CellWrapper, Flex, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import styled, { css } from 'styled-components'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const StyledContainer = styled(Flex)`
  padding: 0 24px;
  ${cellEllipsis};
`

const StyledFlex = styled(Flex)`
  width : 97%;
`

const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

export const OutreachRecipientActionsListTableTdActionName = ({
  row: { original },
  onGoToURL
}) => {
  const { t } = useTranslate()
  const { type, clickLink } = original
  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer column>
          <Text size='12' color='primary.dark'>{type}</Text>
          {clickLink &&
          <Tooltip
            text={clickLink}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}>
            <Text size='10' color='primary.light3'>{clickLink}</Text>
          </Tooltip>
          }
        </StyledContainer>
        {clickLink &&
          <HoverWrapper>
            <Tooltip
              text={t('entity.influencer.tool.openURL.tooltip')}
              place='bottom'
              type='dark'
              color='neutral.white'
              containerStyles={widgetTooltipContainerStyles}
              styles={widgetTooltipStyles}>
              <HoverAction onClick={onGoToURL} iconName='opennewwindow' />
            </Tooltip>
          </HoverWrapper>
        }
      </StyledFlex>
    </CellWrapper>
  )
}
