import { AddPostsManualPostInfo } from './subForms/AddPostsManualPostInfo'
import { AddPostsManualInstagramPostMetrics } from './subForms/AddPostsManualInstagramPostMetrics'
import { AddPostsManualInstagramPostAdditionalMetrics } from './subForms/AddPostsManualInstagramPostAdditionalMetrics'
import { AddPostsManualPostCost } from './subForms/AddPostsManualPostCost'

export const AddPostsManualInstagramPhoto = () => {
  return (
    <>
      <AddPostsManualPostInfo />
      <AddPostsManualInstagramPostMetrics />
      <AddPostsManualInstagramPostAdditionalMetrics />
      <AddPostsManualPostCost />
    </>
  )
}
