import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Flex, Form, Icon, RoundedButton, SideModal, Tag, Text } from 'influ-dms'
import { AddTracking } from './AddTracking'
import { EditTrackingModalFooter } from './EditTrackingModalFooter'
import { get, isEqual } from 'lodash'
import { statusData } from '../../../posts/dataSource/ReportsTrackingCard'
import { ReportsTrackingModalDelete } from '../../../posts/dataSource/ReportsTrackingModalDelete'
import { ReportsTrackingModalLimit } from '../../../posts/dataSource/ReportsTrackingModalLimit'
import { useTranslate } from '~/hooks/useTranslate'

const modalContentStyles = css`
  padding: 0;
`

const StyledContent = styled.div`
  height: calc(100% - 64px);
  flex: 2;
  overflow: auto;
`
const HeaderFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.neutral.white};
  align-items: center;
  padding: 8px 32px 8px 24px;
  height: 66px;
`
const VerticalLine = styled('span')`
  border-left: 1px solid ${({ theme }) => theme.colors.primary.dark};
  margin: 0px 6px;
  height: 17px;
`
const tagStyles = css`
  padding: 2px 6px;
  height: 16px;
  font-size: 10px;
  text-color: ${({ theme }) => theme.colors.neutral.white};
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  margin-top: 2px;
`

export const EditTrackingModal = ({ onClose, isEditTrackingOpen, trackingData }) => {
  const { t } = useTranslate()
  const [error, setError] = useState()
  const [editTrackingData, setEditTrackingData] = useState(trackingData)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [isOpenModalLimit, setIsOpenModalLimit] = useState(false)
  const status = get(trackingData, 'status')
  const statusColor = get(statusData[status], 'color')
  const statusLabel = get(statusData[status], 'label')
  const scrollRef = useRef()

  useEffect(() => {
    scrollRef?.current?.scrollTo(0, 0) // scrollTo({ top: 0, behavior: 'smooth' })
  }, [isEditTrackingOpen])

  useEffect(() => {
    if (!isEqual(trackingData, editTrackingData)) {
      setEditTrackingData(trackingData)
    }
  }, [trackingData])

  return (
    <Form>
      <SideModal
        name='editTracking'
        onClose={onClose}
        isOpen={isEditTrackingOpen}
        title={t('reports.modal.editTracking.header')}
        width='670'
        contentStyles={modalContentStyles}
      >
        <HeaderFlex>
          <Flex column>
            <Flex justify='center' align='center'>
              <Icon name={get(trackingData, 'network', '')} size='14' />
              <VerticalLine />
              <Text bold size='14' color='primary.dark'>{get(trackingData, 'name', '')}</Text>
            </Flex>
            <Flex>
              <Tag color={statusColor} styles={[tagStyles]}>{t(statusLabel)}</Tag>
            </Flex>
          </Flex>
          <Flex column justify='center' align='center'>
            <RoundedButton name='trash' onClick={() => setIsOpenModalDelete(true)} />
            <Text size='10' color='primary.light2'>{t('commons.button.delete')}</Text>
          </Flex>
        </HeaderFlex>
        <StyledContent ref={scrollRef}>
          <AddTracking handleClose={onClose} error={error} setError={setError} trackingData={trackingData} editing />
        </StyledContent>
        <EditTrackingModalFooter handleClose={onClose} error={error} setError={setError} trackingData={editTrackingData} setIsOpenModalLimit={setIsOpenModalLimit} />
      </SideModal>
      <ReportsTrackingModalDelete
        isOpen={isOpenModalDelete}
        setIsOpenModal={setIsOpenModalDelete}
        onClose={(cancel) => { setIsOpenModalDelete(false); !cancel && onClose() }}
        trackingId={trackingData && trackingData._id}
      />
      <ReportsTrackingModalLimit
        isOpen={isOpenModalLimit}
        onClose={() => { setIsOpenModalLimit(false); onClose() }}
      />
    </Form>)
}
