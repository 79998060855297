import { AddPostsManualTiktokAdditionalMetrics } from '../../../../addPosts/manual/form/subForms/AddPostsManualTiktokAdditionalMetrics'
import { AddPostsManualPostCost } from '../../../../addPosts/manual/form/subForms/AddPostsManualPostCost'
import { AddPostsManualTiktokCountriesAndCities } from '../../../../addPosts/manual/form/subForms/AddPostsManualTiktokCountriesAndCities'

export const AddPostsManualTiktokVideo = ({ tooltipText }) => (
  <>
    <AddPostsManualTiktokAdditionalMetrics tooltipText={tooltipText} />
    <AddPostsManualTiktokCountriesAndCities />
    <AddPostsManualPostCost />
  </>
)
