import { gql } from '@apollo/client'

export const getProgramV2TableColumns = gql`
  query getProgramV2TableColumns($brandId: String!) {
    getProgramV2TableColumns(brandId: $brandId) {
        label,
        fieldName,
        columnType,
        type
    }
  }
`
