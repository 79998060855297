import styled from 'styled-components'

const ContainerDiv = styled.div`
  ${({ styles }) => styles || ''};
`

const StyledDiv = styled.div`
  padding: 8px;
  border-radius: 4px;
  ${({ withIcon }) => withIcon ? 'display: flex; flex-direction: row; align-items: center; gap: 8px;' : ''}
  ${({ withHover, theme }) => withHover ? `
  cursor: pointer;
  :hover {
    background: ${theme.colors.primary.light5};
  }
  ` : ''};
`

export const AvatarDropdownOptionItem = (props) => {
  const { withHover, withIcon, styles, handleClick, children } = props
  const onClick = e => {
    if (withHover) {
      handleClick(e)
    }
  }
  return (
    <ContainerDiv styles={styles}>
      <StyledDiv withHover={withHover} withIcon={withIcon} onClick={onClick} {...props}>
        {children}
      </StyledDiv>
    </ContainerDiv>

  )
}
