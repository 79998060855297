import { useContext } from 'react'
import { FormContext } from 'influ-dms'

import { AddPostsManualInstagramCarousel } from './AddPostsManualInstagramCarousel'
import { AddPostsManualInstagramStory } from './AddPostsManualInstagramStory'
import { AddPostsManualTiktokVideo } from './AddPostsManualTiktokVideo'
import { AddPostsManualYoutubeVideo } from './AddPostsManualYoutubeVideo'
import { AddPostsManualInstagramPhoto } from './AddPostsManualInstagramPhoto'
import { AddPostsManualInstagramVideo } from './AddPostsManualInstagramVideo'
import { AddPostsManualInstagramReel } from './AddPostsManualInstagramReel'
import { getForm } from '../../addPostsHelper'
import { AddPostsManualCheckAudienceData } from './AddPostsManualCheckAudienceData'
import { get } from 'lodash'
import { RefreshRemainingFeatureFlagsContext } from '../../../../../../context/RefreshRemainingFeatureFlags'

export const AddPostsManualFormOptions = ({ manualInfoChecked, isEdit }) => {
  const { values } = useContext(FormContext)
  const formName = manualInfoChecked ? getForm(values) : undefined
  const { remainingUrlsPerPeriodReportsV2 } = useContext(RefreshRemainingFeatureFlagsContext)
  const canAdd = values => remainingUrlsPerPeriodReportsV2 === null || remainingUrlsPerPeriodReportsV2 >= get(values, 'urls.length', 1)

  if (!formName) return null
  return (
    <>
      {formName !== 'instagram-story' && <AddPostsManualCheckAudienceData disabled={isEdit || !canAdd(values)} />}
      {formName === 'instagram-photo' && <AddPostsManualInstagramPhoto />}
      {formName === 'instagram-carousel' && <AddPostsManualInstagramCarousel />}
      {formName === 'instagram-video' && <AddPostsManualInstagramVideo />}
      {formName === 'instagram-reel' && <AddPostsManualInstagramReel />}
      {formName === 'instagram-story' && <AddPostsManualInstagramStory />}
      {(formName === 'tiktok-video' || formName === 'tiktok-photo') && <AddPostsManualTiktokVideo multiplePhotos={formName === 'tiktok-photo'} />}
      {(formName === 'youtube-video' || formName === 'youtube-short') && <AddPostsManualYoutubeVideo />}
    </>
  )
}
