import { gql } from '@apollo/client'

export const addTask = gql`
 mutation addTask(
  $brandId: String!
  $campaignId: String!
  $influencerInCampaignId: String!
  $price: Float
  $network: String
  $date: String
  $_type: String
  $username: String
 ) {
  addTask(
    brandId: $brandId
    campaignId : $campaignId
    influencerInCampaignId: $influencerInCampaignId
    price: $price
    network: $network
    date: $date
    _type: $_type
    username: $username
   ){
      id: _id
      brandId
      campaignId 
      influencerInCampaignId
      price
      network
      date
      _type
    }
 }
 `
