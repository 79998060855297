import { useContext, useEffect, useState } from 'react'
import { Form, Modal } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { ThemeContext } from 'styled-components'
import { get } from 'lodash'
import { enableCommunicationAccountMutation } from '../../../../../../../commons/communication/apollo/communicationMutations'
import { CommunicationContext } from '../../../../../../../commons/communication/CommunicationContext'
import { EmailSetupUpdatePasswordModalPassword } from '../updatePassword/EmailSetupUpdatePasswordModalPassword'
import { EmailSetupUpdatePasswordModalFooter } from '../updatePassword/EmailSetupUpdatePasswordModalFooter'
import { ConnectError } from '../../../../../../../commons/communication/setup/forms/result/ConnectError'
import { ConnectSuccess } from '../../../../../../../commons/communication/setup/forms/result/ConnectSuccess'
import { COMMUNICATION_ACTIONS } from '../../../../../../../commons/communication/CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const EmailSetupEnableInboxModal = ({ isOpen, onClose }) => {
  const { t } = useTranslate()
  const [step, setStep] = useState(0)
  const { colors: { gradients } } = useContext(ThemeContext)
  const { setCommunicationAction } = useContext(CommunicationContext)
  const [enableCommunicationAccount, { loading }] = useMutation(enableCommunicationAccountMutation)

  useEffect(() => {
    if (!isOpen) {
      const timeoutId = setTimeout(() => {
        setStep(0)
      }, 500)
      return () => { clearTimeout(timeoutId) }
    }
  }, [isOpen])

  const handleErrorRetry = () => {
    setStep(0)
  }

  const handleEnable = values => {
    enableCommunicationAccount({ variables: values })
      .then(response => {
        if (get(response, 'data.enableCommunicationAccount.success')) {
          setCommunicationAction({ type: COMMUNICATION_ACTIONS.accountEnabled })
          setStep(1)
        } else {
          setStep(2)
        }
      })
      .catch(() => {
        setStep(2)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('settings.yourPreferences.communication.email.configuration.modal.enableInbox.button.enableInbox')}
      height='auto'
      gradient1={gradients[1][0]}
      gradient2={gradients[1][1]}
      onClose={onClose}
    >
      {step === 0 && (
        <Form>
          <EmailSetupUpdatePasswordModalPassword />
          <EmailSetupUpdatePasswordModalFooter loading={loading} saveText={t('commons.button.enableIt')} handleSave={handleEnable} handleClose={onClose} />
        </Form>
      )}
      {step === 1 && <ConnectSuccess handleClose={onClose} />}
      {step === 2 && <ConnectError handleRetry={handleErrorRetry} />}
    </Modal>
  )
}
