import { Icon, Text } from 'influ-dms'
import { IconContainer, HelperTextContainer } from '../styles'

export const ErrorHelperText = ({ errorText }) => {
  return (
    <HelperTextContainer flexSpace='1' marginOnTop={'5'}>
      <IconContainer>
        <Icon name='danger' size='10' />
      </IconContainer>
      <Text color='inherit'>{errorText}</Text>
    </HelperTextContainer>
  )
}
