import { gql } from "@apollo/client";

export const getIRMListsByProfile = gql`
  query getIRMListsByProfile(
    $profileId: String!
    $sortParams: IrmListSortParamsType
    $page: Int
    $limit: Int
    $view: String!
  ) {
    getIRMListsByProfile(profileId: $profileId, sortParams: $sortParams, page: $page, limit: $limit, view: $view) {
      id: _id
      brandId
      type
      size
      listName
      updatedAt
      createdAt
      translations {
        es {
          object
          type
        }
        en {
          object
          type
        }
        fr {
          object
          type
        }
        de {
          object
          type
        }
        it {
          object
          type
        }
        pt {
          object
          type
        }
      }
    }
  }
`
