import { useContext } from 'react'
import {
  Flex,
  Spacing,
  Text,
  Modal,
  Image
} from 'influ-dms'
import { LimitButton } from './LimitButton'
import { ThemeContext } from 'styled-components'
import { useTranslate } from '~/hooks/useTranslate'

const LimitModal = (props) => {
  const { colors: { gradients } } = useContext(ThemeContext)
  const { t } = useTranslate()

  const {
    isOpen,
    onClose,
    limit,
    modalTitle,
    title,
    subtitle,
    onModalUnmount
  } = props
  const src = '/static/img/limitReached.png'

  return (
    <Modal
      isOpen={isOpen}
      height='550'
      onClose={onClose}
      title={modalTitle || t('commons.modals.limitReached.header')}
      zIndex='105'
      gradient1={gradients[1][0]}
      gradient2={gradients[1][1]}
      onModalUnmount={onModalUnmount}
    >
      <Flex column align='center' grow='1'>
        <Image width='250' src={src} alt='light sign' />
        <Spacing size='32' />
        <Text center bold size='16'>
          {title || t('commons.modals.blocked.titleHeader1.youHaveReachedTheLimitFor', { limit })}
        </Text>
        <Spacing size='8' />
        <LimitButton
          onClose={onClose}
          subtitle={subtitle || t('commons.modals.blocked.titleParagraph1.upgradeYourPlanToUseThisFeature')}
        />
      </Flex>
    </Modal>
  )
}

export { LimitModal }
