import { gql } from '@apollo/client'

const fragments = {
  contact: gql`
    fragment Contact on Contact {
      id: _id
      userId
      socialUsername
      profilePhoto
      firstName
      lastName
      phoneNumber
      location
      jobPosition
      hubspotData
      hubspotSteps
    }
  `,
  reportMin: gql`
    fragment ReportMin on Report {
      id: _id
      brandId
      name
      publications{
        _id
      }
    }
  `,
  report: gql`
    fragment Report on Report {
      id: _id
      _type
      name
      influencers
      networks
      posts
      earnedMedia
      engagement
      startDate
      endDate
      remainingUrlsPerReport
      paymentInfo{
        paid
        transactionId
      }
    }
  `,
  influencer: gql`
    fragment Influencer on Influencer {
        id: _id
        firstName
        lastName
        locations
        profilePictures
        socialNetworkProfiles{
           _type
           url
           username
          data{
            profileData{
              username
              follower_count
            }
          }
        }
        tags{
          audienceSkills
          influencerSkills
        }
    }
  `,
  publication: gql`
    fragment Publication on Publication {
      id: _id
      reportId
      _type
      url
      failReason
      status
      createdAt
      postData{
        ... on TwitterPostData{
          owner_username
          publication_timestamp
        }
        ... on InstagramPostData{
          owner_username
          publication_timestamp
        }
        ... on YoutubePostData{
          channelId
          channelName
          publication_timestamp
        }
        ... on TiktokPostData{
          owner_username
          publication_timestamp
        }
      }
    }
  `
}

export const influencerMetrics = gql`
  fragment InfluencerMetrics on InfluencerMetrics {
      noAudienceDataAvailable
      ages{
        calculated
        averageAgeRange
        agesGraph{
          range
          percentage
        }
      }
      networkData{
        username
        type
        url
      }
    influenceGraph{
      calculated
      graph{
        audience
        percentage
      }
    }
    geo{
      calculated
      topCitiesList{
        cityName
        percentage
      }
      topCountriesList{
        countryName
        percentage
      }
    }
    genderGraph{
      calculated
      genderGraph{
        gender
        percentage
      }
      dominantGender{
        gender
        percentage
      }
    }
    totalKPIs{
      calculated
      totals{
        totalInteractions
        totalReach
        totalPosts
        earnedMedia
        engagement
        realFollowersPercentage
      }
        networks{
          instagram{
            totalInteractions
            totalReach
            totalPosts
            earnedMedia
            engagement
            realFollowersPercentage
          }
          youtube{
            totalInteractions
            totalReach
            totalPosts
            earnedMedia
            engagement
            realFollowersPercentage
          }
          twitter{
            totalInteractions
            totalReach
            totalPosts
            earnedMedia
            engagement
            realFollowersPercentage
          }
        }
      }
  }
`

export const influencerMetricsWrapper = gql`
  fragment InfluencerMetricsWrapper on InfluencerMetricsWrapper {
    totalMetrics{
      ...InfluencerMetrics
    }
    byNetworkMetrics{
      ...InfluencerMetrics
    }
  }
  ${influencerMetrics}
  `

export default fragments
