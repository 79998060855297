import { EditPostModalContentAutomaticProfile } from './EditPostModalContentAutomaticProfile'
import { EditPostModalContentAutomaticPost } from './EditPostModalContentAutomaticPost'
import { EditPostModalContentAutomaticMetrics } from './EditPostModalContentAutomaticMetrics'
import { EditPostsModalContentAutomaticFormOptions } from './EditPostsModalContentAutomaticFormOptions'

export const EditPostModalContentAutomatic = () => (
  <>
    <EditPostModalContentAutomaticProfile />
    <EditPostModalContentAutomaticPost />
    <EditPostModalContentAutomaticMetrics />
    <EditPostsModalContentAutomaticFormOptions />
  </>
)
