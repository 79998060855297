import { useEffect } from 'react'
import router, { withRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { brand } from '../graphql/brands'
import { get, isEmpty } from 'lodash'
import { RouterHelper } from '../routing/routerHelper'
import { Loader, Flex } from 'influ-dms'
import { contact } from '~/graphql/contact'
import { useLanguageContext } from '../context/Language'
import getConfig from 'next/config'
import userflow from 'userflow.js'
import { Layout } from '../containers'
const { publicRuntimeConfig } = getConfig()
const { userflowToken } = publicRuntimeConfig

const BlockPlatform = (props) => {
  const { locale } = useLanguageContext()
  const { children } = props
  const brandId = get(props, 'router.query.brandId')
  const pathname = get(props, 'router.pathname', '')
  const isBigdrsHealth = pathname.includes('bigdrsHealth')
  const isPublic = pathname.includes('public')
  const skip = !brandId || isPublic

  const { data: brandData, loading: brandLoading } = useQuery(brand, { variables: { brandId }, skip })
  const { data: contactData, loading: contactLoading } = useQuery(contact, { skip })

  const isLoading = brandLoading || contactLoading || (!brandData && !skip) || (!contactData && !skip)
  const products = get(brandData, 'brand.products') || []

  useEffect(() => {
    if (!isEmpty(brandData)) {
      const brandId = get(brandData, 'brand.id')
      const isTrial = get(brandData, 'brand.trial.isTrial')
      const isEnterprise = get(brandData, 'brand.subscriptionInfo.isEnterprise')
      const brandCreatedAt = get(brandData, 'brand.createdAt')
      const subscriptionCreatedAt = get(brandData, 'brand.subscriptionInfo.createdAt')
      const subscriptionStatus = get(brandData, 'brand.subscriptionInfo.status')
      const subscriptionMrr = get(brandData, 'brand.subscriptionInfo.mrr')
      const mrr = isNaN(subscriptionMrr) ? get(brandData, 'brand.mrr') : subscriptionMrr

      localStorage.setItem('brand-id', brandId)
      localStorage.setItem('is-trial', isTrial)
      localStorage.setItem('is-enterprise', isEnterprise === 'true' || isEnterprise === true)
      localStorage.setItem('brand-created-at', brandCreatedAt)
      localStorage.setItem('subscription-created-at', subscriptionCreatedAt)
      localStorage.setItem('subscription-status', subscriptionStatus)
      if (mrr || mrr === 0) localStorage.setItem('rev', mrr)
      else localStorage.removeItem('rev')
    } else if (!brandLoading && brandId) {
      window.location.href = RouterHelper.getRoute('index', {}, '')
    }
  }, [brandData, brandLoading])

  useEffect(() => {
    if (!isEmpty(contactData)) {
      const userId = get(contactData, 'self.id')
      const userCreatedAt = get(contactData, 'self.createdAt')
      const userFirstLogin = get(contactData, 'self.firstLoginAt')
      localStorage.setItem('user-id', userId)
      localStorage.setItem('user-created-at', userCreatedAt)
      localStorage.setItem('user-first-login-at', userFirstLogin)
    }
  }, [contactData, contactLoading])

  useEffect(() => {
    if (!isEmpty(brandData) && !isEmpty(contactData) && !isEmpty(userflowToken)) {
      const isTrial = get(brandData, 'brand.trial.isTrial')
      const attrs = { brand_id: brandId, is_trial: isTrial }
      if (userflow.isIdentified()) {
        userflow.updateUser(attrs)
      } else {
        const userId = get(contactData, 'self.id')
        const email = get(contactData, 'self.email')
        const firstLoginAt = get(contactData, 'self.firstLoginAt')
        const firstName = get(contactData, 'self.contact.firstName')
        const lastName = get(contactData, 'self.contact.lastName')
        const name = firstName + (lastName ? ' ' + lastName : '')
        const host = window?.location?.host
        const environment = host?.split('.')[0]
        const identifyAttrs = {
          name,
          email,
          signed_up_at: firstLoginAt,
          locale_code: locale,
          environment,
          ...attrs
        }
        userflow.identify(userId, identifyAttrs)
      }
    }
  }, [brandData, contactData])

  function checkPermissionsAndRedirect () {
    const isSettings = pathname.includes('settings')
    const browser = typeof window !== 'undefined'
    if (isBigdrsHealth || isPublic || isLoading || isSettings || !browser) return

    const isCampaignRoute = pathname.includes('campaigns')
    const isIRMRoute = pathname.includes('irm')
    const isCommunication = pathname.includes('communication')
    const isReportsV2Route = pathname.includes('reports')
    const isDiscoverRoute = pathname.includes('search')
    const isHomeRoute = pathname.includes('home')
    const isPayments = pathname.includes('payments')
    const isSocialListening = pathname.includes('socialListening')
    const isSocialRoute = pathname.includes('/social/')
    const isProductRoute = isCampaignRoute || isIRMRoute || isReportsV2Route || isDiscoverRoute || isHomeRoute || isCommunication || isSocialRoute || isPayments || isSocialListening
    const noSubscription = pathname.includes('noSubscription')
    if (!isProductRoute || noSubscription) return

    const subscriptionStatus = get(brandData, 'brand.subscriptionInfo.status')
    const planExpired = subscriptionStatus !== 'active' && subscriptionStatus !== 'past_due'
    const emptyProducts = products.length <= 0
    const hasPermissions = isHomeRoute || hasProductPermissions()
    // const isEnterprise = get(brandData, 'brand.subscriptionInfo.isEnterprise')
    // const subscriptionMrr = get(brandData, 'brand.subscriptionInfo.mrr')
    // const noMrrReason = get(brandData, 'brand.noMrrReason')
    // const mrr = isNaN(subscriptionMrr) ? get(brandData, 'brand.mrr') : subscriptionMrr
    // const enterpriseWithoutMrr = isEnterprise && (!(mrr > 0) || isEmpty(noMrrReason))
    if (planExpired || emptyProducts || !hasPermissions) { //  || enterpriseWithoutMrr
      if (brandId) router.push(RouterHelper.getRoute('noSubscription', { brandId }))
      return true
    }
  }

  function hasProductPermissions () {
    const hasPermissions = products.reduce((acc, next) => {
      const product = next
        .replace('V1', '')
        .replace('V2', '')
        .replace('socialMedia', '/social/')
      if (pathname.includes(product)) return true
      if (product === 'irm' && pathname.includes('communication')) return true
      if (product === 'campaigns' && pathname.includes('payments')) return true
      return acc
    }, false)
    return hasPermissions
  }
  if (isLoading || checkPermissionsAndRedirect()) {
    return (
      <Layout>
        <Flex styles='height: 100vh' center>
          <Loader name='loading-block-platform'/>
        </Flex>
      </Layout>
    )
  } else {
    return children
  }
}

const BlockPlatformWR = withRouter(BlockPlatform)

export { BlockPlatformWR as BlockPlatform }
