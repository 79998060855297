import { createContext, useState } from 'react'
import { get } from 'lodash'
import { useSubscription } from '@apollo/client'
import { useRouter } from 'next/router'
import { notifyMentionsProcessed } from '../apollo/notifyMentionsProcessed'
import { useCleanCache } from '../../irm/commons/hooks'

export const NotifyAlertDataProcessedContext = createContext({ value: {} })

const NotifyAlertDataProcessedProvider = (props) => {
  const router = useRouter()
  const { brandId, alertId } = get(router, 'query', {})
  const [alertToRefreshId, setAlertToRefreshId] = useState(null)
  const [timestamp, setTimestamp] = useState(null)
  const { clearAlertDetailCache, clearAlertTableCache, clearAlertMentionsTableCache } = useCleanCache()

  useSubscription(notifyMentionsProcessed, {
    variables: { brandId },
    skip: !brandId,
    onData: ({ data: rawData }) => {
      const processedAlertData = get(rawData, 'data')
      const processedAlertId = get(processedAlertData, 'notifyMentionsProcessed.alertId')
      setAlertToRefreshId(processedAlertId)
      setTimestamp(new Date().getTime())
      clearAlertTableCache()

      if (processedAlertId !== alertId && processedAlertId) {
        clearAlertDetailCache(processedAlertId)
        clearAlertMentionsTableCache(processedAlertId)
        setAlertToRefreshId(null)
      }
    }
  })

  return (
    <NotifyAlertDataProcessedContext.Provider
      value={{ alertToRefreshId, setAlertToRefreshId, timestamp }}
    >
      {props.children}
    </NotifyAlertDataProcessedContext.Provider>
  )
}

export { NotifyAlertDataProcessedProvider }
