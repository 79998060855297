import { gql } from '@apollo/client'

export const createAudienceBenefitCode = gql`
  mutation createAudienceBenefitCode(
    $programId: String!
    $influencerInProgramIds: [String]!
    $seedCode: SeedCodeInputObjectType!
  ) {
    createAudienceBenefitCode(
      programId: $programId
      influencerInProgramIds: $influencerInProgramIds
      seedCode: $seedCode
    ){
        message
        code
        success
      }
  }
 `
