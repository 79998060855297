import { AddPostsManualPostInfo } from './subForms/AddPostsManualPostInfo'
import { AddPostsManualYoutubeMetrics } from './subForms/AddPostsManualYoutubeMetrics'
import { AddPostsManualYoutubeAdditionalMetrics } from './subForms/AddPostsManualYoutubeAdditionalMetrics'
import { AddPostsManualPostCost } from './subForms/AddPostsManualPostCost'

export const AddPostsManualYoutubeVideo = () => {
  return (
    <>
      <AddPostsManualPostInfo />
      <AddPostsManualYoutubeMetrics />
      <AddPostsManualYoutubeAdditionalMetrics />
      <AddPostsManualPostCost />
    </>
  )
}
