import { gql } from "@apollo/client";

export const getPaymentInvoicesByBrand = gql`
  query getPaymentInvoicesByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: PaymentInvoiceSortParamsType, 
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getPaymentInvoicesByBrand(
      brandId: $brandId,
      sortParams: $sortParams,
      page: $page,
      limit: $limit,
      filters: $filters,
      view: $view
    ) {
      _id
      brandId
      name
      currency
      description
      orderNumber
      statusTranslated
      status
      amount
      fee
      tax
      total
      paymentsCount
      invoiceNumber
      clientGenerationDate
      createdByUser {
        firstName
      }
      createdAt
      updatedAt
      billingEmail
    }
  }
`
