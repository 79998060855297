import { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { Flex, Text, CellWrapper, Button, Icon, Tooltip } from 'influ-dms'
import { ModalsContext } from '../../../commons/context'
import { useTranslate } from '~/hooks/useTranslate'
import { cellPadding, imagePostStyles } from '../../commons/reportsTable/styles'

const StyledContainer = styled(Flex)`
  cursor: pointer;
  :hover{
    text-decoration: underline;
  }
`
const ImageContainer = styled.div`
  width: 32px;
  height: 32px;
  ${imagePostStyles};
`
const buttonStyles = css`
  margin-left: 4px;
  border-radius: 4px;
  width: 32px;
  padding: 0px;
  color: ${({ theme }) => theme.colors.primary.light5} !important;
  background: ${({ theme }) => theme.colors.primary.light5} !important;
`

const flexStyles = css`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${cellPadding}
`

export const ReportsPostTdPost = ({ row: { original: { _id: postId, image, username } }, value, valueMap }) => {
  const { t } = useTranslate()
  const urlImage = get(image, '[0]')
  const { dispatch } = useContext(ModalsContext)
  const [showButton, setShowButton] = useState(false)

  const openEditPost = () => {
    dispatch({ type: 'openEditPost', payload: postId })
  }

  const openPreviewPost = () => {
    dispatch({ type: 'openPreviewPost', payload: postId })
  }

  return (
    <CellWrapper>
      <Flex styles={flexStyles} onMouseOver={() => (!showButton && setShowButton(true))} onMouseLeave={() => setShowButton(false)}>
        <Tooltip place='top' text={t('reports.publicationAndData.publications.table.cell.publicationType.tooltip')} type='dark' color='neutral.white'>
          <StyledContainer align='center' onClick={openEditPost}>
            <ImageContainer image={urlImage} />
            <Text size='14' color='primary.light2' bold ellipsis>{valueMap[value]}</Text>
          </StyledContainer>
        </Tooltip>
        {showButton && username &&
          <Flex>
            <Button onClick={openPreviewPost} variant='fill' name='eye' size='s' styles={buttonStyles}>
              <Icon name='eye' size='13' color='primary.light2' />
            </Button>
          </Flex>}
      </Flex>
    </CellWrapper>
  )
}
