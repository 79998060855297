import { useCallback } from 'react'

export const useSizeDifference = (setShowCarousel = () => {}) => {
  const handleShowCarouselForContainer = useCallback((target) => {
    if (target) {
      // Container width
      const { width } = target.getBoundingClientRect()

      // Tabs children width

      const tabsHeaderChild = target?.children[0]?.children[0]
      const { width: tabsWidth } = tabsHeaderChild.getBoundingClientRect()

      setShowCarousel(width <= tabsWidth)
    }
  }, [])

  const handleShowCarouselForTabs = useCallback((target) => {
    if (target) {
      // Container width
      const { width } = target.getBoundingClientRect()

      // parent container
      const containerElement = target?.parentElement?.parentElement
      const { width: containerWidth } = containerElement.getBoundingClientRect()

      setShowCarousel(containerWidth <= width)
    }
  }, [])

  return { handleShowCarouselForContainer, handleShowCarouselForTabs }
}
