import { useMemo } from 'react'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '~/context/Language'
import { AssociateTable } from '../../../../irm/commons/components/modals/AssociateModal/AssociateTable'
import { useColumnObject } from '../../../../irm/commons/utils/useColumnObject'
import { useTemplates } from '../../hooks/useTemplates'
import { SelectTemplateEmptyState } from './SelectTemplateEmptyState'

export const SelectTemplateModalTable = ({ selectedRows }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const { getTemplates, loadingTemplates } = useTemplates()

  const labels = useMemo(() => {
    return {
      name: t('entity.template.field.name.label'),
      createdByUser: t('entity.template.field.createdBy.label'),
      updatedAt: t('entity.template.field.lastUpdate.label')
    }
  }, [locale, selectedRows])

  const columns = useMemo(() => {
    return [
      useColumnObject('selectForLink', '', labels),
      useColumnObject('text', 'name', labels, { disableSortBy: false, disableDragColumn: true, disableEditColumn: true }),
      useColumnObject('createdByUser', 'createdByUser', labels, { disableSortBy: false, disableDragColumn: false, disableEditColumn: false }),
      useColumnObject('date', 'updatedAt', labels, { disableSortBy: false, disableDragColumn: false, disableEditColumn: false })
    ]
  }, [locale, selectedRows])

  const renderEmptyState = () => {
    return (
      <SelectTemplateEmptyState />
    )
  }

  return (
    <AssociateTable
      loading={loadingTemplates}
      tableId='template'
      columns={columns}
      initialSortBy={{ sortBy: 'createdAt', sortOrder: 'desc' }}
      initialDefaultColumns={['selection', 'name', 'createdByUser', 'updatedAt']}
      searchFilterId='name'
      renderEmptyState={renderEmptyState}
      getEntity={getTemplates}
      tableName='templates'
      tableView='insert'
    />
  )
}
