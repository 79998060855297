export const renderPortalElements = () => {
  return (
    <>
      <div id='networkModal' />
      <div id='footerModal' />
      <div id='shareModal' />
      <div id='paymentMethodModal' />
      <div id='refreshConfirmationModal' />
      <div id='exportModal' />
      <div id='couponModal' />
      <div id='editReportNameModal' />
      <div id='shareReportModal' />
      <div id='changePasswordModal' />
      <div id='editBillingInfo' />
      <div id='payStackModal' />
      <div id='widgetFilter' />
      <div id='tableEditColumns' />
      <div id='cancelPlanNameModal' />
      <div id='deleteSavedSearchModal' />
      <div id='trackingModal' />
      <div id='addFromListsTrackingModal' />
      <div id='duplicatedProfilesTrackingModal' />
      <div id='editConfirmationTrackingModal' />
      <div id='deleteProfilesConfirmationTrackingModal' />
      <div id='sendApiAccessTrackingModal' />
      <div id='addProfileLimitTracking' />
      <div id='massiveCampaignTasks' />
      <div id='deleteInfluencersInCampaign' />
      <div id='removeProfileList' />
      <div id='snackbar' />
      <div id='exportView' />
      <div id='tableActions'></div>
      <div id='deleteProfilePrices'></div>
      <div id='associateModal'/>
      <div id='selectTemplateModal'/>
      <div id='saveTemplateModal'/>
      <div id='addProfile' />
      <div id='listUpdateNotAllowedModal' />
      <div id='listOverlapLimitReachedModal' />
      <div id='listOverlapEmptyDataModal' />
      <div id='profileComparatorModal' />
      <div id='addOutreachRecipientsModal' />
      <div id='associateToOutreachesModal' />
      <div id='addProgramInfluencersModal' />
      <div id='addInvoiceInfluencersModal' />
    </>
  )
}
