import { Flex } from 'influ-dms'
import styled from 'styled-components'

export const ContainerWrapper = styled(Flex)`
  padding: 16px 4px 20px 4px;
  max-width: ${({ isExpanded }) => (isExpanded ? '192px' : '48px')};
  width: ${({ isExpanded }) => (isExpanded ? '192px' : '48px')};
  transition: width 0.3s ease, max-width 0.3s ease;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-right: 1px solid ${({ theme }) => theme.colors.primary.light4};
  position: relative;
  box-sizing: border-box;
  position: fixed;
  height: inherit;
  @-moz-document url-prefix() {
    height: inherit;
  }
  z-index: 100;
  overflow: hidden;
`
