import { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { cloneDeep, isEmpty } from 'lodash'
import { FormContext } from 'influ-dms'
import { EditInfluencerModalContentHeader } from './header/EditInfluencerModalContentHeader'
import { EditInfluencerModalContentCosts } from './costs/EditInfluencerModalContentCosts'
// import { ApiAccessInviteCardTracking } from './ApiAccessInviteCardTracking'

const StyledDiv = styled.div`
  height: calc(100% - 32px);
`

const StyledContent = styled.div`
  overflow: auto;
  height: calc(100% - 144px);
  padding: 16px 32px 64px;
`

export const EditInfluencerModalContent = ({ influencer, isOpen }) => {
  const { setValues } = useContext(FormContext)

  useEffect(() => {
    if (isOpen && !isEmpty(influencer)) {
      const newInfluencer = cloneDeep(influencer)
      const newValues = {
        influencerCost: newInfluencer.cost,
        publicationCosts: {}
      }
      newInfluencer.publications.forEach(({ postId, cost }) => {
        newValues.publicationCosts[postId] = cost
      })
      setValues(newValues)
    }
  }, [isOpen, influencer.influencerId])

  if (isEmpty(influencer) || !isOpen) return null

  return (
    <StyledDiv>
      <EditInfluencerModalContentHeader influencer={influencer} />
      <StyledContent>
        {/* {influencer.network === 'instagram' && <ApiAccessInviteCardTracking influencer={influencer} />} */} {/* old tmt connection */}
        <EditInfluencerModalContentCosts posts={influencer.publications} />
      </StyledContent>
    </StyledDiv>
  )
}
