import chroma from 'chroma-js'
import { merge } from 'lodash'

const colorCodesV2 = {
  app: {
    lightGray: '#EFF0F1',
    lightGray2: '#FBFBFB',
    gray: '#CECECE',
    gray2: '#868082'
  },
  primary: {
    black: '#0D0205',
    black75: '#494143',
    black50: '#868082',
    black25: '#C2C0C0',
    black10: '#E7E6E6',
    pink: '#FF5983',
    skyBlue: '#CCF6FF',
    majorelleBlue: '#6E44FF',
    majorelleBlue10: '#F1ECFF',
    offWhite: '#FCFAFA',
  },
  state: {
    deepSkyBlue: '#40CEFF',
    deepSkyBlue1: '#20c5ff',
    deepSkyBlue2: '#00bdff',
    deepSkyBlue75: '#F2FDFF',
  },
  platform: {
    green10: '#A8DFB9',
    blue10: '#D4D8F7'
  }
}

const colorCodes = {
  app: {
    dark1: '#B20947',
    dark2: '#CF0B53',
    default: '#EE0D61',
    light1: '#F0397D',
    light2: '#F3669A',
    light3: '#FBC7DA'
  },
  primary: {
    dark: '#270038',
    light1: '#62466F',
    light2: '#7C7194',
    light3: '#968EA9',
    light4: '#BEBED8',
    light5: '#EDEDF4',
    light6: '#F8F8FC'
  },
  neutral: {
    white: '#FFFFFF',
    light: '#F9F9F9',
    dark: '#D9D9D9'
  },
  state: {
    default: '#C5C5D2',
    active: '#25AE50',
    activeBackground: '#E9F7EE',
    done: '#270038',
    warningDefault: '#F98300',
    warningHover: '#FA6400',
    warningActive: '#E55200',
    warningDisabled: '#F7CCB5',
    warningBackground: '#FBF2ED',
    dangerDefault: '#DE2A3C',
    dangerHover: '#C41D2F',
    dangerActive: '#AD1A2A',
    dangerDisabled: '#FADCDF',
    dangerBackground: '#FFEFF0',
    blocked: '#0066D4',
    pending: '#FFD100'
  },
  instagram: ['#5A56CA', '#DB2E7B', '#FA9A50', '#FEDA77', '#D0A2DC', '#B055C9'],
  tiktok: ['#69C9D0', '#EE1D52', '#010101', '#49C673'],
  youtube: ['#FF0000', '#606060', '#DCDCDC', '#5A56CA'],
  twitter: ['#AED1F4', '#00ACED', '#E0245E', '#19BF63'],
  twitch: ['#9147FF', '#6441A5', '#B9A3E3', '#262626', '#F1F1F1'],
  others: ['#00ACED', '#B1ABBF', '#E0245E', '#FA9A50'],
  gradients: {
    1: ['#4ABDD9', '#A4E4EC']
  },
  blue: {
    active: '#00428B',
    hover: '#0056B6',
    main: '#0079FF',
    disabled: '#3A96FC'
  }
}

const colors = {
  ...merge(colorCodes, colorCodesV2)
}

const buttons = {
  primary: {
    fill: {
      background: {
        main: colors.app.default,
        hover: colors.app.dark2,
        active: colors.app.dark1,
        disabled: colors.app.light3
      },
      text: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      }
    },
    outline: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.app.default,
        hover: colors.app.dark2,
        active: colors.app.dark1,
        disabled: colors.app.light3
      }
    },
    flat: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.app.default,
        hover: colors.app.dark1,
        active: colors.app.dark1,
        disabled: colors.app.light3
      }
    }
  },
  secondary: {
    fill: {
      background: {
        main: colors.primary.light5,
        hover: colors.primary.light5,
        active: colors.app.default,
        disabled: colors.primary.light5
      },
      text: {
        main: colors.primary.dark,
        hover: colors.app.default,
        active: colors.neutral.white,
        disabled: colors.primary.light4
      }
    },
    outline: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.primary.light3,
        hover: colors.primary.dark,
        active: colors.primary.dark,
        disabled: colors.primary.light4
      }
    },
    flat: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.primary.light3,
        hover: colors.primary.dark,
        active: colors.primary.dark,
        disabled: colors.primary.light4
      }
    }
  },
  danger: {
    fill: {
      background: {
        main: colors.state.dangerDefault,
        hover: colors.state.dangerHover,
        active: colors.state.dangerActive,
        disabled: colors.state.dangerDisabled
      },
      text: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      }
    },
    outline: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.state.dangerDefault,
        hover: colors.state.dangerHover,
        active: colors.state.dangerActive,
        disabled: colors.state.dangerDisabled
      }
    },
    flat: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.state.dangerDefault,
        hover: colors.state.dangerHover,
        active: colors.state.dangerActive,
        disabled: colors.state.dangerDisabled
      }
    }
  },
  warning: {
    fill: {
      background: {
        main: colors.state.warningDefault,
        hover: colors.state.warningHover,
        active: colors.state.warningActive,
        disabled: colors.state.warningDisabled
      },
      text: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      }
    },
    outline: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.state.warningDefaultDefault,
        hover: colors.state.warningDefaultHover,
        active: colors.state.warningDefaultActive,
        disabled: colors.state.warningDefaultDisabled
      }
    },
    flat: {
      background: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      },
      text: {
        main: colors.state.warningDefaultDefault,
        hover: colors.state.warningDefaultHover,
        active: colors.state.warningDefaultActive,
        disabled: colors.state.warningDefaultDisabled
      }
    }
  }
}

const buttonsV2 = {
  primary: {
    rounded: {
      background: {
        main: colors.primary.pink,
        hover: colors.app.dark2,
        active: colors.app.dark1,
        disabled: colors.app.light3
      },
      text: {
        main: colors.neutral.white,
        hover: colors.neutral.white,
        active: colors.neutral.white,
        disabled: colors.neutral.white
      }
    },
  },
  deepSkyBlue: {
    rounded: {
      background: {
        main: colors.state.deepSkyBlue,
        hover: colors.state.deepSkyBlue2,
        active: colors.state.deepSkyBlue1,
        disabled: colors.state.deepSkyBlue75
      },
      text: {
        main: colors.primary.black,
        hover: colors.primary.black,
        active: colors.primary.black,
        disabled: colors.primary.black
      }
    }
  }
}

const theme = {
  ...colors,
  button: {
    ...merge(buttons, buttonsV2)
  }
}

const GRAPH_BASE = '#333'
const appTheme = ({ graphBase = GRAPH_BASE }) => {
  const graphs = [
    chroma(graphBase),
    chroma(graphBase).brighten(1.3),
    chroma(graphBase).brighten(2.2),
    chroma(graphBase).brighten(2.8)
  ]

  return {
    graphs,
    colors: theme
  }
}

export default appTheme
