const getPermissions = (productsArray) => {
  const permissions = productsArray.reduce((acc, next) => {
    if (next === 'reportsV2') acc.reports = true
    acc[next] = true
    return acc
  }, {})

  if (permissions.irm) {
    permissions.communication = true
  }

  if (permissions?.campaignsV1) {
    permissions.payments = true
  }
  return permissions
}

export { getPermissions }
