import { servers, steps } from '../conf'
import { ImapWarning } from './ImapWarning'

export const Warning = ({ server, setStep, handleCancel }) => {
  const handleAccept = () => {
    setStep(steps.form)
  }
  return (
    <>
      {server === servers.imap.id && <ImapWarning handleAccept={handleAccept} handleCancel={handleCancel} />}
    </>
  )
}
