import PropTypes from 'prop-types'
import getConfig from 'next/config'
import { Scrollable, ScrollableSection } from './Scrollable'
import {
  BrandAvatar
  // ScrollableSection
} from 'influ-dms'
import { RouterHelper } from '../../routing/routerHelper'
import { getPermissions } from '../../helpers'
import { homePage } from '../../constants'
import { useRouter } from 'next/router'
import { setTag } from '@sentry/nextjs'

const { publicRuntimeConfig } = getConfig()
const { mainColor } = publicRuntimeConfig

const BrandsSection = (props) => {
  const router = useRouter()
  const { products, settingsClick, onClose, text, data } = props

  const onProductClick = (product, brandId) => {
    let matchingProduct = homePage[product]
    onClose()
    if (homePage[product] === 'reports') matchingProduct = 'reportsV2'
    if (homePage[product] === 'communication') matchingProduct = 'outreaches'
    router.push(RouterHelper.getRoute(matchingProduct, { brandId }))
    window.localStorage.setItem('brand_active', brandId)
    setTag('brandId', brandId)
  }

  const onBrandSettingsClick = (brandId) => {
    settingsClick(brandId)
    window.localStorage.setItem('brand_active', brandId)
    setTag('brandId', brandId)
  }

  const getFilteredBrands = () => {
    const normalizedInput = text.toLowerCase()
    const normalizedBrands = data.map((brand) => {
      const normalizedName = brand.brandName.toLowerCase()
      return { ...brand, normalizedName }
    })
    const filteredBrands = normalizedBrands.filter((brand) =>
      brand.normalizedName.includes(normalizedInput)
    )
    return filteredBrands
  }

  const brandsGroup = () => {
    const filteredBrands = getFilteredBrands()
    const brandsPerPage = 9
    const brandNumber = filteredBrands.length

    let numberOfScreens = Math.floor(brandNumber / brandsPerPage)
    const modu = brandNumber % brandsPerPage
    const hasModule = modu !== 0
    if (hasModule) numberOfScreens += 1

    const result = []

    for (let i = 0; i < numberOfScreens; i++) {
      const startIndex = brandsPerPage * i
      const endIndex = startIndex + brandsPerPage

      const brandsSubarray = filteredBrands.slice(startIndex, endIndex)
      const section = renderScrollableWindow(brandsSubarray, i)

      result.push(section)
    }

    return result
  }

  const renderScrollableWindow = (data, i) => {
    return (
      <ScrollableSection key={i} styles={['max-width: 1376px;']}>
        {data.map((brand) => {
          const { id, brandName, logoImage } = brand
          const permissions = getPermissions(brand.products)
          const onSettingsClick = () => onBrandSettingsClick(id)
          // const productClick = product => onProductClick(product, id)
          return (
            <BrandAvatar
              key={id}
              products={products}
              brandId={id}
              src={logoImage}
              permissions={permissions}
              title={brandName}
              onSettingsClick={onSettingsClick}
              onProductClick={onProductClick}
            />
          )
        })}
      </ScrollableSection>
    )
  }

  return (
    <Scrollable height={550} activeColor={mainColor}>
      {brandsGroup()}
    </Scrollable>
  )
}

BrandsSection.defaultProps = {
  data: [],
  text: ''
}

BrandsSection.propTypes = {
  data: PropTypes.array,
  text: PropTypes.string
}

export { BrandsSection }
