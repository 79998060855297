import { gql } from '@apollo/client'

export const editCountry = gql`
  mutation editCountry(
    $paymentInvoiceId: String!
    $paymentRequestIds: [String]
    $country: String
  ) {
    editCountry(
      paymentInvoiceId: $paymentInvoiceId
      paymentRequestIds: $paymentRequestIds
      country: $country
    ){
      message
      code
      success
      data
    }
  }
`
