import { gql } from '@apollo/client'

export const updateCampaignBetaStatus = gql`
 mutation updateCampaignBetaStatus(
   $campaignId: String!
   $status: CampaignStatusOptions!
 ) {
  updateCampaignBetaStatus(
     _id:$campaignId
     status:$status
   ){
     id: _id
    }
 }
 `
