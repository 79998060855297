import { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { FormContext, Text, Flex, Spacing, Button, SelectButton, Icon, TableContextProvider } from 'influ-dms'
import { headerStyles } from './styles'
import { AddProfileTrackingCardEmpty } from './AddProfileTrackingCardEmpty'
import { AddProfileToTrackingModal, AddFromListsTrackingModal, SendApiAccessTrackingModal, AddProfileLimitTracking, DuplicatedProfilesTrackingModal } from '../../../influencers/commons'
import { get, isEmpty } from 'lodash'
import { ProfilesTrackingTable } from './ProfilesTrackingTable/ProfilesTrackingTable'
import { useTranslate } from '~/hooks/useTranslate'

export const LIMIT_OF_PROFILES_TO_ADD = 500

const PaddedContainer = styled.div`
  padding: 16px 16px 20px 16px;
`
const selectButtonStyles = css`
  margin: 0px 0px 0px 16px;
  padding: 2px;
  width: 101px;
`
const dropDownLeftStyles = css`
  right: 0px;
  position: absolute;
`

const buttonStyles = css`
  margin: 0px 8px;
`
const textStyles = css`
  margin: 2px 2px;
`

export const AddProfileTrackingCard = ({ editing, focusInputs, setFocusInputs, trackingId = '' }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const [modalProfileIndex, setModalProfileIndex] = useState(-1)
  const limitExceeded = get(values.influencersInModal, 'length', 0) + get(values.influencers, 'length', 0) > LIMIT_OF_PROFILES_TO_ADD
  const [duplicatedModalOpen, setDuplicatedModalOpen] = useState(false)
  const [duplicatedProfilesNumber, setDuplicatedProfilesNumber] = useState(false)
  const [totalProfilesNumber, setTotalProfilesNumber] = useState(false)
  const addOptions = [
    { label: t('reports.modal.tracking.addProfileOptions.dropdown.addNewProfile'), value: 0, onClick: () => setModalProfileIndex(0) },
    { label: t('reports.modal.tracking.addProfileOptions.dropdown.fromMyInfluencers'), value: 1, onClick: () => setModalProfileIndex(1) },
    { label: t('reports.modal.tracking.addProfileOptions.dropdown.fromList'), value: 2, onClick: () => setModalProfileIndex(2) }
  ]

  useEffect(() => {
    if (focusInputs && isEmpty(focusInputs.profiles)) {
      setFocusInputs({ ...focusInputs, profiles: true })
    }
  }, [modalProfileIndex])

  return (
    <PaddedContainer>
      <Flex justify='space-between'>
        <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.modal.tracking.profilesBlock.title')}</Text>
        <Flex>
          <Button
            type='button'
            id='sendApiAccess'
            name='sendApiAccess' variant='outline' size='s' color='secondary'
            styles={buttonStyles} onClick={() => setModalProfileIndex(3)}
          >{t('commons.button.inviteToOptin')}
          </Button>

          {limitExceeded
            ? <Button name='addProfilesLimit' size='s' styles={selectButtonStyles} onClick={() => setModalProfileIndex(4)}>{t('commons.button.addProfiles')}</Button>
            : (
              <SelectButton
                showIcon={false}
                buttonText={t('commons.button.addProfiles')}
                options={addOptions}
                size='s'
                styles={selectButtonStyles}
                customWidthDropdown='190px'
                dropdownStyles={dropDownLeftStyles}
              />)}
        </Flex>
      </Flex>
      <Spacing size='24' />
      {values && !isEmpty(values.influencers) ? (
        <TableContextProvider initialState={{
          count: undefined,
          pages: undefined,
          columnName: 'username',
          selectedRows: [],
          isSingleSelect: false
        }}>
          <ProfilesTrackingTable tableId={'profilesInTracking-' + trackingId} influencerList={values.influencers} editing={editing} />
        </TableContextProvider>
      ) : (
        <AddProfileTrackingCardEmpty addOptions={addOptions} selectButtonStyles={selectButtonStyles} />
      )}
      <AddProfileToTrackingModal
        isOpen={modalProfileIndex === 0}
        onClose={() => setModalProfileIndex(-1)}
      />
      {modalProfileIndex === 1 &&
        <AddFromListsTrackingModal
          isOpen={modalProfileIndex === 1}
          onClose={() => setModalProfileIndex(-1)}
          network={values.network}
          fromLists={false}
          setDuplicatedProfilesNumber={setDuplicatedProfilesNumber}
          setTotalProfilesNumber={setTotalProfilesNumber}
          setDuplicatedModalOpen={setDuplicatedModalOpen}
        />}
      {modalProfileIndex === 2 &&
        <AddFromListsTrackingModal
          isOpen={modalProfileIndex === 2}
          onClose={() => setModalProfileIndex(-1)}
          network={values.network}
          fromLists
          setDuplicatedProfilesNumber={setDuplicatedProfilesNumber}
          setTotalProfilesNumber={setTotalProfilesNumber}
          setDuplicatedModalOpen={setDuplicatedModalOpen}
        />}
      <SendApiAccessTrackingModal
        isOpen={modalProfileIndex === 3}
        onClose={() => setModalProfileIndex(-1)}
        network={values.network}
      />
      <AddProfileLimitTracking
        isOpen={modalProfileIndex === 4}
        onClose={() => setModalProfileIndex(-1)}
      />
      {duplicatedModalOpen &&
        <DuplicatedProfilesTrackingModal
          isOpen={duplicatedModalOpen}
          onClose={() => {
            setDuplicatedModalOpen(false)
            setModalProfileIndex(-1)
          }}
          duplicatedProfilesNumber={duplicatedProfilesNumber}
          totalProfilesNumber={totalProfilesNumber}
        />}
      {values && isEmpty(values.influencers) && get(focusInputs, 'profiles', false) &&
      (
        <Flex align='center'>
          <Icon name='danger' size='12' color='state.warningDefault' />
          <Text color='state.warningDefault' styles={textStyles}>
            {t('reports.modal.tracking.profilesBlock.helpertext.warning')}
          </Text>
        </Flex>
      )}
    </PaddedContainer>
  )
}
