import { gql } from '@apollo/client'

export const addProgramV2 = gql`
  mutation addProgramV2(
    $name: String!,
    $description: String,
    $influencerIds: [String],
  ) {
    addProgramV2(
      name: $name,
      description: $description,
      influencerIds: $influencerIds,
    ) {
      message
      code
      success
      data
    }
  }
`