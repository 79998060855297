import { gql } from "@apollo/client";

export const deleteEntityFields = gql`
  mutation deleteEntityFields($entityFieldIds: [String]!) {
    deleteEntityFields(entityFieldIds: $entityFieldIds) {
      message
      code
      success
    }
  }
`