import { useContext, useEffect, useState } from 'react'
import { FormContext, InputTag, Spacing, Text, useArray } from 'influ-dms'
import { css } from 'styled-components'
import { some } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'
import { errorStyles } from '~/containers/reports/influencers/styles'
import { usernameValidator } from '../../../addPostsHelper'

const containerStyles = css`
  padding: 6px;
`

const textareaStyles = css`
  margin: 4px 0;
  ::placeholder {
    color: ${({ theme }) => theme.colors.state.default};
    font-size: 13px;
    opacity: 1; /* Firefox */
  }
`

export const AddPostsManualInstagramTaggedPeople = () => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const [urls, setUrls] = useArray([])
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (values.postTaggedUsers && values.postTaggedUsers.length) {
      setUrls(values.postTaggedUsers.map(u => ({ value: u, isValid: true })))
    }
  }, [values.postTaggedUsers])

  useEffect(() => {
    setValue('InputText', 'taggedUsers', urls.filter(({ isValid }) => isValid).map(({ value }) => value))
    setHasError(some(urls, ({ isValid }) => !isValid))
  }, [urls.length])

  const handleUrl = val => {
    setUrls({ value: val, isValid: usernameValidator(val) }, 'edit', 'value')
  }

  return (
    <>
      <Text color='primary.dark' size='12' bold>{t('commons.insights.peopleTagged.input.label')}</Text>
      <Spacing size='4' />
      <InputTag
        values={urls}
        setValues={setUrls}
        handleValue={handleUrl}
        containerStyles={containerStyles}
        textareaStyles={textareaStyles}
        placeholder={t('commons.insights.peopleTagged.input.placeholder')}
      />
      {hasError && <Text styles={errorStyles} color='state.dangerDefault'>{t('commons.insights.peopleTagged.input.helpertext.error')}</Text>}
    </>
  )
}
