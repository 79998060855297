import { Dropdown, RoundedButton, Tooltip } from 'influ-dms'
import { ActionIcons } from '~/containers/irm/commons/components/ActionButtons'
import { activeButtonsStyles } from './styles'

export const MoreOptions = ({ isDisabled, children, isOpen, setIsOpen, tooltipText, align = 'right' }) => {
  return (
    <>
      <Tooltip text={tooltipText} width='fit-content' type='dark' color='neutral.white'>
        <RoundedButton
          name={ActionIcons.moreOptions}
          onClick={() => setIsOpen(true)}
          color={'primary.light2'}
          styles={activeButtonsStyles}
          size={20}
          isDisabled={isDisabled}
          isActive={isOpen}
        />
      </Tooltip>

      <Dropdown isOpen={isOpen} setOpen={setIsOpen} align={align} top='3px' width='auto'>
        {children}
      </Dropdown>
    </>
  )
}
