import { useContext, useState } from 'react'
import {
  CellWrapper,
  Dot,
  Icon,
  InfluDateFormat,
  Text,
  Td,
  Number,
  TableEditableTh,
  TableV2TdNumber,
  TableV2TdText,
  TableEditableThCheckbox,
  TableV2TdCheckbox,
  TableV2TdDate,
  TableV2TdCurrency,
  Tag,
  Tooltip,
  Flex,
  Spacing,
  SnackbarContext,
  TableV2TdTextEditable,
  TableV2TdNumberEditable,
  i18nNumber
} from 'influ-dms'
import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { brand } from '~/graphql/brands'
import { InfluencersListTableTdAliasName } from '../../influencers/Table/InfluencersListTableTdAliasName'
import { ProfilesListTableTdProfileName } from '../../profiles/table/ProfilesListTableTdProfileName'
import { ListsListTableTdListName } from '../../lists/Table/ListsListTableTdListName'
import { LandingPagesListTableTdName } from '../../landingPages/Table/LandingPagesListTableTdName'
import { ViewsListTableTdViewName } from '../../../settings/dataManagement/views/ViewsListTableTdViewName'
import { DomainsListTableTdDomainName } from '../../../settings/account/domains/DomainsListTableTdDomainName'
import { RouterHelper } from '../../../../routing/routerHelper'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { useLanguageContext } from '~/context/Language'
import { ModalsContext } from '../../../commons/context'
import { ListContext } from '../../lists/commons/context'
import { LandingPageContext } from '../../landingPages/commons/context'
import { ViewContext, GroupContext, PropertyContext } from '../../../settings/dataManagement/commons/context'
import { ProfileContext } from '../../profiles/commons/context'
import { DomainsContext } from '../../../settings/account/domains/commons/context'
import { useTranslate } from '../../../../hooks'
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'libphonenumber-js'
import { ProfilesListTableTdInfluencerAlias } from '../../profiles/table/ProfilesListTableTdInfluencerAlias'
import { useListActions } from '../../lists/commons/hooks'
import { NetworksTableTd } from '../../influencers/Table/NetworksTableTd'
import { ProgramContext } from '../../../programs/commons/context'
import { GroupsListTableTdGroupName } from '../../../settings/dataManagement/properties/groups/GroupsListTableTdGroupName'
import { PropertiesListTableTdPropertyName } from '../../../settings/dataManagement/properties/properties/PropertiesListTableTdPropertyName'
import { TemplatesListTableTdTemplateName } from '../../../communication/templates/Table/TemplatesListTableTdTemplateName'
import { OutreachRecipientActionsListTableTdActionName } from '../../../communication/outreach/modals/OutreachRecipientActions/OutreachRecipientActionsListTableTdActionName'
import { TemplateContext } from '../../../communication/templates/context/TemplateContext'
import { OutreachContext } from '../../../communication/outreach/context/OutreachContext'
import { useTemplates } from '../../../communication/templates/hooks'
import { getSelectionCell } from './getSelectionCell'
import { InvoiceContext, PaymentContext } from '../../../payments/invoices/context'
import { InvoicesListTableTdInvoiceName } from '../../../payments/invoices/Table/InvoicesListTableTdInvoiceName'
import { useInvoicePayments } from '../../../payments/invoices/hooks'
import { InvoicePaymentsTableTdAliasName } from '../../../payments/invoices/Detail/InvoiceDetailTabs/Payments/InvoicePaymentsTableTdAliasName'
import { SocialListeningContext } from '../../../socialListening/context'
import { AlertMentionsListTableTdMention } from '../../../socialListening/Detail/AlertDetailTabs/Mentions/AlertMentionsListTableTdMention'
import { AlertMentionsListTableEditableTh } from '../../../socialListening/Detail/AlertDetailTabs/Mentions/AlertMentionsListTableEditableTh'
import { getFormattedTime } from '../../../../helpers/getFormattedTime'

const tdCellStyles = css`
  width: auto;
  height: 100%;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

const fullWidhtTextStyles = css`
  width: 100%;
  text-align: end;
`
const StyledTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const StyledTag = styled(Tag)`
  font-weight: 400;
  height: auto;
  border-radius: 4px;
`

const StyledFlex = styled(Flex)`
            width : 97%;
          `

const renderHumanizedRow = (key, value) => {
  return (
    <Td styles={tdCellStyles}>
      <Text ellipsis showTooltip color='primary.dark' size='14' styles={fullWidhtTextStyles}>
        {key}
      </Text>
      <Text size='10' >
        <Number percentage>
          {value}
        </Number>
      </Text>
    </Td>
  )
}

// TODO JAVI Refactor - Put each case to a file
export const useColumnObject = (type, accessor, labels, options = {}, params = {}) => {
  const { dispatch } = useContext(ModalsContext)
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const listId = get(router, 'query.listId')
  const programId = get(router, 'query.programId')
  const {
    list,
    setList,
    setEditListModalOpen,
    openDeleteListModal,
    openDeleteProfileFromListModal,
    openDeleteInfluencerFromListModal,
  } = useContext(ListContext)
  const { openDeleteInfluencerFromProgramModal } = useContext(ProgramContext)
  const { setLandingPage, openEditLandingPageModal, openDeleteLandingPageModal } = useContext(LandingPageContext)
  const { setView, openEditViewModal, openDeleteViewModal } = useContext(ViewContext)
  const { setProfileId, openDeleteProfileModal, openAssociateToListModal } = useContext(ProfileContext)
  const { setDomain, openDetailDomainModal, openDeleteDomainModal } = useContext(DomainsContext)
  const { disableSortBy, disableDragColumn, disableEditColumn } = options
  const { setGroup, openEditGroupModal, openDeleteGroupModal } = useContext(GroupContext)
  const { setProperty, openEditPropertyModal, openDeletePropertyModal } = useContext(PropertyContext)
  const { setTemplate, setEditTemplateModalOpen, setDeleteTemplateModalOpen } = useContext(TemplateContext)
  const { setRemoveOutreachRecipientModalOpen, setOutreachRecipientActionsModalOpen } = useContext(OutreachContext)
  const { setInvoice, setEditInvoiceModalOpen, setDeleteInvoiceModalOpen } = useContext(InvoiceContext)
  const { setPayment, setRemoveInfluencerModalOpen } = useContext(PaymentContext)
  const { alert, setMention } = useContext(SocialListeningContext)
  const { showSnackbar } = useContext(SnackbarContext)

  const rawNumericAccessors = [
    'size',
    'submissions',
    'influencerCount',
    'influencersCount',
    'entityFieldsCount',
    'opensCount',
    'linkClicksCount'
  ]

  switch (type) {
    case 'select': {
      return {
        id: 'selection',
        disableResizing: true,
        disableDragColumn: true,
        minWidth: 48,
        width: 48,
        maxWidth: 48,
        Header: ({ page }) => <TableEditableThCheckbox rows={page} />,
        Cell: TableV2TdCheckbox,
        disableFilters: true
      }
    }
    case 'selectForLink': {
      return {
        id: 'selection',
        disableResizing: true,
        minWidth: 48,
        width: 48,
        maxWidth: 48,
        Header: ({ page }) => <TableEditableThCheckbox rows={page} disabled />,
        Cell: ({ row }) => {
          const cell = getSelectionCell(t, row, params, programId, listId, list)
          return cell
        },
        disableFilters: true
      }
    }
    case 'profile':
    case 'profileWithoutActions':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: (props) => <TableEditableTh {...props} isFirst={options.isFirst} />,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        disableSortBy,
        disableDragColumn,
        disableEditColumn,
        Cell: ({ row }) => {
          const { _id } = row.original
          const handleProfile = () => {
            dispatch({ type: 'openPreviewProfile', payload: _id })
          }
          const handleAddToList = () => {
            setProfileId(_id)
            openAssociateToListModal()
          }
          const handleRemove = () => {
            setProfileId(_id)
            if (listId) {
              openDeleteProfileFromListModal()
            } else {
              openDeleteProfileModal()
            }
          }
          const actions = type === 'profileWithoutActions' ? {} : {
            onProfileClick: handleProfile,
            onListClick: handleAddToList,
            onDeleteClick: handleRemove
          }
          return (
            <ProfilesListTableTdProfileName
              row={row}
              isTableRow
              listId={listId}
              profileNetwork={params.network || row.original.network}
              {...actions}
            />
          )
        }
      }
    }
    case 'influencer':
    case 'influencerWithoutActions':
    case 'influencerWithRemove':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: type === 'influencerWithoutActions' ? 250 : 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const { _id: influencerId, irmInfluencerId, email } = row.original

          const handleAliasClick = () => {
            router.push(RouterHelper.getRoute('irmInfluencer', { brandId, influencerId }))
          }

          const handleSendMassiveEmail = () => {
            const influencers = [{ _id: influencerId, email }]
            dispatch({
              type: 'openSendEmailIrmInfluencer',
              influencers
            })
          }

          const handleEditInfluencer = () => {
            dispatch({
              type: 'openEditIrmInfluencer',
              payload: influencerId
            })
          }

          const handleRemoveFromProgram = () => {
            openDeleteInfluencerFromProgramModal(irmInfluencerId)
          }
          const handleRemoveFromList = () => {
            openDeleteInfluencerFromListModal(influencerId)
          }

          const { fromPrograms, fromList } = params
          const actions = type === 'influencerWithoutActions' ? {}
            : (() => {
                const commonOptions = {
                  onEditClick: handleEditInfluencer,
                  onSendMassiveEmailClick: handleSendMassiveEmail,
                  onAliasClick: handleAliasClick,
                  showRenderAddTo: true
                }

                if (fromPrograms) {
                  return {
                    ...commonOptions,
                    onRemoveFromProgramClick: handleRemoveFromProgram
                  }
                } else if (fromList) {
                  return {
                    ...commonOptions,
                    onRemoveFromListClick: handleRemoveFromList
                  }
                } else {
                  return commonOptions
                }
              })()

          return (
            <InfluencersListTableTdAliasName
              {...params}
              row={row}
              isTableRow
              {...actions}
              {...options}
            />
          )
        }
      }
    }
    case 'influencerInProfile':
    case 'influencerInProfileWithoutActions':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 250,
        minWidth: 100,
        maxWidth: 400,
        disableGlobalFilter: true,
        disableSortBy,
        disableDragColumn,
        disableEditColumn,
        Cell: ({ row }) => {
          const handleOnclickInfluencer = (influencerId) => {
            router.push(RouterHelper.getRoute('irmInfluencer', { brandId, influencerId }))
          }

          const handleAssociateToInfluencer = (profile) => {
            params.handleAssociateToInfluencer(profile)
          }

          const actions = type === 'influencerInProfileWithoutActions' ? {} : {
            onInfluencerClick: handleOnclickInfluencer,
            onAssociateInfluencerClick: handleAssociateToInfluencer
          }
          return (
            <ProfilesListTableTdInfluencerAlias
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'recipient':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 400,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const { _id: influencerId, email, alias, picture, outreach } = row.original

          const handleAliasClick = () => {
            setOutreachRecipientActionsModalOpen(true, influencerId, alias, picture, email, outreach)
          }

          const handleSendMassiveEmail = () => {
            const influencers = [{ _id: influencerId, email }]
            dispatch({
              type: 'openSendEmailIrmInfluencer',
              influencers
            })
          }

          const handleEditInfluencer = () => {
            dispatch({
              type: 'openEditIrmInfluencer',
              payload: influencerId
            })
          }

          const handleRemoveFromOutreach = () => {
            setRemoveOutreachRecipientModalOpen(true, influencerId)
          }

          const actions = params?.isPreview
            ? {}
            : {
                onAliasClick: handleAliasClick,
                onSendMassiveEmailClick: handleSendMassiveEmail,
                onEditClick: handleEditInfluencer,
                onRemoveFromOutreachClick: handleRemoveFromOutreach,
                showRenderAddTo: true
              }

          return (
            <InfluencersListTableTdAliasName
              {...params}
              row={row}
              isTableRow
              {...actions}
              {...options}
            />
          )
        }
      }
    }
    case 'recipientAction':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 250,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleGoToURL = () => {
            if (row?.original?.clickLink) window.open(row?.original?.clickLink, '_blank', 'noopener')
          }

          const actions = {
            onGoToURL: handleGoToURL
          }

          return (
            <OutreachRecipientActionsListTableTdActionName
              {...params}
              row={row}
              isTableRow
              {...actions}
              {...options}
            />
          )
        }
      }
    }
    case 'list':
    case 'listWithoutActions':
    case 'listWithRemove':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const { id: listId } = row.original

          const { handleShareList: shareListAction } = useListActions({
            list: row.original,
          })

          const handleNameClick = () => {
            router.push(RouterHelper.getRoute('irmList', { brandId, listId }))
          }

          const handleEditList = () => {
            setList(row.original)
            setEditListModalOpen(true)
          }

          const handleShareList = () => {
            shareListAction()
          }

          const handleDeleteList = () => {
            setList(row.original)
            openDeleteListModal()
          }

          const handleDeleteInfluencerFromList = () => {
            const listId = row.original?.id?.toString()
            openDeleteInfluencerFromListModal(null, listId)
          }

          const actions = type === 'listWithoutActions' ? {}
            : (type === 'listWithRemove')
                ? {
                    onRemoveClick: handleDeleteInfluencerFromList,
                  } : {
                    onEditClick: handleEditList,
                    onShareClick: handleShareList,
                    onDeleteClick: handleDeleteList,
                    onNameClick: handleNameClick,
                    showExportClick: true,
                  }

          return (
            <ListsListTableTdListName
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'view':
    case 'viewWithoutActions':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleEditView = () => {
            setView(row.original)
            openEditViewModal()
          }

          const handleDeleteView = () => {
            setView(row.original)
            openDeleteViewModal()
          }

          const actions = type === 'viewWithoutActions' ? {} : {
            onEditClick: handleEditView,
            onDeleteClick: handleDeleteView,
          }

          return (
            <ViewsListTableTdViewName
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'networks':
    case 'networksWithoutActions':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 280,
        minWidth: 250,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const actions = type === 'networksWithoutActions' ? {} : {
            onAssociateProfileClick: params.handleAssociateToProfiles
          }
          return (
            <NetworksTableTd row={row} {...actions} />
          )
        }

      }
    }
    case 'influencerEmail':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 280,
        minWidth: 250,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const { emails } = row.original
          const primaryEmail = emails.find(email => email.primary)
          return (
            <TableV2TdText value={primaryEmail?.value} />
          )
        }
      }
    }
    case 'programInfluencerStatus':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const isActive = get(row?.original, params?.objectAccessor)
          const translatedLiteral = isActive ? t('entity.influencer.field.status.value.active.label') : t('entity.influencer.field.status.value.inactive.label')
          return (
            <TableV2TdText>
              <Flex gap='4px'>
                <Icon name='dot' color={isActive ? 'state.active' : 'primary.light5'} />
                <Text color='primary.dark'>{translatedLiteral}</Text>
              </Flex>
            </TableV2TdText>
          )
        }
      }
    }
    case 'numeric':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, value: rawValue }) => {
          const value = params.objectAccessor ? get(row.original, params.objectAccessor) : rawValue
          return <TableV2TdNumber value={value || (rawNumericAccessors.includes(accessor) ? 0 : '-')} humanized />
        }
      }
    }
    case 'time':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, value: rawValue }) => {
          const value = params.objectAccessor ? get(row.original, params.objectAccessor) : rawValue
          const timeUnit = params.timeUnit || 'h'

          const text = getFormattedTime(value, timeUnit, true)

          return <TableV2TdText justify='flex-end' value={value ? text : '-'} />
        }
      }
    }

    case 'amount':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, value: rawValue }) => {
          const value = params.objectAccessor ? get(row.original, params.objectAccessor) : rawValue
          return <TableV2TdNumber decimals={2} value={value || '-'} />
        }
      }
    }
    case 'currency':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: (row) => {
          const { currency } = get(row, 'row.original', {})
          return <TableV2TdCurrency value={row.value} symbol={currency.symbol} />
        }
      }
    }
    case 'percent':
    {
      return {
        label: labels[accessor],
        Header: TableEditableTh,
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ value }) => <TableV2TdNumber value={value || '-'} percentage />
      }
    }
    case 'keyPercent':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const hasData = !!row.original[accessor]
          if (!hasData) return renderHumanizedRow('-', '-')
          const { label, translationKey, value } = get(row, 'original', {})[accessor]
          if ((value === undefined || value === null) || (!label && !translationKey)) return renderHumanizedRow('-', '-')
          const text = translationKey ? t(label) : label
          return renderHumanizedRow(text, value, true)
        }
      }
    }
    case 'date':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, value: rawValue }) => {
          const value = params.objectAccessor ? get(row.original, params.objectAccessor) : rawValue
          return (
            <TableV2TdDate value={value !== null || value !== undefined ? value : '-'} />
          )
        }
      }
    }
    case 'dateWithTime':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, value: rawValue }) => {
          const value = params.objectAccessor ? get(row.original, params.objectAccessor) : rawValue
          return (
            value ? <TableV2TdDate format={InfluDateFormat(t).LLL} value={value || '-'} /> : <TableV2TdText value={'-'} justify='flex-end' />
          )
        }
      }
    }
    case 'phone':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ value }) => {
          if (value && typeof value === 'string') {
            let phoneNumber
            const phoneNumberWithCountryCode = !value.includes('+') ? `+${value}` : value
            const isValid = isPossiblePhoneNumber(phoneNumberWithCountryCode) && isValidPhoneNumber(phoneNumberWithCountryCode)
            if (isValid) {
              phoneNumber = parsePhoneNumber(phoneNumberWithCountryCode).formatInternational()
            } else {
              phoneNumber = value
            }
            return <TableV2TdText value={phoneNumber || '-'} />
          } else {
            return <TableV2TdText value={'-'} />
          }
        }
      }
    }
    case 'createdByUser':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const createdByUser = get(row.original, params?.objectAccessor ? params.objectAccessor : 'createdByUser')
          const firstName = get(createdByUser, 'firstName')
          return <TableV2TdText value={firstName ?? '-'} />
        }
      }
    }
    case 'tag':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          let value = get(row?.original, params?.objectAccessor)
          let tagColor = 'primary.light5'
          let textColor = 'primary.dark'
          let tooltipText
          if (params?.objectAccessor === 'emailSubscription') {
            const valueIsNull = value === null || value === undefined || value === 'null' || value === 'undefined'
            value = valueIsNull ? t('entity.influencer.field.emailSubscription.value.noSpecified.label') : value
          }
          if (params?.objectAccessor === 'outreach.status') {
            const serverError = get(row?.original, 'outreach.serverError')
            if (serverError) {
              tagColor = 'state.dangerDefault'
              textColor = 'neutral.White'
              tooltipText = t('entity.recipient.field.status.tooltip.serverError.label')
            }
          }
          return (
            <StyledTagContainer>
              <Tooltip
                text={tooltipText}
                type='dark'
                color='neutral.white'
                place='top'
              >
                <StyledTag color={tagColor} colorOption='default'>
                  <Text color={textColor}>
                    {value ?? '-'}
                  </Text>
                </StyledTag>
              </Tooltip>
            </StyledTagContainer>
          )
        }
      }
    }
    case 'statusTag':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const colors = {
            ok: 'primary.light5',
            notFound: 'state.warningDefault',
            outOfDate: 'app.default',
          }
          const tagTexts = {
            ok: t('entity.overlap.field.status.value.updated.label'),
            notFound: t('entity.overlap.field.status.value.notFound.label'),
            outOfDate: t('entity.overlap.field.status.value.outOfDate.label'),
          }
          const { status } = get(row, 'original', {})
          const color = colors[status] ?? 'primary.light5'
          const tag = status !== 'ok'
            ? <Tooltip
                text={
                  status === 'notFound'
                    ? t('widgets.influencers.listStats.followersOverlapping.profileNotFound.tooltip')
                    : t('widgets.influencers.listStats.followersOverlapping.profileNotUpdated.tooltip')
                }
                type='dark'
                color='neutral.white'
                place='right'
              >
              <StyledTag color={color} colorOption='default'>
                <Flex align='center' >
                  {status === 'notFound' && (
                  <>
                    <Icon name='warning' size='12' color='neutral.white' />
                    <Spacing size='4' vertical />
                  </>
                  )}
                  <Text color={status === 'ok' ? 'primary.dark' : 'neutral.white'} >
                    {tagTexts[status] ?? '-'}
                  </Text>
                </Flex>
              </StyledTag>
            </Tooltip>
            : <StyledTag color={color} colorOption='default'>
              <Text color={status === 'ok' ? 'primary.dark' : 'neutral.white'} >
                {tagTexts[status] ?? '-'}
              </Text>
            </StyledTag>
          return <StyledTagContainer>{tag}</StyledTagContainer>
        }
      }
    }
    case 'landingPage':
    case 'landingPageWithoutActions':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleEditLandingPage = () => {
            setLandingPage(row.original)
            openEditLandingPageModal()
          }

          const handlePreviewLandingPage = () => {
            const { publicRuntimeConfig } = getConfig()
            const { landingPagesRecruitUrl } = publicRuntimeConfig
            window.open(`${landingPagesRecruitUrl}${row.original.defaultURLParam}`, '_blank')
          }

          const handleDeleteLandingPage = () => {
            setLandingPage(row.original)
            openDeleteLandingPageModal()
          }

          const actions = type === 'landingPageWithoutActions' ? {} : {
            onEditClick: handleEditLandingPage,
            onDeleteClick: handleDeleteLandingPage,
            onPreviewClick: handlePreviewLandingPage,
          }

          return (
            <LandingPagesListTableTdName
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'domain':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleDomainNameClick = () => {
            setDomain(row.original)
            openDetailDomainModal()
          }

          const handleDeleteDomain = () => {
            setDomain(row.original)
            openDeleteDomainModal()
          }

          const actions = {
            onDeleteClick: handleDeleteDomain,
            onDomainNameClick: handleDomainNameClick,
          }

          return (
            <DomainsListTableTdDomainName
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'domainStatus':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const statusColors = {
            active: 'state.active',
            unverified: 'state.warningDefault',
            error: 'state.dangerDefault'
          }

          return (
            <CellWrapper>
              <StyledFlex align='center'>
                <Dot color={statusColors[row.original.status]} size='8' />
                <Text color='primary.dark' size='14'>
                  {row.original.status === 'error'
                    ? t('entity.domain.field.status.value.error.label')
                    : row.original.status === 'active'
                      ? t('entity.domain.field.status.value.connected.label')
                      : t('entity.domain.field.status.value.noConnected.label')}
                </Text>
              </StyledFlex>
            </CellWrapper>
          )
        }
      }
    }
    case 'entityFieldGroup':
    case 'entityFieldGroupWithoutActions':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleEditGroup = () => {
            setGroup(row.original)
            openEditGroupModal()
          }

          const handleDeleteGroup = () => {
            setGroup(row.original)
            openDeleteGroupModal()
          }

          const actions = type === 'entityFieldGroupWithoutActions' ? {} : {
            onEditClick: handleEditGroup,
            onDeleteClick: handleDeleteGroup,
          }

          return (
            <GroupsListTableTdGroupName
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'entityField':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleEditProperty = () => {
            setProperty(row.original)
            openEditPropertyModal()
          }

          const handleDeleteProperty = () => {
            setProperty(row.original)
            openDeletePropertyModal()
          }

          const actions = {
            onEditClick: handleEditProperty,
            onDeleteClick: handleDeleteProperty,
          }

          return (
            <PropertiesListTableTdPropertyName
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'template':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const { duplicateTemplate, loadingDuplicateTemplate } = useTemplates()
          const router = useRouter()
          const brandId = get(router, 'query.brandId')
          const pathname = get(router, 'pathname', '')
          const isPublic = pathname.includes('public')
          const skip = isPublic || !brandId
          const { data } = useQuery(brand, { variables: { brandId }, skip })
          const remainingTemplates = get(data, 'brand.featureFlags.irm.remainingTemplates')

          const handleTemplateNameClick = () => {
            setTemplate(row.original)
            setEditTemplateModalOpen(true)
          }

          const handleEditTemplate = () => {
            setTemplate(row.original)
            setEditTemplateModalOpen(true)
          }

          const handleDuplicateTemplate = async () => {
            if (!loadingDuplicateTemplate) {
              await duplicateTemplate(row.original._id)
            }
          }

          const handleDeleteTemplate = () => {
            setTemplate(row.original)
            setDeleteTemplateModalOpen(true)
          }

          const actions = {
            onNameClick: handleTemplateNameClick,
            onEditClick: handleEditTemplate,
            onDuplicateClick: handleDuplicateTemplate,
            onDeleteClick: handleDeleteTemplate
          }

          return (
            <TemplatesListTableTdTemplateName
              row={row}
              isTableRow
              loading={loadingDuplicateTemplate}
              disableDuplicate={remainingTemplates <= 0}
              {...actions}
            />
          )
        }
      }
    }
    case 'invoice':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleInvoiceNameClick = () => {
            const { _id: invoiceId } = row.original
            router.push(RouterHelper.getRoute('invoice', { brandId, invoiceId }))
          }

          const handleEditInvoice = () => {
            setInvoice(row.original)
            setEditInvoiceModalOpen(true)
          }

          const handleDeleteInvoice = () => {
            const { status } = row.original
            const canDeleteInvoice = status === 'draft'

            if (canDeleteInvoice) {
              setInvoice(row.original)
              setDeleteInvoiceModalOpen(true)
            }
          }

          const actions = {
            onNameClick: handleInvoiceNameClick,
            onEditClick: handleEditInvoice,
            onDeleteClick: handleDeleteInvoice
          }

          return (
            <InvoicesListTableTdInvoiceName
              row={row}
              isTableRow
              {...actions}
              />
          )
        }
      }
    }
    case 'invoiceStatus':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const { status, statusTranslated } = row.original
          const colors = {
            draft: 'state.default',
            inProcess: 'state.pending',
            generated: 'state.blocked'
          }
          return (
            <TableV2TdText>
              <Flex gap='4px'>
                <Icon name='dot' color={status ? colors[status] : colors[0]} />
                <Text color='primary.dark'>{statusTranslated}</Text>
              </Flex>
            </TableV2TdText>
          )
        }
      }
    }
    case 'boolean':
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ value }) => {
          return (
            <TableV2TdText value={value ? t('commons.value.yes') : t('commons.value.no')} />
          )
        }
      }
    }
    case 'payment':
    {
      return {
        accessor,
        label: labels[accessor],
        Header: TableEditableTh,
        sortable: false,
        width: 400,
        minWidth: 350,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const influencers = get(row.original, params.objectAccessor, [])
          const influencer = influencers ? influencers[0] : {}
          const influencerId = get(influencer, '_id')
          const email = get(row.original, 'email')

          const { addInfluencersToPay, loadingAddToPay, removeInfluencersFromPay, loadingRemoveFromPay } = useInvoicePayments()
          const [addInfluencersToPayLoading, setAddInfluencersToPayLoading] = useState(false)
          const [removeInfluencersFromPayLoading, setRemoveInfluencersFromPayLoading] = useState(false)
          const rowData = { original: { ...influencer, ...row.original } }

          const handleAliasClick = () => {
            if (influencerId) {
              router.push(RouterHelper.getRoute('irmInfluencer', { brandId, influencerId }))
            }
          }

          const handleSendMassiveEmail = () => {
            if (influencerId) {
              const influencers = [{ _id: influencerId, email }]
              dispatch({
                type: 'openSendEmailIrmInfluencer',
                influencers
              })
            }
          }

          const handleEditInfluencer = () => {
            if (influencerId) {
              dispatch({
                type: 'openEditIrmInfluencer',
                payload: influencerId
              })
            }
          }

          const handleRemoveFromInvoice = () => {
            const { status, statusInPool } = row.original
            const canRemove = status === 'draft' && statusInPool !== 'added' && statusInPool !== 'inPool'

            if (canRemove) {
              setPayment(row.original)
              setRemoveInfluencerModalOpen(true)
            }
          }

          const handleAddToPay = async () => {
            if (!loadingAddToPay) {
              setAddInfluencersToPayLoading(true)
              const paymentId = row.original._id?.toString()
              try {
                const response = await addInfluencersToPay([paymentId])
                const success = get(response, 'data.addToPay.success')
                if (!success) { showSnackbar('error', t('commons.snackbar.error.somethingWentWrong')) }
                setAddInfluencersToPayLoading(false)
              } catch (e) {
                showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
                setAddInfluencersToPayLoading(false)
              }
            }
          }

          const handleRemoveFromPay = async () => {
            if (!loadingRemoveFromPay) {
              setRemoveInfluencersFromPayLoading(true)
              const paymentId = row.original._id?.toString()
              try {
                const response = await removeInfluencersFromPay([paymentId])
                const success = get(response, 'data.removeFromPay.success')
                if (!success) { showSnackbar('error', t('commons.snackbar.error.somethingWentWrong')) }
                setRemoveInfluencersFromPayLoading(false)
              } catch (e) {
                showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
                setRemoveInfluencersFromPayLoading(false)
              }
            }
          }

          const actions = {
            onAliasClick: handleAliasClick,
            onSendMassiveEmailClick: handleSendMassiveEmail,
            onEditClick: handleEditInfluencer,
            onRemoveFromInvoiceClick: handleRemoveFromInvoice,
            onAddToPayClick: handleAddToPay,
            onRemoveFromPayClick: handleRemoveFromPay
          }

          return (
            <InvoicePaymentsTableTdAliasName
              {...params}
              row={rowData}
              isTableRow
              {...actions}
              {...options}
              loadingAddToPay={addInfluencersToPayLoading}
              loadingRemoveFromPay={removeInfluencersFromPayLoading}
              />
          )
        }
      }
    }
    case 'paymentStatus':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const { status, statusTranslated } = row.original

          const colors = {
            draft: 'state.default',
            pending: 'state.pending',
            processing: 'state.blocked',
            paid: 'state.active',
            error: 'state.dangerDefault',
            refund: 'state.done'
          }
          return (
            <TableV2TdText>
              <Tooltip
                text={ status === 'error' && t('entity.payment.field.status.value.error.tooltip', { email: 'info@influencity.com' })}
                type='dark'
                color='neutral.white'
                place='bottom'
              >
                <Flex gap='4px'>
                  <Icon name='dot' color={status ? colors[status] : colors[0]} />
                  <Text color='primary.dark'>{statusTranslated}</Text>
                </Flex>
              </Tooltip>
            </TableV2TdText>

          )
        }
      }
    }
    case 'poolStatus':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row }) => {
          const { status, statusTranslated } = row.original
          const colors = {
            pending: 'state.pending',
            sendingPayments: 'state.blocked',
            paid: 'state.active',
            error: 'state.dangerDefault'
          }
          return (
            <TableV2TdText>
              <Flex gap='4px'>
                <Icon name='dot' color={status ? colors[status] : colors[0]} />
                <Text color='primary.dark'>{statusTranslated}</Text>
              </Flex>
            </TableV2TdText>
          )
        }
      }
    }
    case 'paymentDescription':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, column }) => {
          const { _id, statusInPool, description } = row.original
          const { editDescription, loadingEditDescription } = useInvoicePayments()
          const { invoice } = useContext(InvoiceContext)
          const invoiceId = invoice?._id

          if (statusInPool === 'inPool') {
            return (<TableV2TdText value={description} />
            )
          }
          const disabled = statusInPool === 'added'
          const saveCell = async (columnId, rowId, value) => {
            await editDescription([rowId], value, invoiceId)
          }
          return (
            <TableV2TdTextEditable
              key={_id}
              name='paymentDescription'
              value={description} row={row} column={column}
              saveCell={saveCell}
              placeholder={t('entity.payment.field.description.placeholder')}
              disabled={disabled || loadingEditDescription } disabledTooltip={disabled && t('entity.payment.field.description.disabled.cannotBeModified.tooltip')}
            />)
        }
      }
    }
    /*
    case 'paymentCountry':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, column }) => {
          const { _id, statusInPool, country } = row.original
          const { editCountry, loadingEditCountry } = useInvoicePayments()
          const { invoice } = useContext(InvoiceContext)
          const invoiceId = invoice?._id

          if (statusInPool === 'inPool') {
            return (<TableV2TdText value={country} />
            )
          }
          const disabled = statusInPool === 'added'
          const saveCell = async (columnId, rowId, value) => {
            await editCountry([rowId], value?.value, invoiceId)
          }

          return (
            <TableV2TdSelect
              key={_id}
              name='paymentDescription'
              value={country} row={row} column={column}
              saveCell={saveCell}
              options={params?.paymentCountries || []}
              placeholder={t('entity.payment.field.country.placeholder')}
              disabled={disabled || loadingEditCountry} disabledTooltip={disabled && t('entity.payment.field.country.disabled.cannotBeModified.tooltip')}
          />)
        }
      }
    } */
    case 'paymentAmount':
    {
      return {
        label: labels[accessor],
        accessor,
        Header: TableEditableTh,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ row, column }) => {
          const { _id, statusInPool, amount } = row.original
          const { editAmount, loadingEditAmount } = useInvoicePayments()
          const { invoice } = useContext(InvoiceContext)
          const invoiceId = invoice?._id

          if (statusInPool === 'inPool') {
            return (<TableV2TdNumber value={amount || '-'} />
            )
          }
          const disabled = statusInPool === 'added'
          const saveCell = async (columnId, rowId, value) => {
            await editAmount([rowId], parseFloat(value), invoiceId)
          }
          return (
            <TableV2TdNumberEditable
              key={_id}
              name='paymentAmount'
              value={amount || ''} row={row} column={column}
              saveCell={saveCell}
              maxDigitsDecimal={2}
              placeholder={t('entity.payment.field.amount.placeholder')}
              disabled={disabled || loadingEditAmount} disabledTooltip={disabled && t('entity.payment.field.amount.disabled.cannotBeModified.tooltip')}
          />)
        }
      }
    }
    case 'socialListeningAlertMention':
    {
      return {
        accessor,
        label: t('entity.alert.field.numberOfMentions.label', { count: labels?.count >= 0 ? i18nNumber({ value: labels?.count, withSeparator: true }) : i18nNumber({ value: alert?.mentions, withSeparator: true }) }),
        Header: AlertMentionsListTableEditableTh,
        sortable: false,
        width: 350,
        minWidth: 200,
        maxWidth: 500,
        disableGlobalFilter: true,
        ...options,
        Cell: ({ row }) => {
          const handleMentionClick = () => {
            setMention(row.original)
          }

          const actions = {
            onMentionClick: handleMentionClick
          }

          return (
            <AlertMentionsListTableTdMention
              row={row}
              isTableRow
              {...actions}
            />
          )
        }
      }
    }
    case 'text':
    case 'link':
    default:
    {
      return {
        Header: TableEditableTh,
        label: labels[accessor],
        accessor,
        width: 150,
        minWidth: 80,
        maxWidth: 300,
        ...options,
        Cell: ({ value: rawValue, row }) => {
          let value = rawValue
          if (params.objectAccessor) {
            value = get(row.original, params.objectAccessor)
          }

          let _id = row.original?._id
          if (params.idAccessor) {
            _id = get(row.original, params.idAccessor)
          }
          const handleOnClick = params.handleOnClick ? () => params.handleOnClick(_id) : false
          const hasTranslations = !!row.original.translations
          if (!hasTranslations) {
            const hasData = Boolean(get(row.original, accessor)) || get(row.original, params.objectAccessor)
            if (!hasData) return <TableV2TdText value={'-'} />
            const { label, translationKey } = get(row.original, accessor, {})
            if (!label && !translationKey) return <TableV2TdText value={value ?? '-'} onClick={handleOnClick} />
            const text = label || t(translationKey)
            return <TableV2TdText value={text ?? '-'} onClick={handleOnClick} />
          }
          const translationKeys = row.original.translations[locale]
          return <TableV2TdText value={translationKeys ? translationKeys[accessor] : '-'} onClick={handleOnClick} />
        }
      }
    }
  }
}
