import { gql } from '@apollo/client'

export const getGiftingCodesByProgramIdColumns = gql`
  query getGiftingCodesByProgramIdColumns {
    getGiftingCodesByProgramIdColumns {
      label
      fieldName
      columnType
      type
    }
  }
`
