import { css } from 'styled-components'
import { Button, Flex, Icon, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const ConnectInboxModalHeader = ({ onClose }) => {
  const { t } = useTranslate()
  return (
    <>
      <Text size='20' variant='h2' color='neutral.white' bold>{t('commons.text.email')}</Text>
      <Flex>
        <Button
          styles={css`padding: 0;`}
          withIcon
          variant='flat'
          onClick={onClose}
        >
          <Icon
            size={32}
            color='white'
            name='close'
          />
        </Button>
      </Flex>
    </>
  )
}
