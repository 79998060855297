import { gql } from '@apollo/client'

export const editEntityView = gql`
  mutation editEntityView(
    $_id: String!
    $entityViewName: String!
    $filters: [JSON]
  ) {
    editEntityView(
      _id: $_id
      entityViewName: $entityViewName
      filters: $filters
    ) {
      message
      success
    }
  }
`
