import { useContext, useEffect } from 'react'
import { FormContext, StateButton } from 'influ-dms'
import { get } from 'lodash'
import { MESSAGE_LIMITS } from '../../../CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const NewEmailModalFooterSend = ({ isOpen, loading, handleSend }) => {
  const { t } = useTranslate()
  const { values, cleanForm } = useContext(FormContext)
  const isUserInputEmpty = get(values, 'html', '').replace(/<(.|\n)*?>/g, '').trim().length === 0
  const attachmentsLengthExceeded = get(values, 'attachments.length', 0) > MESSAGE_LIMITS.attachmentCount
  const isFormDisabled = !get(values, 'to.length') || !get(values, 'subject') || isUserInputEmpty || attachmentsLengthExceeded

  useEffect(() => {
    if (!isOpen) {
      cleanForm()
    }
  }, [isOpen])

  const handleClick = () => {
    handleSend(values)
  }
  return (
    <StateButton onClick={handleClick} disabled={isFormDisabled} loading={loading} showLoader>{t('commons.button.sendNow')}</StateButton>
  )
}
