import { useEffect } from 'react'
import { Form, SideBox, SideModal, Spacing, Text, Heading, Loader } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { errorStyles } from '~/containers/reports/influencers/styles'
import { CreateReportModalFooter } from './CreateReportModalFooter'
import { CreateReportModalTitle } from './CreateReportModalTitle'

export const CreateReportModal = ({ isOpen, handleCreate, handleClose, loading, error, clearErrors }) => {
  const { t } = useTranslate()

  useEffect(() => {
    const hubspotIframe = document.getElementById('hubspot-messages-iframe-container')
    if (isOpen && hubspotIframe) hubspotIframe.style.marginBottom = '1.3rem'
    return () => { if (hubspotIframe) hubspotIframe.style.marginBottom = '0' }
  }, [isOpen])

  return (
    <SideModal
      name='createReport'
      onClose={handleClose}
      isOpen={isOpen}
      title={t('reports.modal.createReport.header')}
    >
      <SideBox>
        <Heading variant='sb' size='3'>{t('reports.modal.createReport.mainInfoBlock.title')}</Heading>
        <Spacing />
        <CreateReportModalTitle isOpen={isOpen} clearErrors={clearErrors} />
        <Spacing />
        <Form.TextArea placeholder={t('reports.modal.createReport.mainInfoBlock.description.input.placeholder')} name='description' label={t('reports.modal.createReport.mainInfoBlock.description.input.label')} rows='6' resize='vertical' />
        <Spacing />
        {loading && <Loader fillSpace />}
        {error && <Text styles={errorStyles} color='state.dangerDefault'>{error}</Text>}
        <CreateReportModalFooter isOpen={isOpen} handleCreate={handleCreate} handleClose={handleClose} loading={loading} />
      </SideBox>
    </SideModal>)
}
