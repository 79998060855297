import { gql } from '@apollo/client'

export const getEntityViewsByObjectAndUser = gql`
  query getEntityViewsByObjectAndUser(
    $brandId: String!
    $object: EntityViewObjectEnumType!
  ) {
    getEntityViewsByObjectAndUser(brandId: $brandId, object: $object) {
      sortedShowedViews {
        _id
        createdAt
        updatedAt
        brandId
        entityViewName
        type
        object
        inUse
        filters
        translations {
          de {
            object
            entityViewName
          }
          en {
            object
            entityViewName
          }
          es {
            object
            entityViewName
          }
          fr {
            object
            entityViewName
          }
          it {
            object
            entityViewName
          }
          pt {
            object
            entityViewName
          }
        }
        users {
          _id
        }
        order
        createdByUser {
          email
        }
        updatedByUser {
          email
        }
      }
      unshowedViews {
        _id
        createdAt
        updatedAt
        brandId
        entityViewName
        type
        object
        inUse
        filters
        translations {
          de {
            object
            entityViewName
          }
          en {
            object
            entityViewName
          }
          es {
            object
            entityViewName
          }
          fr {
            object
            entityViewName
          }
          it {
            object
            entityViewName
          }
          pt {
            object
            entityViewName
          }
        }
        users {
          _id
        }
        order
        createdByUser {
          email
        }
        updatedByUser {
          email
        }
      }
    }
  }
`