import { gql } from '@apollo/client'

export const getPaymentInvoice = gql`
  query getPaymentInvoice( 
    $_id: String!
  ) {
    getPaymentInvoice(_id: $_id) {
      _id
      brandId
      name
      currency
      description
      orderNumber
      status
      amount
      fee
      feePercentage
      tax
      taxPercentage
      total
      paymentsCount
      invoiceNumber
      clientGenerationDate
      billingEmail
    }
  }
`
