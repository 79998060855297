import { gql } from "@apollo/client";

export const deletePaymentInvoices = gql`
  mutation deletePaymentInvoices($paymentInvoiceIds: [String]!) {
    deletePaymentInvoices(paymentInvoiceIds: $paymentInvoiceIds) {
      message
      code
      success
    }
  }
`