import { get } from 'lodash'
import { useRouter } from 'next/router'
import { RouterHelper } from '~/routing/routerHelper'
import {
  Spacing,
  Text,
  LinkAsButton,
  Button
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import getConfig from 'next/config'
import { useBrandData } from '~/hooks'

const { publicRuntimeConfig } = getConfig()
const { influInfoEmail } = publicRuntimeConfig

const LimitButton = (props) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname')
  const isPublic = pathname && pathname.includes('public')
  const skip = isPublic || !brandId
  const { isEnterprise, isTrial } = useBrandData({ variables: { brandId }, skip })

  const { t } = useTranslate()

  const {
    onClose,
    subtitle
  } = props

  const text = isEnterprise || isTrial ? subtitle : t('commons.modals.blocked.titleParagraph1.upgradeYourPlanToUseThisFeature')

  const handleClickDefault = () => {
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
    onClose()
  }

  const mailto = `mailto:${influInfoEmail}?Subject=I%20want%20to%20increase%20my%20limits`

  const enterpriseButton = () => {
    return (
      <LinkAsButton
        name='limitModalEnterprise'
        href={mailto}
      >
        {t('commons.button.talkToUS')}
      </LinkAsButton>
    )
  }

  const trialButton = () => {
    return (
      <Button
        onClick={handleClickDefault}
      >
        {t('commons.button.seePlansAndPrices')}
      </Button>
    )
  }

  const defaultButton = () => {
    return (
      <Button
        onClick={handleClickDefault}
      >
        {t('commons.button.upgradeNow')}
      </Button>)
  }

  const renderButton = () => {
    if (isEnterprise) return enterpriseButton()
    if (isTrial) return trialButton()
    return defaultButton()
  }

  return (
    <>
      <Text center size='14'>
        {text}
      </Text>
      <Spacing size='32' />
      {renderButton()}
    </>
  )
}

export { LimitButton }
