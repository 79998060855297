import { get } from 'lodash'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useBrandData } from '~/hooks'
import { RefreshRemainingFeatureFlagsContext } from '~/context/RefreshRemainingFeatureFlags'
import { useLimitModalsOnRefresh } from './useLimitModalsOnRefresh'

export const useRefreshAction = () => {
  const {
    notShowingRefreshModal,
    handleModalForLimitReached,
    handleModalForUnavailableFeature
  } = useLimitModalsOnRefresh()

  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { featureFlags } = useBrandData({ variables: { brandId }, skip: !brandId })

  const getNetworksFromFeatureFlags = get(featureFlags, 'irm.networks')

  const { remainingProfileAnalyses } = useContext(RefreshRemainingFeatureFlagsContext)
  const hasRemainingAnalysis = remainingProfileAnalyses === null || remainingProfileAnalyses > 0

  const doSingleRefresh = ({
    profileNetwork = '',
    setIsOpenRefreshModal,
    handleUpdateProfileMutation
  }) => {
    if (profileNetwork && !getNetworksFromFeatureFlags.includes(profileNetwork)) {
      return handleModalForUnavailableFeature()
    }
    if (notShowingRefreshModal) {
      if (hasRemainingAnalysis) {
        handleUpdateProfileMutation()
        return
      } else {
        handleModalForLimitReached()
      }

      return
    }
    setIsOpenRefreshModal(true)
  }

  return {
    doSingleRefresh,
    hasRemainingAnalysis
  }
}
