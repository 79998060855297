import PropTypes from 'prop-types'
import { get } from 'lodash'
import { withRouter } from 'next/router'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

export const networkSummaryQuery = gql`
 query networkSummaryQuery(
   $brandId: String!,
 ) {
  brand(_id: $brandId){
    id: _id
  }
 }
 `

const NetworkSummaryQuery = (props) => {
  const brandId = get(props, 'router.query.brandId')
  const variables = { brandId }
  return (
    <Query
      query={networkSummaryQuery}
      variables={variables}
      fetchPolicy='network-only'
    >
      {(data) => {
        const loading = get(data, 'loading')
        const networkSummary = get(data, 'data.brand.networkSummary') || {}
        const error = get(data, 'error')
        return props.children({ loading, networkSummary, error })
      }}
    </Query>
  )
}

NetworkSummaryQuery.propTypes = {
  children: PropTypes.func.isRequired
}

const NetworkSummaryQueryWithRouter = withRouter(NetworkSummaryQuery)
export { NetworkSummaryQueryWithRouter as NetworkSummaryQuery }
