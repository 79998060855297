import { gql } from '@apollo/client'

export const addInfluencersToInvoice = gql`
  mutation addInfluencersToInvoice(
    $influencerIds: [String]!
    $paymentInvoiceIds: [String]!
  ) {
    addInfluencersToInvoice(
      influencerIds : $influencerIds
      paymentInvoiceIds: $paymentInvoiceIds
    ){
      message
      code
      success
    }
  }
`
