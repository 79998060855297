import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { brand } from '../../graphql/brands'
import { RouterHelper } from '../../routing/routerHelper'
import { NavbarContainer } from '../navbar'
import { NavbarTop } from '../navbar/NavbarTop'
import styled, { createGlobalStyle } from 'styled-components'

// css global variable
const GlobalStyles = createGlobalStyle`
  :root {
    --trialHeaderHeight: ${props => props.showTopNavbar ? 50 : 0}px;
  }
`

const LayoutRow = styled.div`
  display: flex;
  height: calc(100vh - var(--trialHeaderHeight));
`

const NavBarSpacing = styled.section`
  display: flex;
  width: 48px;
  height: 100%;
  flex-direction: column;
`

const LayoutContent = styled.section`
  display: flex;
  max-width: calc(100vw - 48px);
  height: 100%;
  width: 100%;
  flex-direction: column;
  background: ${({ color, theme }) => color || theme.colors.neutral.white};
`

export const Layout = (props) => {
  const { children, color } = props
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const brandId = get(router, 'query.brandId')

  const skip = isPublic || !brandId
  const { data: dataBrand } = useQuery(brand, { variables: { brandId }, skip })

  const pathname = router.pathname
  const isTrial = get(dataBrand, 'brand.trial.isTrial')
  const subscriptionInfoStatus = get(dataBrand, 'brand.subscriptionInfo.status')

  const subscriptionCreatedAt = get(dataBrand, 'brand.subscriptionInfo.createdAt')

  const upgradePlan = () => {
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }

  const renderTopNavbar = pathname => {
    const expired = get(dataBrand, 'brand.trial.expired')
    const remainingDays = get(dataBrand, 'brand.trial.remainingDays')
    const isPricesPage = pathname.includes('pricesAndPlans')
    const isPaymentPage = pathname.includes('subscriptionForm')
    const isUpgradePaymentPage = pathname.includes('upgradeSubscription')
    const excludedPages = !isPricesPage && !isPaymentPage && !isUpgradePaymentPage

    const trialNavbar = isTrial && !subscriptionCreatedAt
    const paymentsNavbar = subscriptionInfoStatus === 'past_due' || subscriptionInfoStatus === 'incomplete' || subscriptionInfoStatus === 'incomplete_expired'
    const showTopNavbar = (trialNavbar || paymentsNavbar) && excludedPages

    return (
      <>
        <GlobalStyles showTopNavbar={showTopNavbar} />
        {showTopNavbar &&
          <NavbarTop paymentsNavbar={paymentsNavbar} expired={expired} remainingDays={remainingDays} upgradePlan={upgradePlan} subscriptionInfoStatus={subscriptionInfoStatus}/>
        }
      </>
    )
  }

  return (
    <>
      {renderTopNavbar(pathname)}
      <LayoutRow>
        <NavbarContainer />
        <NavBarSpacing />
        <LayoutContent color={color}>
          {children}
        </LayoutContent>
      </LayoutRow>
    </>
  )
}
