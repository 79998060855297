import { useContext } from 'react'
import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { headerStyles } from '../../styles'
import { CurrencyContext } from '../../../../../../commons/context/CurrencyContext'
import { useTranslate } from '~/hooks/useTranslate'

export const AddPostsManualPostCost = () => {
  const { t } = useTranslate()
  const { symbol } = useContext(CurrencyContext)
  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationCostBlock.title')}</Text>
      <Spacing size='24' />
      <Form.InputNumber placeholder={`${t('commons.insights.publicationCost.input.placeholder')} (${symbol})`} name='cost' label={`${t('commons.insights.publicationCost.input.label')} (${symbol})`} withDecimal />
      <Spacing size='8' />
    </SideBox>
  )
}
