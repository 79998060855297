import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles
} from './styles'
import { useProperties } from '../../commons/hooks/useProperties'

export const PropertiesListTableTdPropertyName = ({
  row: { original },
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslate()
  const property = original || {}
  const { id, fieldName, origin, irmInfluencers } = property
  const isDisabledEdit = origin === 'protected'
  const isDisabledDeleteInflu = origin === 'default' || origin === 'protected'
  const isDisabledDeleteInUse = irmInfluencers && irmInfluencers.length > 0
  const isDisabledDuplicate = origin === 'protected'
  const { duplicateEntityField, loadingDuplicateEntityField } = useProperties()

  const handleEditClick = (e) => {
    if (!isDisabledEdit) {
      onEditClick(e)
    }
  }

  const handleDuplicateClick = async (e) => {
    if (!isDisabledDuplicate && !loadingDuplicateEntityField) {
      await duplicateEntityField(id)
    }
  }

  const handleDeleteClick = (e) => {
    if (!(isDisabledDeleteInflu || isDisabledDeleteInUse)) {
      onDeleteClick(e)
    }
  }

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis size='14' color='primary.light2' bold>
            {fieldName}
          </Text>
        </StyledContainer>
        <HoverWrapper>
          {onEditClick && <Tooltip
            text={isDisabledEdit ? t('commons.button.edit.disable.tooltip') : t('commons.button.edit.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={handleEditClick} iconName='edit' disabledIcon={isDisabledEdit} />
          </Tooltip>}
          { <Tooltip
            text={isDisabledDuplicate ? t('commons.button.duplicate.disable.tooltip') : t('commons.button.duplicate.delete')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
        >
            <HoverAction onClick={handleDuplicateClick} iconName='duplicate' disabledIcon={isDisabledDuplicate} />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={(isDisabledDeleteInflu || isDisabledDeleteInUse) ? t('commons.button.delete.properties.disable.tooltip') : t('commons.button.delete.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={handleDeleteClick} iconName='trash' disabledIcon={(isDisabledDeleteInflu || isDisabledDeleteInUse)} />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
