import { gql } from '@apollo/client'

export const editEntityField = gql`
  mutation editEntityField(
    $_id: String!
    $groupName: String!
    $fieldName: String
    $description: String
    $fieldData: [EntityFieldFieldOptionsType]
  ) {
    editEntityField(
      _id: $_id
      groupName: $groupName
      fieldName: $fieldName
      description: $description
      fieldData: $fieldData
    ) {
      message
      success
    }
  }
`
