import { gql } from '@apollo/client'

export const deactivateIrmInfluencersInProgram = gql`
  mutation deactivateIrmInfluencersInProgram(
    $programIds: [String!]!
    $influencerIds: [String!]!
  ) {
    deactivateIrmInfluencersInProgram(
      programIds: $programIds
      influencerIds: $influencerIds
    ) {
      message
      code
      success
      data
    }
  }
`
