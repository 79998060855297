import { Button, EmptyState } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { RouterHelper } from '~/routing/routerHelper'

export const TemplatesLimitReached = ({ onClose }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const goToPricesPlans = () => {
    onClose()
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }

  return (
    <EmptyState
      imageUrl={'/static/img/error.png'}
      titleText={t('modal.newEmailTemplate.limitReached.titleHeader1')}
      subtitleText={t('modal.newEmailTemplate.limitReached.titleHeader2')}
    >
      <Button size='l' onClick={goToPricesPlans}>
        {t('commons.button.seePlansAndPrices')}
      </Button>
    </EmptyState>
  )
}
