import { gql } from '@apollo/client'

export const editAudienceBenefitCode = gql`
  mutation editAudienceBenefitCode(
    $programId: String!
    $couponId: String!
    $influencerInProgramId: String!
    $seedCode: SeedCodeInputObjectType!
  ) {
    editAudienceBenefitCode(
      programId: $programId
      couponId: $couponId
      influencerInProgramId: $influencerInProgramId
      seedCode: $seedCode
    ){
        message
        code
        success
      }
  }
 `
