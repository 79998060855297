import { gql } from '@apollo/client'

export const editTemplate = gql`
  mutation editTemplate(
    $brandId: String!
    $_id: String!
    $name: String!
    $subject: String!
    $html: String!
    $attachments: [EmailTemplateAttachmentInputType]
  ) {
    editTemplate(
      brandId: $brandId
      _id: $_id
      name: $name
      subject: $subject
      html: $html
      attachments: $attachments
    ) {
      message
      success
    }
  }
`
