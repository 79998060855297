/**
 * It takes an object and returns a new object with all the null values removed
 * @param obj - The object to be filtered.
 * @param [restrictedKeys] - An array of keys that should not be filtered out.
 * @returns An object with all the key-value pairs from the original object that have a truthy value.
 */
export function clearNullObjectValues (obj, restrictedKeys = []) {
  if (!obj) return
  return Object.fromEntries(
    Array.from(Object.entries(obj))
      .filter(([k, v]) => !restrictedKeys.includes(k) && Boolean(v))
  )
}
