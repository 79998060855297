import { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { css } from 'styled-components'
import { get } from 'lodash'
import { Flex, ProgressStep } from 'influ-dms'
import { ConnectForm } from './connect/ConnectForm'
import { DetailsForm } from './details/DetailsForm'
import { createCommunicationAccountMutation } from '../../apollo/communicationMutations'
import { ConnectError } from './result/ConnectError'
import { CommunicationContext } from '../../CommunicationContext'
import { ConnectSuccess } from './result/ConnectSuccess'
import { COMMUNICATION_ACTIONS } from '../../CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

const progressStyles = css`
  width: 380px;
`
export const Forms = ({ server, handleClose }) => {
  const { t } = useTranslate()
  const { communicationAction, setCommunicationAction } = useContext(CommunicationContext)
  const [formStep, setFormStep] = useState(0)
  const [error, setError] = useState()
  const [accountSettings, setAccountSettings] = useState()
  const [loading, setLoading] = useState(false)
  const [createCommunicationAccount] = useMutation(createCommunicationAccountMutation)

  const errorText = {
    12418: t('settings.yourPreferences.communication.email.configuration.modal.connectOfficeInbox.errorMessage.emailAccountConfigured')
  }

  const handleConnect = values => {
    setLoading(true)
    setError(undefined)
    const variables = { ...values }
    delete variables.errors
    createCommunicationAccount({ variables })
      .then(response => {
        const success = get(response, 'data.createCommunicationAccount.success')
        if (!success) {
          setFormStep(undefined)
          setError(errorText[get(response, 'data.createCommunicationAccount.code')])
          setCommunicationAction({ type: COMMUNICATION_ACTIONS.accountCreationFailed })
          setLoading(false)
        }
      }).catch(() => {
        setFormStep(undefined)
        setLoading(false)
      })
  }

  const handleSuccess = () => {
    setFormStep(2)
  }

  const handleRetry = () => {
    handleClose()
  }
  useEffect(() => {
    if (communicationAction) {
      if (communicationAction.type === COMMUNICATION_ACTIONS.newAccountConnected) {
        setFormStep(1)
        setAccountSettings({ name: get(communicationAction, 'data.account') })
        setLoading(false)
      }
    }
  }, [communicationAction])

  if (formStep === undefined) return <ConnectError handleRetry={handleRetry} buttonText={t('commons.button.close')} error={error} />
  if (formStep === 2) return <ConnectSuccess handleClose={handleClose} />

  return (
    <>
      <Flex center>
        <ProgressStep steps={[t('commons.communication.form.steps.connectAccount'), t('commons.communication.form.steps.emailDetails')]} size={14} currentStep={formStep} styles={progressStyles} />
      </Flex>
      {formStep === 0 && <ConnectForm loading={loading} server={server} handleConnect={handleConnect} handleCancel={handleClose} />}
      {formStep === 1 && <DetailsForm isClosable accountSettings={accountSettings} handleSuccess={handleSuccess} handleClose={handleClose} isActiveSubscription />}
    </>
  )
}
