import { useState, useContext } from 'react'
import {
  Flex,
  Form,
  FormInputWithValidations,
  Icon,
  Modal,
  Portal,
  SnackbarContext,
  Spacing
} from 'influ-dms'
import { get, isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { brand } from '~/graphql/brands'
import { useTranslate } from '~/hooks/useTranslate'
import { ErrorHelperText } from '../../../NewOrgainzation/Steps/ErrorHelperText'
import { TemplateContext } from '../context/TemplateContext'
import { useTemplates } from '../hooks/useTemplates'
import { TemplatesLimitReached } from './TemplatesLimitReached'
import { CreateEditTemplateModalFooter } from './CreateEditTemplateModalFooter'
import { css, ThemeContext } from 'styled-components'

const iconStyles = css`
  display: inline;
  margin-right: 2px;
`

const modalStyles = css`
  padding: 24px 32px 12px 32px;
`

export const SaveTemplateModal = () => {
  const { t } = useTranslate()
  const { colors: { gradients } } = useContext(ThemeContext)
  const [term, setTerm] = useState('')
  const [showError, setShowError] = useState(false)
  const { createTemplate, loadingCreateTemplate } = useTemplates()
  const { template, isSaveTemplateModalOpen, setSaveTemplateModalOpen } = useContext(TemplateContext)
  const { showSnackbar } = useContext(SnackbarContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname', '')
  const isPublic = pathname.includes('public')
  const skip = isPublic || !brandId
  const { data } = useQuery(brand, { variables: { brandId }, skip })
  const remainingTemplates = get(data, 'brand.featureFlags.irm.remainingTemplates')

  const requiredValidation = {
    checkFunction: isEmpty,
    text: <>
      <Icon name='danger' color='state.dangerDefault' styles={iconStyles} />
      {t('commons.helperError.mandatoryField.text')}
    </>
  }

  const handleSendForm = async () => {
    const values = {
      name: term,
      html: template.html,
      subject: template.subject,
      attachments: template.attachments
    }
    const response = await createTemplate(values)
    const errorCode = get(response, 'graphQLErrors[0].extensions.code')
    const { success, type } = response
    if (!response) {
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    } else if (success) {
      setSaveTemplateModalOpen(false)
      return true
    } else if (errorCode === 12700) {
      setShowError(true)
      return false
    } else {
      if (type === 'snackbarError') {
        showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      }
      return false
    }
  }

  const handleCancel = () => {
    setShowError(false)
    setTerm('')
    setSaveTemplateModalOpen(false)
  }

  return (
    <Portal selector='#saveTemplateModal'>
      <Modal
        name='saveTemplateModal'
        color='state.dangerDefault'
        isOpen={isSaveTemplateModalOpen}
        onClose={handleCancel}
        title={t('modal.newEmailTemplate.header')}
        width='640'
        height={remainingTemplates <= 0 ? '500' : '230'}
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
        styles={modalStyles}
        zIndex='107'
      >
        {remainingTemplates <= 0
          ? <TemplatesLimitReached onClose={() => setSaveTemplateModalOpen(false)} />
          : <Form>
            <Flex column grow='1'>
              <FormInputWithValidations validations={[requiredValidation]} name='name'>
                {({ name, onChange, variant }) => (
                  <Form.InputText
                    variant={showError ? 'danger' : variant}
                    placeholder={t('entity.template.field.name.placeholder')}
                    name={name}
                    label={t('entity.template.field.name.label')}
                    onChange={(e) => {
                      const value = e.target.value
                      setTerm(value)
                      setShowError(false)
                      onChange()
                    }}
                    helperText={
                      showError && <ErrorHelperText errorText={t('entity.template.field.name.helpertext.error.duplicated')} />
                    }
                  />
                )}
              </FormInputWithValidations>
            </Flex>
            <Spacing size='72' />
            <CreateEditTemplateModalFooter
              handleSubmit={handleSendForm}
              loading={loadingCreateTemplate}
              handleCancel={() => setSaveTemplateModalOpen(false)}
              showAttachButton={false}
              quickSave={true}
            />
          </Form>
        }
      </Modal>
    </Portal>
  )
}
