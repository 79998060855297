import { useContext, useMemo } from 'react'
import { Form, FormContext, SideBox, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '~/context/Language'
import { headerStyles } from '../styles'

export const AddPostsManualPostType = ({ disabled }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const { values: { network }, cleanFormExcept } = useContext(FormContext)
  const handlePostTypeChange = () => {
    cleanFormExcept(['username', 'network', 'profileUrl', 'type'])
  }

  const postTypeByNetworkOptions = useMemo(() => {
    return {
      instagram: [
        { value: 'carousel', label: t('commons.publicationType.carousel.label') },
        { value: 'photo', label: t('commons.publicationType.photo.label') },
        { value: 'reel', label: t('commons.publicationType.reels.label') },
        { value: 'story', label: t('commons.publicationType.story.label') }
      ],
      tiktok: [
        { value: 'video', label: t('commons.publicationType.video.label') },
        { value: 'photo', label: t('commons.publicationType.photo.label') }
      ],
      youtube: [
        { value: 'video', label: t('commons.publicationType.video.label') },
        { value: 'short', label: t('commons.publicationType.short.label') }
      ]
    }
  }, [locale])

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationTypeBlock.title')}</Text>
      <Spacing size='16' />
      <Form.Select
        placeholder={t('commons.insights.type.input.placeholder')}
        label={t('commons.insights.type.input.label')}
        name='type'
        options={network ? postTypeByNetworkOptions[network] : []}
        isDisabled={disabled}
        onChange={handlePostTypeChange}
      />
      <Spacing size='8' />
    </SideBox>
  )
}
