import styled, { css } from 'styled-components'
import { CellWrapper, Flex, Icon, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { cellPadding } from '../../../reportsTable/styles'

const StyledContainer = styled(Flex)`
  cursor: pointer;
  ${cellPadding};
`
const textStyles = css`
  margin-left: 4px;
`

export const ApiAccessTrackingTableTd = ({ row: { original: { apiAccess } } }) => {
  const { t } = useTranslate()
  return (
    <CellWrapper>
      <Tooltip text={t('reports.modal.tracking.apiAccess.influencerAcceptInvitation.tooltip')} type='dark' color='neutral.white'>
        <StyledContainer align='center'>
          {apiAccess ? <Icon name='radiochecked' color='state.active' /> : <Icon name='closecircle' color='state.dangerDefault' />}
          <Text color='primary.dark' size='12' styles={textStyles}> {apiAccess ? t('commons.table.cell.registerdInfluencer.yes.label') : t('commons.table.cell.registerdInfluencer.no.label')} </Text>
        </StyledContainer>
      </Tooltip>
    </CellWrapper>
  )
}
