import { gql } from '@apollo/client'

export const sendEmailMessageMutation = gql`
  mutation sendEmailMessage (
    $to: [AddressSchemaInputType]!
    $cc: [AddressSchemaInputType]
    $bcc: [AddressSchemaInputType]
    $reference: EmailReferenceInputType
    $subject: String!
    $html: String!
    $influencerId: String
    $attachments: [EmailAttachmentInputType]
  ) {
    sendEmailMessage (
      to: $to
      cc: $cc
      bcc: $bcc
      reference: $reference
      subject: $subject
      html: $html
      influencerId: $influencerId
      attachments: $attachments
    ) {
      success     
    }
  }
`

export const deleteEmailMessageMutation = gql`
  mutation deleteEmailMessage (
    $messageId: String!
  ) {
    deleteEmailMessage (
      messageId: $messageId
    ) {
      success     
    }
  }
`
