import { Button, EmptyState } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { RouterHelper } from '~/routing/routerHelper'

export const EmptyDomains = ({ brandId, onClose }) => {
  const { t } = useTranslate()
  const router = useRouter()

  const goToSettingsCommunication = () => {
    onClose()
    router.push(`${RouterHelper.getRoute('communication', { brandId })}#bulkEmail`)
  }

  return (
    <EmptyState
      imageUrl={'/static/img/irm/influencers/no_domain.svg'}
      titleText={t('modal.newMessage.setUpBulkEmail.title.header1')}
      subtitleText={t('modal.newMessage.setUpBulkEmail.title.paragraph1')}
    >
      <Button size='l' onClick={goToSettingsCommunication}>
        {t('commons.button.setUpBulkEmail')}
      </Button>
    </EmptyState>
  )
}
