import { gql } from "@apollo/client";

export const getIRMListsByBrand = gql`
  query getIRMListsByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: IrmListSortParamsType, 
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getIRMListsByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      id: _id
      listName
      type
      object
      size
      pictures
      translations {
        es {
          object
          type
        }
        en {
          object
          type
        }
        fr {
          object
          type
        }
        de {
          object
          type
        }
        it {
          object
          type
        }
        pt {
          object
          type
        }
      }
      profiles {
        id: _id
      }
      irmInfluencers { 
       _id
      }
      createdByUser {
        firstName
      }
      createdAt
      updatedAt
    }
  }
`
