import { Text } from 'influ-dms'
import { useRouter } from 'next/router'
import { AvatarDropdownOptionItem } from './AvatarDropdownOptionItem'
import { css } from 'styled-components'
import { useTranslate } from '~/hooks/useTranslate'
import { RouterHelper } from '~/routing/routerHelper'
import { useMutation } from '@apollo/client'
import { logout } from '~/graphql'
import { get } from 'lodash'
import cookiesParser from '../../../../helpers/cookiesParser'

const containerStyles = css`
  padding: 7px 4px;
`

export const AvatarContactSignOut = ({ handleClick }) => {
  const { t } = useTranslate()
  const [logoutMutation] = useMutation(logout)
  const router = useRouter()

  const logoutClick = () => {
    const token = get(cookiesParser(typeof window !== 'undefined' && document), 'token', null)
    if (token) {
      const variables = { token }
      logoutMutation({ variables })
        .finally(() => {
          router.push(RouterHelper.getRoute('logout'))
          handleClick()
        })
    }
  }

  return (
    <AvatarDropdownOptionItem
      withHover
      styles={containerStyles}
      handleClick={logoutClick}
      data-cy='Avatar-contact-Option-logout'
    >
      <Text ellipsis color='primary.light3' size='14'>{t('commons.topNavigation.user.dropdown.listItem.signOut.link')}</Text>
    </AvatarDropdownOptionItem>
  )
}
