import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Form, Spacing } from 'influ-dms'
import { NewEmailModalFooter } from './footer/NewEmailModalFooter'
import { NewEmailModalTo } from './NewEmailModalTo'
import { NewEmailModalMessageBody } from './NewEmailModalMessageBody'
import { NewEmailModalCC } from './NewEmailModalCC'
import { useTranslate } from '~/hooks/useTranslate'
import styled from 'styled-components'

const EmailContentWrapper = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  height: 100%;
`

export const NewEmailModalContent = ({ isOpen, show, loading, email, handleSend }) => {
  const { t } = useTranslate()
  const [showCc, setShowCC] = useState(false)
  const [showBcc, setShowBCC] = useState(false)
  const subjectDisabled = ['reply', 'forward'].includes(get(email, 'type'))

  const handleCcClick = () => {
    setShowCC(true)
  }

  const handleBccClick = () => {
    setShowBCC(true)
  }

  useEffect(() => {
    if (isOpen) {
      const hasCC = get(email, 'cc', []).length > 0
      setShowCC(hasCC)
      const hasBCC = get(email, 'bcc', []).length > 0
      setShowBCC(hasBCC)
    } else {
      setShowCC(false)
      setShowBCC(false)
    }
  }, [isOpen])

  return (
    <EmailContentWrapper show={show}>
      <NewEmailModalTo isOpen={isOpen} name='to' email={email} placeholder={t('influencers.modal.composeMessage.input.recipients.to.placeholder')}>
        <NewEmailModalCC showCc={showCc} handleCcClick={handleCcClick} showBcc={showBcc} handleBccClick={handleBccClick} />
      </NewEmailModalTo>
      {showCc && (
        <>
          <Spacing size='16' />
          <NewEmailModalTo isOpen={isOpen} name='cc' email={email} placeholder={t('influencers.modal.composeMessage.input.recipients.cc.placeholder')} />
        </>
      )}
      {showBcc && (
        <>
          <Spacing size='16' />
          <NewEmailModalTo isOpen={isOpen} name='bcc' email={email} placeholder={t('influencers.modal.composeMessage.input.recipients.bcc.placeholder')} />
        </>
      )}
      <Spacing size='16' />
      <Form.InputText name='subject' placeholder={t('influencers.modal.composeMessage.input.subject.placeholder')} disabled={subjectDisabled} />
      <Spacing size='16' />
      <NewEmailModalMessageBody isOpen={isOpen} email={email} />
      <Spacing size='16' />
      <NewEmailModalFooter isOpen={isOpen} loading={loading} handleSend={handleSend} />
    </EmailContentWrapper>
  )
}
