import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * body{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;

    h1, h2, h3, h4, span, p, div, input, textarea, select, button{
      font-family: 'Rubik', sans-serif;
      margin: 0px;
    }
    *:focus {
      outline: none;
    }
  }
  
  body {
    -webkit-print-color-adjust: exact !important;
    overflow: hidden;
  }

  body ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  body ::-webkit-scrollbar {
   width: 6px !important;
   height: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: #C5C5D2;
    transition: color .5s ease;
    :hover {
      background-color: #968EA9;
    }
  }

  * input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-animation: autofill 0s forwards; 
    animation: autofill 0s forwards; 
  } 
  @keyframes autofill { 
    100% { background: transparent; color: inherit; } 
  } 
  @-webkit-keyframes autofill { 
    100% { background: transparent; color: inherit; } 
  }

  b {
    font-weight: 500;
  }
  
  /* semantic-ui legacy */
  a {
    color: #4183c4;
    text-decoration: none;
    :hover {
      color: #1e70bf;
    }
  }
  html {
    line-height: 1.15;
  }
  body, p {
    line-height: 1.4285em;
  }
  button, input, optgroup, select, textarea {
    font-size: 100%;
    line-height: 1.15;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h1, h2, h3, h4, h5 {
      line-height: 1.28571429em;
      padding: 0;
      font-weight: 700;
  }
  /* semantic-ui legacy */
`
