import { gql } from "@apollo/client";

export const getTableProfilesInListByBrand = gql`
  query getTableProfilesInListByBrand(
    $listId: String
    $sharedId: String
    $sortParams: ProfileTableSortParamsType, 
    $page: Int
    $limit: Int
    $filters: [NewFilterParamsType],
    $view: String
  ) {
    getTableProfilesInListByBrand(listId: $listId, sharedId: $sharedId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      _id
      irmInfluencers {
        _id
        alias
        picture
      }
      irmLists {
        _id
      }
      updatedAt
      createdAt
      lastRefreshDate
      brandId
      network
      username
      language {
        key
        value
        label
        translationKey
      }
      city {
        key
        value
        label
        translationKey
      }
      country {
        key
        value
        label
        translationKey
      }
      state {
        key
        value
        label
        translationKey
      }
      locationLabel
      profilePhotoUrl
      profilePhotoUrlOriginal
      analyzeProfile
      private
      postNumber
      isVerified
      accountType{
        key
        value
        label
        translationKey
      }
      status{
        key
        value
        label
        translationKey
      }
      engagement
      followersQuality
      noAudienceDataAvailable
      avgLikes
      avgViews
      avgDislikes
      avgComments
      totalLikes
      totalViews
      avgReelsPlays
      avgInteractions
      followerCount
      gender {
        key
        value
        label
        translationKey
      }
      age {
        key
        value
        label
        translationKey
      }
      audienceTopBrandAffinity {
        key
        value
        label
        translationKey
      }
      audienceTopLanguage {
        key
        value
        label
        translationKey
      }
      audienceTopGender {
        key
        value
        label
        translationKey
      }
      audienceTopCountry {
        key
        value
        label
        translationKey
      }
      audienceTopCity {
        key
        value
        label
        translationKey
      }
      audienceTopState {
        key
        value
        label
        translationKey
      }
      audienceTopAge {
        key
        value
        label
        translationKey
      }
      audienceTopInterest {
        key
        value
        label
        translationKey
      }
      audienceTopEthnicity {
        key
        value
        label
        translationKey
      }
      audienceTopBrandAffinity {
        key
        value
        label
        translationKey
      }
      createdByUser{
        userId
        firstName
        lastName
        profilePhoto
      }
      updatedByUser{
        userId
        firstName
        lastName
        profilePhoto
      }
      originalSource {
        key
        label
      }
      streamChannelType {
        key
        value
        label
      }
      streamTopCategory {
          key
          value
          label
      }
      streamHoursWatched
      streamPeakViewers
      streamAirtime
      streamPartnershipStatus {
          key
          value
          label
      }
    }
  }
`

