import styled from 'styled-components'
import { Text } from 'influ-dms'
import { shadowLight } from '../../../theme/styles'

const SettingsCardContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutral.white};
  width: fit-content;
  height: fit-content;
  ${shadowLight}
  ${({ styles }) => styles || ''};
`

const StyledTitle = styled(Text)`
  padding: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
`

export const SettingsCard = ({ title, children, styles, dataCy }) => (
  <SettingsCardContainer styles={styles} data-cy={dataCy}>
    <StyledTitle size='13' color='primary.light3'>{title}</StyledTitle>
    {children}
  </SettingsCardContainer>
)
