import { isEmpty } from 'lodash'
import { ROOT_QUERY } from '../../constants/cleanCache'

export const getLastQueriesExecutedInCache = (queries = {}) => {
  return !isEmpty(queries) && Object.keys(queries)
}

export const getOnlyObjectFromQuery = (query = '') => {
  let stringExtractedVars = ''
  if (query && query.length) {
    const initSliceIndex = query.indexOf('(') + 1
    const lastSliceIndex = query.length - 1
    stringExtractedVars = query.slice(initSliceIndex, lastSliceIndex)
  }
  return stringExtractedVars
}

export const getProfileIdForCampaignQuery = (query = '', profileId = '') => {
  let profilesInQuery = []
  if (query && profileId) {
    const stringVars = getOnlyObjectFromQuery(query)
    const varsObj = JSON.parse(stringVars)
    profilesInQuery = varsObj?.filters[0].operation.values
  }
  return profilesInQuery.some((profileInQuery) => profileId === profileInQuery)
}

export const cleanCacheInQueries = (cache, profileId = '', queriesToWatch = []) => {
  if (profileId) {
    queriesToWatch?.forEach((query) => {
      const profileInQueryExist = getProfileIdForCampaignQuery(query, profileId)
      if (profileInQueryExist) {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: query
        })
      }
    })
  }
}

export const cleanByProfileIdOnCampaignQueries = (cache, profileIds = [], queriesToWatch = []) => {
  profileIds?.forEach((profileId) => {
    cleanCacheInQueries(cache, profileId, queriesToWatch)
  })
}
