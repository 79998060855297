import { AddPostsManualPostInfo } from './subForms/AddPostsManualPostInfo'
import { AddPostsManualInstagramVideoMetrics } from './subForms/AddPostsManualInstagramVideoMetrics'
import { AddPostsManualInstagramPostAdditionalMetrics } from './subForms/AddPostsManualInstagramPostAdditionalMetrics'
import { AddPostsManualPostCost } from './subForms/AddPostsManualPostCost'

export const AddPostsManualInstagramVideo = () => {
  return (
    <>
      <AddPostsManualPostInfo />
      <AddPostsManualInstagramVideoMetrics />
      <AddPostsManualInstagramPostAdditionalMetrics />
      <AddPostsManualPostCost />
    </>
  )
}
