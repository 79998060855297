import { useContext } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse } from '../../../../constants/forms/getFormResponse'
import {
  addProfile as addProfileCall,
  addProfiles as addProfilesCall,
  addProfileServiceCost,
  getTableProfilesByBrand,
  deleteProfiles,
  countTableProfilesByBrand,
  unregisterRelationshipIrmInfluencerProfiles,
  addOrAssociateProfile
} from '../../apollo'
import { useFilter } from './useFilter'
import { useCreateEditForm } from 'influ-dms'
import { ModalsContext } from '../../../commons/context/ModalsContext'
import { unregisterRelationshipProfile } from '../../apollo/unregisterRelationshipProfile'
import { campaignInfluencers, campaignQuery } from '../../../campaignsV1/apollo'
import { useFilterQuery } from './useFilterQuery'
import { useCleanCache } from './useCleanCache'
import { captureException } from '@sentry/nextjs'

export const useProfiles = () => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const {
    clearProfileInListsTableCache,
    clearListDetailCache,
    clearInfluencerDetailCache,
    clearProfileTableCache,
    clearProfileDetailCache,
    clearInfluencerTableCache,
    clearListTableCache,
    clearCampaignTableCache,
    clearProfileInCampaignsTableCache
  } = useCleanCache()

  const {
    loadingData: loadingProfiles,
    loadingCount: loadingCountProfiles,
    setQueryOptions: setTableProfileOptions,
    setCountQueryOptions: setCountTableProfileOptions
  } = useFilterQuery({
    query: getTableProfilesByBrand,
    queryAccessor: 'getTableProfilesByBrand',
    countQuery: countTableProfilesByBrand,
    countQueryAccessor: 'countTableProfilesByBrand'
  })

  const [
    deleteProfilesMutation,
    {
      data: deleteProfilesMutationResponse,
      loading: loadingDeleteProfiles,
      error: errorOnDeleteMassiveProfiles
    }
  ] = useMutation(deleteProfiles, {
    update (cache, _, { variables }) {
      // table profiles
      clearProfileTableCache()
      // detail profile
      variables?.profileIds.forEach((profileId) => {
        clearProfileDetailCache(profileId)
      })
      // table influencers
      clearInfluencerTableCache({ evictCount: false })
      // detail influencer
      clearInfluencerDetailCache()
      // table lists
      clearListTableCache({ evictCount: false })
      // detail list
      clearListDetailCache()
      cache.gc()
    }
  })
  const [addProfileServiceCostQuery, { loading: loadingProfileServiceCost }] =
    useLazyQuery(addProfileServiceCost)
  const [addOrAssociateProfileQuery, { loading: loadingAddOrAssociateProfile }] =
    useLazyQuery(addOrAssociateProfile)
  const [addProfileMutation, { loading: loadingAddProfile }] = useMutation(addProfileCall, {
    update (cache, _, { variables }) {
      clearProfileTableCache()

      if (variables.influencerId) {
        clearInfluencerDetailCache(variables.influencerId)
        clearInfluencerTableCache()
      }

      variables?.listIds?.forEach((listId) => {
        clearListDetailCache(listId)
      })
      cache.gc()
    }
  })
  const [addProfilesMutation, { loading: loadingAddProfiles }] = useMutation(addProfilesCall, {
    update (cache, _, { variables }) {
      clearProfileTableCache()

      if (variables.influencerId) {
        clearInfluencerDetailCache(variables.influencerId)
        clearInfluencerTableCache()
      }

      variables?.listIds?.forEach((listId) => {
        clearListDetailCache(listId)
      })
      cache.gc()
    }
  })
  const [
    removeProfileFunc,
    { data: removeProfileResponse, loading: removeProfileLoading, error: removeProfileError }
  ] = useMutation(unregisterRelationshipIrmInfluencerProfiles, {
    update (cache, _, { variables }) {
      clearProfileTableCache()
      clearInfluencerTableCache()
      variables?.influencerId && clearInfluencerDetailCache(variables.influencerId)
      variables?.profileIds.forEach((profileId) => {
        clearProfileDetailCache(profileId)
      })
      cache.gc()
    }
  })

  const [
    unregisterProfileFunc,
    {
      data: unregisterProfileResponse,
      loading: unregisterProfileLoading,
      error: unregisterProfileError
    }
  ] = useMutation(unregisterRelationshipProfile, {
    update (cache, _, { variables }) {
      clearProfileTableCache({ evictCount: false })

      if (variables?.listIds) {
        clearListTableCache()
        variables?.profileIds.forEach((profileId) => {
          clearProfileInListsTableCache(profileId)
        })
        variables?.listIds.forEach((listId) => {
          clearListDetailCache(listId)
        })
      }

      if (variables.campaignIds) {
        clearCampaignTableCache()
        variables?.profileIds.forEach((profileId) => {
          clearProfileInCampaignsTableCache(profileId)
        })
      }
      cache.gc()
    }
  })

  const { getFilteredDataOptions } = useFilter()
  const { sendForm } = useCreateEditForm()
  const { dispatch } = useContext(ModalsContext)

  const getProfiles = (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view },
        { brandId, view }
      )
      setTableProfileOptions(options)
      if (page === 0) setCountTableProfileOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining profiles', e)
    }
  }

  const getRefetchQueries = (originIdName, originId) => {
    switch (originIdName) {
      case 'profileIds':
        return []
      case 'influencerId':
        return []
      case 'campaignIds':
        return [
          {
            query: campaignInfluencers,
            variables: { campaignId: originId }
          },
          {
            query: campaignQuery,
            variables: { campaignId: originId }
          }
        ]
      default:
        return []
    }
  }

  const addProfile = async ({ from, searchResultId, originIdName, originId }) => {
    try {
      const refetchQueries = getRefetchQueries(originIdName, originId)
      const response = await sendForm(
        addProfileMutation,
        false,
        {
          brandId,
          from,
          searchResultId,
          [originIdName]: originId
        },
        { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse },
        refetchQueries
      )
      return response
    } catch (e) {
      captureException('Error adding profile', e)
      return false
    }
  }

  const addProfiles = async ({ from, searchResultIds, originIdName, originId }) => {
    try {
      const refetchQueries = getRefetchQueries(originIdName, originId)
      const response = await sendForm(
        addProfilesMutation,
        false,
        {
          brandId,
          from,
          searchResultIds,
          [originIdName]: originId
        },
        { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse },
        refetchQueries
      )
      return response
    } catch (e) {
      captureException('Error adding profiles', e)
      return false
    }
  }

  const deleteMassiveProfiles = async (profileIds) => {
    const variables = { profileIds }
    try {
      await deleteProfilesMutation({ variables })
      if (
        (deleteProfilesMutationResponse &&
          !deleteProfilesMutationResponse?.deleteProfiles?.success) ||
        errorOnDeleteMassiveProfiles
      ) {
        throw Error
      }
      return true
    } catch (e) {
      captureException('There was an error deleting profiles =>', e)
      return false
    }
  }

  const getServiceCost = async (profilesNumber) => {
    const options = { variables: { brandId, profilesNumber }, fetchPolicy: 'network-only', skip: !brandId }
    try {
      const response = await addProfileServiceCostQuery(options)
      if (response && response.data) {
        return response.data.addProfileServiceCost
      }
    } catch (e) {
      captureException('Error obtaining ProfileServiceCost form', e)
    }
  }

  const getAddOrAssociateProfile = async (type, value) => {
    const options = {
      variables: { brandId, type, value },
      skip: !brandId
    }
    try {
      const response = await addOrAssociateProfileQuery(options)
      if (response && response.data) {
        return response.data.addOrAssociateProfile
      }
    } catch (e) {
      captureException('Error obtaining addOrAssociateProfile', e)
    }
  }

  const handleDuplicatedProfile = (profileId) => {
    dispatch({ type: 'openPreviewProfile', payload: profileId })
  }

  const removeProfile = async (profileId, influencerId) => {
    try {
      await removeProfileFunc({
        variables: { profileIds: [profileId], influencerId: [influencerId] }
      })
      if (
        (removeProfileResponse &&
          !removeProfileResponse.unregisterRelationshipIrmInfluencerProfiles.success) ||
        removeProfileError
      ) {
        throw Error
      }
      return true
    } catch (error) {
      captureException('Error removing profile', error)
      return false
    }
  }

  const unregisterProfile = async (profileIds, originIds, originIdsName) => {
    await unregisterProfileFunc({ variables: { profileIds, [originIdsName]: originIds } })
  }

  const removeProfileOptions = {
    action: async (profileId, influencerId) => {
      return removeProfile(profileId, influencerId)
    },
    loading: removeProfileLoading,
    error: removeProfileError
  }

  const deleteMassiveProfilesOptions = {
    action: async (profileIds) => {
      return deleteMassiveProfiles(profileIds)
    },
    loading: loadingDeleteProfiles,
    error: errorOnDeleteMassiveProfiles
  }

  const unregisterProfileOptions = {
    action: (profileIds, originIds, originIdsName) => {
      unregisterProfile(profileIds, originIds, originIdsName)
    },
    data: unregisterProfileResponse,
    loading: unregisterProfileLoading,
    error: unregisterProfileError
  }

  return {
    getProfiles,
    loadingProfiles,
    loadingCountProfiles,
    addProfile,
    loadingAddProfile,
    addProfiles,
    loadingAddProfiles,
    deleteMassiveProfiles,
    loadingDeleteProfiles,
    getServiceCost,
    loadingProfileServiceCost,
    handleDuplicatedProfile,
    removeProfileOptions,
    deleteMassiveProfilesOptions,
    unregisterProfileOptions,
    getAddOrAssociateProfile,
    loadingAddOrAssociateProfile
  }
}
