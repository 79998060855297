import { gql } from '@apollo/client'

export const getMassiveCommConfig = gql`
  query getMassiveCommConfig($brandId: String!) {
    getMassiveCommConfig(brandId: $brandId) {
      senderName
      senderEmail
      fullSenderEmail
      domainType
      replyToAddress
    }
  }
`