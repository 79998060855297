import { useContext, useEffect, useState } from 'react'
import { Button, FormContext, SideModalFooter, StateButton, Text } from 'influ-dms'
import { css } from 'styled-components'
import { get, isEmpty, isEqual } from 'lodash'
import { editReportV2Tracking } from '../../../apollo/tracking/editReportV2Tracking'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { ModalsContext } from '../../../../commons/context'
import { getTrackingErrorMessage } from '../addPostsHelper'
import { EditConfirmationTrackingModal } from '../../../influencers/commons'
import { brand } from '../../../../../graphql/brands'
import { useTranslate } from '~/hooks/useTranslate'
import { captureException } from '@sentry/nextjs'

const marginStyles = css`
  :not(:last-child) {
    margin-right: 16px;
  }
`
const isEdited = (original, values) => {
  const isNotEmptyData = !isEmpty(values) && !isEmpty(original)
  if (isNotEmptyData) {
    const endDateOriginal = get(original, 'endDate', null) ? new Date(get(original, 'endDate')) : null
    const endDateValue = get(values, 'endDate', null)
    const anyValueChanged = !isEqual(values.name, original.name) ||
      !isEqual(values.hashtags.map(e => get(e, 'value', e)), original.hashtags) ||
      !isEqual(values.mentions.map(e => get(e, 'value', e)), original.mentions) ||
      !isEqual(values.keywords.map(e => get(e, 'value', e)), original.keywords) ||
      !isEqual(values.startDate, new Date(original.startDate)) ||
      !isEqual(endDateValue, endDateOriginal) ||
      !isEqual(values.influencers, original.influencers)
    return anyValueChanged
  }
  return false
}

export const EditTrackingModalFooter = ({ error, setError, handleClose, trackingData, setIsOpenModalLimit }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const { dispatch } = useContext(ModalsContext)
  const [modified, setModified] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [editReportV2TrackingMutation, { loading }] = useMutation(editReportV2Tracking)
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const reportId = get(router, 'query.reportId')
  const brandId = get(router, 'query.brandId')
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId || isPublic, fetchPolicy: 'network-only' })
  const isTrackingLimitExceeded = get(data, 'brand.featureFlags.reportsV2.isTrackingLimitExceeded')
  const endDateOriginal = get(trackingData, 'endDate', null) ? new Date(get(trackingData, 'endDate')) : null
  const endDateValue = get(values, 'endDate', null)
  const endDateChanged = !isEqual(endDateValue, endDateOriginal)
  const trackingStatusFinished = get(trackingData, 'status') === 'finished'
  const isNotValidToEdit = trackingStatusFinished && isTrackingLimitExceeded && endDateChanged
  const isValid = values.name && values.network && !isEmpty(values.influencers) &&
  values.startDate && (!isEmpty(values.hashtags) || !isEmpty(values.mentions) || !isEmpty(values.keywords)) && !loading && modified
  const disabled = !isValid || !!error

  useEffect(() => {
    if (isEdited(trackingData, values)) {
      setModified(true)
    } else {
      setModified(false)
    }
  }, [values])

  const handleOnSave = () => {
    const influencersToAdd = values.influencers.filter(p1 => (trackingData.influencers.find(p2 => p2.username === p1.username) === undefined))
    const influencersToDelete = trackingData.influencers.filter(p1 => (values.influencers.find(p2 => p2.username === p1.username) === undefined))

    editReportV2TrackingMutation({
      variables: {
        reportV2Id: reportId,
        brandId,
        trackingId: trackingData._id,
        ...values,
        hashtags: isEmpty(values.hashtags) ? [] : values.hashtags.map(elem => get(elem, 'value', elem)),
        mentions: isEmpty(values.mentions) ? [] : values.mentions.map(elem => get(elem, 'value', elem)),
        keywords: isEmpty(values.keywords) ? [] : values.keywords.map(elem => get(elem, 'value', elem)),
        influencersToAdd: influencersToAdd.map(influencerToAdd => ({ username: influencerToAdd.username, network: influencerToAdd.network, profileUrl: influencerToAdd.profileUrl, profilePicture: influencerToAdd.profilePicture })),
        influencersToDelete: influencersToDelete.map(influencerToDelete => ({ username: influencerToDelete.username, network: influencerToDelete.network, profileUrl: influencerToDelete.profileUrl, profilePicture: influencerToDelete.profilePicture }))
      }
    }).then(() => {
      dispatch({ type: 'toggleEditTracking', payload: {} })
      setError(undefined)
    }).catch((error) => {
      captureException(error)
      setError(getTrackingErrorMessage(error))
    }).finally(() => setConfirmationModalOpen(false))
  }

  return (
    <SideModalFooter>
      <StateButton name='editTracking' disabled={disabled} loading={loading} loadingIconColor='white' onClick={() => isNotValidToEdit ? setIsOpenModalLimit(true) : setConfirmationModalOpen(true)} styles={marginStyles}>{t('commons.button.save')}</StateButton>
      <Button name='cancelEditTracking' disabled={loading} variant='flat' color='secondary' onClick={handleClose} styles={marginStyles}>{t('commons.button.cancel')}</Button>
      {error && error.showInFooter && <Text color='app.default'>{error.text}</Text>}
      <EditConfirmationTrackingModal
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        handleContinue={handleOnSave}
        loading={loading}
      />
    </SideModalFooter>
  )
}
