import { useContext } from 'react'
import { SnackbarContext } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useTranslate } from '~/hooks'
import { useFilter, useCleanCache } from '../../../irm/commons/hooks'
import { useFilterQuery } from '../../../irm/commons/hooks/useFilterQuery'
import {
  createTemplate as createTemplateCall,
  deleteTemplates as deleteTemplatesCall,
  editTemplate as editTemplateCall,
  duplicateTemplate as duplicateTemplateCall,
  getTemplatesByBrand,
  countTableTemplatesByBrand
} from '../apollo'
import { captureException } from '@sentry/nextjs'

export const useTemplates = () => {
  const { clearTemplateTableCache } = useCleanCache()
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const {
    loadingData: loadingTemplates,
    loadingCount: loadingCountTemplates,
    setQueryOptions: setTableTemplatesOptions,
    setCountQueryOptions: setCountTableTemplatesOptions
  } = useFilterQuery({
    query: getTemplatesByBrand,
    queryAccessor: 'getTemplatesByBrand',
    countQuery: countTableTemplatesByBrand,
    countQueryAccessor: 'countTableTemplatesByBrand'
  })

  const [createTemplateMutation, { loading: loadingCreateTemplate }] = useMutation(createTemplateCall, {
    update (cache, _, { variables }) {
      clearTemplateTableCache()
      cache.gc()
    }
  })
  const [deleteTemplatesMutation, { loading: loadingDeleteTemplates }] = useMutation(deleteTemplatesCall, {
    update (cache, _, { variables }) {
      clearTemplateTableCache()
      cache.gc()
    }
  })

  const [editTemplateMutation, { loading: loadingEditTemplate }] = useMutation(editTemplateCall, {
    update (cache, _, { variables }) {
      clearTemplateTableCache({ evictCount: false })
      cache.gc()
    }
  })

  const [duplicateTemplateMutation, { loading: loadingDuplicateTemplate }] = useMutation(duplicateTemplateCall, {
    update (cache, _, { variables }) {
      clearTemplateTableCache()
      cache.gc()
    }
  })

  const { getFilteredDataOptions } = useFilter()

  const getTemplates = async (filterParams, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view },
        { brandId, view }
      )
      setTableTemplatesOptions(options)
      page === 0 && setCountTableTemplatesOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining templates', e)
    }
  }

  const createTemplate = async (values) => {
    const variables = { brandId, ...values }
    try {
      const response = await createTemplateMutation({ variables })
      showSnackbar('success', t('commons.snackbar.success.createTemplate'))
      return response?.data?.createTemplate
    } catch (e) {
      captureException('Error creating template', e)
      return e
    }
  }

  const deleteTemplates = async (templateIds) => {
    const variables = { brandId, templateIds }
    try {
      const response = await deleteTemplatesMutation({ variables })
      const count = templateIds?.length
      showSnackbar(
        'success',
        t(
          count > 1
            ? 'commons.snackbar.success.deleteTemplates'
            : 'commons.snackbar.success.deleteTemplate',
          { count }
        )
      )
      return response
    } catch (e) {
      captureException('Error deleting templates', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const editTemplate = async (values) => {
    const variables = { brandId, ...values }
    try {
      const response = await editTemplateMutation({ variables })
      showSnackbar('success', t('commons.snackbar.success.edit'))
      return response?.data?.editTemplate
    } catch (e) {
      captureException('Error editing template', e)
      return false
    }
  }

  const duplicateTemplate = async (templateId) => {
    const variables = { _id: templateId }
    try {
      const response = await duplicateTemplateMutation({ variables })
      showSnackbar('success', t('commons.snackbar.success.duplicateTemplate'))
      return response
    } catch (e) {
      captureException('Error duplicating template', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  return {
    getTemplates,
    loadingTemplates,
    createTemplate,
    loadingCreateTemplate,
    deleteTemplates,
    loadingDeleteTemplates,
    editTemplate,
    loadingEditTemplate,
    duplicateTemplate,
    loadingDuplicateTemplate,
    loadingCountTemplates
  }
}
