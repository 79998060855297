import styled, { css } from 'styled-components'
import { get } from 'lodash'
import { Flex, Icon, Text } from 'influ-dms'
import { dataSourceMap, postTypeMap } from '../../../../posts/table/ReportsPostsTable'
import { useTranslate } from '~/hooks/useTranslate'
import { imagePostStyles } from '../../../reportsTable/styles'

const ImageContainer = styled.div`
  width: 40px;
  height: 40px;
  ${imagePostStyles};
`
const textStyles = css`
  border-left: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding-left: 3px;
  margin-left: 3px;
`

export const EditPostModalContentHeaderPost = ({ image, network, type, dataSource }) => {
  const { t } = useTranslate()
  const imageSource = get(image, '[0]')
  return (
    <Flex align='center'>
      <ImageContainer image={imageSource} />
      <div>
        <Flex align='center'>
          <Icon name={network} color='primary.dark' />
          <Text color='primary.dark' size='14' bold styles={textStyles}>{postTypeMap(t)[type]}</Text>
        </Flex>
        <Text color='primary.light3' size='14'>{dataSourceMap(t)[dataSource]}</Text>
      </div>
    </Flex>
  )
}
