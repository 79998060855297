import { gql } from '@apollo/client'

export const addProfile = gql`
  mutation addProfile(
    $brandId: String!
    $influencerId: String
    $listIds: [String]
    $socialNetwork: SocialNetworkEnumType!
    $profile: String!
    $analyzeProfile: Boolean!
    $searchResultId: String
    $from: String!
    $sync: Boolean
  ) {
    addProfile(
      brandId: $brandId
      influencerId: $influencerId
      listIds: $listIds
      socialNetwork: $socialNetwork
      profile: $profile
      analyzeProfile: $analyzeProfile
      searchResultId: $searchResultId
      from: $from
      sync: $sync
    ){
      message
      code
      success
      data
    }
  }
`

export const addProfileServiceCost = gql`
  query addProfileServiceCost(
    $brandId: String!
    $profilesNumber: Int
  ) {
    addProfileServiceCost(
      brandId: $brandId
      profilesNumber: $profilesNumber
    ){
    serviceCost
    balance
    canAddProfile
    }
  }
`

export const addOrAssociateProfile = gql`
query addOrAssociateProfile(
  $brandId: String!
  $type: String!
  $value: String!
  ) {
    addOrAssociateProfile(
      brandId: $brandId
      type: $type
      value: $value
    ){
      exists
      profileId
      irmInfluencerAssociated {
        _id 
      }
    }
  }
`

export const addProfiles = gql`
  mutation addProfiles(
    $brandId: String!
    $socialNetwork: SocialNetworkEnumType!
    $profiles: [String]!
    $analyzeProfile: Boolean!
    $searchResultIds: JSON
    $from: String!
    $sync: Boolean
  ) {
    addProfiles(
      brandId: $brandId
      socialNetwork: $socialNetwork
      profiles: $profiles
      analyzeProfile: $analyzeProfile
      searchResultIds: $searchResultIds
      from: $from
      sync: $sync
    ){
      message
      code
      success
      data
    }
  }
`
