import { gql } from '@apollo/client'

export const addAutomaticPostsMutation = gql`
 mutation addPublicationAuto($reportV2Id: String!, $urls: [String]!) {
  addPublicationAuto(reportV2Id: $reportV2Id, urls: $urls) {
      message,
      success
    }
 }
`

export const addInstagramStoryManualMutation = gql`
  mutation addInstagramStoryManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $stickers: [String]
    $swipeUrl: String
    $cost: Float
    $additionalMetrics: InstagramStoryAdditionalMetricsInputType
    $likes: Int
  ) {
  addInstagramStoryManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    publicationDate: $publicationDate
    image: $image 
    stickers: $stickers
    swipeUrl: $swipeUrl
    cost: $cost
    additionalMetrics: $additionalMetrics
    likes: $likes
  ) {
      reportV2Id
    }
 }
`

export const addInstagramVideoManualMutation = gql`
  mutation addInstagramVideoManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $likes: Int
    $comments: Int
    $videoViews: Int
    $cost: Float
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
  ) {
  addInstagramVideoManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    likes: $likes
    comments: $comments
    videoViews: $videoViews
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`

export const addInstagramReelManualMutation = gql`
  mutation addInstagramReelManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $likes: Int
    $comments: Int
    $videoViews: Int
    $videoPlays: Int
    $additionalMetrics: InstagramReelAdditionalMetricsInputType
    $cost: Float
    $taggedUsers: [String]
  ) {
  addInstagramReelManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    likes: $likes
    comments: $comments
    videoViews: $videoViews
    videoPlays: $videoPlays
    additionalMetrics: $additionalMetrics
    cost: $cost
    taggedUsers: $taggedUsers
  ) {
      reportV2Id
    }
 }
`

export const addInstagramPhotoManualMutation = gql`
  mutation addInstagramPhotoManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $likes: Int
    $comments: Int
    $cost: Float
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
  ) {
  addInstagramPhotoManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    likes: $likes
    comments: $comments
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`

export const addInstagramCarouselManualMutation = gql`
  mutation addInstagramCarouselManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $caption: String
    $publicationDate: String
    $image: [Upload]
    $likes: Int
    $comments: Int
    $cost: Float
    $additionalMetrics: InstagramPostAdditionalMetricsInputType
  ) {
  addInstagramCarouselManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    publicationDate: $publicationDate
    image: $image
    likes: $likes
    comments: $comments
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`

export const addYoutubeVideoManualMutation = gql`
  mutation addYoutubeVideoManual (
    $reportV2Id: String!,
    $url: String,
    $username: String!,
    $profileUrl: String!,
    $requestAudienceData: Boolean
    $caption: String,
    $publicationDate: String,
    $image: [Upload],
    $videoViews: Int,
    $likes: Int,
    $dislikes: Int,
    $comments: Int
    $cost: Float
    $additionalMetrics: YoutubeVideoAdditionalMetricsInputType
  ) {
  addYoutubeVideoManual (
    reportV2Id: $reportV2Id, 
    url: $url, 
    username: $username, 
    profileUrl: $profileUrl, 
    requestAudienceData: $requestAudienceData
    caption: $caption, 
    publicationDate: $publicationDate, 
    image: $image, 
    videoViews: $videoViews, 
    likes: $likes, 
    dislikes: $dislikes, 
    comments: $comments
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`

export const addYoutubeShortManualMutation = gql`
  mutation addYoutubeShortManual (
    $reportV2Id: String!,
    $url: String,
    $username: String!,
    $profileUrl: String!,
    $requestAudienceData: Boolean
    $caption: String,
    $publicationDate: String,
    $image: [Upload],
    $videoViews: Int,
    $likes: Int,
    $dislikes: Int,
    $comments: Int
    $cost: Float
    $additionalMetrics: YoutubeVideoAdditionalMetricsInputType
  ) {
  addYoutubeShortManual (
    reportV2Id: $reportV2Id, 
    url: $url, 
    username: $username, 
    profileUrl: $profileUrl, 
    requestAudienceData: $requestAudienceData
    caption: $caption, 
    publicationDate: $publicationDate, 
    image: $image, 
    videoViews: $videoViews, 
    likes: $likes, 
    dislikes: $dislikes, 
    comments: $comments
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`

export const addTikTokVideoManualMutation = gql`
  mutation addTiktokVideoManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $image: [Upload],
    $caption: String
    $publicationDate: String
    $videoViews: Int
    $likes: Int
    $comments: Int
    $shares: Int
    $saves: Int
    $cost: Float
    $additionalMetrics: TiktokVideoAdditionalMetricsInputType
  ) {
  addTiktokVideoManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    image: $image, 
    publicationDate: $publicationDate
    videoViews: $videoViews
    likes: $likes
    comments: $comments
    shares: $shares
    saves: $saves
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`

export const addTiktokPhotoManualMutation = gql`
  mutation addTiktokPhotoManual (
    $reportV2Id: String!
    $url: String
    $username: String!
    $profileUrl: String!
    $requestAudienceData: Boolean
    $image: [Upload],
    $caption: String
    $publicationDate: String
    $videoViews: Int
    $likes: Int
    $comments: Int
    $shares: Int
    $saves: Int
    $cost: Float
    $additionalMetrics: TiktokVideoAdditionalMetricsInputType
  ) {
  addTiktokPhotoManual (
    reportV2Id: $reportV2Id
    url: $url
    username: $username
    profileUrl: $profileUrl
    requestAudienceData: $requestAudienceData
    caption: $caption
    image: $image, 
    publicationDate: $publicationDate
    videoViews: $videoViews
    likes: $likes
    comments: $comments
    shares: $shares
    saves: $saves
    cost: $cost
    additionalMetrics: $additionalMetrics
  ) {
      reportV2Id
    }
 }
`
