import styled, { css } from 'styled-components'
import { Button, Flex, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const firstButtonStyles = css`
  margin-right: 16px;
`
const ButtonContainer = styled(Flex)`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

export const AddPostsManualCheckUrlButtons = ({ onClick, continueWithoutUrl, loading, isButtonDisabled, showContinueWithoutUrl }) => {
  const { t } = useTranslate()
  return (
    <ButtonContainer>
      <StateButton
        loading={loading}
        showLoader
        size='s'
        disabled={isButtonDisabled}
        styles={firstButtonStyles}
        onClick={onClick}
      >{t('commons.button.verifyUrl')}
      </StateButton>
      {showContinueWithoutUrl &&
        <Button
          disabled={loading}
          size='s'
          variant='outline'
          onClick={continueWithoutUrl}
        >{t('commons.button.continueWithoutUrl')}
        </Button>}
    </ButtonContainer>
  )
}
