import styled, { css } from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 320px;
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 56px;
  gap: 40px;

  width: 549px;

  /* Neutral/White */

  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(124, 113, 148, 0.07);
  border-radius: 8px;
`
export const backgroundStyles = css`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../static/img/BG_platform.jpg');
  background-size: cover; 
  background-repeat: no-repeat; 
  height: 100vh;
`

export const wrapperModalStyles = css`
  width: 549px;
  box-sizing: border-box;
  @media only screen and (max-width: 425px) {
    width: auto;
    margin-left: calc(30px - 1rem);
    margin-right: calc(30px - 1rem);
    padding: 32px 16px;
  }
`

export const CommonContainer = styled.div`
  width: 100%;
`
export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 18px
`
export const IconContainer = styled.div`
  width: 13px;
`
export const HelperTextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.colors.state.dangerDefault};
  ${(props) => (props.marginOnTop ? 'margin-top: ' + props.marginOnTop + 'px;' : '')}
  ${(props) => (props.flexSpace ? 'gap: ' + props.flexSpace + 'px' : '')}
`
export const inputErrorStyles = css`
border-color: ${props => props.theme.colors.state.dangerDefault};
color: ${props => props.theme.colors.state.dangerDefault};
&:hover, &:active, &:focus {
  border-color: ${props => props.theme.colors.state.dangerDefault};
}
`
export const innerLeftIconStyles = css`
  position: relative;
  top: 25px;
  left: 7px;
  margin-top: -18px;
`
export const buttonTextStyles = css`
  font-size: 14px;
  @media only screen and (max-width: 425px) {
    font-size: 12px;
  }
`
