import { gql } from '@apollo/client'

export const addEntityFieldGroup = gql`
mutation addEntityFieldGroup(
  $entityName: String!
  $groupName: String!
  $groupDescription: String
  $origin: String
) {
  addEntityFieldGroup(
    entityName: $entityName
    groupName: $groupName
    groupDescription: $groupDescription
    origin: $origin
  ){
    message
    data
    success
  }
}
 `
