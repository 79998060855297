import { gql } from '@apollo/client'

export const unregisterRelationshipListInfluencers = gql`
  mutation unregisterRelationshipListInfluencers(
    $listIds: [String]!
    $influencerIds: [String]!
  ) {
    unregisterRelationshipListInfluencers(
      listIds: $listIds
      influencerIds: $influencerIds
    ){
        message
        code
        success
      }
  }
 `
