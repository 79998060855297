import { createRef, useContext, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { RouterHelper } from '../../../../../routing/routerHelper'
import { Button, Flex, GenericLoader, Modal, Spacing, Text } from 'influ-dms'
import styled, { ThemeContext } from 'styled-components'
import { useTranslate } from '~/hooks/useTranslate'

const StyledLink = styled.a`
  word-break: break-all;
`

const ShareModal = (props) => {
  const { t } = useTranslate()
  const { colors: { gradients } } = useContext(ThemeContext)
  const [copied, setCopied] = useState()
  const { isOpen, onClose, url, title, type } = props
  const buttonRef = createRef()

  useEffect(() => {
    if (isOpen) {
      RouterHelper.traceEvent(type ? type + 'Share' : 'share')
    }
  }, [isOpen, type])

  const handleEnter = () => {
    buttonRef.current.click()
  }

  const handleOnClose = () => {
    onClose()
    setCopied(false)
  }

  const renderLoading = () => (
    <Flex center grow='1'>
      <GenericLoader fillSpace />
    </Flex>
  )

  const renderCopied = () => {
    if (copied) {
      return (
        <>
          <Flex>
            <Text size='12'>{t('commons.message.confirmation.copied.label')}</Text>
          </Flex>
          <Spacing size='8' />
        </>
      )
    }
    return <></>
  }

  const renderUrl = () => {
    return (
      <>
        <Flex column grow='1'>
          <Text size='14' bold color='primary.dark'>{t('influencers.modal.shareList.titleHeader1')}</Text>
          <Spacing size='4' />
          <Text size='14' color='primary.light3'>{t('influencers.modal.shareList.titleParagraph1')}</Text>
          <Spacing size='4' />
          <StyledLink href={url} target='_blank'>
            <Text size='14'> {url} </Text>
          </StyledLink>
        </Flex>
        {renderCopied()}
        <Flex>
          <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
            <Button ref={buttonRef} name='copy'>{t('commons.button.copyUrl')}</Button>
          </CopyToClipboard>
          <Spacing size='16' vertical />
          <Button name='cancelCopy' variant='flat' color='secondary' onClick={handleOnClose}>{t('commons.button.cancel')}</Button>
        </Flex>
      </>
    )
  }

  return (
    <Modal
      width='800'
      isOpen={isOpen}
      title={title}
      onClose={handleOnClose}
      onSubmit={handleEnter}
      submitEnabled
      gradient1={gradients[1][0]}
      gradient2={gradients[1][1]}
    >
      {url ? renderUrl() : renderLoading()}
    </Modal>
  )
}

export { ShareModal }
