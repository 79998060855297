import { gql } from '@apollo/client'

const deleteDeepSearchFilter = gql`
  mutation deleteDeepSearchFilter(
  $brandId: String!,
  $id: String!,
  ){
    deleteDeepSearchFilter(
      brandId: $brandId,
      _id: $id,
    ){
      message,
      success,
    }
  }
`

export { deleteDeepSearchFilter }
