import { Portal } from 'influ-dms'
import { ModalUnlinkOrDelete } from '../../commons/modals/ModalUnlinkOrDelete'

export const ModalsForOptions = ({
  isOpenUnlink,
  isOpenDelete,
  onCloseUnlink,
  onCloseDelete,
  loadingConfirmUnlink,
  handleConfirmUnlink,
  loadingConfirmDelete,
  handleConfirmDelete
}) => {
  return (
    <Portal selector='#networkModal'>
      {/* Warning modal for unlinking profile */}
      <ModalUnlinkOrDelete
        isOpen={isOpenUnlink}
        onClose={onCloseUnlink}
        title={'modal.removeProfilesFromInfluencer.header'}
        color={'state.warningDefault'}
        header={'modal.removeProfileFromInfluencer.titleHeader1'}
        paragraph={'modal.removeProfileFromInfluencer.titleParagraph1'}
        loadingConfirm={loadingConfirmUnlink}
        handleConfirm={handleConfirmUnlink}
        confirmButtonText={'commons.button.remove'}
        confirmButtonColor={'warning'}
      />
      {/* Danger modal for deleting profile */}
      <ModalUnlinkOrDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        title={'modal.removeAndDeleteProfilesFromInfluencer.header'}
        color={'state.dangerDefault'}
        header={'modal.removeAndDeleteProfileFromInfluencer.titleHeader1'}
        paragraph={'modal.removeAndDeleteProfileFromInfluencer.titleParagraph1'}
        loadingConfirm={loadingConfirmDelete}
        handleConfirm={handleConfirmDelete}
        confirmButtonText={'commons.button.delete'}
        confirmButtonColor={'danger'}
      />
    </Portal>
  )
}
