import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles,
  clickableStyles
} from './styles'

export const InvoicesListTableTdInvoiceName = ({
  row: { original },
  onNameClick,
  onEditClick,
  onDeleteClick,
  loading,
}) => {
  const { t } = useTranslate()
  const invoice = original || {}
  const { name, status } = invoice
  const canDeleteInvoice = status === 'draft'

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis showTooltip size='14' color='primary.light2' bold onClick={onNameClick} styles={clickableStyles}>
            {name}
          </Text>
        </StyledContainer>
        <HoverWrapper>
          {onEditClick && <Tooltip
            text={t('commons.button.editInvoice.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onEditClick} iconName='edit' disabledIcon={loading} />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={canDeleteInvoice ? t('commons.button.deleteInvoice.tooltip') : t('commons.button.deleteInvoice.disabled.notDraft.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onDeleteClick} iconName='trash' disabledIcon={loading || !canDeleteInvoice} />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
