import { products } from '../constants'

/**
 * It takes a URL and returns the product name if it's a valid product URL, otherwise it returns null
 * @param url - The URL of the page that's currently being viewed.
 * @returns The product that matches the slugParam
 */
const getActiveProduct = (url) => {
  const slugParam = url.split('/').filter(Boolean)[0]
  for (const product of Object.values(products)) {
    // If the product is 'reports' and the slugParam is 'socialListening', we want to return 'reports'
    if (product === slugParam ||
      (product === 'reports' && slugParam === 'socialListening') ||
      (product === 'socialMedia' && slugParam === 'social')) {
      return product
    }
  }

  return null
}

export { getActiveProduct }
