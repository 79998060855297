import { css } from 'styled-components'
import { Text } from 'influ-dms'

const styles = css`
  padding: 24px 32px 20px 32px;
`

export const PageTitle = ({ title }) => (
  <Text size='20' bold color='primary.dark' styles={styles}>{title}</Text>
)
