import { css } from 'styled-components'

export const topPositionStyles = css`
  padding-top: 7px;
`

export const bottomPositionStyles = css`
  padding-bottom: 7px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light5};
`

export const avatarContactStyles = css`
  width: 24px;
  height: 24px;
  margin: 0;
  z-index: 1;
`
