import { gql } from '@apollo/client'

export const updateCampaign = gql`
 mutation updateCampaignBeta(
   $campaignId: String!
   $workflowId: String!
   $name: String!
   $networks: [String]!
   $budget: Float!
   $startDate: String!
   $endDate: String!
   $description: String!
   $goal: String!
   $target: CampaignBetaTargetInput
 ) {
   updateCampaignBeta(
     _id: $campaignId
     workflowId:$workflowId
     name:$name
     networks:$networks
     budget:$budget
     startDate:$startDate
     endDate:$endDate
     description:$description
     goal:$goal
     target: $target
   ){
    id: _id
    name
    networks
    budget
    startDate
    endDate
    description
    goal
    countries
    target{
      locations
      gender
      age{
        from
        to
        weight
      }
      interests
      additionalInfo
    }
    currency{
      id: _id
      symbol
    }
    workflow{
      id: _id
      name
      steps{
        name
      }
    }
    }
 }
 `
