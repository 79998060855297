import { gql } from '@apollo/client'

export const editPaymentInvoice = gql`
  mutation editPaymentInvoice (
    $_id: String!
    $name: String!
    $currency: String!
    $description: String
    $orderNumber: String
  ) {
    editPaymentInvoice(
      _id: $_id
      name: $name
      currency: $currency
      description: $description
      orderNumber: $orderNumber
    ) {
      message
      success
    }
  }
`
