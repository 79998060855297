import { useContext, useState } from 'react'
import { Form, FormContext, Spacing } from 'influ-dms'
import { SecureCheck } from './SecureCheck'
import { useTranslate } from '~/hooks/useTranslate'
import { ThemeContext } from 'styled-components'

const minimumTLSVersion = [
  {
    label: '1.2',
    value: 'TLSv1.2'
  },
  {
    label: '1.3',
    value: 'TLSv1.3'
  }
]

export const ImapSecure = () => {
  const { t } = useTranslate()
  const [rejectedOptions] = useState([
    {
      label: t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.smtp.input.rejected.options.true'),
      value: true
    },
    {
      label: t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.smtp.input.rejected.options.false'),
      value: false
    }
  ])

  const { setValue } = useContext(FormContext)
  const { colors: { primary: { light3 } } } = useContext(ThemeContext)
  const [secureChecked, setSecureCheck] = useState(false)
  const handleChange = () => {
    setSecureCheck(prevState => !prevState)
    setValue('InputText', 'imap.tls.rejectUnauthorized', true)
    setValue('InputText', 'imap.tls.minVersion', 'TLSv1.2')
  }
  return (
    <>
      <SecureCheck checkValue={secureChecked} handleChange={handleChange} name='imap.secure' />
      {secureChecked && (
        <>
          <Spacing size='16' />
          <Form.Select label={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.smtp.input.rejected.label')} name='imap.tls.rejectUnauthorized' options={rejectedOptions} color={light3} helperText={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.rejected.helperText')} />
          <Spacing size='16' />
          <Form.Select label={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.minimalTlsVersion.label')} name='imap.tls.minVersion' options={minimumTLSVersion} color={light3} helperText={t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.imap.input.minimalTlsVersion.helperText')} />
        </>
      )}
    </>
  )
}
