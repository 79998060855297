import { get } from 'lodash'

export const parseBrand = (brand, contact) => {
  const companyId = get(brand, 'id')
  const brandName = get(brand, 'brandName')
  const userCount = get(brand, 'owners.length')
  const logoImage = get(brand, 'logoImage')
  const description = get(brand, 'description')
  const socialUsername = get(brand, 'socialUsername')
  const trial = get(brand, 'trial.isTrial')
  const trialStartDate = get(brand, 'trial.startDate')
  const planName = get(brand, 'subscriptionInfo.planType')
  const planPeriod = get(brand, 'subscriptionInfo.planPeriod')
  let plan = trial ? 'trial ' : ''
  plan += planName || ''
  plan += planPeriod ? ' ' + planPeriod : ''
  const campaignCount = get(brand, 'featureFlags.campaignsV1.campaignCount')
  const searchCount = get(brand, 'featureFlags.search.searchCount')
  const resultsCountPerPeriod = get(brand, 'featureFlags.search.resultsCountPerPeriod')
  const networkSummary = get(brand, 'networkSummary')
  const influencersCount = get(networkSummary, 'influencers') || 0
  const influencerListCount = get(networkSummary, 'lists')

  const influencersInstagramCount = get(networkSummary, 'instagram') || 0
  const influencerTwitterCount = get(networkSummary, 'twitter') || 0
  const influencersYoutubeCount = get(networkSummary, 'youtube') || 0
  const influencersTiktokCount = get(networkSummary, 'tiktok') || 0
  const infuencersAverageByNetwork = influencersCount > 0 ? ((influencersInstagramCount + influencerTwitterCount + influencersYoutubeCount + influencersTiktokCount) / influencersCount) : 0
  // new
  const createdAt = get(brand, 'createdAt')
  const contactData = get(contact, 'self.contact') || {}
  const companySegment = get(contactData, 'hubspotData.tipo_de_empresa')
  const companySize = get(contactData, 'hubspotData.numemployees')
  const trialEndDate = get(brand, 'trial.expirationDate')

  const planCreatedAt = get(brand, 'subscriptionInfo.createdAt')
  const planEndDate = get(brand, 'subscriptionInfo.endDate')
  const planCancellationEfectiveAt = get(brand, 'subscriptionInfo.cancellationAt')
  const planCancelledAt = get(brand, 'subscriptionInfo.cancelledAt')
  const planCurrentPeriodStart = get(brand, 'subscriptionInfo.currentPeriodStart')

  const result = {}

  // if hava data , send it
  if (companyId) result.company_id = companyId
  if (brandName) result.name = brandName
  if (Number.isFinite(userCount)) result.user_count = userCount

  if (logoImage) {
    result.avatar = { type: 'avatar' }
    result.avatar.image_url = logoImage
  }

  if (description) result.description = description
  if (socialUsername) result.social_username = socialUsername

  if (planName) result.plan_name = planName
  if (planPeriod) result.plan_period = planPeriod
  if (plan) result.plan = plan
  if (plan) result.plan_definition = plan

  result.trial = !!trial
  if (trialStartDate) result.trial_start_date = trialStartDate
  if (Number.isFinite(campaignCount)) result.campaign_count = campaignCount
  if (Number.isFinite(searchCount)) result.search_count = searchCount
  if (Number.isFinite(resultsCountPerPeriod)) result.results_count_by_period = resultsCountPerPeriod
  if (Number.isFinite(influencersCount)) result.influencers_count = influencersCount
  if (Number.isFinite(influencerListCount)) result.influencer_list_count = influencerListCount
  if (Number.isFinite(influencersInstagramCount)) result.influencers_instagram_count = influencersInstagramCount
  if (Number.isFinite(influencerTwitterCount)) result.influencers_twitter_count = influencerTwitterCount
  if (Number.isFinite(influencersYoutubeCount)) result.influencers_youtube_count = influencersYoutubeCount
  if (Number.isFinite(influencersTiktokCount)) result.influencers_tiktok_count = influencersTiktokCount
  if (Number.isFinite(infuencersAverageByNetwork)) result.influencers_average_by_network = infuencersAverageByNetwork

  if (createdAt) result.company_created_at = createdAt
  if (companySegment) result.company_segment = companySegment
  if (companySize) result.company_size_range = companySize
  if (trialEndDate) result.trial_end_date = trialEndDate

  if (planCreatedAt) result.plan_created_at = planCreatedAt
  if (planEndDate) result.plan_end_date = planEndDate
  if (planCancellationEfectiveAt) result.plan_cancellation_efective_at = planCancellationEfectiveAt
  if (planCancelledAt) result.plan_cancelled_at = planCancelledAt
  if (planCurrentPeriodStart) result.plan_current_period_start = planCurrentPeriodStart

  return result
}

export const parseBrands = (brands, brand, summary, contact) => {
  const bs = get(brands, 'self.brands')
  const totalCount = (bs || []).length
  const br = { ...get(brand, 'brand') }
  const summ = get(summary, 'brand')
  if (summ && get(summ, 'id') === get(br, 'id')) br.networkSummary = get(summ, 'networkSummary')

  if (bs) {
    return {
      company_count: totalCount,
      company: parseBrand(br, contact)
      // ,
      // companies: bs.map(b => {
      //   let last = b
      //   if (b.brandName === br.brandName) {
      //     last = br
      //   }
      //   return parseBrand(last)
      // })
    }
  }
}
