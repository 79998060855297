import { gql } from '@apollo/client'

export const brandFragment = gql`
  fragment Brand on Brand {
    id: _id
    socialMediaIntegration {
      iframeLink
    }
    logoImage
    socialUsername
    brandName
    description
    planName
    createdAt
    products
    subscriptionActive
    integrations
    styles
    integrations
    currency {
      symbol
    }
    featureFlags {
      irm {
        analysisLimitPerPeriod
        remainingAnalysisPerPeriod
        remainingInfluencers
        analysisCountPerPeriod
        influencersLimit
        influencersCount
        listsLimit
        listsCount
        remainingLists
        pdfInfluencer
        pdfList
        totalUrlLimit
        totalUrlCount
        networks
        exportCSV
        communication
        massiveComm
        massiveCommMultiplier
        remainingMassiveCommPerPeriod
        massiveCommCountPerPeriod
        customFieldsLimit
        customFieldsCount
        remainingCustomFields
        remainingTemplates
        remainingOutreaches
        printPDF
      }
      search {
        searchCount
        resultsLimitPerPeriod
        resultsCountPerPeriod
        remainingResultsPerPeriod
        networks
        exportCSV
      }
      campaignsV1 {
        addInfluencers
        showComments
        remainingCampaigns
        remainingPrograms
        campaignLimit
        campaignCount
        deleteCampaigns
        programsLimit
        programsCount
        influencerPayments
        influencerPaymentsFee
      }
      reportsV2 {
        reportsLimit
        urlLimit
        hashtagReport
        deleteUrl
        remainingReports
        addUrl
        createReport
        deleteReport
        pdfReport
        metrics
        reportCount
        remainingUrlsPerPeriod
        totalUrlsPerPeriod
        trackingLimit
        activeTrackingCount
        remainingReportsV2Trackings
        isTrackingLimitExceeded
        networks
        exportCSV
      }
      config {
        guestUsersLimit
        guestUsersCount
        remainingGuestUsers
      }
      recruitment {
        limitForms
        formsCount
        remainingForms
        externalDomain
      }
      socialMedia {
        analytics
        planner
        inbox
        smartlink
        ads
      }
      socialListening {
        mentionsLimitPerPeriod
        mentionsCountPerPeriod
        remainingMentionsPerPeriod
        alertsLimit
        alertsCount
        remainingAlerts
        exportCSV
      }
    }
    owners {
      id:_id
    }
    trial {
      isTrial
      expired
      expirationDate
      startDate
      remainingDays
      totalDays
    }
    subscriptionInfo {
      status
      isTrial
      isEnterprise
      planPeriod
      planType
      cancellationAt
      cancelledAt
      createdAt
      currentPeriodStart
      endDate
      cantChangeSubscription
      mrr
    }
    billingInfo {
      id: _id
      planId
      planItems {
        nickname
        quantity
      }
      planType
      planPeriod
      newPlanType
      newPlanPeriod
      newPlanItems {
        nickname
        quantity
      }
      brandId
      taxExempt
      paymentMethodId
      customerId
      taxRateId
      subscriptionId
      subscriptionStartAt
      country
      companyName
      email
      phone
      address1
      address2
      city
      state
      zipCode
      taxIdentifier
      isEditionBlocked
      isEnterprise
      currency
    }
    mrr
    noMrrReason
  }
`

export const brand = gql`
  query brand($brandId: String!) {
    brand(_id: $brandId) {
    ...Brand
    }
  }
  ${brandFragment}
`

export const brands = gql`
  query brands {
    self {
      id: _id
      brands {
        id: _id
        logoImage
        socialUsername
        brandName
        description
        products
        subscriptionActive
        owners{
          id:_id
        }
        trial {
          isTrial
          expired
          expirationDate
          startDate
        }
        billingInfo {
          id: _id
          planId
          planItems {
            nickname
            quantity
          }
          planType
          planPeriod
          subscriptionStartAt
        }
      }
    }
  }
`

export const uploadBrandLogo = gql`
  mutation uploadBrandLogo(
    $id: String!,
    $file: Upload
  ) {
    uploadBrandLogo(
      _id: $id,
      file: $file,
    )
  }
`

export const paymentInfo = gql`
  query paymentInfo($brandId: String!) {
    brand(_id: $brandId) {
      id: _id
      paymentInfo {
        subscriptionNextPayment {
          subtotal
          tax
          total
          amount_due
          amount_remaining
          currency
          next_payment_attempt
          invoiceLines {
            amount
            currency
            description
            nickname
            quantity
          }
        }
        uniquePayments {
          searchResults {
            defaultPrice
          }
          networkAnalyses {
            defaultPrice
          }
        }
      }
    }
  }
`

export const refreshFeatureFlags = gql`
  subscription refreshFeatureFlags($brandId: String!) {
    refreshFeatureFlags(brandId: $brandId) {
      id: _id
    }
  }
`

export const refreshRemainingFeatureFlags = gql`
  subscription refreshRemainingFeatureFlags($brandId: String!) {
    refreshRemainingFeatureFlags(brandId: $brandId) {
      id: _id
      remainingResultsPerPeriod
      remainingAnalysisPerPeriod
      remainingProfileAnalysisPerPeriod
      overlapToBeRefreshedListId
      remainingCampaigns
      remainingPrograms
      remainingUrlsPerPeriod
      remainingUrlsPerPeriodReportsV2
      remainingReports
      remainingReportsV2Trackings
      remainingGuestUsers
      remainingInfluencers
      remainingLists
      remainingForms
      remainingMassiveCommPerPeriod
      remainingCustomFields
      remainingTemplates
      remainingOutreaches
      remainingMentionsPerPeriod
      remainingAlerts
    }
  }
`
