import { gql } from '@apollo/client'

export const refreshReportV2TrackingSubscription = gql`
    subscription refreshReportV2Tracking($brandId: String) {
      refreshReportV2Tracking(brandId: $brandId) {
        brandId
        status
      }
    }
`
