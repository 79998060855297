import { gql } from '@apollo/client'

export const addEntityField = gql`
mutation addEntityField(
  $entityName: String!
  $groupName: String!
  $fieldName: String!
  $description: String
  $entityFieldType: String!
  $origin: String
  $fieldData: [EntityFieldFieldOptionsType]
) {
  addEntityField(
    entityName: $entityName
    groupName: $groupName
    fieldName: $fieldName
    description: $description
    entityFieldType: $entityFieldType
    origin: $origin
    fieldData: $fieldData
  ){
    message
    data
    success
  }
}
 `
