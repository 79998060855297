import { gql } from '@apollo/client'

export const editIrmList = gql`
  mutation editIrmList(
    $_id: String!
    $listName: String!
    $description: String
  ) {
    editIrmList(
      _id: $_id
      listName: $listName
      description: $description
    ) {
      message
      success
    }
  }
`
