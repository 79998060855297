import { useContext, useEffect } from 'react'
import { Button, FormContext, SideModalFooter, Spacing, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const EditInfluencerModalFooter = ({ isOpen, handleCreate, handleClose, loading, disabled }) => {
  const { t } = useTranslate()
  const { values, cleanForm } = useContext(FormContext)
  const onCreate = () => handleCreate(values)

  useEffect(() => {
    if (!isOpen) {
      cleanForm()
    }
  }, [isOpen])

  return (
    <SideModalFooter>
      <StateButton loading={loading} showLoader name='editPost' disabled={disabled} onClick={onCreate}>{t('commons.button.save')}</StateButton>
      <Spacing size='16' vertical />
      <Button name='cancelAddPost' disabled={loading} variant='flat' color='secondary' onClick={handleClose}>{t('commons.button.cancel')}</Button>
    </SideModalFooter>
  )
}
