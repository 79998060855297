import { FiltersTableProvider, TableContextProvider } from 'influ-dms'
import { SelectTemplateModal } from './SelectTemplateModal'

export const SelectTemplate = ({ setSelectedTemplate, setLoadingSelectedTemplate }) => {
  return (
    <>
      <FiltersTableProvider>
        <TableContextProvider initialState={
          {
            count: undefined,
            pages: undefined,
            columnName: '_id',
            selectedRows: [],
            isSingleSelect: true
          }
        }>
          <SelectTemplateModal
            setSelectedTemplate={setSelectedTemplate}
            setLoadingSelectedTemplate={setLoadingSelectedTemplate}
          />
        </TableContextProvider>
      </FiltersTableProvider>
    </>
  )
}
