import { css } from 'styled-components'
import { SideModal, Loader } from 'influ-dms'
import { EditPostModalFooter } from './EditPostModalFooter'
import { EditPostModalContent } from './content/EditPostModalContent'
import { useTranslate } from '~/hooks/useTranslate'

const contentStyles = css`
  overflow: hidden; 
  padding: 0; 
`

export const EditPostModal = ({ post, isOpen, handleCreate, handleClose, loading, editLoading, error, setError }) => {
  const { t } = useTranslate()

  return (
    <SideModal
      name='editPost'
      isPanel
      onClose={handleClose}
      isOpen={isOpen}
      title={t('reports.report.modal.publication.header')}
      contentStyles={contentStyles}
    >
      {loading ? <Loader fillSpace /> : <EditPostModalContent post={post} isOpen={isOpen} />}
      <EditPostModalFooter
        isOpen={isOpen}
        handleCreate={handleCreate}
        handleClose={handleClose}
        loading={editLoading}
        disabled={loading}
        error={error}
        setError={setError}
      />
    </SideModal>
  )
}
