import { AvatarDropdownOptionItem } from './AvatarDropdownOptionItem'
import { css } from 'styled-components'

const containerStyles = css`
  padding: 0 4px;
`

export const AvatarContactItem = ({ handleClick, isInPage, styles, children }) => (
  <AvatarDropdownOptionItem
    withHover={!isInPage}
    handleClick={handleClick}
    styles={[containerStyles, styles]}
  >
    {children}
  </AvatarDropdownOptionItem>
)
