import { gql } from '@apollo/client'

export const addInfluencersMassiveWithProfileFromIrm = gql`
  mutation addInfluencersMassiveWithProfileFromIrm(
    $profileIds: [String]!
  ) {
    addInfluencersMassiveWithProfileFromIrm(
    profileIds: $profileIds
  ) {
      message
      code
      success
      data
    }
  }
`
