import styled from 'styled-components'
import { Button, Flex, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin-top: 44px;
`

export const EmailSetupDisableInboxModalFooter = ({ loading, handleDisable, handleClose }) => {
  const { t } = useTranslate()
  return (
    <StyledFlex>
      <StateButton showLoader loading={loading} color='danger' onClick={handleDisable}>{t('settings.yourPreferences.communication.email.configuration.modal.turnOffInbox.button.yesDisable')}</StateButton>
      <Button disabled={loading} color='secondary' variant='flat' onClick={handleClose}>{t('commons.button.cancel')}</Button>
    </StyledFlex>
  )
}
