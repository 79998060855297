import { isEmpty } from 'lodash'
import { createContext, useReducer } from 'react'

const initialValues = {
  program: undefined,
  isProgramErrorModalOpen: false,
  isNewProgramModalOpen: false,
  isSetupProgramModalOpen: false,
  isEditProgramModalOpen: false,
  isAddInfluencersToProgramModalOpen: false,
  isLinkInfluencersToProgramModalOpen: false,
  isDeleteProgramModalOpen: false,
  isDeleteInfluencerFromProgramModalOpen: {
    isOpen: false,
    influencerId: null,
  },
  isSyncInfluencersProgramModalOpen: {
    isOpen: false,
    programId: null,
    influencerIds: null,
  },
  isDeleteMassiveInfluencersFromProgramModalOpen: {
    isOpen: false,
    influencerIds: []
  },
  isInfluencerCantBeEnabledModalOpen: false,
  isInfluencersCantBeEnabledModalOpen: {
    isOpen: false,
    selectedInfluencers: []
  },
  isProgramDataNotSyncModalOpen: {
    isOpen: false
  },
  influencersLoading: [],
  isLinkProgramModalOpen: {
    isOpen: false,
    selectedInfluencers: []
  },
  isSummaryInfluencersModalOpen: {
    isOpen: false,
    type: ''
  },
  isAddProgramInfluencersModalOpen: {
    isOpen: false,
    ids: [],
    entityName: ''
  },
  isConfirmEditAudienceBenefitModalOpen: {
    isOpen: false,
    formValues: {}
  },
  isInfluencerManagementModalOpen: {
    isOpen: false,
    influencerInProgram: null,
  },
  code: undefined,
  isActivateCodesModalOpen: {
    isOpen: false,
    codeIds: [],
    clearTableSelection: () => {}
  },
  isDeactivateCodesModalOpen: {
    isOpen: false,
    codeIds: [],
    benefit: '',
    clearTableSelection: () => {},
  },
  isCreateAudienceBenefitCodeModalOpen: false,
  isEditAudienceBenefitCodeModalOpen: false,
  isDeleteCodeModalOpen: false,
  isDeleteCodesModalOpen: false
}

export const ProgramContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setInfluencerManagementModalOpen':
      return { ...state, isInfluencerManagementModalOpen: action.payload }
    case 'setProgram':
      return { ...state, program: action.payload }
    case 'setProgramErrorModalOpen':
      return { ...state, isProgramErrorModalOpen: action.value }
    case 'setNewProgramModalOpen':
      return { ...state, isNewProgramModalOpen: action.value }
    case 'setSetupProgramModalOpen':
      return { ...state, isSetupProgramModalOpen: action.value }
    case 'setEditProgramModalOpen':
      return { ...state, isEditProgramModalOpen: action.value }
    case 'setAddInfluencersToProgramModalOpen':
      return { ...state, isAddInfluencersToProgramModalOpen: action.value }
    case 'setLinkInfluencersToProgramModalOpen':
      return { ...state, isLinkInfluencersToProgramModalOpen: action.value }
    case 'setDeleteProgramModalOpen':
      return { ...state, isDeleteProgramModalOpen: action.value }
    case 'setDeleteInfluencerFromProgramModalOpen':
      return { ...state, isDeleteInfluencerFromProgramModalOpen: action.payload }
    case 'setSyncInfluencersProgramModalOpen':
      return { ...state, isSyncInfluencersProgramModalOpen: action.payload }
    case 'setDeleteMassiveInfluencersFromProgramModalOpen':
      return { ...state, isDeleteMassiveInfluencersFromProgramModalOpen: action.payload }
    case 'setInfluencerCantBeEnabledModalOpen':
      return { ...state, isInfluencerCantBeEnabledModalOpen: action.value }
    case 'setInfluencersCantBeEnabledModalOpen':
      return { ...state, isInfluencersCantBeEnabledModalOpen: action.payload }
    case 'setInfluencersLoading':
      return { ...state, influencersLoading: action.value }
    case 'setProgramDataNotSyncModalOpen':
      return { ...state, isProgramDataNotSyncModalOpen: action.payload }
    case 'setLinkProgramModalOpen':
      return { ...state, isLinkProgramModalOpen: action.payload }
    case 'setSummaryInfluencersModalOpen':
      return { ...state, isSummaryInfluencersModalOpen: action.payload }
    case 'setAddProgramInfluencersModalOpen':
      return { ...state, isAddProgramInfluencersModalOpen: action.payload }
    case 'setConfirmEditAudienceBenefitModalOpen':
      return { ...state, isConfirmEditAudienceBenefitModalOpen: action.payload }
    case 'setCode':
      return { ...state, code: action.payload }
    case 'setActivateCodesModalOpen':
      return { ...state, isActivateCodesModalOpen: action.payload }
    case 'setDeactivateCodesModalOpen':
      return { ...state, isDeactivateCodesModalOpen: action.payload }
    case 'setCreateAudienceBenefitCodeModalOpen':
      return { ...state, isCreateAudienceBenefitCodeModalOpen: action.payload }
    case 'setEditAudienceBenefitCodeModalOpen':
      return { ...state, isEditAudienceBenefitCodeModalOpen: action.payload }
    case 'setDeleteCodeModalOpen':
      return { ...state, isDeleteCodeModalOpen: action.payload }
    case 'setDeleteCodesModalOpen':
      return { ...state, isDeleteCodeModalOpen: action.payload }
  }
}

export const ProgramProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setProgram = (program) => {
    dispatch({ type: 'setProgram', payload: program })
  }

  const setProgramErrorModal = (value) => {
    dispatch({ type: 'setProgramErrorModalOpen', value })
  }

  const setNewProgramModal = (value) => {
    dispatch({ type: 'setNewProgramModalOpen', value })
  }

  const setEditProgramModal = (value) => {
    dispatch({ type: 'setEditProgramModalOpen', value })
  }

  const openAddInfluencersToProgramModal = () => {
    dispatch({ type: 'setAddInfluencersToProgramModalOpen', value: true })
  }

  const closeAddInfluencersToProgramModal = () => {
    dispatch({ type: 'setAddInfluencersToProgramModalOpen', value: false })
  }

  const openSetupProgramModal = () => {
    dispatch({ type: 'setSetupProgramModalOpen', value: true })
  }

  const openLinkInfluencersToProgramModal = () => {
    dispatch({ type: 'setLinkInfluencersToProgramModalOpen', value: true })
  }

  const closeLinkInfluencersToProgramModal = () => {
    dispatch({ type: 'setLinkInfluencersToProgramModalOpen', value: false })
  }

  const openDeleteProgramModal = () => {
    dispatch({ type: 'setDeleteProgramModalOpen', value: true })
  }

  const closeDeleteProgramModal = () => {
    dispatch({ type: 'setDeleteProgramModalOpen', value: false })
  }

  const openDeleteInfluencerFromProgramModal = (influencerId) => {
    dispatch({ type: 'setDeleteInfluencerFromProgramModalOpen', payload: { isOpen: true, influencerId } })
  }

  const closeDeleteInfluencerFromProgramModal = () => {
    dispatch({ type: 'setDeleteInfluencerFromProgramModalOpen', payload: { isOpen: false, influencerId: null } })
  }

  const openSyncInfluencersProgramModal = (programId, influencerIds) => {
    dispatch({ type: 'setSyncInfluencersProgramModalOpen', payload: { isOpen: true, programId, influencerIds } })
  }

  const closeSyncInfluencersProgramModal = () => {
    dispatch({ type: 'setSyncInfluencersProgramModalOpen', payload: { isOpen: false, programId: null, influencerIds: null } })
  }

  const openDeleteMassiveInfluencersFromProgramModal = (influencerIds) => {
    dispatch({ type: 'setDeleteMassiveInfluencersFromProgramModalOpen', payload: { isOpen: true, influencerIds } })
  }

  const closeDeleteMassiveInfluencersFromProgramModal = () => {
    dispatch({ type: 'setDeleteMassiveInfluencersFromProgramModalOpen', payload: { isOpen: false, influencerIds: [] } })
  }

  const closeSetupProgramModal = () => {
    dispatch({ type: 'setSetupProgramModalOpen', value: false })
  }

  const openInfluencerCantBeEnabledModal = () => {
    dispatch({ type: 'setInfluencerCantBeEnabledModalOpen', value: true })
  }

  const closeInfluencerCantBeEnabledModal = () => {
    dispatch({ type: 'setInfluencerCantBeEnabledModalOpen', value: false })
  }

  const openInfluencersCantBeEnabledModal = (selectedInfluencers) => {
    dispatch({ type: 'setInfluencersCantBeEnabledModalOpen', payload: { isOpen: true, selectedInfluencers } })
  }

  const closeInfluencersCantBeEnabledModal = () => {
    dispatch({ type: 'setInfluencersCantBeEnabledModalOpen', payload: { isOpen: false, selectedInfluencers: [] } })
  }

  const openProgramDataNotSyncModal = () => {
    dispatch({ type: 'setProgramDataNotSyncModalOpen', payload: { isOpen: true } })
  }

  const closeProgramDataNotSyncModal = () => {
    dispatch({ type: 'setProgramDataNotSyncModalOpen', payload: { isOpen: false } })
  }

  const openInfluencerManagementModal = (influencerInProgram) => {
    dispatch({ type: 'setInfluencerManagementModalOpen', payload: { isOpen: true, influencerInProgram } })
  }

  const closeInfluencerManagementModal = () => {
    dispatch({ type: 'setInfluencerManagementModalOpen', payload: { isOpen: false, influencerInProgram: null } })
  }

  const setInfluencersLoading = (influencerIdsToAdd, influencerIdsToRemove) => {
    let actualValues = [...state.influencersLoading]
    if (!isEmpty(influencerIdsToAdd)) {
      actualValues = [...actualValues, ...influencerIdsToAdd]
    }
    if (!isEmpty(influencerIdsToRemove)) {
      actualValues = actualValues.filter(id => influencerIdsToRemove.includes(id))
    }
    dispatch({ type: 'setInfluencersLoading', value: actualValues })
  }

  const openLinkProgramModal = (selectedInfluencers) => {
    dispatch({ type: 'setLinkProgramModalOpen', payload: { isOpen: true, selectedInfluencers } })
  }

  const closeLinkProgramModal = () => {
    dispatch({ type: 'setLinkProgramModalOpen', payload: { isOpen: false, selectedInfluencers: [] } })
  }

  const setSummaryInfluencersModalOpen = (isOpen, type) => {
    dispatch({ type: 'setSummaryInfluencersModalOpen', payload: { isOpen, type } })
  }

  const setAddProgramInfluencersModalOpen = (isOpen, ids, entityName) => {
    dispatch({ type: 'setAddProgramInfluencersModalOpen', payload: { isOpen, ids, entityName } })
  }

  const setConfirmEditAudienceBenefitModalOpen = (value, formValues) => {
    dispatch({ type: 'setConfirmEditAudienceBenefitModalOpen', payload: { isOpen: value, formValues } })
  }

  const setCode = (code) => {
    dispatch({ type: 'setCode', payload: code })
  }

  const setActivateCodesModalOpen = (isOpen, codeIds, clearTableSelection = () => {}) => {
    dispatch({ type: 'setActivateCodesModalOpen', payload: { isOpen, codeIds, clearTableSelection } })
  }

  const setDeactivateCodesModalOpen = (isOpen, codeIds, benefit, influencerId = null, clearTableSelection = () => {}) => {
    dispatch({ type: 'setDeactivateCodesModalOpen', payload: { isOpen, codeIds, benefit, influencerId, clearTableSelection } })
  }

  const setCreateAudienceBenefitCodeModalOpen = (value) => {
    dispatch({ type: 'setCreateAudienceBenefitCodeModalOpen', payload: value })
  }

  const setEditAudienceBenefitCodeModalOpen = (value) => {
    dispatch({ type: 'setEditAudienceBenefitCodeModalOpen', payload: value })
  }

  const setDeleteCodeModalOpen = (value) => {
    dispatch({ type: 'setDeleteCodeModalOpen', payload: value })
  }

  const setDeleteCodesModalOpen = (value) => {
    dispatch({ type: 'setDeleteCodesModalOpen', payload: value })
  }

  return (
    <ProgramContext.Provider value={{
      ...state,
      setProgram,
      openDeleteProgramModal,
      closeDeleteProgramModal,
      setNewProgramModal,
      setEditProgramModal,
      setProgramErrorModal,
      openAddInfluencersToProgramModal,
      openSetupProgramModal,
      closeAddInfluencersToProgramModal,
      openLinkInfluencersToProgramModal,
      closeLinkInfluencersToProgramModal,
      openDeleteInfluencerFromProgramModal,
      closeDeleteInfluencerFromProgramModal,
      openDeleteMassiveInfluencersFromProgramModal,
      closeDeleteMassiveInfluencersFromProgramModal,
      closeSetupProgramModal,
      openInfluencerCantBeEnabledModal,
      closeInfluencerCantBeEnabledModal,
      openInfluencersCantBeEnabledModal,
      closeInfluencersCantBeEnabledModal,
      setInfluencersLoading,
      openProgramDataNotSyncModal,
      closeProgramDataNotSyncModal,
      openLinkProgramModal,
      closeLinkProgramModal,
      openSyncInfluencersProgramModal,
      closeSyncInfluencersProgramModal,
      setSummaryInfluencersModalOpen,
      setAddProgramInfluencersModalOpen,
      setConfirmEditAudienceBenefitModalOpen,
      openInfluencerManagementModal,
      closeInfluencerManagementModal,
      setCode,
      setActivateCodesModalOpen,
      setDeactivateCodesModalOpen,
      setEditAudienceBenefitCodeModalOpen,
      setDeleteCodeModalOpen,
      setDeleteCodesModalOpen,
      setCreateAudienceBenefitCodeModalOpen
    }}>
      {children}
    </ProgramContext.Provider>
  )
}
