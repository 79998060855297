import { useState, useContext, useEffect } from 'react'
import { get, isEmpty, unionBy } from 'lodash'
import {
  Button,
  Flex,
  Modal,
  Spacing,
  StateButton,
  Form,
  FormContext,
  Portal,
  InputSearch
} from 'influ-dms'
import styled, { css, ThemeContext } from 'styled-components'
import { FormatOptionLabel } from '../../../../search/FiltersModal/SelectNetwork/FormatOptionLabel'
import { useRouter } from 'next/router'
import { AddFromMyInfluencers } from '../../../commons/addPosts/tracking/AddFromMyInfluencers'
import { AddFromInfluencersList } from '../../../commons/addPosts/tracking/AddFromInfluencersList'
import { LIMIT_OF_PROFILES_TO_ADD } from '../../../commons/addPosts/tracking/AddProfileTrackingCard'
import { useTranslate } from '~/hooks/useTranslate'

const networkOptions = [{ value: 'instagram', label: 'Instagram' }]

const seachStyles = css`
  border: 1px solid #C5C5D2;
  background: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.dark};
  height: 32px;
  ${({ fromLists }) => fromLists
    ? 'width: 525px;'
   : `width: 328px;
      align-items: self-end;`
  }
`
const HeaderFlex = styled(Flex)`
  width: 170px;
  justify-content: space-between;
  align-items: center;
`

const AddFromListsTrackingModal = (props) => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const { isOpen, onClose, network, fromLists, setDuplicatedProfilesNumber, setTotalProfilesNumber, setDuplicatedModalOpen } = props
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { colors: { gradients } } = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)
  const [term, setTerm] = useState('')
  const limitReached = get(values.influencersInModal, 'length', 0) + get(values.influencers, 'length', 0) > LIMIT_OF_PROFILES_TO_ADD
  const isDisabled = isEmpty(values.influencersInModal) || loading || limitReached

  useEffect(() => {
    setValue('InputText', 'influencersInModal', [])
  }, [])

  const handleOnSave = () => {
    if (!isDisabled) {
      const newProfiles = unionBy(values.influencersInModal, values.influencers, 'username')
      const duplicatedProfiles = newProfiles.length - get(values.influencers, 'length', 0)
      if (values.influencersInModal.length - duplicatedProfiles !== 0) {
        setDuplicatedProfilesNumber(duplicatedProfiles)
        setTotalProfilesNumber(values.influencersInModal.length)
        setDuplicatedModalOpen(true)
      }
      setValue('InputText', 'influencers', newProfiles)
      setValue('InputText', 'influencersInModal', [])
      onClose()
    }
  }

  const handleOnCancel = () => {
    setValue('InputText', 'influencersInModal', [])
    onClose()
  }

  return (
    <Portal selector='#addFromListsTrackingModal'>
      <Modal
        name='addProfileFromInfluTracking'
        onClose={handleOnCancel}
        isOpen={isOpen}
        title={fromLists ? t('reports.modal.tracking.addProfilesFromLists.header') : t('reports.modal.tracking.addProfilesFromMyInfluencers.header')}
        height='auto'
        minHeight='500'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        <Flex column grow='1'>
          <HeaderFlex>
            {!fromLists &&
              <Form.Select
                formatOptionLabel={FormatOptionLabel}
                name='network'
                defaultValue={networkOptions[0]}
                options={networkOptions}
                color='primary.light3'
                isSearchable={false}
                value={networkOptions[0]}
                isDisabled
              />}
            {!fromLists && <Spacing vertical size='16' />}
            <InputSearch
              name='searchProfileTracking'
              autoFocus
              onChange={setTerm}
              size='l'
              styles={seachStyles}
              placeholder={fromLists ? t('reports.modal.tracking.addProfilesFromLists.search.input.placeholder') : t('reports.modal.tracking.addProfilesFromMyInfluencers.search.input.placeholder')}
              fromLists={fromLists}
              position='left'
              noMargin
            />
          </HeaderFlex>
          <Spacing size='8' />
          {fromLists
            ? (
              <AddFromInfluencersList
                brandId={brandId}
                network={network}
                setLoading={setLoading}
                searchFilter={term}
              />
              )
            : (
              <AddFromMyInfluencers
                brandId={brandId}
                network={network}
                setLoading={setLoading}
                searchFilter={term}
              />
              )}
          <Spacing size='16' />
        </Flex>
        <Flex>
          <StateButton
            name='addProfileToTracking'
            loading={loading}
            onClick={handleOnSave}
            data-cy='addProfileToTracking'
            disabled={isDisabled}
            showLoader
          >{t('commons.button.addXProfiles', { profilesNumber: get(values, 'influencersInModal.length', '') })}
          </StateButton>
          <Spacing size='16' vertical />
          <Button
            name='closeAddProfileToTracking'
            variant='flat'
            color='secondary'
            onClick={handleOnCancel}
          >
            {t('commons.button.cancel')}
          </Button>
        </Flex>
      </Modal>
    </Portal>
  )
}

export { AddFromListsTrackingModal }
