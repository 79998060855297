import { Form, FormInputWithValidations, Spacing } from 'influ-dms'
import { isEmpty } from 'lodash'
import { validateEmail } from '../../../../../stripe/PaymentFormFieldValidation'
import { useTranslate } from '~/hooks/useTranslate'

const emailValidation = {
  checkFunction: val => !validateEmail(val)
}

const requiredValidation = {
  checkFunction: isEmpty
}

export const OfficeForm = () => {
  const { t } = useTranslate()

  return (
    <>
      <Spacing size='24' />
      <FormInputWithValidations name='email' validations={[emailValidation]}>
        {({ name }) => (
          <Form.InputText
            id='email' label={t('settings.yourPreferences.communication.email.configuration.input.emailAddress.label')} name={name}
            helperText={t('settings.yourPreferences.communication.email.configuration.input.emailAddress.helperText.default')} autoComplete='off'
          />
        )}
      </FormInputWithValidations>
      <Spacing size='16' />
      <FormInputWithValidations name='password' validations={[requiredValidation]}>
        {({ name }) => (
          <Form.InputText label={t('settings.yourPreferences.communication.email.configuration.input.password.label')} name={name} type='password' helperText={t('settings.yourPreferences.communication.email.configuration.connection.office.password.helpertext')} autoComplete='new-password' />
        )}
      </FormInputWithValidations>
      <Spacing size='24' />
    </>
  )
}
