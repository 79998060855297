import { useContext, useEffect } from 'react'
import { get, some } from 'lodash'
import { InputTag, useArray } from 'influ-dms'
import { ReportsContext } from '../../../context/ReportsContext'
import { postNetworkValidator, postValidator } from '../addPostsHelper'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { brand } from '../../../../../graphql/brands'
import { useTranslate } from '~/hooks/useTranslate'

const MAX_NUMBER_OF_POST_BY_REPORT_IN_ADD = 20

export const AddPostsAutomaticTagInput = ({ setValue, error, setError }) => {
  const { t } = useTranslate()
  const [urls, setUrls] = useArray([])
  const { reportsAction } = useContext(ReportsContext)
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const brandId = get(router, 'query.brandId')
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId || isPublic })
  const networksInReportsV2 = get(data, 'brand.featureFlags.reportsV2.networks', [])

  useEffect(() => {
    setValue('InputText', 'urls', urls.filter(({ isValid }) => isValid).map(({ value }) => value))
    if (some(urls, ({ isDuplicated, isValid }) => isDuplicated || !isValid)) {
      const textErrors = urls.filter(({ isValid }) => !isValid).map(({ validationError }) => validationError)
      const storiesError = t('commons.insights.url.input.helpertext.storiesNotSupported.error')
      let errorText = `${t('reports.report.modal.addPublication.automaticAnalyses.urls.input.helpertext.error')} `
      if (textErrors.includes(storiesError)) errorText += storiesError + '.'
      setError(errorText)
    } else if (urls.length > MAX_NUMBER_OF_POST_BY_REPORT_IN_ADD) {
      setError(t('reports.report.modal.addPublication.automaticAnalyses.urls.input.helpertext.error.maxPublications', { MaxPostPerReport: MAX_NUMBER_OF_POST_BY_REPORT_IN_ADD }))
    } else {
      setError(undefined)
    }
  }, [urls.length])

  useEffect(() => {
    if (error && error.urls) {
      setUrls(prevUrls => {
        prevUrls.forEach(url => {
          url.isDuplicated = error.urls.indexOf(url.value) > -1
        })
        return [...prevUrls]
      })
    }
  }, [error])

  useEffect(() => {
    if (reportsAction && reportsAction.action === 'addPosts') {
      setUrls([])
    }
  }, [reportsAction])

  const handleUrl = val => {
    const validNetworkUrl = postNetworkValidator(val, networksInReportsV2)
    const validationError = postValidator(val, 'automatic', t) || validNetworkUrl
    setUrls({ value: val, validationError, isValid: validationError === null }, 'edit', 'value')
  }

  return (
    <InputTag
      values={urls}
      setValues={setUrls}
      handleValue={handleUrl}
      placeholder={t('reports.report.modal.addPublication.automaticAnalyses.urls.input.placeholder', { MaxPostPerReport: MAX_NUMBER_OF_POST_BY_REPORT_IN_ADD })}
    />
  )
}
