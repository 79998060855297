import { useContext } from 'react'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { RoundedButton } from 'influ-dms'
import { brand } from '../../../../../../graphql/brands'
import { ModalsContext } from '../../../../../commons/context'
import { LimitsContext } from '../../../../../../context'
import { useTranslate } from '~/hooks/useTranslate'

export const EditPostModalContentHeaderDelete = ({ postId }) => {
  const { t } = useTranslate()
  const { dispatch } = useContext(ModalsContext)
  const { validateLimit } = useContext(LimitsContext)
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId })

  const handleOnDelete = () => {
    const canDelete = get(data, 'brand.featureFlags.reportsV2.deleteUrl')
    if (!validateLimit(canDelete)) return
    dispatch({ type: 'toggleDeletePost', payload: postId })
  }
  return <RoundedButton onClick={handleOnDelete} name='trash' label={t('commons.button.delete')} />
}
