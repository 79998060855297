import { i18nNumber } from 'influ-dms'

const getFormattedTime = (value, timeUnit = 'h', showOnlyOneUnit = false) => {
  if (value === null || value === undefined) return '-'

  const units = { h: 60, m: 1, s: 1 / 60 }
  const timeFactors = { h: 60, m: 1, s: 1 }

  const totalMinutes = value * units[timeUnit]
  const hours = Math.floor(totalMinutes / timeFactors.h)
  const minutes = Math.floor((totalMinutes % timeFactors.h) / timeFactors.m)
  const seconds = Math.floor((totalMinutes % timeFactors.h) % timeFactors.m * timeFactors.s)

  const formatTime = (val, unit) => {
    if (val > 0) {
      const number = i18nNumber({ value: val, withSeparator: true, maxDecimals: 2 })
      return `${number}${unit} `
    }
    return ''
  }

  let text = ''
  if (showOnlyOneUnit) {
    const number = i18nNumber({ value, withSeparator: true, maxDecimals: 2 })
    return `${number}${timeUnit}`
  } else {
    text += formatTime(hours, 'h')
    text += formatTime(minutes, 'm')
    text += formatTime(seconds, 's')
  }

  return text.trim()
}

export { getFormattedTime }
