export const steps = {
  setup: 'setup',
  noTool: 'noTool',
  terms: 'terms',
  warning: 'warning',
  form: 'form',
  details: 'details',
  ok: 'ok',
  error: 'error'
}/* settings.yourPreferences.communication.email.configuration.modal.connectInbox.withEmailPackage.connectGmail */

export const servers = {
  gmail: {
    id: 'gmail',
    title: 'settings.yourPreferences.communication.email.configuration.modal.connectInbox.withEmailPackage.connectGmail',
    image: '/static/img/communication/gmail.png'
  },
  outlook: {
    id: 'outlook',
    title: 'communication.email.configuration.modal.connectInbox.withEmailPackage.connectOffice',
    image: '/static/img/communication/office.png'
  },
  imap: {
    id: 'imap',
    title: 'communication.email.configuration.modal.connectInbox.withEmailPackage.connectOther',
    image: '/static/img/communication/imap.png'
  }
}
