import { useApolloClient } from '@apollo/client'
import { sortObjectByKeyNames } from '~/helpers'
import {
  ROOT_QUERY,
  influencerQueries,
  influencerTableQueries,
  profileInListQueries,
  profileQueries,
  profileTableQueries,
  profilesInCampaignsQueries,
  listTableQueries,
  listDetailQueries,
  productTableQueries,
  profilePriceHistoryQueries,
  entityViewQueries,
  formPageQueries,
  programTableQueries,
  codeTableQueries,
  domainQueries,
  influencerInListQueries,
  entityFieldGroupsQueries,
  entityFieldsQueries,
  emailQueries,
  templateQueries,
  applicationForms,
  configFilters,
  outreachQueries,
  outreachRecipientQueries,
  massiveCommQueries,
  invoicesQueries,
  paymentsQueries,
  poolsQueries,
  outreachInProgramV2Queries,
  alertQueries
} from '../../constants'
import {
  cleanByProfileIdOnCampaignQueries,
  cleanCacheInQueries,
  getLastQueriesExecutedInCache
} from './campaignsUtilMethods'

const {
  GET_TABLE_PROFILES_BY_BRAND,
  COUNT_TABLE_PROFILES_BY_BRAND,
  PROFILES_ARE_ALREADY_IN_IRM,
  IRM_SUMMARY,
  IRM_SUMMARY_BY_NETWORK,
  LAST_PROFILE_ADDED
} = profileTableQueries

const { GET_IRM_LISTS_BY_PROFILE, COUNT_TABLE_IRM_LISTS_BY_PROFILE } = profileInListQueries

const { GET_IRM_LISTS_BY_INFLUENCER, COUNT_TABLE_IRM_LISTS_BY_INFLUENCER } = influencerInListQueries

const { GET_CAMPAIGNS_BETA_BY_BRAND, COUNT_TABLE_CAMPAIGNS_BETA_BY_BRAND, TASKS } = profilesInCampaignsQueries

const { PROFILE, PROFILE_BY_SHARED_LINK, PROFILE_METRICS_QUERY } = profileQueries

const {
  GET_IRM_INFLUENCERS_BY_BRAND,
  COUNT_TABLE_IRM_INFLUENCERS_BY_BRAND,
  LAST_INFLUENCERS_ADDED,
  GET_IRM_INFLUENCER_COLUMNS
} = influencerTableQueries

const { GET_IRM_INFLUENCER, PROFILES_TO_PRINT } = influencerQueries

const { GET_IRM_LISTS_BY_BRAND, COUNT_TABLE_IRM_LISTS_BY_BRAND, LAST_LISTS_ADDED } = listTableQueries

const {
  GET_IRM_LIST,
  GET_TABLE_PROFILES_IN_LIST_BY_BRAND,
  COUNT_TABLE_PROFILES_IN_LIST_BY_BRAND,
  GET_PROFILES_SUMMARY_IN_LIST,
  GET_TABLE_INFLUENCERS_IN_LIST_BY_BRAND,
  COUNT_TABLE_INFLUENCERS_IN_LIST_BY_BRAND
} = listDetailQueries

const { GET_PRODUCT_TABLES_CONFIG_BY_BRAND } = productTableQueries

const { GET_PROFILE_PRICES_HISTORY } = profilePriceHistoryQueries

const {
  GET_ENTITY_VIEWS_BY_BRAND,
  COUNT_TABLE_ENTITY_VIEWS_BY_BRAND,
  GET_ENTITY_VIEWS_BY_OBJECT_AND_USER
} = entityViewQueries

const {
  GET_FORM_PAGES_BY_BRAND,
  COUNT_TABLE_FORM_PAGES_BY_BRAND
} = formPageQueries

const {
  GET_PROGRAMS_BY_BRAND,
  COUNT_TABLE_PROGRAMS_BY_BRAND,
  GET_INFLUENCERS_IN_PROGRAM,
  COUNT_INFLUENCERS_IN_PROGRAM,
  GET_PROGRAMS_IN_INFLUENCER,
  COUNT_PROGRAMS_IN_INFLUENCER,
  GET_PROGRAM
} = programTableQueries

const {
  GET_AUDIENCE_CODES_BY_PROGRAM,
  COUNT_TABLE_AUDIENCE_CODES_BY_PROGRAM,
  GET_GIFTING_CODES_BY_PROGRAM,
  COUNT_TABLE_GIFTING_CODES_BY_PROGRAM,
  GET_AUDIENCE_CODES_BY_INFLUENCER,
  COUNT_TABLE_AUDIENCE_CODES_BY_INFLUENCER,
  GET_GIFTING_CODES_BY_INFLUENCER
} = codeTableQueries

const {
  GET_DOMAINS_BY_BRAND,
  COUNT_TABLE_DOMAINS_BY_BRAND
} = domainQueries

const {
  GET_ENTITY_FIELD_GROUPS_BY_BRAND,
  COUNT_ENTITY_FIELD_GROUPS_BY_BRAND
} = entityFieldGroupsQueries

const {
  GET_ENTITY_FIELDS_BY_BRAND,
  COUNT_ENTITY_FIELDS_BY_BRAND,
  GET_GROUPED_ENTITY_FIELDS,
  GET_ENTITY_FIELDS_BY_ENTITY
} = entityFieldsQueries

const {
  GET_INFLUENCER_EMAILS
  // GET_EMAIL_ATTACHMENT
} = emailQueries

const {
  GET_TEMPLATES_BY_BRAND,
  COUNT_TABLE_TEMPLATES_BY_BRAND
} = templateQueries

const {
  GET_APPLICATION_FORM_BY_ID
} = applicationForms

const {
  GET_MT_CONFIG_FILTERS
} = configFilters

const {
  GET_OUTREACHES_BY_BRAND,
  COUNT_TABLE_OUTREACHES_BY_BRAND,
  GET_OUTREACH_COLUMNS,
  GET_OUTREACH
} = outreachQueries

const {
  GET_OUTREACH_RECIPIENTS,
  COUNT_TABLE_OUTREACH_RECIPIENTS
} = outreachRecipientQueries

const {
  GET_MASSIVE_COMM_CONFIG
} = massiveCommQueries

const {
  GET_INVOICES_BY_BRAND,
  COUNT_INVOICES_BY_BRAND,
  GET_INVOICE,
  CAN_GENERATE_INVOICE
} = invoicesQueries

const {
  GET_PAYMENTS_BY_BRAND,
  COUNT_PAYMENTS_BY_BRAND,
  COUNT_INFLUENCERS_TO_PAY,
  GET_PAYMENT_SUMMARY
} = paymentsQueries

const {
  GET_POOLS_BY_BRAND,
  COUNT_POOL_BY_BRAND
} = poolsQueries

const {
  GET_OUTREACH_TABLE_IN_PROGRAM,
  COUNT_OUTREACH_TABLE_IN_PROGRAM
} = outreachInProgramV2Queries

const {
  GET_ALERTS_BY_BRAND,
  COUNT_TABLE_ALERTS_BY_BRAND,
  GET_ALERT_COLUMNS,
  GET_ALERT,
  GET_ALERT_MENTIONS_TABLE_BY_ALERT,
  COUNT_ALERT_MENTIONS_TABLE_BY_ALERT
} = alertQueries

export const useCleanCache = () => {
  const client = useApolloClient()
  const cache = client.cache

  const evictByVariables = (cache, queryName, variables) => {
    const lastQueries = getLastQueriesExecutedInCache(cache.extract().ROOT_QUERY)
    const queries = lastQueries?.filter((query) => query.includes(queryName))

    queries.forEach((query) => {
      if (!query) return
      const queryVars = query.split('(')?.[1]?.split(')')?.[0]

      if (!queryVars) return
      const vars = JSON.parse(queryVars)

      const includesVars = Object.entries(variables).some(([key, value]) => vars[key] === value || (vars[key] && (vars[key][0] === value[0])))
      if (!includesVars) return
      cache.evict({
        id: ROOT_QUERY,
        fieldName: query
      })
    })
  }

  const evictActiveQuery = (queryNameToEvict = '') => {
    // Getting variables from last query executed, sorting its keys and converting to string
    const observableQueries = Array.from(client.getObservableQueries().values())
    const lastQueryOnTableProfiles = observableQueries?.find(
      ({ queryName }) => queryName === queryNameToEvict
    )
    if (lastQueryOnTableProfiles) {
      // Sorting and converting to string
      const sortedVariables = sortObjectByKeyNames(lastQueryOnTableProfiles?.last?.variables)
      const stringifiedVariables = JSON.stringify(sortedVariables)
      const lastQueryWithQueryAndVars = `${queryNameToEvict}(${stringifiedVariables})`

      // Getting from cache every query executed with same name
      const queriesArray = Object.keys(client.cache.extract().ROOT_QUERY).filter((queryName) =>
        queryName.includes(queryNameToEvict)
      )

      // Evicting every query and only requesting the current active query
      queriesArray.forEach((query) => {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: query,
          broadcast: query === lastQueryWithQueryAndVars
        })
      })
      return
    }
    cache.evict({
      id: ROOT_QUERY,
      fieldName: queryNameToEvict
    })
  }

  const commonClearTables = ({ flags, tableQuery = '', countQuery = '' }) => {
    evictActiveQuery(tableQuery)
    flags?.evictCount && evictActiveQuery(countQuery)
  }

  const clearProfileTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_TABLE_PROFILES_BY_BRAND,
      countQuery: COUNT_TABLE_PROFILES_BY_BRAND
    })

    cache.evict({ id: ROOT_QUERY, fieldName: PROFILES_ARE_ALREADY_IN_IRM })

    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: IRM_SUMMARY })
    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: IRM_SUMMARY_BY_NETWORK })
    cache.evict({ id: ROOT_QUERY, fieldName: LAST_PROFILE_ADDED })
  }

  // bea-todo replicate
  const clearProfileInListsTableCache = (profileId, flags = { evictCount: true }) => {
    if (profileId) {
      evictByVariables(cache, GET_IRM_LISTS_BY_PROFILE, { profileId })
      flags.evictCount &&
        evictByVariables(cache, COUNT_TABLE_IRM_LISTS_BY_PROFILE, { profileId })
    } else {
      commonClearTables({
        flags,
        tableQuery: GET_IRM_LISTS_BY_PROFILE,
        countQuery: COUNT_TABLE_IRM_LISTS_BY_PROFILE
      })
    }
  }

  const clearProfileInCampaignsTableCache = (profileId, flags = { evictCount: true }) => {
    const lastQueriesExecuted = getLastQueriesExecutedInCache(cache.extract().ROOT_QUERY)
    const campaignsQueries = lastQueriesExecuted.filter((queryName) =>
      queryName.includes('getCampaignsbetaByBrand')
    )
    const countTableCampaignQueries = lastQueriesExecuted.filter((queryName) =>
      queryName.includes('countTableCampaignsbetaByBrand')
    )

    if (profileId) {
      // For campaignQueries
      cleanCacheInQueries(cache, profileId, campaignsQueries)
      // For count table
      cleanCacheInQueries(cache, profileId, countTableCampaignQueries)
    } else {
      commonClearTables({
        flags,
        tableQuery: GET_CAMPAIGNS_BETA_BY_BRAND,
        countQuery: COUNT_TABLE_CAMPAIGNS_BETA_BY_BRAND
      })
    }
  }

  const clearProfileDetailCache = (profileId) => {
    if (profileId) {
      evictByVariables(cache, PROFILE, { _id: profileId })
      evictByVariables(cache, PROFILE_BY_SHARED_LINK, { profileId })
      evictByVariables(cache, PROFILE_METRICS_QUERY, { _id: profileId })
      clearProfileInListsTableCache(profileId)
      clearProfileInCampaignsTableCache(profileId)
    }
  }

  const clearInfluencerTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_IRM_INFLUENCERS_BY_BRAND,
      countQuery: COUNT_TABLE_IRM_INFLUENCERS_BY_BRAND
    })

    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: IRM_SUMMARY })
    cache.evict({ id: ROOT_QUERY, fieldName: LAST_INFLUENCERS_ADDED })
    cache.evict({ id: ROOT_QUERY, fieldName: GET_IRM_INFLUENCER_COLUMNS })
  }

  const clearInfluencerDetailCache = (influencerId) => {
    influencerId ? evictByVariables(cache, GET_IRM_INFLUENCER, { influencerId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_IRM_INFLUENCER })
    influencerId ? evictByVariables(cache, GET_GROUPED_ENTITY_FIELDS, { entityId: influencerId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_GROUPED_ENTITY_FIELDS })
    cache.evict({ id: ROOT_QUERY, fieldName: PROFILES_TO_PRINT })
  }

  const clearListTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_IRM_LISTS_BY_BRAND,
      countQuery: COUNT_TABLE_IRM_LISTS_BY_BRAND
    })
    cache.evict({ id: ROOT_QUERY, fieldName: GET_IRM_LISTS_BY_BRAND })

    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: IRM_SUMMARY })
    cache.evict({ id: ROOT_QUERY, fieldName: LAST_LISTS_ADDED })
  }

  const clearListDetailCache = (listId, flags = { evictCount: true }) => {
    listId ? evictByVariables(cache, GET_IRM_LIST, { listId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_IRM_LIST })
    listId ? evictByVariables(cache, GET_TABLE_PROFILES_IN_LIST_BY_BRAND, { listId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_TABLE_PROFILES_IN_LIST_BY_BRAND })
    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: COUNT_TABLE_PROFILES_IN_LIST_BY_BRAND })
    listId ? evictByVariables(cache, GET_PROFILES_SUMMARY_IN_LIST, { listId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_PROFILES_SUMMARY_IN_LIST })
    listId ? evictByVariables(cache, GET_TABLE_INFLUENCERS_IN_LIST_BY_BRAND, { listId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_TABLE_INFLUENCERS_IN_LIST_BY_BRAND })
    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: COUNT_TABLE_INFLUENCERS_IN_LIST_BY_BRAND })
  }

  const clearCampaignTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_CAMPAIGNS_BETA_BY_BRAND,
      countQuery: COUNT_TABLE_CAMPAIGNS_BETA_BY_BRAND
    })
  }

  const clearCampaignDetailCache = (data, options) => {
    const lastQueriesExecuted = getLastQueriesExecutedInCache(cache.extract().ROOT_QUERY)
    const campaignsQueries = lastQueriesExecuted.filter((queryName) =>
      queryName.includes(GET_CAMPAIGNS_BETA_BY_BRAND)
    )
    const countTableCampaignQueries = lastQueriesExecuted.filter((queryName) =>
      queryName.includes(COUNT_TABLE_CAMPAIGNS_BETA_BY_BRAND)
    )

    const { variables } = options
    const { data: addInfluencerInCampaign } = data

    if (variables?.profileIds?.length) {
      const profilesIdFromOption = variables?.profileIds
      // For campaign queries
      cleanByProfileIdOnCampaignQueries(cache, profilesIdFromOption, campaignsQueries)
      // For count table
      cleanByProfileIdOnCampaignQueries(cache, profilesIdFromOption, countTableCampaignQueries)
    } else {
      cache.evict({ id: ROOT_QUERY, fieldName: GET_CAMPAIGNS_BETA_BY_BRAND })
      cache.evict({ id: ROOT_QUERY, fieldName: COUNT_TABLE_CAMPAIGNS_BETA_BY_BRAND })
    }

    const influencers = addInfluencerInCampaign?.addInfluencerInCampaign
    if (!influencers) return
    for (const influencer of influencers) {
      const identity = cache.identify(influencer)
      cache.evict({ id: ROOT_QUERY, identity, fieldName: TASKS })
    }
  }

  const clearProductTablesConfigCache = (data, options) => {
    const { variables } = options
    variables?.brandId && variables?.product && variables?.type
      ? evictByVariables(cache, GET_PRODUCT_TABLES_CONFIG_BY_BRAND, {
        brandId: variables.brandId,
        product: variables.product,
        type: variables.type
      })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_PRODUCT_TABLES_CONFIG_BY_BRAND })
  }

  const clearProfilePriceHistory = (data, options) => {
    const { variables } = options
    variables.profileId &&
      evictByVariables(cache, GET_PROFILE_PRICES_HISTORY, { profileId: variables.profileId })
  }

  const clearViewTableCache = (flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_ENTITY_VIEWS_BY_BRAND, countQuery: COUNT_TABLE_ENTITY_VIEWS_BY_BRAND })
  }

  const clearViewDetailCache = () => {
    cache.evict({ id: ROOT_QUERY, fieldName: GET_ENTITY_VIEWS_BY_OBJECT_AND_USER })
  }

  const clearLandingPageTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_FORM_PAGES_BY_BRAND, countQuery: COUNT_TABLE_FORM_PAGES_BY_BRAND })
  }

  const clearProgramTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_PROGRAMS_BY_BRAND, countQuery: COUNT_TABLE_PROGRAMS_BY_BRAND })
  }

  const clearAudienceCodeTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_AUDIENCE_CODES_BY_PROGRAM, countQuery: COUNT_TABLE_AUDIENCE_CODES_BY_PROGRAM })
    commonClearTables({ flags, tableQuery: GET_AUDIENCE_CODES_BY_INFLUENCER, countQuery: COUNT_TABLE_AUDIENCE_CODES_BY_INFLUENCER })
  }

  const clearGiftingCodeTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_GIFTING_CODES_BY_PROGRAM, countQuery: COUNT_TABLE_GIFTING_CODES_BY_PROGRAM })
    commonClearTables({ flags, tableQuery: GET_GIFTING_CODES_BY_INFLUENCER })
  }

  const clearProgramDetailCache = (programId, flags = { evictCount: true }) => {
    programId ? evictByVariables(cache, GET_PROGRAM, { _id: programId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_PROGRAM })
    programId ? evictByVariables(cache, GET_INFLUENCERS_IN_PROGRAM, { programId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_INFLUENCERS_IN_PROGRAM })
    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: COUNT_INFLUENCERS_IN_PROGRAM })
  }

  const clearProgramsInInfluencer = (influencerId, flags = { evictCount: true }) => {
    influencerId ? evictByVariables(cache, GET_PROGRAMS_IN_INFLUENCER, { influencerId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_PROGRAMS_IN_INFLUENCER })
    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: COUNT_PROGRAMS_IN_INFLUENCER })
  }

  // const clearProfileInListsTableCache = (profileId, flags = { evictCount: true }) => {
  //   if (profileId) {
  //     evictByVariables(cache, GET_IRM_LISTS_BY_PROFILE, { profileId })
  //     flags.evictCount &&
  //       evictByVariables(cache, COUNT_TABLE_IRM_LISTS_BY_PROFILE, { profileId })
  //   } else {
  //     commonClearTables({
  //       flags,
  //       tableQuery: GET_IRM_LISTS_BY_PROFILE,
  //       countQuery: COUNT_TABLE_IRM_LISTS_BY_PROFILE
  //     })
  //   }
  // }

  // bea-todo 2
  const clearListsInInfluencer = (influencerId, flags = { evictCount: true }) => {
    if (influencerId) {
      evictByVariables(cache, GET_IRM_LISTS_BY_INFLUENCER, { influencerId })
      flags.evictCount &&
         evictByVariables(cache, COUNT_TABLE_INFLUENCERS_IN_LIST_BY_BRAND, { influencerId })
    } else {
      commonClearTables({
        flags,
        tableQuery: GET_IRM_LISTS_BY_INFLUENCER,
        countQuery: COUNT_TABLE_IRM_LISTS_BY_INFLUENCER
      })
    }
  }

  const clearDomainsTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_DOMAINS_BY_BRAND, countQuery: COUNT_TABLE_DOMAINS_BY_BRAND })
  }

  const clearEntityFieldGroupsTableCache = (flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_ENTITY_FIELD_GROUPS_BY_BRAND, countQuery: COUNT_ENTITY_FIELD_GROUPS_BY_BRAND })
  }

  const clearEntityFieldsTableCache = (flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_ENTITY_FIELDS_BY_BRAND, countQuery: COUNT_ENTITY_FIELDS_BY_BRAND })
  }

  const clearApplicationFormCache = (formId) => {
    formId ? evictByVariables(cache, GET_APPLICATION_FORM_BY_ID, { formId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_APPLICATION_FORM_BY_ID })
  }

  const clearConfigFiltersCache = (brandId, tableName) => {
    (brandId && tableName) ? evictByVariables(cache, GET_MT_CONFIG_FILTERS, { brandId, tableName })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_MT_CONFIG_FILTERS })
  }

  const clearMassiveCommTokensCache = () => {
    cache.evict({ id: ROOT_QUERY, fieldName: GET_ENTITY_FIELDS_BY_ENTITY })
  }

  const clearInfluencerEmailsTabCache = (influencerEmail) => {
    influencerEmail ? evictByVariables(cache, GET_INFLUENCER_EMAILS, { influencerAccounts: [influencerEmail] })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_INFLUENCER_EMAILS })
  }

  const clearTemplateTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_TEMPLATES_BY_BRAND,
      countQuery: COUNT_TABLE_TEMPLATES_BY_BRAND
    })
    cache.evict({ id: ROOT_QUERY, fieldName: GET_TEMPLATES_BY_BRAND })

    flags.evictCount && cache.evict({ id: ROOT_QUERY, fieldName: IRM_SUMMARY })
    cache.evict({ id: ROOT_QUERY, fieldName: LAST_LISTS_ADDED })
  }

  const clearOutreachTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_OUTREACHES_BY_BRAND,
      countQuery: COUNT_TABLE_OUTREACHES_BY_BRAND
    })
    cache.evict({ id: ROOT_QUERY, fieldName: GET_OUTREACH_COLUMNS })
  }

  const clearOutreachDetailCache = (outreachId, flags = { evictCount: true }) => {
    outreachId ? evictByVariables(cache, GET_OUTREACH, { _id: outreachId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_OUTREACH })
    outreachId ? evictByVariables(cache, GET_GROUPED_ENTITY_FIELDS, { entityId: outreachId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_GROUPED_ENTITY_FIELDS })
  }

  const clearOutreachRecipientsTableCache = (outreachId, flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_OUTREACH_RECIPIENTS,
      countQuery: COUNT_TABLE_OUTREACH_RECIPIENTS
    })
    outreachId ? evictByVariables(cache, GET_OUTREACH, { _id: outreachId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_OUTREACH })
    outreachId ? evictByVariables(cache, GET_GROUPED_ENTITY_FIELDS, { entityId: outreachId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_GROUPED_ENTITY_FIELDS })
    cache.evict({ id: ROOT_QUERY, fieldName: GET_OUTREACH_RECIPIENTS })
  }

  const clearMassiveCommCache = () => {
    cache.evict({ id: ROOT_QUERY, fieldName: GET_MASSIVE_COMM_CONFIG })
  }

  const clearInvoicesTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_INVOICES_BY_BRAND, countQuery: COUNT_INVOICES_BY_BRAND })
  }

  const clearInvoiceDetailCache = (invoiceId, flags = { evictCount: true }) => {
    invoiceId ? evictByVariables(cache, GET_INVOICE, { _id: invoiceId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_INVOICE })
  }

  const clearPaymentsTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_PAYMENTS_BY_BRAND, countQuery: COUNT_PAYMENTS_BY_BRAND })
  }

  const clearCountInfluencersToPayCache = () => {
    cache.evict({ id: ROOT_QUERY, fieldName: COUNT_INFLUENCERS_TO_PAY })
  }

  const clearGetPaymentSummaryCache = () => {
    cache.evict({ id: ROOT_QUERY, fieldName: GET_PAYMENT_SUMMARY })
  }

  const clearPoolsTableCache = (cache, flags = { evictCount: true }) => {
    commonClearTables({ flags, tableQuery: GET_POOLS_BY_BRAND, countQuery: COUNT_POOL_BY_BRAND })
  }

  const clearCanGenerateInvoiceCache = () => {
    cache.evict({ id: ROOT_QUERY, fieldName: CAN_GENERATE_INVOICE })
  }

  const clearOutreachInProgramV2TableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_OUTREACH_TABLE_IN_PROGRAM,
      countQuery: COUNT_OUTREACH_TABLE_IN_PROGRAM
    })
  }

  const clearAlertTableCache = (flags = { evictCount: true }) => {
    commonClearTables({
      flags,
      tableQuery: GET_ALERTS_BY_BRAND,
      countQuery: COUNT_TABLE_ALERTS_BY_BRAND
    })
    cache.evict({ id: ROOT_QUERY, fieldName: GET_ALERT_COLUMNS })
  }

  const clearAlertDetailCache = (alertId, flags = { evictCount: true }) => {
    alertId ? evictByVariables(cache, GET_ALERT, { _id: alertId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_ALERT })
    alertId ? evictByVariables(cache, GET_GROUPED_ENTITY_FIELDS, { entityId: alertId })
      : cache.evict({ id: ROOT_QUERY, fieldName: GET_GROUPED_ENTITY_FIELDS })
  }

  const clearAlertMentionsTableCache = (alertId, flags = { evictCount: true }) => {
    evictByVariables(cache, GET_ALERT_MENTIONS_TABLE_BY_ALERT, { socialListeningAlertId: alertId })
    flags.evictCount &&
      evictByVariables(cache, COUNT_ALERT_MENTIONS_TABLE_BY_ALERT, { socialListeningAlertId: alertId })
  }

  return {
    clearCampaignDetailCache,
    clearCampaignTableCache,
    clearInfluencerDetailCache,
    clearInfluencerTableCache,
    clearListDetailCache,
    clearListTableCache,
    clearProductTablesConfigCache,
    clearProfileDetailCache,
    clearProfileInCampaignsTableCache,
    clearProfileInListsTableCache,
    clearProfilePriceHistory,
    clearViewDetailCache,
    clearViewTableCache,
    clearProfileTableCache,
    clearLandingPageTableCache,
    clearProgramTableCache,
    clearProgramDetailCache,
    clearProgramsInInfluencer,
    clearAudienceCodeTableCache,
    clearGiftingCodeTableCache,
    clearDomainsTableCache,
    clearListsInInfluencer,
    clearEntityFieldGroupsTableCache,
    clearEntityFieldsTableCache,
    clearApplicationFormCache,
    clearConfigFiltersCache,
    clearMassiveCommTokensCache,
    clearTemplateTableCache,
    clearInfluencerEmailsTabCache,
    clearOutreachTableCache,
    clearOutreachDetailCache,
    clearOutreachRecipientsTableCache,
    clearMassiveCommCache,
    clearInvoicesTableCache,
    clearInvoiceDetailCache,
    clearPaymentsTableCache,
    clearCountInfluencersToPayCache,
    clearPoolsTableCache,
    clearGetPaymentSummaryCache,
    clearCanGenerateInvoiceCache,
    clearOutreachInProgramV2TableCache,
    clearAlertTableCache,
    clearAlertDetailCache,
    clearAlertMentionsTableCache
  }
}
