import { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Form, HelperText, SideBox, Spacing, Text, FormContext } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../styles'

const StyledText = styled(Text)`
  margin-left: 4px;
`

export const AddPostsManualCheckAudienceData = ({ disabled }) => {
  const { t } = useTranslate()
  const { setValue } = useContext(FormContext)

  useEffect(() => {
    if (!disabled) {
      setValue('SimpleCheckbox', 'requestAudienceData', true)
    }
  }, [])

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.audienceDataBlock.title')}</Text>
      <Spacing size='16' />
      <Form.SimpleCheckbox name='requestAudienceData' value='requestAudienceData' disabled={disabled}>
        <StyledText size='13'>{t('reports.report.modal.publication.audienceData.analyzePublicationAudience.checkbox')}</StyledText>
      </Form.SimpleCheckbox>
      <HelperText>
        {t('reports.report.modal.publication.audienceData.analyzePublicationAudience.titleParagraph1')}
      </HelperText>
      <Spacing size='8' />
    </SideBox>
  )
}
