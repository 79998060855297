import { gql } from '@apollo/client'

export const registerRelationshipIrmInfluencerProfilesMutation = gql`
 mutation registerRelationshipIrmInfluencerProfiles(
  $influencerId: [String]!
  $profileIds: [String]!
 ) {
  registerRelationshipIrmInfluencerProfiles(
    influencerId : $influencerId
    profileIds: $profileIds
   ){
      message
      code
      success
    }
 }
 `
