import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useMutation } from '@apollo/client'
import { Form, Modal } from 'influ-dms'
import { EmailSetupUpdatePasswordModalFooter } from './EmailSetupUpdatePasswordModalFooter'
import { EmailSetupUpdatePasswordModalContent } from './EmailSetupUpdatePasswordModalContent'
import { updateCommunicationPasswordMutation } from '../../../../../../../commons/communication/apollo/communicationMutations'
import { get } from 'lodash'
import { CommunicationContext } from '../../../../../../../commons/communication/CommunicationContext'
import { ConnectSuccess } from '../../../../../../../commons/communication/setup/forms/result/ConnectSuccess'
import { ConnectError } from '../../../../../../../commons/communication/setup/forms/result/ConnectError'
import { COMMUNICATION_ACTIONS } from '../../../../../../../commons/communication/CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const EmailSetupUpdatePasswordModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(0)
  const { setCommunicationAction } = useContext(CommunicationContext)
  const { colors: { gradients } } = useContext(ThemeContext)
  const [updateCommunicationPassword, { loading }] = useMutation(updateCommunicationPasswordMutation)
  const { t } = useTranslate()

  useEffect(() => {
    if (!isOpen) {
      const timeoutId = setTimeout(() => {
        setStep(0)
      }, 500)
      return () => { clearTimeout(timeoutId) }
    }
  }, [isOpen])

  const handleErrorRetry = () => {
    setStep(0)
  }

  const handleSave = values => {
    updateCommunicationPassword({ variables: values })
      .then(response => {
        if (get(response, 'data.updateCommunicationPassword.success')) {
          setCommunicationAction({ type: COMMUNICATION_ACTIONS.accountEnabled })
          setStep(1)
        } else {
          setStep(2)
        }
      })
      .catch(() => {
        setStep(2)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('settings.yourPreferences.communication.email.personalEmailBlock.emailBlock.button.updatePassword')}
      gradient1={gradients[1][0]}
      gradient2={gradients[1][1]}
      onClose={onClose}
      height='auto'
    >
      {step === 0 && (
        <Form>
          <EmailSetupUpdatePasswordModalContent />
          <EmailSetupUpdatePasswordModalFooter loading={loading} saveText={t('settings.yourPreferences.communication.email.personalEmailBlock.emailBlock.button.updatePassword')} handleSave={handleSave} handleClose={onClose} />
        </Form>
      )}
      {step === 1 && <ConnectSuccess handleClose={onClose} />}
      {step === 2 && <ConnectError handleRetry={handleErrorRetry} />}
    </Modal>
  )
}
