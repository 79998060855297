import { useEffect, useState } from 'react'

export const useCountDown = (initialTimeInSeconds) => {
  const [count, setCount] = useState(initialTimeInSeconds)
  useEffect(() => {
    const timerCountDown = setTimeout(() => count && count > 0 && setCount(count - 1), 1000)
    return () => clearTimeout(timerCountDown)
  }, [count])
  return { count, setCount }
}
