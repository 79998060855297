import { gql } from '@apollo/client'

export const accountTLSFragment = gql`
fragment accountTLS on AccountTLSType {
  rejectUnauthorized
  minVersion
}`

export const accountEmailProtocolFragment = gql`
fragment accountEmailProtocol on AccountEmailProtocolType {
  host
  port
  secure
  tls {
    ...accountTLS
  }
}
${accountTLSFragment}
`

export const getCommunicationAccountSettingsQuery = gql`
query getCommunicationAccountSettings {
  getCommunicationAccountSettings {
    success
    account
    name
    imap {
      ...accountEmailProtocol
    }
    smtp {
      ...accountEmailProtocol
    }
    signature
    signatureAttachments {
      attachmentId
      encodedSize
      filename
      inline
      contentId
      content
    }
    lastSyncDate
    disabled
    type
    status {
      code
    }
  }
}
${accountEmailProtocolFragment}
`
