import { onError } from '@apollo/client/link/error'
import { get, isEqual, isEmpty } from 'lodash'
import { RouterHelper } from '../routing/routerHelper'
import logout from '../helpers/logout'
import axios from 'axios'
import getConfig from 'next/config'
import { captureException } from '@sentry/nextjs'

const { publicRuntimeConfig } = getConfig()

let knownErrors = []

const handleNetworkError = (errors) => {
  const operationName = get(errors, 'operation.operationName')
  const isLoginRequest = operationName === 'login' || operationName === 'user'
  const networkError = get(errors, 'networkError')
  const networkErrorResult = get(networkError, 'result') || ''
  const networkErrorMessage = get(networkError, 'message') || ''
  const isLimitReached = get(networkError, 'statusCode') === 429
  const isMaintenanceMode = get(networkError, 'statusCode') === 503
  const isNetworkError = typeof window !== 'undefined' && !isEmpty(networkErrorMessage)
  const isUnauthenticated = get(networkErrorResult, 'errors[0].extensions.code') === 'UNAUTHENTICATED' || networkErrorMessage === '401' ||
  networkErrorMessage === 'ws 401' || networkErrorMessage.includes('Token expired')
  const isForbidden = get(networkErrorResult, 'errors[0].extensions.code') === 'FORBIDDEN' || networkErrorMessage === '403' ||
  networkErrorMessage === 'ws 403'
  const failedToFetch = networkErrorMessage === 'Failed to fetch'

  if (typeof window !== 'undefined' && isLimitReached) {
    window.location.href = RouterHelper.getRoute('limitReached')
    return
  }
  if (typeof window !== 'undefined' && isMaintenanceMode) {
    console.warn(networkErrorResult)
    window.location.href = RouterHelper.getRoute('maintenanceMode')
    return
  }
  const textError = '[Network error]: ' + networkErrorMessage + ' operationName: ' + operationName
  const objectError = {
    variables: get(errors, 'operation.variables'),
    networkError: get(errors, 'networkError')
  }
  if (isNetworkError && !isUnauthenticated && !isForbidden && !failedToFetch) {
    captureException(textError)
  }
  if (isLoginRequest) return
  if (typeof window !== 'undefined' && isNetworkError && operationName === 'contact') {
    window.location.href = RouterHelper.getRoute('networkError')
  }
}

const handleUnauthorizedError = (errors) => {
  const operationName = get(errors, 'operation.operationName')
  const isLoginRequest = operationName === 'login'
  if (isLoginRequest) return

  const errorMessage = get(errors, 'graphQLErrors[0].message', '')
  const is401 = isEqual(errorMessage, '401') || isEqual(errorMessage, 'Context creation failed: 401')
  const is403 = isEqual(errorMessage, '403') || isEqual(errorMessage, 'Context creation failed: 403')
  const isShareExpired = isEqual(errorMessage, '410') || isEqual(errorMessage, 'Context creation failed: 410')
  const isUserBlocked = isEqual(errorMessage, '423') || isEqual(errorMessage, 'Context creation failed: 423')

  if (typeof window !== 'undefined' && isShareExpired) window.location.href = RouterHelper.getRoute('shareExpired')
  if (isUserBlocked) { logout({errorCode:'423'}); return } // user blocked

  const isUnauthorizedError = is401 || is403
  const isRecognizedError = isUnauthorizedError || isShareExpired || isUserBlocked

  const unauthorizedUrl = typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem('unauthorizedUrl'))
  const loginSuccessPreviousUnauthorizedUrl = get(unauthorizedUrl, 'loginSuccessPreviousUnauthorizedUrl')
  const lastUnauthorizedUrl = typeof window !== 'undefined' && window.location.href
  if (isUnauthorizedError && loginSuccessPreviousUnauthorizedUrl !== lastUnauthorizedUrl && typeof window !== 'undefined') {
    window.localStorage.setItem('unauthorizedUrl', JSON.stringify({ lastUnauthorizedUrl }))
  }

  if (isUnauthorizedError && loginSuccessPreviousUnauthorizedUrl === lastUnauthorizedUrl) { logout({errorCode:'403'}); return }
  if (isUnauthorizedError) logout()
  if (!isRecognizedError) handleErrorLogs(errors)
}

const handleErrorLogs = async (errors) => {
  const graphQLErrors = get(errors, 'graphQLErrors')
  const networkError = get(errors, 'networkError')
  const isMaintenanceMode = get(networkError, 'statusCode') === 503
  if (typeof window !== 'undefined' && graphQLErrors && !isMaintenanceMode) {
    let errorText = ''
    let firstMessage
    if (knownErrors.length < 1) {
      const res = await axios.get(publicRuntimeConfig.graphqlUri.replace(new RegExp('graphql$'), '') + 'error-codes')
      if (res.status < 300) { knownErrors = res.data && res.data.errorCodes }
    }
    graphQLErrors && graphQLErrors.map(({ message, locations, path, extensions }) => {
      if (!firstMessage) firstMessage = message
      const isKnownError = knownErrors.includes(extensions.code) || knownErrors.includes(parseInt(message))
      if (!isKnownError) {
        errorText += `Message: ${message}, Path: ${path}, Location: ${JSON.stringify(locations, null, 2)}\n`
      }
    })
    if (errorText !== '') {
      captureException(`[GraphQL error]: ${firstMessage} \n` + errorText)
    }
  }
}

const afterware = onError((errors) => {
  handleNetworkError(errors)
  handleUnauthorizedError(errors)
})

export default afterware
