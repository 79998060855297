import { gql } from '@apollo/client'

export const updateInfluencerClientStatus = gql`
mutation updateInfluencerClientStatus(
  $influencerInCampaignIds: [String!]
  $clientStatus: String!
  ) {
    updateInfluencerClientStatus(
      ids: $influencerInCampaignIds
      clientStatus: $clientStatus
    ){
      id: _id
      updatedAt
      clientStatus
    }
  }
`
