import { Text, Flex, Spacing, SelectButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { css } from 'styled-components'

const flexStyles = css`
  padding: 0px 4px;
`
const dropDownTopStyles = css`
  bottom: 26px;
  position: absolute;
`

export const AddProfileTrackingCardEmpty = ({ addOptions, selectButtonStyles }) => {
  const { t } = useTranslate()

  return (
    <>
      <Flex center>
        <img
          width='80px' height='80px'
          src='/static/img/tracking/noProfilesTracking.png'
          alt='Add profiles to Tracking'
        />
        <Spacing size='16' />
        <Text size='15' color='primary.dark' bold>{t('reports.modal.tracking.profilesBlock.empty.titleHeader1')}</Text>
      </Flex>
      <Spacing size='8' />
      <Flex justify='center' styles={flexStyles}>
        <Flex column styles={flexStyles}>
          <Text size='14' color='primary.light3'>•</Text>
          <Text size='14' color='primary.light3'>•</Text>
          <Text size='14' color='primary.light3'>•</Text>
        </Flex>
        <Flex column styles={flexStyles}>
          <Text size='14' color='primary.light3'>{t('reports.modal.tracking.profilesBlock.empty.titleParagraph1')}.</Text>
          <Text size='14' color='primary.light3'>{t('reports.modal.tracking.profilesBlock.empty.titleParagraph2')}</Text>
          <Text size='14' color='primary.light3'>{t('reports.modal.tracking.profilesBlock.empty.titleParagraph3')}</Text>
        </Flex>
      </Flex>
      <Spacing size='24' />
      <Flex center>
        <SelectButton
          showIcon={false}
          buttonText={t('commons.button.addProfiles')}
          options={addOptions}
          size='s'
          styles={selectButtonStyles}
          customWidthDropdown='190px'
          dropdownStyles={dropDownTopStyles}
        />
      </Flex>
      <Spacing size='16' />
    </>
  )
}
