import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { SnackbarContext } from 'influ-dms'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import { NotifyProfileDataProcessedContext } from '../../../../context/irm/profiles/NotifyProfileDataProcessed'
import { USE_ARRAY_ACTIONS, useTranslate } from '../../../../hooks'
import { updateProfileMutation } from '../../apollo'
import { captureException } from '@sentry/nextjs'

export const useRefreshUpdateMutation = ({ profileIds = [], onUpdateSuccess = () => {} }) => {
  const { t } = useTranslate()

  const { showSnackbar } = useContext(SnackbarContext)
  const { setProfilesLoading, setHasErrorOnRequest } = useContext(NotifyProfileDataProcessedContext)

  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const [updateProfile, { loading, error }] = useMutation(updateProfileMutation)

  const onErrorActions = () => {
    setHasErrorOnRequest(true)
    setProfilesLoading(profileIds, USE_ARRAY_ACTIONS.DELETE_FROM_ARRAY)
    showSnackbar(
      'error',
      t('commons.logIn.form.input.helperText.error.generalError'),
      true,
      undefined,
      5000
    )
  }

  const handleUpdateProfileMutation = async () => {
    setProfilesLoading(profileIds, USE_ARRAY_ACTIONS.NEST_NEW_ARRAY)
    try {
      const response = await updateProfile({
        variables: { brandId, profileIds, from: router.asPath.split('/')[3] }
      })
      if (response && response.data.updateProfile.success) {
        onUpdateSuccess()
      } else {
        onErrorActions()
      }
    } catch (error) {
      onErrorActions()
      showSnackbar(
        'error',
        t('commons.logIn.form.input.helperText.error.generalError'),
        true,
        undefined,
        5000
      )
      captureException({ error, profileIds })
    }
  }

  return {
    handleUpdateProfileMutation,
    loading,
    error
  }
}
