import styled, { css } from 'styled-components'
import { Button, Flex, SideBox, Spacing, Text } from 'influ-dms'
import { RouterHelper } from '../../../../../routing/routerHelper'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'

const sideBoxStyles = css`
  padding: 0;
  text-align: center
`
const Container = styled.div`
  padding: 32px 32px 32px 32px;
`
const PaddedContainer = styled.div`
  padding: 16px 16px 20px 16px;
`
const StyledButton = styled(Button)`
  margin-top: 14px;
`
const StyledFlex = styled(Flex)`
  margin-bottom: 32px;
`

export const AddTrackingLimitExceeded = ({ handleClose }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const { brandId } = router.query
  const handleClick = () => {
    handleClose()
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }
  return (
    <Container>
      <Text color='primary.dark' size='16' bold>{t('reports.report.modal.addPublication.tracking.titleHeader1')}</Text>
      <Spacing size='8' />
      <Text color='primary.light3'>{t('reports.report.modal.addPublication.tracking.titleParagraph1')}</Text>
      <Spacing size='12' />
      <SideBox styles={sideBoxStyles}>
        <PaddedContainer>
          <Spacing size='32' />
          <Text size='15' color='primary.dark' bold>{t('reports.report.modal.addPublication.tracking.limitReached.titleHeader1')}</Text>
          <Spacing size='4' />
          <Text size='14' color='primary.light3'>{t('reports.report.modal.addPublication.tracking.limitReached.titleParagraph1')}</Text>
          <Spacing size='16' />
          <img
            width='250px' height='250px'
            src='/static/img/tracking/trackingLimitExceeded.png'
            alt='Limit Exceeded'
          />
          <Spacing size='4' />
          <StyledFlex center>
            <StyledButton onClick={handleClick} size='l'>{t('commons.button.seePlansAndPrices')}</StyledButton>
          </StyledFlex>
          <Spacing size='4' />
        </PaddedContainer>
      </SideBox>
    </Container>
  )
}
