import { gql } from '@apollo/client'

export const campaignEstimates = gql`
 query campaignEstimatesQuery(
   $campaignId: String!,
 ) {
  campaignBeta(_id: $campaignId ){
    id: _id
    name
    currency{
      id: _id
      symbol
    }
    metrics{
      campaignDetails{
        influencersEstimated
        accounts
        accountsNotAnalyzed
        influencersWithTasks
        totalTasks
        totalReach
        averageInteractions
        forecastBudget
        availableBudget
        tasksByNetwork{
          instagram
          tiktok
          youtube
          twitter
          others
        }
        spentByNetwork{
          instagram
          tiktok
          youtube
          twitter
          others
        }
      }
      byNetworkMetrics{
        network
        networkKPIs{
          calculated
          KPIs{
            influencers
            totalTasks
            totalReach
            averageInteractions
            engagement
            earnedMedia
            accounts
            accountsNotAnalyzed
          }
        }
        taskSummary{
          _type
          spent
          label
          count
        }
        agesByGender{
          calculated
          agesByGenderGraph{
            range
            total
            men
            women
          }
        }
        avgInteractions{
          calculated
          avgInteractionsGraph{
            type
            value
          }
        }
        genderGraph{
          calculated
          dominantGender{
            gender
            percentage
          }
          genderGraph{
            gender
            percentage
          }
        }
        geo{
          countries{
            calculated
            topCountriesList{
              countryName
              countryCode
              count
              percentage
              realUsers
              geometry
            }
          }
          cities{
            calculated
            topCitiesList{
              cityName
              count
              percentage
              realUsers
            }
          }
        }

      }
    }
  }
 }
 `
