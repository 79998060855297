import styled, { css } from 'styled-components'
import { Flex } from 'influ-dms'

export const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const StyledContainer = styled(Flex)`
  padding: 0 24px;
  ${cellEllipsis};
`

export const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out; 
    text-decoration: underline;
  }
`

export const StyledFlex = styled(Flex)`
  width : 97%;
`

export const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

export const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

export const InvoicesTableContainerStyledComponent = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px 24px 4px 24px;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
`

export const hoverStyles = css`
  :hover {
    > div {
      background: ${({ theme }) => theme.colors.primary.light6} !important;
    }
  }
`

export const emptyStateStyles = css`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-top-right-radius: 8px;
`

export const subtitleStyles = css`
  margin-top: -20px;
`

export const loaderStyles = css`
  position: absolute;
  z-index: 10;
  backdrop-filter: blur(5px);
  display: flex;
  height: 80vh;
  width: 99vw;
  margin-top: -1px;
  align-items: center;
  justify-content: center;
`

export const inputSearchStyles = css`
  width: 240px;
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.primary.light3};
  color: ${({ theme }) => theme.colors.primary.dark};
  ${({ hideIcon }) => hideIcon ? 'padding-left: 8px;' : 'padding-left: 38px;'}
  font-size: 14px;
`

export const StyledFlex100Height = styled(Flex)`
  height: 100%;
`

export const StyledFlex100HeightContainer = styled(Flex)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  margin: 24px;
`

export const StyledFlex100HeightWidth = styled(Flex)`
  height: 100%;
  width: 100%;
`
