import { gql } from '@apollo/client'

export const getAudienceCodesByProgramId = gql`
  query getAudienceCodesByProgramId(
    $programId: String!
    $sortParams: CodesSortParamsType
    $page: Int
    $limit: Int
    $filters: [NewFilterParamsType]!
    $view: String!
  ) {
    getAudienceCodesByProgramId(
      programId: $programId
      sortParams: $sortParams
      page: $page
      limit: $limit
      filters: $filters
      view: $view
    ) {
      _id
      brandId
      programId
      benefit
      type
      code
      influencer {
        _id
        alias
        picture
        hasInfluencerGiftingRules
        hasAudienceBenefitRules
        rawSynchronized
        irmInfluencerId
        updatedAt
      }
      ecommerceCustomerId
      url
      status
      rawStatus
      totalCodesUsed
      totalSales
      createdAt
      updatedAt
      createdByUser {
        firstName
      }
      updatedByUser {
        firstName
      }
      addedByUser {
        firstName
      }
    }
  }
`