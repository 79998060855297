import { gql } from '@apollo/client'

export const addCustomDomain = gql`
    mutation addCustomDomain(
        $domainName: String!
        $usage: DomainUsageEnumType!
    ) {
        addCustomDomain(
            domainName: $domainName
            usage: $usage
        ){
            message
            data
            success
        }
    }
`
