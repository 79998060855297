import { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  Flex,
  HorizontalOverhang,
  TableV2,
  editableTableBodyStyles,
  editableTableHeaderStyles,
  editableTableTableStyles,
  TableEditableFiltersBar,
  EmptyState,
  Spacing,
  TableContext,
  TableEditableSelectedHeader,
  FiltersTableContext,
  PaginationV2,
  FiltersBar,
  Loader
} from 'influ-dms'
import { useApolloClient } from '@apollo/client'
import { get, isEmpty, debounce } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'
import { searchAutocomplete, locationAutocompleteByType } from '~/graphql/search'
import { useFilter } from '../../../hooks'

const StyledContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  flex-grow: 1;
  max-height: 480px;
  flex-direction: column;
`

const hoverStyles = css`
  :hover {
    > div {
      background: ${({ theme }) => theme.colors.primary.light6} !important;
    }
  }
`

const emptyStateStyles = css`
  height: '100%';
  overflow: auto;
`

const inputSearchStyles = css`
  width: 240px;
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.primary.light3};
  color: ${({ theme }) => theme.colors.primary.dark};
  ${({ hideIcon }) => (hideIcon ? 'padding-left: 8px;' : 'padding-left: 38px;')}
  font-size: 14px;
`

const PAGE_SIZE = 20

export const AssociateTable = (props) => {
  const [initialLoading, setInitialLoading] = useState(true)
  const {
    tableId,
    columns,
    initialSortBy,
    initialDefaultColumns,
    loading,
    searchFilterId,
    getEntity,
    rightComponent,
    tableName,
    tableView,
    renderEmptyState,
    newFiltersHidden = [],
    refetch = false
  } = props
  const { t } = useTranslate()
  const {
    dispatch,
    state: { count: tableCount }
  } = useContext(TableContext)
  const client = useApolloClient()

  const {
    filtersAdded,
    filteredData,
    searchFilter,
    setSearchFilter,
    setSearchQuery,
    setSortBy,
    sortBy,
    count,
    processingFilter,
    pageIndex,
    setPageIndex,
    favFilters
  } = useContext(FiltersTableContext)
  const { getFilters } = useFilter()

  const searchQuery = async ({ inputValue, autocompleteWith, autocompleteWithOption }) => {
    const queries = {
      searchAutocomplete,
      locationAutocompleteByType
    }
    if (queries[autocompleteWith]) {
      return client.query({
        query: queries[autocompleteWith],
        variables: {
          query: inputValue,
          type: autocompleteWithOption
        }
      })
    }
  }

  const getData = async () => {
    setInitialLoading(true)
    const promises = [
      isEmpty(favFilters) && getFilters(tableName, tableView),
      getEntity({ newSort: initialSortBy, newFiltersHidden }, tableView)
    ]
    await Promise.all(promises)
    setSortBy(initialSortBy)
    setSearchQuery(() => (variables) => searchQuery(variables))
    setInitialLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (refetch) {
      getData()
    }
  }, [refetch])

  useEffect(() => {
    if (count && tableCount !== count) {
      dispatch({ type: 'setCount', payload: count })
    }
  }, [count])

  const debouncedSearch = debounce((value, callback) => {
    callback(value)
  }, 1000)

  const handleSearchInputChange = async (value) => {
    const { value: prevValue } = searchFilter
    if (prevValue !== value && (value?.length >= 2 || !value)) {
      const newSearchFilter = { filterId: searchFilterId, value }
      setSearchFilter(newSearchFilter)
      getEntity({ newSearchFilter }, tableView)
    }
  }

  const handlePageChange = (page) => {
    setPageIndex(page)
    getEntity({ newPage: page }, tableView)
  }

  const onSortChange = async (sort) => {
    if (!isEmpty(sort)) {
      const newSortBy = get(sort, '[0].id', undefined)
      const isDesc = get(sort, '[0].desc', false)
      const newSortOrder = isDesc ? 'desc' : 'asc'
      const sortParams = { sortBy: newSortBy, sortOrder: newSortOrder }
      if (sortBy && sortBy.sortBy === newSortBy && sortBy.sortOrder === newSortOrder) return
      setSortBy(sortParams)
      getEntity({ newSort: sortParams }, tableView)
    }
  }
  const filtersBarComponent = (
    <FiltersBar
      filters={favFilters}
      getFilteredData={getEntity}
      view={tableView}
      showAllFiltersChip={false}
      showClear={false}
    />
  )

  if (initialLoading) {
    return (
      <Flex column center grow='1'>
        <Loader data-cy='loading-to-associated' />
      </Flex>
    )
  } else if (
    !loading &&
    !processingFilter &&
    isEmpty(filteredData) &&
    isEmpty(filtersAdded) &&
    searchFilter &&
    searchFilter.value === ''
  ) {
    return <StyledContainer>{renderEmptyState()}</StyledContainer>
  } else {
    return (
      <>
        <StyledContainer>
          <TableV2
            initialColumns={initialDefaultColumns}
            sortable
            manualSortBy
            onFetchData={onSortChange}
            columns={columns}
            data={loading ? [{}, {}, {}, {}, {}, {}] : filteredData}
            loading={loading || processingFilter}
            tableId={tableId}
            emptyStateComponent={
              <Flex column center grow='1' styles={emptyStateStyles}>
                <Spacing size='24' />
                <EmptyState
                  imageUrl='/static/img/irm/profiles/empty_filters_search.svg'
                  titleText={t('commons.table.search.empty.titleHeaderOne')}
                  subtitleText={t('commons.table.search.empty.titleHeaderTwo')}
                />
              </Flex>
            }
            selectedHeader={({ headerGroup, selectedFlatRows, styles }) => {
              return (
                <TableEditableSelectedHeader
                  tableId={tableId}
                  headerGroup={headerGroup}
                  selectedFlatRows={selectedFlatRows}
                  styles={styles}
                />
              )
            }}
            navBarFilter={({ tableId, columns }) => {
              return (
                <TableEditableFiltersBar
                  tableId={tableId}
                  setGlobalFilter={(text) => debouncedSearch(text, handleSearchInputChange)}
                  columns={columns}
                  externalFilters={filtersBarComponent}
                  searchStylesFromProps={inputSearchStyles}
                  searchPlaceholder={t('entity.profile.tool.search.input.placeholder')}
                  compactSearch={false}
                  searchIconPosition='left'
                  filterColumns={false}
                  rightComponent={rightComponent}
                  showEditColumns={false}
                />
              )
            }}
            horizontalOverhang={({ children }) => (
              <HorizontalOverhang>{(ref) => children({ ref })}</HorizontalOverhang>
            )}
            pagination={() => {
              return (
                <PaginationV2
                  onPageChange={(value) => handlePageChange(value - 1)}
                  pages={Math.ceil(count / PAGE_SIZE)}
                  page={pageIndex + 1}
                  hideGoto
                />
              )
            }}
            initialSortBy={[{ id: initialSortBy.sortBy, desc: initialSortBy.sortOrder === 'desc' }]}
            styles={editableTableTableStyles}
            headerStyles={editableTableHeaderStyles}
            bodyStyles={[editableTableBodyStyles, hoverStyles]}
            enabledEmptyState={!loading && !processingFilter}
            initialPageSize={PAGE_SIZE}
          />
        </StyledContainer>
      </>
    )
  }
}
