import Head from 'next/head'
import getConfig from 'next/config'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SnackbarProvider, Snackbar } from 'influ-dms'
import { IsValidDevice, BlockPlatform } from '../hoc'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../apollo/apollo-client'
import withPrint from '../hoc/withPrint'
import { FavSearchesProvider } from '~/context/search/FavSearches'
import hotjar from '../helpers/hotjar'
import { LimitsContextProvider } from '../context/LimitsContext'
import { TrackingComponent } from '../containers/tracking/Tracking'
import { GlobalSubscriptionsProvider } from '../context/GlobalSubscriptions'
import { LanguageProvider } from '../context/Language'
import { ChangePassword } from '../hoc/ChangePassword'
import { CampaignContextProvider } from '../containers/commons/context/CampaignContext'
import { ModalsContextProvider } from '../containers/commons/context'
import { Modals } from '../containers/commons'
import { ReportsContextProvider } from '../containers/reports/context/ReportsContext'
import { CurrencyContextProvider } from '../containers/commons/context/CurrencyContext'
import { GlobalStyle } from '../theme/globalStyles'
import { CommunicationContextProvider } from '../containers/commons/communication/CommunicationContext'
import { TemplateProvider } from '../containers/communication/templates/context/TemplateContext'
import { RouterHelper } from '../routing/routerHelper'
import { RESPONSIVE_ROUTES as responsiveRoutes } from '~/routing/routesConstants'
import userflow from 'userflow.js'
import { PATHS } from '../constants'
import './_app.css'
import { renderPortalElements } from '../helpers/firstLoad/portalElements'
import { ThemeProvider } from 'styled-components'
import advertiserTheme from '../theme/advertiserTheme'
import { renderBeamer, renderFirstPromoted, renderGA, renderHubspot, renderIframely } from '../helpers/firstLoad/thirdPartyScripts'
import { setAnalyticsId } from '../helpers'
import { SentryComponent } from '../containers/search/SentryComponent'

const { publicRuntimeConfig } = getConfig()
const { themeName, nodeEnv, userflowToken, favicon } = publicRuntimeConfig

const { injectMetaOnTrialPaths } = RouterHelper
const setTitle = (string = '') => string.charAt(0).toUpperCase() + string.slice(1)

function MyApp ({ Component, pageProps, theme }) {
  const [domLoaded, setDomLoaded] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (nodeEnv !== 'development') {
        hotjar()
        if (userflowToken) userflow.init(userflowToken)
      }
    }
  }, [])

  useEffect(() => {
    isResponsiveRoute() && nodeEnv !== 'production' && delete window.dataLayer
  }, [router.asPath])

  const injectAnalyticsId = () => {
    if (typeof window !== 'undefined') setAnalyticsId({})
  }

  const isResponsiveRoute = () => {
    const { asPath: pathname } = router
    return responsiveRoutes.some(path => pathname.includes(path)) || pathname === '/'
  }

  if (!domLoaded) {
    return (
      <div className='loadingStylesContainer'>
        <img src={PATHS.LOGO_SHORT} className='startLoadStyles' alt='Influencity' />
      </div>
    )
  } else {
    return <>
      <Head>
        {/** TODO Download ALL dependencies from own server **/}
        <link href='https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap' rel='stylesheet' />
        <link href='https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap' rel='stylesheet' />
        <link rel='icon' type='image/png' href={favicon} />
        <title>{setTitle(themeName)}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta name='google' content='notranslate' />
        {injectMetaOnTrialPaths(router.asPath)}
        {injectAnalyticsId()}
        {/* <!-- Third party imports --> */}
        {renderGA(isResponsiveRoute())}
        {renderFirstPromoted()}
        {renderBeamer()}
        {renderHubspot()}
        {renderIframely()}
        <style>
          {`
            .iframely-embed {
              margin: 0 auto;
            }
            .iframely-responsive {
              position: relative;
              top: 0;
              left: 0;
              width: 100%;
              height: 0;
              padding-bottom: 56.25%;
            }
            .iframely-responsive > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 0;
            }
          `}
        </style>
      </Head>
      {renderPortalElements()}
      <GlobalStyle/>
      <ApolloProvider client={apolloClient}>
        <TrackingComponent />
        <SentryComponent />
        <ThemeProvider theme={advertiserTheme}>
          <LanguageProvider>
            <IsValidDevice>
              <DndProvider backend={HTML5Backend}>
                <BlockPlatform>
                  <CurrencyContextProvider>
                    <ChangePassword>
                      <FavSearchesProvider>
                        <GlobalSubscriptionsProvider>
                          <LimitsContextProvider>
                            <ModalsContextProvider>
                              <CampaignContextProvider>
                                <ReportsContextProvider>
                                  <CommunicationContextProvider>
                                    <TemplateProvider>
                                      <SnackbarProvider >
                                        <Modals />
                                        <Snackbar />
                                        <Component
                                          {...pageProps}
                                          url={router}
                                          router={router}
                                          theme={theme}
                                        />
                                      </SnackbarProvider>
                                    </TemplateProvider>
                                  </CommunicationContextProvider>
                                </ReportsContextProvider>
                              </CampaignContextProvider>
                            </ModalsContextProvider>
                          </LimitsContextProvider>
                        </GlobalSubscriptionsProvider>
                      </FavSearchesProvider>
                    </ChangePassword>
                  </CurrencyContextProvider>
                </BlockPlatform>
              </DndProvider>
            </IsValidDevice>
          </LanguageProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  }
}

export default withPrint(MyApp)
