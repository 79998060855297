import { gql } from '@apollo/client'

const keyLabelValue = gql`
    fragment keyLabelValue on KeyLabelValueObjectType {
      key
      value
      label
    }
`

export { keyLabelValue }
