// Stripe dashboard customers form
// https://b.stripecdn.com/manage/assets/dashboard.customers.617464592f06bb22345f.min.js

export const dataCountriesVat = {
  at_vat: {
    country: 'AT',
    format: 'ATU[0-9]{8}',
    placeholder: 'ATU12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  be_vat: {
    country: 'BE',
    format: 'BE(0|1)[0-9]{9}',
    placeholder: 'BE0123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  bg_vat: {
    country: 'BG',
    format: 'BG[0-9]{9,10}',
    placeholder: 'BG0123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  // br_cnpj: {
  //   country: 'BR',
  //   format: '([0-9]{2})\\.?([0-9]{3})\\.?([0-9]{3})[\\/\\\\]?([0-9]{4})\\-?([0-9]{2})',
  //   placeholder: '01.234.456/5432-10',
  //   external_type: 'br_cnpj',
  //   description: 'Brazilian CNPJ number',
  //   verifiable: !1,
  //   replacement_string: '\\1.\\2.\\3/\\4-\\5'
  // },
  // br_cpf: {
  //   country: 'BR',
  //   format: '([0-9]{3})\\.?([0-9]{3})\\.?([0-9]{3})\\-?([0-9]{2})',
  //   placeholder: '123.456.789-87',
  //   external_type: 'br_cpf',
  //   description: 'Brazilian CPF number',
  //   verifiable: !1,
  //   replacement_string: '\\1.\\2.\\3-\\4'
  // },
  cy_vat: {
    country: 'CY',
    format: 'CY[0-9]{8}[A-Z]',
    placeholder: 'CY12345678Z',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  cz_vat: {
    country: 'CZ',
    format: 'CZ[0-9]{8,10}',
    placeholder: 'CZ1234567890',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  de_vat: {
    country: 'DE',
    format: 'DE[0-9]{9}',
    placeholder: 'DE123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  dk_vat: {
    country: 'DK',
    format: 'DK[0-9]{8}',
    placeholder: 'DK12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  ee_vat: {
    country: 'EE',
    format: 'EE[0-9]{9}',
    placeholder: 'EE123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  // es_vat: {
  //   country: 'ES',
  //   format: 'ES([A-Z][0-9]{8}|[0-9]{8}[A-Z]|[A-Z][0-9]{7}[A-Z])',
  //   placeholder: 'ESA1234567Z',
  //   external_type: 'eu_vat',
  //   category: 'eu_vat',
  //   verifiable: !0,
  //   description: 'European VAT number'
  // },
  fi_vat: {
    country: 'FI',
    format: 'FI[0-9]{8}',
    placeholder: 'FI12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  fr_vat: {
    country: 'FR',
    format: 'FR[A-Z0-9]{2}[0-9]{9}',
    placeholder: 'FRAB123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  gr_vat: {
    country: 'GR',
    format: 'EL[0-9]{9}',
    placeholder: 'EL123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  hr_vat: {
    country: 'HR',
    format: 'HR[0-9]{11}',
    placeholder: 'HR12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  hu_vat: {
    country: 'HU',
    format: '(HU)?[0-9]{8}((-)?[0-9]{1}(-)?[0-9]{2})?',
    placeholder: 'HU12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  ie_vat: {
    country: 'IE',
    format: 'IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])',
    placeholder: 'IE1234567AB',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  it_vat: {
    country: 'IT',
    format: 'IT[0-9]{11}',
    placeholder: 'IT12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  lt_vat: {
    country: 'LT',
    format: 'LT([0-9]{9}|[0-9]{12})',
    placeholder: 'LT123456789123',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  lu_vat: {
    country: 'LU',
    format: 'LU[0-9]{8}',
    placeholder: 'LU12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  lv_vat: {
    country: 'LV',
    format: 'LV[0-9]{11}',
    placeholder: 'LV12345678912',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  mt_vat: {
    country: 'MT',
    format: 'MT[0-9]{8}',
    placeholder: 'MT12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  nl_vat: {
    country: 'NL',
    format: 'NL[0-9]{9}B[0-9]{2}',
    placeholder: 'NL123456789B12',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  pl_vat: {
    country: 'PL',
    format: 'PL[0-9]{10}',
    placeholder: 'PL1234567890',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  pt_vat: {
    country: 'PT',
    format: 'PT[0-9]{9}',
    placeholder: 'PT123456789',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  ro_vat: {
    country: 'RO',
    format: 'RO[0-9]{2,10}',
    placeholder: 'RO1234567891',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  se_vat: {
    country: 'SE',
    format: 'SE[0-9]{12}',
    placeholder: 'SE123456789123',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  si_vat: {
    country: 'SI',
    format: 'SI[0-9]{8}',
    placeholder: 'SI12345678',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  sk_vat: {
    country: 'SK',
    format: 'SK[0-9]{10}',
    placeholder: 'SK1234567891',
    external_type: 'eu_vat',
    category: 'eu_vat',
    verifiable: !0,
    description: 'European VAT number'
  },
  // xi_vat: {
  //   country: 'GB',
  //   format: '(XI)([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})',
  //   placeholder: 'XI123456789',
  //   external_type: 'eu_vat',
  //   category: 'eu_vat',
  //   verifiable: !0,
  //   description: 'Northern Ireland VAT number'
  // },
  // gb_vat: {
  //   country: 'GB',
  //   format: 'GB([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})',
  //   placeholder: 'GB123456789',
  //   external_type: 'gb_vat',
  //   verifiable: !0,
  //   description: 'United Kingdom VAT number'
  // },
  // nz_gst: {
  //   country: 'NZ',
  //   format: '[0-9]{8,9}',
  //   placeholder: '123456789',
  //   external_type: 'nz_gst',
  //   description: 'New Zealand GST number',
  //   verifiable: !1
  // },
  // au_abn: {
  //   country: 'AU',
  //   format: '[0-9]{11}',
  //   placeholder: '12345678912',
  //   external_type: 'au_abn',
  //   verifiable: !0,
  //   description: 'Australian Business Number (AU ABN)'
  // },
  // in_gst: {
  //   country: 'IN',
  //   format: '[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]Z[0-9A-Z]',
  //   placeholder: '12ABCDE3456FGZH',
  //   external_type: 'in_gst',
  //   description: 'Indian GST number',
  //   verifiable: !1
  // },
  // no_vat: {
  //   country: 'NO',
  //   format: '[0-9]{9}MVA',
  //   placeholder: '123456789MVA',
  //   external_type: 'no_vat',
  //   description: 'Norwegian VAT number',
  //   verifiable: !1
  // },
  // za_vat: {
  //   country: 'ZA',
  //   format: '4[0-9]{9}',
  //   placeholder: '4123456789',
  //   external_type: 'za_vat',
  //   description: 'South African VAT number',
  //   verifiable: !1
  // },
  // ch_vat: {
  //   country: 'CH',
  //   placeholder: 'CHE-123.456.789 MWST',
  //   format: '([Cc][Hh][Ee])[\\. \\-]?(\\d{3})[\\. \\-]?(\\d{3})[\\. \\-]?(\\d{3}) ?([Mm][Ww][Ss][Tt]|[Tt][Vv][Aa]|[Ii][Vv][Aa])',
  //   external_type: 'ch_vat',
  //   description: 'Switzerland VAT number',
  //   verifiable: !1,
  //   replacement_string: '\\1-\\2.\\3.\\4 \\5'
  // },
  // mx_rfc: {
  //   country: 'MX',
  //   format: '([A-Za-zÑñ&]{3,4})-?([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-?([A-Za-z0-9])-?([A-Za-z0-9][0-9A])',
  //   placeholder: 'ABC010203AB9',
  //   external_type: 'mx_rfc',
  //   description: 'Mexican RFC number',
  //   verifiable: !1,
  //   replacement_string: '\\1\\2\\3\\4\\5\\6'
  // },
  // sg_uen: {
  //   country: 'SG',
  //   format: '([0-9]{8}[A-Z]|[0-9]{4}[0-9]{5}[A-Z]|F(000|   )[0-9]{5}[A-Z]|[ST][0-9]{2}[A-Z]{2}[0-9]{4}[A-Z])',
  //   placeholder: '123456789F',
  //   external_type: 'sg_uen',
  //   description: 'Singaporean UEN',
  //   verifiable: !1
  // },
  // ru_inn: {
  //   country: 'RU',
  //   format: '[0-9]{10}',
  //   placeholder: '1234567891',
  //   external_type: 'ru_inn',
  //   description: 'Russian INN',
  //   verifiable: !1
  // },
  // ru_kpp: {
  //   country: 'RU',
  //   format: '[0-9]{9}',
  //   placeholder: '123456789',
  //   external_type: 'ru_kpp',
  //   description: 'Russian KPP',
  //   verifiable: !1
  // },
  // ca_bn: {
  //   country: 'CA',
  //   format: '[0-9]{9}',
  //   placeholder: '123456789',
  //   external_type: 'ca_bn',
  //   description: 'Canadian BN',
  //   verifiable: !1
  // },
  // hk_br: {
  //   country: 'HK',
  //   format: '[0-9]{8}',
  //   placeholder: '12345678',
  //   external_type: 'hk_br',
  //   description: 'Hong Kong BR number',
  //   verifiable: !1
  // },
  es_cif: {
    country: 'ES',
    format: '[0-9A-Z][0-9]{7}[0-9A-Z]',
    placeholder: 'A12345678',
    external_type: 'es_cif',
    description: 'Spanish CIF number',
    verifiable: !1
  }
  // tw_vat: {
  //   country: 'TW',
  //   format: '[0-9]{8}',
  //   placeholder: '12345678',
  //   external_type: 'tw_vat',
  //   description: 'Taiwanese VAT',
  //   verifiable: !1
  // },
  // th_vat: {
  //   country: 'TH',
  //   format: '[0-9]{13}',
  //   placeholder: '1234567891234',
  //   external_type: 'th_vat',
  //   description: 'Thai VAT',
  //   verifiable: !1
  // },
  // jp_cn: {
  //   country: 'JP',
  //   format: '[0-9]{13}',
  //   placeholder: '1234567891234',
  //   external_type: 'jp_cn',
  //   description: 'Japanese Corporate Number (*Hōjin Bangō*)',
  //   verifiable: !1
  // },
  // jp_rn: {
  //   country: 'JP',
  //   format: '[0-9]{5}',
  //   placeholder: '12345',
  //   external_type: 'jp_rn',
  //   description: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
  //   verifiable: !1
  // },
  // li_uid: {
  //   country: 'LI',
  //   format: 'CHE[0-9]{9}',
  //   placeholder: 'CHE123456789',
  //   external_type: 'li_uid',
  //   description: 'Liechtensteinian UID number',
  //   verifiable: !1
  // },
  // my_itn: {
  //   country: 'MY',
  //   format: '([A-Z]{1,2}) ?([0-9]{10})',
  //   placeholder: 'C 1234567890',
  //   external_type: 'my_itn',
  //   description: 'Malaysian ITN',
  //   verifiable: !1,
  //   replacement_string: '\\1 \\2'
  // },
  // us_ein: {
  //   country: 'US',
  //   format: '([0-9]{2})-?([0-9]{7})',
  //   placeholder: '12-3456789',
  //   external_type: 'us_ein',
  //   description: 'United States EIN',
  //   verifiable: !1,
  //   replacement_string: '\\1-\\2'
  // },
  // kr_brn: {
  //   country: 'KR',
  //   format: '([0-9]{3})-?([0-9]{2})-?([0-9]{5})',
  //   placeholder: '123-45-67890',
  //   external_type: 'kr_brn',
  //   description: 'Korean BRN',
  //   verifiable: !1,
  //   replacement_string: '\\1-\\2-\\3'
  // },
  // ca_qst: {
  //   country: 'CA',
  //   format: '([0-9]{10}TQ[0-9]{4}|[0-9]{9}[A-Z]{2}[0-9]{4}|[0-9]{9,10}|NR[0-9]{8})',
  //   placeholder: '1234567890TQ1234',
  //   external_type: 'ca_qst',
  //   description: 'Canadian QST number',
  //   verifiable: !1
  // },
  // my_sst: {
  //   country: 'MY',
  //   format: '(([A-Z][0-9]{2})-?([0-9]{4})-?([0-9]{8})|[0-9]{8})',
  //   placeholder: 'A12-3456-78912345',
  //   external_type: 'my_sst',
  //   description: 'Malaysian SST number',
  //   verifiable: !1,
  //   replacement_string: '\\2-\\3-\\4',
  //   only_replace_if_group_captured: 2
  // },
  // sg_gst: {
  //   country: 'SG',
  //   format: '([0-9]{8}[A-Z]|[0-9]{4}[0-9]{5}[A-Z]|F(000|   )[0-9]{5}[A-Z]|[ST][0-9]{2}[A-Z]{2}[0-9]{4}[A-Z]|M[0-9A-Z][0-9]{7}[0-9A-Z]|F[0-9]{8}[A-Z])',
  //   placeholder: 'M12345678X',
  //   external_type: 'sg_gst',
  //   description: 'Singaporean GST',
  //   verifiable: !1
  // },
  // ae_trn: {
  //   country: 'AE',
  //   format: '[0-9]{15}',
  //   placeholder: '123456789012345',
  //   external_type: 'ae_trn',
  //   verifiable: !1,
  //   description: 'United Arab Emirates TRN'
  // },
  // cl_tin: {
  //   country: 'CL',
  //   format: '([0-9]{2})\\.?([0-9]{3})\\.?([0-9]{3})-?([K0-9])',
  //   placeholder: '12.345.678-K',
  //   external_type: 'cl_tin',
  //   description: 'Chilean TIN',
  //   verifiable: !1,
  //   replacement_string: '\\1.\\2.\\3-\\4'
  // },
  // sa_vat: {
  //   country: 'SA',
  //   format: '[0-9]{15}',
  //   placeholder: '123456789012345',
  //   external_type: 'sa_vat',
  //   description: 'Saudi Arabia VAT',
  //   verifiable: !1
  // },
  // id_npwp: {
  //   country: 'ID',
  //   format: '([0-9]{2})\\.?([0-9]{3})\\.?([0-9]{3})\\.?([0-9])-?([0-9]{3})\\.?([0-9]{3})',
  //   placeholder: '12.345.678.9-012.345',
  //   verifiable: !1,
  //   external_type: 'id_npwp',
  //   description: 'Indonesian NPWP number',
  //   replacement_string: '\\1.\\2.\\3.\\4-\\5.\\6'
  // },
  // my_frp: {
  //   country: 'MY',
  //   format: '[0-9]{8}',
  //   placeholder: '12345678',
  //   verifiable: !1,
  //   external_type: 'my_frp',
  //   description: 'Malaysian FRP number'
  // }
}
