import styled, { css } from 'styled-components'
import { Flex } from 'influ-dms'

const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 32px;
  height: 64px;
  ${({ theme }) => `background: linear-gradient(90deg, ${theme.colors.gradients[1][0]} 0%, ${theme.colors.gradients[1][1]} 100%)`};
  ${({ isWarningModal }) => isWarningModal && css`
    ${({ theme }) => `background: ${theme.colors.state.warningDefault}`};
  `}
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
`

export const BottomCenteredModalHeader = ({ isWarningModal, children }) => (
  <Header isWarningModal={isWarningModal}>
    {children}
  </Header>
)
