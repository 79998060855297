import { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InputCustom from './Input'
import { Button, Icon } from 'influ-dms'

class Form extends Component {
  constructor (props) {
    super(props)
    const initialFieldValues = this.props.formFields.map((field) => {
      const { error, ...rest } = field
      return rest
    })
    const initialValidations = this.props.formFields.map(field => ({ name: field.name, error: field.error }))
    this.state = { fields: initialFieldValues, errors: initialValidations }
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.formFields) !== JSON.stringify(this.props.formFields)) {
      if (prevProps.resetForm) {
        return this.setState({ fields: prevProps.formFields })
      }
      if (prevProps.remoteSubmit && typeof window !== 'undefined') {
        const form = document.getElementById(prevProps.id).elements
        const fields = [...form]

        fields.reduce((acc, next) => {
          if (next.name) acc[next.name] = next.value
          return acc
        }, {})
        // document.querySelector(`#${this.props.buttonName}`).click();
        // this.props.onSubmit(formValues, prevProps.remoteSubmit);
      }

      if (prevProps.newValues && !this.props.newValues) {
        const newxFields = prevProps.formFields.map((field) => {
          const oldField = this.props.formFields.find(elem => elem.name === field.name)
          const oldStateField = this.state.fields.find(elem => elem.name === field.name)
          if (!oldField) { return field }
          if (!field.value && oldStateField.value) {
            return oldStateField
          }
          if (JSON.stringify(field) !== JSON.stringify(oldField)) {
            return field
          }
          return oldField
        })
        this.setState({ fields: newxFields })
      }

      const newErrors = prevProps.formFields.map((field) => {
        const oldField = this.props.formFields.find(elem => elem.name === field.name)
        if (!oldField || field.error !== oldField.error) {
          return { name: field.name, error: field.error }
        }
        return { name: oldField.name, error: oldField.error }
      })
      this.setState({ errors: newErrors })
      return null
    }
  }

  onSubmit (event) {
    event.preventDefault()
    const formElements = [...event.target.elements]
    const formValues = formElements.reduce((acc, next) => {
      if (next.name) acc[next.name] = next.value
      return acc
    }, {})
    this.props.onSubmit(formValues)
  }

  onChange (event) {
    return this.props.onChange && this.props.onChange(event.target)
  }

  handleChange (event) {
    const { value, checked, name, type } = event.target
    const targetIndex = this.state.fields.findIndex(field => field.name === name)
    let newField
    if (type === 'checkbox') {
      newField = { ...this.state.fields[targetIndex], checked }
    } else {
      newField = { ...this.state.fields[targetIndex], value }
    }
    if (event.target.required && event.target.value) {
      event.target.className = ''
    }
    this.setState({
      fields: [
        ...this.state.fields.slice(0, targetIndex),
        newField,
        ...this.state.fields.slice(targetIndex + 1)
      ]
    })
  }

  renderButtonLabel () {
    if (this.props.loading) {
      return this.props.loadingLabel || (
        <div className='loading-form-container'>
          <span>Loading</span>
          <Icon name='loading' />
        </div>)
    }
    return this.props.buttonLabel || 'Submit'
  }

  render () {
    return (
      <form
        className={this.props.className}
        onSubmit={(event => this.onSubmit(event))}
        onChange={(event => this.onChange(event))}
        method='post'
        id={this.props.id && this.props.id}
      >
        {
          this.state.fields.map((targetField, index) => {
            return (
              <InputCustom
                ref={`input-${index}`}
                className={`input-${index}`}
                label={targetField.label}
                description={targetField.description}
                type={targetField.type}
                required={targetField.required}
                key={targetField.name}
                name={targetField.name}
                value={targetField.value || ''}
                checked={targetField.type === 'checkbox' && targetField.value}
                onChange={event => this.handleChange(event)}
                placeholder={targetField.placeholder}
                onBlur={event => targetField.onBlur && targetField.onBlur(event)}
                inline={targetField.inline}
                error={this.state.errors[index].error}
                maxLength={targetField.maxLength}
              >
                {targetField.selfDelete && <Icon name='delete' onclick={targetField.selfDelete} data-cy='Form-DeleteIcon' />}
              </InputCustom>
            )
          })
        }
        {this.props.children}
        <span className='errors'>
          {this.props.errors ? this.props.errors : ' '}
        </span>
        <Button
          primary
          className={this.props.buttonHide && 'hiddenButton'}
          type='submit'
          id={this.props.buttonName}
          data-cy={'Form-' + this.props.buttonName}
        >{this.renderButtonLabel()}
        </Button>
      </form>
    )
  }
}

const FormStyled = styled(Form)`
  &{
    font-family: 'Rubik', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #270038BA;
    .hiddenButton {
      height: 0px;
      overflow: hidden;
      visibility: hidden;
      padding: 0px;
    }
    .divider-line{
      border-top: 1px solid rgba(34,36,38,.15);
      width: 100%;
      margin:10px 0;
    }
    .loading-form-container{
      display: flex;
      align-items: center;
      svg{
        margin-left: 8px;
      }
    }
    .errors{
      display:${props => props.errors ? 'inline-block' : 'none'} ;
      height: 32px;
      color: red;
    }
  }
 `

Form.propTypes = {
  children: PropTypes.node,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string,
  buttonName: PropTypes.string,
  buttonHide: PropTypes.bool,
  loadingLabel: PropTypes.node,
  id: PropTypes.string,
  remoteSubmit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  formFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    description: PropTypes.string,
    component: PropTypes.node,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ])
  }))
}

export default FormStyled
