import { Form, FormInputWithValidations, Spacing, Text } from 'influ-dms'
import styled from 'styled-components'
import { Footer } from './Footer'
import { useTranslate } from '~/hooks/useTranslate'
import { bindLinksMap } from '~/hooks/useLink'
import { useEffect, useState } from 'react'
import { useLanguageContext } from '../../../../../context/Language'

const checkValidation = {
  checkFunction: val => !val
}

const StyledLink = styled.a`
  text-decoration: underline;
`

export const ImapWarning = ({ handleAccept, handleCancel }) => {
  const { t } = useTranslate()
  const [imapLink, setImapLink] = useState('')
  const { locale } = useLanguageContext()
  const linksMap = new Map([
    ['imap_link', setImapLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])

  return (
    <Form>
      <Text size='14' bold color='primary.dark'>{t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.confirmation.paragraph1')}</Text>
      <Spacing size='8' />
      <Text>{t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.confirmation.paragraph2', { articleLink: <StyledLink href={imapLink} target='_blank'>{t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.confirmation.articleLink')}</StyledLink> })}</Text>
      <Spacing size='30' />
      <FormInputWithValidations name='check1' validations={[checkValidation]}>
        {({ name, onChange }) => (
          <Form.SimpleCheckbox name={name} onChange={onChange}>
            <Spacing vertical size='6' />
            <Text color='primary.light2' size='14'>{t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.confirmation.check1')}</Text>
          </Form.SimpleCheckbox>
        )}
      </FormInputWithValidations>
      <FormInputWithValidations name='check2' validations={[checkValidation]}>
        {({ name, onChange }) => (
          <Form.SimpleCheckbox name={name} onChange={onChange}>
            <Spacing vertical size='6' />
            <Text color='primary.light2' size='14'>{t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.confirmation.check2')}</Text>
          </Form.SimpleCheckbox>
        )}
      </FormInputWithValidations>
      <Footer acceptText={t('settings.yourPreferences.communication.email.modal.connect.setupOption.imap.confirmation.button')} handleAccept={handleAccept} handleCancel={handleCancel} />
    </Form>
  )
}
