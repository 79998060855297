import { ProviderReducer } from '~/containers/commons/ProviderReducer'
import { NotifyProfileDataProcessedProvider } from './irm/profiles/NotifyProfileDataProcessed'
import { RefreshFeatureFlagsProvider } from './RefreshFeatureFlags'
import { RefreshRemainingFeatureFlagsProvider } from './RefreshRemainingFeatureFlags'
import { NotifyIrmInfluencerDataProcessedProvider } from './irm/influencers/NotifyIrmInfluencerDataProcessed'
import { NotifyListDataProcessedProvider } from './irm/lists/NotifyListDataProcessed'
import { NotifyAlertDataProcessedProvider } from '../containers/socialListening/context/NotifyAlertDataProcessed'

const providers = [
  RefreshFeatureFlagsProvider,
  RefreshRemainingFeatureFlagsProvider,
  NotifyListDataProcessedProvider,
  NotifyIrmInfluencerDataProcessedProvider,
  NotifyProfileDataProcessedProvider,
  NotifyAlertDataProcessedProvider
]

const GlobalSubscriptionsProvider = ({ children }) => (
  <ProviderReducer providers={providers}>
    {children}
  </ProviderReducer>
)

export { GlobalSubscriptionsProvider }
