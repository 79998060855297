export const TRIAL_ROUTES = [
  {
    locale: 'en',
    name: 'getStarted',
    path: '/get-started',
    thankYouName: 'thankYou',
    thankYouPath: '/get-started/thank-you'
  },
  {
    locale: 'es',
    name: 'empiezaAhora',
    path: '/es/empieza-ahora',
    thankYouName: 'gracias',
    thankYouPath: '/es/empieza-ahora/gracias'
  },
  {
    locale: 'it',
    name: 'iniziaOra',
    path: '/it/inizia-ora',
    thankYouName: 'grazie',
    thankYouPath: '/it/inizia-ora/grazie'
  },
  {
    locale: 'de',
    name: 'jetztBeginnen',
    path: '/de/jetzt-beginnen',
    thankYouName: 'danke',
    thankYouPath: '/de/jetzt-beginnen/danke'
  },
  {
    locale: 'fr',
    name: 'premiersPas',
    path: '/fr/premiers-pas',
    thankYouName: 'merci',
    thankYouPath: '/fr/premiers-pas/merci'
  },
  {
    locale: 'pt',
    name: 'iniciar',
    path: '/pt/iniciar',
    thankYouName: 'obrigado',
    thankYouPath: '/pt/iniciar/obrigado'
  }
]

export const RESPONSIVE_ROUTES = [
  '/landing',
  '/pricesAndPlans/public',
  '/influencer-email-subscription/subscription',
  '/reset-password',
  '/login',
  '/logout',
  '/new-organization',
  '/get-started',
  '/es/empieza-ahora',
  '/it/inizia-ora',
  '/de/jetzt-beginnen',
  '/fr/premiers-pas',
  '/pt/iniciar'
]
