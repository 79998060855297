import { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FormContext, InputTag, Text, useArray, Icon, Flex } from 'influ-dms'
import { isEmpty, isEqual } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'

const containerStyles = css`
  height: fit-content;
  overflow: hidden;
  padding: 0;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;
  flex-shrink: 0;
  border: 0px;
`

const textareaStyles = css`
  height: 100%;
  min-height: 12px;
  margin: 0 4px;
  padding: 8px;
  flex: 1;
  color:${({ theme }) => theme.colors.primary.dark}; 
`

const tagStyles = css`
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  margin-left: 4px;
  color:${({ theme }) => theme.colors.primary.light5}; 
  p {
    margin-right: 6px;
  }
  border-radius: 4px;
`

const StyledFlex = styled(Flex)`
border-radius: 4px;
border: 1px solid ${({ theme, warning }) => warning ? theme.colors.state.warningDefault : theme.colors.state.default};
`

export const InputTagTracking = ({ separator, separatorName, onBlur, defaultTexts = [] }) => {
  const { t } = useTranslate()
  const [decoratedTexts, setDecoratedTexts] = useArray(defaultTexts)
  const { values, setValue } = useContext(FormContext)
  useEffect(() => {
    if (isEmpty(values[separatorName])) {
      setDecoratedTexts([])
    }
  }, [values])

  useEffect(() => {
    setValue('InputText', separatorName, decoratedTexts)
  }, [decoratedTexts.length])

  useEffect(() => {
    if (!isEmpty(defaultTexts) && !isEqual(defaultTexts, decoratedTexts)) {
      setDecoratedTexts(defaultTexts.map(tag => { return { value: tag, isValid: true } }))
    }
  }, [defaultTexts])

  const handleUrl = val => {
    if (decoratedTexts.length < 5) {
      setDecoratedTexts({ value: val.startsWith(separator) ? val : separator + val, isValid: true }, 'edit', 'value')
    }
  }

  const translateSeparatorName = (separatorName) => {
    return separatorName === 'mentions' ? t('widgets.influencers.listStats.mentions.pagination.totalMentions') : separatorName
  }

  return (
    <>
      <StyledFlex warning={decoratedTexts && decoratedTexts.length > 4}>
        <InputTag
          values={decoratedTexts}
          setValues={setDecoratedTexts}
          handleValue={handleUrl}
          containerStyles={containerStyles}
          textareaStyles={textareaStyles}
          tagStyles={tagStyles}
          rows={1}
          placeholder={isEmpty(decoratedTexts) ? t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.placeholder', { separatorName: translateSeparatorName(separatorName) }) : ''}
          name={separatorName}
          onBlurProp={onBlur}
        />
      </StyledFlex>
      {decoratedTexts && decoratedTexts.length > 4 && (
        <Flex align='center'>
          <Icon name='danger' size='12' color='state.warningDefault' />
          <Text color='state.warningDefault' styles={{ margin: '4px 4px' }}>
            {t('reports.modal.tracking.hashtagsMentionAndKeywordBlock.input.helpertext.warning', { separatorName: translateSeparatorName(separatorName) })}
          </Text>
        </Flex>)}
    </>
  )
}
