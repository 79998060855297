import { gql } from "@apollo/client";

export const getEntityFieldsByBrand = gql`
  query getEntityFieldsByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: EntityFieldGroupSortParamsType,
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getEntityFieldsByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      id: _id
      brandId
      fieldName
      entityName
      groupName
      type
      autoComplete
      origin
      irmInfluencers
      translations {
        es {
          entityName
          fieldName
          type
          groupName
        }
        en {
          entityName
          fieldName
          type
          groupName
        }
        fr {
          entityName
          fieldName
          type
          groupName
        }
        de {
          entityName
          fieldName
          type
          groupName
        }
        it {
          entityName
          fieldName
          type
          groupName
        }
        pt {
          entityName
          fieldName
          type
          groupName
        }
      }
      createdByUser {
        firstName
      }
      createdAt
    }
  }
`
