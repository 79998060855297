import { createContext, useReducer } from 'react'

const initialValues = {
  view: undefined,
  isAddViewModalOpen: false,
  isEditViewModalOpen: false,
  isDeleteViewModalOpen: false
}

export const ViewContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setView':
      return { ...state, view: action.payload }
    case 'setAddViewModalOpen': {
      return { ...state, isAddViewModalOpen: action.value }
    }
    case 'setEditViewModalOpen': {
      return { ...state, isEditViewModalOpen: action.value }
    }
    case 'setDeleteViewModalOpen': {
      return { ...state, isDeleteViewModalOpen: action.value }
    }
  }
}

export const ViewProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setView = (list) => {
    dispatch({ type: 'setView', payload: list })
  }

  const openAddViewModal = () => {
    dispatch({ type: 'setAddViewModalOpen', value: true })
  }

  const closeAddViewModal = () => {
    dispatch({ type: 'setAddViewModalOpen', value: false })
  }

  const openDeleteViewModal = () => {
    dispatch({ type: 'setDeleteViewModalOpen', value: true })
  }

  const closeDeleteViewModal = () => {
    dispatch({ type: 'setDeleteViewModalOpen', value: false })
  }

  const openEditViewModal = () => {
    dispatch({ type: 'setEditViewModalOpen', value: true })
  }

  const closeEditViewModal = () => {
    dispatch({ type: 'setEditViewModalOpen', value: false })
  }

  return (
    <ViewContext.Provider value={{
      ...state,
      setView,
      openAddViewModal,
      closeAddViewModal,
      openDeleteViewModal,
      closeDeleteViewModal,
      openEditViewModal,
      closeEditViewModal,
    }}>
      {children}
    </ViewContext.Provider>
  )
}
