import { gql } from '@apollo/client'

export const createCommunicationAccountMutation = gql`
  mutation createCommunicationAccount(
    $type: String!
    $email: String!
    $password: String!
    $imap: AccountEmailProtocolInputType
    $smtp: AccountEmailProtocolInputType
  ) {
    createCommunicationAccount(
      type: $type
      email: $email
      password: $password
      imap: $imap
      smtp: $smtp
    ) {
      success
      code
    }
  }
`

export const setCommunicationAccountSettingsMutation = gql`
 mutation setCommunicationAccountSettings($name: String!, $signature: String, $attachments: [EmailAttachmentInputType]) {
  setCommunicationAccountSettings(name: $name, signature: $signature, attachments: $attachments) {
      name
      signature
    }
 }
`

export const updateCommunicationPasswordMutation = gql`
 mutation updateCommunicationPassword($password: String!) {
  updateCommunicationPassword(password: $password) {
      code
      success
    }
 }
`

export const disableCommunicationAccountMutation = gql`
 mutation disableCommunicationAccount {
  disableCommunicationAccount {
      code
      success
    }
 }
`

export const enableCommunicationAccountMutation = gql`
 mutation enableCommunicationAccount($password: String!) {
  enableCommunicationAccount(password: $password) {
      code
      success
    }
 }
`

export const deleteCommunicationAccountMutation = gql`
 mutation deleteCommunicationAccount {
  deleteCommunicationAccount {
      code
      success
    }
 }
`
