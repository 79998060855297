import { intComma } from 'humanize-plus'
import { Button } from 'influ-dms'
import { buttonCustomStyles } from './styles'

export const Pages = ({ visiblePages, activePage, handlePageClick }) => {
  return visiblePages.map((page, index, array) => {
    const onClick = () => handlePageClick(page - 1)
    const isActive = activePage === page
    return (
      <Button
        name='page'
        styles={buttonCustomStyles}
        key={page}
        color={isActive ? 'primary' : '#EDEDF4'}
        onClick={onClick}
        isActive={isActive}
      >
        {array[index - 1] + 1 < page ? `...${intComma(page)}` : page}
      </Button>
    )
  })
}
