import { useContext, useEffect } from 'react'
import { get } from 'lodash'
import { Form } from 'influ-dms'
import { EditInfluencerModal } from './EditInfluencerModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ReportsContext } from '../../context/ReportsContext'
import { influencerQuery } from '../../apollo/influencerQuery'
import { updateInfluencerCostsMutation } from '../../apollo/editInfluencerCostsQuery'

export const EditReportsInfluencerCost = ({ isOpen, onClose, profileId }) => {
  const { setReportsAction } = useContext(ReportsContext)
  const [fetchInfluencer, { data, loading }] = useLazyQuery(influencerQuery, { fetchPolicy: 'network-only' })
  const influencer = get(data, 'influencerInReportsV2', {})
  const [updateInfluencerCosts, { loading: updateInfluencerCostsLoading }] = useMutation(updateInfluencerCostsMutation)

  useEffect(() => {
    if (isOpen && profileId) {
      fetchInfluencer({ variables: { profileId } })
    }
  }, [isOpen, profileId])

  const handleEditPost = values => {
    const publicationCosts = Object.entries(values.publicationCosts).map(([key, value]) => ({ _id: key, cost: value }))
    updateInfluencerCosts({ variables: { ...values, influencerId: profileId, publicationCosts } })
      .then(({ data: { editedInfluencer } }) => {
        setReportsAction({ action: 'editInfluencer', data: editedInfluencer })
        onClose()
      })
      .catch((err) => { console.log({ createError: err }) })
  }

  return (
    <Form>
      <EditInfluencerModal
        profileId={profileId}
        isOpen={isOpen}
        influencer={influencer}
        handleCreate={handleEditPost}
        handleClose={onClose}
        loading={loading}
        editLoading={updateInfluencerCostsLoading}
      />
    </Form>
  )
}
