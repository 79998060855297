import { gql } from '@apollo/client'

export const getTableInfluencersInListByBrand = gql`
  query getTableInfluencersInListByBrand (
    $listId: String,
    $sharedId: String,
    $sortParams: IrmInfluencerInListSortOptions, 
    $page: Int, 
    $limit: Int,
    $view: String!,
    $filters: [NewFilterParamsType]
  ) {
    getTableInfluencersInListByBrand(listId: $listId, sharedId: $sharedId, sortParams: $sortParams, page: $page, limit: $limit, view: $view, filters: $filters) {
      _id
      alias
      firstName
      lastName
      picture
      emails{
        value
        primary
        deleted
      }
      phoneNumbers{
        value
        primary
        deleted
      }
      socialNetworks
      profiles {
        _id
      }
      createdAt
      updatedAt
      createdByUser {
        firstName
      }
      originalSource
      irmLists {
        _id
        addedByUser {
          firstName
        }
        addedAt
      }
      customProperties
    }
  }
`
