import { gql } from '@apollo/client'

export const createTemplate = gql`
  mutation createTemplate(
    $brandId: String!
    $name: String!
    $subject: String!
    $html: String!
    $attachments: [EmailTemplateAttachmentInputType]
  ) {
    createTemplate(
      brandId: $brandId
      name: $name
      subject: $subject
      html: $html
      attachments: $attachments
    ){
      message
      success
    }
  }
`
