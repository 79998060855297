import { gql } from '@apollo/client'

export const getAudienceCodesColumns = gql`
  query getAudienceCodesColumns {
    getAudienceCodesColumns {
      label
      fieldName
      columnType
      type
    }
  }
`