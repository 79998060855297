import { gql } from '@apollo/client'

export const getProfileSharedLink = gql`
  mutation getProfileSharedLink($profileId: String!) {
    getProfileSharedLink(profileId: $profileId) {
      type,
      refId,
      sharedId
    }
 }
`