import { css } from 'styled-components'
import { Button, Flex, Icon, Text } from 'influ-dms'

export const SendMassiveEmailModalHeader = ({ onClose, title }) => {
  return (
    <>
      <Text size='20' variant='h2' color='neutral.white' bold>{title}</Text>
      <Flex>
        <Button
          styles={css`padding: 0;`}
          withIcon
          variant='flat'
          onClick={onClose}
        >
          <Icon
            size={32}
            color='white'
            name='close'
          />
        </Button>
      </Flex>
    </>
  )
}
