import { useContext } from 'react'
import { Tooltip, TableEditableHeaderAction, TableContext } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { scrapPublicationsBulkMutation } from '../../../apollo/refreshQueries'
import { ReportsContext } from '../../../context/ReportsContext'
import { useTranslate } from '~/hooks/useTranslate'
import { captureException } from '@sentry/nextjs'

export const ReportsTableHeaderRefresh = ({ ids }) => {
  const { t } = useTranslate()
  const { setReportsAction } = useContext(ReportsContext)
  const { dispatch } = useContext(TableContext)
  const [scrapPublicationsBulk] = useMutation(scrapPublicationsBulkMutation)

  const handleRefresh = () => {
    dispatch({ type: 'resetSelectedRows' })
    scrapPublicationsBulk({ variables: { ids } })
      .then(() => {
        setReportsAction({ action: 'refreshPublications' })
      }).catch((err) => {
        captureException({ err, ids })
      }).finally(() => {
        setReportsAction()
      })
  }
  return (
    <Tooltip text={t('commons.table.headerTable.button.updatePublications.tooltip')} type='dark' color='neutral.white'>
      <TableEditableHeaderAction
        iconName='refresh'
        text={t('commons.table.headerTable.button.updatePublications')}
        onClick={handleRefresh}
      />
    </Tooltip>

  )
}
