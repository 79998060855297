import { AddPostsManualInstagramPostAdditionalMetrics } from '../../../../addPosts/manual/form/subForms/AddPostsManualInstagramPostAdditionalMetrics'
import { AddPostsManualInstagramLikesDisabled } from '../../../../addPosts/manual/form/subForms/AddPostsManualInstagramLikesDisabled'
import { AddPostsManualPostCost } from '../../../../addPosts/manual/form/subForms/AddPostsManualPostCost'

export const AddPostsManualInstagramPhoto = ({ tooltipText, isTracking, likeAndViewCountDisabled }) => (
  <>
    {likeAndViewCountDisabled && <AddPostsManualInstagramLikesDisabled />}
    <AddPostsManualInstagramPostAdditionalMetrics tooltipText={tooltipText} isTracking={isTracking} />
    <AddPostsManualPostCost />
  </>
)
