import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { getIRMList } from '../../lists/commons/apollo'

export const useQueryList = ({ variables = {}, skip, fetchPolicy = 'cache-first' }) => {
  const query = getIRMList
  const { data, loading, error, refetch } = useQuery(query, { variables, fetchPolicy, skip, })
  const resultData = get(data, 'getIRMList')

  return { data: resultData, loading, error, refetch }
}
