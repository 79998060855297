import { useContext } from 'react'
import { SnackbarContext } from 'influ-dms'
import { useTranslate } from '~/hooks'
import { useProfiles } from '.'
import { captureException } from '@sentry/nextjs'

export const useRemoveAndDeleteActions = ({
  profileId,
  influencerId = '',
  successTextOnRemove = 'commons.snackbar.success.removeProfiles',
  successTextOnDelete = 'commons.snackbar.success.removeAndDeleteProfile',
  onUnlinkCompleted = () => {},
  onDeleteCompleted = () => {},
  setUnlinkModalOpen = () => {},
  setDeleteModalOpen = () => {}
}) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const { t } = useTranslate()

  const showErrorSnackbar = () => {
    showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
  }

  const {
    deleteMassiveProfilesOptions: {
      action: deleteProfile,
      loading: deleteLoading,
    },
    removeProfileOptions: {
      action: unlinkProfile,
      loading: unlinkLoading,
    }
  } = useProfiles()

  const handleConfirmUnlink = async () => {
    try {
      const success = await unlinkProfile(profileId, influencerId)
      onUnlinkCompleted()
      setUnlinkModalOpen(false)
      showSnackbar('success', t(successTextOnRemove))
      return success
    } catch (error) {
      showErrorSnackbar()
      captureException('Error unlinking profile', error)
    }
  }

  const handleConfirmDelete = async () => {
    try {
      const success = await deleteProfile([profileId])
      onDeleteCompleted()
      setDeleteModalOpen(false)
      showSnackbar('success', t(successTextOnDelete))
      return success
    } catch (error) {
      showErrorSnackbar()
      captureException('Error unlinking profile', error)
    }
  }

  return {
    handleConfirmUnlink,
    handleConfirmDelete,
    deleteLoading,
    unlinkLoading
  }
}
