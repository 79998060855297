import { useContext, useEffect, useState } from 'react'
import { Button, FormContext, Spacing, StateButton } from 'influ-dms'
import { useMutation } from '@apollo/client'
import { get, isEmpty } from 'lodash'
import { css } from 'styled-components'
import { SettingsCardFooter } from '../../../../../settings/commons'
import { setCommunicationAccountSettingsMutation } from '../../../apollo/communicationMutations'
import { CommunicationContext } from '../../../CommunicationContext'
import { COMMUNICATION_ACTIONS, MESSAGE_LIMITS } from '../../../CommunicationConstants'
import { _replaceInlineContentIds, removeSpecialChars, emailSizeExceeded10Mb, attachmentCountExceeded } from '../../../helpers'
import { useTranslate } from '~/hooks/useTranslate'

const footerStyles = css`
  padding: 0;
  border: none;
`

export const Footer = ({ accountSettings, handleSuccess, handleClose, isClosable, setLoading }) => {
  const { t } = useTranslate()
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [generalError, setGeneralError] = useState()
  const { setCommunicationAction } = useContext(CommunicationContext)
  const { values, setValue, setValues } = useContext(FormContext)
  const [setCommunicationAccountSettings, { loading }] = useMutation(setCommunicationAccountSettingsMutation)
  const isFormDirty = values.formDirty
  const isFormInvalid = Object.values(values.errors || {}).some(val => val)
  const isFormDisabled = isClosable ? isFormInvalid : isFormInvalid || !isFormDirty
  const isCancelDisabled = isClosable ? loading : !isFormDirty || loading

  const buttonText = loading ? t('commons.button.updating') : t('commons.button.save')

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  const handleSave = () => {
    setSaveSuccess(false)
    setGeneralError(undefined)
    setValue('InputText', 'formDirty', false)

    const variables = {
      ...values,
      attachments: []
    }
    const haveSignature = !isEmpty(values.signature)

    const inlineAttachments = get(values, 'inlineAttachments') || []
    for (const inlineAttachment of inlineAttachments) {
      // new images
      const contentId = removeSpecialChars(inlineAttachment.contentId)
      const src = `cid:${contentId}`
      if (haveSignature) {
        variables.signature = variables.signature.replace(inlineAttachment.preview, src)
        if (variables.signature.indexOf(src) > -1) {
          variables.attachments.push({ file: inlineAttachment, inline: true, contentId: inlineAttachment.contentId })
        }
      }
    }
    // old images
    const oldAttachments = get(accountSettings, 'signatureAttachments')
    const { html, attachments } = _replaceInlineContentIds(variables.signature, oldAttachments)
    variables.attachments = variables.attachments.concat(attachments)
    if (haveSignature && html) variables.signature = html

    if (emailSizeExceeded10Mb(variables.signature, variables.attachments)) {
      setGeneralError(t('commons.communication.form.footer.errorLimit1'))
      return
    }
    if (attachmentCountExceeded(variables.attachments)) {
      setGeneralError(t('commons.communication.form.footer.errorLimit2', { limit: MESSAGE_LIMITS.attachmentCount }))
      return
    }

    setCommunicationAccountSettings({ variables })
      .then(response => {
        const account = get(response, 'data.setCommunicationAccountSettings')
        if (account) {
          setSaveSuccess(true)
          setCommunicationAction({ type: COMMUNICATION_ACTIONS.accountUpdated, data: { account } })
        } else {
          setGeneralError(t('commons.errorMessage.somethingHappened'))
        }
        if (handleSuccess) handleSuccess()
      })
      .catch((err) => {
        setGeneralError(t('commons.errorMessage.somethingHappened'))
        console.error(err)
      })
  }

  const handleCancel = () => {
    // refresh quill
    setValues({ ...accountSettings, showSignature: !!accountSettings.signature })
    if (isClosable) {
      handleClose()
    }
  }

  useEffect(() => {
    if (get(accountSettings, 'name')) {
      const values = { ...accountSettings, showSignature: !!accountSettings.signature }
      setValues(values)
    }
  }, [accountSettings])

  return (
    <SettingsCardFooter saved={saveSuccess} error={generalError} setError={setGeneralError} styles={footerStyles}>
      <StateButton
        size='s'
        loading={loading}
        disabled={isFormDisabled}
        loadingIconColor='white'
        onClick={handleSave}
      >{buttonText}
      </StateButton>
      <Spacing vertical size='8' />
      <Button
        disabled={isCancelDisabled}
        size='s'
        color='secondary'
        variant='flat'
        onClick={handleCancel}
      >{t('commons.button.cancel')}
      </Button>
    </SettingsCardFooter>
  )
}
