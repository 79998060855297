import { gql } from '@apollo/client'

export const updateTask = gql`
 mutation updateTask(
  $taskId: String!
  $price: Float
  $status: String
  $date: String
  $_type: String
 ) {
  updateTask(
    _id: $taskId
    price: $price
    date: $date
    _type: $_type
    status: $status
   ){
      id: _id
      brandId
      campaignId 
      influencerInCampaignId
      price
      date
      _type
    }
 }
 `
