import { Flex, Text } from 'influ-dms'
import { useRouter } from 'next/router'
import styled from 'styled-components'

const Dropdown = styled(Flex)`
  display: flex;
  min-width: 192px;
  position: fixed;
  bottom: 0;
  left: ${({ isMenuExpanded, isOpen }) => (!isOpen ? '-150px' : isMenuExpanded ? '192px' : '48px')};
  transition: left 0.3s ease;
  height: ${({ isExcludedPage }) => (isExcludedPage ? 'calc(100vh - var(--trialHeaderHeight))' : '100vh')};
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.primary.light6};
  padding: 16px 12px;  
  box-sizing: border-box;
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const DropdownLink = styled(Text)`
  color: ${({ theme }) => theme.colors.primary.light1};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`

export const MenuDropdown = ({ label, isMenuExpanded, isOpen, subMenu, onProductClick }) => {
  const router = useRouter()
  const pathname = router.pathname
  const isPricesPage = pathname.includes('pricesAndPlans')
  const isPaymentPage = pathname.includes('subscriptionForm')
  const isUpgradePaymentPage = pathname.includes('upgradeSubscription')

  return (
    <Dropdown
      column
      justify='flex-start'
      gap='24px'
      isOpen={isOpen}
      isMenuExpanded={isMenuExpanded}
      isExcludedPage={!isPricesPage && !isPaymentPage && !isUpgradePaymentPage}
    >
      {label && subMenu?.length > 0 &&
      <>
        <Text color='primary.light1' size='16' bold>
          {label}
        </Text>
        <Flex column gap='16px'>
          {subMenu?.map((item, index) => (
            <DropdownLink
              key={index}
              color='primary.light1'
              size='14'
              onClick={() => onProductClick(item.key)}
          >
              {item.label}
            </DropdownLink>
          ))}
        </Flex>
      </>
    }
    </Dropdown>
  )
}
