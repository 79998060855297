import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../../styles'

export const AddPostsManualInstagramStoryMetrics = ({ isTracking }) => {
  const { t } = useTranslate()

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationMetrics.title')}</Text>
      {!isTracking && (
        <>
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.reach.input.placeholder')} name='additionalMetrics.reach' label={t('commons.insights.reach.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.likes.input.placeholder')} name='likes' label={t('commons.insights.likes.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.shares.input.placeholder')} name='additionalMetrics.shares' label={t('commons.insights.shares.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.interactingAccounts.input.placeholder')} name='additionalMetrics.interactingAccounts' label={t('commons.insights.interactingAccounts.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.impressions.input.placeholder')} name='additionalMetrics.impressions' label={t('commons.insights.impressions.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.replies.input.placeholder')} name='additionalMetrics.replies' label={t('commons.insights.replies.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.tapsBack.input.placeholder')} name='additionalMetrics.navigationBack' label={t('commons.insights.tapsBack.input.label')} />
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.tapsForward.input.placeholder')} name='additionalMetrics.navigationForward' label={t('commons.insights.tapsForward.input.label')} />
        </>
      )}
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.nextStory.input.placeholder')} name='additionalMetrics.nextStory' label={t('commons.insights.nextStory.input.label')} />
      {!isTracking && (
        <>
          <Spacing size='24' />
          <Form.InputNumber placeholder={t('commons.insights.exits.input.placeholder')} name='additionalMetrics.exits' label={t('commons.insights.exits.input.label')} />
        </>
      )}
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.clicksOnTheLink.input.placeholder')} name='additionalMetrics.linkClicks' label={t('commons.insights.clicksOnTheLink.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.profilevisits.input.placeholder')} name='additionalMetrics.profileVisits' label={t('commons.insights.profilevisits.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.swipeUp.input.placeholder1')} name='additionalMetrics.swipes' label={t('commons.insights.swipeUp.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.stickersTaps.input.placeholder')} name='additionalMetrics.stickerTaps' label={t('commons.insights.stickers.input.label')} />
      <Spacing size='8' />
    </SideBox>)
}
