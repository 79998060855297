import { Flex, Loader, TranslationProvider } from 'influ-dms'
import { useState, useContext, useEffect, createContext } from 'react'
import { IntlProvider, ReactIntlErrorCode } from 'react-intl'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useQuery, useMutation, NetworkStatus } from '@apollo/client'
import { saveLocale, self } from '../graphql'
import moment from 'moment'
import 'moment/min/locales'
import { captureException } from '@sentry/nextjs'

export const LanguageContext = createContext({
  locale: 'en',
  updateLanguage: async () => { }
})

export const RFC_4646_LOCALES = {
  EN: 'en',
  ES: 'es',
  DE: 'de',
  PT: 'pt',
  FR: 'fr',
  IT: 'it',
}

export const LOCALES_TRANSLATED = {
  en: 'settings.yourPreferences.general.platformLanguage.input.language.dropdown.english.listItem',
  es: 'settings.yourPreferences.general.platformLanguage.input.language.dropdown.spanish.listItem',
  de: 'settings.yourPreferences.general.platformLanguage.input.language.dropdown.germany.listItem',
  pt: 'settings.yourPreferences.general.platformLanguage.input.language.dropdown.portuguese.listItem',
  fr: 'settings.yourPreferences.general.platformLanguage.input.language.dropdown.french.listItem',
  it: 'settings.yourPreferences.general.platformLanguage.input.language.dropdown.italian.listItem'
}

export async function loadLanguageCatalogue (locale) {
  try {
    let localeToFetch = 'en'
    if (Object.values(RFC_4646_LOCALES).includes(locale)) {
      localeToFetch = locale
    }

    const response = await fetch(`/static/locales/${localeToFetch}.json`)
    if (!response.ok) {
      throw new Error()
    }

    return await response.json()
  } catch (error) {
    const fallbackCatalogue = await fetch('/static/locales/en.json')
    return await fallbackCatalogue.json()
  }
}

const findLocaleInPath = (pathNameArray = []) =>
  pathNameArray.find(pathElement => pathElement === RFC_4646_LOCALES[pathElement.toUpperCase()])

const getDefaultLang = (lang, routerLang) => {
  let userLocale
  if (lang) {
    userLocale = lang
  } else if (routerLang) {
    userLocale = routerLang
  }
  return userLocale
}

let isInitialAccess = true

const getNavigatorLanguage = async () => {
  const _language = get(navigator, 'language', RFC_4646_LOCALES.EN)
  return (_language.indexOf('-') > -1 ? _language.replace(/[-_][a-z0-9]+$/i, '') : _language).toLowerCase()
}

export const LanguageProvider = ({ children }) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const lang = get(router, 'query.lang')
  const pathname = get(router, 'pathname', '')

  const [locale, setLocale] = useState()
  const [messages, setMessages] = useState()

  const isPublic = pathname.includes('public')
  const isLogin = router?.pathname?.includes('login')
  const skip = isPublic || isLogin || !brandId
  const { data, networkStatus } = useQuery(self, { fetchPolicy: 'network-only', skip })
  const [saveLocaleMutation, { loading }] = useMutation(saveLocale)

  let errorMessagesInt = new Map()
  if (typeof window !== 'undefined') errorMessagesInt = new Map(JSON.parse(window?.localStorage?.errorMessagesInt || '[]'))

  const asyncSetLocale = async () => {
    const routerLang = findLocaleInPath(pathname.split('/'))
    const localePath = getDefaultLang(lang, routerLang)
    const localeNavigator = await getNavigatorLanguage()
    const localeUser = get(data, 'self.locale', null)
    let resultLocale

    if (localePath) {
      resultLocale = localePath
    } else if (data) {
      resultLocale = localeUser ?? localeNavigator

      if (!localeUser) {
        try {
          const variables = {
            locale: RFC_4646_LOCALES[resultLocale?.toUpperCase()] ?? RFC_4646_LOCALES.EN,
            browserLocale: localeNavigator
          }

          await saveLocaleMutation({ variables })
        } catch (e) {
          resultLocale = RFC_4646_LOCALES.EN
        }
      }
    } else {
      resultLocale = localeNavigator
    }

    setLocale(RFC_4646_LOCALES[resultLocale?.toUpperCase()] ?? RFC_4646_LOCALES.EN)

    const messages = await loadLanguageCatalogue(resultLocale)
    setMessages(messages)

    moment.locale(resultLocale?.toLowerCase() ?? 'en')
    isInitialAccess = false
  }

  useEffect(() => {
    const isReady = networkStatus === NetworkStatus.ready && router?.isReady && isInitialAccess
    isReady && asyncSetLocale()
  }, [networkStatus, router])

  useEffect(() => {
    const userEmail = get(data, 'self.email')
    if (locale && userEmail) {
      const updateArgs = { user_email: userEmail, language: locale?.toUpperCase() }
      window?.Beamer?.update(updateArgs)
    }
  }, [locale])

  const updateLanguage = async (locale, isPublic = false) => {
    if (!isPublic) {
      const variables = { locale }
      saveLocaleMutation({ variables })
    }
    moment.locale(locale?.toLowerCase() || 'en')
    setLocale(locale)

    const messages = await loadLanguageCatalogue(locale)
    setMessages(messages)
  }

  const handleErrorMessagesIntl = (e) => {
    if (typeof window === 'undefined') return
    if (e.code === ReactIntlErrorCode.FORMAT_ERROR || e.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
      if (!e?.descriptor?.id.includes('faqs.')) {
        const key = e?.descriptor?.id || 'err'
        if (!errorMessagesInt.has(key)) {
          console.warn(e)
          if (e?.descriptor?.id?.includes('.')) {
            captureException(`Key is not found in locales, received ${e?.descriptor?.id}` +
            '\n[defaultMessage] ' + e?.descriptor?.defaultMessage, e)
          }
          errorMessagesInt.set(key, true)
          const entries = Array.from(errorMessagesInt.entries())
          localStorage.errorMessagesInt = JSON.stringify(entries)
        }
      }
    } else {
      console.error(e)
    }
  }

  const contextValues = {
    updateLanguage,
    locale,
    messages,
    loading,
    data
  }

  if (!locale) {
    return (
      <Flex styles='height: 100vh' center>
        <Loader name='loading-language' />
      </Flex>
    )
  }

  return (
    messages && (<LanguageContext.Provider value={contextValues}>
      <IntlProvider messages={messages} locale={locale} onError={(error) => handleErrorMessagesIntl(error)}>
        <InfluDMSContext>
          {children}
        </InfluDMSContext>
      </IntlProvider>
    </LanguageContext.Provider>)
  )
}

const InfluDMSContext = ({ children }) => {
  const { t } = useTranslate()
  return (
    <TranslationProvider t={t}>
      {children}
    </TranslationProvider>
  )
}

export const useLanguageContext = () => {
  return useContext(LanguageContext)
}
