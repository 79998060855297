import { useContext, useState } from 'react'
import { Flex, FormContext, Icon, Text } from 'influ-dms'
import styled from 'styled-components'
import { DeleteProfilesConfirmationTrackingModal } from '../../../../influencers/commons'
import { useTranslate } from '~/hooks/useTranslate'

const StyledIconContainer = styled(Flex)`
  margin-left: 12px;
  height: 100%;
  padding: 0 6px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const StyledButtonText = styled(Text)`
  margin-left: 6px;
`

export const ProfilesTableHeaderDelete = ({ ids, editing = false, searchReset, setSearchReset }) => {
  const { t } = useTranslate()
  const { values, setValue } = useContext(FormContext)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleOnDelete = () => {
    const filteredProfiles = values.influencers.filter((profile) => !ids.includes(profile.username))
    setValue('InputText', 'influencers', filteredProfiles)
    setDeleteModalOpen(false)
    setSearchReset(!searchReset)
  }

  return (
    <StyledIconContainer align='center' onClick={() => editing ? setDeleteModalOpen(true) : handleOnDelete()}>
      <Icon color='primary.dark' size='18' name='trash' />
      <StyledButtonText color='primary.dark' size='13'>{t('commons.button.delete')}</StyledButtonText>
      <DeleteProfilesConfirmationTrackingModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        handleContinue={handleOnDelete}
      />
    </StyledIconContainer>
  )
}
