import styled from 'styled-components'
import { Button, Flex } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  margin: 16px 0;
`

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export const Footer = ({ acceptText, handleAccept, handleCancel }) => {
  const { t } = useTranslate()
  return (
    <StyledFlex>
      <Button onClick={handleAccept}>{acceptText}</Button>
      <StyledButton variant='flat' color='secondary' type='reset' onClick={handleCancel}>{t('commons.button.cancel')}</StyledButton>
    </StyledFlex>
  )
}
