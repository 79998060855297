import { gql } from '@apollo/client'

export const editInvoiceOrderNumber = gql`
  mutation editInvoiceOrderNumber(
    $_id: String!
    $orderNumber: String
  ) {
    editInvoiceOrderNumber(
      _id: $_id
      orderNumber: $orderNumber
    ) {
      message
      success
    }
  }
`
