import { RFC_4646_LOCALES } from '~/context/Language'

export async function loadCatalogue (locale) {
  try {
    let localeToFetch = 'en'
    if (Object.values(RFC_4646_LOCALES).includes(locale)) {
      localeToFetch = locale
    }

    const response = await fetch(`/static/locales/links_${localeToFetch}.json`)
    if (!response.ok) {
      throw new Error()
    }

    return await response.json()
  } catch (error) {
    const fallbackCatalogue = await fetch('/static/locales/links_en.json')
    return await fallbackCatalogue.json()
  }
}

export const useLink = async (linkKey, locale = RFC_4646_LOCALES.EN) => {
  const catalogue = await loadCatalogue(locale)
  return catalogue[linkKey] ?? ''
}

export const bindLinksMap = async (linksMap, locale) => {
  const promises = []
  linksMap.forEach((value, key) => {
    promises.push(useLink(key, locale))
  })

  const result = await Promise.all(promises)
  let count = 0
  linksMap.forEach((value, _) => {
    value(result[count])
    count++
  })
}
