import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { brand } from '~/graphql/brands'

export const useBrandData = ({ variables, skip = false }) => {
  const { data, error, loading, refetch } = useQuery(brand, {
    variables,
    skip
  })
  const plan = get(data, 'brand.subscriptionInfo.planType')
  const isEnterprise = plan === 'enterprise'
  const isTrial = get(data, 'brand.trial.isTrial')
  const products = get(data, 'brand.products', [])
  const featureFlags = get(data, 'brand.featureFlags', {})
  return { data, error, loading, isEnterprise, isTrial, products, featureFlags, refetch }
}
