import { gql } from '@apollo/client'
import { getApplicationFormFragment } from '../../../graphql/getApplicationFormFragment'

export const getInfluencerFormForEdition = gql`
  query getInfluencerFormForEdition($_id: String!) {
    getInfluencerFormForEdition(_id: $_id) {
      ...ApplicationFormFragment
    }
  }
  ${getApplicationFormFragment}
`
