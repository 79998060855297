import styled from 'styled-components'
import { Flex, Text } from 'influ-dms'

const StyledFlex = styled(Flex)`
  flex: 50%;
  :nth-last-child(n+3) {
    padding-bottom: 16px;
  }
`

export const EditPostModalContentAutomaticPersonTagged = ({ username }) => (
  <StyledFlex align='center' flex='1'>
    <Text size='12' color='primary.dark'>@{username}</Text>
  </StyledFlex>
)
