import { gql } from '@apollo/client'

export const editEntityViewsOrderByUser = gql`
  mutation editEntityViewsOrderByUser(
    $entityViewIds: [String]!
  ) {
    editEntityViewsOrderByUser(
      entityViewIds: $entityViewIds
    ){
      message
      code
      success
    }
  }
`