import { gql } from '@apollo/client'

export const sendMassiveEmailToInfluencers = gql`
  mutation sendMassiveEmailToInfluencers(
    $influencerIds: [String]
    $emails: [EmailsInputType]
    $subject: String!
    $html: String
    $attachments: [EmailAttachmentInputType]
    $source: SourceInputType
  ) {
    sendMassiveEmailToInfluencers(
      influencerIds: $influencerIds
      emails: $emails
      subject: $subject
      html: $html
      attachments: $attachments
      source: $source
    ) {
      message
      code
      success
    }
  }
`
