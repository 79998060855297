import { HelpTooltip, RoundedButton, Tooltip } from 'influ-dms'
import { comparatorActionsStyles, roundedButtonStyles } from './styles'

export const Actions = {
  NOTES: 'notes',
  EDIT: 'edit',
  PHONE: 'phone',
  EMAILS: 'emails',
  SEND: 'send',
  SHARE: 'share',
  PRINT: 'print',
  EXPORT: 'export',
  REFRESH: 'refresh',
  ADD_TO: 'addTo',
  MORE_OPTIONS: 'moreOptions',
  SEPARATOR: 'separator',
  SEPARATOR_2: 'separator2',
  DELETE: 'delete',
  PREVIEW: 'preview',
  DUPLICATE: 'duplicate'
}

export const ActionIcons = {
  [Actions.NOTES]: 'note',
  [Actions.EDIT]: 'edit',
  [Actions.PHONE]: 'phone',
  [Actions.EMAILS]: 'mail',
  [Actions.SEND]: 'send',
  [Actions.SHARE]: 'share-alt',
  [Actions.PRINT]: 'print',
  [Actions.EXPORT]: 'download',
  [Actions.REFRESH]: 'refresh',
  [Actions.ADD_TO]: 'addbox',
  [Actions.MORE_OPTIONS]: 'moreoptions',
  [Actions.DELETE]: 'trash',
  [Actions.PREVIEW]: 'eye',
  [Actions.DUPLICATE]: 'duplicate'
}

export default function Action ({
  text,
  tooltipPlace = 'bottom',
  iconName,
  onClick = () => {},
  customComponent,
  tooltipName,
  withHelpTooltip,
  color,
  isDisabled,
  dropdownComponent,
  spinWhenDisabled,
  isComparator
}) {
  const CustomTooltip = withHelpTooltip ? HelpTooltip : Tooltip
  const tooltipProps = withHelpTooltip
    ? {
        name: tooltipName,
        text
      }
    : { text, place: tooltipPlace, width: isComparator ? 'max-content' : 'fit-content', type: 'dark', color: 'neutral.white' }
  return (
    <CustomTooltip {...tooltipProps}>
      {customComponent ? (
        customComponent()
      ) : (
        <RoundedButton
          name={iconName}
          onClick={onClick}
          color={color ?? 'primary.light2'}
          styles={isComparator ? comparatorActionsStyles : roundedButtonStyles}
          size={20}
          isDisabled={isDisabled}
          isDropdown={Boolean(dropdownComponent)}
          dropdownComponent={dropdownComponent}
          spinWhenDisabled={spinWhenDisabled}
        />
      )}
    </CustomTooltip>
  )
}
