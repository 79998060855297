import { useContext } from 'react'
import { Flex, Text, GenericLoader } from 'influ-dms'
import { get } from 'lodash'
import { CommunicationContext } from '../../../CommunicationContext'
import { COMMUNICATION_STATUS } from '../../../CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const ConnectFormSteps = () => {
  const { t } = useTranslate()

  const steps = {
    [COMMUNICATION_STATUS.newAccountConnecting]: t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.loading.step.verifyAccount'),
    [COMMUNICATION_STATUS.newAccountVerified]: t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.loading.step.connectingMailbox'),
    [COMMUNICATION_STATUS.newAccountMailboxConnected]: t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.loading.step.sendingTestEmail'),
    [COMMUNICATION_STATUS.newConnectionMessageSending]: t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.loading.step.sendingTestEmail'),
    [COMMUNICATION_STATUS.newConnectionMessageSent]: t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.loading.step.waitingForTestEmail')
  }

  const { notifyCommunicationUser } = useContext(CommunicationContext)
  const status = get(notifyCommunicationUser, 'status')
  const textToShow = steps[status] || t('settings.yourPreferences.communication.email.configuration.status.step.wait')
  return (
    <Flex center>
      <GenericLoader />
      <Text color='primary.light3'>{textToShow}</Text>
    </Flex>
  )
}
