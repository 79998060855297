import { useEffect, useState } from 'react'
import { GeneralError } from './GeneralError'
import { SaveSuccess } from './SaveSuccess'
import styled from 'styled-components'
import { Flex } from 'influ-dms'

const FooterContainer = styled(Flex)`
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light5};
  ${({ styles }) => styles || ''};
`

export const SettingsCardFooter = ({ saved, error, setError, styles, children }) => {
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [generalError, setGeneralError] = useState(undefined)

  useEffect(() => {
    if (saved) {
      setSaveSuccess(true)
      const timeoutSuccess = setTimeout(() => { setSaveSuccess(false) }, 5000)
      return () => clearTimeout(timeoutSuccess)
    }
  }, [saved])

  useEffect(() => {
    if (error) {
      setGeneralError(error)
      const timeoutGeneralError = setTimeout(() => { setGeneralError(undefined); setError && setError(undefined) }, 5000)
      return () => clearTimeout(timeoutGeneralError)
    }
  }, [error])

  return (
    <FooterContainer styles={styles}>
      {generalError && <GeneralError text={generalError} />}
      {saveSuccess && <SaveSuccess />}
      {!saveSuccess && !generalError && children}
    </FooterContainer>
  )
}
