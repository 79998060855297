export const REPORTS_DASHBOARD_TABS = {
  overview: 'Overview',
  publications: 'Publications'
}

export const REPORTS_POSTS_TABS = [
  { label: 'publications', key: 'reports.report.publicationAndDate.publications.tabName' },
  /* TODO: uncomment if tracking needs to be restore */
  // { label: 'Data Source', key: 'reports.report.publicationAndDate.dataSource.tabName' }
]

export const REPORTS_DASHBOARD_TABS_KEYS = [
  { label: 'Overview', key: 'reports.report.dashboard.overview.tabName' },
  { label: 'Publications', key: 'reports.report.dashboard.publications.tabName' }
]
