import styled from 'styled-components'

const StyledIframe = styled('iframe')`
  width: 533px;
  height: 300px;
  border: 0;
`

export const PreviewPublicationYoutube = ({ youtubeShortcode }) => {
  if (!youtubeShortcode) { return '' }
  return (
    <StyledIframe
      src={`https://www.youtube.com/embed/${youtubeShortcode}?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
    />
  )
}
