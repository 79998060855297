import { useContext } from 'react'
import { get } from 'lodash'
import { LimitsContext } from '~/context'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { brand } from '~/graphql/brands'
import { Select, SideBox, Flex, Text, Spacing } from 'influ-dms'
import { SelectWrapper } from './styles'
import { FormatOptionLabel } from './FormatOptionLabel'
import { useTranslate } from '~/hooks/useTranslate'

export const networkOptions = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'youtube', label: 'YouTube' }
]

const SelectNetwork = ({ value, onChange, onPermissioDenied }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = router.query.brandId
  const { data } = useQuery(brand, { variables: { brandId }, skip: !brandId })
  const filterNetworks = get(data, 'brand.featureFlags.search.networks') || []
  const { setLimit, setLimitOpen } = useContext(LimitsContext)

  const handleChange = (params) => {
    const { value } = params
    const hasPermissions = filterNetworks.includes(value)
    if (!hasPermissions) {
      setLimit(t('commons.modals.blocked.titleHeader1.networkYouCanSearchIn'))
      setLimitOpen(true)
      onPermissioDenied()
      return
    }
    onChange(params)
  }

  return (
    <SideBox styles='width:100%;'>
      <Flex align='center'>
        <Text
          color='primary.dark'
          bold
          size='14'
        >
          {t('commons.modal.filtersInfluencers.searchOnBlock.input.label')}
        </Text>
        <Spacing vertical size='6' />
        <SelectWrapper>
          <Select
            placeholder='Select network'
            name='selectNetwork'
            options={networkOptions}
            formatOptionLabel={FormatOptionLabel}
            value={value}
            onChange={handleChange}
          />
        </SelectWrapper>
      </Flex>
    </SideBox>
  )
}

export { SelectNetwork }
