import { createContext, useReducer } from 'react'

const initialValues = {
  domain: undefined,
  isAddDomainModalOpen: false,
  isDeleteDomainModalOpen: false,
  isDetailDomainModalOpen: false
}

export const DomainsContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setDomain':
      return { ...state, domain: action.payload }
    case 'setAddDomainModalOpen': {
      return { ...state, isAddDomainModalOpen: action.value }
    }
    case 'setDeleteDomainModalOpen': {
      return { ...state, isDeleteDomainModalOpen: action.value }
    }
    case 'setDetailDomainModalOpen': {
      return { ...state, isDetailDomainModalOpen: action.value }
    }
  }
}

export const DomainProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setDomain = (list) => {
    dispatch({ type: 'setDomain', payload: list })
  }

  const openAddDomainModal = () => {
    dispatch({ type: 'setAddDomainModalOpen', value: true })
  }

  const closeAddDomainModal = () => {
    dispatch({ type: 'setAddDomainModalOpen', value: false })
  }

  const openDeleteDomainModal = () => {
    dispatch({ type: 'setDeleteDomainModalOpen', value: true })
  }

  const closeDeleteDomainModal = () => {
    dispatch({ type: 'setDeleteDomainModalOpen', value: false })
  }

  const openDetailDomainModal = () => {
    dispatch({ type: 'setDetailDomainModalOpen', value: true })
  }

  const closeDetailDomainModal = () => {
    dispatch({ type: 'setDetailDomainModalOpen', value: false })
  }

  return (
    <DomainsContext.Provider value={{
      ...state,
      setDomain,
      openAddDomainModal,
      closeAddDomainModal,
      openDeleteDomainModal,
      closeDeleteDomainModal,
      openDetailDomainModal,
      closeDetailDomainModal
    }}>
      {children}
    </DomainsContext.Provider>
  )
}
