import { Component } from 'react'
import { createGlobalStyle } from 'styled-components'
import { get } from 'lodash'
import getConfig from 'next/config'
import { Button } from 'influ-dms'
import { captureException } from '@sentry/nextjs'

// Global styles
const GlobalStyles = createGlobalStyle`
  @media print {
    #printButton{
      display: none;
    }
    #print-loader {
      display: none;
    }
    @page {
      margin: 0;
      background-color: #FBF9FB !important;
    }
  }
  .print-page{
    overflow:visible !important;
    overflow-x:visible !important;
  }
  .print-overflow{
   overflow:visible !important;
  }
`

const { publicRuntimeConfig } = getConfig()
const { pdfDebug } = publicRuntimeConfig

const withPrint = (WrappedComponent) => {
  class WithPrint extends Component {
    constructor (props) {
      super(props)
      this.state = {
        // User component <PrintPDFTitle/> to set document title
        fileName: (typeof window !== 'undefined' && document.querySelector('#print-fileName .value') && document.querySelector('#print-fileName .value').innerHTML)
      }
    }

    componentDidMount () {
      const pathname = get(this.props, 'router.pathname', '')
      const isPrintLocation = pathname.includes('print')
      const isNotPrintLocation = !isPrintLocation

      if (isNotPrintLocation) return <></>
    }

    handleTitle (title) {
      if (!this.state.title) {
        this.setState({ title })
      }
    }

    PrintPDF () {
      if (window) window.print()
    }

    render () {
      const pathname = get(this.props, 'router.pathname', '')
      const isPrintLocation = pathname.includes('print')
      const isNotPrintLocation = !isPrintLocation

      if (isNotPrintLocation) return <WrappedComponent {...this.props} />

      if (pdfDebug) { captureException(this.state) }

      return (
        <>
          {pdfDebug &&
            <div id='print-actions'>
              <Button
                id='printButton'
                styles='position: absolute; z-index: 1000; right: 0px;'
                onClick={() => { this.PrintPDF() }}
                primary
                data-cy='hoc-withPrint-printButton'
                  >Test Print
              </Button>
            </div>}

          <WrappedComponent
            onTitleSet={(title) => { this.handleTitle(title) }}
            {...this.props}
            />
          <GlobalStyles />
        </>
      )
    }
  }

  return WithPrint
}

export default withPrint
