import { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  CellWrapper,
  Dropdown,
  DropdownOptionItem,
  Flex,
  HoverAction,
  HoverWrapper,
  Spacing,
  Text,
  Tooltip
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { ProgramContext } from '~/containers/programs/commons/context'
import { usePrograms } from '~/containers/programs/commons/hooks/usePrograms'
import { OutreachContext } from '../../../communication/outreach/context/OutreachContext'
import { ListContext } from '../../lists/commons/context'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const imagePostStyles = css`
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
  background-image: url('${({ image }) => image}'),
    url('/static/img/placeholders/avatarPlaceholder.png'); 
  background-size: cover;
  background-position: center;
`

const StyledContainer = styled(Flex)`
  padding: 0 24px;
  ${cellEllipsis};
`

const ImageContainer = styled.div`
  width: 30px;
  height: 30px;
  ${imagePostStyles};
  border-radius: 50%;
`
const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out; 
    text-decoration: underline;
  }
`

const StyledFlex = styled(Flex)`
  width : 97%;
`

const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

const textWrapperStyles = css`
  overflow: hidden;
`

const SyncHoverActionWrapper = styled.div`
  & > div {
    background-color: ${({ theme, disabled }) => disabled ? theme.colors.app.light3 : theme.colors.app.default} !important;
    ${({ disabled }) => disabled && 'cursor: not-allowed;'}
    
    & > div > svg {
      color: ${({ theme }) => theme.colors.neutral.white} !important;
    }

    & > p {
      color: ${({ theme }) => theme.colors.neutral.white} !important;
    }
  }
`

export const InfluencersListTableTdAliasName = ({
  row: { original },
  onEditClick,
  onRemoveFromProgramClick,
  onRemoveFromListClick,
  onRemoveFromOutreachClick,
  onAliasClick,
  onSendMassiveEmailClick,
  showRenderAddTo,
  onSyncInfluencerClick,
  fromPrograms = false,
  fromList = false,
  fromOutreach = false,
  fromCodes = false
}) => {
  const { t } = useTranslate()
  const {
    loadingSyncInfluencers
  } = usePrograms()
  const { openLinkProgramModal, influencersLoading } = useContext(ProgramContext)
  const { setAssociateToOutreachesModalOpen } = useContext(OutreachContext)
  const influencer = fromCodes ? original.influencer : original || {}
  const influencerId = influencer._id

  const isThisInfluencerLoading = influencersLoading?.includes(influencerId)
  const loading = isThisInfluencerLoading || loadingSyncInfluencers
  const { alias, picture } = influencer
  const isDisabledRemoveFromOutreach = (
    original?.outreach?.rawStatus === 'processed' ||
    original?.outreach?.rawStatus === 'processing'
  ) && fromOutreach

  const isDisabledSyncInfluencers = !original?.hasInfluencerGiftingRules && !original?.hasAudienceBenefitRules
  const showSync = !original?.rawSynchronized

  const [sendToOpened, setSendToOpened] = useState(false)
  const [addToOpened, setAddToOpened] = useState(false)
  const { openLinkInfluencersToListsModal } = useContext(ListContext)

  const handleSyncInfluencers = () => {
    if (isDisabledSyncInfluencers) return

    onSyncInfluencerClick()
  }

  const handleAddOrSendTo = (onClick) => {
    setAddToOpened(false)
    onClick()
  }

  const renderSendTo = () => {
    return (
      <Dropdown
        isOpen={sendToOpened}
        setOpen={setSendToOpened}
        align='left'
        top='18px'
        right='30px'
        position='fixed'
        width='auto'
      >
        <DropdownOptionItem
          onClick={() => handleAddOrSendTo(onSendMassiveEmailClick)}
          data-cy='compose-email'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.composeEmail')}
            </Text>
          </Flex>
        </DropdownOptionItem>
        <DropdownOptionItem
          onClick={() => handleAddOrSendTo(() => setAssociateToOutreachesModalOpen(true, [influencerId], 'influencer'))}
          data-cy='send-to-outreach'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.addToOutreach')}
            </Text>
          </Flex>
        </DropdownOptionItem>
      </Dropdown>
    )
  }

  const renderAddTo = () => {
    return (
      <Dropdown
        isOpen={addToOpened}
        setOpen={setAddToOpened}
        align='left'
        top='18px'
        right='60px'
        width='auto'
      >
        <DropdownOptionItem
          onClick={() => handleAddOrSendTo(() => openLinkInfluencersToListsModal([influencerId]))}
          data-cy='add-to-list'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.list')}
            </Text>
          </Flex>
        </DropdownOptionItem>
        <DropdownOptionItem
          onClick={() => handleAddOrSendTo(() => openLinkProgramModal([influencerId]))}
          data-cy='add-to-program'
        >
          <Flex>
            <Text size='14' ellipsis color='primary.light2'>
              {t('commons.button.program')}
            </Text>
          </Flex>
        </DropdownOptionItem>
      </Dropdown>
    )
  }

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          {alias &&
            <>
              <Spacing vertical size={5} />
              <ImageContainer image={picture} />
            </>
          }
          <Flex column styles={textWrapperStyles}>
            {onAliasClick
              ? <Text name='Influencer-Alias' ellipsis showTooltip styles={clickableStyles} size='14' color='primary.light2' bold onClick={onAliasClick}>{alias}</Text>
              : <Text name='Influencer-Alias' ellipsis showTooltip size='14' color='primary.light2' bold>{alias || t('commons.button.associateToAnInfluencer')}</Text>}
          </Flex>
        </StyledContainer>
        <Flex>
          <HoverWrapper>
            {fromPrograms && onSyncInfluencerClick && showSync && (
              isDisabledSyncInfluencers ? (
                <Tooltip
                  place='bottom'
                  text={t('commons.button.sync.disabled.noBenefitsCreated.tooltip')}
                  width='134px'
                  type='dark'
                  color='neutral.white'
              >
                  <SyncHoverActionWrapper disabled>
                    <HoverAction
                      onClick={() => {}}
                      text={t('commons.button.sync')}
                      width='fit-content'
                      isLoading={loading}
                />
                  </SyncHoverActionWrapper>
                </Tooltip>
              ) : (
                <SyncHoverActionWrapper>
                  <HoverAction
                    onClick={handleSyncInfluencers}
                    text={t('commons.button.sync')}
                    width='fit-content'
                    isLoading={loading}
                />
                </SyncHoverActionWrapper>
              )
            )}
            {onSendMassiveEmailClick &&
              <Tooltip
                text={t('commons.button.sendEmail.tooltip')}
                place='top'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
              >
                <HoverAction
                  onClick={() => setSendToOpened(!sendToOpened)}
                  iconName='send'
                  isDropdown
                  dropdownComponent={renderSendTo}
                />
              </Tooltip>
            }
            {onEditClick &&
              <Tooltip
                text={t('entity.influencer.tool.edit.tooltip')}
                place='bottom'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
              >
                <HoverAction onClick={onEditClick} iconName='edit' />
              </Tooltip>
            }
            {fromPrograms && onRemoveFromProgramClick && (
              <Tooltip
                text={t('commons.button.remove.tooltip')}
                place='bottom'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
              >
                <HoverAction onClick={onRemoveFromProgramClick} iconName='remove' />
              </Tooltip>
            )}
            {fromList && onRemoveFromListClick && (
              <Tooltip
                text={t('commons.button.remove.tooltip')}
                place='bottom'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
                >
                <HoverAction onClick={onRemoveFromListClick} iconName='remove' />
              </Tooltip>
            )}
            {fromOutreach && onRemoveFromOutreachClick && (
              <Tooltip
                text={isDisabledRemoveFromOutreach ? t('entity.influencer.tool.remove.disabled.tooltip') : t('commons.button.remove.tooltip')}
                place='bottom'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
                >
                <HoverAction onClick={onRemoveFromOutreachClick} iconName='remove' disabledIcon={isDisabledRemoveFromOutreach} />
              </Tooltip>
            )}
            {showRenderAddTo &&
              <HoverAction
                onClick={() => setAddToOpened(!addToOpened)}
                iconName='arrowdown'
                text={t('commons.button.addTo')}
                isDropdown
                dropdownComponent={renderAddTo}
                width='fit-content'
              />
            }
          </HoverWrapper>
        </Flex>
      </StyledFlex>
    </CellWrapper>
  )
}
