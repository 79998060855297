import { useState, createContext } from 'react'
import { LimitModal } from '../containers/commons/LimitModal'
import { useTranslate } from '~/hooks/useTranslate'

export const LimitsContext = createContext({ value: {} })

const LimitsContextProvider = (props) => {
  const [isOpen, setLimitOpen] = useState(false)
  const [onClose, setOnClose] = useState(false)
  const [limit, setLimit] = useState('')
  const [modalTitle, setModalTitle] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const [subtitle, setSubtitle] = useState(undefined)

  const { t } = useTranslate()

  const handleOnClose = () => {
    if (onClose instanceof Function) onClose()
    setLimitOpen(false)
  }

  const handleUnmountModal = () => {
    setLimit('')
    setModalTitle('')
    setTitle('')
    setSubtitle('')
  }
  const validateLimit = (canExport) => {
    if (!canExport) {
      setModalTitle(t('commons.modals.featureUnavailable.header'))
      setTitle(t('commons.modals.featureUnavailable.titleHeader1'))
      setSubtitle(t('commons.modals.blocked.titleHeader1.youNeedToUpgrade'))
      setLimitOpen(true)
      return false
    }
    return true
  }

  return (
    <LimitsContext.Provider
      value={{
        setLimitOpen,
        setLimit,
        setModalTitle,
        setTitle,
        setSubtitle,
        validateLimit,
        setOnClose
      }}
    >
      <LimitModal
        limit={limit}
        isOpen={isOpen}
        modalTitle={modalTitle}
        title={title}
        subtitle={subtitle}
        onClose={handleOnClose}
        onModalUnmount={handleUnmountModal}
      />
      {props.children}
    </LimitsContext.Provider>
  )
}

export { LimitsContextProvider }
