import styled from 'styled-components'
import { Flex, Icon, Number, Text } from 'influ-dms'

const StyledFlex = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary.light5};
  border-radius: 12px;
  height: 24px;
  padding: 4px 8px;
  box-sizing: border-box;
  :not(:last-child) {
    margin-bottom: 8px;
  }
  svg {
    display: none;
  }
  :hover {
    background: ${({ theme }) => theme.colors.primary.light3};
    p {
      color: ${({ theme }) => theme.colors.neutral.white};
    }
    svg {
      display: block;
    }
  }
`

const StyledText = styled(Text)`
  margin: 0 4px 0 0;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.app.default};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const IconContainer = styled.div`
  margin-right: auto;
  margin-left: 8px;
  cursor: pointer;
`

export const NewEmailModalFooterAttachment = ({ file, files, setFiles, canDelete = true }) => {
  const handleDelete = () => {
    setFiles(files.filter(f => (f?.id || f?.attachmentId) !== (file?.id || file?.attachmentId)))
  }
  const handleDownload = () => {
    const link = document.createElement('a')
    const blob = new Blob([file])
    const fileContent = URL.createObjectURL(blob)
    link.setAttribute('href', fileContent)
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
  }

  return (
    <StyledFlex align='center'>
      <StyledText bold onClick={handleDownload}>{file?.name}</StyledText>
      <Text color='primary.dark'>(<Number humanized>{file?.size}</Number>)</Text>
      {canDelete &&
        <IconContainer onClick={handleDelete}>
          <Icon name='close' color='neutral.white' />
        </IconContainer>
      }
    </StyledFlex>
  )
}
