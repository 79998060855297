import { createContext, useReducer } from 'react'

const initialValues = {
  template: undefined,
  isCreateTemplateModalOpen: false,
  isEditTemplateModalOpen: false,
  isDeleteTemplateModalOpen: false,
  isDeleteTemplatesModalOpen: false,
  isSaveTemplateModalOpen: false,
  isSelectTemplateModalOpen: false
}

export const TemplateContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setTemplate':
      return { ...state, template: action.payload }
    case 'setCreateTemplateModalOpen':
      return { ...state, isCreateTemplateModalOpen: action.payload }
    case 'setEditTemplateModalOpen':
      return { ...state, isEditTemplateModalOpen: action.payload }
    case 'setDeleteTemplateModalOpen':
      return { ...state, isDeleteTemplateModalOpen: action.payload }
    case 'setDeleteTemplatesModalOpen':
      return { ...state, isDeleteTemplatesModalOpen: action.payload }
    case 'setSaveTemplateModalOpen':
      return { ...state, isSaveTemplateModalOpen: action.payload }
    case 'setSelectTemplateModalOpen':
      return { ...state, isSelectTemplateModalOpen: action.payload }
  }
}

export const TemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setTemplate = (template) => {
    dispatch({ type: 'setTemplate', payload: template })
  }

  const setCreateTemplateModalOpen = (isOpen) => {
    dispatch({ type: 'setCreateTemplateModalOpen', payload: isOpen })
  }

  const setEditTemplateModalOpen = (isOpen) => {
    dispatch({ type: 'setEditTemplateModalOpen', payload: isOpen })
  }

  const setDeleteTemplateModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteTemplateModalOpen', payload: isOpen })
  }

  const setDeleteTemplatesModalOpen = (isOpen) => {
    dispatch({ type: 'setDeleteTemplatesModalOpen', payload: isOpen })
  }

  const setSaveTemplateModalOpen = (isOpen) => {
    dispatch({ type: 'setSaveTemplateModalOpen', payload: isOpen })
  }

  const setSelectTemplateModalOpen = (isOpen) => {
    dispatch({ type: 'setSelectTemplateModalOpen', payload: isOpen })
  }

  return (
    <TemplateContext.Provider value={{
      ...state,
      setTemplate,
      setCreateTemplateModalOpen,
      setEditTemplateModalOpen,
      setDeleteTemplateModalOpen,
      setDeleteTemplatesModalOpen,
      setSaveTemplateModalOpen,
      setSelectTemplateModalOpen
    }}>
      {children}
    </TemplateContext.Provider>
  )
}
