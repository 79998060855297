const products = {
  search: 'search',
  campaignsV1: 'campaignsV1',
  campaigns: 'campaigns',
  reports: 'reports',
  irm: 'irm',
  communication: 'communication',
  payments: 'payments',
  socialMedia: 'socialMedia'
}

const homePage = {
  overview: 'overview',
  search: 'search',
  newSearch: 'newSearch',
  mySearches: 'mySearches',
  campaignsV1: 'campaignsV1',
  programs: 'programs',
  campaigns: 'campaigns',
  communication: 'communication',
  templates: 'templates',
  outreaches: 'outreaches',
  reports: 'reports',
  socialListening: 'socialListening',
  irm: 'irmOverview',
  irmOverview: 'irmOverview',
  irmProfiles: 'irmProfiles',
  irmInfluencers: 'irmInfluencers',
  irmLists: 'irmLists',
  irmLandingPages: 'irmLandingPages',
  socialMedia: 'socialInbox',
  socialInbox: 'socialInbox',
  socialAnalytics: 'socialAnalytics',
  socialPlanner: 'socialPlanner',
  socialBiolink: 'socialBiolink',
  socialAds: 'socialAds',
  payments: 'invoices'
}

export const INTEGRATIONS = {
  SHOPIFY: {
    type: 'ecommerce',
    name: 'shopify',
    logo: '/static/img/shopify/shopify_logo.svg'
  }
}

const navBarProducts = (t) => {
  return [
    {
      key: 'search',
      icon: 'discover',
      label: t('commons.topNavigation.discover.tabName'),
      children: [
        { key: 'search', label: t('commons.topNavigation.discover.dropdown.listItem.overview') },
        { key: 'mySearches', label: t('commons.topNavigation.discover.dropdown.listItem.mySearches') }
      ]
    },
    {
      key: 'irm',
      icon: 'contact',
      label: t('commons.topNavigation.influencers.tabName'),
      children: [
        { key: 'irmOverview', label: t('commons.topNavigation.influencers.dropdown.listItem.overview') },
        { key: 'irmProfiles', label: t('commons.topNavigation.influencers.dropdown.listItem.profiles') },
        { key: 'irmInfluencers', label: t('commons.topNavigation.influencers.dropdown.listItem.influencers') },
        { key: 'irmLists', label: t('commons.topNavigation.influencers.dropdown.listItem.lists') },
        { key: 'irmLandingPages', label: t('commons.topNavigation.recruiting.dropdown.listItem.influencers') }
      ]
    },
    {
      key: 'communication',
      icon: 'mail',
      label: t('commons.topNavigation.communication.tabName'),
      children: [
        { key: 'outreaches', label: t('commons.topNavigation.communication.dropdown.listItem.outreaches') },
        { key: 'templates', label: t('commons.topNavigation.communication.dropdown.listItem.templates') }
      ]
    },
    {
      key: 'campaignsV1',
      icon: 'calendar',
      label: t('commons.topNavigation.campaigns.tabName'),
      children: [
        { key: 'campaignsV1', label: t('commons.topNavigation.campaigns.tabName') },
        { key: 'programs', label: t('commons.topNavigation.campaigns.dropdown.listItem.programs') },
      ]
    },
    {
      key: 'reports',
      icon: 'barchart_line',
      label: t('commons.topNavigation.reporting.tabName'),
      children: [
        { key: 'reports', label: t('commons.topNavigation.reporting.dropdown.listItem.reports') },
        { key: 'socialListening', label: t('commons.topNavigation.reporting.dropdown.listItem.monitoring') },
      ]
    },
    {
      key: 'payments',
      icon: 'wallet',
      label: t('commons.topNavigation.payments.tabName'),
      children: [
        { key: 'payments', label: t('commons.topNavigation.payments.dropdown.listItem.invoices') }
      ]
    },
    {
      key: 'socialMedia',
      icon: 'overlapping',
      label: t('commons.topNavigation.social.tabName'),
      children: [
        { key: 'socialInbox', label: t('commons.button.socialMediaInbox') },
        { key: 'socialAnalytics', label: t('commons.button.analytics') },
        { key: 'socialPlanner', label: t('commons.button.planner') },
        { key: 'socialBiolink', label: t('commons.button.bioLinks') },
        { key: 'socialAds', label: t('commons.button.ads') },
      ]
    }
  ]
}

const networks = ['instagram', 'tiktok', 'youtube', 'twitter', 'twitch', 'others']

const networkMapValues = {
  instagram: 'Instagram',
  tiktok: 'TikTok',
  youtube: 'YouTube'
}

const orderedProducts = ['search', 'irm', 'communication', 'campaignsV1', 'campaigns', 'payments', 'reportsV2', 'socialListening', 'socialMedia']

const PATHS = {
  LOGO_SHORT: '/static/img/logo/influencity_logo_short.png',
  LOGO: '/static/img/logo/influencity.png',
  LOGO_SHORT_SVG: '/static/img/logo/influencity_logo_short.svg',
  LOGO_SVG: '/static/img/logo/influencity.svg'
}

const SECTIONS_HEIGHTS = {
  NAVBAR: 64,
  SUBNAVBAR: 79,
  TABS: 41
}

export const USER_INVITATION_STATES = {
  SUCCESS: '/static/img/success.png',
  ERROR: '/static/img/error.png',
  LOADING: '/static/img/animation/somethingload.gif'
}

export { homePage, products, navBarProducts, networks, networkMapValues, orderedProducts, PATHS, SECTIONS_HEIGHTS }

export const AVAILABLE_NETWORKS = ['instagram', 'youtube', 'tiktok', 'twitch']
export const OVERLAP_AVAILABLE_NETWORKS = ['instagram', 'youtube']

export const ENTITIES = {
  INFLUENCER: 'influencer',
  PROGRAM: 'program',
  USER: 'user',
  BRAND: 'brand',
  LIST: 'list',
  PROPOSAL: 'proposal',
  CAMPAIGN: 'campaign',
  REPORT: 'report',
  OUTREACH: 'outreach',
  ALERT: 'socialListeningAlert'
}

export const MODELS = {
  PROFILE_TABLE: 'profile_table',
  IRM_INFLUENCERS: 'irm_influencers',
  CAMPAIGNS: 'campaignbetas',
  IRM_LISTS: 'irm_lists',
  ENTITY_VIEWS: 'entity_views',
  ENTITY_FIELDS_GROUPS: 'entity_fields_groups',
  ENTITY_FIELDS: 'entity_fields',
  FORM_PAGES: 'form_pages',
  PROGRAMS: 'programs',
  OUTREACH: 'outreach',
  ALERT: 'socialListeningAlert',
  INVOICES: 'payment_invoices',
  INVOICES_PAYMENTS: 'payment_requests',
  INVOICES_POOLS: 'payment_pools',
}

export const VIEWS = {
  [MODELS.PROFILE_TABLE]: {
    table: 'table',
    link: 'link',
    profilesList: 'profilesList'
  },
  [MODELS.IRM_INFLUENCERS]: {
    table: 'table',
    link: 'link',
    linkToProgram: 'linkToProgram',
    programDetailTable: 'programDetailTable',
    influencersList: 'influencersList',
    linkToProgramV2: 'linkToProgramV2',
  },
  [MODELS.CAMPAIGNS]: {
    table: 'table',
    link: 'link'
  },
  [MODELS.IRM_LISTS]: {
    table: 'table',
    link: 'link',
    influencerListsTable: 'influencerListsTable'
  },
  [MODELS.ENTITY_VIEWS]: {
    table: 'table'
  },
  [MODELS.FORM_PAGES]: {
    table: 'table'
  },
  [MODELS.PROGRAMS]: {
    table: 'table',
    influencerProgramsTable: 'influencerProgramsTable',
    linkToIrmInfluencer: 'linkToIrmInfluencer'
  },
  [MODELS.ENTITY_FIELDS_GROUPS]: {
    table: 'table',
    link: 'link'
  },
  [MODELS.ENTITY_FIELDS]: {
    table: 'table'
  },
  [MODELS.OUTREACH]: {
    table: 'table'
  },
  [MODELS.ALERT]: {
    table: 'table'
  },
  [MODELS.INVOICES]: {
    table: 'table'
  },
  [MODELS.INVOICES_PAYMENTS]: {
    table: 'table'
  },
  [MODELS.INVOICES_POOLS]: {
    table: 'table'
  }
}

export const STREAMCHARTS_NETWORKS = ['twitch']
