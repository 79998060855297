import { useContext } from 'react'
import {
  CellWrapper,
  Date,
  Flex,
  Icon,
  InfluDateFormat,
  Spacing,
  Text
} from 'influ-dms'
import { useTranslate } from '~/hooks'
import { SocialListeningContext } from '../../../context'
import { IconWrapper, SentimentTag, StyledContainer, mentionTextStyles, noWrapStyles } from './styles'

const networkIconsColors = {
  facebook: '#3B5998',
  x: '#000000',
  twitter: '#000000',
  instagram: '#5A56CA',
  youtube: '#FF0000',
  pinterest: '#E60023',
  reddit: '#FF5700',
  tiktok: '#010101',
  tumblr: '#021A35',
  vk: '#0077FF',
  weibo: '#DF2029',
  note: '#B055C9',
  comments: '#69C9D0',
  global: '#F98300'
}

export const findNetworkIcon = (rawChannels) => {
  const otherNetworkIcons = { forum: 'comments', blog: 'note', news: 'global' }
  const channelName = rawChannels.find(channel => otherNetworkIcons[channel])
  return channelName ? otherNetworkIcons[channelName] : 'global'
}

export const AlertMentionsListTableTdMention = ({
  row: { original },
  onMentionClick,
}) => {
  const { t } = useTranslate()
  const { author, body, handle, network, publishedDate, sentimentRaw, site, rawChannels } = original
  const { mention } = useContext(SocialListeningContext)
  const networkIconName = network === 'other' ? findNetworkIcon(rawChannels) : network === 'Twitter' ? 'x' : network?.toLowerCase()

  const sentimentLabels = {
    0: 'neutral',
    p: 'positive',
    n: 'negative'
  }

  return (
    <CellWrapper>
      <StyledContainer column onClick={() => onMentionClick(original)} selected={mention?._id === original._id}>
        <Flex justify='space-between' align='center' gap='8px'>
          <Flex align='center' gap='4px'>
            <IconWrapper color={networkIconsColors[networkIconName]}>
              <Icon name={networkIconName} size='16' color='neutral.white' />
            </IconWrapper>
            <Text size='14' color='primary.dark' bold>
              {author || site}
            </Text>
          </Flex>
          <Flex align='center' gap='8px'>
            <SentimentTag color={sentimentRaw === 'p' ? 'state.active' : sentimentRaw === '0' ? 'primary.light3' : 'state.dangerDefault'}>
              {t(`entity.mention.field.sentiments.value.${sentimentLabels[sentimentRaw]}.label`)}
            </SentimentTag>
            <Text size='14' color='primary.light2' styles={noWrapStyles}>
              <Date format={InfluDateFormat().LL}>{publishedDate}</Date>
            </Text>
          </Flex>
        </Flex>
        <Flex align='center' gap='4px'>
          {handle && <Text size='14' color='primary.light2' bold>{`@${handle}`}</Text>}
          {handle && <Text size='14' color='primary.light2' bold>{t('·')}</Text>}
          <Text size='14' color='primary.light2'>{site}</Text>
        </Flex>
        <Spacing size='8' />
        <Text
          size='12'
          color='primary.light1'
          dangerouslySetInnerHTML={{ __html: body?.replace(/<img[^>]*>/g, '') }}
          styles={mentionTextStyles}
        />
      </StyledContainer>
    </CellWrapper>
  )
}
