import { CellWrapper, HoverAction, HoverWrapper, Text, Tooltip } from 'influ-dms'
import { useTranslate } from '~/hooks'
import {
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles
} from './styles'

export const ViewsListTableTdViewName = ({
  row: { original },
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslate()
  const view = original || {}
  const { entityViewName, type } = view
  const isDisabled = type === 'readOnly' || type === 'default'

  const handleEditClick = (e) => {
    if (!isDisabled) {
      onEditClick(e)
    }
  }

  const handleDeleteClick = (e) => {
    if (!isDisabled) {
      onDeleteClick(e)
    }
  }

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <Text ellipsis showTooltip size='14' color='primary.light2' bold>
            {entityViewName}
          </Text>
        </StyledContainer>
        <HoverWrapper>
          {onEditClick && <Tooltip
            text={isDisabled ? t('commons.button.edit.view.disable.tooltip') : t('commons.button.edit.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={handleEditClick} iconName='edit' disabledIcon={isDisabled} />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={isDisabled ? t('commons.button.delete.view.disable.tooltip') : t('commons.button.delete.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={handleDeleteClick} iconName='trash' disabledIcon={isDisabled} />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
