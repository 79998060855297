import { createContext, useEffect, useState } from 'react'
import { get, xor } from 'lodash'
import { useSubscription, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { notifyProfileDataProcesssed } from '~/containers/irm/apollo/notifyProfileDataProcesssed'
import { useArray, USE_ARRAY_ACTIONS } from '~/hooks'
import { getProfilesInInitialState } from '../../../containers/irm/apollo/getProfilesInInitialState'
import { useDidMountEffect } from '../../../hooks/useDidMountEffect'
import { useCleanCache } from '../../../containers/irm/commons/hooks/useCleanCache'
import { self } from '../../../graphql'

export const NotifyProfileDataProcessedContext = createContext({ value: {} })

const NotifyProfileDataProcessedProvider = (props) => {
  const [profilesLoading, setProfilesLoadingArray] = useArray([])
  const [profilesChanges, setProfilesChanges] = useState()
  const [hasErrorOnRequest, setHasErrorOnRequest] = useState()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const isLogout = router && router.pathname && router.pathname.includes('logout')
  const skip = !brandId || isPublic
  const { data: selfData } = useQuery(self, { skip })
  const { data, refetch } = useQuery(getProfilesInInitialState, { variables: { brandId }, skip: !brandId || isPublic || isLogout })
  const { clearProfileDetailCache } = useCleanCache()

  const setProfilesLoading = (newProfilesLoading, action, subscriptionStatus) => {
    const notLoading = xor(profilesLoading, newProfilesLoading)
    if (notLoading?.length !== 0 && subscriptionStatus === 'metricsCompleted') {
      setProfilesChanges(new Date().getTime())
    }
    setProfilesLoadingArray(newProfilesLoading, action)
  }

  useEffect(() => {
    if (data) {
      setProfilesLoading(data.getProfilesInInitialState, USE_ARRAY_ACTIONS.DEFAULT)
    }
  }, [data])

  const { data: dataProfileProcessed } = useSubscription(notifyProfileDataProcesssed, {
    variables: { brandId },
    skip: !brandId || isPublic
  })

  useDidMountEffect(() => !isLogout && brandId && refetch(), [brandId])

  useEffect(() => {
    const userId = get(selfData, 'self.id')
    const userIdNotification = get(dataProfileProcessed, 'notifyProfileDataProcesssed.userId')
    const profileId = get(dataProfileProcessed, 'notifyProfileDataProcesssed.profileId')
    const profileIdsBefore = get(dataProfileProcessed, 'notifyProfileDataProcesssed.profileIdsBefore')
    const newProfilesLoading = get(dataProfileProcessed, 'notifyProfileDataProcesssed.profilesLoading')
    const subscriptionStatus = get(
      dataProfileProcessed,
      'notifyProfileDataProcesssed.subscriptionStatus'
    )

    if (['metricsCompleted'].includes(subscriptionStatus)) {
      const notLoading = xor(profilesLoading, newProfilesLoading)
      if (notLoading?.length > 0) {
        notLoading.forEach((profileId) => {
          clearProfileDetailCache(profileId)
        })
      }
      if (newProfilesLoading) setProfilesLoading(newProfilesLoading, USE_ARRAY_ACTIONS.DEFAULT, subscriptionStatus)
      if (profileId) {
        clearProfileDetailCache(profileId)
      }
      if (profileIdsBefore) {
        profileIdsBefore.forEach((profileId) => {
          clearProfileDetailCache(profileId)
        })
      }
    } else if (['start'].includes(subscriptionStatus) && userId !== userIdNotification) {
      if (newProfilesLoading) setProfilesLoading(newProfilesLoading, USE_ARRAY_ACTIONS.DEFAULT, subscriptionStatus)
    }
  }, [dataProfileProcessed])

  return (
    <NotifyProfileDataProcessedContext.Provider
      value={{ profilesLoading, setProfilesLoading, hasErrorOnRequest, setHasErrorOnRequest, profilesChanges, setProfilesChanges }}
    >
      {props.children}
    </NotifyProfileDataProcessedContext.Provider>
  )
}

export { NotifyProfileDataProcessedProvider }
