import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { useFilter } from '../../../../irm/commons/hooks/useFilter'
import { useCleanCache } from '../../../../irm/commons/hooks'
import { useFilterQuery } from '../../../../irm/commons/hooks/useFilterQuery'
import {
  addEntityField as addEntityFieldCall,
  getEntityFieldsByBrand,
  deleteEntityFields as deleteEntityFieldsCall,
  editEntityField as editEntityFieldCall,
  duplicateEntityField as duplicateEntityFieldCall,
  countEntityFieldsByBrand,
  getGroupedEntityFieldsByEntity
} from '../apollo'
import { SnackbarContext, useCreateEditForm } from 'influ-dms'
import { useTranslate } from '~/hooks'
import { useContext, useState } from 'react'
import { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse } from '../../../../../constants/forms/getFormResponse'
import { captureException } from '@sentry/nextjs'

export const useProperties = () => {
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)
  const {
    clearEntityFieldsTableCache,
    clearInfluencerTableCache, clearInfluencerDetailCache,
    clearOutreachTableCache, clearOutreachDetailCache,
    clearApplicationFormCache, clearEntityFieldGroupsTableCache, clearConfigFiltersCache, clearMassiveCommTokensCache,
    clearOutreachInProgramV2TableCache
  } = useCleanCache()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const {
    loadingData: loadingEntityFields,
    loadingCount: loadingCountEntityFields,
    setQueryOptions: setTablePropertiesOptions,
    setCountQueryOptions: setCountTablePropertiesOptions
  } = useFilterQuery({
    query: getEntityFieldsByBrand,
    queryAccessor: 'getEntityFieldsByBrand',
    countQuery: countEntityFieldsByBrand,
    countQueryAccessor: 'countEntityFieldsByBrand'
  })

  const cleanInfluencerCaches = () => {
    const FORM_ID = 'influencer'
    const TABLE_NAME = 'irm_influencers'
    clearInfluencerTableCache({ evictCount: false })
    clearInfluencerDetailCache()
    clearApplicationFormCache(FORM_ID)
    clearConfigFiltersCache(brandId, TABLE_NAME)
    clearMassiveCommTokensCache()
  }

  const cleanOutreachCaches = () => {
    const FORM_ID = 'outreach'
    const TABLE_NAME = 'outreach'
    clearOutreachTableCache({ evictCount: false })
    clearOutreachInProgramV2TableCache({ evictCount: false })
    clearOutreachDetailCache()
    clearApplicationFormCache(FORM_ID)
    clearConfigFiltersCache(brandId, TABLE_NAME)
    clearMassiveCommTokensCache()
  }

  const [groupedEntityFieldsOptions, setGroupedEntityFieldsOptions] = useState({ skip: true })
  const { data: groupedEntityFields, loading: loadingGroupedEntityFields } = useQuery(getGroupedEntityFieldsByEntity, groupedEntityFieldsOptions)

  const [addEntityFieldMutation, { loading: loadingAddEntityField }] = useMutation(addEntityFieldCall, {
    update (cache) {
      clearEntityFieldsTableCache()
      clearEntityFieldGroupsTableCache()
      cleanInfluencerCaches()
      cleanOutreachCaches()
      cache.gc()
    }
  })

  const [deleteEntityFieldsMutation, { loading: loadingDeleteEntityFields }] = useMutation(deleteEntityFieldsCall, {
    update (cache) {
      clearEntityFieldsTableCache()
      clearEntityFieldGroupsTableCache()
      cleanInfluencerCaches()
      cleanOutreachCaches()
      cache.gc()
    },
  })

  const [editEntityFieldMutation, { loading: loadingEditEntityField }] = useMutation(editEntityFieldCall, {
    update (cache, _, { variables }) {
      variables?._id && clearEntityFieldsTableCache(cache, variables._id)
      clearEntityFieldsTableCache({ evictCount: false })
      clearEntityFieldGroupsTableCache()
      cleanInfluencerCaches()
      cleanOutreachCaches()
      cache.gc()
    },
  })

  const [moveEntityFieldToGroupMutation, { loading: loadingMoveEntityFieldToGroup }] = useMutation(editEntityFieldCall, {
    update (cache) {
      clearEntityFieldsTableCache({ evictCount: false })
      clearEntityFieldGroupsTableCache()
      cleanInfluencerCaches()
      cleanOutreachCaches()
      cache.gc()
    },
  })

  const [duplicateEntityFieldMutation, { loading: loadingDuplicateEntityField }] = useMutation(duplicateEntityFieldCall, {
    update (cache) {
      clearEntityFieldsTableCache()
      clearEntityFieldGroupsTableCache()
      cleanInfluencerCaches()
      cleanOutreachCaches()
      cache.gc()
    },
  })

  const { sendForm } = useCreateEditForm()
  const { getFilteredDataOptions } = useFilter()

  const getEntityFields = async (filterParams, entityName, view = 'table') => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, entityName, view },
        { brandId, entityName, view }
      )
      setTablePropertiesOptions(options)
      page === 0 && setCountTablePropertiesOptions(countOptions)
    } catch (e) {
      captureException('Error obtaining entity field', e)
    }
  }

  const getGroupedEntityFields = (_id, entity, sharedId) => {
    try {
      const options = {
        variables: {
          entity,
          ...(sharedId) && { sharedId },
          ...(!sharedId) && {
            brandId,
            entityId: _id
          }
        },
        skip: false
      }
      setGroupedEntityFieldsOptions(options)
    } catch (e) {
      captureException('Error obtaining grouped entity fields', e)
    }
  }

  const addEntityField = async () => {
    try {
      const response = await sendForm(addEntityFieldMutation, false, { origin: 'custom' }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error adding entity field', e)
      return false
    }
  }

  const deleteEntityFields = async (entityFieldIds) => {
    const variables = { entityFieldIds }
    try {
      const response = await deleteEntityFieldsMutation({ variables })
      const count = entityFieldIds?.length
      showSnackbar('success', t(count > 1 ? 'commons.snackbar.success.deleteProperties' : 'commons.snackbar.success.deleteProperty', { count }))
      return response
    } catch (e) {
      captureException('Error deleting entity field', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const editEntityField = async (entityFieldId) => {
    try {
      const response = await sendForm(editEntityFieldMutation, true, {
        brandId,
        _id: entityFieldId,
      }, { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse })
      return response
    } catch (e) {
      captureException('Error editing entity field', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const moveEntityFieldToGroup = async (entityFieldId) => {
    const variables = { _id: entityFieldId }
    try {
      const response = await moveEntityFieldToGroupMutation({ variables })
      return response
    } catch (e) {
      captureException('Error moving entity field', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  const duplicateEntityField = async (entityFieldId) => {
    const variables = { _id: entityFieldId }
    try {
      const response = await duplicateEntityFieldMutation({ variables })
      showSnackbar('success', t('commons.snackbar.success.duplicateProperty'))
      return response
    } catch (e) {
      captureException('Error duplicating entity field', e)
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      return false
    }
  }

  return {
    getEntityFields,
    loadingEntityFields,
    loadingCountEntityFields,
    addEntityField,
    loadingAddEntityField,
    deleteEntityFields,
    loadingDeleteEntityFields,
    editEntityField,
    loadingEditEntityField,
    duplicateEntityField,
    loadingDuplicateEntityField,
    moveEntityFieldToGroup,
    loadingMoveEntityFieldToGroup,
    getGroupedEntityFields,
    groupedEntityFields,
    loadingGroupedEntityFields
  }
}
