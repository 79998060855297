import { gql } from '@apollo/client'

export const checkAddProfileToTracking = gql`
 query checkAddProfileToTracking($account: String!, $network: String!, $brandId: String!, $trackingId: String) {
    checkAddProfileToTracking(account: $account, network: $network, brandId: $brandId,trackingId: $trackingId){
    profileUrl
    username
    profilePicture
    exists
    private
    error
    apiAccess
    }
 }
 `
