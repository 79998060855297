import { gql } from '@apollo/client'

export const editAmount = gql`
  mutation editAmount(
    $paymentInvoiceId: String!
    $paymentRequestIds: [String]
    $amount: Float
  ) {
    editAmount(
      paymentInvoiceId: $paymentInvoiceId
      paymentRequestIds: $paymentRequestIds
      amount: $amount
    ){
      message
      code
      success
      data
    }
  }
`
