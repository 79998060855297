import { gql } from '@apollo/client'

export const scrapPublicationDataMutation = gql`
  mutation scrapPublicationData (
    $postId: String!
  ) {
  scrapPublicationData (
    _id: $postId
  ) {
      message
      success
    }
 }
`
export const scrapAllPublicationsByReportMutation = gql`
  mutation scrapAllPublicationsByReport (
    $reportV2Id: String!
  ) {
  scrapAllPublicationsByReport (
    reportV2Id: $reportV2Id
  ) {
      message
      success
    }
 }
`
export const scrapPublicationsBulkMutation = gql`
  mutation scrapPublicationsBulk (
    $ids: [String]!
  ) {
    scrapPublicationsBulk (
      ids: $ids
  ) {
      message
      success
    }
 }
`
