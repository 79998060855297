import { gql } from '@apollo/client'

export const editIrmInfluencer = gql`
  mutation editIrmInfluencer(
    $_id: String!
    $alias: String!
    $firstName: String
    $lastName: String
    $emails: [FieldWithPrimaryOptionInputType]
    $phoneNumbers: [FieldWithPrimaryOptionInputType]
    $emailSubscription: String
    $customFields: [JSON]
  ) {
    editIrmInfluencer(
      _id: $_id
      alias: $alias
      firstName: $firstName
      lastName: $lastName
      emails: $emails
      phoneNumbers: $phoneNumbers
      emailSubscription: $emailSubscription
      customFields: $customFields
    ) {
      message
      code
      success
    }
  }
`
