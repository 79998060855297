import { useContext } from 'react'
import { AvatarContactItem } from '../AvatarContactItem'
import { Text } from 'influ-dms'
import { bottomPositionStyles, topPositionStyles } from '../styles'
import { ModalsContext } from '../../../../commons/context'
import { useTranslate } from '~/hooks/useTranslate'

export const AvatarContactAllAccounts = () => {
  const modalsContext = useContext(ModalsContext)
  if (!modalsContext) return <></>

  const { dispatch } = modalsContext
  const { t } = useTranslate()

  const handleClick = () => {
    dispatch({ type: 'toggleMultiBrand' })
  }
  return (
    <AvatarContactItem styles={[topPositionStyles, bottomPositionStyles]} handleClick={handleClick}>
      <Text bold color='primary.dark'>{t('commons.topNavigation.user.dropdown.brandSelector.changeAccounts.dropdown.allAccounts.link')}</Text>
    </AvatarContactItem>
  )
}
