import pathToRegexp from 'path-to-regexp'
import { isEmpty } from 'lodash'
import { TRIAL_ROUTES as trialRoutes } from './routesConstants'
import axios from 'axios'
import getConfig from 'next/config'
import { setTraceDurations, setTraceSection } from '../helpers/traceInfo/traceInfo'
const { publicRuntimeConfig } = getConfig()
const { webRestUri } = publicRuntimeConfig

let sessionPageCount = 0
let pageTotalCount = typeof window !== 'undefined' ? localStorage.getItem('page-total-count') || 0 : 0
if (typeof window !== 'undefined' && !pageTotalCount) localStorage.setItem('page-total-count', 0)

let sessionEventCount = 0
let eventTotalCount = typeof window !== 'undefined' ? localStorage.getItem('event-total-count') || 0 : 0
if (typeof window !== 'undefined' && !eventTotalCount) localStorage.setItem('event-total-count', 0)

class _RouterHelper {
  constructor () {
    this.routesMap = this.initRoutesMap()
    this.fallbackLanguage = 'en'
  }

  _getRoute (routeName, params = {}, traceId = undefined, qs = {}) {
    const routePath = this.routesMap.get(routeName)
    const route = new _Route(routeName, routePath) || {}

    let url = route.toPath(
      Object.assign({}, params, {
        language: params.language || this.fallbackLanguage
      })
    )

    if (!isEmpty(qs)) {
      url += `?${new URLSearchParams(qs).toString()}`
    }

    return { route, url }
  }

  /**
   * @param routeName string
   * @param params object
   * @param traceId string
   * @param qs
   * @returns {*} string
   */
  getUrl (routeName, params = {}, traceId = undefined, qs = {}) {
    const { url } = this._getRoute(routeName, params, traceId, qs)
    return url
  }

  /**
   * @param routeName string
   * @param params object
   * @param traceId string
   * @param qs
   * @returns {*} string
   */
  getRoute (routeName, params = {}, traceId = undefined, qs = {}) {
    const { route, url } = this._getRoute(routeName, params, traceId, qs)
    this.traceRouteEvent(route.folder, { ...params, ...qs }, traceId, 'nav_route_')
    return url
  }

  /**
   * @param traceId
   * @param params
   */
  traceEvent (traceId, params) {
    this.traceRouteEvent(window?.location?.href, params, traceId, 'nav_event_')
  }

  /**
   * @param routeName
   * @param params
   */
  traceRoute (routeName, params) {
    const routePath = this.routesMap.get(routeName)
    const route = new _Route(routeName, routePath) || {}
    this.traceRouteEvent(route.name, params, undefined, 'nav_route_')
  }

  sendToAxios = async (variables, endpoint) => {
    // if (!ip) ip = await axios.get(getIpUrl) || 'error'
    axios
      .post(webRestUri + '/trace/' + endpoint, variables)
      .then(function (response) {
        // console.debug('Trace ' + endpoint + ' saved', variables, response)
      })
      .catch(function (error) {
        console.error('Trace ' + endpoint + ' not saved', variables, error)
      })
  }

  /**
   * @param routeName string
   * @param params object
   * @param traceId string
   * @param prefix string
   */
  traceRouteEvent (routeName, params, traceId, prefix) {
    if (typeof window !== 'undefined') {
      const userId = localStorage?.getItem('user-id')
      const brandId = localStorage?.getItem('brand-id')

      const isEnterprise = localStorage.getItem('is-enterprise') === 'true'
      const isTrial = localStorage.getItem('is-trial') === 'true'
      const idAnalytics = localStorage.getItem('idAnalytics')

      const traceParams = {
        routeName,
        params,
        traceId,
        prefix,
        userId,
        brandId,
        isTrial,
        isEnterprise,
        idAnalytics
      }
      let endpoint
      if (prefix === 'nav_event_') {
        sessionEventCount++
        eventTotalCount++
        localStorage.setItem('event-total-count', eventTotalCount)

        traceParams.sessionEventCount = sessionEventCount
        traceParams.eventTotalCount = eventTotalCount
        endpoint = 'addEvent'
      } else {
        sessionPageCount++
        pageTotalCount++
        localStorage.setItem('page-total-count', pageTotalCount)
        traceParams.sessionPageCount = sessionPageCount
        traceParams.pageTotalCount = pageTotalCount
        endpoint = 'addPage'
        this.traceHubspotRouteEvent(routeName) // hubspot
      }
      setTraceSection(traceParams)
      setTraceDurations(traceParams)

      this.sendToAxios(traceParams, endpoint)
    }
  }

  /**
   * @param routeName string
   */
  traceHubspotRouteEvent (routeName) {
    if (typeof window !== 'undefined') {
      const _hsq = (window._hsq = window._hsq || [])
      _hsq.push(['setPath', window.location.href])
      _hsq.push(['trackPageView'])
    }
  }

  /**
   * If the pathname is in the array of trial paths, return a meta tag with the content of 'noindex'
   * @param pathname - The pathname of the current page.
   * @returns A meta tag with the name 'robots' and the content 'noindex'
   */
  injectMetaOnTrialPaths (pathname) {
    const pathIntoArray = trialRoutes.filter((trial) => trial.path === pathname)
    if (isEmpty(pathIntoArray)) {
      return <meta name='robots' content='noindex' />
    }
  }

  /**
   * It takes a locale and a boolean value and returns a path
   * @param locale - The locale of the user.
   * @param [isThankYou=false] - boolean - if true, the thank you page will be returned
   * @returns The path to the trial page.
   */
  goToTrialPath (locale, isThankYou = false) {
    const pathIntoArray = trialRoutes.filter((trial) => trial.locale === locale)[0]
    if (pathIntoArray && Object.keys(pathIntoArray).length > 0) {
      return isThankYou ? pathIntoArray.thankYouName : pathIntoArray.name
    }
  }

  /**
   * @returns {Map<any, any>}
   */
  initRoutesMap () {
    const _data = new Map()

    trialRoutes.forEach(({ name, path, thankYouName, thankYouPath }) => {
      if (name && path) _data.set(name, path)
      if (thankYouName && thankYouPath) _data.set(thankYouName, thankYouPath)
    })

    _data
      .set('index', '/')
      .set('login', '/login')
      .set('logout', '/logout')
      .set('resetPassword', '/reset-password')
      .set('landing', '/landing/:token')
      .set('newOrganization', '/new-organization/:token')

      // search
      .set('search', '/search/:brandId/overview')
      .set('mySearches', '/search/:brandId/mySearches')
      .set('searchCategories', '/search/:brandId/categories')
      .set('searchResults', '/search/:brandId/results/:network/:filterParams')

      // campaigns v1
      .set('campaignsV1', '/campaignsV1/:brandId/overview')
      .set('campaignV1Detail', '/campaignsV1/:brandId/detail/:campaignId')
      .set('programs', '/campaignsV1/:brandId/programs')
      .set('program', '/campaignsV1/:brandId/programs/:programId')

      // reports
      .set('reportsV2', '/reports/:brandId/overview')
      .set('reportsV2Dashboard', '/reports/:brandId/report/:reportId/dashboard')
      .set('reportsV2DashboardPublic', '/shared/:sharedId/report/public')
      .set('reportsV2DashboardPrint', '/reports/:brandId/report/:reportId/dashboard/print')
      .set('reportsV2Posts', '/reports/:brandId/report/:reportId/posts')
      .set('reportsV2Influencers', '/reports/:brandId/report/:reportId/influencers')
      .set('reportsV2Settings', '/reports/:brandId/report/:reportId/settings')

      // socialListening
      .set('socialListening', '/socialListening/:brandId/alerts')
      .set('socialListeningAlert', '/socialListening/:brandId/alerts/:alertId')

      // settings
      .set('basicInfo', '/settings/:brandId/basicInfo')
      .set('security', '/settings/:brandId/security')
      .set('communication', '/settings/:brandId/communication')
      .set('accountDefault', '/settings/:brandId/account')
      .set('productsAndBilling', '/settings/:brandId/productsAndBilling')
      .set('branding', '/settings/:brandId/branding')
      .set('users', '/settings/:brandId/users')
      .set('domains', '/settings/:brandId/domains')
      .set('views', '/settings/:brandId/views')
      .set('properties', '/settings/:brandId/properties')
      .set('integrations', '/settings/:brandId/integrations')
      // .set('properties', '/settings/:brandId/properties')
      // .set('objects', '/settings/:brandId/objects')

      // errors
      .set('noSubscription', '/no-subscription/:brandId')
      .set('noBrand', '/data-error')
      .set('oops', '/oops/:brandId')
      .set('error', '/error')
      .set('networkError', '/network-error/public')
      .set('limitReached', '/limit-reached/public')
      .set('shareExpired', '/share-expired/public')

      // payment
      .set('subscriptionForm', '/payment/:brandId/subscriptionForm')
      .set('upgradeSubscription', '/payment/:brandId/upgradeSubscription')
      .set('pricesAndPlans', '/payment/:brandId/pricesAndPlans')
      .set('pricesAndPlansPublic', '/payment/pricesAndPlans/public')
      .set('pricesAndPlansIframe', '/payment/pricesAndPlans/iframe')

      // scraper health
      .set('bigdrsHealth', '/bigdrsHealth')
      .set('bigdrsHealthNetwork', '/bigdrsHealthNetwork/:network')

      // maintenanceMode
      .set('maintenanceMode', '/maintenance-mode/public')

      // home
      .set('home', '/home/:brandId')

      // irm
      .set('irmOverview', '/irm/:brandId/overview')
      .set('irmProfiles', '/irm/:brandId/profiles')
      .set('irmInfluencers', '/irm/:brandId/influencers')
      .set('irmLists', '/irm/:brandId/lists')
      .set('irmInfluencer', '/irm/:brandId/influencers/:influencerId')
      .set('irmList', '/irm/:brandId/lists/:listId')
      .set('irmInfluencerPublic', '/shared/:sharedId/influencer/public')
      .set('irmInfluencerPrint', '/irm/:brandId/influencers/:influencerId/print')
      .set('irmListPrint', '/irm/:brandId/lists/:listId/print')
      .set('irmListPublic', '/shared/:sharedId/list/public')
      .set('irmLandingPages', '/irm/:brandId/landingPages')
      .set('profilePublic', '/shared/:sharedId/profile/public')
      .set('profilePrint', '/irm/:brandId/profile/:profileId/print')

      // communication
      .set('outreaches', '/communication/:brandId/outreaches')
      .set('outreach', '/communication/:brandId/outreaches/:outreachId')
      .set('outreachPrint', '/communication/:brandId/outreaches/:outreachId/print')
      .set('templates', '/communication/:brandId/templates')

      // unsubscribe
      .set('unsubscribe', '/influencer-email-subscription/subscription')

      // social media
      .set('socialInbox', '/social/:brandId/inbox')
      .set('socialAnalytics', '/social/:brandId/analytics')
      .set('socialPlanner', '/social/:brandId/planner')
      .set('socialBiolink', '/social/:brandId/biolinks')
      .set('socialAds', '/social/:brandId/ads')

      // payments
      .set('invoices', '/payments/:brandId/invoices')
      .set('invoice', '/payments/:brandId/invoices/:invoiceId')

    return _data
  }
}

class _Route {
  /**
   * @param name string
   * @param pattern string
   * @param page string
   */
  constructor (name, pattern, page = name) {
    if (!name && !page) {
      throw new Error(`Missing page to render for route "${pattern}"`)
    }

    this.name = name
    this.pattern = pattern || `/${name}`
    this.page = page.replace(/(^|\/)index$/, '').replace(/^\/?/, '/')
    this.regex = pathToRegexp(this.pattern, (this.keys = []))
    this.toPath = pathToRegexp.compile(this.pattern)
    const parseFolder = this.parseToFolder(pattern)
    if (!isEmpty(parseFolder) && pattern) {
      this.folder = pattern
      for (const [key, value] of Object.entries(parseFolder)) {
        this.folder = this.folder.replace(key, value)
      }
    }
  }

  /**
   * @param path string
   * @returns {*}
   */
  match (path) {
    const values = this.regex.exec(path)
    if (values) {
      return this.valuesToParams(values.slice(1))
    }
  }

  /**
   * @param values array
   * @returns {*}
   */
  valuesToParams (values) {
    return values.reduce((params, val, i) => {
      if (val === undefined) return params
      return Object.assign(params, {
        [this.keys[i].name]: decodeURIComponent(val)
      })
    }, {})
  }

  /**
   * @param path string
   * @returns {*}
   */
  parseToFolder (path) {
    const values = this.regex.exec(path)
    if (values) {
      return this.valuesToFolder(values.slice(1))
    }
  }

  /**
   * @param values array
   * @returns {*}
   */
  valuesToFolder (values) {
    return values.reduce((params, val, i) => {
      if (val === undefined) return params
      return Object.assign(params, {
        [':' + this.keys[i].name]: '[' + this.keys[i].name + ']'
      })
    }, {})
  }
}

export const RouterHelper = new _RouterHelper()
