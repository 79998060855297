import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../../styles'

export const AddPostsManualInstagramStoryInfo = () => {
  const { t } = useTranslate()

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationInfo.title')}</Text>
      <Spacing size='24' />
      <Form.InputFile name='image' deletedName='deletedImages' changeId='publicationId' label={t('commons.insights.image.input.label')} type='file' accept='image/png, image/jpeg' />
      <Spacing size='24' />
      <Form.InputText placeholder={t('commons.insights.description.input.placeholder')} name='caption' label={t('commons.insights.description.input.label')} />
      <Spacing size='24' />
      <Form.InputDate placeholder={t('commons.insights.publicationDate.input.placeholder')} name='publicationDate' label={t('commons.insights.publicationDate.input.label')} />
      <Spacing size='24' />
      <Form.InputText placeholder={t('commons.insights.stickers.input.placeholder')} name='stickers' label={t('commons.insights.stickers.input.label')} />
      <Spacing size='24' />
      <Form.InputText placeholder={t('commons.insights.swipeUp.input.placeholder2')} name='swipeUrl' label={t('commons.insights.swipeUp.input.label')} />
      <Spacing size='8' />
    </SideBox>
  )
}
