import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { get } from 'lodash'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

export const brandBasicQuery = gql`
  query brandBasicQuery(
    $brandId: String!,
  ) {
      brand(_id: $brandId) {
        id: _id
        logoImage
        brandName
      }
    }
`

const BrandBasic = (props) => {
  const brandId = get(props, 'router.query.brandId')

  return (
    <Query
      query={brandBasicQuery}
      variables={{ brandId }}
      skip={!brandId}
    >
      {(data) => {
        const loading = get(data, 'loading')
        const brand = get(data, 'data.brand')
        const error = get(data, 'error')
        return props.children({ loading, brand, error })
      }}
    </Query>
  )
}

BrandBasic.propTypes = {
  children: PropTypes.func.isRequired
}

const BrandBasicWR = withRouter(BrandBasic)
export { BrandBasicWR as BrandBasic }
