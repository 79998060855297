import { gql } from '@apollo/client'

export const addBulkTasks = gql`
 mutation addBulkTasks(
  $campaignId: String!
  $influencerIds: [String]!
  $tasksData: [BulkTaskDataType]
 ) {
  addBulkTasks(
    campaignId : $campaignId
    influencerIds: $influencerIds
    tasksData: $tasksData
   ){
      message
      success
    }
 }
 `
