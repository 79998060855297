import { Button, Flex, Modal, Spacing, StateButton, Text } from 'influ-dms'
import { DeletePostsMutation } from '../deletePosts'
import { useTranslate } from '~/hooks/useTranslate'

export const DeletePost = ({ postId, isOpen, onSuccess, onClose }) => {
  const { t } = useTranslate()
  const onDelete = (mutationHandler, loading) => {
    if (loading) return
    mutationHandler({ ids: [postId] })
      .then(() => {
        if (onSuccess) onSuccess()
        onClose()
      })
      .catch(() => {
      })
  }

  return (
    <DeletePostsMutation>
      {({ mutationHandler, loading }) => {
        return (
          <Modal
            name='deletePost'
            color='state.dangerDefault'
            onClose={onClose}
            isOpen={isOpen}
            title={t('reports.modal.detelePublication.titleHeader1')}
            height='250'
            width='500'
          >
            <Flex column grow='1'>
              <Text size='14'>{t('reports.modal.detelePublication.titleParagraph1')}</Text>
            </Flex>
            <Flex>
              <StateButton
                loading={loading}
                showLoader
                color='danger'
                name='deletePost'
                onClick={() => onDelete(mutationHandler, loading)}
              >{t('commons.button.confirm')}
              </StateButton>
              <Spacing size='16' vertical />
              <Button name='cancelDeletePost' color='secondary' variant='flat' onClick={onClose}>{t('commons.button.cancel')}</Button>
            </Flex>
          </Modal>
        )
      }}
    </DeletePostsMutation>
  )
}
