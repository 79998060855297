import { useContext, useEffect, useState } from 'react'
import { Flex } from 'influ-dms'
import { useTranslate, useBrandData } from '~/hooks'
import { useLanguageContext } from '~/context/Language'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { ModalsContext } from '../../../../../commons/context'
import { CommunicationContext } from '../../../../../commons/communication/CommunicationContext'
import { LimitsContext } from '../../../../../../context'
import { BottomCenteredModal } from '../../../../../commons/communication/setup/modal/BottomCenteredModal'
import { TemplateContext } from '../../../../../communication/templates/context/TemplateContext'
import { SelectTemplate } from '../../../../../communication/templates/modals/selectTemplate/SelectTemplate'
import { SaveTemplateModal } from '../../../../../communication/templates/modals/SaveTemplateModal'
import { SendMassiveEmailOptionsModal } from './SendMassiveEmailOptionsModal'
import { SendMassiveEmailTemplateOptionsModal } from './SendMassiveEmailTemplateOptionsModal'
import { SendMassiveEmailBulkEmailModal } from './SendMassiveEmailBulkEmailModal'
import { SendMassiveEmailModalHeader } from './SendMassiveEmailModalHeader'
import { modalContainerStyles } from './styles'

export const SendMassiveEmailModal = ({ isOpen, onClose }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const { state, dispatch } = useContext(ModalsContext)
  const influencers = get(state, 'influencers')
  const useEmails = get(state, 'useEmails')
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedTemplateOption, setSelectedTemplateOption] = useState(null)
  const [step, setStep] = useState(1)
  const [modalTitle, setModalTitle] = useState(t('modal.selectEmailType.header'))
  const [influencersWithoutEmail, setInfluencersWithoutEmail] = useState([])
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [errorInfo, setErrorInfo] = useState(null)

  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { accountSettings } = useContext(CommunicationContext)

  const { featureFlags } = useBrandData({ variables: { brandId }, skip: !brandId })
  const hasCommunicationFF = get(featureFlags, 'irm.communication')
  const signature = get(accountSettings, 'signature')
  const signatureAttachments = get(accountSettings, 'signatureAttachments')
  const isAccountConnected = get(accountSettings, 'status.code') === 'CONNECTED' && !get(accountSettings, 'disabled')
  const { validateLimit } = useContext(LimitsContext)

  const { isSelectTemplateModalOpen, setSelectTemplateModalOpen } = useContext(TemplateContext)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [loadingSelectedTemplate, setLoadingSelectedTemplate] = useState(false)

  useEffect(() => {
    const influencersWithoutEmail = influencers?.filter(influencer => !influencer.email)
    setInfluencersWithoutEmail(influencersWithoutEmail)
    if (influencersWithoutEmail?.length > 0 && step === 4) {
      setIsWarningModal(true)
    } else {
      setIsWarningModal(false)
    }
  }, [influencers, step])

  useEffect(() => {
    switch (step) {
      case 1:
      case 2:
        setModalTitle(t('modal.selectEmailType.header'))
        break
      case 3:
      case 4:
        setModalTitle(t('modal.newMessage.header'))
        break
      default:
        break
    }
  }, [step, locale])

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setSelectedOption(null)
      setStep(1)
      setErrorInfo(null)
      setSelectedTemplate(null)
    }, 1000)
  }

  const handleSelectOption = (option) => {
    setSelectedOption(option)
    if (option === 'bulkEmail') {
      setStep(2)
    } else if (option === 'personalEmail') {
      handleClose()
      if (hasCommunicationFF) {
        dispatch({
          type: isAccountConnected ? 'toggleNewEmail' : 'toggleConnectInbox',
          payload: {
            influencerIds: influencers?.map(influencer => influencer._id),
            to: influencers?.map(influencer => influencer.email),
            signature,
            signatureAttachments
          }
        })
      } else {
        validateLimit(false)
      }
    }
  }

  const handleSelectTemplateOption = (option) => {
    setSelectedTemplateOption(option)
    if (option === 'scratch') {
      setStep(3)
    } else if (option === 'template') {
      setSelectTemplateModalOpen(true)
    }
  }

  useEffect(() => {
    if (selectedTemplateOption === 'template' && !isSelectTemplateModalOpen && selectedTemplate) {
      setStep(3)
    }
  }, [selectedTemplateOption, isSelectTemplateModalOpen])

  return (
    <BottomCenteredModal
      name='sendMassiveEmail'
      isOpen={isOpen}
      header={<SendMassiveEmailModalHeader
        title={modalTitle}
        onClose={handleClose}
      />}
      isWarningModal={isWarningModal}
      selectedOption={selectedOption}
    >
      <Flex column styles={modalContainerStyles}>
        {step === 1 &&
          <SendMassiveEmailOptionsModal
            influencers={influencers}
            handleSelectOption={handleSelectOption}
          />
        }
        {step === 2 &&
        <>
          <SelectTemplate
            setSelectedTemplate={setSelectedTemplate}
            setLoadingSelectedTemplate={setLoadingSelectedTemplate}
          />
          <SendMassiveEmailTemplateOptionsModal
            handleSelectTemplateOption={handleSelectTemplateOption}
          />
        </>
        }
        {[3, 4].includes(step) && selectedOption === 'bulkEmail' &&
          <>
            <SaveTemplateModal />
            <SendMassiveEmailBulkEmailModal
              influencers={influencers}
              influencersWithoutEmail={influencersWithoutEmail}
              selectedTemplate={selectedTemplate}
              loadingSelectedTemplate={loadingSelectedTemplate}
              isOpen={isOpen}
              onClose={handleClose}
              step={step}
              setStep={setStep}
              errorInfo={errorInfo}
              setErrorInfo={setErrorInfo}
              useEmails={useEmails}
            />
          </>
        }
      </Flex>
    </BottomCenteredModal>
  )
}
