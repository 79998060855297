import { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'
import {
  Flex,
  Modal,
  Spacing,
  Portal,
  Text,
  Button,
  StateButton
} from 'influ-dms'
import { css, ThemeContext } from 'styled-components'
import { useRouter } from 'next/router'
import { getInvitationLink } from '../../../apollo/tracking/getApiAccessInvitation'
import { useQuery } from '@apollo/client'
import { useTranslate } from '~/hooks/useTranslate'
import { bindLinksMap } from '~/hooks/useLink'
import { useLanguageContext } from '../../../../../context/Language'

export const linkStyles = css`
  cursor: pointer;
  text-decoration: underline;
  word-break: break-all;
  `

const SendApiAccessTrackingModal = (props) => {
  const { t } = useTranslate()
  const { isOpen, onClose, network } = props
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const _id = get(router, 'query.brandId')
  const { colors: { gradients } } = useContext(ThemeContext)
  const skip = !_id || !network || isPublic
  const { data, loading: dataLoading } = useQuery(getInvitationLink, { variables: { _id, network }, skip })
  const invitationLink = get(data, 'invitationLink.link')
  const [copied, setCopied] = useState(false)
  const [trackingLink, setTrackingLink] = useState('')
  const { locale } = useLanguageContext()
  const linksMap = new Map([
    ['invite_influencers_link', setTrackingLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])

  const handleCopy = () => {
    navigator.clipboard.writeText(invitationLink)
    setCopied(true)
  }
  const renderCopied = () => {
    if (copied) {
      return (
        <>
          <Flex>
            <Text size='12'>{t('commons.message.confirmation.copied.label')}</Text>
          </Flex>
          <Spacing size='8' />
        </>
      )
    }
    return <></>
  }
  return (
    <Portal selector='#sendApiAccessTrackingModal'>
      <Modal
        name='sendApiAccessTrackingModal'
        isOpen={isOpen}
        title={t('commons.modals.inviteInfluencers.header')}
        height='auto'
        onClose={onClose}
        minHeight='300'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        <Flex column grow='1'>
          <Text color='primary.dark' bold size='15'>{t('commons.modals.inviteInfluencers.titleHeader1')}</Text>
          <Text color='primary.light3' size='14'>{t('commons.modals.inviteInfluencers.titleParagraph1')}</Text>
          <Spacing size='4' />
          <Text color='primary.light3' size='14' styles={linkStyles} onClick={() => window.open(invitationLink, '_blank')}>{invitationLink}</Text>
          <Spacing size='16' />
          <Text color='primary.light3' styles={linkStyles} bold size='14' onClick={() => window.open(trackingLink, '_blank')}>{t('commons.modals.inviteInfluencers.button.readMoreAbout')}</Text>
        </Flex>
        <Spacing size='32' />
        {renderCopied()}
        <Flex>
          <StateButton
            loading={dataLoading}
            name='coppy-clipboard-api'
            onClick={handleCopy}
          >
            {t('commons.button.copyLink')}
          </StateButton>
          <Spacing vertical size='8' />
          <Button
            onClick={onClose}
            variant='flat'
            color='secondary'
          >{t('commons.button.close')}
          </Button>
        </Flex>
      </Modal>
    </Portal>
  )
}

export { SendApiAccessTrackingModal }
