import { gql } from '@apollo/client'

export const userBrandSubscription = gql`
  subscription userBrandSubscription($userId: String!, $brandId: String!) {
    userBrandSubscription(userId: $userId, brandId: $brandId) {
      userId
      brandId
      domain
      status
      message
    }
  }
`
