import { Fragment } from 'react'
import { useTranslate } from '~/hooks/useTranslate'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import { getMasterTableCountries } from '~/graphql/commons'
import { cloneDeep } from '@apollo/client/utilities'
import { Loader, SideBox, Spacing, Text } from 'influ-dms'
import { headerStyles } from '../../styles'
import { AddPostsManualTiktokCountryMetrics } from './AddPostsManualTiktokCountryMetrics'
import { AddPostsManualTiktokCityMetrics } from './AddPostsManualTiktokCityMetrics'

export const AddPostsManualTiktokCountriesAndCities = () => {
  const { t } = useTranslate()
  const { data: countriesData, loading: loadingCountries } = useQuery(getMasterTableCountries, { variables: { query: '', type: 'countries' } })
  const countries = cloneDeep(get(countriesData, 'getMasterTableCountries', [])).map(c => ({ value: c.label[0], label: c.label[0] }))
  countries.unshift({ value: '', label: t('commons.insights.selectCountry.input.placeholder') })

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>
        {t('reports.report.modal.publication.audienceLocationBlock.title')}
      </Text>
      {loadingCountries && <Loader fillSpace />}
      {!loadingCountries && [1, 2, 3, 4, 5].map(i => (
        <Fragment key={i}>
          <Spacing size='24' />
          <AddPostsManualTiktokCountryMetrics i={i} countries={countries} />
        </Fragment>
      ))}
      <Spacing size='24' />
      {!loadingCountries && [1, 2, 3, 4, 5].map(i => (
        <Fragment key={i}>
          <Spacing size='24' />
          <AddPostsManualTiktokCityMetrics i={i} />
        </Fragment>
      ))}
      <Spacing size='8' />
    </SideBox>
  )
}
