import { cloneElement } from 'react'

export const ProviderReducer = ({ providers, children }) => {
  const JSXProviders = providers.map((Provider, index) => (
    <Provider key={index} />
  ))

  function render (providers, children) {
    const [provider, ...rest] = providers

    if (provider) {
      return cloneElement(provider, {}, render(rest, children))
    }

    return children
  }

  return render(JSXProviders, children)
}
