import { useState } from 'react'
import { Form, FormInputWithValidations, Spacing } from 'influ-dms'
import { isEmpty } from 'lodash'
import { Footer } from './Footer'
import { SignatureCheck } from './SignatureCheck'
import { useTranslate } from '~/hooks/useTranslate'
import styled from 'styled-components'

export const Fieldset = styled.fieldset`
  border: 0px;
  padding: 0px;
  margin: 0px;
`

const requiredValidation = {
  checkFunction: isEmpty
}

export const DetailsForm = ({ accountSettings, handleSuccess, handleClose, isClosable, isActiveSubscription }) => {
  const [loading, setLoading] = useState(false)
  const disabled = !isActiveSubscription || loading
  const { t } = useTranslate()

  return (
    <Form>
      <Fieldset disabled={disabled}>
        <Spacing size='24' />
        <FormInputWithValidations name='name' validations={[requiredValidation]}>
          {({ name, onChange }) => {
            return (
              <Form.InputText label={t('settings.yourPreferences.communication.email.configuration.input.displayName.label')} name={name} helperText={isClosable ? t('settings.yourPreferences.communication.email.configuration.input.displayName.helperText') : ''} onChange={onChange} />
            )
          }}
        </FormInputWithValidations>
        <Spacing size='16' />
        <SignatureCheck accountSettings={accountSettings} disabled={disabled} />
        <Spacing size='32' />
        <Footer
          isClosable={isClosable}
          accountSettings={accountSettings}
          handleSuccess={handleSuccess}
          handleClose={handleClose}
          setLoading={setLoading}
        />
      </Fieldset>
    </Form>
  )
}
