import { useContext, useEffect, useState } from 'react'
import { get, isEmpty, some } from 'lodash'
import { FormContext, useArray } from 'influ-dms'
import { ProfileInputTagTracking } from './ProfilesInputTagTracking/index'
import { checkAddProfileToTracking } from '../../../apollo/tracking'
import { useQuery } from '@apollo/client'
import { isValidSocialUrl, isUrl } from '~/helpers/network'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'
import { captureException } from '@sentry/nextjs'

const MAX_NUMBER_OF_PROFILES_BY_TRACKING_IN_ADD = 20
const generalError = 'commons.errorMessage.tryAgainLater'

export const AddProfileTrackingTagInput = ({ setError, setLoading, loading }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const { values, setValue } = useContext(FormContext)
  const [profiles, setProfiles] = useArray([])
  const [updateFlag, setUpdateFlag] = useState(false) // Multiple profile loading management
  const { refetch: fetchCheckAddProfileToTracking } = useQuery(checkAddProfileToTracking, { skip: true, fetchPolicy: 'network-only' })
  const brandId = get(router, 'query.brandId')
  useEffect(() => {
    setValue('InputText', 'influencersInModal', profiles)
    if (some(profiles, ({ isValid }) => !isValid)) {
      setError(t('reports.modal.tracking.addNewProfile.profile.input.helpertext.invalidProfilesOrDuplicates.error'))
    } else {
      setError(undefined)
    }
    if (profiles.length > MAX_NUMBER_OF_PROFILES_BY_TRACKING_IN_ADD) {
      setError(t('reports.modal.tracking.addNewProfile.profile.input.helpertext.profilesExceeded.error', { totalUrls: MAX_NUMBER_OF_PROFILES_BY_TRACKING_IN_ADD }))
    }
    if (some(profiles, ({ loading }) => loading)) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [loading, updateFlag, profiles.length])

  useEffect(() => {
    if (!isEmpty(profiles) && isEmpty(values.influencersInModal)) {
      setProfiles([])
    }
  }, [values.influencersInModal])

  const isWellFormedProfile = (profile, network) => {
    const validUrl = isUrl(profile)
    if (validUrl) {
      const { error, success } = isValidSocialUrl(t, profile, network)
      return { error, success }
    }
    return { success: true }
  }

  const isValidProfileToAdd = async (profile) => {
    const isValidProfile = isWellFormedProfile(profile, values.network)
    if (isValidProfile && !isValidProfile.success) {
      setLoading(false)
      return { success: false }
    }

    try {
      const isValidProfileToAdd = await fetchCheckAddProfileToTracking({ account: profile, network: values.network, brandId })
      const success = get(isValidProfileToAdd, 'data.checkAddProfileToTracking.exists')
      const apiAccess = get(isValidProfileToAdd, 'data.checkAddProfileToTracking.apiAccess')
      const profilePicture = get(isValidProfileToAdd, 'data.checkAddProfileToTracking.profilePicture')
      const profileUrl = get(isValidProfileToAdd, 'data.checkAddProfileToTracking.profileUrl')
      const username = get(isValidProfileToAdd, 'data.checkAddProfileToTracking.username')

      if (!success) {
        setLoading(false)
        return { success: false }
      }
      setLoading(false)
      return { success: true, profilePicture, profileUrl, username, apiAccess, network: values.network }
    } catch (error) {
      setError(t(generalError))
      setLoading(false)
      captureException({ error })
      return { success: false }
    }
  }

  const handleProfile = async profile => {
    setProfiles({ value: profile, isValid: true, loading: true }, 'edit', 'value')
    const { success, profilePicture, profileUrl, username, apiAccess, network } = await isValidProfileToAdd(profile)
    setProfiles({ value: profile, profilePicture, profileUrl, username, apiAccess, network, isValid: success, loading: false }, 'edit', 'value')
    setUpdateFlag(!updateFlag)
  }

  return (
    <ProfileInputTagTracking
      urls={profiles}
      setUrls={setProfiles}
      handleUrl={handleProfile}
      placeholder={t('reports.modal.tracking.addNewProfile.profile.input.placeholder', { totalUrls: MAX_NUMBER_OF_PROFILES_BY_TRACKING_IN_ADD })}
    />
  )
}
