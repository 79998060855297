import { useEffect, useState } from 'react'

export const useEnableAfterTime = ({ dateToEnable }) => {
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    let timeout
    const safeDate = new Date()
    safeDate.setMinutes(safeDate.getMinutes() - 5)
    if (safeDate > dateToEnable) {
      setDisabled(false)
    } else {
      setDisabled(true)
      timeout = setTimeout(() => {
        setDisabled(false)
      }, [dateToEnable - safeDate])
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [dateToEnable])

  return disabled
}
