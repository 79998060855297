import { get } from 'lodash'
import getConfig from 'next/config'
import {
  BrandBasic,
  imageStyles,
  HeaderWrapper,
  BrandWrapper,
  brandNameStyles,
  avatarStyles
} from './'
import { Avatar, Image, Text } from 'influ-dms'

const { publicRuntimeConfig } = getConfig()
const src = publicRuntimeConfig.brandImage

export const PrintHeader = ({ title, children, withBrand = true }) => {
  return (
    <BrandBasic>
      {({ brand }) => {
        const brandName = get(brand, 'brandName')
        const logoImage = get(brand, 'logoImage')
        return (
          <HeaderWrapper id='pageHeader'>
            <Image src={src} styles={imageStyles} alt='Influencity' />
            <Text size='14' ellipsis>{title}</Text>
            {withBrand && (
              <BrandWrapper>
                <Text styles={brandNameStyles} bold ellipsis size='14'>{brandName}</Text>
                <Avatar styles={avatarStyles} variant='brand' src={logoImage} />
              </BrandWrapper>
            )}
            {children && children}
          </HeaderWrapper>
        )
      }}
    </BrandBasic>
  )
}
