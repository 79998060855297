import { gql } from '@apollo/client'

export const getProfilesInMyInfluencersForTracking = gql`
 query getProfilesInMyInfluencersForTracking(
    $brandId: String!,
    $network: String!
) {
  getProfilesInMyInfluencersForTracking(
    brandId: $brandId,
    network: $network,
    ) {
        username
        network
        profilePicture
        followers
        profileUrl
        apiAccess
        analyzeProfile
        firstName
        lastName
    }
 }
`
