import { useContext, useEffect, useState } from 'react'
import { Loader } from 'influ-dms'
import { useBrandData } from '~/hooks'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { getDomainsByBrand } from '../../../../../settings/account/domains/commons/apollo'
import { getMassiveCommConfig } from '../../../../../settings/preferences/communication/apollo'
import { RefreshRemainingFeatureFlagsContext } from '../../../../../../context/RefreshRemainingFeatureFlags'
import { NoCommunicationFF } from './NoCommunicationFF'
import { MessageLimitReached } from './MessageLimitReached'
import { EmptyDomains } from './EmptyDomains'
import { SendMassiveEmailBulkEmailForm } from './SendMassiveEmailBulkEmailForm'
import { loaderStyles } from './styles'

export const SendMassiveEmailBulkEmailModal = ({
  influencers,
  influencersWithoutEmail,
  selectedTemplate,
  loadingSelectedTemplate,
  isOpen,
  onClose,
  step,
  setStep,
  setErrorInfo,
  useEmails
}) => {
  const router = useRouter()
  const { brandId } = router.query
  const { data: brandData } = useBrandData({ variables: { brandId }, skip: !brandId })
  const isEnterprise = get(brandData, 'isEnterprise')
  const [initialLoading, setInitialLoading] = useState(true)
  const { data: massiveCommData, loading: loadingMassiveCommData, refetch: refetchMassiveComm } = useQuery(getMassiveCommConfig, { variables: { brandId }, skip: !brandId })
  const { data: domainsData, loading: loadingDomainsData, refetch: refetchDomains } = useQuery(getDomainsByBrand, { variables: { brandId }, skip: !brandId || !massiveCommData })

  const { remainingMassiveCommMessages } = useContext(RefreshRemainingFeatureFlagsContext)
  const moreInfluencersThanMessages = influencers?.length - influencersWithoutEmail?.length > remainingMassiveCommMessages
  const noRemainingMessages = remainingMassiveCommMessages === 0

  useEffect(() => {
    if (isOpen) {
      refetchMassiveComm()
      refetchDomains()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && !loadingMassiveCommData && !loadingDomainsData) {
      setInitialLoading(false)
    }
  }, [loadingMassiveCommData, loadingDomainsData])

  return (
    initialLoading || loadingDomainsData || loadingMassiveCommData
      ? <Loader data-cy='loading-bulk-email' styles={loaderStyles} />
      : !massiveCommData?.getMassiveCommConfig ? <NoCommunicationFF brandId={brandId} isEnterprise={isEnterprise} onClose={onClose} />
          : noRemainingMessages || moreInfluencersThanMessages ? <MessageLimitReached onClose={onClose} brandId={brandId} moreInfluencersThanMessages={moreInfluencersThanMessages} remainingMassiveCommMessages={remainingMassiveCommMessages} />
            : !massiveCommData?.getMassiveCommConfig?.senderEmail || !massiveCommData?.getMassiveCommConfig?.senderName || domainsData?.getDomainsByBrand?.length === 0
                ? <EmptyDomains brandId={brandId} onClose={onClose} />
                : <SendMassiveEmailBulkEmailForm
                    influencers={influencers}
                    influencersWithoutEmail={influencersWithoutEmail}
                    selectedTemplate={selectedTemplate}
                    loadingSelectedTemplate={loadingSelectedTemplate}
                    massiveCommData={massiveCommData?.getMassiveCommConfig}
                    onClose={onClose}
                    step={step}
                    setStep={setStep}
                    setErrorInfo={setErrorInfo}
                    useEmails={useEmails}
                  />
  )
}
