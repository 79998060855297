import styled from 'styled-components'
import { Flex, Text, Icon, Spacing } from 'influ-dms'

const StyledFlex = styled(Flex)`
  height: 24px;
`

export const GeneralError = ({ text = 'We are experiencing some issues. Try again!' }) => {
  return (
    <StyledFlex align='center'>
      <Icon name='warning' size='13' color='state.warningDefault' />
      <Spacing vertical size='4' />
      <Text color='primary.light3'>{text}</Text>
    </StyledFlex>
  )
}
