import { get } from 'lodash'
import moment from 'moment'

export const setTraceSection = (obj) => {
  let pathname = ''
  if (typeof window !== 'undefined') pathname = get(window, 'location.pathname')
  const pathArray = pathname.split('/')
  obj.section = get(pathArray, '[1]')
  if (get(pathArray, '[2].length') === 24) {
    obj.subsection = get(pathArray, '[3]')
  }
}

export const setTraceDurations = (obj) => {
  const now = moment()
  const userCreatedAt = moment(localStorage.getItem('user-created-at'))
  const userFirstLogin = moment(localStorage.getItem('user-first-login-at'))
  const userFirstLoginSince = now.diff(userFirstLogin, 'days')
  const userFirstLoginSinceMonths = now.diff(userFirstLogin, 'months')
  const userSince = now.diff(userCreatedAt, 'days')
  const userSinceMonths = now.diff(userCreatedAt, 'months')
  const brandCreatedAt = moment(localStorage.getItem('brand-created-at'))
  const brandSince = now.diff(brandCreatedAt, 'days')
  const brandSinceMonths = now.diff(brandCreatedAt, 'months')
  const subscriptionCreatedAt = moment(localStorage.getItem('subscription-created-at'))
  const subscriptionSince = now.diff(subscriptionCreatedAt, 'days')
  const subscriptionSinceMonths = now.diff(subscriptionCreatedAt, 'months')
  obj['user-first-login-since-days'] = userFirstLoginSince
  obj['user-first-login-since-months'] = userFirstLoginSinceMonths
  obj['user-since-days'] = userSince
  obj['user-since-months'] = userSinceMonths
  obj['brand-since-days'] = brandSince
  obj['brand-since-months'] = brandSinceMonths
  obj['subscription-since-days'] = subscriptionSince
  obj['subscription-since-months'] = subscriptionSinceMonths
  obj['subscription-status'] = localStorage.getItem('subscription-status')
  obj.rev = localStorage.getItem('rev')
}
