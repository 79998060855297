import { gql } from '@apollo/client'

export const addInfluencersToCampaign = gql`
 mutation addInfluencersToCampaign($campaignIds: [String!], $influencerIds: [String], $profileIds: [String], $irm: Boolean) {
  addInfluencerInCampaign(campaignIds: $campaignIds , influencerIds: $influencerIds, profileIds: $profileIds, irm: $irm){
      id: _id
      name
    }
 }
 `

export const addListsToCampaign = gql`
 mutation addListsToCampaign($campaignIds: [String!], $listIds: [String!]) {
  addListToCampaignBeta(campaignIds: $campaignIds , listIds: $listIds){
      id: _id
      name
    }
 }
 `
