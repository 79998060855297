import styled, { css } from 'styled-components'
import { Button, Flex, SideBox, Spacing, Text, Icon } from 'influ-dms'
import { RouterHelper } from '../../../../../routing/routerHelper'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'

const sideBoxStyles = css`
  padding: 0;
  text-align: center
`
const Container = styled.div`
  padding: 32px 32px 32px 32px;
`
const PaddedContainer = styled.div`
  padding: 16px 16px 20px 16px;
`
const StyledButton = styled(Button)`
  margin-top: 14px;
`
const StyledFlex = styled(Flex)`
  margin-bottom: 32px;
`
const CenteredRowIconFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-bottom: 8px
`
const MarginedText = styled(Text)`
  margin: 0px 4px
`

const leftText = css`
text-align: left;
`

const StyledFlexStart = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`

export const UnlockAddTracking = ({ trial = true, handleClose }) => {
  const { t } = useTranslate()
  const router = useRouter()
  const { brandId } = router.query
  const handleClick = () => {
    handleClose()
    router.push(RouterHelper.getRoute('pricesAndPlans', { brandId }))
  }
  return (
    <Container>
      <Text color='primary.dark' size='16' bold>{t('reports.report.modal.addPublication.tracking.titleHeader1')}</Text>
      <Spacing size='8' />
      <Text size={14} color='primary.light3'>{t('reports.report.modal.addPublication.tracking.titleParagraph1')}</Text>
      <Spacing size='24' />
      <SideBox styles={sideBoxStyles}>
        <PaddedContainer>
          <Spacing size='16' />
          <Text size='15' color='primary.dark' bold>{t('reports.report.modal.addPublication.tracking.withoutFeature. ')}</Text>
          <Spacing size='4' />
          {trial ? (<Text size='14' color='primary.light3'>{t('reports.report.modal.addPublication.tracking.withoutFeature.trial.titleParagraph1')}</Text>)
            : (<Text size='14' color='primary.light3'>{t('reports.report.modal.addPublication.tracking.withoutFeature.notIncluyed.titleParagraph1')}</Text>)}
          <Spacing size='24' />
          <StyledFlexStart>
            <Text size='14' color='primary.light3' styles={leftText}>
              {t('reports.report.modal.addPublication.tracking.withoutFeature.titleParagraph1')}
            </Text>
            <Spacing size='16' />
            <CenteredRowIconFlex>
              <Icon name='checkok' size='20' color='state.active' />
              <MarginedText size='13' color='primary.light3'>
                {t('reports.report.modal.addPublication.tracking.withoutFeature.checkPoint1.titleParagraph2')}
              </MarginedText>
            </CenteredRowIconFlex>
            <CenteredRowIconFlex>
              <Icon name='checkok' size='20' color='state.active' />
              <MarginedText size='13' color='primary.light3'> {t('reports.report.modal.addPublication.tracking.withoutFeature.checkPoint2.titleParagraph2')}
              </MarginedText>
            </CenteredRowIconFlex>
            <CenteredRowIconFlex>
              <Icon name='checkok' size='20' color='state.active' />
              <MarginedText size='13' color='primary.light3'> {t('reports.report.modal.addPublication.tracking.withoutFeature.checkPoint3.titleParagraph2')}
              </MarginedText>
            </CenteredRowIconFlex>
            <CenteredRowIconFlex>
              <Icon name='checkok' size='20' color='state.active' />
              <MarginedText size='13' color='primary.light3'>{t('reports.report.modal.addPublication.tracking.withoutFeature.checkPoint4.titleParagraph2')}
              </MarginedText>
            </CenteredRowIconFlex>
          </StyledFlexStart>
          <img
            width='250px' height='250px'
            src='/static/img/tracking/trackingLimitExceeded.png'
            alt='Tracking Limit Exceeded'
          />
          <Spacing size='4' />
          <StyledFlex center>
            <StyledButton onClick={handleClick} size='l'>{t('commons.button.seePlansAndPrices')}</StyledButton>
          </StyledFlex>
          <Spacing size='2' />
          <Text size='14' color='primary.light3' styles={leftText}>
            {t('reports.report.modal.addPublication.tracking.withoutFeature.titleParagraph3')}
          </Text>
        </PaddedContainer>
      </SideBox>
    </Container>
  )
}
