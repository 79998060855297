import { useContext } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { Date as DateComponent, Flex, Form, Spacing, Text } from 'influ-dms'
import { postTypeMap } from '../../../../posts/table/ReportsPostsTable'
import { CurrencyContext } from '../../../../../commons/context/CurrencyContext'
import { imagePostStyles } from '../../../reportsTable/styles'
import { useTranslate } from '~/hooks/useTranslate'

const StyledContainer = styled.div`
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.primary.light5};
  :not(:last-child) {
    margin-bottom: 8px;
  }
  padding: 12px;
`

const ImageContainer = styled.div`
  width: 75px;
  height: 75px;
  ${imagePostStyles};
`

export const EditInfluencerModalContentPost = ({ postId, image, type, publicationDate }) => {
  const { t } = useTranslate()
  const { symbol } = useContext(CurrencyContext)
  const imageSrc = get(image, '[0]')
  return (
    <StyledContainer>
      <Flex justify='space-between'>
        <Flex>
          <ImageContainer image={imageSrc} />
          <div>
            <Text size='14' bold>{postTypeMap(t)[type]}</Text>
            <Spacing size='2' />
            <Text><DateComponent>{publicationDate}</DateComponent></Text>
          </div>
        </Flex>
        <Form.InputNumber placeholder={`${t('reports.report.modal.profile.costBlock.publicationCost.input.placeholder')} (${symbol})`} name={`publicationCosts[${postId}]`} label={`${t('reports.report.modal.profile.costBlock.publicationCost.input.label')} (${symbol})`} withDecimal />
      </Flex>
    </StyledContainer>
  )
}
