import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

class Input extends Component {
  constructor (props) {
    super(props)
    this.state = {
      require: false
    }
  }

  handleChange (event) {
    if (event.target.required && event.target.value) {
      this.setState({ require: false })
    }
    return this.props.onChange(event)
  }

  handleOnBlur (event) {
    if (event.target.required && !event.target.value) {
      this.setState({ require: true })
    }
    return this.props.onBlur && this.props.onBlur(event)
  }

  renderInluneWarnLabel () {
    if (this.props.inline && this.state.require) {
      return (
        <span className='warn-label'>
          <FormattedMessage id='input.required.warning' defaultMessage='Required' />
        </span>
      )
    } else if (this.props.inline && this.props.error) {
      return <span className='warn-label'>{this.props.error}</span>
    }
    return ''
  }

  renderWarnLabel () {
    if (this.state.require) {
      return (
        <span className='require-label warn-label'>
          <FormattedMessage id='input.required.warning' defaultMessage='Required' />
        </span>
      )
    } else if (this.props.error) {
      return <span className='warn-label'>{this.props.error}</span>
    }
    return ''
  }

  render () {
    const { className, description, children, accepted, inline, error, handleFocus, ...rest } = this.props
    return (
      <div
        className={`${this.props.className} ${this.props.inline ? 'inline' : ''}`}
      >
        {!this.props.inline &&
          <div className='label-warn-div'>
            {this.props.label && <label htmlFor={this.props.name}>{this.props.label}</label>}
            {this.renderWarnLabel()}
          </div>}
        {this.props.inline && this.props.label && <label htmlFor={this.props.name}>{this.props.label}{this.props.required && '*'}</label>}
        <input
          {...rest}
          id={this.props.name}
          className={`${rest.type === 'checkbox' ? 'checkbox' : ''} ${this.state.require || this.props.error ? 'require' : ''}`}
          onBlur={event => this.handleOnBlur(event)}
          onChange={event => this.handleChange(event)}
          data-cy={'Input-' + this.props.name}
        />
        {rest.type === 'checkbox' && <span>{description}</span>}
        {this.props.children}
        {this.renderInluneWarnLabel()}
      </div>
    )
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  accepted: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  description: PropTypes.string,
  children: PropTypes.node,
  inline: PropTypes.bool
}

const InputStyled = styled(Input)`
  font-family: 'Rubik', sans-serif;
  width: 100%;
  input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #C5C5D2;
    height: 32px;
    margin: 8px 0;
    padding: 8px;
    border-radius: 2px;
    background-color: #FFFFFF;
    font-size: 14px;
    &:invalid{
      box-shadow: none !important;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #C5C5D2;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #C5C5D2;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #C5C5D2;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #C5C5D2;
    }
    &.require{
      border: 1px solid red;
    }
    &.checkbox{
      width:auto;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #C5C5D2;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Rubik', sans-serif;
    }
  }
  svg{
   margin: 0 8px;
  &:hover{
    fill: ${props => props.theme.colors.app.default};
    cursor: pointer;
  }
  }
  .warn-label{
    color: red;
  }
  &.inline{
    display: flex;
    align-items: center;
    label{
      width: 120px;
      margin-right: 8px;
      text-align: end;
    }
    input{
      width: 240px;
    }
    span{
      margin-left: 8px;
    }
  }
  .label-warn-div{
    display: flex;
    justify-content: space-between;
    .require-label{
      margin-top: 8px;
    }
    label {
      display: inline-block;
      margin-top: 8px;
    }
  }
`

export default InputStyled
