import { gql } from '@apollo/client'

const searchResultCount = gql`
  query searchResultCount(
    $brandId: String!
    $filterParams: DeepSearchFilterParamsTypeInput!
    $network: NetworkOptions
  ){
    searchResultCount(
      brandId: $brandId,
      filterParams: $filterParams,
      network: $network
    ) {
      totalCount,
    }
  }
`

export { searchResultCount }
