import { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'
import { Form } from 'influ-dms'
import { EditPostModal } from './EditPostModal'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { postQuery } from '../../apollo/postQuery'
import {
  updateInstagramCarouselAutoMutation,
  updateInstagramCarouselManualMutation,
  updateInstagramCarouselTrackingMutation,
  updateInstagramPhotoAutoMutation,
  updateInstagramPhotoManualMutation,
  updateInstagramPhotoTrackingMutation,
  updateInstagramReelAutoMutation, updateInstagramReelManualMutation,
  updateInstagramStoryManualMutation,
  updateInstagramStoryTrackingMutation,
  updateInstagramVideoAutoMutation,
  updateInstagramVideoManualMutation,
  updateInstagramVideoTrackingMutation,
  updateTiktokVideoAutoMutation,
  updateTiktokPhotoAutoMutation,
  updateTiktokVideoManualMutation,
  updateTiktokPhotoManualMutation,
  updateYoutubeVideoAutoMutation,
  updateYoutubeVideoManualMutation,
  updateYoutubeShortAutoMutation,
  updateYoutubeShortManualMutation
} from '../../apollo/editPostsQueries'
import { ReportsContext } from '../../context/ReportsContext'
import { calculateReportV2MetricsSubscription } from '../../apollo/calculateReportV2MetricsSubscription'
import { useRouter } from 'next/router'
import { useTranslate } from '~/hooks/useTranslate'

export const EditPost = ({ isOpen, onClose, postId }) => {
  const { t } = useTranslate()
  const { reportsAction, setReportsAction } = useContext(ReportsContext)
  const router = useRouter()
  const reportId = get(router, 'query.reportId')
  const variables = { postId }
  const { data, loading, refetch } = useQuery(postQuery, { variables, fetchPolicy: 'network-only', skip: !isOpen })
  const { data: metricsSubscriptionData } = useSubscription(calculateReportV2MetricsSubscription, { variables: { reportV2Id: reportId }, skip: !reportId })
  const post = get(data, 'getReportsV2Publication', {})

  const [editInstagramVideoAuto, { loading: editInstagramVideoAutoLoading }] = useMutation(updateInstagramVideoAutoMutation)
  const [editInstagramVideoTracking, { loading: editInstagramVideoTrackingLoading }] = useMutation(updateInstagramVideoTrackingMutation)
  const [editInstagramReelAuto, { loading: editInstagramReelAutoLoading }] = useMutation(updateInstagramReelAutoMutation)
  const [editInstagramPhotoAuto, { loading: editInstagramPhotoAutoLoading }] = useMutation(updateInstagramPhotoAutoMutation)
  const [editInstagramPhotoTracking, { loading: editInstagramPhotoTrackingLoading }] = useMutation(updateInstagramPhotoTrackingMutation)
  const [editInstagramCarouselAuto, { loading: editInstagramCarouselAutoLoading }] = useMutation(updateInstagramCarouselAutoMutation)
  const [editInstagramCarouselTracking, { loading: editInstagramCarouselTrackingLoading }] = useMutation(updateInstagramCarouselTrackingMutation)
  const [editTiktokVideoAuto, { loading: editTiktokVideoAutoLoading }] = useMutation(updateTiktokVideoAutoMutation)
  const [editTiktokVideoManual, { loading: editTiktokVideoManualLoading }] = useMutation(updateTiktokVideoManualMutation)
  const [editTiktokPhotoAuto, { loading: editTiktokPhotoAutoLoading }] = useMutation(updateTiktokPhotoAutoMutation)
  const [editTiktokPhotoManual, { loading: editTiktokPhotoManualLoading }] = useMutation(updateTiktokPhotoManualMutation)
  const [editInstagramStoryManual, { loading: editInstagramStoryManualLoading }] = useMutation(updateInstagramStoryManualMutation)
  const [editInstagramStoryTracking, { loading: editInstagramStoryTrackingLoading }] = useMutation(updateInstagramStoryTrackingMutation)
  const [editInstagramVideoManual, { loading: editInstagramVideoManualLoading }] = useMutation(updateInstagramVideoManualMutation)
  const [editInstagramReelManual, { loading: editInstagramReelManualLoading }] = useMutation(updateInstagramReelManualMutation)
  const [editInstagramPhotoManual, { loading: editInstagramPhotoManualLoading }] = useMutation(updateInstagramPhotoManualMutation)
  const [editInstagramCarouselManual, { loading: editInstagramCarouselManualLoading }] = useMutation(updateInstagramCarouselManualMutation)
  const [editYoutubeVideoAuto, { loading: editYoutubeVideoAutoLoading }] = useMutation(updateYoutubeVideoAutoMutation)
  const [editYoutubeVideoManual, { loading: editYoutubeVideoManualLoading }] = useMutation(updateYoutubeVideoManualMutation)
  const [editYoutubeShortAuto, { loading: editYoutubeShortAutoLoading }] = useMutation(updateYoutubeShortAutoMutation)
  const [editYoutubeShortManual, { loading: editYoutubeShortManualLoading }] = useMutation(updateYoutubeShortManualMutation)

  const [error, setError] = useState()

  const editLoading = editInstagramVideoAutoLoading || editInstagramVideoTrackingLoading || editInstagramReelAutoLoading || editInstagramPhotoAutoLoading || editInstagramPhotoTrackingLoading || editInstagramCarouselAutoLoading || editInstagramCarouselTrackingLoading ||
    editYoutubeVideoAutoLoading || editTiktokVideoAutoLoading || editInstagramStoryManualLoading || editInstagramStoryTrackingLoading || editInstagramVideoManualLoading || editInstagramReelManualLoading ||
    editInstagramPhotoManualLoading || editInstagramCarouselManualLoading || editYoutubeVideoManualLoading || editTiktokVideoManualLoading || editTiktokPhotoManualLoading || editTiktokPhotoAutoLoading || editYoutubeShortAutoLoading || editYoutubeShortManualLoading

  const getMutation = values => {
    const { network, type, dataSource } = values
    const isAutomatic = dataSource === 'automatic'
    const isTracking = dataSource === 'tracking'
    if (network === 'youtube') {
      if (type === 'video') return isAutomatic ? editYoutubeVideoAuto : editYoutubeVideoManual
      if (type === 'short') return isAutomatic ? editYoutubeShortAuto : editYoutubeShortManual
    }
    if (network === 'tiktok') {
      if (type === 'video') return isAutomatic ? editTiktokVideoAuto : editTiktokVideoManual
      if (type === 'photo') return isAutomatic ? editTiktokPhotoAuto : editTiktokPhotoManual
    }
    if (network === 'instagram') {
      if (type === 'story') return isTracking ? editInstagramStoryTracking : editInstagramStoryManual
      if (type === 'video') return isAutomatic ? editInstagramVideoAuto : isTracking ? editInstagramVideoTracking : editInstagramVideoManual
      if (type === 'reel') return isAutomatic ? editInstagramReelAuto : editInstagramReelManual
      if (type === 'photo') return isAutomatic ? editInstagramPhotoAuto : isTracking ? editInstagramPhotoTracking : editInstagramPhotoManual
      if (type === 'carousel') return isAutomatic ? editInstagramCarouselAuto : isTracking ? editInstagramCarouselTracking : editInstagramCarouselManual
    }
    return null
  }

  const handleEditPost = values => {
    const mutation = getMutation(values)
    mutation({ variables: { ...values, publicationInReportV2Id: postId } })
      .then(() => {
        setReportsAction({ action: 'editPost', data: postId })
        onClose()
      })
      .catch(() => {
        setError(t('commons.errorMessage.tryAgainLater'))
      })
  }

  useEffect(() => {
    if (reportsAction && ['editPost'].indexOf(reportsAction.action) > -1 && reportsAction.data === postId) {
      refetch(variables)
    }
  }, [reportsAction])

  useEffect(() => {
    if (metricsSubscriptionData) {
      const calculateReportV2Metrics = get(metricsSubscriptionData, 'calculateReportV2Metrics')
      if (calculateReportV2Metrics.calculateMetrics &&
        calculateReportV2Metrics.reportV2Id === reportId &&
        calculateReportV2Metrics.reportV2PublicationIds.indexOf(postId) > -1) {
        refetch(variables)
      }
    }
  }, [metricsSubscriptionData])

  return (
    <Form>
      <EditPostModal
        isOpen={isOpen}
        post={post}
        handleCreate={handleEditPost}
        handleClose={onClose}
        loading={loading}
        editLoading={editLoading}
        error={error}
        setError={setError}
      />
    </Form>
  )
}
