import { useContext, useEffect, useState } from 'react'
import { FiltersTableContext } from 'influ-dms'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

export const useFilterQuery = ({
  query,
  queryAccessor,
  countQuery,
  countQueryAccessor,
  options = { skip: true }
}) => {
  const [queryOptions, setQueryOptions] = useState(options)
  const [countQueryOptions, setCountQueryOptions] = useState(options)
  const { setFilteredData, setProcessingFilter, setCount } = useContext(FiltersTableContext)
  const { data, loading: loadingData } = useQuery(query, queryOptions)
  const filteredData = get(data, queryAccessor)

  useEffect(() => {
    if (filteredData) {
      setFilteredData(filteredData)
      setProcessingFilter(false)
    }
  }, [filteredData, queryOptions])

  if (!countQuery) return { filteredData, loadingData, setQueryOptions }

  const { data: dataCount, loading: loadingCount } = useQuery(countQuery, countQueryOptions)
  const filteredDataCount = get(dataCount, countQueryAccessor)

  useEffect(() => {
    filteredDataCount >= 0 && setCount(filteredDataCount)
  }, [filteredDataCount])

  return {
    filteredData,
    loadingData,
    filteredDataCount,
    loadingCount,
    setQueryOptions,
    setCountQueryOptions
  }
}
