import { Flex, Text, Icon, Spacing } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import styled from 'styled-components'

const StyledFlex = styled(Flex)`
  height: 24px;
`

export const SaveSuccess = ({ text = 'settings.cardFooter.success.helperText' }) => {
  const { t } = useTranslate()
  return (
    <StyledFlex align='center'>
      <Icon name='radiochecked' size='13' color='state.active' />
      <Spacing vertical size='4' />
      <Text color='primary.light3' data-cy='SaveSuccess-text'>{t(text)}</Text>
    </StyledFlex>
  )
}
