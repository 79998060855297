import { Button, Flex, Modal, Spacing, StateButton, Text } from 'influ-dms'
import { DeleteReportsMutation } from '../deleteReports'
import { useTranslate } from '~/hooks/useTranslate'

export const DeleteReport = ({ reportId, isOpen, onSuccess, onClose }) => {
  const { t } = useTranslate()
  const onDelete = (mutationHandler, loading) => {
    if (loading) return
    mutationHandler({ ids: [reportId] })
      .then(() => {
        if (onSuccess) onSuccess()
        onClose()
      })
      .catch(() => {
      })
  }

  return (
    <DeleteReportsMutation>
      {({ mutationHandler, loading }) => {
        return (
          <Modal
            name='deleteReport'
            color='state.dangerDefault'
            onClose={onClose}
            isOpen={isOpen}
            title={t('reports.modal.deteleReport.header')}
            height='250'
            width='500'
          >
            <Flex column grow='1'>
              <Text size='14'>{t('reports.modal.deteleReport.titleParagraph1')}</Text>
            </Flex>
            <Flex>
              <StateButton
                loading={loading}
                showLoader
                color='danger'
                name='deleteReport'
                onClick={() => onDelete(mutationHandler, loading)}
              >{t('commons.button.confirm')}
              </StateButton>
              <Spacing size='16' vertical />
              <Button name='cancelDeleteReport' color='secondary' variant='flat' onClick={onClose}>{t('commons.button.cancel')}</Button>
            </Flex>
          </Modal>
        )
      }}
    </DeleteReportsMutation>
  )
}
