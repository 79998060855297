import { gql } from '@apollo/client'

export const getInvitationLink = gql`
 query invitationLink(
    $_id: String!,
    $network: String
) {
  invitationLink(
    _id: $_id,
    network: $network,
    ) {
      link
      network
      }
 }
`
