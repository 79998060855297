import { useContext, useState } from 'react'
import { css, ThemeContext } from 'styled-components'
import { get } from 'lodash'
import { Modal } from 'influ-dms'
import { SetupContent } from './SetupContent'
import { NoCommunicationContent } from './NoCommunicationContent'
import { servers, steps } from './conf'
import { Terms } from './terms/Terms'
import { Forms } from './forms/Forms'
import { useRouter } from 'next/router'
import cookiesParser from '../../../../helpers/cookiesParser'
import getConfig from 'next/config'
import { Warning } from './warning/Warning'
import { useTranslate } from '~/hooks/useTranslate'
const { publicRuntimeConfig } = getConfig()

const modalContentStyles = css`
  max-height: 90vh;
  overflow-y: auto;
`

export const EmailSetupModal = ({ isOpen, onClose }) => {
  const { t } = useTranslate()
  const { colors: { gradients } } = useContext(ThemeContext)
  const [step, setStep] = useState(steps.setup)
  const [server, setServer] = useState()

  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const handleSetup = server => {
    setServer(server)
    // TODO check ff
    setStep(steps.terms)
  }

  const handleClose = e => {
    setTimeout(() => {
      setStep(steps.setup)
      setServer(undefined)
    }, 500)
    onClose(e)
  }

  const handleGoogleRedirect = () => {
    const token = get(cookiesParser(typeof window !== 'undefined' && document), 'token', null)
    // prompt=consent is only for first time login
    window.location.href = publicRuntimeConfig.googleUrlLogin + `?brandId=${brandId}&token=${token}&prompt=consent`
  }

  const getTitle = () => {
    if (step === steps.setup) return t('settings.yourPreferences.communication.email.configuration.modal.connectInbox.title')
    if (server === servers.outlook.id) return t('settings.yourPreferences.communication.email.configuration.modal.connectOfficeInbox.title')
    return t('settings.yourPreferences.communication.email.configuration.modal.connectImapInbox.title', {}, 'Email Setup')
  }

  return (
    <Modal
      isOpen={isOpen}
      title={getTitle()}
      minHeight='100'
      gradient1={gradients[1][0]}
      gradient2={gradients[1][1]}
      onClose={handleClose}
      styles={modalContentStyles}
    >
      {step === steps.setup && <SetupContent handleClick={handleSetup} googleRedirect={handleGoogleRedirect} />}
      {step === steps.noTool && <NoCommunicationContent />}
      {step === steps.terms && <Terms server={server} setStep={setStep} handleCancel={handleClose} />}
      {step === steps.warning && <Warning server={server} setStep={setStep} handleCancel={handleClose} />}
      {step === steps.form && <Forms server={server} setServer={setServer} handleClose={handleClose} />}
    </Modal>
  )
}
