import { get, isEmpty, some } from 'lodash'

/** Consider replace t argument by translate in order to improve readability */
export const postValidator = (url, type, t) => {
  const isNetworkReg = /(twitter|youtube|instagram|tiktok)/
  const isUrlRegExp = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|(www\.)?[a-zA-Z0-9]\.[^\s]{2,}/
  const isTwitterPost = /\/status\//
  const isInstagramPost = /\/p\//
  const isInstagramStory = /\/stories\//
  const isInstagramReel = /\/reel\//
  const isYoutubePost = /\/watch/
  const isYoutubeShort = /\/shorts/
  const isTiktokPost = /\/video\//
  const isTiktokCarrousel = /\/photo\//
  const checkRepeatedValue = (text, group) => (text.match(group) || []).length > 1
  const checkRepeatedValues = (text, groups) => some(groups, group => checkRepeatedValue(text, group))

  if (!isNetworkReg.test(url) || !isUrlRegExp.test(url) || checkRepeatedValues(url, [/www./g, /http:/g, /https:/g, /http:|https:/g])) {
    return t('commons.insights.url.input.helpertext.notValidPublicationUrl.error')
  }

  if (url.includes('twitter') && !isTwitterPost.test(url)) {
    return t('commons.insights.url.input.helpertext.notAvalidSocialPublication.error', { socialNetwork: 'Twitter' })
  }

  if (url.includes('instagram') && !isInstagramPost.test(url) && !isInstagramStory.test(url) && !isInstagramReel.test(url)) {
    return t('commons.insights.url.input.helpertext.notAvalidSocialPublication.error', { socialNetwork: 'Instagram' })
  }
  if (type === 'automatic' && url.includes('instagram') && isInstagramStory.test(url)) {
    return t('commons.insights.url.input.helpertext.storiesNotSupported.error')
  }

  if (url.includes('youtube') && !isYoutubePost.test(url) && !isYoutubeShort.test(url)) {
    return t('commons.insights.url.input.helpertext.notAvalidSocialPublication.error', { socialNetwork: 'YouTube' })
  }

  if (url.includes('tiktok') && !isTiktokPost.test(url) && !isTiktokCarrousel.test(url)) {
    return t('commons.insights.url.input.helpertext.notAvalidSocialPublication.error', { socialNetwork: 'TikTok' })
  }
  return null
}

export const postNetworkValidator = (url, networks) => {
  if (isEmpty(networks)) return true
  const networksToCheck = networks.reduce((prev, curr, i) => (prev + curr + `${i !== networks.length - 1 ? '|' : ''}`), '')
  const networkRegEx = new RegExp(`(${networksToCheck})`, 'i')
  return networkRegEx.test(url) ? null : true
}

export const isFormEmpty = values => {
  const isAutomaticFormEmpty = !get(values, 'urls.length')
  const isManualFormEmpty = !get(values, 'username') || !get(values, 'network') || !get(values, 'type')
  return isAutomaticFormEmpty && isManualFormEmpty
}

export const getErrorMessage = (errors, t) => {
  const is12010 = get(errors, 'message') === '12010'
  const is12001 = get(errors, 'message') === '12001'
  const is476 = get(errors, 'message') === '476'
  if (is12010) {
    return {
      text: t('reports.report.modal.addPublication.automaticAnalyses.urls.input.helpertext.error.limitUrls'),
      showInFooter: true
    }
  }
  if (is12001) {
    return {
      text: t('reports.report.modal.addPublication.automaticAnalyses.urls.input.helpertext.error'),
      urls: get(errors, 'graphQLErrors[0].extensions.errors', []).map(({ url }) => url)
    }
  }
  if (is476) {
    return (
      <>
        {t('reports.report.modal.addPublication.automaticAnalyses.urls.input.helpertext.error.limitPublications')}
        <a
          data-cy='addPostsLimitReached-link' href={'mailto:info@influencity.com?Subject=I\'m%20interested%20in%20increase%20my%20posts%20limit'}
        >{t('commons.button.contactUs')}
        </a>
      </>
    )
  }
  return t('commons.errorMessage.tryAgainLater')
}

export const getForm = ({ network, type }) => type ? `${network}-${type}` : undefined

export const usernameValidator = username => /^@/.test(username)

export const getTrackingErrorMessage = (errors, t) => {
  switch (errors && errors.message) {
    case '12100':
      return {
        text: t('reports.modal.tracking.trackingInformationBlock.input.trackingName.helpertext.nameAlreadyInUse.error'),
        code: '12100'
      }
    case '12110':
      return {
        text: t('reports.report.modal.addPublication.tracking.limitReached.titleHeader1')
      }
    default:
      return {
        text: t('commons.errorMessage.tryAgainLater')
      }
  }
}
