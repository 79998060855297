import { useRef } from 'react'
import { Button, Flex, Tooltip } from 'influ-dms'
import styled, { css } from 'styled-components'

const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

const styles = css`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ theme }) => `color: ${theme.colors.primary.light1};`}
  margin: 0;
  padding: 4px 10px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
`

const activeStyles = css`
  ${({ theme }) => `color: ${theme.colors.primary.dark};`}
  > p {
    ${({ theme }) => `color: ${theme.colors.primary.dark};`}
  }
  ${({ theme }) => `background: ${theme.colors.primary.light5};`}
`

const MenuButtonWithDropdown = styled(Flex)`
  position: relative;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  ${({ isExpanded }) => isExpanded && 'min-width: 184px;'}
  transition: min-width 0.5s ease;
  ${({ isActive }) => isActive && activeStyles}
  ${({ theme, dropdownOpened }) => dropdownOpened && `background: ${theme.colors.primary.light5};`}
  &:hover {
    ${({ theme }) => `background: ${theme.colors.primary.light5};`}
  }
  border-radius: 4px;
`

export const MenuButton = ({
  children,
  key,
  tooltipText,
  isActive,
  isExpanded,
  setIsExpanded,
  product,
  subMenu,
  onProductClick,
  dropdownOpened,
  setMenuDropdownOpened,
  setProductOpened,
  productInfo
}) => {
  const ref = useRef(null)

  const toggleOpen = () => {
    setIsExpanded && !isExpanded && setIsExpanded(true)
    setProductOpened(productInfo)
    setMenuDropdownOpened && setMenuDropdownOpened(true)
  }

  return (
    <MenuButtonWithDropdown
      ref={ref}
      column
      justify='flex-start'
      isExpanded={isExpanded}
      isActive={isActive}
      dropdownOpened={dropdownOpened}
    >
      {tooltipText
        ? <Tooltip
            key={key}
            text={tooltipText}
            place='right'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
          <Button
            name={'MenuButton-' + product}
            styles={[styles, isActive && activeStyles]}
            variant='flat'
            color='secondary'
            onClick={() => subMenu ? toggleOpen() : onProductClick(product)}
            paddingSideways='0px'
          >
            {children}
          </Button>
        </Tooltip>
        : <Button
            name={'MenuButton-' + product}
            styles={[styles, isActive && activeStyles]}
            variant='flat'
            color='secondary'
            onClick={() => subMenu ? toggleOpen() : onProductClick(product)}
            paddingSideways='0px'
          >
          {children}
        </Button>
      }
    </MenuButtonWithDropdown>
  )
}
