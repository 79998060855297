import { gql } from '@apollo/client'

export const addPaymentInvoice = gql`
  mutation addPaymentInvoice(
    $name: String!
    $currency: String!
    $description: String
    $orderNumber: String
  ) {
    addPaymentInvoice(
      name: $name
      currency: $currency
      description: $description
      orderNumber: $orderNumber
    ) {
      message
      success
      data
    }
  }
`
