import { useContext } from 'react'
import { RoundedButton } from 'influ-dms'
import { LimitsContext } from '~/context'

const ExportButton = props => {
  const { canExport, getFile, loading, color, size, styles, isDisabled } = props
  const { validateLimit } = useContext(LimitsContext)

  const onClick = () => {
    if (validateLimit(canExport)) getFile()
  }

  return (
    <RoundedButton
      isDisabled={loading || isDisabled}
      onClick={onClick}
      name='download'
      downWhenDisabled
      color={color}
      size={size}
      styles={styles}
    />)
}

export { ExportButton }
