import { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  Flex,
  HorizontalOverhang,
  TableV2,
  editableTableBodyStyles,
  editableTableHeaderStyles,
  editableTableTableStyles,
  TableEditableSelectedHeader,
  TableEditableFiltersBar,
  EmptyState,
  TableContext
} from 'influ-dms'
import { useMutation } from '@apollo/client'
import { updateTableConfigMutation } from '~/graphql/commons'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { Pagination } from './pagination'
import { ReportsTableHeaderRefresh } from './actions/ReportsTableHeaderRefresh'
import { ReportsTableHeaderDelete } from './actions/ReportsTableHeaderDelete'
import { useTranslate } from '~/hooks/useTranslate'
import { useCleanCache } from '../../../irm/commons/hooks'

const StyledContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px 24px 0px 24px;
  height: calc(100% - ${({ tableId }) => tableId === 'influencers' ? '16px' : '0px'});
  box-sizing: border-box;
  flex-direction: column;
`

const hoverStyles = css`
  :hover {
    > div {
      background: ${({ theme }) => theme.colors.primary.light6} !important;
    }
  }
`

export const ReportsTableContainer = ({ tableId, columns, data, initialColumns, loading, initialSortBy, searchPlaceholder }) => {
  const { clearProductTablesConfigCache } = useCleanCache()
  const { t } = useTranslate()
  const { dispatch, state: { count } } = useContext(TableContext)
  const [updateTableConfig] = useMutation(updateTableConfigMutation, { update (cache, _, options) { clearProductTablesConfigCache(_, options) } })
  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const onDropColumn = columns => {
    updateTableConfig({ variables: { brandId, product: 'reportsV2', type: tableId, value: columns } }).catch(() => {})
  }

  useEffect(() => {
    if (count !== data.length) {
      dispatch({ type: 'setCount', payload: data.length })
    }
  }, [data])

  return (
    <StyledContainer tableId={tableId}>
      <TableV2
        initialColumns={initialColumns}
        columns={columns}
        data={data}
        loading={loading}
        emptyStateComponent={
          <EmptyState
            imageUrl='/static/img/reports/ReportEmpty.png'
            titleText={t('reports.overview.allReports.empty.titleHeader1')}
            subtitleText={t('reports.overview.allReports.empty.titleParagraph1')}
            imageStyles={{ width: '500px' }}
          />
        }
        selectedHeader={({ headerGroup, selectedFlatRows, styles }) => {
          const ids = selectedFlatRows.map(({ _id }) => _id)
          const deleteActionProp = headerGroup?.headers?.find(({ deleteAction }) => deleteAction !== undefined && deleteAction)?.deleteAction
          return (
            <TableEditableSelectedHeader tableId={tableId} headerGroup={headerGroup} selectedFlatRows={selectedFlatRows} styles={styles}>
              {tableId === 'publications' && (
                <ReportsTableHeaderRefresh ids={ids} />
              )}
              <ReportsTableHeaderDelete ids={ids} deleteAction={deleteActionProp || {}}
              />
            </TableEditableSelectedHeader>
          )
        }}
        navBarFilter={({ setGlobalFilter, columns, setHiddenColumns }) => (
          <TableEditableFiltersBar
            tableId={tableId}
            name='reportListSearch'
            columns={columns}
            searchPlaceholder={searchPlaceholder}
            setGlobalFilter={setGlobalFilter}
            onColumnChange={onDropColumn}
            setHiddenColumns={setHiddenColumns}
          />
        )}
        horizontalOverhang={({ children }) => (
          <HorizontalOverhang>
            {ref => children({ ref })}
          </HorizontalOverhang>
        )}
        pagination={({ canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage, pageIndex }) => (
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex + 1}
          />
        )}
        initialSortBy={initialSortBy}
        onDropColumn={onDropColumn}
        styles={editableTableTableStyles}
        headerStyles={editableTableHeaderStyles}
        bodyStyles={[editableTableBodyStyles, hoverStyles]}
      />
    </StyledContainer>
  )
}
