import { useContext, useEffect } from 'react'
import { Flex, FormContext, useArray, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { AttachmentListContainer } from '../../../../../irm/influencers/commons/modals/SendMassiveEmailModals/styles'
import { MESSAGE_LIMITS } from '../../../CommunicationConstants'
import { NewEmailModalFooterSend } from './NewEmailModalFooterSend'
import { NewEmailModalFooterAttachmentButton } from './NewEmailModalFooterAttachmentButton'
import { NewEmailModalFooterAttachment } from './NewEmailModalFooterAttachment'
import styled from 'styled-components'

const StyledFlex = styled(Flex)`
  padding-top: ${({ hasFiles }) => hasFiles ? '16px' : 0};
`

export const NewEmailModalFooter = ({ isOpen, loading, handleSend }) => {
  const { t } = useTranslate()
  const { setValue } = useContext(FormContext)
  const [files, setFiles] = useArray([])
  const showWarningText = files.length > MESSAGE_LIMITS.attachmentCount

  useEffect(() => {
    setFiles([])
  }, [isOpen])

  useEffect(() => {
    setValue('InputText', 'attachments', files)
  }, [files.length])

  return (
    <div>
      <AttachmentListContainer>
        {files.map(file => <NewEmailModalFooterAttachment key={file.id} file={file} setFiles={setFiles} />)}
      </AttachmentListContainer>
      {showWarningText && <Text color='state.warningDefault'>{t('commons.communication.newEmail.modal.footer.limitFilesAllowed', { limitFilesAllowed: MESSAGE_LIMITS.attachmentCount })}</Text>}
      <StyledFlex align='center' hasFiles={!!files.length}>
        <NewEmailModalFooterSend isOpen={isOpen} loading={loading} handleSend={handleSend} />
        <NewEmailModalFooterAttachmentButton setFiles={setFiles} />
      </StyledFlex>
    </div>
  )
}
