import { gql } from '@apollo/client'

const exportCostQuery = gql`
 query exportSearchCost(
    $brandId: String!
    $filterParams: DeepSearchFilterParamsTypeInput,
    $limit: Int,
    $sortBy: SortByOptions,
    $sortOrder: OrderByOptions,
    $network: NetworkOptions,
    $ranges: RangeObjectType,
   ) {
    exportSearchCost(
        brandId: $brandId,
        filterParams: $filterParams,
        limit: $limit,
        sortBy: $sortBy,
        sortOrder: $sortOrder,
        network: $network,
        ranges: $ranges
     )
   }
   `

export { exportCostQuery }
