import { gql } from '@apollo/client'

export const updateInfluencerNote = gql`
  mutation updateInfluencerNote($_id: String!, $text: String!) {
    updateInfluencerNote(_id: $_id, text: $text) {
      id: _id
      text
      influencerId
      brandId
      author {
        userId
        fullName
        image
      }
      updateUser {
        userId
        fullName
        image
      }
      createdAt
      hasUpdatePermission
    }
  }
`
