import { useContext } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { Flex, FormContext, SideBox, Text } from 'influ-dms'
import { EditPostModalContentAutomaticAutomaticMetric } from './EditPostModalContentAutomaticAutomaticMetric'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../../../addPosts/manual/styles'

const StyledFlex = styled(Flex)`
  padding: 16px 16px 8px 16px;
`

const LIKES_AND_VIEWS_COUNT_DISABLED_TOOLTIP = 'commons.stats.metric.likes.noAvailable.tooltip'

export const EditPostModalContentAutomaticMetrics = () => {
  const { t } = useTranslate()
  const { values: { dislikes, likes, comments, engagement, totalInteractions, videoViews, videoPlays, shares, saves, type, additionalMetrics, dataSource, likeAndViewCountDisabled } } = useContext(FormContext)

  const replies = get(additionalMetrics, 'replies')
  const navigationForward = get(additionalMetrics, 'navigationForward')
  const navigationBack = get(additionalMetrics, 'navigationBack')
  const exits = get(additionalMetrics, 'exits')
  const reach = get(additionalMetrics, 'reach')
  const impressions = get(additionalMetrics, 'impressions')
  const videoSaves = saves || get(additionalMetrics, 'saves')

  const showNumber = n => typeof n === 'number'
  const isTrakcing = dataSource === 'tracking'
  const isReel = type === 'reel'

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationInsightsblock.title')}</Text>
      <StyledFlex wrap='wrap'>
        {isTrakcing && showNumber(replies) && <EditPostModalContentAutomaticAutomaticMetric value={replies} type='humanized' label={t('commons.stats.metric.replies.label')} icon='comments' />}
        {likeAndViewCountDisabled
          ? <EditPostModalContentAutomaticAutomaticMetric value={showNumber(likes) ? likes : 'N/A'} type='humanized' label={t('commons.stats.metric.likes.label')} icon='heart-line' tooltip={showNumber(likes) ? '' : t(LIKES_AND_VIEWS_COUNT_DISABLED_TOOLTIP)} />
          : showNumber(likes) && <EditPostModalContentAutomaticAutomaticMetric value={likes} type='humanized' label={t('commons.stats.metric.likes.label')} icon='heart-line' />}
        {showNumber(dislikes) && <EditPostModalContentAutomaticAutomaticMetric value={dislikes} type='humanized' label={t('commons.stats.metric.dislikes.label')} icon='dislike' />}
        {showNumber(comments) && <EditPostModalContentAutomaticAutomaticMetric value={comments} type='humanized' label={t('commons.stats.metric.comments.label')} icon='comments' />}
        {isTrakcing && showNumber(reach) && <EditPostModalContentAutomaticAutomaticMetric value={reach} type='humanized' label={t('commons.stats.metric.reach.label')} icon='reach' />}
        {isTrakcing && showNumber(navigationForward) && <EditPostModalContentAutomaticAutomaticMetric value={navigationForward} type='humanized' label={t('commons.stats.metric.tapsFoward.label')} icon='swipe left' />}
        {isTrakcing && showNumber(navigationBack) && <EditPostModalContentAutomaticAutomaticMetric value={navigationBack} type='humanized' label={t('commons.stats.metric.tapsBack.label')} icon='swipe right' />}
        {isTrakcing && showNumber(exits) && <EditPostModalContentAutomaticAutomaticMetric value={exits} type='humanized' label={t('commons.stats.metric.exits.label')} icon='exit' />}
        {isTrakcing && showNumber(impressions) && <EditPostModalContentAutomaticAutomaticMetric value={impressions} type='humanized' label={t('commons.stats.metric.impressions.label')} icon='print' />}
        {showNumber(engagement) && <EditPostModalContentAutomaticAutomaticMetric value={engagement} type='percentage' label={t('commons.stats.metric.engagement.label')} icon='target' />}
        {isTrakcing && showNumber(videoSaves) && <EditPostModalContentAutomaticAutomaticMetric value={videoSaves} type='humanized' label={t('commons.stats.metric.saves.label')} icon='bookmark' />}
        {showNumber(videoViews) && <EditPostModalContentAutomaticAutomaticMetric value={videoViews} type='humanized' label={t('commons.stats.metric.videoViews.label')} icon={isReel ? 'eye' : 'videoplay'} />}
        {showNumber(videoPlays) && <EditPostModalContentAutomaticAutomaticMetric value={videoPlays} type='humanized' label={t('commons.stats.metric.plays.label')} icon='videoplay' />}
        {showNumber(totalInteractions) && <EditPostModalContentAutomaticAutomaticMetric value={totalInteractions} type='humanized' label={t('commons.stats.metric.totalInteractions.label')} icon='interactions' />}
        {showNumber(shares) && <EditPostModalContentAutomaticAutomaticMetric value={shares} type='humanized' label={t('commons.stats.metric.shares.label')} icon='share' />}
        {showNumber(saves) && <EditPostModalContentAutomaticAutomaticMetric value={saves} type='humanized' label={t('commons.stats.metric.saves.label')} icon='bookmark' />}
      </StyledFlex>
    </SideBox>
  )
}
