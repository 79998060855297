import { gql } from '@apollo/client'

export const getProgramsV2ByIrmInfluencer = gql`
  query getProgramsV2ByIrmInfluencer(
    $influencerId: String!
    $sortParams: ProgramV2SortParamsType
    $page: Int
    $limit: Int
    $filters: [NewFilterParamsType]!
  ) {
    getProgramsV2ByIrmInfluencer(
      influencerId: $influencerId
      sortParams: $sortParams
      page: $page
      limit: $limit
      filters: $filters
    ) {
      id: _id
      brandId
      name
      description
      status
      rawStatus
      influencersCount
      irmInfluencers {
        _id
      }
      createdAt
      updatedAt
      createdByUser {
        firstName
      }
      updatedByUser {
        firstName
      }
    }
  }
`