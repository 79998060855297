import styled from 'styled-components'
import { Flex, Image, Text } from 'influ-dms'

const StyledContainer = styled(Flex)`
  width: 70%;
  max-width: 747px;
  border: 1px solid ${({ theme }) => theme.colors.primary.light3};
  border-radius: 4px;
  padding: 48px;
`

const StyledImage = styled(Image)`
  max-height: 153px;
  margin-bottom: 8px;
`

export const EmptyCard = ({ text }) => (
  <StyledContainer align='center' justify='center' column>
    <StyledImage src='/static/img/placeholders/emptyNotes.png' alt='emptyCard' />
    <Text color='primary.light2' size='10'>{text}</Text>
  </StyledContainer>
)
