import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { firstPromotedDebug, nodeEnv } = publicRuntimeConfig
const showGetBeamer = (publicRuntimeConfig?.showBeamer && publicRuntimeConfig?.showBeamer === 'true')

// Third party imports
export const renderGA = (isResponsiveRoute) => {
  if (isResponsiveRoute && nodeEnv === 'production') {
    return (
      <>
        <script async src='https://www.googletagmanager.com/gtag/js?id=UA-49608554-2' />
        <script async src='https://www.googletagmanager.com/gtag/js?id=AW-959839886' />
        <script id='google-analytics'>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'AW-959839886');
            gtag('config', 'UA-49608554-2');
          `}
        </script>
      </>
    )
  }
}

export const renderFirstPromoted = () => {
  if (nodeEnv === 'production' || firstPromotedDebug === 'true') {
    return (
      <script id='first-promoted'>
        {`
          (function(){var t=document.createElement('script');t.type='text/javascript',t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||'complete'==t||'loaded'==t)try{$FPROM.init('5mviqlet','.influencity.com')}catch(t){}};var e=document.getElementsByTagName('script')[0];e.parentNode.insertBefore(t,e)})();
        `}
      </script>
    )
  }
}

export const renderBeamer = () => {
  if (showGetBeamer) {
    return (
      <>
        <script id='get-beamer'>
          {
            `var beamer_config = {
              product_id : 'SVfigVeo53001', //DO NOT CHANGE: This is your product code on Beamer
              button: false,
              display : 'right',
              lazy: false,
              alert : true,
            };`
          }
        </script>
        <script type='text/javascript' src='https://app.getbeamer.com/js/beamer-embed.js' defer/>
      </>
    )
  }
}

export const renderHubspot = () => {
  if (nodeEnv !== 'development') {
    return (
      <script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/3449185.js' />
    )
  }
}

export const renderIframely = () => {
  return (
    <script async src='//cdn.iframe.ly/embed.js'></script>
  )
}
