import styled, { css } from 'styled-components'
import {
  CellWrapper,
  Flex,
  HoverAction,
  Text,
  HoverWrapper,
  Icon,
  Spacing,
  Tooltip
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'

const cellEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const cellPadding = css`
  padding: 0 24px;
  ${cellEllipsis};
`

const imagePostStyles = css`
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 4px;
  background-image: url('${({ image }) => image}'),
    url('/static/img/placeholders/avatarPlaceholder.png');
  background-size: cover;
  background-position: center;
`

const StyledContainer = styled(Flex)`
  ${cellPadding};
`

const ImageContainer = styled.div`
  width: 30px;
  height: 30px;
  ${imagePostStyles};
  border-radius: 50%;
`
const clickableStyles = css`
  :hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-decoration: underline;
  }
`

const StyledFlex = styled(Flex)`
  width: 97%;
`
const widgetTooltipContainerStyles = css`
  overflow: hidden;
`

const widgetTooltipStyles = css`
  max-width: 183px;
  width: unset;
`

const textWrapperStyles = css`
  overflow: hidden;
`

// const loaderStyles = css`
//   padding: 2px 5px 0 0;
// `

export const ProfilesListTableTdProfileName = ({
  row: { original },
  listId,
  profileNetwork,
  onListClick,
  onProfileClick,
  onDeleteClick
}) => {
  const { t } = useTranslate()
  const router = useRouter()
  const { sharedId } = router.query
  // const { profilesLoading } = useContext(NotifyProfileDataProcessedContext)
  const profile = original || {}
  const { username, profilePhotoUrl, profilePicture, isVerified, streamPartnershipStatus } = profile
  // const profileIsLoading = profilesLoading.some((profileId) => profileId === profile._id)

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center'>
          {/* {profileIsLoading && (
            <GenericLoader size='s' styles={loaderStyles} />
          )} */}
          <Flex>
            <Icon key={profileNetwork} name={profileNetwork} size='18' color='primary.dark' />
          </Flex>
          <Spacing vertical size={5} />
          <ImageContainer image={profilePhotoUrl || profilePicture} />
          <Flex column styles={textWrapperStyles}>
            {onProfileClick ? (
              <Text
                name={`Profile-Name-${username}`}
                ellipsis
                showTooltip
                styles={clickableStyles}
                size='14'
                color='primary.light2'
                bold
                onClick={onProfileClick}
              >{`@${username}`}</Text>
            ) : (
              <Text
                name={`Profile-Name-${username}`}
                ellipsis
                showTooltip
                size='14'
                color='primary.light2'
                bold
              >{`@${username}`}</Text>
            )}
          </Flex>
          {isVerified && (
            <>
              <Spacing vertical size={5} />
              <Flex>
                <Tooltip
                  text={t('entity.profile.field.verified.value.verified.tooltip')}
                  place='right'
                  type='dark'
                  color='neutral.white'
                  containerStyles={widgetTooltipContainerStyles}
                  styles={widgetTooltipStyles}
              >
                  <Icon key='verified' name='verified' size='12' color='blue.disabled' />
                </Tooltip>
              </Flex>
            </>
          )}
          {streamPartnershipStatus?.key && (
            <>
              <Spacing vertical size={5} />
              <Flex>
                <Tooltip
                  text={streamPartnershipStatus?.key === 'partner'
                    ? t('entity.profile.field.partnershipStatus.value.partner.tooltip')
                    : streamPartnershipStatus?.key === 'affiliate'
                      ? t('entity.profile.field.partnershipStatus.value.affiliate.tooltip')
                      : null}
                  place='right'
                  type='dark'
                  color='neutral.white'
                  containerStyles={widgetTooltipContainerStyles}
                  styles={widgetTooltipStyles}
              >
                  {
                  streamPartnershipStatus?.key === 'partner'
                    ? <Icon key='shield' name='shield' size='12' color='blue.disabled' />
                    : streamPartnershipStatus?.key === 'affiliate'
                      ? <Icon key='affiliate' name='affiliate' size='12' color='blue.disabled' />
                      : null
                }
                </Tooltip>
              </Flex>
            </>
          )}
        </StyledContainer>
        {!sharedId && (
          <HoverWrapper>
            {onListClick && (
              <Tooltip
                text={t('entity.profile.tool.addToList.tooltip')}
                place='bottom'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
              >
                <HoverAction onClick={onListClick} iconName='newlist' />
              </Tooltip>
            )}
            {onDeleteClick && (
              <Tooltip
                text={t(listId ? 'commons.button.remove.tooltip' : 'commons.button.delete.tooltip')}
                place='top'
                type='dark'
                color='neutral.white'
                containerStyles={widgetTooltipContainerStyles}
                styles={widgetTooltipStyles}
              >
                <HoverAction onClick={onDeleteClick} iconName={listId ? 'remove' : 'trash'} />
              </Tooltip>
            )}
          </HoverWrapper>
        )}
      </StyledFlex>
    </CellWrapper>
  )
}
