import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Flex, Form, FormContext, SideBox, Spacing, Text } from 'influ-dms'
import { headerStyles } from '../../../addPosts/manual/styles'
import { EditInfluencerModalContentTotalCost } from './EditInfluencerModalContentTotalCost'
import { EditInfluencerModalContentPost } from './EditInfluencerModalContentPost'
import { CurrencyContext } from '../../../../../commons/context/CurrencyContext'
import { costFormatterWithDecimals } from '../../../../dashboard/widgets/helper'
import { useTranslate } from '~/hooks/useTranslate'

const sideBoxStyles = css`
  margin-top: 32px;
`

const PostContainer = styled.div`
  margin: 32px 0;
`

const TotalCostContainer = styled(Flex)`
  margin-right: 48px;
`

export const EditInfluencerModalContentCosts = ({ posts }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const { symbol } = useContext(CurrencyContext)
  const influencerCost = costFormatterWithDecimals(values.influencerCost, symbol)
  const postCost = Object.entries(values.publicationCosts || {}).reduce((acc, [key, value]) => Number.isFinite(value) ? value + acc : acc, null)
  const finalCost = costFormatterWithDecimals(postCost, symbol)

  return (
    <SideBox styles={sideBoxStyles}>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.profile.costBlock.title')}</Text>
      <Spacing size='16' />
      <Form.InputNumber
        placeholder={`${t('reports.report.modal.profile.costBlock.influencerCost.input.placeholder')} (${symbol ?? '$'})`}
        name='influencerCost'
        label={`${t('reports.report.modal.profile.costBlock.influencerCost.input.label')} (${symbol ?? '$'})`}
        helperText={t('reports.report.modal.profile.costBlock.influencerCost.input.helpertext')}
        withDecimal
      />
      <Spacing size='4' />
      <PostContainer>
        {posts.map(post => <EditInfluencerModalContentPost key={post.postId} {...post} />)}
      </PostContainer>
      <TotalCostContainer justify='flex-end'>
        <EditInfluencerModalContentTotalCost value={influencerCost} text={t('reports.report.modal.profile.costBlock.influencerCost.input.label')} />
        <EditInfluencerModalContentTotalCost value={finalCost} text={t('reports.report.modal.profile.costBlock.publicationCost.input.label')} />
      </TotalCostContainer>
      <Spacing size='16' />
    </SideBox>
  )
}
