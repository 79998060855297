import { Button, Flex, Modal, Spacing, StateButton, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const ModalUnlinkOrDelete = ({
  isOpen,
  onClose,
  title,
  color = 'state.warningActive',
  header,
  paragraph,
  loadingConfirm,
  handleConfirm,
  confirmButtonText,
  confirmButtonColor
}) => {
  const { t } = useTranslate()

  return (
    <Modal
      name='modalUnlinkOrDelete'
      isOpen={isOpen}
      onClose={onClose}
      title={t(title)}
      color={color}
    >
      <Flex column grow='1' gap='8px'>
        <Text bold color='primary.dark' size='14'>
          {t(header)}
        </Text>
        <Text color={'primary.light2'} size='14'>
          {t(paragraph)}
        </Text>
      </Flex>
      <Flex>
        <StateButton
          name='confirmButton'
          color={confirmButtonColor}
          disabled={loadingConfirm}
          onClick={() => {
            handleConfirm()
          }}
          showLoader
          loading={loadingConfirm}
        >
          {t(confirmButtonText)}
        </StateButton>
        <Spacing vertical size='16' />
        <Button name='cancelConfirm' color='secondary' variant='flat' onClick={onClose}>
          {t('commons.button.cancel')}
        </Button>
      </Flex>
    </Modal>
  )
}
