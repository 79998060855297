import { gql } from '@apollo/client'
import fragments from './fragments'

export const cities = gql`
  query cities($countryCode: String!){
    cities(countryCode: $countryCode){
      name
    }
  }
`

export const self = gql`
query user {
  self{
    id: _id
    email
    locale
    requestChangePassword
    intercomId
    analyticsId
    roles
    contact {
      _id
      jobPosition
      hubspotSteps
    }
  }
}
`

export const saveLocale = gql`
mutation saveLocale($locale: String, $browserLocale: String) {
  saveLocale(locale: $locale, browserLocale : $browserLocale) {
    success
  }
}
`

export const confirmNewPassword = gql`
mutation confirmNewPassword($password: String!, $token: String!) {
  confirmNewPassword(password: $password, token: $token){
    success
    message
    data
  }
}
`

export const selfWithInitialData = gql`
query user {
  self{
    id: _id
    email
    locale
    intercomId
    analyticsId
    contact{
    ...Contact
    }
    brands {
       id: _id
       logoImage
       socialUsername
       brandName
       description
       products
       subscriptionActive
       owners{
         id:_id
       }
       trial {
         isTrial
         expired
         expirationDate
         startDate
         remainingDays
         totalDays
       }
       billingInfo {
          id: _id
          planId         
          planItems {
            nickname
            quantity
          }
          planType
          planPeriod
          subscriptionStartAt
       }
    }
  }
}
${fragments.contact}
`
export const login = gql`
mutation login($email: String!, $password: String!) {
  login(email: $email, password:$password){
    token
  }
}
`

export const validateEmailInHubspot = gql`
 mutation validateEmailInHubspot {
  validateEmailInHubspot{
      message
      success
    }
 }
 `

export const logout = gql`
  mutation logout($token: String!) {
    logout(token: $token){
      success
    }
  }
`
