import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { headerStyles } from '../../styles'
import { AddPostsManualInstagramTaggedPeople } from './AddPostsManualInstagramTaggedPeople'
import { useTranslate } from '~/hooks/useTranslate'

export const AddPostsManualInstagramReelInfo = ({ multiple }) => {
  const { t } = useTranslate()
  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.publicationInfo.title')}</Text>
      <Spacing size='24' />
      <Form.InputFile
        name='image'
        deletedName='deletedImages'
        changeId='publicationId'
        label={multiple ? t('commons.insights.images.input.label') : t('commons.insights.image.input.label')}
        type='file'
        multiple={multiple}
        maxFiles={10}
        accept='image/png, image/jpeg'
      />
      <Spacing size='24' />
      <Form.InputText placeholder={t('commons.insights.description.input.placeholder')} name='caption' label={t('commons.insights.description.input.label')} />
      <Spacing size='24' />
      <Form.InputDate placeholder={t('commons.insights.publicationDate.input.placeholder')} name='publicationDate' label={t('commons.insights.publicationDate.input.label')} />
      <Spacing size='24' />
      <AddPostsManualInstagramTaggedPeople />
      <Spacing size='8' />
    </SideBox>
  )
}
