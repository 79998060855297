import { gql } from '@apollo/client'


export const notifyMentionsProcessed = gql`
subscription notifyMentionsProcessed($brandId: String!){
    notifyMentionsProcessed(brandId: $brandId){
    alertId
  }
}
`
