import styled from 'styled-components'

export const MenuButtons = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 40px 0px;
  gap: 16px;
`
