export const ROOT_QUERY = 'ROOT_QUERY'

export const profileTableQueries = {
  GET_TABLE_PROFILES_BY_BRAND: 'getTableProfilesByBrand',
  COUNT_TABLE_PROFILES_BY_BRAND: 'countTableProfilesByBrand',
  PROFILES_ARE_ALREADY_IN_IRM: 'profilesAreAlreadyInIRM',
  IRM_SUMMARY: 'irmSummary',
  IRM_SUMMARY_BY_NETWORK: 'irmSummaryByNetwork',
  LAST_PROFILE_ADDED: 'lastProfilesAdded'
}

export const profileInListQueries = {
  GET_IRM_LISTS_BY_PROFILE: 'getIRMListsByProfile',
  COUNT_TABLE_IRM_LISTS_BY_PROFILE: 'countTableIrmListsByProfile',
}

export const influencerInListQueries = {
  GET_IRM_LISTS_BY_INFLUENCER: 'getIRMListsByInfluencer',
  COUNT_TABLE_IRM_LISTS_BY_INFLUENCER: 'countTableIrmListsByInfluencer',
}

export const profilesInCampaignsQueries = {
  GET_CAMPAIGNS_BETA_BY_BRAND: 'getCampaignsbetaByBrand',
  COUNT_TABLE_CAMPAIGNS_BETA_BY_BRAND: 'countTableCampaignsbetaByBrand',
  TASKS: 'tasks'
}

export const profileQueries = {
  PROFILE: 'profile',
  PROFILE_BY_SHARED_LINK: 'profileBySharedLink',
  PROFILE_METRICS_QUERY: 'profileMetricsQuery'
}

export const influencerTableQueries = {
  GET_IRM_INFLUENCERS_BY_BRAND: 'getIRMInfluencersByBrand',
  COUNT_TABLE_IRM_INFLUENCERS_BY_BRAND: 'countTableIrmInfluencersByBrand',
  LAST_INFLUENCERS_ADDED: 'lastInfluencersAdded',
  GET_IRM_INFLUENCER_COLUMNS: 'getIrmInfluencerColumns'
}

export const influencerQueries = {
  GET_IRM_INFLUENCER: 'getIRMInfluencer',
  PROFILES_TO_PRINT: 'profilesToPrint'
}

export const listTableQueries = {
  GET_IRM_LISTS_BY_BRAND: 'getIRMListsByBrand',
  COUNT_TABLE_IRM_LISTS_BY_BRAND: 'countTableIrmListsByBrand',
  LAST_LISTS_ADDED: 'lastListsAdded',
}

export const listDetailQueries = {
  GET_IRM_LIST: 'getIRMList',
  GET_TABLE_PROFILES_IN_LIST_BY_BRAND: 'getTableProfilesInListByBrand',
  COUNT_TABLE_PROFILES_IN_LIST_BY_BRAND: 'countTableProfilesInListByBrand',
  GET_PROFILES_SUMMARY_IN_LIST: 'getProfilesSummaryInList',
  GET_TABLE_INFLUENCERS_IN_LIST_BY_BRAND: 'getTableInfluencersInListByBrand',
  COUNT_TABLE_INFLUENCERS_IN_LIST_BY_BRAND: 'countTableInfluencersInListByBrand',
}

export const productTableQueries = {
  GET_PRODUCT_TABLES_CONFIG_BY_BRAND: 'getProductTablesConfigByBrand'
}

export const profilePriceHistoryQueries = {
  GET_PROFILE_PRICES_HISTORY: 'getProfilePriceHistory'
}

export const entityViewQueries = {
  GET_ENTITY_VIEWS_BY_BRAND: 'getEntityViewsByBrand',
  COUNT_TABLE_ENTITY_VIEWS_BY_BRAND: 'countTableEntityViewsByBrand',
  GET_ENTITY_VIEWS_BY_OBJECT_AND_USER: 'getEntityViewsByObjectAndUser'
}

export const formPageQueries = {
  GET_FORM_PAGES_BY_BRAND: 'getFormPagesByBrand',
  COUNT_TABLE_FORM_PAGES_BY_BRAND: 'countTableFormPagesByBrand'
}

export const programTableQueries = {
  GET_PROGRAMS_BY_BRAND: 'getProgramsV2ByBrand',
  COUNT_TABLE_PROGRAMS_BY_BRAND: 'countProgramsV2ByBrand',
  GET_INFLUENCERS_IN_PROGRAM: 'getIrmInfluencersTableInProgramV2',
  COUNT_INFLUENCERS_IN_PROGRAM: 'countIrmInfluencersTableInProgramV2',
  GET_PROGRAM: 'getProgram',
  GET_PROGRAMS_IN_INFLUENCER: 'getProgramsV2ByIrmInfluencer',
  COUNT_PROGRAMS_IN_INFLUENCER: 'countProgramsV2ByIrmInfluencer'
}

export const codeTableQueries = {
  GET_AUDIENCE_CODES_BY_PROGRAM: 'getAudienceCodesByProgramId',
  COUNT_TABLE_AUDIENCE_CODES_BY_PROGRAM: 'countAudienceCodesByProgramId',
  GET_GIFTING_CODES_BY_PROGRAM: 'getGiftingCodesByProgramId',
  COUNT_TABLE_GIFTING_CODES_BY_PROGRAM: 'countGiftingCodesByProgramId',
  GET_AUDIENCE_CODES_BY_INFLUENCER: 'getAudienceCodeByInfluencerTable',
  COUNT_TABLE_AUDIENCE_CODES_BY_INFLUENCER: 'countAudienceCodeByInfluencerTable',
  GET_GIFTING_CODES_BY_INFLUENCER: 'getGiftingCodesByInfluencerTable',
}

export const domainQueries = {
  GET_DOMAINS_BY_BRAND: 'getDomainsByBrand',
  COUNT_TABLE_DOMAINS_BY_BRAND: 'countTableDomainsByBrand'
}

export const entityFieldGroupsQueries = {
  GET_ENTITY_FIELD_GROUPS_BY_BRAND: 'getEntityFieldGroupsByBrand',
  COUNT_ENTITY_FIELD_GROUPS_BY_BRAND: 'countTableEntityFieldGroupsByBrand'
}

export const entityFieldsQueries = {
  GET_ENTITY_FIELDS_BY_BRAND: 'getEntityFieldsByBrand',
  COUNT_ENTITY_FIELDS_BY_BRAND: 'countTableEntityFieldsByBrand',
  GET_GROUPED_ENTITY_FIELDS: 'getGroupedEntityFieldsByEntity',
  GET_ENTITY_FIELDS_BY_ENTITY: 'getEntityFieldsByEntity'
}

export const emailQueries = {
  GET_INFLUENCER_EMAILS: 'getInfluencerEmails',
  GET_EMAIL_ATTACHMENT: 'getEmailAttachment'
}

export const templateQueries = {
  GET_TEMPLATES_BY_BRAND: 'getTemplatesByBrand',
  COUNT_TABLE_TEMPLATES_BY_BRAND: 'countTableTemplatesByBrand',
}

export const applicationForms = {
  GET_APPLICATION_FORM_BY_ID: 'getApplicationFormByFormId'
}

export const configFilters = {
  GET_MT_CONFIG_FILTERS: 'getMTConfigFilters'
}

export const outreachQueries = {
  GET_OUTREACHES_BY_BRAND: 'getOutreachesByBrand',
  COUNT_TABLE_OUTREACHES_BY_BRAND: 'countTableOutreachesByBrand',
  GET_OUTREACH_COLUMNS: 'getOutreachColumns',
  GET_OUTREACH: 'getOutreach',
}

export const outreachRecipientQueries = {
  GET_OUTREACH_RECIPIENTS: 'getOutreachRecipients',
  COUNT_TABLE_OUTREACH_RECIPIENTS: 'countOutreachRecipients'
}

export const massiveCommQueries = {
  GET_MASSIVE_COMM_CONFIG: 'getMassiveCommConfig',
}

export const invoicesQueries = {
  GET_INVOICES_BY_BRAND: 'getPaymentInvoicesByBrand',
  COUNT_INVOICES_BY_BRAND: 'countPaymentInvoicesByBrand',
  GET_INVOICE: 'getPaymentInvoice',
  CAN_GENERATE_INVOICE: 'canGenerateInvoice'
}

export const paymentsQueries = {
  GET_PAYMENTS_BY_BRAND: 'getPaymentRequestsByInvoice',
  COUNT_PAYMENTS_BY_BRAND: 'countPaymentRequestsByInvoice',
  COUNT_INFLUENCERS_TO_PAY: 'countInfluencersToPayByInvoice',
  GET_PAYMENT_SUMMARY: 'getPaymentsSummary'
}

export const poolsQueries = {
  GET_POOLS_BY_BRAND: 'getPaymentPoolsByInvoice',
  COUNT_POOL_BY_BRAND: 'countPaymentPoolsByInvoice'
}

export const outreachInProgramV2Queries = {
  GET_OUTREACH_TABLE_IN_PROGRAM: 'getOutreachTableInProgramV2',
  COUNT_OUTREACH_TABLE_IN_PROGRAM: 'countOutreachTableInProgramV2'
}

export const alertQueries = {
  GET_ALERTS_BY_BRAND: 'getSocialListeningAlertTableByBrand',
  COUNT_TABLE_ALERTS_BY_BRAND: 'countSocialListeningAlertTableByBrand',
  GET_ALERT_COLUMNS: 'getSocialListeningAlertsTableColumns',
  GET_ALERT: 'getSocialListeningAlert',
  GET_ALERT_MENTIONS_TABLE_BY_ALERT: 'getSocialListeningAlertMentionsTableByAlert',
  COUNT_ALERT_MENTIONS_TABLE_BY_ALERT: 'countSocialListeningAlertMentionsTableByAlert'
}
