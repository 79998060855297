import { gql } from '@apollo/client'

export const getAudienceCodeByInfluencerTable = gql`
  query getAudienceCodeByInfluencerTable($influencerInProgramId: String!, $filters: [NewFilterParamsType], $sortParams: CodesSortParamsType, $page: Int, $limit: Int) {
    getAudienceCodeByInfluencerTable(influencerInProgramId: $influencerInProgramId, filters: $filters, sortParams: $sortParams, page: $page, limit: $limit) {
      _id
      code
      status
      rawStatus
      totalCodesUsed
      totalSales
      url
      influencerInProgramV2
      influencer {
        _id
      }
    }
  }
`
