import { useContext } from 'react'
import { FormContext } from 'influ-dms'
import { get } from 'lodash'
import { getForm } from '../../../addPosts/addPostsHelper'
import { AddPostsManualInstagramPhoto } from './subForms/AddPostsManualInstagramPhoto'
import { AddPostsManualInstagramCarousel } from './subForms/AddPostsManualInstagramCarousel'
import { AddPostsManualInstagramVideo } from './subForms/AddPostsManualInstagramVideo'
import { AddPostsManualInstagramReel } from './subForms/AddPostsManualInstagramReel'
import { AddPostsManualInstagramStory } from './subForms/AddPostsManualInstagramStory'
import { AddPostsManualTiktokVideo } from './subForms/AddPostsManualTiktokVideo'
import { AddPostsManualYoutubeVideo } from './subForms/AddPostsManualYoutubeVideo'
import { useTranslate } from '~/hooks/useTranslate'

export const EditPostsModalContentAutomaticFormOptions = () => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)
  const formName = getForm(values)
  const dataSource = get(values, 'dataSource')
  const status = get(values, 'status')
  if ((dataSource === 'automatic' || dataSource === 'tracking') && status === 'processing') return null
  const isTracking = dataSource === 'tracking'
  const likeAndViewCountDisabled = get(values, 'likeAndViewCountDisabled')
  const tooltipText = t('reports.report.modal.publication.additionalMetricsOfThepublicationBlock.title.tooltip')
  return (
    <>
      {formName === 'instagram-photo' && <AddPostsManualInstagramPhoto tooltipText={tooltipText} isTracking={isTracking} likeAndViewCountDisabled={likeAndViewCountDisabled} />}
      {formName === 'instagram-carousel' && <AddPostsManualInstagramCarousel tooltipText={tooltipText} isTracking={isTracking} likeAndViewCountDisabled={likeAndViewCountDisabled} />}
      {formName === 'instagram-video' && <AddPostsManualInstagramVideo tooltipText={tooltipText} isTracking={isTracking} likeAndViewCountDisabled={likeAndViewCountDisabled} />}
      {formName === 'instagram-reel' && <AddPostsManualInstagramReel tooltipText={tooltipText} likeAndViewCountDisabled={likeAndViewCountDisabled} />}
      {formName === 'instagram-story' && <AddPostsManualInstagramStory tooltipText={tooltipText} isTracking={isTracking} />}
      {(formName === 'tiktok-video' || formName === 'tiktok-photo') && <AddPostsManualTiktokVideo tooltipText={tooltipText} />}
      {(formName === 'youtube-video' || formName === 'youtube-short') && <AddPostsManualYoutubeVideo tooltipText={tooltipText} />}
    </>
  )
}
