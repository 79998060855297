import { useContext } from 'react'
import {
  Flex,
  Modal,
  Spacing,
  Portal,
  Text,
  Button
} from 'influ-dms'
import { ThemeContext } from 'styled-components'
import { LIMIT_OF_PROFILES_TO_ADD } from '../../../commons/addPosts/tracking/AddProfileTrackingCard'
import { useTranslate } from '~/hooks/useTranslate'

const AddProfileLimitTracking = (props) => {
  const { t } = useTranslate()
  const { isOpen, onClose } = props
  const { colors: { gradients } } = useContext(ThemeContext)

  return (
    <Portal selector='#addProfileLimitTracking'>
      <Modal
        name='addProfileLimitTracking'
        isOpen={isOpen}
        title={t('reports.modal.tracking.maximunProfilesPerTrackingReached.header')}
        height='auto'
        onClose={onClose}
        minHeight='200'
        gradient1={gradients[1][0]}
        gradient2={gradients[1][1]}
      >
        <Flex column grow='1'>
          <Text color='primary.dark' bold size='15'>{t('reports.modal.tracking.maximunProfilesPerTrackingReached.titleHeader1')}</Text>
          <Text color='primary.light3' size='14'>{t('reports.modal.tracking.maximunProfilesPerTrackingReached.titleParagraph1', { profilesNumber: LIMIT_OF_PROFILES_TO_ADD })}</Text>
          <Spacing size='42' />
        </Flex>
        <Flex>
          <Button
            onClick={onClose}
          >{t('commons.button.close')}
          </Button>
        </Flex>
      </Modal>
    </Portal>
  )
}

export { AddProfileLimitTracking }
