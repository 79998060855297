import { useContext, useState } from 'react'
import { DropdownOptionItem, Flex, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { MoreOptions } from '../../../commons/components/ActionButtons'
import { ListContext } from '../context'

export const MoreOptionsForList = ({ isDisabled, isSidebarOpen }) => {
  const { t } = useTranslate()
  const { setEditListModalOpen, openDeleteListModal } = useContext(ListContext)
  const [moreOptionsOpened, setMoreOptionesOpened] = useState(false)

  return (
    <MoreOptions isDisabled={isDisabled} isOpen={moreOptionsOpened} align={!isSidebarOpen ? 'left' : 'right'} setIsOpen={setMoreOptionesOpened} tooltipText={t('commons.button.moreActions.tooltip')}>
      <DropdownOptionItem
        onClick={() => setEditListModalOpen(true)}
        data-cy='edit-list'>
        <Flex>
          <Text size='14' ellipsis color='primary.light2'>
            {t('commons.button.editList')}
          </Text>
        </Flex>
      </DropdownOptionItem>
      <DropdownOptionItem
        onClick={() => openDeleteListModal()}
        data-cy='delete-list'>
        <Flex>
          <Text size='14' ellipsis color='primary.light2'>
            {t('commons.button.deleteList')}
          </Text>
        </Flex>
      </DropdownOptionItem>
    </MoreOptions>
  )
}
