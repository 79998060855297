import { createContext, useEffect, useState } from 'react'
import { get } from 'lodash'
import { useSubscription } from '@apollo/client'
import { useRouter } from 'next/router'
import { notifyIrmListDataProcesssed } from '~/containers/irm/apollo'
import { useCleanCache } from '../../../containers/irm/commons/hooks'

export const NotifyListDataProcessedContext = createContext({ value: {} })

const NotifyListDataProcessedProvider = (props) => {
  const { clearListDetailCache, clearListTableCache } = useCleanCache()
  const [refreshListId, setRefreshListId] = useState()

  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const listId = get(router, 'query.listId')
  const isPublic = router?.pathname?.includes('public')

  const { data: dataListProcessed } = useSubscription(notifyIrmListDataProcesssed, {
    variables: { brandId },
    skip: !brandId || isPublic
  })

  useEffect(() => {
    const subscriptionListId = get(dataListProcessed, 'notifyIrmListDataProcesssed.listId')
    const subscriptionStatus = get(
      dataListProcessed,
      'notifyIrmListDataProcesssed.subscriptionStatus'
    )

    if (subscriptionStatus === 'listSize') {
      setRefreshListId(subscriptionListId)
    }
  }, [dataListProcessed])

  useEffect(() => {
    const subscriptionListId = get(dataListProcessed, 'notifyIrmListDataProcesssed.listId')

    if (subscriptionListId && listId === subscriptionListId) {
      setRefreshListId(null)
      clearListDetailCache(refreshListId)
      clearListTableCache()
    }
  }, [router])

  return (
    <NotifyListDataProcessedContext.Provider
      value={{ refreshListId, setRefreshListId }}
    >
      {props.children}
    </NotifyListDataProcessedContext.Provider>
  )
}

export { NotifyListDataProcessedProvider }
