import { gql } from '@apollo/client'

export const setStatusReportV2Tracking = gql`
 mutation setStatusReportV2Tracking(
  $brandId: String!
  $reportV2Id: String!
  $trackingIds: [String]!
  $status: TrackingStatusOptions!
) {
  setStatusReportV2Tracking(
    brandId: $brandId,
    reportV2Id: $reportV2Id,
    trackingIds: $trackingIds
    status: $status
    ) {
      success
    }
 }
`
