import { useState } from 'react'
import styled from 'styled-components'
import { WidgetKpi } from 'influ-dms'

const StyledDiv = styled.div`
  flex: 50%;
  :nth-last-child(n+3) {
    padding-bottom: 16px;
  }
`

export const EditPostModalContentAutomaticAutomaticMetric = ({ icon = 'user', value, type, label = 'Label', tooltip }) => {
  const [initialLikes] = useState(value) // Done to prevent data being displayed on the fly
  return (
    <StyledDiv>
      <WidgetKpi icon={icon} value={initialLikes} type={type} label={label} tooltip={tooltip} />
    </StyledDiv>
  )
}
