import styled, { css } from 'styled-components'

export const buttonCustomStyles = css`
  margin: 4px;
  color: ${({ isActive }) => isActive ? 'white' : '#270038'};
`

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
`

export const InputContainer = styled.div`
   width: 80px;
   margin-left: 8px;
   margin-right: 8px;
`

export const textStyles = css`
  flex-shrink: 0;
  margin: auto !important;
`
export const Container = styled.div`
  padding: 12px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${({ styles }) => styles}
`
