import styled from 'styled-components'
import { Text } from 'influ-dms'

const StyledDiv = styled.div`
  margin-right: 32px;
`

export const EditInfluencerModalContentTotalCost = ({ value, text }) => (
  <StyledDiv>
    <Text size='15' color='primary.dark'>{value}</Text>
    <Text color='primary.light3'>{text}</Text>
  </StyledDiv>
)
