import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { get } from 'lodash'
import { Form } from 'influ-dms'
import { BottomCenteredModal } from '../modal/BottomCenteredModal'
import { NewEmailModalHeader } from './NewEmailModalHeader'
import { NewEmailModalContent } from './NewEmailModalContent'
import { ConnectError } from '../forms/result/ConnectError'
import { sendEmailMessageMutation } from '../../apollo/emailMutations'
import { attachmentCountExceeded, emailSizeExceeded10Mb, removeSpecialChars, _replaceInlineContentIds } from '../../helpers'
import { MESSAGE_LIMITS } from '../../CommunicationConstants'
import { useTranslate } from '~/hooks/useTranslate'

export const Main = styled.main`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: ${({ isMinimized }) => isMinimized ? 0 : '100vh'};
  height: ${({ isMaximized }) => isMaximized ? '100vh' : '508px'};
  padding:  ${({ isMinimized }) => isMinimized ? '0 32px' : '24px 32px'};
  overflow: hidden;
  transition: all 0.4s ease-in-out;
`

export const NewEmailModal = ({ email, isOpen, onClose }) => {
  const { t } = useTranslate()
  const [sendEmailMessage, { loading }] = useMutation(sendEmailMessageMutation)
  const [isMinimized, setMinimized] = useState(false)
  const [isMaximized, setMaximized] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setMaximized(false)
      setMaximized(false)
      setError(false)
    }
  }, [isOpen])

  const onMinimize = () => {
    if (isMaximized) {
      setMaximized(false)
    }
    setMinimized(prevState => !prevState)
  }
  const onMaximize = () => {
    if (isMinimized) {
      setMinimized(false)
    }
    setMaximized(prevState => !prevState)
  }
  const handleSend = values => {
    const variables = {
      ...values,
      influencerId: get(email, 'influencerId'),
      messageId: get(email, 'messageId')
    }
    if (['reply', 'forward'].indexOf(email.type) > -1) {
      variables.reference = { messageServerId: email.messageIdForReplies, action: 'reply' }
    }
    const attachments = []
    for (const attachment of (get(variables, 'attachments') || [])) {
      attachments.push({ inline: false, file: attachment })
    }

    variables.attachments = attachments

    const inlineAttachments = get(variables, 'inlineAttachments') || []

    for (const inlineAttachment of inlineAttachments) {
      const src = `cid:${removeSpecialChars(inlineAttachment.contentId)}`
      variables.html = variables.html.replace(inlineAttachment.preview, src)
      if (variables.html.indexOf(src) > -1) {
        variables.attachments.push({ file: inlineAttachment, inline: true, contentId: inlineAttachment.contentId })
      }
    }

    // Replace inline images signature
    const { html, attachments: signatureAttachments } = _replaceInlineContentIds(variables.html, email.signatureAttachments)
    variables.attachments = variables.attachments.concat(signatureAttachments)
    variables.html = html

    if (emailSizeExceeded10Mb(variables.html, variables.attachments)) {
      setError(t('commons.communication.newEmail.modal.errorLimit1'))
      return
    }

    if (attachmentCountExceeded(variables.attachments)) {
      setError(t('commons.communication.newEmail.modal.errorLimit2', { limit: MESSAGE_LIMITS.attachmentCount }))
      return
    }

    sendEmailMessage({ variables })
      .then(() => {
        onClose()
      }).catch(() => {
        setError(t('commons.communication.newEmail.modal.error'))
      })
  }

  const hideError = () => {
    setError(false)
  }

  return (
    <BottomCenteredModal
      isOpen={isOpen}
      isMaximized={isMaximized}
      noAnimation
      header={<NewEmailModalHeader onClose={onClose} onMaximize={onMaximize} onMinimize={onMinimize} />}
    >
      <Form>
        <Main isMinimized={isMinimized} isMaximized={isMaximized}>
          {error && <ConnectError handleRetry={hideError} error={error} />}
          <NewEmailModalContent isOpen={isOpen} show={!error} loading={loading} email={email} handleSend={handleSend} />

        </Main>
      </Form>
    </BottomCenteredModal>
  )
}
