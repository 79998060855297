import { useContext, useEffect, useMemo, useState } from 'react'
import {
  TableEditableTh,
  TableV2TdNetworks,
  TableEditableThCheckbox,
  TableV2TdCheckbox,
  TableV2,
  editableTableTableStyles,
  editableTableHeaderStyles,
  editableTableBodyStyles,
  TableEditableFiltersBar,
  Pagination,
  TableEditableSelectedHeader,
  Text,
  Flex,
  Icon,
  Spacing,
  TableContext
} from 'influ-dms'
import { ProfilesTrackingTableTd } from './ProfilesTrackingTableTd'
import { ApiAccessTrackingTableTd } from './ApiAccessTrackingTableTd'
import styled, { css } from 'styled-components'
import { ProfilesTableHeaderDelete } from './ProfilesTableHeaderDelete'
import { LIMIT_OF_PROFILES_TO_ADD } from '../AddProfileTrackingCard'
import { get } from 'lodash'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '~/context/Language'

const TableContainer = styled.div`
  padding-bottom: ${({ hasPagination }) => hasPagination ? '4px' : '16px'};
`

const inputSearchStyles = css`
  background: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.state.default};
  width: 200px;
  color: ${({ theme }) => theme.colors.primary.dark};
`

const VerticalLine = styled('span')`
  border-left: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding: 0px 4px;
  margin: 0px 4px;
  height: 17px;
`

const TRACKING_PAGE_SIZE = 20

export const ProfilesTrackingTable = ({ tableId, influencerList = [], editing }) => {
  const { t } = useTranslate()
  const { locale } = useLanguageContext()
  const { dispatch, state: { count } } = useContext(TableContext)
  const influncersWithoutApiAccess = influencerList.reduce((n, influencer) => (influencer.apiAccess === false || influencer.apiAccess === null) ? n + 1 : n, 0)
  const [searchReset, setSearchReset] = useState(false)

  useEffect(() => {
    setSearchReset(!searchReset)
  }, [get(influencerList, 'length', 0), editing])

  useEffect(() => {
    if (count !== influencerList.length) {
      dispatch({ type: 'setCount', payload: influencerList.length })
    }
  }, [influencerList])

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        minWidth: 40,
        width: 40,
        maxWidth: 40,
        Header: ({ page }) => <TableEditableThCheckbox rows={page} />,
        Cell: TableV2TdCheckbox,
        disableResizing: true,
        disableEditColumn: true,
        disableDragColumn: true,
        disableFilters: true
      },
      {
        label: t('commons.table.column.profile.label'),
        accessor: 'username',
        Header: TableEditableTh,
        width: 70,
        minWidth: 70,
        Cell: ProfilesTrackingTableTd,
        disableEditColumn: true,
        disableDragColumn: true,
        deleteAction: {
          event: 'toggleDeleteReports'
        }
      },
      {
        label: t('commons.table.column.socialNetworks.label'),
        accessor: 'network',
        Header: TableEditableTh,
        width: 75,
        minWidth: 50,
        Cell: ({ value }) => <TableV2TdNetworks value={value} justify='center' />,
        disableEditColumn: true,
        disableDragColumn: true,
        disableFilters: true
      },
      {
        label: t('commons.table.column.apiAccess.label'),
        accessor: 'apiAccess',
        Header: TableEditableTh,
        width: 75,
        minWidth: 50,
        Cell: ApiAccessTrackingTableTd,
        disableEditColumn: true,
        disableDragColumn: true,
        disableFilters: true
      }
    ],
    [locale]
  )

  const renderSideComponent = () => (
    <Flex column>
      <Flex justify='right'>
        <Text bold size='14'>{t('reports.modal.tracking.profilesBlock.numberOfProfiles.label', { profile: influencerList.length, totalProfiles: LIMIT_OF_PROFILES_TO_ADD })}</Text>
        {influncersWithoutApiAccess > 0 && (
          <VerticalLine><Text color='state.warningDefault' size='14'>{influncersWithoutApiAccess} </Text></VerticalLine>
        )}
      </Flex>
      {influncersWithoutApiAccess > 0 && (
        <Flex align='center'>
          <Icon name='danger' color='state.warningDefault' size='10' />
          <Spacing vertical size='4' />
          <Text color='state.warningDefault' size='12'>{t('reports.publicationsAndData.dataSource.trackings.tracking.notOptedInYet.warning.label', { totalProfiles: influncersWithoutApiAccess })}</Text>
        </Flex>
      )}
    </Flex>
  )

  return (
    <TableContainer hasPagination>
      <TableV2
        columns={columns}
        data={influencerList}
        initialPageSize={TRACKING_PAGE_SIZE}
        initialSortBy={[{ id: 'username', desc: false }]}
        styles={editableTableTableStyles}
        headerStyles={editableTableHeaderStyles}
        bodyStyles={editableTableBodyStyles}
        navBarFilter={({ setGlobalFilter, columns, setHiddenColumns }) => (
          <TableEditableFiltersBar
            tableId={tableId}
            name='trackingListSearch'
            editColumn={false}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            searchStylesFromProps={inputSearchStyles}
            searchPlaceholder={t('reports.influencers.input.searchByProfile.placeholder')}
            sideTextComponent={renderSideComponent}
            resetSearch={searchReset}
            setHiddenColumns={setHiddenColumns}
          />
        )}
        pagination={({ pageIndex, pageCount, gotoPage, pageSize }) => {
          return (
            <Pagination
              onPageChange={(value) => gotoPage(value - 1)}
              pages={pageCount}
              page={pageIndex + 1}
              hideGoto
            />
          )
        }}
        selectedHeader={({ headerGroup, selectedFlatRows, styles }) => {
          const ids = selectedFlatRows.map(({ username }) => username)
          return (
            <TableEditableSelectedHeader tableId={tableId} headerGroup={headerGroup} selectedFlatRows={selectedFlatRows} styles={styles}>
              <ProfilesTableHeaderDelete ids={ids} editing={editing} setSearchReset={setSearchReset} searchReset={searchReset} />
            </TableEditableSelectedHeader>
          )
        }}
      />
    </TableContainer>
  )
}
