import { gql } from '@apollo/client'

export const unregisterRelationshipProfile = gql`
 mutation unregisterRelationshipProfile(
  $profileIds: [String]!
  $campaignIds: [String]
  $listIds: [String]
 ) {
  unregisterRelationshipProfile(
    profileIds : $profileIds
    campaignIds: $campaignIds
    listIds: $listIds
   ){
      message
      code
      success
    }
 }
 `
