import { Button, Text, Flex, Modal, Spacing, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const ReportsTrackingModalPause = (props) => {
  const { t } = useTranslate()
  const { isOpen, onClose, subtitle, trackingId, handleChangeStatusMutation, loading } = props

  const handlePauseTracking = () => {
    handleChangeStatusMutation(trackingId, 'paused')
    onClose()
  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={t('reports.modal.pauseTracking.header')}
        subtitle={subtitle}
        minHeight='100'
        color='state.warningDefault'
      >
        <Flex column grow='1'>
          <Text size='15' color='primary.dark' bold>{t('reports.modal.pauseTracking.titleHeader1')}</Text>
          <Spacing size='8' />
          <Text size='14' color='primary.light3'>{t('reports.modal.pauseTracking.titleParagraph1')}</Text>
          <Spacing size='48' />
        </Flex>
        <Flex>
          <StateButton
            isDisabled={loading}
            loading={loading}
            color='warning'
            onClick={handlePauseTracking}
          >{t('commons.button.pause')}
          </StateButton>
          <Spacing vertical size='8' />
          <Button variant='flat' color='secondary' onClick={onClose}>{t('commons.button.cancel')}</Button>
        </Flex>
      </Modal>
    </>
  )
}

export { ReportsTrackingModalPause }
