export const getFiltersSorted = (filters, t) => {
  const filtersGroups = filters.sort((a, b) => a.order - b.order)
  let favFilters = []
  for (const filtersGroup of filtersGroups) {
    const favs = filtersGroup.filters.filter((filter) => filter.favoriteOrder)
    favFilters = favFilters.concat(favs)
    // filtersGroup.filters = filtersGroup.filters.sort((a, b) => a.order - b.order)
    // order alphabetically
    filtersGroup.filters = filtersGroup.filters.sort((a, b) => t(a?.translationKey)?.localeCompare(t(b?.translationKey)))
    for (const filter of filtersGroup.filters) {
      filter.operations = filter?.operations?.sort((a, b) => a.order - b.order)
    }
  }

  const favFiltersSorted = favFilters.sort((a, b) => a.favoriteOrder - b.favoriteOrder)
  return { allFilters: filtersGroups, favFilters: favFiltersSorted }
}
