import { gql } from "@apollo/client";

export const getIRMListsByInfluencer = gql`
  query getIRMListsByInfluencer(
    $influencerId: String!
    $sortParams: IrmListSortParamsType
    $page: Int
    $limit: Int
    $view: String!
    $filters: [NewFilterParamsType]
  ) {
    getIRMListsByInfluencer(influencerId: $influencerId, sortParams: $sortParams, page: $page, limit: $limit, view: $view, filters: $filters) {
      id: _id
      brandId
      type
      object
      size
      listName
      updatedAt
      createdAt
      pictures
      createdByUser{
        firstName
      }
    }
  }
`
