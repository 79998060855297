import { CellWrapper, HoverAction, HoverWrapper, ListAvatar, Text, Tooltip } from 'influ-dms'
import { useTranslate, useBrandData } from '~/hooks'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { RouterHelper } from '../../../../routing/routerHelper'
import { ListDetailExport } from '../Detail/ListDetailExport'
import {
  clickableStyles,
  StyledContainer,
  StyledFlex,
  widgetTooltipContainerStyles,
  widgetTooltipStyles
} from './styles'

import { css } from 'styled-components'

const listRowExportButtonStyles = css`
  margin-left: 4px;
  margin-right: 0;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.light5};
  background: ${({ theme }) => theme.colors.primary.light5};
  min-width: 24px;
  min-height: 16px;
  width: 24px;
  justify-content: center;
  align-items: center;
  height: auto;

  a {
    width: 16px;
    height: 16px;
    background: ${({ theme }) => theme.colors.primary.light5};
  }

  div {
    margin-bottom: 0;
  }
`

export const ListsListTableTdListName = ({
  row: { original },
  onEditClick,
  onShareClick,
  onDeleteClick,
  onNameClick,
  showExportClick,
  onRemoveClick
}) => {
  const { t } = useTranslate()
  const list = original || {}
  const { id: listId, listName, pictures } = list
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const { featureFlags } = useBrandData({ variables: { brandId }, skip: !brandId })
  const canExport = get(featureFlags, 'irm.exportCSV')

  const onListClick = () => {
    router.push(RouterHelper.getRoute('irmList', { brandId, listId }))
  }

  return (
    <CellWrapper>
      <StyledFlex justify='space-between' align='center'>
        <StyledContainer align='center' >
          <ListAvatar
            images={pictures}
            onClick={onNameClick}
            size='30'
            horizontal
          />
          <Text ellipsis showTooltip styles={clickableStyles} size='14' color='primary.light2' bold onClick={onListClick}>{listName}</Text>
        </StyledContainer>
        <HoverWrapper>
          {onEditClick && <Tooltip
            text={t('entity.list.tool.edit.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onEditClick} iconName='edit' />
          </Tooltip>}
          {onShareClick && <Tooltip
            text={t('entity.list.tool.share.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onShareClick} iconName='share-alt' />
          </Tooltip>}
          {showExportClick && <Tooltip
            text={t('entity.list.tool.export.tooltip')}
            place='top'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <ListDetailExport
              brandId={brandId}
              canExport={canExport}
              domain={'list.detail'}
              query={{ listId: list.id }}
              listName={list.listName}
              styles={listRowExportButtonStyles}
            />
          </Tooltip>}
          {onDeleteClick && <Tooltip
            text={t('entity.list.tool.delete.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onDeleteClick} iconName='trash' />
          </Tooltip>}
          {onRemoveClick && <Tooltip
            text={t('commons.button.removeInfluencerFromList.tooltip')}
            place='bottom'
            type='dark'
            color='neutral.white'
            containerStyles={widgetTooltipContainerStyles}
            styles={widgetTooltipStyles}
          >
            <HoverAction onClick={onRemoveClick} iconName='remove' />
          </Tooltip>}
        </HoverWrapper>
      </StyledFlex>
    </CellWrapper>
  )
}
