import { get } from 'lodash'
import { useRouter } from 'next/router'
import { countTableCampaignsbetaByBrand } from '../../apollo'
import { getCampaignsbetaByBrand } from '../../apollo/getCampaignsbetaByBrand'
import { useFilter } from './useFilter'
import { useFilterQuery } from './useFilterQuery'
import { captureException } from '@sentry/nextjs'

export const useCampaigns = () => {
  const {
    loadingData: loadingCampaigns,
    loadingCount: loadingCountCampaigns,
    setQueryOptions: setTableCampaignsOptions,
    setCountQueryOptions: setCountTableCampaignsOptions,
  } = useFilterQuery({
    query: getCampaignsbetaByBrand,
    queryAccessor: 'getCampaignsbetaByBrand',
    countQuery: countTableCampaignsbetaByBrand,
    countQueryAccessor: 'countTableCampaignsbetaByBrand',
  })

  const router = useRouter()
  const brandId = get(router, 'query.brandId')

  const { getFilteredDataOptions } = useFilter()

  const getCampaigns = (filterParams, view = 'table', extraArgs) => {
    try {
      const { options, countOptions, page } = getFilteredDataOptions(
        filterParams,
        { brandId, view },
        { brandId, view }
      )

      setTableCampaignsOptions({ ...options, ...extraArgs })
      page === 0 && setCountTableCampaignsOptions({ ...countOptions, ...extraArgs })
    } catch (e) {
      captureException('Error obtaining campaigns', e)
    }
  }

  return {
    getCampaigns,
    loadingCampaigns,
    loadingCountCampaigns
  }
}
