import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { get } from 'lodash'
import { FiltersTableContext, SnackbarContext, useCreateEditForm } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse } from '../../../../constants/forms/getFormResponse'
import {
  addProfilePrice,
  deleteProfilePrices,
  getTableProfilePrices
} from '../../apollo/profilePrices'
import { useFilter } from './useFilter'
import { useCleanCache } from './useCleanCache'
import { captureException } from '@sentry/nextjs'

export const useProfilePrices = () => {
  const { clearProfilePriceHistory } = useCleanCache()
  const { t } = useTranslate()
  const { showSnackbar } = useContext(SnackbarContext)
  const { setFilteredData } = useContext(FiltersTableContext)
  const [queryOptions, setQueryOptions] = useState({ skip: true })

  const { data: rawData, loading: loadingProfilePrices } = useQuery(
    getTableProfilePrices,
    queryOptions
  )
  const [deleteProfilePricesMutation, { loading: loadingDeleteProfilePrices }] = useMutation(
    deleteProfilePrices,
    {
      update (cache, data, options) {
        clearProfilePriceHistory(data, options)
      }
    }
  )
  const [addProfilePriceMutation, { loading: loadingAddProfilePrices }] = useMutation(
    addProfilePrice,
    {
      update (cache, data, options) {
        clearProfilePriceHistory(data, options)
      }
    }
  )

  const data = get(rawData, 'getProfilePriceHistory')

  const { getFilteredDataOptions } = useFilter()
  const { sendForm } = useCreateEditForm()

  useEffect(() => {
    if (data) {
      setFilteredData(data)
    }
  }, [data])

  const getProfilePrices = async (args) => {
    try {
      const { options } = getFilteredDataOptions(args, { profileId: args.profileId })
      setQueryOptions(options)
    } catch (e) {
      captureException('Error obtaining profile prices', e)
    }
  }

  const addProfilePrices = async ({ username, network, profileId }) => {
    try {
      const response = await sendForm(addProfilePriceMutation, false, {
        username,
        network,
        profileId
      },
      { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse }
      )
      if (response && response.success) {
        const message = response.message || 'commons.snackbar.success.addPrice'
        showSnackbar('success', t(message))
        return true
      } else {
        if (response.type === 'snackbarError') {
          const message = response.message || 'commons.snackbar.error.noServiceCostHasBeenCharged'
          showSnackbar('error', t(message))
          return false
        }
      }
      return false
    } catch (e) {
      captureException('Error adding profile', e)
    }
  }

  const deleteMassiveProfilePrices = async (profileId, profilePricesIds) => {
    const variables = { profileId, ids: profilePricesIds }
    try {
      await deleteProfilePricesMutation({ variables })
      showSnackbar('success', t('commons.snackbar.success.deletePrice'))
      return true
    } catch (e) {
      showSnackbar('error', t('commons.snackbar.error.somethingWentWrong'))
      captureException('There was an error deleting profile prices =>', e)
      return false
    }
  }

  return {
    getProfilePrices,
    loadingProfilePrices,
    addProfilePrices,
    loadingAddProfilePrices,
    deleteMassiveProfilePrices,
    loadingDeleteProfilePrices
  }
}
