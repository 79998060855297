import { gql } from '@apollo/client'

export const countIrmInfluencersTableInProgramV2 = gql`
    query countIrmInfluencersTableInProgramV2(
      $programId: String!,
      $filters: [NewFilterParamsType],
      $view: String!
    ) {
      countIrmInfluencersTableInProgramV2(
        programId: $programId,
        filters: $filters,
        view: $view
      )
    }
`
