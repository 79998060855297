import styled from 'styled-components'
import { Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledContainer = styled.div`
  padding: 4px;
  margin-left: auto;
`

const StyledText = styled(Text)`
  cursor: pointer;
  display: inline;
  margin-right: 6px;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary.light1};
  }
`

export const NewEmailModalCC = ({ showCc, handleCcClick, showBcc, handleBccClick }) => {
  const { t } = useTranslate()
  return (
    <StyledContainer>
      {!showCc && <StyledText color='primary.light3' onClick={handleCcClick}>{t('influencers.modal.composeMessage.input.recipients.cc.placeholder')}</StyledText>}
      {!showBcc && <StyledText color='primary.light3' onClick={handleBccClick}>{t('influencers.modal.composeMessage.input.recipients.bcc.placeholder')}</StyledText>}
    </StyledContainer>
  )
}
