import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Tag } from './Tag'
import { Flex } from 'influ-dms'

const StyledContainer = styled(Flex)`
  width: 100%;
  height: calc(100% - 70px);
  border: 1px solid ${({ theme }) => theme.colors.state.default};
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.neutral.white};
  overflow-y: auto;
  overflow-x: hidden;
  ${({ styles }) => styles || ''};
`

const StyledTextarea = styled.textarea`
  resize: none;
  border: none;
  width: 100%;
  height: 100%;
  min-height: 30px;
  margin: 12px 4px;
  overflow: hidden;
  ${({ styles }) => styles || ''};
`

export const ProfileInputTagTracking = ({ urls, setUrls, handleUrl, containerStyles, textareaStyles, tagStyles, placeholder, rows, onBlurProp, name }) => {
  const ref = useRef()
  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    if (!textValue.trim()) {
      setTextValue('')
      ref.current.selectionStart = 0
      ref.current.selectionEnd = 0
    }
  }, [textValue])

  const inputKeyDown = e => {
    const val = e.target.value.trim()
    // enter, spacebar, comma
    if ([13, 32, 188].indexOf(e.keyCode) > -1 && val) {
      handleTags(e.target.value.trim())
      // backspace
    } else if (e.keyCode === 8) {
      if (ref.current.selectionStart === 0) {
        setUrls(prevUrls => {
          prevUrls.pop()
          return [...prevUrls]
        })
      }
    }
  }
  const onChange = e => {
    setTextValue(e.target.value)
  }
  const onBlur = e => {
    handleTags(e.target.value.trim())
    if (onBlurProp) onBlurProp(e)
  }
  const handleTags = val => {
    if (val) {
      const tags = val.replace(/\t/g, '').split(/\r?\n/)
      tags.forEach(tag => {
        handleTag(tag.trim())
      })
    }
  }
  const handleTag = val => {
    handleUrl(val)
    setTextValue('')
  }
  const handleClose = tag => {
    setUrls(tag, 'delete', 'value')
    setTextValue('')
  }

  return (
    <StyledContainer column styles={containerStyles}>
      {urls.map(url => <Tag key={url.value} url={url} handleClose={handleClose} styles={tagStyles} />)}
      <StyledTextarea
        ref={ref}
        placeholder={placeholder}
        value={textValue}
        onChange={onChange}
        onKeyDown={inputKeyDown}
        onBlur={onBlur}
        styles={textareaStyles}
        rows={rows}
        name={name}
      />
    </StyledContainer>
  )
}
