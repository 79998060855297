import { gql } from '@apollo/client'

export const getIrmInfluencerSharedLink = gql`
  mutation getIrmInfluencerSharedLink($influencerId: String!) {
    getIrmInfluencerSharedLink(influencerId: $influencerId) {
      type,
      refId,
      sharedId
    }
 }
`