import { Avatar, Flex } from 'influ-dms'
import { avatarBrandStyles } from './styles'

const AvatarBrand = (props) => {
  const { src, brandName } = props

  if (!brandName) return <div />

  return (
    <Flex column align='flex-end'>
      <Avatar
        variant='brand'
        size='m'
        src={src}
        name={brandName}
        styles={avatarBrandStyles}
      />
    </Flex>
  )
}

export { AvatarBrand }
