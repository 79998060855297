import { createContext, useEffect } from 'react'
import { get } from 'lodash'
import { refreshFeatureFlags } from '~/graphql/brands'
import { useSubscription } from '@apollo/client'
import { useRouter } from 'next/router'
import { useBrandData } from '~/hooks'

export const RefreshFeatureFlagsContext = createContext({ value: {} })

const RefreshFeatureFlagsProvider = (props) => {
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname')
  const isPublic = pathname && pathname.includes('public')
  const skip = isPublic || !brandId

  const { refetch } = useBrandData({ variables: { brandId }, skip }) // useQuery(brand, { variables: { brandId }, skip })

  const { data } = useSubscription(refreshFeatureFlags, { variables: { brandId }, skip: !brandId })

  useEffect(() => {
    if (data) refetch()
  }, [data])

  return (
    <RefreshFeatureFlagsContext.Provider value={data}>
      {props.children}
    </RefreshFeatureFlagsContext.Provider>
  )
}

export { RefreshFeatureFlagsProvider }
