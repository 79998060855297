import { useIntl } from 'react-intl'

export const useTranslate = () => {
  const intl = useIntl()
  const t = (key, variables, defaultMessage) => {
    if (key === null || key === undefined) {
      return
    }

    return key && intl.formatMessage({ id: key, defaultMessage }, variables)
  }
  return { t }
}
