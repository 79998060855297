import { useState } from 'react'
import { AddPostsManualMainInfo } from './AddPostsManualMainInfo'
import { AddPostsManualPostType } from './AddPostsManualPostType'
import { AddPostsManualFormOptions } from './AddPostsManualFormOptions'

export const AddPostsManualForm = ({ urlHasBeenChecked, isEdit }) => {
  const [profileHasBeenChecked, setProfileHasBeenChecked] = useState(false)
  const manualInfoChecked = urlHasBeenChecked || profileHasBeenChecked

  return (
    <>
      <AddPostsManualMainInfo
        urlHasBeenChecked={urlHasBeenChecked}
        profileHasBeenChecked={profileHasBeenChecked}
        setProfileHasBeenChecked={setProfileHasBeenChecked}
      />
      {manualInfoChecked && <AddPostsManualPostType disabled={urlHasBeenChecked} />}
      <AddPostsManualFormOptions manualInfoChecked={manualInfoChecked} isEdit={isEdit} />
    </>
  )
}
