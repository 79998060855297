import { css } from 'styled-components'
import { Flex } from 'influ-dms'
import { EditPostModalContentHeaderButtons } from './EditPostModalContentHeaderButtons'
import { EditPostModalContentHeaderPost } from './EditPostModalContentHeaderPost'

export const postContentHeaderStyles = css`
  height: 64px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
`

export const EditPostModalContentHeader = ({ post }) => (
  <Flex styles={postContentHeaderStyles} align='center' shrink='0'>
    <EditPostModalContentHeaderPost {...post} />
    <EditPostModalContentHeaderButtons post={post} />
  </Flex>
)
