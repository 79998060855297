import { Form, SideBox, Spacing, Text } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { headerStyles } from '../../styles'

export const AddPostsManualYoutubeAdditionalMetrics = () => {
  const { t } = useTranslate()

  return (
    <SideBox>
      <Text size='13' color='primary.light3' uppercase styles={headerStyles}>{t('reports.report.modal.publication.additionalMetricsOfThepublicationBlock.title')}</Text>
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.shares.input.placeholder')} name='additionalMetrics.shares' label={t('commons.insights.shares.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.impressions.input.placeholder')} name='additionalMetrics.impressions' label={t('commons.insights.impressions.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.impressionsClickThroughRate.input.placeholder')} name='additionalMetrics.impressionsClickThroughRate' label={t('commons.insights.impressionsClickThroughRate.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.uniqueViewers.input.placeholder')} name='additionalMetrics.uniqueViewers' label={t('commons.insights.uniqueViewers.input.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.returningViewers.input.placeholder')} name='additionalMetrics.returningViewers' label={t('commons.insights.returningViewers.input.label')} />
      <Spacing size='24' />
      <Form.TimeInput name='additionalMetrics.timeWatched' label={t('commons.insights.totalTimeWatched.totalPlayTime.label')} />
      <Spacing size='24' />
      <Form.InputNumber placeholder={t('commons.insights.suscriberChange.input.placeholder')} name='additionalMetrics.subscriptionDriven' label={t('commons.insights.suscriberChange.input.label')} />
      <Spacing size='8' />
    </SideBox>)
}
