import { Button, Icon, Spacing } from 'influ-dms'

const buttonOptions = {
  fill: 'fill',
  outline: 'outline'
}

const iconOptions = {
  fill: 'white',
  outline: 'primary'
}

export const SubNavbarButton = ({ variant = 'fill', icon, text, name, onClick, size, disabled }) => (
  <Button name={name} variant={buttonOptions[variant]} withIcon onClick={onClick} size={size} disabled={disabled}>
    {icon &&
      <>
        <Icon size='20' color={iconOptions[variant]} name={icon} />
        <Spacing vertical size='4' />
      </>
    }
    {text}
  </Button>
)
