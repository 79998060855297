import { gql } from '@apollo/client'

export const addProgramV2InfluencerGifting = gql`
  mutation addProgramV2InfluencerGifting(
    $programId: String!,
    $benefit: ProgramV2InfluencerGiftingOptions!,
    $product: ProgramV2ProductInputObjectType,
    $activeDates: ProgramV2ActiveDateInputObjectType,
    $seedCode: String
  ) {
    addProgramV2InfluencerGifting(
      programId: $programId,
      benefit: $benefit,
      product: $product,
      activeDates: $activeDates,
      seedCode: $seedCode
    ) {
      message
      code
      success
      data
    }
  }
`