import { gql } from "@apollo/client";

export const deleteEntityFieldGroup = gql`
  mutation deleteEntityFieldGroup($_id: String!) {
    deleteEntityFieldGroup(_id: $_id) {
      message
      code
      success
    }
  }
`