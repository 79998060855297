import { gql } from '@apollo/client'
import { influencerFragment } from './fragments'

const deepsearch = gql`
  query search(
    $filterParams: DeepSearchFilterParamsTypeInput,
    $page: Int,
    $limit: Int,
    $brandId: String!,
    $sortBy: SortByOptions,
    $sortOrder: OrderByOptions,
    $network: NetworkOptions!,
  ){
    search(
      filterParams: $filterParams,
      limit: $limit,
      page: $page,
      brandId: $brandId,
      sortBy: $sortBy,
      sortOrder: $sortOrder,
      network: $network
    ) {
      totalCount,
      edges{
        ...influencer,
      }
    }
  }
  ${influencerFragment}
`

export { deepsearch }
