import { ThirdPartyIntegrationError } from '../../containers/programs/commons/constants'

const FORM_ERROR_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SNACKBAR_ERROR: 'snackbarError',
}

// formId: translationKey
export const successMsgOnCreateTrKeys = {
  profile: 'commons.snackbar.success.addProfile',
  profiles: 'commons.snackbar.success.addProfiles',
  profilePrice: 'commons.snackbar.success.addPrice',
  influencer: 'commons.snackbar.success.addInfluencer',
  list: 'commons.snackbar.success.createList',
  proposal: 'commons.snackbar.success.createProposal',
  report: 'commons.snackbar.success.createReport',
  campaign: 'commons.snackbar.success.createCampaign',
  paymentInvoice: 'commons.snackbar.success.createInvoice'
}

export const successMsgOnEditTrKey = 'commons.snackbar.success.edit'

const duplicatedMessages = {
  profile: 'modal.addProfile.mainInfoBlock.profile.helpertext.error.duplicated',
  influencer: 'entity.influencer.field.influencerAlias.helpertext.error.duplicated',
  entityView: 'entity.view.field.viewName.helpertext.error.duplicated',
  list: 'entity.list.field.listName.helpertext.error.duplicated',
  proposal: 'entity.proposal.field.proposalName.helpertext.error.duplicated',
  report: 'entity.report.field.reportName.helpertext.error.duplicated',
  campaign: 'entity.campaign.field.campaignName.helpertext.error.duplicated',
  formPages: 'entity.landing.field.landingName.helpertext.error.duplicated',
  addProgramV2Form: 'entity.program.field.programName.helpertext.error.duplicated',
  domain: 'entity.landingPage.field.defaultUrl.helpertext.error.alreadyExists',
  address: 'entity.influencer.field.postalAddress.addressName.helpertext.error.duplicated',
  outreach: 'entity.outreach.field.outreachName.helpertext.error.duplicated'
}

const getFormErrors = (formId) => ({
  12600: {
    code: 12600,
    message: 'modal.addProfile.mainInfoBlock.profile.helpertext.error.invalidFormat',
    type: FORM_ERROR_TYPES.ERROR
  },
  12602: {
    code: 12602,
    message: 'modal.addProfile.profileStatsBlock.helpertext.warning.privateProfile',
    type: FORM_ERROR_TYPES.WARNING
  },
  12603: {
    code: 12603,
    message: 'modal.addProfile.mainInfoBlock.profile.helpertext.error.restrictedOrDoesntExist',
    type: FORM_ERROR_TYPES.ERROR
  },
  12604: {
    code: 12604,
    message: 'commons.snackbar.error.noServiceCostHasBeenCharged',
    type: FORM_ERROR_TYPES.SNACKBAR_ERROR
  },
  12630: {
    code: 12630,
    message: 'commons.snackbar.error.somethingWentWrong',
    type: FORM_ERROR_TYPES.SNACKBAR_ERROR
  },
  12643: {
    code: 12643,
    type: FORM_ERROR_TYPES.ERROR
  },
  12620: {
    code: 12620,
    message: 'entity.influencer.field.primaryEmail.helpertext.error.notValid',
    type: FORM_ERROR_TYPES.ERROR
  },
  12660: {
    code: 12660,
    message: 'entity.landingPage.field.defaultUrl.helpertext.error.alreadyExists',
    type: FORM_ERROR_TYPES.ERROR
  },
  12662: {
    code: 12662,
    message: 'entity.landingPage.field.defaultUrl.helpertext.error.invalidFormat',
    type: FORM_ERROR_TYPES.ERROR
  },
  12700: {
    code: 12700,
    message: duplicatedMessages[formId],
    type: FORM_ERROR_TYPES.ERROR
  },
  12800: {
    code: 12800,
    message: 'commons.modals.limitReached.header',
    type: FORM_ERROR_TYPES.ERROR
  },
  12802: {
    code: 12802,
    message: 'entity.program.influencerGifting.field.codeName.helpertext.error.duplicated',
    type: FORM_ERROR_TYPES.ERROR
  },
  12815: {
    code: 12815,
    message: 'entity.program.influencerGifting.field.codeName.helpertext.error.mandatory',
    type: FORM_ERROR_TYPES.ERROR
  },
  12816: {
    code: 12816,
    message: 'entity.program.influencerGifting.field.codeName.helpertext.error.exceededCharacters',
    type: FORM_ERROR_TYPES.ERROR
  },
  [ThirdPartyIntegrationError.ECOMMERCE_SHOP_NOT_FOUND]: {
    code: ThirdPartyIntegrationError.ECOMMERCE_SHOP_NOT_FOUND,
    message: 'entity.integration.field.apiKey.helpertext.error.shopNotFound',
    type: FORM_ERROR_TYPES.ERROR
  },
  [ThirdPartyIntegrationError.ECOMMERCE_WRONG_API_KEY]: {
    code: ThirdPartyIntegrationError.ECOMMERCE_WRONG_API_KEY,
    message: 'entity.integration.field.apiKey.helpertext.error.wrongApiKey',
    type: FORM_ERROR_TYPES.ERROR
  },
  12900: {
    code: 12900,
    message: 'entity.groupOfProperties.field.groupName.helpertext.error.duplicated',
    type: FORM_ERROR_TYPES.ERROR
  },
  13000: {
    code: 13000,
    message: 'entity.property.field.propertyName.helpertext.error.alreadyExists',
    type: FORM_ERROR_TYPES.ERROR
  },
  // Massive comm
  12680: {
    code: 12680,
    message: 'settings.yourPreferences.communication.bulkEmail.bulkEmailConfiguration.block.customizeEmail.input.senderName.helpertext.error.domainNoVerified',
    type: FORM_ERROR_TYPES.ERROR
  },
  12681: {
    code: 12681,
    message: 'settings.yourPreferences.communication.bulkEmail.bulkEmailConfiguration.block.customizeEmail.input.senderName.helpertext.error.alreadyInUse',
    type: FORM_ERROR_TYPES.ERROR
  },
  12682: {
    code: 12682,
    message: 'settings.yourPreferences.communication.bulkEmail.bulkEmailConfiguration.block.customizeEmail.input.senderName.helpertext.error.notValidEmail',
    type: FORM_ERROR_TYPES.ERROR
  },
  12683: {
    code: 12683,
    message: 'settings.yourPreferences.communication.bulkEmail.bulkEmailConfiguration.block.customizeEmail.input.senderName.helpertext.error.domainNoVerified',
    type: FORM_ERROR_TYPES.ERROR
  },
  // Massive comm - Mailgun
  20000: {
    code: 20000,
    message: 'modal.connectAnEmailSendingDomain.domain.block.input.typeEmail.helpertext.error.alreadyInUse',
    type: FORM_ERROR_TYPES.ERROR
  },
  20001: {
    code: 20001,
    message: 'modal.connectAnEmailSendingDomain.domain.block.input.typeEmail.helpertext.error.alreadyInUse',
    type: FORM_ERROR_TYPES.ERROR
  },
  // Payments
  13201: {
    code: 13201,
    message: 'entity.invoice.field.name.helpertext.error.duplicated',
    type: FORM_ERROR_TYPES.ERROR
  },
  // Social listening
  14000: {
    code: 14000,
    message: 'entity.alert.field.name.helpertext.error.duplicated',
    type: FORM_ERROR_TYPES.ERROR
  },
})

const getFormErrorResponse = (code, formId) => {
  const errorObjects = getFormErrors(formId)
  return errorObjects[code] || {
    message: formId === 'profile' ? 'commons.snackbar.error.noServiceCostHasBeenCharged' : 'commons.snackbar.error.somethingWentWrong',
    type: FORM_ERROR_TYPES.SNACKBAR_ERROR
  }
}

const getFormSuccessResponse = (formId, editing) => {
  return editing ? successMsgOnEditTrKey : successMsgOnCreateTrKeys?.[formId] || successMsgOnEditTrKey
}

export { FORM_ERROR_TYPES, getFormSuccessResponse, getFormErrorResponse, getFormErrors }
