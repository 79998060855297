import { Text, Button, Flex, Icon } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useRouter } from 'next/router'
import { RouterHelper } from '../../routing/routerHelper'
import styled, { css } from 'styled-components'

const Container = styled.div`
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: var(--trialHeaderHeight);
  background-color: ${({ theme, paymentsNavbar }) => paymentsNavbar ? theme.colors.state.warningDefault : theme.colors.primary.dark};
  border-bottom: 1px solid #EAEAF2;
  position: relative;
  z-index: 60;
`
const dangerSpaceStyles = css`
  padding-right: 4px;
`

const BoldText = styled.div`
  padding-right: 8px;
  font-weight: 500;
`

export const NavbarTop = (props) => {
  const {
    paymentsNavbar,
    subscriptionInfoStatus,
    expired,
    remainingDays,
    upgradePlan
  } = props
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = router?.query?.brandId

  const renderTrialText = () => {
    return (
      <Text color='neutral.white' size='14'>
        <Flex>
          <Icon name='danger' size='20' color='neutral.white' styles={dangerSpaceStyles}/>
          <BoldText>
            {remainingDays > 0 && t('commons.topBar.trial.titleParagraph1.trialEndInXDays', { remainingDays })}
            {remainingDays === '0' && t('commons.topBar.trial.titleParagraph1.endToday')}
            {expired && t('commons.topBar.trial.titleParagraph1.hasExpired')}
          </BoldText>
          {t('commons.topBar.trial.titleParagraph1.upgradeNow')}
        </Flex>
      </Text>
    )
  }
  const renderTrialButton = () => {
    return (
      <Button
        name='upgradePlan'
        color='neutral.white'
        size='m'
        paddingSideways='16px'
        onClick={upgradePlan}
      >
        <Text color='primary.dark' size='14'>{t('commons.button.viewPricingAndFeatures')}</Text>
      </Button>)
  }

  const renderPaymentsText = () => {
    return (
      <Text color='primary.dark' size='14'>
        <Flex>
          <Icon name='danger' size='20' color='primary.dark' styles={dangerSpaceStyles}/>
          <BoldText>{t('commons.topBar.paymentFailed.titlePargraph1')}</BoldText>
          {subscriptionInfoStatus === 'incomplete' && t('commons.topBar.paymentFailed.incomplete.titleParagraph2')}
          {subscriptionInfoStatus === 'incomplete_expired' && t('commons.topBar.paymentFailed.incompleteExpired.titleParagraph2')}
          {subscriptionInfoStatus === 'past_due' && t('commons.topBar.paymentFailed.pastDue.titleParagraph2')}
        </Flex>
      </Text>
    )
  }

  const goToProductsAndBilling = () => {
    router.push(RouterHelper.getRoute('productsAndBilling', { brandId }))
  }

  const renderPaymentsButton = () => {
    return (
      <Button
        name='updatePayment'
        color='neutral.white'
        size='m'
        paddingSideways='16px'
        onClick={goToProductsAndBilling}
      >
        <Text color='primary.dark' size='14'>{t('commons.button.updatePayment')}</Text>
      </Button>)
  }
  return (
    <Container paymentsNavbar={paymentsNavbar}>
      {paymentsNavbar ? renderPaymentsText() : renderTrialText() }
      {paymentsNavbar ? renderPaymentsButton() : renderTrialButton()}
    </Container>
  )
}
