import { useContext } from 'react'
import styled from 'styled-components'
import { Form, FormContext, FormInputWithValidations, Text } from 'influ-dms'
import { Signature } from './Signature'
import { useTranslate } from '~/hooks/useTranslate'

const StyledText = styled(Text)`
  margin-left: 6px;
`

export const SignatureCheck = ({ accountSettings, setGeneralError, disabled }) => {
  const { values } = useContext(FormContext)
  const showSignature = values.showSignature
  const { t } = useTranslate()

  return (
    <>
      <Text bold color='primary.dark'>{t('settings.yourPreferences.communication.email.configuration.input.emailSignature.label')}</Text>
      <FormInputWithValidations name='showSignature'>
        {({ name, onChange }) => (
          <Form.SimpleCheckbox name={name} onChange={onChange}>
            <StyledText>{t('settings.yourPreferences.communication.email.configuration.input.emailSignature.enableSignature.checkItem')}</StyledText>
          </Form.SimpleCheckbox>
        )}
      </FormInputWithValidations>
      <Text color='primary.light3'>{t('settings.yourPreferences.communication.email.configuration.input.emailSignature.enableSignature.helperText')}</Text>
      <Signature showSignature={showSignature} accountSettings={accountSettings} setGeneralError={setGeneralError} disabled={disabled} />
    </>
  )
}
