import { useContext, useEffect, useRef, useState, Suspense, lazy } from 'react'
import {
  Button,
  Flex,
  FormContext,
  Icon,
  Loader,
  Spacing,
  Text,
  Tooltip,
  useArray
} from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { ThemeContext } from 'styled-components'
import { getEntityFieldsByEntity } from '../../apollo/getEntityFieldsByEntity'
import {
  StyledDiv,
  widgetTooltipContainerStyles,
  widgetTooltipStyles,
  wrapperStyles,
  templateModalPersonalizeButtonStyles,
  iconStyles,
  customAttachmentButtonStyles
} from './styles'
import { PersonalizationTokenModal } from './PersonalizationTokenModal'
import { NewEmailModalFooterAttachmentButton } from '../../../../../commons/communication/setup/newEmail/footer/NewEmailModalFooterAttachmentButton'

const MassiveEmailQuill = lazy(() => import('../../../../../commons/communication/setup/forms/details/wysiwyg/quill/MassiveEmailQuill'))

export const SendMassiveEmailBulkEmailFormContent = ({
  selectedTemplate,
  attachments,
  loadingSelectedTemplate,
  styles,
  quickSave,
  addedFiles,
  setAddedFiles
}) => {
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname')
  const isInInfluencers = pathname?.includes('influencers')
  const entityNames = isInInfluencers ? ['influencer', 'user', 'brand'] : ['influencer', 'programV2', 'user', 'brand']
  const variables = { brandId, entityNames }
  const { data: entityFieldsByEntityData, loading: loadingEntityFieldsByEntityData } = useQuery(
    getEntityFieldsByEntity,
    {
      variables,
      skip: !brandId,
      fetchPolicy: 'network-only'
    }
  )
  const { colors } = useContext(ThemeContext)
  const { setValue, values } = useContext(FormContext)
  const [hasMounted, setHasMounted] = useState(false)
  const [showPersonalizationModal, setShowPersonalizationModal] = useState(false)
  const [selectedQuill, setSelectedQuill] = useState('')
  const [files, setFiles] = useArray([])
  const emailSubject = useRef(0)
  const emailBody = useRef(1)
  const [incompatibilityErrors, setIncompatibilityErrors] = useState({
    subject: false,
    html: false
  })

  const handleChange = (value, name) => {
    if (name === 'html') {
      const contentIds = value.match(/alt="(.*?)"/g)?.map((image) => image.replace('alt="', '').replace('"', ''))
      const inlineAttachments = values?.inlineAttachments?.filter((attachment) => contentIds?.includes(attachment.contentId))
      setValue('InputText', 'inlineAttachments', inlineAttachments)
    }
    setValue('InputText', `raw${name}`, value)
    setValue('InputText', name, value.replace(/{{/g, '%').replace(/}}/g, '%'))
  }

  const handleSelectQuill = (name) => {
    setSelectedQuill(name)
    setShowPersonalizationModal(true)
  }

  const onAddImage = (file) => {
    delete file.__typename
    delete file.success
    setFiles(file, 'add')
  }

  const replaceInlineAttachments = (html, inlineAttachments) => {
    if (!html) return ''
    for (const attachment of inlineAttachments) {
      html = html.replace(`src="${attachment.preview}"`, `alt="${attachment.contentId}" src="cid:${attachment.contentId}"`)
    }
    return html
  }

  useEffect(() => {
    if (attachments?.length) {
      setFiles(attachments || [])
    }
  }, [attachments?.length])

  useEffect(() => {
    const parsedFiles = files?.map(file => ({ inline: true, file, contentId: file.contentId, attachmentId: file?.attachmentId })) || []
    setValue('InputText', 'inlineAttachments', parsedFiles)
    const html = replaceInlineAttachments(values?.html, files)
    setValue('InputText', 'html', html)
  }, [files?.length])

  const checkIncompatibilities = (rawText, field) => {
    let incompatibilities = []
    const emailEntity = isInInfluencers ? 'influencer' : 'program'
    if (entityFieldsByEntityData) {
      entityFieldsByEntityData.getEntityFieldsByEntity.forEach((entity) => {
        const { incompatibleEntities, entityName } = entity
        if (entityName === emailEntity) {
          incompatibilities = incompatibleEntities
        }
      })
    }

    const text = rawText.replace('<p><br></p>', '')
    const textWithIncompatibilities = text.replace(/<em class="quill-token(.*?)<\/em>/ig, (token) => {
      const entity = token.match(/entityname="(.*?)"/i)?.pop()?.replace('entityname="', '')?.replace('"', '')
      if (incompatibilities?.includes(entity)) {
        setIncompatibilityErrors({ ...incompatibilityErrors, [field]: true })
        return token.replace(/quill-token/gi, 'quill-token-incompatible')
      } else {
        return token
      }
    })

    return textWithIncompatibilities
  }

  useEffect(() => {
    if (!selectedTemplate || !entityFieldsByEntityData || loadingSelectedTemplate) return
    setValue('InputText', 'name', selectedTemplate?.name)
    setValue('InputText', 'rawsubject', checkIncompatibilities(values?.rawsubject || selectedTemplate?.rawsubject, 'subject'))
    setValue('InputText', 'subject', checkIncompatibilities(values?.subject || selectedTemplate?.subject, 'subject'))
    setValue('InputText', 'rawhtml', checkIncompatibilities(values?.rawhtml || selectedTemplate?.rawhtml, 'html'))
    setValue('InputText', 'html', checkIncompatibilities(values?.html || selectedTemplate?.html, 'html'))
  }, [loadingEntityFieldsByEntityData, loadingSelectedTemplate])

  useEffect(() => {
    if (!values?.subject || !values?.html) return
    setIncompatibilityErrors({
      subject: values?.subject?.includes('quill-token-incompatible'),
      html: values?.html?.includes('quill-token-incompatible')
    })
  }, [values?.subject, values?.html])

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) return null

  return (
    loadingEntityFieldsByEntityData
      ? <>
        <Loader fillSpace />
        <Spacing size={40} />
      </>
      : <>
        <Suspense fallback={<div />}>
          <StyledDiv styles={incompatibilityErrors.subject ? 'border: 1px solid #DE2A3C; border-radius: 4px;' : ''}>
            <MassiveEmailQuill
              bounds='#newEmailSubject'
              theme='bubble'
              colors={colors}
              placeholder={t('entity.email.field.subject.placeholder')}
              value={values?.rawsubject || ''}
              onChange={(value) => handleChange(value, 'subject')}
              onAddImage={onAddImage}
              ref={emailSubject}
              isOneLine={true}
              wrapperStyles={wrapperStyles}
              inlineIcon={
                <Tooltip
                  text={t('commons.button.personalize.tooltip')}
                  place='top'
                  type='dark'
                  color='neutral.white'
                  containerStyles={widgetTooltipContainerStyles}
                  styles={widgetTooltipStyles}
                >
                  <Icon
                    size='20'
                    color='primary.light3'
                    onClick={() => handleSelectQuill('subject')}
                    name='quick'
                    data-cy='Icon-personalizeSubject'
                  />
                </Tooltip>
              }
            />
          </StyledDiv>
          {incompatibilityErrors.subject &&
            <>
              <Spacing size={8} />
              <Flex align='flex-start'>
                <Icon name='danger' color='state.dangerDefault' styles={iconStyles} />
                <Spacing size={4} vertical/>
                <Text size='12' color='state.dangerDefault'>
                  {t('entity.property.field.object.value.tag.error.tooltip.differentEntity')}
                </Text>
              </Flex>
            </>
          }
        </Suspense>
        <Spacing size={8} />
        <Suspense fallback={<div />}>
          <StyledDiv styles={incompatibilityErrors.html ? 'border: 1px solid #DE2A3C; border-radius: 4px;' : ''}>
            <MassiveEmailQuill
              bounds='#newEmailBody'
              theme='snow'
              colors={colors}
              placeholder={t('entity.email.field.message.placeholder')}
              value={values?.rawhtml || ''}
              onChange={(value) => handleChange(value, 'html')}
              onAddImage={onAddImage}
              ref={emailBody}
            />
            {!quickSave && <NewEmailModalFooterAttachmentButton files={addedFiles} setFiles={setAddedFiles} styles={customAttachmentButtonStyles}/>}
            <Button
              color='secondary'
              variant='flat'
              size='m'
              withIcon
              onClick={() => handleSelectQuill('html')}
              data-cy='Button-personalizeBody'
              paddingSideways='0px'
              styles={templateModalPersonalizeButtonStyles}
            >
              {t('commons.button.personalize')}
              <Icon name='arrowdown' size='16' color='primary.light2' />
            </Button>
          </StyledDiv>
          {incompatibilityErrors.html &&
            <>
              <Spacing size={8} />
              <Flex align='flex-start'>
                <Icon name='danger' color='state.dangerDefault' styles={iconStyles} />
                <Spacing size={4} vertical/>
                <Text size='12' color='state.dangerDefault'>
                  {t('entity.property.field.object.value.tag.error.tooltip.differentEntity')}
                </Text>
              </Flex>
            </>
          }
        </Suspense>
        <PersonalizationTokenModal
          isOpen={showPersonalizationModal}
          onClose={() => setShowPersonalizationModal(false)}
          quillRef={selectedQuill === 'subject' ? emailSubject.current : emailBody.current}
          selectedQuill={selectedQuill}
          data={entityFieldsByEntityData}
          entityNames={entityNames}
        />
        {!styles && <Spacing size={16} />}
      </>
  )
}
