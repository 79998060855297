import { gql } from '@apollo/client'

export const deleteAudienceBenefitCode = gql`
  mutation deleteAudienceBenefitCode(
    $programId: String!
    $couponIds: [String]!
  ) {
    deleteAudienceBenefitCode(
      programId: $programId
      couponIds: $couponIds
    ){
        message
        code
        success
      }
  }
 `
