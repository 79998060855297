import { gql } from "@apollo/client";

export const getTemplatesByBrand = gql`
  query getTemplatesByBrand(
    $brandId: String!
    $limit: Int
    $page: Int
    $sortParams: TemplateSortParamsType, 
    $filters: [NewFilterParamsType],
    $view: String!
  ) {
    getTemplatesByBrand(brandId: $brandId, sortParams: $sortParams, page: $page, limit: $limit, filters: $filters, view: $view) {
      _id
      name
      type
      createdByUser {
        firstName
      }
      createdAt
      updatedAt
    }
  }
`
