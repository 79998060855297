import { gql } from "@apollo/client";

export const deleteDomains = gql`
    mutation deleteDomains($domainsIds: [String]!) {
        deleteDomains(domainsIds: $domainsIds) {
            message
            code
            success
        }
    }
`