import { createContext, useReducer } from 'react'

const initialValues = {
  profileId: undefined,
  isDeleteProfileModalOpen: false
}

export const ProfileContext = createContext(initialValues)

function reducer (state = initialValues, action) {
  switch (action.type) {
    case 'setProfileId': {
      return { ...state, profileId: action.value }
    }
    case 'setDeleteProfileModalOpen': {
      return { ...state, isDeleteProfileModalOpen: action.value }
    }
    case 'setDeleteMassiveProfilesModalOpen': {
      return { ...state, isDeleteMassiveProfilesModalOpen: action.value }
    }
    case 'setRemoveMassiveListsFromProfileModalOpen': {
      return { ...state, isRemoveMassiveListsFromProfileModalOpen: action.value }
    }
    case 'setAssociateToListModalOpen': {
      return { ...state, isAssociateToListModalOpen: action.value }
    }
    case 'setAssociateToComparatorModalOpen': {
      return { ...state, isAssociateToComparatorModalOpen: action.value }
    }
  }
}

export const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues)

  const setProfileId = (profileId) => {
    dispatch({ type: 'setProfileId', value: profileId })
  }

  const openDeleteProfileModal = () => {
    dispatch({ type: 'setDeleteProfileModalOpen', value: true })
  }

  const closeDeleteProfileModal = () => {
    dispatch({ type: 'setDeleteProfileModalOpen', value: false })
  }

  const openDeleteMassiveProfilesModal = () => {
    dispatch({ type: 'setDeleteMassiveProfilesModalOpen', value: true })
  }

  const closeDeleteMassiveProfilesModal = () => {
    dispatch({ type: 'setDeleteMassiveProfilesModalOpen', value: false })
  }

  const openAssociateToListModal = () => {
    dispatch({ type: 'setAssociateToListModalOpen', value: true })
  }

  const closeAssociateToListModal = () => {
    dispatch({ type: 'setAssociateToListModalOpen', value: false })
  }

  const openAssociateToComparatorModal = () => {
    dispatch({ type: 'setAssociateToComparatorModalOpen', value: true })
  }

  const closeAssociateToComparatorModal = () => {
    dispatch({ type: 'setAssociateToComparatorModalOpen', value: false })
  }

  const openRemoveMassiveListsFromProfileModalOpen = () => {
    dispatch({ type: 'setRemoveMassiveListsFromProfileModalOpen', value: true })
  }

  const closeRemoveMassiveListsFromProfileModalOpen = () => {
    dispatch({ type: 'setRemoveMassiveListsFromProfileModalOpen', value: false })
  }

  return (
    <ProfileContext.Provider value={{
      ...state,
      setProfileId,
      openDeleteProfileModal,
      closeDeleteProfileModal,
      openDeleteMassiveProfilesModal,
      closeDeleteMassiveProfilesModal,
      openAssociateToListModal,
      closeAssociateToListModal,
      openRemoveMassiveListsFromProfileModalOpen,
      closeRemoveMassiveListsFromProfileModalOpen,
      openAssociateToComparatorModal,
      closeAssociateToComparatorModal,
    }}>
      {children}
    </ProfileContext.Provider>
  )
}
