import { useContext } from 'react'
import styled from 'styled-components'
import { Button, Flex, FormContext, StateButton } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

const StyledFlex = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light5};
  margin: 24px -32px 0 -32px;
  padding: 12px 32px 0 32px;
`

export const EmailSetupUpdatePasswordModalFooter = ({ loading, handleSave, saveText, handleClose }) => {
  const { t } = useTranslate()
  const { values } = useContext(FormContext)

  const isFormDirty = values.formDirty
  const isFormInvalid = Object.values(values.errors || {}).some(val => val)
  const isFormDisabled = isFormInvalid || !isFormDirty

  const onSave = () => {
    handleSave(values)
  }

  return (
    <StyledFlex>
      <StateButton disabled={isFormDisabled} loading={loading} showLoader onClick={onSave}>{saveText}</StateButton>
      <Button disabled={loading} color='secondary' variant='flat' onClick={handleClose}>{t('commons.button.cancel')}</Button>
    </StyledFlex>
  )
}
