import { useContext, useEffect, useState } from 'react'
import { css } from 'styled-components'
import { get, isEmpty } from 'lodash'
import { Flex, FormContext, Icon, Spacing, Text, useArray, Loader } from 'influ-dms'
import { getProfilesInMyInfluencersForTracking } from '../../../apollo/tracking/getProfilesInMyInfluencersForTracking'
import { useQuery } from '@apollo/client'
import { LIMIT_OF_PROFILES_TO_ADD } from './AddProfileTrackingCard'
import { useRouter } from 'next/router'
import { AddFromMyInfluencersVirtualized } from './AddFromMyInfluencersVirtualized'
import { useTranslate } from '~/hooks/useTranslate'

const cursorStyles = css`
  cursor: pointer;
`
const flexStyles = css`
  align-items: center;
`
const textStyles = css`
  margin: 2px 4px;
`

const findByUsernameOrName = (profile, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()
  const fullName = `${profile.firstName || ''} ${profile.lastName || ''}`.toLowerCase()
  return (profile.username.toLowerCase().includes(searchFilter) || fullName.includes(searchFilter))
}

export const AddFromMyInfluencers = (props) => {
  const { t } = useTranslate()
  const { brandId, network, setLoading, searchFilter } = props
  const { values, setValue } = useContext(FormContext)
  const router = useRouter()
  const isPublic = router && router.pathname && router.pathname.includes('public')
  const { data, loading: dataLoading } = useQuery(getProfilesInMyInfluencersForTracking, { variables: { brandId, network }, skip: !brandId || !network || isPublic, fetchPolicy: 'network-only' })
  const [activeItems, setActiveItems] = useArray([])
  const allProfiles = get(data, 'getProfilesInMyInfluencersForTracking', [])
  const [filteredItems, setFilteredItems] = useState(allProfiles)
  const [selectAll, setSelectAll] = useState(true)
  const limitExceeded = get(values.influencersInModal, 'length', 0) + get(values.influencers, 'length', 0) > LIMIT_OF_PROFILES_TO_ADD
  const allItemsSelected = get(filteredItems, 'length', -1) > 0 && get(activeItems, 'length', 0) === get(filteredItems, 'length', 0)

  useEffect(() => {
    if (isEmpty(values.influencersInModal)) {
      setActiveItems([])
    }
  }, [values.influencersInModal])

  useEffect(() => {
    if (!dataLoading && isEmpty(filteredItems)) {
      setFilteredItems(allProfiles)
      setActiveItems([])
    }
  }, [allProfiles])

  useEffect(() => {
    if (searchFilter) {
      setFilteredItems(allProfiles.filter((profile) => findByUsernameOrName(profile, searchFilter)))
    } else {
      setFilteredItems(allProfiles)
    }
  }, [searchFilter])

  useEffect(() => {
    setLoading(dataLoading)
  }, [dataLoading])

  const onClickSelectAll = () => {
    if (selectAll) {
      setActiveItems(filteredItems)
      setValue('InputText', 'influencersInModal', filteredItems)
    } else if (!isEmpty(activeItems)) {
      setActiveItems([])
      setValue('InputText', 'influencersInModal', [])
    }
    setSelectAll(!selectAll)
  }

  const addToSelected = (influencer) => {
    const isSelected = (activeItems || []).find(profile => profile.username === influencer.username) !== undefined
    if (isSelected) {
      setActiveItems(influencer.username, 'delete', 'username')
      setValue('InputText', 'influencersInModal', (activeItems || []).filter((infl) => infl.username !== influencer.username))
    } else {
      setActiveItems(influencer, 'add')
      setValue('InputText', 'influencersInModal', isEmpty(activeItems) ? [influencer] : activeItems.concat([influencer]))
    }
  }
  return (
    <>
      {!isEmpty(filteredItems) && !isEmpty(allProfiles) &&
        <Flex onClick={onClickSelectAll} styles={[cursorStyles, flexStyles]}>
          <Icon
            name={allItemsSelected ? 'radiochecked' : 'addcircle'}
            color={allItemsSelected ? 'state.active' : 'primary.light1'}
            size='20'
          />
          <Text color='primary.light1' styles={textStyles} size='14' bold>
            {t('reports.modal.tracking.addProfilesFromMyInfluencers.profilesSelected.button', { selectedProfiles: get(activeItems, 'length', 0) })}
          </Text>
        </Flex>}
      <Spacing size='8' />
      {dataLoading ? <Loader fillspace />
        : !isEmpty(filteredItems) && !isEmpty(allProfiles) && <AddFromMyInfluencersVirtualized filteredItems={filteredItems} activeItems={activeItems} addToSelected={addToSelected} />}
      {!dataLoading &&
        <>
          {isEmpty(filteredItems) && !isEmpty(allProfiles) && <Text color='primary.light1' styles={textStyles} size='14'> {t('reports.modal.tracking.addProfilesFromMyInfluencers.helpertext.error.noResults', { searchFilter })} </Text>}
          {isEmpty(allProfiles) && <Text color='primary.light1' styles={textStyles} size='14'> {t('reports.modal.tracking.addProfilesFromMyInfluencers.helpertext.error.noProfile')} </Text>}
        </>}
      <Spacing size='8' />
      {limitExceeded && (
        <Flex align='center'>
          <Icon name='danger' size='12' color='state.dangerDefault' />
          <Text color='state.dangerDefault' styles={textStyles}>
            {t('reports.modal.tracking.addProfilesFromMyInfluencers.helpertext.error', { profilesLimit: LIMIT_OF_PROFILES_TO_ADD })}
          </Text>
        </Flex>
      )}
    </>)
}
