import { useState } from 'react'
import styled from 'styled-components'
import { ProgressStep, Spacing, Text } from 'influ-dms'
import { AddPostsManualCheckUrl } from './checkUrl/AddPostsManualCheckUrl'
import { AddPostsManualForm } from './form/AddPostsManualForm'
import { useTranslate } from '~/hooks/useTranslate'

const Container = styled.div`
  padding: 32px 64px 32px 32px;
`

export const AddPostsManual = () => {
  const { t } = useTranslate()
  const [currentStep, setCurrentStep] = useState(0)
  const [urlHasBeenChecked, setUrlHasBeenChecked] = useState(false)
  const firstStep = currentStep === 0

  const continueWithoutUrl = () => setCurrentStep(1)

  const onCheckUrlExists = () => {
    setCurrentStep(1)
    setUrlHasBeenChecked(true)
  }

  return (
    <Container>
      <Text color='primary.dark' size='16' bold>{t('reports.report.modal.addPublication.manualAnalyses.titleHeader1')}</Text>
      <Spacing size='8' />
      <Text color='primary.light3'>{t('reports.report.modal.addPublication.manualAnalyses.titleParagraph1')}</Text>
      <Spacing size='32' />
      <ProgressStep steps={[t('reports.report.modal.addPublication.manualAnalyses.stepper.url.step'), t('reports.report.modal.addPublication.manualAnalyses.stepper.publicationMetrics.step')]} size={14} currentStep={currentStep} />
      <Spacing size='16' />
      {(firstStep || urlHasBeenChecked) && <AddPostsManualCheckUrl continueWithoutUrl={continueWithoutUrl} onCheckUrlExists={onCheckUrlExists} currentStep={currentStep} />}
      {currentStep === 1 && <AddPostsManualForm urlHasBeenChecked={urlHasBeenChecked} />}
    </Container>
  )
}
