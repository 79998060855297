import { useContext, useEffect } from 'react'
import { Button, FormContext, SideModalFooter, Spacing } from 'influ-dms'
import { useTranslate } from '~/hooks/useTranslate'

export const CreateReportModalFooter = ({ isOpen, handleCreate, handleClose, loading }) => {
  const { t } = useTranslate()
  const { values, cleanForm } = useContext(FormContext)
  const onCreate = () => handleCreate(values, cleanForm, false)
  const onAnother = () => handleCreate(values, cleanForm, true)
  const isEmpty = !values.name || loading

  useEffect(() => {
    if (!isOpen) {
      cleanForm()
    }
  }, [isOpen])
  return (
    <SideModalFooter>
      <Button name='saveReport' disabled={isEmpty} onClick={onCreate}>{t('commons.button.createReport')}</Button>
      <Spacing size='16' vertical />
      <Button name='saveAndCreateAnotherReport' disabled={isEmpty} variant='outline' onClick={onAnother}>{t('commons.button.createAndAddAnother')}</Button>
      <Spacing size='16' vertical />
      <Button name='cancelSaveReport' disabled={loading} color='secondary' variant='flat' onClick={handleClose}>{t('commons.button.cancel')}</Button>
    </SideModalFooter>
  )
}
