import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Date, Flex, FormContext, SideBox, Text } from 'influ-dms'
import { get } from 'lodash'
import { imagePostStyles } from '../../../reportsTable/styles'

const ImageContainer = styled.div`
  width: 75px;
  height: 75px;
  ${imagePostStyles};
  border-radius: 4px;
  margin-right: 16px;
`

const postTextStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  height: 51px; // IE safeguard
  -webkit-box-orient: vertical;
`

export const EditPostModalContentAutomaticPost = () => {
  const { values: { image, publicationDate, caption } } = useContext(FormContext)
  const imageSource = get(image, '[0]')
  return (
    <SideBox>
      <Flex>
        <ImageContainer image={imageSource} />
        <div>
          <Text color='primary.light3' bold><Date>{publicationDate}</Date></Text>
          <Text size='13' color='primary.dark' styles={postTextStyles}>{caption}</Text>
        </div>
      </Flex>
    </SideBox>
  )
}
