import { gql } from '@apollo/client'

export const removeFromPay = gql`
  mutation removeFromPay(
    $paymentRequestIds: [String]!
  ) {
    removeFromPay(
      paymentRequestIds: $paymentRequestIds
    ){
      message
      code
      success
    }
  }
`
